import React from "react";
import styled from "@emotion/styled";
import { Grid, TextBlock } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  padding: 40px 0;
  border-bottom: 1px solid var(--color-mono-20);

  .twoColumn {
    &Left {
      grid-column: 1/-1;
      margin-bottom: 40px;
      color: ${({ theme }) => (theme === `light` ? `black` : `white`)};

      ${breakpoint(`large-tablet`)} {
        grid-column: 1/5;
      }

      ${breakpoint(`small-desktop`)} {
        grid-column: 2/6;
      }
    }

    &Right {
      color: ${({ theme }) => (theme === `light` ? `black` : `#dddee2`)};
      grid-column: 1/-1;

      ${breakpoint(`large-tablet`)} {
        grid-column: 6/-1;
      }

      ${breakpoint(`small-desktop`)} {
        grid-column: 7/12;
      }
    }
  }

  .blur-container {
    border-radius: 0.625rem;
    gap: 1.5rem;
    padding: 1.5rem;
  }
`;

/** ============================================================================
 * @component
 */
const TwoColumnText = ({ data: { columnLeft, _rawColumnRight, theme } }) => (
  <Container theme={theme}>
    <Grid>
      <h3 className="h3 twoColumnLeft">{columnLeft}</h3>
      <div className="b2 twoColumnRight blur-container frosted-glass-dark">
        <TextBlock rawText={_rawColumnRight} />
      </div>
    </Grid>
  </Container>
);

export default TwoColumnText;
