import React from "react";
import styled from "@emotion/styled";
import cn from "classnames";

/** ============================================================================
 * @css
 */
const transitionTime = `0.3s`;

const Container = styled.button`
  width: 42px;
  height: 42px;
  position: relative;
  transition: ${transitionTime} transform;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.hamburgerActive {
    transform: rotate(-45deg);
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &Line {
      transition: ${transitionTime} transform;

      width: 100%;
      height: 1px;
      position: relative;
      background: var(--color-black);

      margin: 3px 0;

      &Active {
        margin: 0;
        top: 50%;
        position: absolute;

        &:nth-of-type(1) {
          transform: scaleX(0);
        }
        &:nth-of-type(2) {
          transform: rotate(90deg);
          transform: scaleX(0);
        }
        &:nth-of-type(3) {
          transform: rotate(-90deg);
        }
      }
    }
  }
`;

/** ============================================================================
 * @component
 * @param  {boolean}    	active 					Whether the menu is active
 * @param  {function}    	setActive 			Function to set the active state
 * @return {node}
 */

const Hamburger = ({ active, setActive }) => (
  <Container onClick={() => setActive((prev) => !prev)} className={cn({ [`hamburgerActive`]: active })}>
    <div className={cn(`hamburgerLine`, { [`hamburgerLineActive`]: active })} />
    <div className={cn(`hamburgerLine`, { [`hamburgerLineActive`]: active })} />
    <div className={cn(`hamburgerLine`, { [`hamburgerLineActive`]: active })} />
    <div className={cn(`hamburgerLine`, { [`hamburgerLineActive`]: active })} />
  </Container>
);

export default Hamburger;
