import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useEmblaCarousel from "embla-carousel-react";

/** ============================================================================
 * @css
 */
const Embla = styled.div`
  position: relative;
  overflow: visible;
`;

const EmblaContainer = styled.ul`
  height: 100%;
  position: relative;
  display: flex;
  align-items: stretch;

  flex-direction: ${({ vertical }) => `${vertical ? `column` : `row`}`};

  gap: ${({ gap }) => `${gap}px`};
`;

const EmblaSlide = styled.li`
  position: relative;
`;

/** ============================================================================
 * @component Core Embla carousel component
 * Accepts optional useEmblaCarousel object as embla = [ref, api], otherwise
 * initializes one of its own. Returns the current int to slide children.
 *
 * todo: move slidesPerView into the options, or find an Embla equivalent.
 */
const Carousel = ({ embla, className = ``, watchDrag, gap = 0, onSlideClick = () => {}, slides = () => {}, slidesPerView = 1, vertical = false }) => {
  // --------------------------------------------------------------------------
  // context / ref / state

  const [current, setCurrent] = useState(0);
  const [defaultEmblaRef, defaultEmblaApi] = useEmblaCarousel({
    align: `start`,
    watchDrag,
    loop: false
  });

  // --------------------------------------------------------------------------
  // methods

  const getApi = () => embla?.api || defaultEmblaApi || null;

  const reinit = (options = {}) => {
    const api = getApi();

    if (!api || typeof api?.reInit !== `function`) {
      return;
    }

    api.reInit(options);
  };

  // --------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const api = getApi(); // FIXME: might not be needed, depends on how the React wrapper works

    if (typeof api?.on !== `function`) {
      return;
    }

    api.on(`select`, () => setCurrent(api.selectedScrollSnap()));
  }, [defaultEmblaApi, embla]);

  useEffect(() => {
    if (!embla?.options) {
      return;
    }

    reinit(embla.options);
  }, [embla?.options]);

  useEffect(() => {
    reinit();
  }, [slidesPerView]);

  // --------------------------------------------------------------------------
  // render

  if (!embla?.ref && !defaultEmblaRef) {
    return <></>;
  }

  const ref = embla?.ref || defaultEmblaRef;

  return (
    <Embla ref={ref} className={`${className} embla`}>
      <EmblaContainer className="embla__container" gap={gap} vertical={vertical}>
        {slides({ current }).map((slide, slideIndex) => (
          <EmblaSlide
            key={`carousel-slide-${slideIndex}`}
            css={css`
              flex: 0 0 calc(${100 / slidesPerView}% - ${gap}px);
            `}
            className="embla__slide"
            onClick={() => onSlideClick(slideIndex)}
            role="presentation"
          >
            {slide}
          </EmblaSlide>
        ))}
      </EmblaContainer>
    </Embla>
  );
};

export default Carousel;
