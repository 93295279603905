import { useState } from "react";
import { useDevice } from "~hooks";

export const useRelativeCursor = (ref) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { deviceAbove } = useDevice();

  const [pos, setPos] = useState({
    x: 0,
    y: 0
  });

  // ---------------------------------------------------------------------------
  // variables

  const isMobile = deviceAbove(`small-tablet`) === false;

  // ---------------------------------------------------------------------------
  // methods

  const relativeCursorHandler = (e) => {
    if (!ref?.current || isMobile) return;

    const { left, top } = ref.current.getBoundingClientRect();

    setPos({
      x: e.clientX - left,
      y: e.clientY - top
    });
  };

  return { relativeCursorHandler, pos };
};

export default useRelativeCursor;
