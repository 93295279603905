import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { Button, Link, SVG, CurrencyBadge } from "~components";
import { thousandCommas } from "~utils/helpers";
import { blockchainHooks } from "~hooks/blockchainHooks";
import useApp from "~hooks/useApp";
import useExternalIntegrations from "~hooks/useExternalIntegrations";

const Container = styled.div`
  width: 100%;
`;

const StatusDot = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.5rem;
  background: ${({ active }) => (active ? `var(--color-ux-green-100)` : `var(--color-ux-green-100)`)};
  border-radius: 100%;
`;

const Tooltip = styled.div`
  transition: 0.3s var(--cubic-easing) opacity;

  opacity: ${({ active }) => (active ? 1 : 0)};

  width: auto;
  position: absolute;
  transform: translate3d(-50%, calc(-50% - 2rem), 0);
  top: 50%;
  left: 50%;
  z-index: 20;
  overflow: hidden;
  display: block;
  padding: 0.75rem;
  white-space: nowrap;
  border-radius: 0.5rem;
  pointer-events: none;
  background: var(--color-mono-60);
  color: white;
`;

const ProfileItemActionsFragment = ({ nft, STATUS, header, statusText }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const { setActiveOverlay, userData } = useApp();
  const { useCanDoBuyout, useGetBuyoutData } = blockchainHooks();
  const { graph } = useExternalIntegrations();
  // ---------------------------------------------------------------------------
  // context / ref / state
  const [hoveringBuyout, setHoveringBuyout] = useState(false);
  const [graphBuyoutData, setGraphBuyoutData] = useState();
  const [isBuyoutRequested, setIsBuyoutRequested] = useState(false);

  const showActionButtons = true; // FIXME:

  const disableActionButtons = STATUS === `awaitingQuotation` || STATUS === `awaitingEmailConfirmation`;

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const fetchBuyoutData = async () => {
      const fragmentsAddress = nft?.enrichedProduct?.nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress;
      const fragmentsTokenId = nft?.enrichedProduct?.nftData?.saleId?.toString();

      const buyouts = await graph.getBuyoutDataFromFragmentsIdAndInitiator(userData?.address, fragmentsAddress, fragmentsTokenId);

      setGraphBuyoutData(buyouts?.[0]);
    };

    fetchBuyoutData().catch((e) => console.error(e));
  }, [nft?.enrichedProduct?.nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress, nft?.enrichedProduct?.nftData?.saleId]);

  const { data: isBuyoutEnabled } = useCanDoBuyout(nft?.enrichedProduct?.nftData);

  const { data: buyoutData } = useGetBuyoutData(nft?.enrichedProduct?.nftData, graphBuyoutData?.buyoutId?.toString());

  useEffect(() => {
    if (buyoutData && !buyoutData?.isSuccessful) {
      setIsBuyoutRequested(true);
    } else {
      setIsBuyoutRequested(false);
    }
  }, [buyoutData?.isSuccessful]);
  // ---------------------------------------------------------------------------
  // render

  return (
    <Container>
      <div className="profileItemActionsBuyoutFlex">
        <h3 className="b1">{header}</h3>

        {STATUS === `awaitingQuotation` ||
          (STATUS === `quotationreceived` && (
            <div className="profileItemActionsBuyoutInfo" onMouseEnter={() => setHoveringBuyout(true)} onMouseLeave={() => setHoveringBuyout(false)}>
              <Tooltip active={hoveringBuyout}>
                {/* @externalintegration */}
                <h5 className="caption">REQUEST ID NUMBER: ????</h5>
              </Tooltip>

              <SVG svg="info" />
            </div>
          ))}
      </div>
      {statusText && (
        <div className="profileItemActionsBuyoutFlex">
          <StatusDot active={STATUS === `readyToClaim`} />
          <p className="profileItemActionsBuyoutStatus caption">{statusText}</p>
        </div>
      )}
      {showActionButtons && (
        <div className="profileItemActionsButtons">
          <button
            type="button"
            onClick={() => setActiveOverlay(`NFTOverlayTransferFragment`)}
            className={disableActionButtons ? `profileItemActionsButtonDisabled` : ``}
          >
            <span className="b2">Transfer</span>
            <SVG svg="arrowRight" />
          </button>

          <button
            type="button"
            onClick={() => setActiveOverlay(`NFTOverlayCreateFragmentSellOrder`)}
            className={disableActionButtons ? `profileItemActionsButtonDisabled` : ``}
          >
            <span className="b2">New Sell Offer</span>
            <SVG svg="arrowRight" />
          </button>

          {/* <button
            type="button"
            onClick={() => setActiveOverlay(`NFTOverlayBuyoutQuote`)}
            className={disableActionButtons || !isBuyoutEnabled || isBuyoutRequested ? `profileItemActionsButtonDisabled` : ``}
          >
            <span className="b2">Buyout</span>
            <SVG svg="arrowRight" />
          </button> */}
        </div>
      )}

      {STATUS === `quotationReceived` && (
        <>
          {buyoutData && (
            <div className="profileItemBuyouts">
              {buyoutData?.owner && (
                <ul className="profileItemBuyoutsList">
                  <li className="profileItemBuyoutsItem">
                    <h5 className="profileItemBuyoutsKey profileItemBuyoutsKeyPrimary caption">BuyOut price defined by</h5>
                    <p className="profileItemBuyoutsValue caption">
                      {/* @externalintegration */}
                      <Link to="/">{buyoutData.owner}</Link>
                    </p>
                  </li>
                </ul>
              )}

              <ul className="profileItemBuyoutsList">
                {buyoutData?.expiry && (
                  <li className="profileItemBuyoutsItem">
                    <h5 className="profileItemBuyoutsKey caption">buyout proposal expiration</h5>
                    <p className="profileItemBuyoutsValue b2">{buyoutData.expiry}</p>
                  </li>
                )}

                {buyoutData?.pricePerFragment && (
                  <li className="profileItemBuyoutsItem">
                    <h5 className="profileItemBuyoutsKey caption">Price per Fragment</h5>
                    <p className="profileItemBuyoutsValue b2">${buyoutData.pricePerFragment} USDt</p>
                  </li>
                )}

                {buyoutData?.fragments && (
                  <li className="profileItemBuyoutsItem">
                    <h5 className="profileItemBuyoutsKey caption">number of fragments to buyout</h5>
                    <p className="profileItemBuyoutsValue b2">{buyoutData.fragments}</p>
                  </li>
                )}
              </ul>

              <div className="profileItemBuyoutsTotal">
                <div className="profileItemBuyoutsKey profileItemBuyoutsKeyPrimary">
                  <h4 className="b1">Total Price</h4>
                </div>

                <div className="profileItemBuyoutsValue profileItemBuyoutsValueTotal">
                  <p className="h3">{thousandCommas(buyoutData.fragments * buyoutData.pricePerFragment)}</p>

                  <CurrencyBadge color="white" text="USDt" />
                </div>
              </div>

              <div className="profileItemBuyoutsAccept">
                <Button onClick={() => setActiveOverlay(`NFTOverlayBuyout`)} fluid variant="primaryTall" colorTheme="light">
                  <span className="b1">Buyout</span>
                </Button>
              </div>
            </div>
          )}
        </>
      )}

      {STATUS === `awaitingConversion` && (
        <>
          <p className="b2 profileItemActionsAwaitingConversionHint">By clicking this button, you get ownership of the whole digitised asset.</p>

          <Button onClick={() => setActiveOverlay(`NFTOverlayConvertFragmentToWhole`)} fluid variant="primaryTall" colorTheme="light">
            <span className="b1">Convert Fragments to Whole</span>
          </Button>
        </>
      )}
    </Container>
  );
};

export default ProfileItemActionsFragment;
