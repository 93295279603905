import React, { useEffect, useRef, useState, useMemo } from "react";
import styled from "@emotion/styled";
import cn from "classnames";
import { Button, ProfileIdentityVerificationModal, NoSSR } from "~components";
import { useApp } from "~hooks";
import { createPortal } from "react-dom";
import { UserPurchaseabilityStatus } from "~dApp/models/profile/UserPurchaseabilityStatus";

const Modal = styled.div`
  transition: opacity 0.2s;

  opacity: ${({ show }) => (show ? 1 : 0)};

  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 90;
  pointer-events: ${({ show }) => (show ? `all` : `none`)};
  display: flex;
  align-items: center;
  justify-content: center;

  .idVerificationModalEscape {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

/** ============================================================================
 * @component
 * @param {boolean} connected - Whether the user is connected to a wallet.
 * @param {string} className - For additional styling config
 * @return {node}
 */
const IdentityVerification = ({ kycSession, userData }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const modalRef = useRef();
  const { userIsLoggedIn } = useApp();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [showKycModal, setShowKycModal] = useState(false);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowKycModal(false);
      }
    }
    // Bind the event listener
    document.addEventListener(`mousedown`, handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener(`mousedown`, handleClickOutside);
    };
  }, [modalRef]);

  const kycMessage = useMemo(() => {
    if (!userIsLoggedIn || !kycSession) return null;

    switch (kycSession.status) {
      case UserPurchaseabilityStatus.Whitelisted:
        return { message: `User has been allowlisted`, verified: true };
      case UserPurchaseabilityStatus.NoEmail:
        return null;
      case UserPurchaseabilityStatus.KycRejected:
        return { message: `Your identity verification has been permanently rejected` };
      case UserPurchaseabilityStatus.Verified:
        return { message: `User's identity verified`, verified: true };
      default:
        return null;
    }
  }, [userIsLoggedIn, kycSession]);

  // ---------------------------------------------------------------------------
  // render

  if (!userData?.isConnected || !userIsLoggedIn) {
    return null;
  }

  const canVerify = !!(
    userData?.user?.emailAddress &&
    userData.user.emailAddress.length &&
    (!kycMessage || !kycMessage.verified) &&
    kycSession?.status !== UserPurchaseabilityStatus.Whitelisted
  );

  return (
    <>
      {kycSession?.status !== UserPurchaseabilityStatus.Verified && kycSession?.status !== UserPurchaseabilityStatus.Whitelisted && (
        <p className="kycMessage">
          The verification procedure is needed only for invoicing.
          <br />
          We do not store any of your any personal information and these won’t be associated with your wallet address.
        </p>
      )}

      <dl>
        <dt>Identity verification</dt>
        {!!kycMessage && <dd className={cn(kycMessage.verified && `verified`)}>{kycMessage.message}</dd>}
      </dl>

      {!canVerify ? (
        kycSession.status !== UserPurchaseabilityStatus.Whitelisted && (
          <Button readOnly className="kycButton" variant="secondary" colorTheme="light">
            Specify your email first
          </Button>
        )
      ) : (
        <Button className="kycButton" iconRight="arrowRight" variant="secondary" colorTheme="light" onClick={() => setShowKycModal(true)}>
          Verify identity
        </Button>
      )}

      <NoSSR>
        {createPortal(
          <Modal show={showKycModal}>
            <div ref={modalRef}>
              {showKycModal && (
                <ProfileIdentityVerificationModal
                  setKycDone={() => {
                    setShowKycModal(false);
                  }}
                  kycSession={kycSession}
                  userData={userData}
                />
              )}
            </div>
          </Modal>,
          document.body
        )}
      </NoSSR>
    </>
  );
};

export default IdentityVerification;
