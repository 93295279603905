import React, { useState } from "react";
import cn from "classnames";
import { useAccount } from "wagmi";
import { Button } from "~components";
import { useApp } from "~hooks";
import { truncateAddress } from "~utils/helpers";
import SVG from "~components/Common/SVG";

/** ============================================================================
 * @component
 * @return {node}
 */
const ProfileWalletStatus = () => {
  const { setConnectorActive, userData, disconnect } = useApp();
  const { isConnected } = useAccount();

  const [copyClicked, setCopyClicked] = useState(false);

  const handleClick = () => {
    if (isConnected) {
      disconnect();
    } else {
      setConnectorActive(true);
    }
  };

  return (
    <section className="profileWalletStatus">
      <dl className={cn(`status`, isConnected && `connected`)}>
        <dt>Status</dt>
        <dd>{isConnected ? `Signed In` : `Signed Out`}</dd>
      </dl>
      <dl className="wallet">
        <dt>Wallet</dt>
        <dd>
          <span>{truncateAddress(userData.address)}</span>
          <button
            type="button"
            className="copy"
            disabled={copyClicked}
            onClick={() => {
              if (typeof navigator !== `undefined` && typeof navigator.clipboard !== `undefined`) {
                navigator?.clipboard.writeText(userData.address);
                setCopyClicked(true);
                setTimeout(() => setCopyClicked(false), 2000);
              }
            }}
          >
            {copyClicked ? <SVG svg="checkboxChecked" /> : <SVG svg="copy" />}
          </button>
        </dd>
      </dl>
      <Button className="connectionButton" onClick={handleClick} variant="secondary" colorTheme="light" fluid>
        {isConnected ? `Sign Out` : `Sign In`}
      </Button>
    </section>
  );
};

export default ProfileWalletStatus;
