import React, { useEffect, useState } from "react";
import { Button } from "~components";
import { lendWebsite } from "~utils/helpers";
import useApp from "~hooks/useApp";
import { blockchainHooks } from "~hooks/blockchainHooks";
import useExternalIntegrations from "~hooks/useExternalIntegrations";

const FragmentActions = ({ nft, disableActionButtons }) => {
  const { graph } = useExternalIntegrations();
  const { setActiveOverlay, userData } = useApp();
  const { useCanDoBuyout, useGetBuyoutData } = blockchainHooks();
  const { data: isBuyoutEnabled } = useCanDoBuyout(nft?.enrichedProduct?.nftData);
  const [isBuyoutRequested, setIsBuyoutRequested] = useState(false);
  const [graphBuyoutData, setGraphBuyoutData] = useState();

  const { data: buyoutData } = useGetBuyoutData(nft?.enrichedProduct?.nftData, graphBuyoutData?.buyoutId?.toString());

  useEffect(() => {
    const fetchBuyoutData = async () => {
      const fragmentsAddress = nft?.enrichedProduct?.nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress;
      const fragmentsTokenId = nft?.enrichedProduct?.nftData?.saleId?.toString();

      const buyouts = await graph.getBuyoutDataFromFragmentsIdAndInitiator(userData?.address, fragmentsAddress, fragmentsTokenId);

      setGraphBuyoutData(buyouts?.[0]);
    };

    fetchBuyoutData().catch((e) => console.error(e));
  }, [nft?.enrichedProduct?.nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress, nft?.enrichedProduct?.nftData?.saleId]);

  useEffect(() => {
    if (buyoutData && !buyoutData?.isSuccessful) {
      setIsBuyoutRequested(true);
    } else {
      setIsBuyoutRequested(false);
    }
  }, [buyoutData?.isSuccessful]);

  return (
    <>
      <Button className="action" variant="primary" onClick={() => setActiveOverlay(`NFTOverlayTransferFragment`)} disabled={disableActionButtons} fluid>
        Transfer
      </Button>
      <Button
        className="action"
        variant="secondary"
        onClick={() => setActiveOverlay(`NFTOverlayCreateFragmentSellOrder`)}
        disabled={disableActionButtons}
        fluid
      >
        New Sell Offer
      </Button>
      <Button
        className="action"
        variant={disableActionButtons || !isBuyoutEnabled || isBuyoutRequested ? `primary` : `secondary`}
        onClick={() => setActiveOverlay(`NFTOverlayBuyoutQuote`)}
        disabled={disableActionButtons || !isBuyoutEnabled || isBuyoutRequested}
        fluid
      >
        Buyout
      </Button>
    </>
  );
};

const WholeActions = ({ nft, disableActionButtons, alreadyOnSale }) => {
  const { setActiveOverlay } = useApp();

  const STATUS = nft?.nftStatus?.status;

  return (
    <>
      <Button className="action" variant="primary" onClick={() => setActiveOverlay(`NFTOverlayTransferWhole`)} disabled={disableActionButtons} fluid>
        Transfer
      </Button>

      {alreadyOnSale ? (
        <Button className="action" variant="secondary" href={`/marketplace/${nft?.enrichedProduct?.product?.identifier}`} newTab={false}>
          View Listing
        </Button>
      ) : (
        <Button className="action" variant="secondary" onClick={() => setActiveOverlay(`NFTOverlayCreateWholeSellOrder`)} disabled={disableActionButtons} fluid>
          New Sell Offer
        </Button>
      )}

      <Button className="action" variant="secondary" onClick={() => setActiveOverlay(`NFTOverlayClaimWhole`)} disabled={disableActionButtons} fluid>
        Claim
      </Button>
      {(STATUS === `readyToClaim` || STATUS === `finalisingDetails`) && (
        <Button
          className="action"
          variant="secondary"
          onClick={() => setActiveOverlay(`NFTOverlayClaimRequestApproveCurrency`)}
          disabled={disableActionButtons || STATUS === `finalisingDetails`}
          fluid
        >
          Claim Now
        </Button>
      )}
      <Button className="action borrow" variant="secondary" iconRight="arrowRight" href={`${lendWebsite}/borrow`} disabled={disableActionButtons} fluid>
        Borrow
      </Button>
    </>
  );
};

const ProfileItemActionsWhole = ({ nft, item, readOnly, alreadyOnSale }) => {
  if (readOnly) return null;

  const STATUS = nft?.nftStatus?.status;
  const isItemFragmented = item?.tokenType === `ERC1155`;
  const disableActionButtons = STATUS === `awaitingQuotation` || STATUS === `awaitingEmailConfirmation`;

  return (
    <div className="profileItemActions">
      {isItemFragmented ? (
        <FragmentActions nft={nft} disableActionButtons={disableActionButtons} />
      ) : (
        <WholeActions nft={nft} disableActionButtons={disableActionButtons} alreadyOnSale={alreadyOnSale} />
      )}
    </div>
  );
};

export default ProfileItemActionsWhole;
