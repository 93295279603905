import React from "react";
import styled from "@emotion/styled";
import { SlicePadding, ArticleTextArea, MediaRow, Grid } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  .textAndMediaGrid {
    gap: 1.5rem;
    grid-auto-flow: dense;
  }

  .textAndMedia {
    &MediaRow {
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &TextArea {
      ${breakpoint(`small-tablet`)} {
        position: sticky;
        top: 120px; // Navbar
        align-self: flex-start;
      }
    }
  }
`;

const TextContainer = styled.div`
  grid-column: 1/-1;

  ${breakpoint(`tablet`)} {
    grid-column: ${({ mediaSide }) => (mediaSide === `left` ? `5/-1` : `1/5`)};
  }

  ${breakpoint(`large-tablet`)} {
    grid-column: ${({ mediaSide }) => (mediaSide === `left` ? `7/-1` : `1/7`)};
  }

  ${breakpoint(`small-desktop`)} {
    grid-column: ${({ mediaSide }) => (mediaSide === `left` ? `9/-1` : `1/5`)};
  }

  h2 {
    color: ${({ theme }) => (theme === `dark` ? `#ffffff` : `#000000`)};
  }
`;

const MediaContainer = styled.div`
  grid-column: 1/-1;

  ${breakpoint(`tablet`)} {
    grid-column: ${({ mediaSide }) => (mediaSide === `left` ? `1/5` : `5/-1`)};
  }

  ${breakpoint(`large-tablet`)} {
    grid-column: ${({ mediaSide }) => (mediaSide === `left` ? `1/7` : `7/-1`)};
  }

  ${breakpoint(`small-desktop`)} {
    grid-column: ${({ mediaSide }) => (mediaSide === `left` ? `1/7` : `7/-1`)};
  }
`;

/** ============================================================================
 * @component
 */
const TextAndMedia = ({ data: { theme = `light`, mediaSide, mediaRows, textArea } }) => (
  <Container>
    <SlicePadding>
      <Grid noXPadding className="textAndMediaGrid">
        <TextContainer theme={theme} mediaSide={mediaSide}>
          <ArticleTextArea
            theme={theme}
            heading={textArea?.heading}
            headingCaption={textArea?.headingCaption}
            rawText={textArea?._rawBodyText}
            className="textAndMediaTextArea"
          />
        </TextContainer>
        <MediaContainer mediaSide={mediaSide}>
          {mediaRows.map((row, i) => (
            <MediaRow key={i} className="textAndMediaMediaRow" media={row.media} aspectRatio={row.aspectRatio} />
          ))}
        </MediaContainer>
      </Grid>
    </SlicePadding>
  </Container>
);

export default TextAndMedia;
