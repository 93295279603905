import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useWindowDimensions } from "~hooks";
import { SlicePadding } from "~components";
import InfoTabsDesktop from "./components/InfoTabsDesktopSanity";
import InfoTabsMobile from "./components/InfoTabsMobileSanity";
import AltrApiService from "~dApp/AltrApiService";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  background: var(--color-khaki-50);
`;

/** ============================================================================
 * @component
 */
const InfoTabs = ({ product, enrichedProduct }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const altrApiService = new AltrApiService();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [lucidProduct, setLucidProduct] = useState();

  const { saleData } = product || {};
  const { basicInfo, certificate, _rawAbout: aboutText, authCertificate } = product?.productInfo || {};

  const { isMobile: mobile, isTablet: tablet } = useWindowDimensions();

  const isMobile = mobile || tablet;

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!product?.productGuid && !enrichedProduct?.product?.identifier) {
      return;
    }

    const getData = async () => {
      const lucid = await altrApiService.getLucidProductById(product?.productGuid, product?.collection?.title?.toLowerCase());

      setLucidProduct(lucid);
    };

    if (enrichedProduct?.product?.identifier) {
      setLucidProduct(enrichedProduct);
    } else {
      getData();
    }
  }, [product?.productGuid, product?.collection?.title, enrichedProduct?.product?.identifier]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container>
      <SlicePadding>
        {(isMobile && (
          <InfoTabsMobile
            basicInfo={basicInfo}
            certificate={certificate}
            authCertificate={authCertificate}
            aboutText={aboutText}
            saleDataRows={saleData?.rows}
            saleDataDescription={saleData?.description}
            lucidProduct={lucidProduct}
          />
        )) || (
          <InfoTabsDesktop
            basicInfo={basicInfo}
            certificate={certificate}
            authCertificate={authCertificate}
            aboutText={aboutText}
            saleDataRows={saleData?.rows}
            saleDataDescription={saleData?.description}
            lucidProduct={lucidProduct}
          />
        )}
      </SlicePadding>
    </Container>
  );
};

export default InfoTabs;
