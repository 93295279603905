import React from "react";
import styled from "@emotion/styled";
import { SVG } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  width: 100%;
  user-select: none;
  display: block;

  ${({ disabled }) => {
    if (!disabled) return null;
    return `
			opacity: 0.3;
		`;
  }}

  .textInputWarning {
    margin-top: 0.5rem;
    display: flex;
    text-transform: uppercase;

    color: ${({ error }) => (error ? `var(--color-ux-error)` : `var(--color-black-40)`)};

    &Icon {
      height: 15px;
      width: 15px;
      margin-right: 4px;
    }
  }
`;

const TopText = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  .textInputHint {
    color: var(--color-mono-40);
  }
`;

const InputContainer = styled.div`
  position: relative;

  input,
  textarea {
    background: var(--color-white);
    padding: 0.875rem 1rem;
    line-height: 1;
    width: 100%;
    border-radius: 0.5rem;
    border: none;
    color: var(--color-black);
    transition: all 0.2s;
    border: ${({ error }) => (error ? `0.0625rem solid var(--color-ux-error)` : `0.0625rem solid white`)};
    font-size: 1rem;

    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: var(--color-mono-40);
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: var(--color-mono-40);
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: var(--color-mono-40);
    }

    &:focus {
      box-shadow: 0 0 0 1px var(--color-black);
    }

    &.hasSearch {
      padding-left: 2.5rem;
    }

    padding-left: ${({ hasSearchIcon }) => (hasSearchIcon ? `2.5rem` : `1rem`)};
    padding-right: ${({ onClick }) => (onClick ? `3.25rem` : `1rem`)};
  }

  textarea {
    height: 160px;
    resize: none;
    @media only screen and (min-width: $desktop) {
      height: 200px;
    }
  }

  .icon.searchIcon {
    font-size: 1rem;
    line-height: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  display: flex;
  align-items: ${({ textarea }) => (textarea ? `auto` : `center`)};
  padding-top: ${({ textarea }) => (textarea ? `0.5rem` : `0`)};
`;

const SubmitButton = styled.button`
  width: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  border-radius: 100%;

  &:focus-visible {
    box-shadow: 0 0 0 1px var(--color-black);
  }

  .textInputSubmitButtonIcon {
    height: 16px;
    width: 16px;
  }
`;

/** ============================================================================
 * @component
 * Text input form field
 * Can be standalone (pass in onClick) or part of larger form
 *
 * @param  {string}    	className  			For additional styling config
 * @param  {string}    	placeholder  		Placeholder text
 * @param  {string}    	id           		Used to connect with label for a11y
 * @param  {string}  		value 					Control value from parent
 * @param  {string}   	label        		Text which displays in top left corner
 * @param  {string}    	hint      			Text which displays in top right corner
 * @param  {string} 		warningMessage  Shows in bottom left next to warning icon
 * @param  {boolean} 		hasSearchIcon  	Whether to display the search icon
 * @param  {boolean} 		required  			Whether to render the '*' next to label
 * @param  {boolean} 		error  			Toggles the error state
 * @param  {boolean} 		disabled  			Toggles the disabled state
 * @param  {boolean} 		isDarkTheme  		Toggles inverted color scheme
 * @param  {boolean} 		textarea  			Renders multiline textarea
 * @param  {function} 	onClick  				Renders the inline submit button
 * @param  {function} 	onChange  			Callback which gets passed e.target.value when updated
 * @param  {function} 	onEnter  				Called when user presses Enter key, used for standalone inputs
 * @param  {function} 	onBlur  				Runs when input loses focus
 * @return {node}
 */

const TextInput = ({
  placeholder,
  id,
  value,
  label,
  hint,
  isDarkTheme,
  warningMessage,
  hasSearchIcon,
  error,
  required,
  disabled,
  onClick,
  onChange,
  className,
  textarea,
  onEnter,
  onBlur
}) => {
  const MAX_LENGTH = 300;

  const handleChange = (e) => {
    if (onChange) onChange(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (!onEnter) return;
    if (e.key === `Enter`) onEnter();
  };

  const InputElement = textarea ? `textarea` : `input`;
  return (
    <Container disabled={disabled} error={error} className={className || null}>
      {(label || hint || required) && (
        <TopText>
          <div>
            {(label || required) && (
              <label className="caption textInputLabel" htmlFor={id}>
                {/* {required && `* `} */}
                {label}
              </label>
            )}
          </div>
          <div>{hint && <span className="caption textInputHint">{hint}</span>}</div>
        </TopText>
      )}
      <InputContainer hasSearchIcon={hasSearchIcon} onClick={onClick} error={error}>
        {hasSearchIcon && <i className="icon searchIcon">looking_glass</i>}
        <InputElement
          maxLength={MAX_LENGTH}
          id={id}
          type="text"
          disabled={disabled}
          placeholder={placeholder}
          onChange={handleChange}
          value={value}
          onKeyDown={handleKeyDown}
          onBlur={() => onBlur && onBlur()}
        />
        {onClick && (
          <ButtonContainer textarea={textarea}>
            <SubmitButton
              tabIndex={disabled ? -1 : 0}
              type="submit"
              aria-label="Submit"
              onClick={(e) => {
                e.preventDefault();
                onClick();
              }}
            >
              <SVG
                className="textInputSubmitButtonIcon"
                svg="arrowRight"
                style={{
                  color: isDarkTheme ? `var(--color-white)` : `var(--color-black)`
                }}
              />
            </SubmitButton>
          </ButtonContainer>
        )}
      </InputContainer>
      {warningMessage && (
        <span className="caption textInputWarning">
          <SVG
            svg="warningTriangle"
            className="textInputWarningIcon"
            style={{
              color: error ? `var(--color-ux-error)` : `var(--color-black-40)`
            }}
          />
          {warningMessage}
        </span>
      )}
    </Container>
  );
};

export default TextInput;
