import React from "react";
import styled from "@emotion/styled";
import { EventPurchaseInfo } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: visible;
  border-radius: 1rem;
`;

/** ============================================================================
 * @component
 * todo: animations
 * @return {node}
 */
const EventPurchase = ({ nft, nftStatus, setSelected, selected, loading, displayPrice, ticketAvailable, maxTicketSupply }) => (
  // ---------------------------------------------------------------------------
  // imports / hooks

  // per Figma:
  /*
   enrichedProduct will have:
    - drop date
    - price
    - fragments
    - fragment price
  for when the status of the nft is "before first sale"
   */

  // ---------------------------------------------------------------------------
  // variables

  // ---------------------------------------------------------------------------
  // methods

  // ---------------------------------------------------------------------------
  // render
  <Container>
    <EventPurchaseInfo
      nft={nft}
      nftStatus={nftStatus}
      title="Event Access Ticket"
      visible
      background="khaki-10"
      heading="Ticket choice:"
      selected={selected}
      setSelected={setSelected}
      loading={loading}
      displayPrice={displayPrice}
      ticketAvailable={ticketAvailable}
      maxTicketSupply={maxTicketSupply}
    />
  </Container>
);
export default EventPurchase;
