/* eslint-disable import/no-extraneous-dependencies */

import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Grid, PriceWidget, SVG, Button } from "~components";
import { breakpoint } from "~utils/css";
import { BLOCK_EXPLORER, BLOCK_EXPLORER_ADDRESS, truncateAddress, truncateTxId } from "~utils/helpers";
import { createPortal } from "react-dom";
import useScroll from "~hooks/useScroll";
import useApp from "~hooks/useApp";
import useWindowDimensions from "~hooks/useWindowDimensions";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  transition:
    0.3s var(--cubic-easing) opacity,
    0.3s var(--cubic-easing) transform;

  opacity: ${({ active }) => `${active ? 1 : 0}`};
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};
  transform: translate3d(0, ${({ active }) => `${active ? `0` : `30%`}`}, 0);

  ${breakpoint(`large-tablet`)} {
    transform: scale(${({ active }) => `${active ? 1 : 1.025}`});
  }

  width: 100vw;
  height: 100svh;
  position: fixed;
  inset: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpoint(`large-tablet`)} {
    height: 100svh;
    top: 0;
  }

  .profileHistoryInspector {
    height: 100%;
    grid-column: 1 / -1;
    position: relative;
    padding: 1rem;
    background: var(--color-mono-90);
    backdrop-filter: blur(50px);
    border-radius: 12px;

    ${breakpoint(`large-tablet`)} {
      grid-column: 3 / span 8;
      height: auto;
      position: relative;
      padding: 2.5rem;
    }

    ${breakpoint(`small-desktop`)} {
      grid-column: 4 / span 6;
    }

    //

    &Link {
      margin-left: 6px;
    }

    &LinkIcon {
      width: 15px;
      margin-left: 5px;
      color: var(--color-mono-40);
    }

    //

    &Info {
      display: block;
      padding-bottom: 2rem;

      ${breakpoint(`large-tablet`)} {
        display: flex;
      }

      &Heading {
        margin-top: 1.5rem;
        margin-bottom: 0.25rem;

        ${breakpoint(`large-tablet`)} {
          margin-top: 0;
          margin-bottom: 0.5rem;
        }

        &Upper {
          margin-bottom: 2rem;
        }
      }

      &Date,
      &Price {
        ${breakpoint(`large-tablet`)} {
          width: 50%;
        }
      }
    }

    &Header {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid var(--color-mono-70);
    }

    &Close {
      width: 1.25rem;
      height: 1.25rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 100%;
      border: 1px solid var(--color-white);

      ${breakpoint(`large-tablet`)} {
        width: 1.5rem;
        height: 1.5rem;
      }

      svg {
        width: 10px;
      }
    }

    &Table {
      border-top: 1px solid var(--color-mono-70);

      &Heading {
        margin-bottom: 0.25rem;
        color: var(--color-mono-40);

        ${breakpoint(`large-tablet`)} {
          margin-bottom: 0;
        }
      }

      &Row {
        position: relative;
        display: block;
        padding: 0.5rem 0 0.75rem;
        border-bottom: 1px solid var(--color-mono-70);
        word-break: break-all;

        ${breakpoint(`large-tablet`)} {
          padding: 0.5rem 0;
        }

        p {
          display: inline-block;
        }
      }
    }
  }
`;

const Background = styled.div`
  width: 100vw;
  height: 100svh;
  position: fixed;
  inset: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
`;

const Content = styled.div`
  margin-top: 18rem;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 20;
  overflow-y: hidden;

  & > div {
    width: 100%;
  }

  ${breakpoint`small-tablet`} {
    margin-top: 22rem;
  }

  ${breakpoint(`large-tablet`)} {
    margin-top: 6rem;
    height: auto;
  }
`;

/** ============================================================================
 * @component
 */
const ProfileHistoryInspector = ({ transaction, setTransaction }) => {
  const { scrollTop } = useScroll();
  const { setDisableScroll } = useApp();
  const { isMobile } = useWindowDimensions();

  const modalRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setTransaction(null);
      }
    }
    // Bind the event listener
    document.addEventListener(`mousedown`, handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener(`mousedown`, handleClickOutside);
    };
  }, [modalRef]);

  useEffect(() => {
    if (transaction) {
      setDisableScroll(true);
    }
    return () => setDisableScroll(false);
  }, [transaction]);

  return createPortal(
    <Container active={transaction !== null} scrollTop={scrollTop}>
      <Background />

      <Content>
        <Grid>
          <div className="profileHistoryInspector" ref={modalRef}>
            <header className="profileHistoryInspectorHeader">
              <h3 className="profileHistoryInspectorTitle caption">{transaction?.item?.name || transaction?.nftType === `ERC721` ? `WHOLE` : `FRAGMENT`}</h3>

              <button type="button" onClick={() => setTransaction(null)}>
                <div className="profileHistoryInspectorClose">
                  <SVG svg="minus" />
                </div>
              </button>
            </header>

            <div className="profileHistoryInspectorInfo">
              <div className="profileHistoryInspectorInfoDate">
                <h4 className="profileHistoryInspectorInfoHeading b1">{transaction?.description}</h4>
                <p className="profileHistoryInspectorTableHeading caption">{transaction?.date || ``}</p>
              </div>

              <div className="profileHistoryInspectorInfoPrice">
                {transaction?.nftType === `ERC1155` && (
                  <>
                    <h5 className="profileHistoryInspectorInfoHeading caption">AMOUNT</h5>
                    <p className="profileHistoryInspectorInfoHeadingUpper b1">{transaction?.amount || ``}</p>
                  </>
                )}

                {transaction?.price && (
                  <>
                    <h5 className="profileHistoryInspectorInfoHeading caption">PRICE</h5>
                    <PriceWidget displayPrice={transaction?.price || ``} fontClass="b1" color="white" />
                  </>
                )}
              </div>
            </div>

            <ul className="profileHistoryInspectorTable">
              {transaction?.minter?.name && (
                <li className="profileHistoryInspectorTableRow">
                  <h5 className="profileHistoryInspectorTableHeading caption">MINTER:</h5>
                  <Button variant="text" className="profileHistoryInspectorLink" href={`${BLOCK_EXPLORER_ADDRESS}${transaction?.minter?.address}`}>
                    <p className="b2">{transaction?.minter?.name || ``}</p>
                    <SVG svg="chain" className="profileHistoryInspectorLinkIcon" />
                  </Button>
                </li>
              )}

              <li className="profileHistoryInspectorTableRow">
                <h5 className="profileHistoryInspectorTableHeading caption">FROM:</h5>
                <Button variant="text" className="profileHistoryInspectorLink" href={`${BLOCK_EXPLORER_ADDRESS}${transaction?.from}`}>
                  <p className="b2">{isMobile ? truncateAddress(transaction?.from) : transaction?.from}</p>
                  <SVG svg="chain" className="profileHistoryInspectorLinkIcon" />
                </Button>
              </li>

              <li className="profileHistoryInspectorTableRow">
                <h5 className="profileHistoryInspectorTableHeading caption">TO:</h5>
                <Button variant="text" className="profileHistoryInspectorLink" href={`${BLOCK_EXPLORER_ADDRESS}${transaction?.to}`}>
                  <p className="b2">{isMobile ? truncateAddress(transaction?.to) : transaction?.to}</p>
                  <SVG svg="chain" className="profileHistoryInspectorLinkIcon" />
                </Button>
              </li>

              <li className="profileHistoryInspectorTableRow">
                <h5 className="profileHistoryInspectorTableHeading caption">TRANSACTION ID:</h5>
                <Button variant="text" className="profileHistoryInspectorLink" href={`${BLOCK_EXPLORER}${transaction?.id}`}>
                  <p className="b2">{truncateTxId(transaction?.id)}</p>
                  <SVG svg="chain" className="profileHistoryInspectorLinkIcon" />
                </Button>
              </li>
            </ul>
          </div>
        </Grid>
      </Content>
    </Container>,
    document.getElementById(`profileCollectionItem`)
  );
};

export default ProfileHistoryInspector;
