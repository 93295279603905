import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Grid } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 2.5rem 0;
  background: ${({ background }) => `${background}`};
  color: ${({ color }) => `${color}`};

  ${breakpoint(`large-tablet`)} {
    padding: 3.75rem 0 ${({ padded }) => `${padded ? `4rem` : `0`}`};
  }
`;

const Header = styled.header`
  grid-column: 1 / -1;
  margin-bottom: 1.5rem;
  white-space: pre-wrap;

  ${breakpoint(`large-tablet`)} {
    grid-column: 1 / span 6;
    margin-bottom: 0;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: 2 / span 5;
  }
`;

const Content = styled.div`
  grid-column: 1 / -1;
  position: relative;

  ${breakpoint(`large-tablet`)} {
    grid-column: 7 / span 6;
    display: flex;
    justify-content: end;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: 7 / span 5;
  }
`;

/** ============================================================================
 * @component
 */
const CtaHeading = ({ data: { backgroundColour, fontColour, padded, heading, link } }) => (
  <Container className="container narrow" padded={padded} background={backgroundColour?.hex || `transparent`} color={fontColour?.hex || `#000000`}>
    <Grid
      node="article"
      css={css`
        align-items: center;
      `}
    >
      <Header>
        <h2 className="h2">{heading}</h2>
      </Header>

      <Content>
        {link?.url && link?.text && (
          <Button
            css={css`
              width: auto;
              display: inline-block;
            `}
            to={link.url}
            color={backgroundColour?.title?.toLowerCase() === `black` ? `white` : `black`}
            transparent
          >
            <span className="button-text">{link.text}</span>
          </Button>
        )}
      </Content>
    </Grid>
  </Container>
);

export default CtaHeading;
