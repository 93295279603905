import React from "react";
import styled from "@emotion/styled";
import { breakpoint } from "~utils/css";
import { Link, SVG } from "~components";

/** ============================================================================
 * @css
 */

const Container = styled.div`
  color: ${({ colorTheme, disabled }) => {
    if (!disabled) {
      return colorTheme === `light` ? `var(--color-white)` : `var(--color-black)`;
    }
    return colorTheme === `light` ? `var(--color-mono-40)` : `var(--color-mono-60)`;
  }};

  display: inline;
  pointer-events: ${({ disabled }) => (disabled ? `none` : `auto`)};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .menuItemText {
        text-decoration: underline;
      }
    }
  }

  .menuItemComingSoon {
    margin-left: 8px;
  }

  .menuItemIcon {
    width: 1rem;
    height: 1rem;
    margin-left: 0.75rem;

    ${breakpoint(`small-tablet`)} {
      transform: rotate(-45deg);
    }
  }
`;

/** ============================================================================
 * @component
 * @param  {string}    	text   				Button text
 * @param  {boolean}    comingSoon   	Disabled,	shows 'coming soon' text
 * @param  {string}    	url 					External link e.g. 'https://google.com'
 * @param  {string}    	to 						Internal link e.g. '/about'
 * @param  {string}    	colorTheme 		'light' or 'dark'
 * @return {node}
 */

const MenuItem = ({ text, comingSoon, url, colorTheme = `light`, to }) => {
  const isExternalLink = url;

  return (
    <li>
      <Container disabled={comingSoon} className="h3" colorTheme={colorTheme}>
        {isExternalLink && (
          <>
            <Link to={url}>
              <span className="menuItemText">{text}</span>
              <SVG svg="arrowRight" className="menuItemIcon" />
            </Link>
          </>
        )}
        {!isExternalLink && (
          <>
            {(!to && <span className="menuItemText">{text}</span>) || (
              <Link to={to}>
                <span className="menuItemText">{text}</span>
              </Link>
            )}
          </>
        )}
        {comingSoon && <span className="caption menuItemComingSoon">Coming soon</span>}
      </Container>
    </li>
  );
};

export default MenuItem;
