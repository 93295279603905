//
// todo: better, later
// baseline courtesy of https://codepen.io/soulwire/pen/mEMPrK

export const CHARS = `πøµ≤≥∆<>+=-*∫√¢£∆∂≈†¥`;

class TextScramble {
  constructor(el) {
    this.el = el;
    this.chars = CHARS;
    this.write = this.write.bind(this);
  }

  getSymbol() {
    return this.chars[Math.floor(Math.random() * this.chars.length)];
  }

  write() {
    let output = ``;
    let complete = 0;

    for (let i = 0, n = this.queue.length; i < n; i += 1) {
      let { from, to, start, end, char } = this.queue[i];

      if (this.frame >= end) {
        complete += 1;
        output += to;
      } else if (this.frame >= start) {
        if (!char || Math.random() < 0.2) {
          char = this.getSymbol();
          this.queue[i].char = char;
        }

        output += `<span class="dummy">${char}</span>`;
      } else {
        output += from;
      }
    }

    this.el.innerHTML = output;

    if (complete === this.queue.length) {
      this.resolve();
    } else {
      this.frameRequest = requestAnimationFrame(() => setTimeout(this.write, 10));
      this.frame += 1;
    }
  }

  setText(newText) {
    const oldText = this.el.innerText;
    const length = Math.max(oldText?.length, newText?.length);
    const promise = new Promise((resolve) => (this.resolve = resolve));

    this.queue = [];

    for (let i = 0; i < length; i += 1) {
      const from = oldText[i] || ``;
      const to = newText[i] || ``;
      const start = Math.floor(Math.random() * 20);
      const end = start + Math.floor(Math.random() * 40);

      this.queue.push({ from, to, start, end });
    }

    cancelAnimationFrame(this.frameRequest);

    this.frame = 0;
    this.write();

    return promise;
  }
}

export default TextScramble;
