import React, { useEffect, useState } from "react";
import { Button, TextInput } from "~components";
import { useApp } from "~hooks";
import regex from "~constants/regex";
import altrLoginAPI from "~hooks/altrLoginApi";
import LoadingSpinner from "~components/Common/LoadingSpinner";

/** ============================================================================
 * @component
 * @param {boolean} connected - Whether the user is connected to a wallet.
 * @param {string} className - For additional styling config
 * @return {node}
 */
const ProfileUserData = ({ className, userData }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const { updateUserProfile, refreshProfileData } = altrLoginAPI();
  const { reloadProfileData, setReloadProfileData } = useApp();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [formValues, setFormValues] = useState({ name: ``, email: `` });
  const [formErrors, setFormErrors] = useState({ name: false, email: false });
  const [isSubmitting, setIsSubmitting] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  const isFormReadyToSubmit = () => {
    if (isSubmitting) {
      return false;
    }

    if (!formValues.name || !formValues.email || !regex.email.test(formValues.email)) {
      return false;
    }

    if (formValues.name === userData.user?.nickname && formValues.email === userData.user?.emailAddress) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    await updateUserProfile(formValues.email, formValues.name);
    await refreshProfileData();
    setReloadProfileData(!reloadProfileData);

    setIsSubmitting(false);

    return true;
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setFormValues({
      name: userData.user?.nickname ?? ``,
      email: userData.user?.emailAddress ?? ``
    });
  }, [userData?.user?.nickname, userData?.user?.emailAddress]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <form onSubmit={handleSubmit} className={className}>
      <fieldset>
        <TextInput
          required
          className="field"
          label="Full name"
          placeholder="Your Name"
          value={formValues.name}
          error={formErrors.name}
          onBlur={() => {
            if (!formValues.name) {
              setFormErrors((prev) => ({ ...prev, name: true }));
            }
          }}
          onChange={(value) => {
            setFormErrors((prev) => ({ ...prev, name: false }));
            setFormValues((prev) => ({ ...prev, name: value }));
          }}
        />

        <TextInput
          required
          className="field"
          label="Email"
          placeholder="Your Email"
          value={formValues.email}
          error={formErrors.email}
          onBlur={() => {
            if (!regex.email.test(formValues.email)) {
              setFormErrors((prev) => ({ ...prev, email: true }));
            }
          }}
          onChange={(value) => {
            setFormErrors((prev) => ({ ...prev, email: false }));
            setFormValues((prev) => ({ ...prev, email: value }));
          }}
        />
      </fieldset>
      <Button className="settingsSubmit" disabled={!isFormReadyToSubmit()} buttonType="submit" variant="secondary" colorTheme="light" fluid>
        {isSubmitting && <LoadingSpinner className="loader" isInline />}
        <span>Save</span>
      </Button>
    </form>
  );
};

export default ProfileUserData;
