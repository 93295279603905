import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { LOCALSTORAGE_KEY } from "~constants";
import { breakpoint } from "~utils/css";
import { isBrowser } from "~utils/helpers";
import { useEffect, useState } from "react";
import { SVG, Button } from "~components";
import altrLoginAPI from "~hooks/altrLoginApi";
import useMarketing from "~hooks/useMarketing";
import useApp from "~hooks/useApp";

const Container = styled.div`
  transition:
    opacity 0.3s var(--cubic-easing),
    transform 0.3s var(--cubic-easing);

  opacity: ${({ active }) => `${active ? 1 : 0}`};

  ${({ active }) => {
    if (active) {
      return css`
        pointer-events: auto;
      `;
    }
    return css`
      pointer-events: none;
      * {
        pointer-events: none !important;
      }
    `;
  }};

  transform: scale(${({ active }) => `${active ? 1 : 1.05}`});

  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: center;

  .connectOverlay {
    &CreateWalletButton {
      justify-content: space-between;
    }

    &ConnectButton {
      background: var(--color-primary-orange-100);
      border: none;

      &.disabled {
        color: white;
        background: var(--color-primary-orange-50);
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: var(--color-white);
          background: var(--color-primary-orange-50);
        }
      }
    }
  }
`;

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  background: black;
`;

const Content = styled.article`
  width: calc(100% - 2rem);
  position: relative;
  margin: 0 auto;
  z-index: 10;
  padding: 20px 24px 40px;
  color: var(--color-black);
  box-shadow: 0px -4px 30px rgba(0, 0, 0, 0.35);
  border-radius: 16px;
  overflow: hidden;
  text-align: center;
  background: white;

  ${breakpoint(`large-tablet`)} {
    width: 426px;
  }

  h1 {
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 20px;
  }

  .buttonsContainer {
    width: 50%;
    margin: 10px auto;
    display: flex;
    justify-content: space-around;
  }
`;

const Header = styled.header`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
`;

const CloseButtonInner = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
`;

const EmailSetterOverlay = () => {
  // ---------------------------------------------------------------------------
  // import / hooks
  const { emailSetterActive, setEmailSetterActive, userData, reloadProfileData, setReloadProfileData, setEmailTrigger, emailTrigger, userIsLoggedIn } =
    useApp();
  const { subscribeToAProductDropPage } = useMarketing();
  const { updateUserProfile, refreshProfileData } = altrLoginAPI();
  // ---------------------------------------------------------------------------
  // state / refs
  const [userInterest, setUserInterest] = useState();

  // ---------------------------------------------------------------------------
  // methods
  const setLocalStorageEmailForUser = async () => {
    const entries = Object.entries(userInterest);

    try {
      await Promise.all(
        entries.map(async ([key, value]) => {
          if (key !== `email`) {
            const response = await subscribeToAProductDropPage(...value);
            if (!response?.ok) {
              throw new Error(`Error in subscribeToAProductDropPage. ${response?.statusText}`);
            }
          }
        })
      );

      await updateUserProfile(userInterest?.email, userData?.user?.nickname || null);

      await refreshProfileData();
      setEmailTrigger(!emailTrigger);

      localStorage.removeItem(LOCALSTORAGE_KEY);
    } catch (e) {
      console.error(e);
      alert(`Problem updating your email, please try enter it in the profile page`); // FIXME
    }
    setReloadProfileData(!reloadProfileData);

    setEmailSetterActive(false);
  };

  const removeLocalStorageEntry = () => {
    if (isBrowser()) {
      localStorage.removeItem(LOCALSTORAGE_KEY);
      setEmailSetterActive(false);
    }
  };
  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (isBrowser() && userIsLoggedIn && !userData?.user?.emailAddress) {
      const allUserInterest = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY));
      setUserInterest(allUserInterest);
      if (allUserInterest?.email) {
        setEmailSetterActive(true);
      }
    }
  }, [userIsLoggedIn, userData?.user?.emailAddress]);

  // ---------------------------------------------------------------------------
  // render
  return (
    <Container active={emailSetterActive}>
      <Background />

      <Content>
        <Header>
          <CloseButton type="button" onClick={() => setEmailSetterActive(false)}>
            <CloseButtonInner>
              <SVG
                svg="cross"
                css={css`
                  width: 16px;
                `}
                fill="black"
              />
            </CloseButtonInner>
            <span className="visually-hidden">Close</span>
          </CloseButton>
        </Header>
        <p>Seems you have already visited us</p>
        <p>Is {userInterest?.email} your email?</p>
        <div className="buttonsContainer">
          <Button
            variant="primary"
            colorTheme="dark"
            onClick={() => {
              setLocalStorageEmailForUser();
            }}
          >
            Yes
          </Button>
          <Button
            variant="primary"
            colorTheme="dark"
            onClick={() => {
              removeLocalStorageEntry();
            }}
          >
            No
          </Button>
        </div>
      </Content>
    </Container>
  );
};

export default EmailSetterOverlay;
