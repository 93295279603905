import { walletAddressAreEqual } from "~utils/helpers";
import MarketplaceItemActions from "./MarketplaceItemActions";
import MarketplaceItemActionsOverlays from "./MarketplaceItemActions/MarketplaceItemActionsOverlays";
import MarketplaceItemProvider from "./MarketplaceItemContext";
import MarketplaceItemInfo from "./MarketplaceItemInfo";
import Balances from "~components/Common/Balances";
import Button from "~components/Common/Button";

const MarketplaceItem = ({ product, sellOrder, isOwner, owner, oracle }) => {
  const isOracleSeller = walletAddressAreEqual(sellOrder?.order?.maker, oracle?.walletAddress);

  return (
    <MarketplaceItemProvider nftData={product.nftData}>
      <div id="marketplaceItem" className="bgWrapper">
        <section className="container wide light">
          <div className="header">
            <Button iconLeft="arrowLeft" to="/marketplace">
              Back
            </Button>
            <Balances />
          </div>
          <div className="body">
            <h2 className="visually-hidden">{product.product.productName}</h2>
            <MarketplaceItemInfo product={product} sellOrder={sellOrder} owner={owner} oracle={oracle} isOracleSeller={isOracleSeller} />
            {sellOrder && <MarketplaceItemActions product={product} sellOrder={sellOrder} isOwner={isOwner} oracle={oracle} isOracleSeller={isOracleSeller} />}
          </div>
        </section>
        {sellOrder && <MarketplaceItemActionsOverlays nft={{ enrichedProduct: product }} sellOrder={sellOrder} />}
      </div>
    </MarketplaceItemProvider>
  );
};

export default MarketplaceItem;
