import React from "react";
import { CollectionCard } from "~components";

/** ============================================================================
 * @component
 */
const CollectionCardCluster = ({ data: { header, collections } }) => (
  <div className="bg-wrapper" style={{ "--wrapper-background": `#161616` }}>
    <section className="collectionCardCluster container dark wide" style={{ "--background": `transparent` }}>
      <strong className="tag">Collections</strong>
      <h2 className="h1">{header}</h2>
      <ul>
        {collections.map((collection, index) => (
          <li>
            <CollectionCard key={index} data={collection} />
          </li>
        ))}
      </ul>
    </section>
  </div>
);

export default CollectionCardCluster;
