import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import AppProvider from "~context/AppContext.jsx";
import { ConnectOverlay, Footer, Navbar, HeightDetector, NoJs, Theme, VideoPlayerOverlay, EmailSetterOverlay } from "~components";
import { breakpoint } from "~utils/css";
import { PersistQueryClientProvider, removeOldestQuery } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { createConfig, WagmiProvider, http, deserialize, serialize, fallback } from "wagmi";
import { polygon, polygonAmoy } from "wagmi/chains";
import { QueryClient } from "@tanstack/react-query";
import { isDevelopment } from "~utils/helpers";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { CHAIN_NAMESPACES, UX_MODE, WEB3AUTH_NETWORK } from "@web3auth/base";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { injected, walletConnect } from "wagmi/connectors";
import { Web3AuthConnector } from "@web3auth/web3auth-wagmi-connector";
import { AuthAdapter } from "@web3auth/auth-adapter";

import "~node_modules/modern-normalize/modern-normalize.css";
import "~styles/main.scss";
import NFTOverlayCompletion from "~components/Marketplace/NFTOverlays/NFTOverlayCompletion";

const NavbarContainer = styled.div`
  position: fixed;
  margin: auto;
  width: 100%;
  top: 0.5rem;
  z-index: 81;
  pointer-events: none;
  padding: 0 1rem;

  ${breakpoint(`small-tablet`)} {
    top: 1rem;
    padding: 0 1rem;
  }
`;

/** ============================================================================
 * Web3Auth
 * Interfaces allowing users to connect to the dApp using web2 social auth
 */
const currentChain = isDevelopment ? polygonAmoy : polygon;

const chainConfig = {
  chainNamespace: CHAIN_NAMESPACES.EIP155,
  chainId: `0x${currentChain.id.toString(16)}`,
  rpcTarget: currentChain.rpcUrls.default.http[0],
  displayName: currentChain.name,
  tickerName: currentChain.nativeCurrency.name,
  ticker: currentChain.nativeCurrency.symbol,
  blockExplorer: currentChain.blockExplorers.default.url
};

export const privateKeyProvider = new EthereumPrivateKeyProvider({ config: { chainConfig } });

export const web3AuthInstance = new Web3AuthNoModal({
  clientId: process.env.GATSBY_WEB3_AUTH_CLIENT_ID,
  web3AuthNetwork: WEB3AUTH_NETWORK.CYAN,
  enableLogging: false,
  privateKeyProvider
});

const openloginAdapterInstance = new AuthAdapter({ adapterSettings: { uxMode: UX_MODE.REDIRECT } });
web3AuthInstance.configureAdapter(openloginAdapterInstance);

/** ============================================================================
 * WAGMI
 * React Hooks for Ethereum.
 * https://wagmi.sh/
 */
const transports = [];

const alchemyApiKey = process.env.GATSBY_ALCHEMY_API_KEY;
if (alchemyApiKey) {
  transports.push(http(`https://polygon-${isDevelopment ? `amoy` : `mainnet`}.g.alchemy.com/v2/${alchemyApiKey}`, { timeout: 120_000 }));
}

const infuraApiKey = process.env.GATSBY_INFURA_API_KEY;
if (infuraApiKey) {
  transports.push(http(`https://polygon-${isDevelopment ? `amoy` : `mainnet`}.infura.io/v3/${infuraApiKey}`, { timeout: 120_000 }));
}

const connectors = [
  injected(),
  walletConnect({
    projectId: process.env.GATSBY_WALLETCONNECT_PROJECT_ID,
    showQrModal: true
  }),
  Web3AuthConnector({
    web3AuthInstance,
    loginParams: {
      loginProvider: `google`
    }
  }),
  Web3AuthConnector({
    web3AuthInstance,
    loginParams: {
      loginProvider: `apple`
    }
  }),
  Web3AuthConnector({
    web3AuthInstance,
    loginParams: {
      loginProvider: `twitter`
    }
  }),
  Web3AuthConnector({
    web3AuthInstance,
    loginParams: {
      loginProvider: `discord`
    }
  })
];

const config = createConfig({
  connectors,
  ssr: true,
  transports: {
    [currentChain.id]: fallback(transports, { timeout: 120_000 })
  },
  chains: [currentChain]
});

/** ============================================================================
 * @component Core template wrapper.
 */
const Layout = ({ children, className }) => {
  const pathsWithoutNavbar = [`/playground/`, `/slices/`];
  const pathname = typeof window !== `undefined` ? window.location.pathname : undefined;

  const showNavbar = !pathsWithoutNavbar.includes(pathname);

  const [persister, setPersister] = useState();
  const [reactQueryClient, setReactQueryClient] = useState();

  useEffect(() => {
    if (typeof window !== `undefined`) {
      // Set sync storage persister
      setPersister(
        createSyncStoragePersister({
          serialize,
          storage: window.localStorage,
          deserialize,
          retry: removeOldestQuery
        })
      );

      setReactQueryClient(
        new QueryClient({
          defaultOptions: { queries: { staleTime: 60_000, gcTime: 24 * 3_600_000 } }
        })
      );
    }
  }, []);

  if (!persister || !reactQueryClient) return null;

  return (
    <WagmiProvider config={config}>
      <PersistQueryClientProvider client={reactQueryClient} persistOptions={{ persister }}>
        <AppProvider>
          <NoJs />

          <HeightDetector />

          <Theme />

          {showNavbar && (
            <NavbarContainer>
              <Navbar />
            </NavbarContainer>
          )}

          <main className={className}>{children}</main>

          <ConnectOverlay />
          <EmailSetterOverlay />
          <NFTOverlayCompletion />

          <Footer />

          <VideoPlayerOverlay />
        </AppProvider>
      </PersistQueryClientProvider>
    </WagmiProvider>
  );
};

export default Layout;
