import React from "react";
import { Button, PgWrapper, PgItem } from "~components";
import styled from "@emotion/styled";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  .buttonPGJustifyBetween {
    justify-content: space-between;
  }
`;

const ButtonPG = () => (
  <Container>
    <PgWrapper title="Button" itemWidth="280px">
      <PgItem title="Primary Light">
        <Button colorTheme="light">Click me</Button>
      </PgItem>

      <PgItem title="Primary Dark">
        <Button colorTheme="dark">Click me</Button>
      </PgItem>

      <PgItem title="Primary Light Fluid">
        <Button colorTheme="light" fluid>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Primary Dark Fluid">
        <Button colorTheme="dark" fluid>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Primary Light Disabled">
        <Button colorTheme="light" disabled>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Primary Dark Disabled">
        <Button colorTheme="dark" disabled>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Secondary Light">
        <Button variant="secondary" colorTheme="light">
          Click me
        </Button>
      </PgItem>

      <PgItem title="Secondary Dark">
        <Button variant="secondary" colorTheme="dark">
          Click me
        </Button>
      </PgItem>

      <PgItem title="Secondary Light Fluid">
        <Button variant="secondary" colorTheme="light" fluid>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Secondary Dark Fluid">
        <Button variant="secondary" colorTheme="dark" fluid>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Secondary Light Disabled">
        <Button variant="secondary" colorTheme="light" disabled>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Secondary Dark Disabled">
        <Button variant="secondary" colorTheme="dark" disabled>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Text Light">
        <Button variant="text" colorTheme="light">
          Click me
        </Button>
      </PgItem>

      <PgItem title="Text Dark">
        <Button variant="text" colorTheme="dark">
          Click me
        </Button>
      </PgItem>

      <PgItem title="Text Light Fluid">
        <Button variant="text" colorTheme="light" fluid>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Text Dark Fluid">
        <Button variant="text" colorTheme="dark" fluid>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Text Light Disabled">
        <Button variant="text" colorTheme="light" disabled>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Text Dark Disabled">
        <Button variant="text" colorTheme="dark" disabled>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Feature Light">
        <Button variant="feature" colorTheme="light">
          Click me
        </Button>
      </PgItem>

      <PgItem title="Feature Dark">
        <Button variant="feature" colorTheme="dark">
          Click me
        </Button>
      </PgItem>

      <PgItem title="Feature Light Fluid">
        <Button variant="feature" colorTheme="light" fluid>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Feature Dark Fluid">
        <Button variant="feature" colorTheme="dark" fluid>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Feature Light Disabled">
        <Button variant="feature" colorTheme="light" disabled>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Feature Dark Disabled">
        <Button variant="feature" colorTheme="dark" disabled>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Primary Light (anchor)">
        <Button href="https://www.google.com" colorTheme="light">
          Click me
        </Button>
      </PgItem>

      <PgItem title="Primary Dark (anchor)">
        <Button href="https://www.google.com" colorTheme="dark">
          Click me
        </Button>
      </PgItem>

      <PgItem title="Primary Light Fluid (anchor)">
        <Button href="https://www.google.com" colorTheme="light" fluid>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Primary Dark Fluid (anchor)">
        <Button href="https://www.google.com" colorTheme="dark" fluid>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Primary Light Disabled (anchor)">
        <Button href="https://www.google.com" colorTheme="light" disabled>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Primary Dark Disabled (anchor)">
        <Button href="https://www.google.com" colorTheme="dark" disabled>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Primary Light (Link)">
        <Button to="/" colorTheme="light">
          Click me
        </Button>
      </PgItem>

      <PgItem title="Primary Dark (Link)">
        <Button to="/" colorTheme="dark">
          Click me
        </Button>
      </PgItem>

      <PgItem title="Primary Light Fluid (Link)">
        <Button to="/" colorTheme="light" fluid>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Primary Dark Fluid (Link)">
        <Button to="/" colorTheme="dark" fluid>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Primary Light Disabled (Link)">
        <Button to="/" colorTheme="light" disabled>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Primary Dark Disabled (Link)">
        <Button to="/" colorTheme="dark" disabled>
          Click me
        </Button>
      </PgItem>

      <PgItem title="Icon left dark">
        <Button colorTheme="dark" iconLeft="arrowLeft">
          Click me
        </Button>
      </PgItem>

      <PgItem title="Icon left light">
        <Button colorTheme="light" iconLeft="arrowLeft">
          Click me
        </Button>
      </PgItem>
      <PgItem title="Icon right dark">
        <Button colorTheme="dark" iconRight="arrowRight">
          Click me
        </Button>
      </PgItem>

      <PgItem title="Icon right light">
        <Button colorTheme="light" iconRight="arrowRight">
          Click me
        </Button>
      </PgItem>

      <PgItem title="Both icons dark">
        <Button colorTheme="dark" iconLeft="arrowLeft" iconRight="arrowRight">
          Click me
        </Button>
      </PgItem>

      <PgItem title="Both icons light">
        <Button colorTheme="light" iconLeft="arrowLeft" iconRight="arrowRight">
          Click me
        </Button>
      </PgItem>

      <PgItem title="Justify between primary">
        <Button
          colorTheme="light"
          iconRight="arrowRight"
          fluid
          className="buttonPGJustifyBetween"
        >
          Click me
        </Button>
      </PgItem>

      <PgItem title="Justify between secondary">
        <Button
          colorTheme="light"
          variant="secondary"
          iconRight="arrowRight"
          fluid
          className="buttonPGJustifyBetween"
        >
          Click me
        </Button>
      </PgItem>

      <PgItem title="Justify between text">
        <Button
          colorTheme="light"
          variant="text"
          iconRight="arrowRight"
          fluid
          className="buttonPGJustifyBetween"
        >
          Click me
        </Button>
      </PgItem>

      <PgItem title="Justify between feature">
        <Button
          colorTheme="light"
          variant="feature"
          iconRight="arrowRight"
          fluid
          className="buttonPGJustifyBetween"
        >
          Click me
        </Button>
      </PgItem>
    </PgWrapper>
  </Container>
);

export default ButtonPG;
