import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Button, PriceWidget } from "~components";
import { TARGET_CHAIN, thousandCommas } from "~utils/helpers";
import { useAccount } from "wagmi";
import Link from "~components/Common/Link";
import SwitchChainButton from "~components/Common/SwitchChainButton";

const EXPIRY_HOURS = { "24 hours": 24, "7 days": 168, "15 days": 360 };

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTCheckout = ({
  className = ``,
  heading,
  subheading,
  subheadingVisible = true,
  finalButtonText = `Purchase`,
  totalText = `Total`,
  type = `fragment`,
  excludeKeys = [],
  summaryActive = true,
  nft,
  data,
  execute = () => {},
  executeApproval = () => {},
  approved = false,
  valid = true,
  refetch = () => {},
  prepareError,
  approveLoading,
  actionLoading,
  applyFee = true,
  fee,
  feeSubtract = false
}) => {
  const [formData, setFormData] = useState({ accepted: false });
  const [displayPrice, setDisplayPrice] = useState(`-`);
  const [displayFeeAmount, setDisplayFeeAmount] = useState(`-`);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prev) => ({ ...prev, [name]: checked }));
  };

  const getFormattedExpiryDate = () => {
    const hours = EXPIRY_HOURS[data.expiry] || 0;
    if (!hours) {
      return typeof data.expiry === `string` ? new Date(parseInt(data.expiry) * 1000).toDateString() : `-`;
    }
    const expiryDate = new Date();
    expiryDate.setHours(expiryDate.getHours() + hours);
    return expiryDate.toDateString();
  };

  const { chain } = useAccount();

  useEffect(() => {
    let parsedFeePercentage = fee?.split(`%`)[0];
    if (type === `whole`) {
      const parsedFee = parseFloat(parsedFeePercentage) / 100;
      const price = parseFloat(data?.price);
      const feeAmount = price * (feeSubtract ? -parsedFee : parsedFee);
      const totalPrice = price + feeAmount;
      setDisplayFeeAmount(feeAmount.toFixed(2));
      setDisplayPrice(thousandCommas(totalPrice ? totalPrice.toFixed(2) : `-`));
    } else {
      const pricePerFragment = parseFloat(data?.pricePerFragment);
      const fragments = parseInt(data?.fragments);
      const totalPrice = pricePerFragment * fragments;
      if (!applyFee) {
        parsedFeePercentage = 0;
      }
      const finalPrice = totalPrice + (totalPrice * parseFloat(parsedFeePercentage)) / 100;
      setDisplayPrice(thousandCommas(finalPrice ? finalPrice.toFixed(2) : `-`));
    }
  }, [data?.price, data?.fragments, data?.pricePerFragment, fee]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!approved) {
      try {
        await executeApproval();

        refetch();
      } catch (error) {
        console.error(error);
      }
    } else {
      execute();
    }
  };

  return (
    <>
      {(!!heading || !!summaryActive) && (
        <section className={cn(`nftEntry checkout`, className)}>
          {!!heading && <h3>{heading}</h3>}
          {!!summaryActive && (
            <section className="summary">
              <h4 className="caption">Summary</h4>
              <div className="summaryValues">
                {data &&
                  Object.keys(data)
                    .sort((a, b) => a - b)
                    .map((key) => {
                      if (excludeKeys.includes(key) || (type === `whole` && key.toLowerCase().includes(`fragment`))) {
                        return null;
                      }

                      let label = ``;

                      switch (key) {
                        case `expiry`:
                          label = `Offer Valid until `;
                          break;

                        case `fragments`:
                          label = `No. of fragments`;
                          break;

                        case `pricePerFragment`:
                          label = `Price per fragment`;
                          break;

                        case `price`:
                          label = `${feeSubtract ? `Listed ` : `Offer `}${key}`;
                          break;

                        default:
                          label = key;
                      }

                      const value = key === `expiry` ? getFormattedExpiryDate() : key === `pricePerFragment` ? `${thousandCommas(data[key])} USDt` : data[key];

                      return (
                        <dl key={`nft-offer-control-${key}`} className="caption">
                          <dt>{label}</dt>
                          <dd>{value || `-`}</dd>
                        </dl>
                      );
                    })}
                {data.price && (
                  <dl key="nft-offer-control-fee" className="caption">
                    <dt>{`Platform fee (${fee}%)`}</dt>
                    <dd>{displayFeeAmount}</dd>
                  </dl>
                )}
              </div>
            </section>
          )}
          {!!summaryActive && (
            <div className="summary">
              <h4 className="caption">{totalText}</h4>
              <div className="summaryPrice">
                <PriceWidget displayPrice={displayPrice} color="white" enrichedProduct={nft?.enrichedProduct} fontClass="h2 summaryPriceValue" />
              </div>
            </div>
          )}
        </section>
      )}
      <section className="nftEntry controls">
        <form onSubmit={handleSubmit}>
          <label>
            <input name="accepted" type="checkbox" checked={formData.accepted} onChange={handleCheckboxChange} />
            <span className="caption">
              Accept <Link to={`/terms-and-conditions/nft/${nft?.enrichedProduct?.product?.identifier}`}>NFT terms and conditions</Link>
            </span>
          </label>
          {subheadingVisible && <p className="notice b2">{subheading}</p>}
          {TARGET_CHAIN?.id !== chain?.id ? (
            <SwitchChainButton fluid variant="feature" colorTheme="light" />
          ) : (
            <div className="buttons flexGrid">
              <Button
                className={cn(approveLoading && `loading`, approved && `success`)}
                buttonType="submit"
                fluid
                variant="primaryTall"
                disabled={formData?.accepted !== true || !!approved}
              >
                <span className="b1">{!approved ? `Approve` : `Approved`}</span>
              </Button>
              <Button
                className={cn(actionLoading && `loading`)}
                buttonType="submit"
                fluid
                variant="primaryTall"
                colorTheme="dark"
                disabled={!formData.accepted || !valid || prepareError || !approved}
              >
                <span className="b1">{prepareError || finalButtonText}</span>
              </Button>
            </div>
          )}
        </form>
      </section>
    </>
  );
};

export default NFTCheckout;
