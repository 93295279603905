import { Nft } from "alchemy-sdk";

export enum Category {
  ALL = "all",
  WATCHES = "watches",
  CARS = "cars"
}

export const getTokenType = (token: Nft) => {
  if (token?.raw?.metadata?.attributes?.reduce((acc: boolean, attribute: Record<string, string>) => acc || attribute.trait_type === "Horsepower", false)) {
    return Category.CARS;
  }
  if (token?.raw?.metadata?.attributes?.reduce((acc: boolean, attribute: Record<string, string>) => acc || attribute.trait_type === "Clasp", false)) {
    return Category.WATCHES;
  }

  return null;
};
