import React from "react";
import { TransactionHistoryTable, PgWrapper, PgItem } from "~components";

const data = [
  {
    date: `01/01/21`,
    time: `12:00 UTC`,
    productName: `Rolex Daytona`,
    fractions: `20`,
    vendor: `The Watch Boutique`,
    from: `0x1234567890`,
    to: `0x1234567890`,
    transactionType: `TKTK`,
    price: `100,000 USDt`
  },
  {
    date: `01/01/21`,
    time: `12:00 UTC`,
    productName: `Rolex Daytona`,
    fractions: `20`,
    vendor: `The Watch Boutique`,
    from: `0x1234567890`,
    to: `0x1234567890`,
    transactionType: `TKTK`,
    price: `100,000 USDt`
  }
];

const TransactionHistoryTablePG = () => (
  <>
    <PgWrapper title="TransactionHistoryTable">
      <PgItem>
        <div style={{ background: `#2C2C2C`, padding: 16 }}>
          <TransactionHistoryTable transactionHistoryItems={data} />
        </div>
      </PgItem>
    </PgWrapper>
  </>
);

export default TransactionHistoryTablePG;
