import React from "react";
import styled from "@emotion/styled";
import { Grid, Link, InfoBlock } from "~components";
import { breakpoint, hoverable } from "~utils/css";
import { useOracle } from "~hooks";

/** ============================================================================
 * @css
 */

const Container = styled.section`
  background: top / 100% no-repeat ${({ background }) => (background ? `url(${background});` : ``)} var(--color-beige);
  padding: 10rem 1rem; 3rem;

  ${breakpoint(`tablet`)} {
    padding: 10rem 10.25rem 3rem;
  }

  .info-block {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    margin-bottom: 5rem;

    ${breakpoint(`large-tablet`)} {
      padding-top: 11.25rem;
      margin-bottom: 10rem;
    }
  }

  .oracleAtf {
    &Grid {
      grid-template-columns: repeat(10, minmax(0, 1fr));
    }

    &Title {
      grid-column: 1/-1;
      text-align: center;
      margin-bottom: 2.5rem;
    }

    &ImageContainer {
      grid-column: 1/-1;
      width: 100%;
      max-width: 100%;
      margin-bottom: 40px;
      border-radius: 0.75rem;
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black); // Need for safari rounded corners ¯\_(ツ)_/¯
    }

    &Image {
      width: 100%;
      max-width: 100%;
    }

    &Description {
      margin-bottom: 40px;
      grid-column: 1/-1;
      line-height: 1.25;

      ${breakpoint(`small-desktop`)} {
        grid-column: 1/5;
        margin-bottom: 0;
      }
    }

    &InfoItems {
      @keyframes fade-in {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }

      animation: fade-in 1s;
      grid-column: 1/-1;

      ${breakpoint(`small-desktop`)} {
        grid-column: 6/-1;
      }
    }
  }

  .oracleAtfImageContainer {
    margin: 0 auto 2.5rem;
    width: 50%;
  }
`;

/** ============================================================================
 * @component
 */

const OracleInfoCard = ({ sanityOracle }) => {
  const { atfText, image, title, category, badges, link, description, backgroundImage } = sanityOracle;

  const { walletAddress, website, phone, nationality, companyName } = useOracle(sanityOracle.guid, sanityOracle.category?.[0]) || {};

  return (
    <Container background={backgroundImage?.asset.url}>
      <InfoBlock className="info-block" title={title} badges={badges} description={description} image={image} link={link} />
      <Grid className="oracleAtfGrid">
        <p className="h3 oracleAtfDescription">{atfText}</p>
        <ul className="oracleAtfInfoItems">
          {walletAddress && (
            <li>
              <OracleInfoItem label="Wallet Address" value={walletAddress} />
            </li>
          )}
          {website && (
            <li>
              <a href={website} target="_blank" rel="noopener noreferrer">
                <OracleInfoItem label="Website" value={website} />
              </a>
            </li>
          )}
          {phone?.prefix && phone.number && (
            <li>
              <OracleInfoItem label="Phone" value={`${phone.prefix} ${phone.number}`} />
            </li>
          )}
          {companyName && (
            <li>
              <OracleInfoItem label="Company" value={companyName} />
            </li>
          )}
          {nationality && (
            <li>
              <OracleInfoItem label="Nationality" value={nationality} />
            </li>
          )}
          {sanityOracle.infoRows?.map(({ _key, columns }) => (
            <li key={_key}>
              <OracleInfoItem label={columns.at(0)} value={columns.at(1)} />
            </li>
          ))}
          {category.length && (
            <li>
              <OracleInfoList label="Category" list={category} />
            </li>
          )}
        </ul>
      </Grid>
    </Container>
  );
};

export default OracleInfoCard;

/** ============================================================================
 * @sub-component
 */

const InfoItem = styled.div`
  color: var(--color-black);
  border-bottom: 1px solid var(--color-mono-40);
  padding: 0.5rem 0;

  .oracleAtfInfoItem {
    &Label {
      color: var(--color-mono-60);
      margin-bottom: 0.5rem;
    }
    &Value {
      word-wrap: break-word;
    }
  }

  ${hoverable} {
    a:hover,
    button:hover {
      text-decoration: underline;
    }
  }

  ${breakpoint(`small-desktop`)} {
    display: grid;
    grid-template-columns: repeat(5, 1fr);

    .oracleAtfInfoItem {
      &Label {
        grid-column: 1/3;
        margin-bottom: 0;
      }
      &Value {
        grid-column: 3/-1;
      }
    }
  }
`;

const OracleInfoItem = ({ label, value }) => (
  <InfoItem>
    <h3 className="caption oracleAtfInfoItemLabel">{label}</h3>
    <div className="tag oracleAtfInfoItemValue">{value}</div>
  </InfoItem>
);

const OracleInfoList = ({ label, list }) => (
  <InfoItem key={label}>
    <h3 className="caption oracleAtfInfoItemLabel">{label}</h3>
    <div className="tag oracleAtfInfoItemValue">
      {list.map((value, index) => (
        <span key={`oracle-info-${index}`}>
          <span>{index ? `, ` : ``}</span>
          <Link key={`oracle-category-${index}`} to={`/oracles/?category=${value}`}>
            {value}
          </Link>
        </span>
      ))}
    </div>
  </InfoItem>
);
