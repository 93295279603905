import React, { useMemo, useState } from "react";
import { Link, navigate } from "gatsby";
import { formatStablecoin, humanizeDuration, TARGET_CHAIN } from "~utils/helpers";
import cn from "classnames";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import Button from "~components/Common/Button";
import MarketplaceItemActionDrawer from "../../MarketplaceItemActionDrawer";
import PriceWidget from "~components/Product/PriceWidget";
import { blockchainHooks } from "~hooks/blockchainHooks";
import useApp from "~hooks/useApp";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { handleError } from "~utils/error";
import SwitchChainButton from "~components/Common/SwitchChainButton";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";
import useWindowDimensions from "~hooks/useWindowDimensions";
import { UserPurchaseabilityStatus } from "~dApp/models/profile/UserPurchaseabilityStatus";

const BuyAction = ({ product, sellOrder, isOracleSeller }) => {
  const [tokenApproved, setTokenApproved] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [executing, setExecuting] = useState(false);

  const {
    userData: {
      balances: { nativeBalance, usdtBalance }
    },
    setOverlayCompletionData,
    setActiveOverlay,
    userIsLoggedIn,
    setConnectorActive,
    connectorActive,
    forceUpdate,
    update,
    getConfig,
    kycData
  } = useApp();
  const { useManage0xTokenAllowance, useGetVaultServiceDeadline } = blockchainHooks();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { trader } = useExternalIntegrations();
  const { isMobile, isTablet } = useWindowDimensions();

  const {
    price: { priceWithFees, displayPriceWithMakerFee, displayPriceWithFees, takerFeePercentage },
    eta
  } = useParseTraderOrder(sellOrder.order);

  const hasBalance = usdtBalance?.value >= priceWithFees;
  const displayMobileButton = isMobile || isTablet;
  const isKycd = kycData.kycStatus === UserPurchaseabilityStatus.Whitelisted;

  const exchangeProxyAddress = useMemo(() => (isOracleSeller ? sellOrder.order.taker : null), [isOracleSeller, sellOrder.order.taker]);

  const { refetch } = useManage0xTokenAllowance(product, Number(formatStablecoin(priceWithFees)), setTokenApproved, false, {
    exchangeProxyAddress
  });
  const { data: vaultServiceDeadline } = useGetVaultServiceDeadline(product.nftData);
  const { chain } = useAccount();

  const handleMakeAnOffer = () => {
    setActiveOverlay(`NFTOverlayOfferWhole`);
  };

  const handleBuy = () => {
    setActiveOverlay(`NFTOverlayPurchaseWholeFromSellOrder`);
  };

  const handleApprove = async () => {
    setExecuting(true);
    const traderSdk = trader(publicClient, walletClient);
    try {
      await traderSdk.approveTokenOrder(sellOrder, { exchangeProxyAddress });

      refetch();
    } catch (e) {
      console.error(e);
      handleError(e, setOverlayCompletionData, nativeBalance?.value);
    }
    setExecuting(false);
  };

  const handleBuyNow = async () => {
    setExecuting(true);
    const traderSdk = trader(publicClient, walletClient);
    const config = await getConfig();
    try {
      await traderSdk.fillOrder(sellOrder, {}, config);
      navigate(`/purchase-confirmation/${product.product.identifier}/`);
    } catch (e) {
      console.error(e);
      handleError(e, setOverlayCompletionData, nativeBalance?.value);
    }
    setExecuting(false);
  };

  return (
    <MarketplaceItemActionDrawer>
      <section className="buy">
        <span className="buyHeading">
          <h3>Buy</h3>
          <dl>
            <dt>Expiration</dt>
            <dd>
              <span>{eta}</span>
            </dd>
          </dl>
        </span>
        <p className="notice">Includes insurance, storage for {humanizeDuration(Number(vaultServiceDeadline) * 1000 - Date.now())}.</p>
        <div className="buyTable">
          <dl>
            <dt>Listing price</dt>
            <dd>
              <PriceWidget color="white" fontClass="h4" currencyVariant="secondary" displayPrice={displayPriceWithMakerFee} />
            </dd>
          </dl>
          <dl>
            <dt>After fees ({takerFeePercentage}%)</dt>
            <dd>
              <PriceWidget color="white" fontClass="h4" currencyVariant="secondary" displayPrice={displayPriceWithFees} />
            </dd>
          </dl>
        </div>

        {!(isMobile || isTablet) && userIsLoggedIn && TARGET_CHAIN?.id === chain?.id && (!isOracleSeller || (isOracleSeller && isKycd)) && (
          <label>
            <input name="accepted" onChange={() => setIsTermsChecked((prev) => !prev)} type="checkbox" />
            <span className="caption">
              Accept <Link to={`/terms-and-conditions/nft/${product.product.identifier}`}>terms and conditions</Link>
            </span>
          </label>
        )}

        {!userIsLoggedIn && (
          <Button variant="primaryTall" className={displayMobileButton ? `mobile` : ``} onClick={() => setConnectorActive(!connectorActive)}>
            Sign In
          </Button>
        )}
        {userIsLoggedIn && TARGET_CHAIN?.id !== chain?.id && (
          <SwitchChainButton
            className={displayMobileButton ? `mobile` : `singleButton`}
            fluid
            variant="feature"
            colorTheme="light"
            onSuccess={() => forceUpdate(!update)}
          />
        )}
        {userIsLoggedIn && TARGET_CHAIN?.id === chain?.id && isOracleSeller && !isKycd && (
          <Button variant="primaryTall" fluid onClick={() => navigate(`/profile/`)}>
            Perform Identity Verification
          </Button>
        )}
        {userIsLoggedIn && TARGET_CHAIN?.id === chain?.id && (!isOracleSeller || (isOracleSeller && isKycd)) && (
          <>
            {!hasBalance && (
              <Button className={displayMobileButton ? `mobile` : `singleButton`} variant="primaryTall" fluid onClick={() => navigate(`/profile/balances`)}>
                Top up USDt
              </Button>
            )}
            {hasBalance && (
              <>
                <Button
                  className={cn(executing && !tokenApproved && `loading`)}
                  variant="primaryTall"
                  fluid
                  disabled={tokenApproved || executing}
                  onClick={handleApprove}
                >
                  {tokenApproved ? `Approved` : `Approve`}
                </Button>
                <Button
                  className={cn(executing && tokenApproved && `loading`)}
                  disabled={!isTermsChecked || !tokenApproved}
                  variant="feature"
                  fluid
                  onClick={handleBuyNow}
                >
                  Buy Now
                </Button>
                <Button className="mobile" variant="primaryTall" fluid onClick={handleBuy}>
                  Buy
                </Button>
                <Button className="mobile" variant="feature" fluid onClick={handleMakeAnOffer}>
                  Make an Offer
                </Button>
              </>
            )}
          </>
        )}
      </section>
    </MarketplaceItemActionDrawer>
  );
};

export default BuyAction;
