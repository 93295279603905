import { useEffect, useMemo, useState } from "react";
import { useApp } from "~hooks";
import { useQuery } from "@tanstack/react-query";
import useExternalIntegrations from "~hooks/useExternalIntegrations";

const useInventory = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { api } = useExternalIntegrations();
  const { userData } = useApp();
  const [activeInventoryItem, setActiveInventoryItem] = useState(null);
  const [sorting, setSorting] = useState(`MOST FRAGMENTS`);
  const { data: inventory, isLoading } = useQuery({
    queryKey: [`useInventory`, userData?.address],
    queryFn: async () => api.getNfts(userData),
    enabled: !!userData
  });

  const sortedInventory = useMemo(() => {
    if (!inventory) return null;

    const inventoryToSort = [...inventory];

    switch (sorting) {
      case `MOST FRAGMENTS`:
        inventoryToSort.sort((a, b) => b.balance - a.balance);
        break;
      case `LEAST FRAGMENTS`:
        inventoryToSort.sort((a, b) => a.balance - b.balance);
        break;
      case `MOST RECENT ACQUISITION`:
        inventoryToSort.sort((a, b) => {
          const dateA = new Date(a.timeLastUpdated);
          const dateB = new Date(b.timeLastUpdated);
          return dateB - dateA;
        });
        break;
      case `OLDEST ACQUISITION`:
        inventoryToSort.sort((a, b) => {
          const dateA = new Date(a.timeLastUpdated);
          const dateB = new Date(b.timeLastUpdated);
          return dateA - dateB;
        });
        break;
      default:
        break;
    }

    return inventoryToSort;
  }, [inventory, sorting]);

  useEffect(() => {
    if (activeInventoryItem) {
      setActiveInventoryItem(inventory?.find((item) => item.raw.metadata.productIdentifier === activeInventoryItem.raw.metadata.productIdentifier));
    }
  }, [JSON.stringify(inventory || [])]);

  // ---------------------------------------------------------------------------

  return {
    inventory: sortedInventory,
    isLoading,
    sorting,
    activeInventoryItem,
    setSorting,
    setActiveInventoryItem
  };
};

export default useInventory;
