import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { breakpoint } from "~utils/css";
import { Button, Image } from "~components";
import { GRID_GAP_REM_XS, GRID_GAP_REM_SM, GRID_GAP_REM } from "~components/Common/Theme/Grid/index.jsx";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  position: relative;
  display: grid;
  grid-template-columns: repeat(8, minmax(0, 1fr));
  grid-gap: 0 ${GRID_GAP_REM_XS}rem;
  padding: 5rem 0;
  color: white;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  overflow: hidden;

  // from Figma
  border: 1px solid #4f4f4f;
  backdrop-filter: blur(50px);
  //

  ${breakpoint(`small-tablet`)} {
    grid-template-columns: repeat(8, minmax(0, 1fr));
    grid-gap: 0 ${GRID_GAP_REM_SM}rem;
  }

  ${breakpoint(`large-tablet`)} {
    grid-template-columns: repeat(8, minmax(0, 1fr));
    grid-gap: 0 ${GRID_GAP_REM}rem;
  }

  .nftPurchaseCard {
    &Image {
      grid-column: 2 / span 6;
      margin-bottom: 5rem;
      padding: 1.5rem;

      // from Figma
      background: rgba(240, 240, 240, 0.1);
      box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(25px);
      border-radius: 20px;
      //

      ${breakpoint(`tablet`)} {
        grid-column: 3 / span 4;
      }

      &Subtitle {
        margin-bottom: 0.25rem;
        color: var(--color-mono-40);
      }

      &Title {
        margin-bottom: 1.5rem;
        color: var(--color-white);
      }

      &Figure {
        width: 100%;
        aspect-ratio: 0.828;
        position: relative;
        display: block;

        // from Figma
        box-shadow: inset 0px 0px 10px 5px rgba(0, 0, 0, 0.9);
        border-radius: 10px;
        overflow: hidden;
        //
      }
    }

    &Detail {
      grid-column: 2 / span 6;
      text-align: center;
    }

    &Buttons {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 1.5rem 1.5rem;
      margin: 2.5rem 0 0;

      ${breakpoint(`tablet`)} {
        flex-direction: row;
        gap: 0 1.5rem;
      }

      & > div {
        flex: 1 0 auto;
      }
    }
  }
`;

/** ============================================================================
 * @component
 */
const EventPurchaseCard = ({ nft: { enrichedEvent, sanityEvent }, ticketId }) => {
  if (!enrichedEvent) {
    return null;
  }

  return (
    <Container>
      <div className="nftPurchaseCardImage">
        {enrichedEvent?.oracle?.name && <h5 className="nftPurchaseCardImageSubtitle caption">{enrichedEvent.oracle?.name}</h5>}

        {sanityEvent?.title && <h5 className="nftPurchaseCardImageTitle h2">{sanityEvent.title}</h5>}

        <figure className="nftPurchaseCardImageFigure">
          <>
            {enrichedEvent?.ids?.[ticketId - 1] && (
              <Image
                css={css`
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                `}
                image={enrichedEvent?.ids[ticketId - 1]?.image}
              />
            )}
          </>
        </figure>
      </div>

      <div className="nftPurchaseCardDetail">
        <header>
          <h3 className="b1">You now own a</h3>
          <h2 className="h2">{sanityEvent.title} Ticket</h2>
        </header>

        <div className="nftPurchaseCardButtons">
          <Button to="/profile?activeTab=collection" variant="feature" colorTheme="light" fluid>
            <span className="button-text">Go to my NFTs</span>
          </Button>

          <Button to="/" variant="feature" colorTheme="light" fluid>
            <span className="button-text">Back to home</span>
          </Button>
        </div>

        {enrichedEvent?.nftData?.tokenExplorerUrl && (
          <Button href={enrichedEvent.nftData.tokenExplorerUrl} variant="feature" colorTheme="light" fluid>
            <span className="button-text">View transaction on Polygonscan</span>
          </Button>
        )}
      </div>
    </Container>
  );
};

export default EventPurchaseCard;
