import cn from "classnames";
import { PortableText } from "@portabletext/react";
import { CtaButton, Carousel } from "~components";
import { useWindowDimensions } from "~hooks";
import useEmblaCarousel from "embla-carousel-react";
import React, { useEffect, useState } from "react";

const CardWrapper = ({ cardsPerRow: cardsPerRowProp, _key, cards, fontSize }) => (
  <div className="cardsWrapper" key={_key}>
    {cards?.map((card) => {
      const backgroundImage = card.backgroundImage?.asset.url || card.oracle?.image?.asset.url;
      const cardsPerRow = +cardsPerRowProp || 1;
      const styles = {
        "--background": backgroundImage
          ? `linear-gradient(0deg, #000000cc 15.73%, #00000000 40.61%), url(${backgroundImage})`
          : `linear-gradient(180deg, #161616 0%, #010101 100%)`,
        "--min-width": `${100 / (cardsPerRow + 1) + 1}%`,
        "--max-width": `${48.75 / cardsPerRow}rem`,
        "--ratio": cardsPerRow > 1 ? -0.371 * cardsPerRow : 2.4
      };

      return (
        <div key={card._key} className="card" style={styles}>
          <div className={cn(`cardContent`, card.blur && `blur`)}>
            {card.caption && <p className="tag">{card.caption}</p>}
            {card.title && <h3 className={fontSize || card.fontSize || `h1`}>{card.title}</h3>}
            <CtaButton cta={card.link} colorTheme="light" />
          </div>
        </div>
      );
    })}
  </div>
);

const componentsMap = {
  types: {
    linkCardsGroup: ({ value }) => <CardWrapper {...value} />
  },
  block: {
    normal: ({ children }) => children && <p className="description">{children}</p>
  }
};

const MobileCardWrapper = ({ cardsPerRow: cardsPerRowProp, cards, fontSize }) => {
  const { screen } = useWindowDimensions();
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: `start`,
    loop: false,
    slidesToScroll: 1
  });

  // ---------------------------------------------------------------------------
  // context / ref / state
  const [carouselPage, setCarouselPage] = useState(0);
  const [mounted, setMounted] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on(`select`, () => setCarouselPage(emblaApi.selectedScrollSnap()));
  }, [emblaApi]);

  useEffect(() => {
    if (mounted || !screen) {
      return;
    }

    setTimeout(() => {
      setMounted(true);
    }, 100);
  }, [mounted, screen]);

  if (!screen) return null;

  return (
    <>
      <Carousel
        embla={{
          api: emblaApi,
          ref: emblaRef
        }}
        className=""
        slidesPerView={1}
        gap={15}
        slides={() =>
          cards?.map((card) => {
            const backgroundImage = card.backgroundImage?.asset.url || card.oracle?.image?.asset.url;
            const cardsPerRow = +cardsPerRowProp || 1;
            const styles = {
              "--background": backgroundImage
                ? `linear-gradient(0deg, #000000cc 15.73%, #00000000 40.61%), url(${backgroundImage})`
                : `linear-gradient(180deg, #161616 0%, #010101 100%)`,
              "--min-width": `${100 / (cardsPerRow + 1) + 1}%`,
              "--max-width": `${68.75 / cardsPerRow}rem`,
              "--ratio": cardsPerRow > 1 ? -0.371 * cardsPerRow : 2.4
            };

            return (
              <div key={card._key} className="card" style={styles}>
                <div className={cn(`cardContent`, card.blur && `blur`)}>
                  {card.title && <h3 className={fontSize || card.fontSize || `h1`}>{card.title}</h3>}
                  <CtaButton cta={card.link} colorTheme="light" />
                </div>
              </div>
            );
          })
        }
      />
      <div className="dots">
        {cards.map((card, index) => (
          <div key={`dots_${card._key}`} className={cn(`dot`, carouselPage === index && `active`)} />
        ))}
      </div>
    </>
  );
};

const mobileComponentMap = {
  types: {
    linkCardsGroup: ({ value }) => <MobileCardWrapper {...value} />
  }
};

const LinksCardBannerSmall = ({ data: { theme, blur, _rawItems } }) => {
  const { isMobile, windowSize } = useWindowDimensions();
  const [showSlider, setShowSlider] = useState(false);

  useEffect(() => {
    if (windowSize && windowSize.width <= 1140) {
      setShowSlider(true);
    }

    return () => {
      setShowSlider(false);
    };
  }, [windowSize]);

  return (
    <div className={cn(`bg-wrapper`, theme === `light` || theme === `dark-transparent` ? `light` : `dark`)}>
      <section
        className={cn(`linksCardBanner`, `linksCardBannerSmall`, `container`, !isMobile && `narrow`, theme === `light` ? `light` : `dark`)}
        style={{ "--secondary-color": theme === `light` ? `#161616` : undefined }}
      >
        <div className={cn(blur && `frosted-glass-dark`)}>
          <div className="content">
            <PortableText value={_rawItems} components={showSlider ? mobileComponentMap : componentsMap} />
          </div>
        </div>
      </section>
    </div>
  );
};

export default LinksCardBannerSmall;
