import React from "react";
import styled from "@emotion/styled";
import { LinkButton } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-white)` : `var(--color-black)`)};

  .textCtaCluster {
    &Caption {
      margin-bottom: 0.25rem;

      ${breakpoint(`large-tablet`)} {
        margin-bottom: 1.5rem;
      }
    }

    &Title {
      margin-bottom: 1.5rem;

      ${breakpoint(`large-tablet`)} {
        margin-bottom: 3.75rem;
      }
    }

    &Body {
      margin-bottom: 1.5rem;
    }

    &ButtonNoBody {
      margin-top: 1rem;

      ${breakpoint(`large-tablet`)} {
        margin-top: 0;
      }
    }
  }

  ${breakpoint(`large-tablet`)} {
    display: flex;

    > div {
      flex: 1;
    }

    > div:nth-of-type(2) {
      text-align: right;
    }
  }
`;

/** ============================================================================
 * @component
 * @param  {string}    caption 	- Text to display above the title
 * @param  {string}    title 		- Text to display as the title
 * @param  {string}    body 		- Text to display as the body
 * @param  {Link}      ctaLink 	- Button link
 * @param  {string}    colorTheme - `light` or `dark
 * @param  {string}    className 	- Class name to apply to the component
 * @return {node}
 */

const TextCtaCluster = ({ caption, title, body, ctaLink, colorTheme = `light`, className }) => {
  const getLinkData = () => {
    const linkType = ctaLink?.type;

    if (linkType === `linkInternal`) {
      return ctaLink.internalLink;
    }
    if (linkType === `linkExternal`) {
      return ctaLink.externalLink;
    }
    if (linkType === `globalLink`) {
      return ctaLink.globalLink;
    }
    return null;
  };

  return (
    <Container colorTheme={colorTheme} className={className || null}>
      <div>
        <div className="tag textCtaClusterCaption">{caption}</div>
        <h2 className="h1 textCtaClusterTitle">{title}</h2>
        {body && <p className="textCtaClusterBody">{body}</p>}
        {body && getLinkData() && <LinkButton className="textCtaClusterButtonBody" colorTheme={colorTheme} link={getLinkData()} />}
      </div>
      {!body && getLinkData() && (
        <div>
          <LinkButton variant="feature" colorTheme={colorTheme} className="textCtaClusterButtonNoBody" link={getLinkData()} />
        </div>
      )}
    </Container>
  );
};

export default TextCtaCluster;
