import React from "react";
import styled from "@emotion/styled";
import { useApp } from "~hooks";
import { Grid, Image, SVG } from "~components";
import { breakpoint } from "~utils/css";

import loader from "~assets/images/loader.webp";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  transition: 0.5s var(--cubic-easing) opacity;

  opacity: ${({ active }) => `${active ? `1` : `0`}`};
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};

  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: end;
  background: rgba(240, 240, 240, 0.1);
  backdrop-filter: blur(23px);
`;

const Toast = styled.div`
  grid-column: 1 / -1;
  height: 58px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  border-radius: 20px;
  background: var(--color-ux-green-50);
`;

const ToastIcon = styled.div`
  width: 58px;
  height: 58px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }

  svg {
    width: 20px;
  }
`;

const ToastText = styled.div`
  width: calc(100% - 72px);
  height: 58px;
  position: relative;
  padding-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap;

  ${breakpoint(`large-tablet`)} {
    width: calc(100% - 58px);
    padding-left: 0;
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayProcessing = () => {
  const { overlayProcessingData } = useApp();

  return (
    <Container active={overlayProcessingData !== null}>
      <Grid>
        <Toast>
          <ToastIcon>{overlayProcessingData?.icon && <>{overlayProcessingData?.icon === `done` ? <SVG svg="check" /> : <Image image={loader} />}</>}</ToastIcon>
          <ToastText>{overlayProcessingData?.text && <p className="caption">{overlayProcessingData.text}</p>}</ToastText>
        </Toast>
      </Grid>
    </Container>
  );
};

export default NFTOverlayProcessing;
