import React from "react";
import { CollectionCard, PgWrapper, PgItem } from "~components";

const data = {
  image: {
    asset: {
      gatsbyImageData: {
        images: {
          sources: [
            {
              srcSet:
                "/_gatsby/image/ede3aa9fadd166292a297ccab58bfc83/c5034b2cd6543139a26b16d46d27e976/118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.avif?u=https%3A%2F%2Fcdn.sanity.io%2Fimages%2F9npd7b7q%2Fproduction%2F118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.jpg&a=w%3D326%26h%3D265%26fm%3Davif%26q%3D75&cd=f3db020631000f120c9c40002bb6bf73 326w,/_gatsby/image/ede3aa9fadd166292a297ccab58bfc83/c5b30527e58d8a4043554676b9d6b2c0/118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.avif?u=https%3A%2F%2Fcdn.sanity.io%2Fimages%2F9npd7b7q%2Fproduction%2F118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.jpg&a=w%3D652%26h%3D530%26fm%3Davif%26q%3D75&cd=f3db020631000f120c9c40002bb6bf73 652w,/_gatsby/image/ede3aa9fadd166292a297ccab58bfc83/492f34096d08e8d521c030d78ec9ece8/118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.avif?u=https%3A%2F%2Fcdn.sanity.io%2Fimages%2F9npd7b7q%2Fproduction%2F118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.jpg&a=w%3D1304%26h%3D1060%26fm%3Davif%26q%3D75&cd=f3db020631000f120c9c40002bb6bf73 1304w",
              type: "image/avif",
              sizes: "(min-width: 1304px) 1304px, 100vw"
            },
            {
              srcSet:
                "/_gatsby/image/ede3aa9fadd166292a297ccab58bfc83/25260cce2918bb544c925c7c10032f04/118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.webp?u=https%3A%2F%2Fcdn.sanity.io%2Fimages%2F9npd7b7q%2Fproduction%2F118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.jpg&a=w%3D326%26h%3D265%26fm%3Dwebp%26q%3D75&cd=f3db020631000f120c9c40002bb6bf73 326w,/_gatsby/image/ede3aa9fadd166292a297ccab58bfc83/294552f992c42b4953db316c03f584f6/118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.webp?u=https%3A%2F%2Fcdn.sanity.io%2Fimages%2F9npd7b7q%2Fproduction%2F118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.jpg&a=w%3D652%26h%3D530%26fm%3Dwebp%26q%3D75&cd=f3db020631000f120c9c40002bb6bf73 652w,/_gatsby/image/ede3aa9fadd166292a297ccab58bfc83/d163f4c0da2db91326d023a962614332/118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.webp?u=https%3A%2F%2Fcdn.sanity.io%2Fimages%2F9npd7b7q%2Fproduction%2F118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.jpg&a=w%3D1304%26h%3D1060%26fm%3Dwebp%26q%3D75&cd=f3db020631000f120c9c40002bb6bf73 1304w",
              type: "image/webp",
              sizes: "(min-width: 1304px) 1304px, 100vw"
            }
          ],
          fallback: {
            src: "/_gatsby/image/ede3aa9fadd166292a297ccab58bfc83/e51dbe9efe1c2f8a0dc46e78b25801e5/118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.jpg?u=https%3A%2F%2Fcdn.sanity.io%2Fimages%2F9npd7b7q%2Fproduction%2F118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.jpg&a=w%3D326%26h%3D265%26fm%3Djpg%26q%3D75&cd=f3db020631000f120c9c40002bb6bf73",
            srcSet:
              "/_gatsby/image/ede3aa9fadd166292a297ccab58bfc83/e51dbe9efe1c2f8a0dc46e78b25801e5/118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.jpg?u=https%3A%2F%2Fcdn.sanity.io%2Fimages%2F9npd7b7q%2Fproduction%2F118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.jpg&a=w%3D326%26h%3D265%26fm%3Djpg%26q%3D75&cd=f3db020631000f120c9c40002bb6bf73 326w,/_gatsby/image/ede3aa9fadd166292a297ccab58bfc83/ec08d904d33ccefcab24a57cdc60ac3e/118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.jpg?u=https%3A%2F%2Fcdn.sanity.io%2Fimages%2F9npd7b7q%2Fproduction%2F118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.jpg&a=w%3D652%26h%3D530%26fm%3Djpg%26q%3D75&cd=f3db020631000f120c9c40002bb6bf73 652w,/_gatsby/image/ede3aa9fadd166292a297ccab58bfc83/cc6a3e1252a562bae58d53995ade2028/118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.jpg?u=https%3A%2F%2Fcdn.sanity.io%2Fimages%2F9npd7b7q%2Fproduction%2F118b001596c3917dc6593299169a9a8da9fae9c1-1304x1060.jpg&a=w%3D1304%26h%3D1060%26fm%3Djpg%26q%3D75&cd=f3db020631000f120c9c40002bb6bf73 1304w",
            sizes: "(min-width: 1304px) 1304px, 100vw"
          }
        },
        layout: "constrained",
        width: 1304,
        height: 1060,
        placeholder: {
          fallback:
            "data:image/jpg;base64,/9j/2wBDAAYEBQYFBAYGBQYHBwYIChAKCgkJChQODwwQFxQYGBcUFhYaHSUfGhsjHBYWICwgIyYnKSopGR8tMC0oMCUoKSj/2wBDAQcHBwoIChMKChMoGhYaKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCgoKCj/wAARCAARABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAYHCAT/xAAsEAABAwMCAwYHAAAAAAAAAAABAgMEAAUGBxESEyEUIjVRcoEjMTI0QWFx/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgME/8QAGBEBAQEBAQAAAAAAAAAAAAAAAAERAhL/2gAMAwEAAhEDEQA/AL3Ps2Zh3RqzRJ6Ia3x3pQUN0j36Vz9kl/etOWTGZdxcntNuhtiQDvxA/np0qony4VpzKYvImWJ8hLI5BA3aUSnyNSzOTW+RZJdqk2FpVxdc+E+lsdzyArL6tVx706rXizDslstaZEf6+YCVcRPz9/1StL0u03cGJMqlhsOuOKXsodQDtSlIOsW1P++T/BVFot4gfQaUo8k61s3hrHpFKUqov//Z"
        }
      }
    }
  },
  slug: {
    current: `slug`
  },
  comingSoon: false,
  title: `Card Title`
};

const CollectionCardPG = () => (
  <PgWrapper title="CollectionCard" itemWidth="400px">
    <PgItem title="Default">
      <CollectionCard data={{ ...data }} />
    </PgItem>

    <PgItem title="Coming Soon">
      <CollectionCard data={{ ...data, comingSoon: true }} />
    </PgItem>
  </PgWrapper>
);

export default CollectionCardPG;
