import React from "react";
import cn from "classnames";
import { Link } from "gatsby";
import { SVG } from "~components";

const variantToClass = {
  primary: `primary`,
  primaryTall: `primary tall`,
  secondary: `secondary`,
  feature: `secondary tall`,
  text: `text`
};

/** ============================================================================
 * @component
 * @param  {string}    variant   				"primary" | "primaryTall" | "secondary" | "text" | "feature"
 * @param  {string}    colorTheme   		"light" | "dark"
 * @param  {boolean}   fluid   					Whether button fills width of container
 * @param  {string}    buttonType   		Usually one of "button" or "submit"
 * @param  {string}    className    		Can be an identifier, Emotion CSS, or both
 * @param  {function}  onClick      		Click listener function
 * @param  {string}  	 to      					Used for internal links
 * @param  {string}  	 href      				Used for external links
 * @param  {boolean}   newTab      			Open external link in new tab
 * @param  {boolean}   disabled     		Disable button
 * @param  {node}      children     		Button text, icons if required
 * @param  {node}      iconLeft     		Icon to render on left side
 * @param  {node}      iconRight     		Icon to render on right side
 * @param  {boolean}   readOnly     		Button for display purposes only
 * @return {node}
 */

const Button = ({
  variant = `primary`,
  colorTheme = `light`,
  fluid,
  buttonType = `button`,
  className,
  onClick,
  to,
  href,
  newTab = true,
  disabled,
  children,
  iconLeft,
  iconRight,
  readOnly,
  style
}) => {
  let BtnElement;
  if (readOnly) BtnElement = `div`;
  else if (to) BtnElement = Link;
  else if (href) BtnElement = `a`;
  else BtnElement = `button`;

  return (
    <BtnElement
      className={cn(`buttonContainer`, className, colorTheme, variantToClass[variant], fluid && `fluid`, disabled && `disabled`, readOnly && `readOnly`)}
      type={BtnElement === `button` ? buttonType : undefined}
      href={href}
      target={href ? (newTab ? `_blank` : `_self`) : null}
      rel={href ? `noreferrer noopener` : null}
      to={to}
      disabled={disabled}
      onClick={() => onClick && onClick()}
      tabIndex={disabled ? `-1` : 0}
      style={style}
    >
      {iconLeft && (
        <span className="icon left">
          <SVG svg={iconLeft} />
        </span>
      )}
      {children}
      {iconRight && (
        <span className="icon right">
          <SVG svg={iconRight} />
        </span>
      )}
    </BtnElement>
  );
};

export default Button;
