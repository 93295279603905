import React from "react";
import styled from "@emotion/styled";
import cn from "classnames";
import { breakpoint } from "~utils/css";
import { Grid } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-white)` : `var(--color-black)`)};

  .infoRowPartition {
    border-top: 1px solid;
    border-color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-white)` : `var(--color-black)`)};
    margin-bottom: 22px;
  }

  span {
    display: block;
  }

  .infoRow {
    &Header {
      margin-bottom: 0.5rem;
      grid-column: 1/-1;
    }
    &Caption {
      margin-bottom: 1.25rem;
      grid-column: 1/-1;
    }
    &Text {
      grid-column: 1/-1;
    }
  }

  ${breakpoint(`large-tablet`)} {
    .infoRow {
      &Header {
        margin-bottom: 0;
        grid-column: 1/3;
      }
      &Caption {
        margin-bottom: 0;
        grid-column: 3/8;
      }
      &Text {
        grid-column: 8/-1;
      }
    }
  }
`;

/** ============================================================================
 * @component
 * @param  {string}    step   				Largest text e.g. "01"
 * @param  {string}    title   				Underneath header, prompt or call to action
 * @param  {string}    description  	Longer, descriptive text
 * @param  {string}    colorTheme 		"light" or "dark"
 * @param  {string}    className 			Additional class names
 * @return {node}
 */

const infoRow = ({ step, title, description, colorTheme, className }) => (
  <Container className={cn(className)} colorTheme={colorTheme}>
    <div className="infoRowPartition" />
    <Grid>
      <span className="d1 infoRowHeader">{step}</span>
      <span className="tag infoRowCaption">{title}</span>
      <p className="b2 infoRowText">{description}</p>
    </Grid>
  </Container>
);

export default infoRow;
