import styled from "@emotion/styled";
import { breakpoint } from "~utils/css";

const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  font-family: "Sohne Leicht", sans-serif;
  gap: 2.5rem;
  padding: 2.5rem 2rem;

  ${breakpoint(`tablet`)} {
    padding: 4.125rem 8rem;
  }

  & > * {
    flex: 1 0 calc(50% - 10rem);
  }

  table {
    border-collapse: collapse;
  }

  tr {
    ${breakpoint(`tablet`, `max`)} {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #919191;
      padding: 0.5rem 0;
    }
  }

  td {
    padding: 0.5rem 0;
    border-bottom: 1px solid #919191;

    ${breakpoint(`tablet`, `max`)} {
      border-bottom: 0;
      padding: 0;
    }

    &:nth-of-type(1) {
      color: #4f4f4f;
      padding-right: 1.5rem;

      ${breakpoint(`tablet`, `max`)} {
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
      }
    }
  }
`;

const InfoTable = ({ data }) => (
  <Container>
    <p className="h3">{data.description}</p>
    <table>
      <tbody>
        {data.rows.map((row) => (
          <tr>
            {row.columns.map((column) => (
              <td className="tag">{column}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </Container>
);

export default InfoTable;
