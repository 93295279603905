import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NFTStatusDot } from "~components";
import { breakpoint } from "~utils/css";
import { ticketContractHooks } from "~hooks/ticketContractHook";

const Container = styled.div`
  position: relative;

  .nftStatus {
    margin-bottom: 1rem;
    ${breakpoint(`large-tablet`)} {
      margin-bottom: 0;
    }

    padding: 0.375rem 0.5rem;
    display: flex;
    align-items: center;
    background: #e1e1e1;
    border-radius: 4px;
    text-transform: uppercase;

    &Dot {
      margin: 0 4px;
    }

    &Info {
      width: 0.75rem;
      height: 0.75rem;
      position: relative;
      display: block;
      margin: 0 0 0 4px;
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const EventStatusBadge = ({ className = ``, nftStatus, enrichedEvent }) => {
  // ---------------------------------------------------------------------------
  // methods
  const [displayDate, setDisplayDate] = useState(null);
  const { useGetClosingTime } = ticketContractHooks();

  const { data: closingTime } = useGetClosingTime(enrichedEvent?.contractAddress);

  useEffect(() => {
    const date = new Date(parseInt(closingTime?.mul(1000).toString()));
    setDisplayDate(
      `${date.toLocaleString(`en-GB`, {
        day: `numeric`,
        month: `long`,
        year: `numeric`,
        timeZone: `UTC`,
        timeZoneName: `short`,
        hour: `numeric`,
        minute: `numeric`
      })}`
    );
  }, [closingTime]);

  const getStatusDotHex = () => {
    if (!nftStatus?.status) {
      return ``;
    }

    const { status } = nftStatus;

    switch (status) {
      case `CollectUserInterest`:
      case `Whitelisted`:
        return `#0b9bed`;

      case `OnSale`:
      case `NFTBurned`:
      case `OnFractionsSale`:
        return `#3df988`;

      case `OnFractionsSaleSuccess`:
        return `#919191`;

      default:
        return `#000000`;
    }
  };

  const getStatusText = () => {
    if (!nftStatus?.status) {
      return ``;
    }

    const { status } = nftStatus;

    switch (status) {
      case `CollectUserInterest`:
        return `Waitlist Open`;

      case `Whitelisted`:
        return `Whitelist Open`;

      //

      case `OnSale`:
        return `Sale open until ${displayDate}`;

      case `NFTBurned`:
        return `Claimed`;

      case `OnFractionsSale`:
        return `Fragmentation open utill 5th June 2023`;

      case `OnFractionsSaleSuccess`:
        return `Sold Out`;

      default:
        return ``;
    }
  };

  // ---------------------------------------------------------------------------
  // render

  if (!nftStatus?.status) {
    return null;
  }

  return (
    <>
      <Container className={className}>
        <div className="caption nftStatus">
          <span>STATUS: </span>

          <NFTStatusDot
            id="NFTStatusBadge"
            animate={!nftStatus?.is([`OnFractionsSaleSuccess`, `NFTBurned`])}
            className="nftStatusDot"
            hex={getStatusDotHex()}
          />

          <span>{getStatusText()}</span>
        </div>
      </Container>
    </>
  );
};

export default EventStatusBadge;
