import { useSaleData } from "~hooks";
import { blockchainHooks } from "~hooks/blockchainHooks";

// short-hand helper intended to be run with/after hooks/useSaleData
export const useFragments = (nft) => {
  const { useGetSaleData } = blockchainHooks();

  const { data: saleData } = useGetSaleData(nft?.enrichedProduct?.nftData, false);

  const allSaleData = useSaleData(saleData);

  if (!allSaleData) {
    return {};
  }

  return {
    ...allSaleData
  };
};

export default useFragments;
