import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Image, Link } from "~components";
import { truncateAddress } from "~utils/helpers.js";
import { blockchainHooks } from "~hooks/blockchainHooks";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import useApp from "~hooks/useApp";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  transition:
    color 0.3s var(--cubic-easing),
    background-color 0.3s var(--cubic-easing);

  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  color: var(--color-white);

  ${({ frosted }) =>
    `${frosted ? `background: rgba(255, 255, 255, 0.01);backdrop-filter: blur(100px);` : `background: var(--color-black);color: var(--color-white);`}`};
`;

const CardMedia = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
`;

const CardFigure = styled.figure`
  transition: 0.3s var(--cubic-easing) transform;

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  img {
    border-radius: 16px 16px 0 0;
  }
`;

const CardContent = styled.div`
  position: relative;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const FractionDescriptor = styled.div`
  width: 100%;
  position: relative;
  margin: 1rem auto;
`;

const Caption = styled.h5`
  color: var(--color-white);
`;

/** ============================================================================
 * @component
 */
const InventoryCard = ({ data, frosted = false, nft }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { userData } = useApp();
  const { api } = useExternalIntegrations();

  const ref = useRef();

  const [saleData, setSaleData] = useState(undefined);

  const { useGetFractionsBalance } = blockchainHooks();
  const {
    data: balance,
    isError: isBalanceError,
    isLoading: isBalanceLoading,
    isIdle: isBalanceIdle
  } = useGetFractionsBalance(nft?.enrichedProduct?.nftData, userData?.address);

  useEffect(() => {
    const getSaleData = async () => {
      const res = await api.getFractionalizedOrderDetailBySaleGuid(data.product.saleGuid);
      setSaleData(res);
    };

    getSaleData();
  }, [data?.product?.saleGuid]);

  // ---------------------------------------------------------------------------
  // render

  if (!data?.sanity?.slug?.current) {
    return null;
  }

  return balance >= 0n ? (
    <Container ref={ref} frosted={frosted}>
      <CardMedia>
        <CardFigure>
          {data?.sanity?.image && (
            <Image
              image={data.sanity.image}
              css={css`
                height: 100%;
              `}
            />
          )}
        </CardFigure>
      </CardMedia>

      <CardContent>
        <h4
          css={css`
            text-transform: uppercase;
          `}
          className="caption"
        >
          {data.oracle.companyName}
        </h4>

        <h3
          css={css`
            margin: 0.5rem 0;
            text-transform: uppercase;
          `}
          className="h4"
        >
          {data.sanity.title ?? `${data.product.productName}`}
        </h3>

        {data?.product?.serialNumber && <h4 className="caption">REF {data.product.serialNumber}</h4>}

        <FractionDescriptor>
          <h4 className="caption">FRACTIONS YOU HOLD</h4>
          <h4 className="b1">
            {isBalanceLoading || isBalanceError || isBalanceIdle ? `-` : balance?.toString()}/{saleData?.totalFractions}
          </h4>
        </FractionDescriptor>

        <div>
          <Caption className="caption">NFT ID: {data.nftData.tokenId}</Caption>

          <Caption className="caption">
            NFT COLLECTION:&nbsp;
            <Link
              css={css`
                &:hover {
                  text-decoration: underline;
                  color: var(--color-primary-orange-100);
                }
              `}
              to={data.nftData.contractExplorerUrl}
            >
              {truncateAddress(data.nftData.nftCollectionInfo.collectionAddress)}
            </Link>
          </Caption>
        </div>
      </CardContent>
    </Container>
  ) : (
    `You still don't own any Altr NFT`
  );
};

export default InventoryCard;
