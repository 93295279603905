import React, { useState } from "react";
import Button from "~components/Common/Button";
import useApp from "~hooks/useApp";
import { useMarketplaceItemContext } from "../../MarketplaceItemContext";
import { TARGET_CHAIN, walletAddressAreEqual } from "~utils/helpers";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import { handleError } from "~utils/error";
import PriceWidget from "~components/Product/PriceWidget";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";
import cn from "classnames";
import SwitchChainButton from "~components/Common/SwitchChainButton";

const OfferAction = () => {
  const [executing, setExecuting] = useState(false);

  const { selectedOffer } = useMarketplaceItemContext();
  const { trader } = useExternalIntegrations();
  const {
    setActiveOverlay,
    userData: {
      address,
      balances: { nativeBalance }
    },
    setOverlayCompletionData
  } = useApp();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { chain } = useAccount();

  const handleMakeAnOffer = () => {
    setActiveOverlay(`NFTOverlayOfferWhole`);
  };

  const {
    price: { displayPriceWithTakerFee, displayPriceWithFees },
    eta
  } = useParseTraderOrder(selectedOffer?.order);

  const handleCancelOffer = async () => {
    setExecuting(true);
    const traderSdk = trader(publicClient, walletClient);
    try {
      await traderSdk.cancelOrder(selectedOffer);
      setOverlayCompletionData({
        icon: `check`,
        heading: `Order cancelled`
      });
    } catch (error) {
      handleError(error, setOverlayCompletionData, nativeBalance?.value);
    }
    setExecuting(false);
  };

  const isUserOffer = selectedOffer && selectedOffer.order && walletAddressAreEqual(selectedOffer.order.maker, address);

  return (
    <section className={isUserOffer ? `buy` : `offer`}>
      {!isUserOffer && (
        <>
          <h3>Make an Offer</h3>
          <p>
            Place your offer now and watch for the seller's acceptance.
            <br />
            Remember, the seller can fill the order anytime, so make sure your wallet is funded.
          </p>
          <Button variant="primaryTall" fluid onClick={handleMakeAnOffer}>
            Make an Offer
          </Button>
        </>
      )}

      {isUserOffer && (
        <>
          <h3>Offer Details</h3>
          <dl>
            <dt>Price</dt>
            <dd>
              <PriceWidget color="white" fontClass="h4" currencyVariant="secondary" displayPrice={displayPriceWithTakerFee} />
            </dd>
          </dl>
          <dl>
            <dt>After fees</dt>
            <dd>
              <PriceWidget color="white" fontClass="h4" currencyVariant="secondary" displayPrice={displayPriceWithFees} />
            </dd>
          </dl>
          <dl>
            <dt>Expiration</dt>
            <dd>{eta}</dd>
          </dl>
          {TARGET_CHAIN?.id !== chain?.id ? (
            <SwitchChainButton className="singleButton" fluid variant="feature" colorTheme="light" onSuccess={() => forceUpdate(!update)} />
          ) : (
            <Button className={cn(`singleButton`, executing && `loading`)} variant="primaryTall" fluid onClick={handleCancelOffer}>
              Cancel Offer
            </Button>
          )}
        </>
      )}
    </section>
  );
};

export default OfferAction;
