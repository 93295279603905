import React, { useState } from "react";
import styled from "@emotion/styled";
import cn from "classnames";
import { createPortal } from "react-dom";
import { Button, Tooltip, MtPelerinModal } from "~components";
import { formatCryptoCurrency, governanceWebsite, TARGET_CHAIN, USDT_ADDRESS } from "~utils/helpers";
import NoSSR from "~components/Common/NoSSR";
import useApp from "~hooks/useApp";

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const ProfileTopUp = ({ className }) => {
  const {
    setDisableScroll,
    userData: {
      balances: { nativeBalance, usdtBalance },
      address
    }
  } = useApp();

  const [activeModal, setActiveModal] = useState();

  const handleOpenModal = (modal) => {
    setDisableScroll(true);
    setActiveModal(modal);
  };

  const handleCloseModal = () => {
    setDisableScroll(false);
    setActiveModal(null);
  };

  return (
    <section id="profileBalances" className={cn(`container dark`, className)}>
      {activeModal && (
        <NoSSR>{createPortal(<MtPelerinModal closeModal={handleCloseModal} currency={activeModal} userAddress={address} />, document.body)}</NoSSR>
      )}
      <h2 className="title">Balances</h2>
      <p>Explore your financial overview in this section. View and manage your token balances.</p>
      <p>Easily top up your account through our integrated on-chain and off-chain funding options.</p>
      <ul className="flex-grid">
        <li>
          <dl className="native">
            <dt className="tag">
              <span>POLYGON ({TARGET_CHAIN.nativeCurrency.symbol})</span>
              <Tooltip
                direction="top"
                content={
                  <section className="infoTooltip">
                    <h3>POLYGON ({TARGET_CHAIN.nativeCurrency.symbol})</h3>
                    <p>used to pay network transaction fees on the Polygon blockchain on which Altr is built.</p>
                  </section>
                }
              >
                <span className="info" aria-hidden="true" />
              </Tooltip>
            </dt>
            <dd>{formatCryptoCurrency(nativeBalance?.formatted || 0)}</dd>
          </dl>
          <ButtonContainer>
            <Button className="topUpButton" onClick={() => handleOpenModal(`MATIC`)} variant="primary" colorTheme="light" fluid>
              Ramp Up
            </Button>
            <Button
              href="https://jumper.exchange/gas/?toChain=137&toToken=0x0000000000000000000000000000000000000000"
              className="topUpButton"
              variant="primary"
              colorTheme="dark"
              fluid
            >
              Top Up
            </Button>
          </ButtonContainer>
        </li>
        <li>
          <dl className="usdt">
            <dt className="tag">
              <span>USDt</span>
              <Tooltip
                direction="top"
                content={
                  <section className="infoTooltip">
                    <h3>USDt</h3>
                    <p>stablecoin pegged to the US Dollar, used to pay for goods and services hosted on and provided by Altr.</p>
                  </section>
                }
              >
                <span className="info" aria-hidden="true" />
              </Tooltip>
            </dt>
            <dd>{formatCryptoCurrency(usdtBalance?.formatted || 0)}</dd>
          </dl>
          <ButtonContainer>
            <Button className="topUpButton" onClick={() => handleOpenModal(`USDT`)} variant="primary" colorTheme="light" fluid>
              Ramp Up
            </Button>
            <Button
              href={`https://jumper.exchange/?toChain=137&toToken=${USDT_ADDRESS.toLowerCase()}`}
              className="topUpButton"
              variant="primary"
              colorTheme="dark"
              fluid
            >
              Top Up
            </Button>
          </ButtonContainer>
        </li>
      </ul>
    </section>
  );
};

export default ProfileTopUp;
