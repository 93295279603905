import React, { useEffect, useState } from "react";
import { padNumberTwoDigits as padNumber } from "~utils/helpers";

/** ============================================================================
 * @component
 * @param  {date}    	launchDate  		UTC date object
 * @param  {string}   className   		Additional class names
 * @return {node}
 */

const CountdownClock = ({ launchDate, className }) => {
  const getFormattedTimeUntilLaunchDate = () => {
    const now = new Date();
    const timeUntilLaunchDate = launchDate - now;
    const days = Math.floor(timeUntilLaunchDate / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeUntilLaunchDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeUntilLaunchDate % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeUntilLaunchDate % (1000 * 60)) / 1000);

    return `${days}:${padNumber(hours)}:${padNumber(minutes)}:${padNumber(seconds)}`;
  };

  const [timeUntilLaunchDate, setTimeUntilLaunchDate] = useState(getFormattedTimeUntilLaunchDate());

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeUntilLaunchDate(getFormattedTimeUntilLaunchDate());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return <span className={className || null}>{timeUntilLaunchDate}</span>;
};

export default CountdownClock;
