import walletDataImage from "~assets/images/wallet-data.webp";
import { Button } from "~components";
import { useApp } from "~hooks";

const ProfileWalletConnection = ({ children }) => {
  const { setConnectorActive, userData, isUserDataLoading } = useApp();

  const handleConnectWallet = () => {
    setConnectorActive(true);
  };

  if (userData.user === null && isUserDataLoading) return null;

  if (userData.isConnected) {
    return children;
  }

  return (
    <section className="profileWalletConnection">
      <div className="content">
        <div>
          <h2 className="h3">Signed Out</h2>
        </div>
        <p>Connect your wallet to see your profile</p>
        <Button onClick={handleConnectWallet} variant="secondary" colorTheme="dark" iconRight="arrowRight">
          Sign In
        </Button>
      </div>
      <div className="image" aria-hidden="true" style={{ "--bg-image": `url(${walletDataImage})` }} />
    </section>
  );
};

export default ProfileWalletConnection;
