import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import gsap from "gsap";
import { CryptoScramblerManual } from "~components";
import { useScroll } from "~hooks";

/** ============================================================================
 * @css
 */

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
`;

/** ============================================================================
 * @page
 * Static product routes @ /products/[sanityProduct.slug.current].
 */
const ProductATFText = ({ active, product }) => {
  // ---------------------------------------------------------------------------
  // imports

  const containerRef = useRef();

  const { scrollTop } = useScroll();
  const [rendered, setRendered] = useState(false);

  //

  const initialScramble = {
    subheading: {
      scrambled: true,
      value: product?.oracle?.companyName || ``
    },
    heading: {
      scrambled: true,
      value: product?.sanity?.title || ``
    },
    ref: {
      scrambled: true,
      value: `REF ${
        product?.product?.serialNumber || product?.product?.identifier || ``
      }`
    }
  };

  const [scrambleText, setScrambleText] = useState(
    JSON.parse(JSON.stringify(initialScramble))
  );

  // ---------------------------------------------------------------------------
  // methods

  const getPhrase = (key) =>
    !scrambleText?.[key]?.scrambled ? scrambleText[key].value : ``;

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (active) {
      setTimeout(() => {
        setRendered(true);
      }, 1200);
    }
  }, [active]);

  useEffect(() => {
    if (typeof window === `undefined` || !containerRef?.current) {
      return;
    }

    const boundingRect = containerRef.current.getBoundingClientRect();
    const percentComplete = scrollTop / boundingRect.height;
    const newScramble = JSON.parse(JSON.stringify(scrambleText));

    newScramble.subheading.scrambled = !rendered || percentComplete > 0.05;
    newScramble.heading.scrambled = !rendered || percentComplete > 0.1;
    newScramble.ref.scrambled = !rendered || percentComplete > 0.15;

    setScrambleText(newScramble);

    gsap.to(`.product-atf-text-container`, {
      duration: 0.01,
      opacity: 1 - (percentComplete * 3 < 1 ? percentComplete * 3 : 1),
      y: `-${parseInt(percentComplete * 250)}`,
      ease: `expo.inOut`
    });
  }, [rendered, scrollTop]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container ref={containerRef} className="product-atf-text-container">
      <h3
        css={css`
          height: 18px;
        `}
        className="b2"
      >
        <CryptoScramblerManual phrase={getPhrase(`subheading`)} />
      </h3>

      <h2
        css={css`
          height: 48px;
          margin: 2rem 0;
        `}
        className="h2"
      >
        <CryptoScramblerManual phrase={getPhrase(`heading`)} />
      </h2>

      <h4
        css={css`
          height: 18px;
        `}
        className="b2"
      >
        <CryptoScramblerManual phrase={getPhrase(`ref`)} />
      </h4>
    </Container>
  );
};

export default ProductATFText;
