import React from "react";
import styled from "@emotion/styled";
import { useApp } from "~hooks";
import { Button, Grid, SVG } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  transition: 0.5s var(--cubic-easing) opacity;

  opacity: ${({ active }) => `${active ? `1` : `0`}`};
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};

  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 110;
  display: flex;
  align-items: center;
  background: rgba(240, 240, 240, 0.1);
  backdrop-filter: blur(23px);
`;

const Modal = styled.article`
  grid-column: 1 / -1;
  position: relative;
  background: var(--color-mono-10);
  border: 1px solid var(--color-mono-40);
  border-radius: 1rem;
  backdrop-filter: blur(50px);

  ${breakpoint(`large-tablet`)} {
    grid-column: 4 / span 6;
  }

  ${breakpoint(`small-desktop`)} {
    grid-column: 5 / span 4;
  }

  .nftOverlayCompletion {
    &Close {
      width: 100%;
      height: 3.5rem;
      position: relative;
      padding: 0 0.75rem;
      display: flex;
      align-items: center;
      justify-content: end;

      ${breakpoint(`large-tablet`)} {
        height: 4.75rem;
      }

      button {
        width: 3rem;
        height: 3rem;

        svg {
          width: 0.75rem;
          height: 0.75rem;
        }
      }
    }

    //

    &Content {
      padding: 0 1.5rem 1.75rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      ${breakpoint(`large-tablet`)} {
        padding: 0 2.5rem 1.75rem;
      }
    }

    &Fragments {
      // todo: this needs to be an image export
      padding: 2.5rem 2.5rem 2rem 2.5rem;
      border-radius: 0 0 1rem 1rem;
      overflow: hidden;
      background: #382531;
      color: white;

      p {
        margin: 0.5rem 0 1.5rem;
      }
    }

    &Icon {
      width: 64px;
      height: 64px;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: var(--color-mono-20);
      margin-bottom: 1.5rem;

      svg {
        // todo: adjust height based on icon source
        height: 1.5rem;
      }
    }

    &Heading {
      margin: 1.5rem 0;
    }

    &Body {
      margin: 0 0 2rem;
    }

    &Buttons {
      width: 100%;
    }

    &Button {
      margin-bottom: 0.75rem;

      &Dark {
        background: var(--color-black);
        border-color: var(--color-black);
        color: var(--color-white);

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background: var(--color-mono-70);
            border-color: var(--color-mono-70);
          }
        }
      }
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayCompletion = () => {
  const { setActiveOverlay, overlayCompletionData, setOverlayCompletionData } = useApp();

  const reset = () => {
    setActiveOverlay(null);
    setOverlayCompletionData(null);
  };

  // todo: this needs to:
  // - track which requests have already been sent
  // - be disabled depending on the user's balance
  // - send an email/similar somewhere on init, or from the caller
  //   (e.g. NFTOverlayPurchaseClaim/index.jsx)

  if (!overlayCompletionData) return <></>;

  return (
    <Container active>
      <Grid>
        <Modal>
          <div className="nftOverlayCompletionClose">
            <button type="button" onClick={() => reset()}>
              <span className="visually-hidden">Close</span>
              <SVG svg="cross" fill="black" />
            </button>
          </div>

          <div className="nftOverlayCompletionContent">
            <div className="nftOverlayCompletionIcon">
              <SVG svg={overlayCompletionData?.icon || `mail`} />
            </div>

            {overlayCompletionData?.heading && <h3 className="nftOverlayCompletionHeading h4">{overlayCompletionData.heading}</h3>}

            {overlayCompletionData?.body && <p className="nftOverlayCompletionBody b2">{overlayCompletionData.body}</p>}

            <div className="nftOverlayCompletionButtons">
              {overlayCompletionData?.transactionUrl ? (
                <>
                  <Button
                    href={overlayCompletionData.transactionUrl}
                    className={!overlayCompletionData?.purchaseSubheading ? `nftOverlayCompletionButton nftOverlayCompletionButtonDark` : ``}
                    colorTheme="dark"
                    fluid
                    variant="feature"
                  >
                    <span className="b1">See Transaction on Polygonscan</span>
                  </Button>
                  {!overlayCompletionData?.purchaseSubheading && (
                    <Button
                      onClick={() => reset()}
                      className="nftOverlayCompletionButton nftOverlayCompletionButtonLight"
                      colorTheme="dark"
                      fluid
                      variant="feature"
                    >
                      <span className="b1">Close</span>
                    </Button>
                  )}
                </>
              ) : overlayCompletionData?.action && overlayCompletionData?.actionText ? (
                <>
                  <Button
                    onClick={(e) => {
                      e?.stopPropagation();
                      overlayCompletionData.action();
                    }}
                    colorTheme="dark"
                    fluid
                    variant="feature"
                  >
                    <span className="b1">{overlayCompletionData.actionText}</span>
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => reset()}
                    className="nftOverlayCompletionButton nftOverlayCompletionButtonDark"
                    colorTheme="dark"
                    fluid
                    variant="feature"
                  >
                    <span className="b1">Close</span>
                  </Button>

                  <Button
                    to="/profile/"
                    onClick={() => reset()}
                    className="nftOverlayCompletionButton nftOverlayCompletionButtonLight"
                    colorTheme="dark"
                    fluid
                    variant="feature"
                  >
                    <span className="b1">Go to Profile</span>
                  </Button>
                </>
              )}
            </div>
          </div>

          {overlayCompletionData?.purchaseSubheading && (
            <div className="nftOverlayCompletionContent nftOverlayCompletionFragments">
              <p className="caption">{overlayCompletionData?.purchaseSubheading}</p>

              <div className="nftOverlayCompletionButtons">
                <Button
                  to="/profile/?activeTab=settings"
                  onClick={() => reset()}
                  className="nftOverlayCompletionButton nftOverlayCompletionButtonLight"
                  colorTheme="light"
                  fluid
                  variant="feature"
                >
                  <span className="b1">Go to Profile</span>
                </Button>
              </div>
            </div>
          )}
        </Modal>
      </Grid>
    </Container>
  );
};

export default NFTOverlayCompletion;
