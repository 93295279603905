/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { CategoryTab } from "~components";

/** ============================================================================
 * @css
 */
const Expander = styled.div`
  margin-bottom: 1rem;
  overflow: hidden;
  transition: 0.4s height;
  height: ${({ isOpen, contentHeight }) => (isOpen ? `${contentHeight}px` : `0px`)};
`;

const Content = styled.div`
  padding: 1.5rem 0;

  p {
    white-space: pre-line;
  }

  .infoTabsMobile {
    &StaticImage {
      width: 100%;
    }

    &SummaryTag {
      margin-bottom: 0.75rem;
    }

    &InfoRow {
      &Heading {
        margin-bottom: 1.25rem;
        margin-top: 2.5rem;
      }
      &LabelText {
        border-bottom: 1px solid var(--color-mono-40);
        margin-bottom: 1.25rem;
      }
      &Label {
        color: var(--color-mono-60);
        margin-bottom: 4px;
      }
      &Text {
        margin-bottom: 8px;
      }
    }
  }
`;

/** ============================================================================
 * @component
 */
const InfoTabsMobile = ({ data }) => {
  const initialOpenTabs = {};

  data.forEach((tab) => {
    initialOpenTabs[tab.tabNumber] = false;
  });

  const [tabHeights, setTabHeights] = useState({});
  const tabRefs = useRef([]);

  const [openTabs, setOpenTabs] = useState(initialOpenTabs);

  const calculateContentHeight = () => {
    for (let i = 0; i < data.length; i++) {
      const ref = tabRefs.current[i];

      const refHeight = ref.offsetHeight || 0;

      setTabHeights((prev) => ({
        ...prev,
        [i]: refHeight
      }));
    }
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    calculateContentHeight();

    window.addEventListener(`resize`, calculateContentHeight);

    return () => window.removeEventListener(`resize`, calculateContentHeight);
  }, []);

  // ---------------------------------------------------------------------------
  // render

  return (
    <div>
      {/* 01 Basic Info */}
      {data.map((tab, i) => (
        <div key={i}>
          <CategoryTab
            tabNumber={tab.tabNumber}
            tabTitle={tab.tabTitle}
            selected={openTabs[tab.tabNumber]}
            onClick={() =>
              setOpenTabs((prev) => ({
                ...prev,
                [tab.tabNumber]: !prev[tab.tabNumber]
              }))
            }
            mobile
          />
          <Expander isOpen={openTabs[tab.tabNumber]} contentHeight={tabHeights[i]}>
            <Content
              ref={(el) => {
                tabRefs.current[i] = el;
              }}
            >
              {tab.content.map((contentNode, j) => (
                <div key={j}>
                  {contentNode.type === `text` && (
                    <>
                      <div className="tag infoTabsMobileSummaryTag">{contentNode.subtitle}</div>
                      <p className="b2">{contentNode.content}</p>
                    </>
                  )}

                  {contentNode.type === `image` && <img className="infoTabsMobileStaticImage" src={contentNode.url} alt={tab.tabTitle} />}
                </div>
              ))}
            </Content>
          </Expander>
        </div>
      ))}
    </div>
  );
};

export default InfoTabsMobile;
