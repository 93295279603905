import React from "react";
import styled from "@emotion/styled";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const transitionTime = `1s`;
const rowHeight = `30px`;

const Container = styled.section`
  position: relative;
  height: 100%;
  text-align: end;
  overflow: hidden;
`;

const Scroller = styled.div`
  transition: transform ${transitionTime};
  height: 100%;
`;

const Text = styled.span`
  height: ${rowHeight};
  display: block;
  color: var(--color-white);

  ${breakpoint(`tablet`)} {
    text-align: left;
  }
`;

/** ============================================================================
 * @component
 */
const TextImageChangerScrollingText = ({ text, orchestratedIndex }) => (
  <Container>
    <Scroller style={{ transform: `translateY(-${100 * orchestratedIndex}%)` }}>
      {text.map((textItem, i) => (
        <Text aria-hidden={orchestratedIndex !== i} key={i}>
          {textItem}
        </Text>
      ))}
    </Scroller>
  </Container>
);

export default TextImageChangerScrollingText;
