import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Grid, ImageRatio, Button } from "~components";
import { breakpoint } from "~utils/css";
import { useWindowDimensions } from "~hooks";
import { useMarketplace } from "~hooks/useMarketplace";
import { useConfig } from "wagmi";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  background: var(--color-beige);
  padding: 40px 5px;

  ${breakpoint(`tablet`)} {
    padding: 40px 0;
  }

  .featuredProduct {
    &Header {
      grid-column: 1/-1;
      margin-bottom: 40px;

      ${breakpoint(`tablet`)} {
        text-align: center;
      }
    }

    &Cards {
      grid-column: 1/-1;

      ${breakpoint(`tablet`)} {
        display: flex;
        gap: 1rem;
        justify-content: center;
      }
    }
  }
`;

const Card = styled.div`
  background: var(--color-white);
  padding: 40px 0;
  border-radius: 20px;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 1rem;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .featuredProductCardImageDesktop {
        transform: scale(1.03);
      }
    }
  }

  ${breakpoint(`tablet`)} {
    padding: 1rem;
    ${({ condensed }) => `${condensed ? `width: 33%;` : ``}`};
  }

  .featuredProductCard {
    &Grid {
      grid-gap: 0;
    }
    &Content {
      grid-column: 1/-1;

      ${breakpoint(`tablet`)} {
        grid-column: ${({ condensed }) => `${condensed ? `1/-1` : `5/-1`}`};
        padding: ${({ condensed }) => `${condensed ? `1rem 0 0 0` : `12%`}`};
        display: flex;
      }
      ${breakpoint(`large-tablet`)} {
        grid-column: ${({ condensed }) => `${condensed ? `1/-1` : `7/-1`}`};
      }
    }
    &Caption {
      margin-bottom: 2px;
    }
    &Title {
      margin-bottom: 1.5rem;
    }
    &ImageMobile,
    &ImageDesktopContainer {
      border-radius: 0.75rem;
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black); // Need for safari rounded corners ¯\_(ツ)_/¯
    }
    &ImageMobile {
      margin-bottom: 1.5rem;
      grid-column: 1/-1;

      ${breakpoint(`tablet`)} {
        display: none;
      }
    }
    &ImageDesktopContainer {
      display: none;
      position: relative;
      padding-bottom: 100%;

      ${breakpoint(`tablet`)} {
        display: block;
        grid-column: ${({ condensed }) => `${condensed ? `1/-1` : `1/5`}`};
      }
      ${breakpoint(`large-tablet`)} {
        grid-column: ${({ condensed }) => `${condensed ? `1/-1` : `1/7`}`};
      }
    }
    &ImageDesktop {
      transition: 0.4s;
      position: absolute;
      inset: 0;
    }
    &Text {
      grid-column: 1/-1;
      margin-bottom: 1.5rem;
    }
  }

  ${({ condensed }) => (condensed ? `display: flex; align-items: flex-start;` : ``)}
`;

/** ============================================================================
 * @component
 */
const FeaturedProducts = ({ data }) => {
  const { isMobile } = useWindowDimensions();

  const config = useConfig();

  let { productsOnSale: products } = useMarketplace(config, { oracles: ["dab3b55c-0977-4859-9113-bd1623c6adda"] });

  if (!products || products.length === 0) return;

  products = products.slice(0, 3);

  return (
    <Container>
      {data.header && (
        <Grid>
          <h2 className="featuredProductHeader h3">{data.header}</h2>
        </Grid>
      )}

      <Grid noXPadding={isMobile}>
        <div className="featuredProductCards">
          {products.map((product, i) => (
            <FeaturedProductCard product={product} key={i} condensed={products.length > 1} />
          ))}
        </div>
      </Grid>
    </Container>
  );
};

export default FeaturedProducts;

const FeaturedProductCard = ({ product, condensed }) => {
  const { isMobile } = useWindowDimensions();

  return (
    <Card condensed={condensed}>
      <Grid noXPadding={!isMobile} className="featuredProductCardGrid">
        <div className="featuredProductCardImageDesktopContainer">
          <ImageRatio ratio="1/1" altText={product.product.text} image={product.nftImage} className="featuredProductCardImageDesktop" />
        </div>
        <div className="featuredProductCardContent">
          <div>
            <div className="featuredProductCardTopText">
              <div className="featuredProductCardCaption caption">Featured Product</div>
              <h3 className="featuredProductCardTitle h2 max-2-lines">{product.product.text}</h3>
            </div>
            <ImageRatio ratio="1/1" altText={product.product.text} image={product.nftImage} className="featuredProductCardImageMobile" />
            {/* <p className="featuredProductCardText">{product.product.text}</p> */}
            <Button to={`/marketplace/?oracles=${product.oracle.identifier}`} fluid={isMobile} iconRight="arrowRight" colorTheme="dark">
              View all oracle products
            </Button>
          </div>
        </div>
      </Grid>
    </Card>
  );
};
