import React, { useRef, useState, useEffect } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Link, Image } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  pointer-events: ${({ disabled }) => `${disabled ? `none` : `auto`}`};
  padding-bottom: ${({ paddingBottom }) => `${paddingBottom}`};

  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  background: var(--color-mono-20);
  color: var(--color-mono-90);
  -webkit-mask-image: -webkit-radial-gradient(white, black); // Need for safari rounded corners ¯\_(ツ)_/¯

  .collection-card-text-container {
    ${({ disabled }) => `${disabled ? `opacity:0.6;` : ``}`}
  }

  &:hover {
    .collection-card-aspect-container {
      transform: scale(1.05);
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const Figure = styled.figure`
  width: 100%;
  max-width: none;
  height: 100%;
  position: relative;
  align-self: center;
  flex: 0 0 auto;

  transition: transform 0.3s var(--cubic-easing);
`;

const TextContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: 1.5rem;

  strong,
  h3 {
    display: block;
    color: var(--tertiary-color);
  }
`;

/** ============================================================================
 * @component
 */
const CollectionCard = ({ data: { comingSoon, customComingSoonTitle, image, slug, title, paddingBottom = `125%` } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const ref = useRef();
  const figureRef = useRef();
  // const { deviceAbove } = useDevice();

  // const [pos, setPos] = useState({
  //   x: 0,
  //   y: 0
  // });

  const [imageAspect] = useState(image?.asset?.gatsbyImage ? parseFloat(image.asset.gatsbyImage.width / image.asset.gatsbyImage.height).toFixed(2) : 1);

  const [figureAspect, setFigureAspect] = useState(1);

  // ---------------------------------------------------------------------------
  // variables

  // const imageSrc = getFallbackImageSrc(image);
  // const isMobile = deviceAbove(`small-tablet`) === false;

  // ---------------------------------------------------------------------------
  // methods

  // const relativeCursorHandler = (e) => {
  //   if (!ref?.current || isMobile) return;

  //   const { left, top } = ref.current.parentNode.getBoundingClientRect();

  //   setPos({
  //     x: e.clientX - left,
  //     y: e.clientY - top
  //   });
  // };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!figureRef?.current) {
      return;
    }

    const boundingRect = figureRef.current.getBoundingClientRect();

    setFigureAspect(boundingRect.width / boundingRect.height);
  }, [figureRef]);

  // ---------------------------------------------------------------------------
  // render

  const innerJSX = (
    <Container
      ref={ref}
      disabled={comingSoon}
      paddingBottom={paddingBottom}
    // onMouseMove={relativeCursorHandler}
    >
      <ImageContainer>
        <Figure
          ref={figureRef}
          aspect={imageAspect}
          figureAspect={figureAspect}
          className="collection-card-aspect-container"
        // onMouseMove={comingSoon ? () => {} : relativeCursorHandler}
        >
          {/* <RippleImage
            id={`collection-card-ripple-${uuid()}`}
            imageUrl={imageSrc}
            pos={pos}
          /> */}
          <Image
            image={image}
            css={css`
              height: 100%;
            `}
          />
        </Figure>
      </ImageContainer>

      <TextContainer className="collection-card-text-container">
        <header>
          <strong className="caption">COLLECTION</strong>
          <h3
            css={css`
              margin: 0.5rem 0 1.5rem;
            `}
            className="h4"
          >
            {comingSoon ? customComingSoonTitle || title : title}
          </h3>
        </header>
      </TextContainer>
    </Container>
  );

  if (comingSoon) {
    return innerJSX;
  }

  return <Link to={`/collections/${slug?.current}`}>{innerJSX}</Link>;
};

export default CollectionCard;
