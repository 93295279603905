import React from "react";
import { Countdown, PgWrapper, PgItem } from "~components";

const data = {
  launchDate: `2028-02-21T23:23:44.249Z`,
  product: `Ferrari F40`,
  description: `The Ferrari F40 by Schaltkulisse becomes available February 23 3:00PM EST.`
};

const CountdownPG = () => (
  <PgWrapper title="Countdown" itemWidth="100%">
    <PgItem title="Dark">
      <div style={{ position: `relative`, padding: `1rem` }}>
        <div
          style={{
            position: `absolute`,
            inset: 0,
            background: `url(https://images.unsplash.com/photo-1607236083122-583e96ea5aac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80)`,
            backgroundSize: `cover`
          }}
        />
        <div style={{ position: `relative` }}>
          <Countdown {...data} colorTheme="dark" />
        </div>
      </div>
    </PgItem>
    <PgItem title="Light">
      <div style={{ position: `relative`, padding: `1rem` }}>
        <div
          style={{
            position: `absolute`,
            inset: 0,
            background: `url(https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2664&q=80)`,
            backgroundSize: `cover`
          }}
        />
        <div style={{ position: `relative` }}>
          <Countdown {...data} colorTheme="light" />
        </div>
      </div>
    </PgItem>
  </PgWrapper>
);

export default CountdownPG;
