/* eslint-disable react/no-danger */
/* eslint-disable import/no-extraneous-dependencies */

import React from "react";
import styled from "@emotion/styled";
import DOMPurify from "dompurify";
import { useWindowDimensions } from "~hooks";
import { OwnershipStatus, SVG } from "~components";
import { breakpoint } from "~utils/css";
import { thousandCommas } from "~utils/helpers";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  position: relative;

  > * {
    animation: 0.5s var(--cubic-easing) appear-down forwards;
  }

  .profileItemDetails {
    &Ownership {
      margin-bottom: 0.75rem;
    }

    &Group {
      position: relative;
      overflow: hidden;
      margin-bottom: 0.5rem;
      padding: 1rem 1rem 1rem;
      background: var(--color-mono-90);
      backdrop-filter: blur(50px);
      border-radius: 12px;

      ${breakpoint(`large-tablet`)} {
        padding: 1.25rem;
      }
    }

    &Table {
      position: relative;

      &Row {
        position: relative;
        padding: 0.5rem 0;
        border-bottom: 1px solid var(--color-mono-70);

        ${breakpoint(`large-tablet`)} {
          display: flex;
        }
      }

      // special; overrides the default row values as it's the leading info
      &Description {
        position: relative;
        margin-top: 2.5rem;
        padding-bottom: 1.5rem;
      }

      &Key {
        width: 100%;
        margin-bottom: 0.5rem;
        color: var(--color-mono-40);
        line-height: 160%;

        ${breakpoint(`large-tablet`)} {
          width: 200px;
          margin-bottom: 0;
        }
      }

      &Value {
        width: 100%;
        line-height: 160%;
        padding-right: 1.25rem;

        ${breakpoint(`large-tablet`)} {
          width: calc(100% - 200px);
        }
      }
    }
  }

  .estimate {
    background-color: #1e1e1e;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    padding: 1.25rem;

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.2;
    }

    h3 * {
      vertical-align: middle;
    }

    h3 svg {
      aspect-ratio: 1;
      margin-right: 0.625rem;
      width: 2.5rem;
    }

    data {
      font-size: 2rem;
      line-height: 1.4;
    }

    p {
      line-height: 1.4;
      color: var(--mono-0, #ffffff);
      padding-left: 3.125rem;
    }

    .price {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.25rem;
    }
  }
`;

/** ============================================================================
 * @component
 */
const ProfileItemDetails = ({ item, nft, isCertificate, isFullCertificate, certificationRequestDetails, readOnly, alreadyOnSale }) => {
  const { isMobile } = useWindowDimensions();

  return (
    <Container>
      <OwnershipStatus
        className="profileItemDetailsOwnership"
        certificationRequestDetails={certificationRequestDetails}
        item={item}
        isFullCertificate={isFullCertificate}
        nft={nft}
        readOnly={readOnly}
        alreadyOnSale={alreadyOnSale}
      />
      {!!isCertificate && !!certificationRequestDetails?.priceEstimate && (
        <section className="estimate">
          <div className="price">
            <h3>
              <SVG svg="altrLogo" />
              <span>Altr Expert</span>
            </h3>
            <data value={certificationRequestDetails?.priceEstimate}>{thousandCommas(certificationRequestDetails?.priceEstimate)} $</data>
          </div>
          <p>
            {certificationRequestDetails?.source?.text
              ? `This watch has been appraised by Altr Oracles Davide and Andrea Parmegiani, who have provided a valuation of the low estimate for this watch at the Exclusive Timepieces Sale 2023 auction. Please be aware that this value is not necessarily the amount you can use for collateral as part of Altr's lending functionality. To determine that, you'll need to proceed with full digitisation.`
              : `Your collectible has been evaluated by an Altr category expert, who has provided a provisional value. Please be aware that this value is not the
            amount you can use for collateral. To determine that, you'll need to proceed with full digitisation.`}
          </p>
        </section>
      )}
      {item?.raw.metadata && (
        <article className="profileItemDetailsGroup">
          <h3 className="b1">Item Details</h3>

          <div className="profileItemDetailsTableRow profileItemDetailsTableDescription">
            <h5 className={`profileItemDetailsTableKey ${isMobile ? `caption` : `tag`}`}>Description</h5>
            <div
              className="profileItemDetailsTableValue b2"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(item?.raw.metadata?.description)
              }}
            />
          </div>

          <ul className="profileItemDetailsTable">
            {item?.raw.metadata?.attributes
              ?.filter((attribute) => !!attribute.value?.trim())
              .map((attribute, index) => (
                <li key={`profile-item-attribute-${attribute.trait_type}-${index}`} className="profileItemDetailsTableRow">
                  <h5 className={`profileItemDetailsTableKey ${isMobile ? `caption` : `tag`}`}>{attribute.trait_type}</h5>
                  <p className="profileItemDetailsTableValue tag">{attribute.value?.trim()}</p>
                </li>
              ))}
            {certificationRequestDetails?.attributes &&
              Object.entries(certificationRequestDetails.attributes)
                .filter((attribute) => attribute[0]?.toLocaleLowerCase() !== `extra notes` && !!attribute[1]?.trim())
                .map((attribute, index) => (
                  <li key={`profile-item-attribute-${attribute[0]}-${index}`} className="profileItemDetailsTableRow">
                    <h5 className={`profileItemDetailsTableKey ${isMobile ? `caption` : `tag`}`}>{attribute[0]}</h5>
                    <p className="profileItemDetailsTableValue tag">{attribute[1]?.trim()}</p>
                  </li>
                ))}
          </ul>
        </article>
      )}
    </Container>
  );
};

export default ProfileItemDetails;
