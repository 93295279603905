import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Expander, Grid } from "~components";
import { breakpoint } from "~utils/css.js";

/** ============================================================================
 * @css
 */

const Container = styled.section`
  width: 100%;
  position: relative;
  display: block;
  padding: 4rem 0;
  background: ${({ background }) => background || `#ffffff`};
  color: ${({ color }) => color || `#ffffff`};
`;

const Heading = styled.h2`
  padding-bottom: 24px;

  ${breakpoint(`large-tablet`)} {
    padding-bottom: 0;
  }
`;

/** ============================================================================
 * @component
 */
const FAQ = ({ data: { backgroundColour, fontColour, heading, questions } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [expandedIndex, setExpandedIndex] = useState(null);

  // ---------------------------------------------------------------------------
  // lifecycle

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container
      css={css`
        padding: 40px 0;

        ${breakpoint(`large-tablet`)} {
          padding: 60px 0;
        }
      `}
      className="container"
      background={backgroundColour?.hex}
      color={fontColour?.hex}
    >
      <Grid>
        <header
          css={css`
            grid-column: span 12 / span 12;
            position: relative;

            ${breakpoint(`large-tablet`)} {
              grid-column: span 6 / span 6;
            }
          `}
        >
          <Heading className="h2">{heading}</Heading>
        </header>

        <ul
          css={css`
            grid-column: span 12 / span 12;
            position: relative;

            ${breakpoint(`large-tablet`)} {
              grid-column: span 6 / span 6;
            }
          `}
        >
          {questions?.[0] &&
            questions.map(({ question, answer }, index) => {
              const active = expandedIndex === index;

              return (
                <li
                  key={`faq-question-${question}`}
                  css={css`
                    margin-bottom: 1.5rem;
                  `}
                >
                  <Expander active={active} expander={{ key: question, value: answer }} onExpand={() => setExpandedIndex(active ? null : index)} />
                </li>
              );
            })}
        </ul>
      </Grid>
    </Container>
  );
};

export default FAQ;
