import React from "react";
import useApp from "~hooks/useApp";
import NFTActionConnect from "../../NFTActionButtons/NFTActionConnect";
import NFTActionVerifyIdentity from "../../NFTActionButtons/NFTActionVerifyIdentity";
import { KycStatus } from "~dApp/models/profile/KycStatus";
import Button from "~components/Common/Button";

const NFTPurchaseWholeActions = ({ nftStatus }) => {
  const { setActiveOverlay, userIsLoggedIn, userData, kycData } = useApp();
  const { kycStatus } = kycData;

  const nonVerified =
    nftStatus?.is([`NoEmail`, `DoKyc`, `KycRejected`]) ||
    !userData?.user?.emailAddress ||
    kycStatus === KycStatus.PENDING ||
    kycStatus === KycStatus.REJECTED ||
    kycStatus === KycStatus.FINAL_REJECTED;

  const initialSale = nftStatus?.is([`OnSale`]);
  const preSale = nftStatus?.is([`WaitingForSaleStart`, `CollectUserInterest`, `WaitingForWhitelisting`, `Whitelisted`]);

  if (!userIsLoggedIn) {
    return <NFTActionConnect />;
  }

  if (!nftStatus.is([`OnFractionsSaleSuccess`]) && nonVerified) {
    return <NFTActionVerifyIdentity nftStatus={nftStatus} />;
  }

  if (!(preSale || initialSale)) {
    return null;
  }

  return (
    <>
      {preSale && (
        <p>
          <span>You are now eligible to make purchases on the Altr platform. Stay updated for the live sale of this product.</span>
        </p>
      )}
      <div className="nftPurchaseWholeFlex nftPurchaseWholeControls">
        <Button
          className="nftPurchaseWholeButton"
          onClick={() => {
            setActiveOverlay(`NFTOverlayPurchase`);
          }}
          fluid
          variant="primaryTall"
          colorTheme="dark"
          disabled={!initialSale}
        >
          <span className="b1">Buy</span>
        </Button>

        <Button
          className="nftPurchaseWholeButton"
          onClick={() => {
            setActiveOverlay(`NFTOverlayPurchaseClaim`);
          }}
          fluid
          variant="feature"
          colorTheme="dark"
          disabled={!initialSale}
        >
          <span className="b1">Buy & Claim</span>
        </Button>
      </div>
    </>
  );
};

export default NFTPurchaseWholeActions;
