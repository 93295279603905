import { graphql, useStaticQuery } from "gatsby";

const useSanityEvents = () => {
  // ---------------------------------------------------------------------------
  // graphql data

  const { allSanityEvent } = useStaticQuery(graphql`
    query useSanityEventsQuery {
      allSanityEvent {
        edges {
          node {
            ...SanityEventFragment
          }
        }
      }
    }
  `);

  // ---------------------------------------------------------------------------
  // methods

  const flattenedData = () =>
    allSanityEvent?.edges?.[0]
      ? allSanityEvent.edges.map(({ node }) => node)
      : [];

  const getSanityEventById = (guid) => {
    const sanityEvents = flattenedData();

    if (!sanityEvents?.[0]) {
      return null;
    }

    return sanityEvents.find(({ eventGuid }) => eventGuid === guid);
  };

  // ---------------------------------------------------------------------------

  return {
    allSanityEvent,
    flattenedSanityEvents: flattenedData(),
    getSanityEventById
  };
};

export default useSanityEvents;
