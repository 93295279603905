import React, { useState } from "react";
import styled from "@emotion/styled";
import { Button, Grid } from "~components";
import { useApp } from "~hooks";
import { breakpoint } from "~utils/css";
import { walletAddressAreEqual, formatStablecoin, formatCryptoCurrency } from "~utils/helpers";
import loader from "~assets/images/loader.webp";
import Image from "~components/Common/Image";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  width: 100%;
  position: relative;
  display: block;
`;

const ActivityEmpty = styled.div`
  grid-column: 1 / -1;
  height: 400px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-mono-20);
  border-radius: 12px;
`;

const ActivityList = styled.ul`
  width: 100%;
  position: relative;

  .nftActivityListMobile {
    &Item {
      margin: 1rem;
      border: 1px solid var(--color-mono-20);
      padding: 1rem 0.75rem;
      border-radius: 0.5rem;

      ${breakpoint(`small-tablet`)} {
        margin: 1rem 2rem;
      }

      ${breakpoint(`large-tablet`)} {
        display: none;
      }
    }
    &WalletLabel {
      display: flex;
      color: var(--color-khaki-100);
      margin-bottom: 0.5rem;
    }
    &WalletIcon {
      width: 12px;
      margin-right: 0.5rem;
    }
    &WalletAddress {
      margin-bottom: 2rem;
    }
    &RowInfo {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 1.5rem;
    }
    &Label {
      color: var(--color-khaki-100);
      margin-bottom: 0.5rem;
    }
  }
`;

const ActivityListItem = styled.li`
  width: 100%;
  position: relative;
  padding: ${({ heading }) => `${heading ? `1rem` : `2rem`}`} 0 0;
  display: none;

  ${breakpoint(`large-tablet`)} {
    display: block;
  }

  .activityListItem {
    &Grid {
      align-items: center;
    }

    &Separator {
      grid-column: 1 / -1;
      height: 1px;
      margin: ${({ heading }) => `${heading ? `1rem` : `2rem`}`} 0 0;
      background: var(--color-mono-20);
    }

    //

    &Wallet,
    &Fragments,
    &Price {
      color: var(--color-${({ heading }) => `${heading ? `khaki-50` : `black`}`});
    }

    &Wallet {
      grid-column: span 6;
    }

    &Fragments {
      grid-column: span 2;
    }

    &Price {
      grid-column: span 2;

      &Hidden {
        opacity: 0;
      }
    }

    &Actions {
      grid-column: span 2;
      display: flex;
      justify-content: end;
    }
  }
`;

const LoadMoreButton = styled.div`
  grid-column: 1/-1;

  ${breakpoint(`large-tablet`)} {
    grid-column: 4 / span 6;
  }

  position: relative;
  margin-top: 4rem;

  // todo: refactor after buttons are aligned

  button,
  button div {
    height: 80px;
    margin-bottom: 1rem;
  }
`;

const NFTActivityListDesktop = ({
  nftStatus,
  id,
  rows,
  buttonLabel,
  alternativeButtonLabel,
  secondaryAction,
  handleSecondaryActivityItemClick = () => {},
  handleActivityItemClick = () => {}
}) => {
  // ---------------------------------------------------------------------------
  // imports / state
  const [showAllRows, setShowAllRows] = useState(false);

  const { userData } = useApp();

  const maxRows = 3;

  const isSoldOut = nftStatus?.is(`SoldOut`);

  const fragmentLabel = isSoldOut ? `` : `Fragments`;

  let displayedRows = rows;

  if (rows?.length > maxRows && !showAllRows) {
    displayedRows = rows.slice(0, maxRows);
  }

  // ---------------------------------------------------------------------------
  // render
  return (
    <Container>
      {rows === undefined && (
        <Grid>
          <ActivityEmpty>
            <Image image={loader} contain fill />
          </ActivityEmpty>
        </Grid>
      )}
      {rows?.length > 0 && (
        <>
          <ActivityListItem heading>
            <Grid className="activityListItemGrid">
              <h4 className="activityListItemWallet caption">Wallet</h4>

              <h4 className="activityListItemFragments caption">{fragmentLabel}</h4>

              <h4
                className={`activityListItemPrice caption ${
                  id?.toLowerCase() === `owner’s board` || id?.toLowerCase() === `current owner` ? `activityListItemPriceHidden` : ``
                }`}
              >
                Price {!isSoldOut && `per fragment`}
              </h4>

              <div className="activityListItemActions" />
              <div className="activityListItemSeparator" />
            </Grid>
          </ActivityListItem>

          <ActivityList>
            {displayedRows.map((row, rowIndex) => {
              const isOwner = walletAddressAreEqual(userData?.address, row?.address) || walletAddressAreEqual(userData?.address, row?.order?.maker);
              return (
                <ActivityListItem key={`activityListItem-${row.wallet}-${rowIndex}`}>
                  <Grid className="activityListItemGrid">
                    <h4 className="activityListItemWallet">{!isOwner ? row?.address || row?.order?.maker : `You`}</h4>

                    <h4 className="activityListItemFragments">{!isSoldOut && (row.fractions || row.nftTokenAmount)}</h4>
                    <h4 className="activityListItemPrice">
                      {row?.erc20TokenAmount &&
                        row?.order?.fees?.[0] &&
                        row?.nftTokenAmount &&
                        `${formatCryptoCurrency(
                          formatStablecoin(
                            (BigInt(row.erc20TokenAmount) + BigInt(row?.sellOrBuyNft === `sell` ? row.order.fees[0].amount : 0)) /
                              BigInt(row.nftTokenAmount || `1`)
                          )
                        )} USDt`}
                    </h4>

                    <div className="activityListItemActions">
                      <Button variant={isOwner ? `feature` : `primaryTall`} colorTheme="dark" onClick={() => handleActivityItemClick(row)}>
                        <span className="button-text">{isOwner ? alternativeButtonLabel : buttonLabel}</span>
                      </Button>
                    </div>

                    <div className="activityListItemSeparator" />
                  </Grid>
                </ActivityListItem>
              );
            })}
          </ActivityList>
        </>
      )}
      {rows?.length <= 0 && (
        <Grid>
          <ActivityEmpty>
            <p className="b2">No entries yet</p>
          </ActivityEmpty>
        </Grid>
      )}
      <Grid>
        <LoadMoreButton>
          {secondaryAction && (
            <Button colorTheme="dark" fluid variant="feature" onClick={() => handleSecondaryActivityItemClick()}>
              <span className="button-text">{secondaryAction}</span>
            </Button>
          )}
          {rows?.length > 3 && (
            <Button colorTheme="dark" fluid variant="feature" onClick={() => setShowAllRows(!showAllRows)}>
              <span className="button-text">Show {showAllRows ? `Less` : `All`}</span>
            </Button>
          )}
        </LoadMoreButton>
      </Grid>
    </Container>
  );
};

export default NFTActivityListDesktop;
