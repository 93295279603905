import React from "react";
import styled from "@emotion/styled";
import { ImageRatio, SVG } from "~components";
import { useApp } from "~hooks";
import { breakpoint } from "~utils/css";
/** ============================================================================
 * @css
 */

const Container = styled.div`
  position: relative;
  .videoThumbnailTile {
    &PlayIcon {
      filter: drop-shadow(0px 0px 3px black);
      height: 40px;
      width: 40px;
      transition: transform 0.3s;

      ${breakpoint(`small-desktop`)} {
        height: 70px;
        width: 70px;
      }
    }
  }
`;

const ButtonContainer = styled.button`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-white);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .videoThumbnailTile {
        &PlayIcon {
          transform: scale(1.2);
        }
      }
    }
  }
`;

/** ============================================================================
 * @component
 * @param  {string}    					aspectRatio   			e.g. "1/3" | "9/16"
 * @param  {string}  						videoUrl   					URL of video file
 * @param  {GatsbyImageData}    thumbnailImage  		Image to display as the thumbnail
 * @return {node}
 */

const VideoThumbnailTile = ({ aspectRatio, videoUrl, thumbnailImage }) => {
  const { setVideoPlayerSource, setIsVideoPlayerVisible } = useApp();

  const handleClick = () => {
    setVideoPlayerSource(videoUrl);
    setIsVideoPlayerVisible(true);
  };

  return (
    <Container>
      <ImageRatio image={thumbnailImage} ratio={aspectRatio} />
      <ButtonContainer onClick={handleClick} type="button" aria-label="Play video">
        <SVG svg="playSolid" className="videoThumbnailTilePlayIcon" />
      </ButtonContainer>
    </Container>
  );
};

export default VideoThumbnailTile;
