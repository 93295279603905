export const raffleRewarderAbi = [
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_raffleId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "_verificationTime",
                "type": "uint256"
            },
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "contractAddress",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "tokenId",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct RaffleRewarder.Prize",
                "name": "_prize",
                "type": "tuple"
            },
            {
                "internalType": "bytes32",
                "name": "_participantsProof",
                "type": "bytes32"
            },
            {
                "internalType": "address",
                "name": "_link",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "_vrfV2Wrapper",
                "type": "address"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "constructor"
    },
    {
        "inputs": [],
        "name": "AskForRandomnessFirst",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "CannotAskForNewRandomness",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "InvalidAmount",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "InvalidCaps",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "InvalidTicketAmount",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "InvalidTimestamps",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "InvalidTokenAddress",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "MaxCapReached",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "NoExcessPurchaseToken",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "OnlyWinnerCanCall",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "PersonalMaxCapReached",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "PrizeClaimPeriodElapsed",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "PrizeNotApproved",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "RaffleNotActive",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "RaffleNotFailed",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "RaffleNotSuccessful",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "RefundNotAvailable",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "UnableToFundContract",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "VerificationTimeNotElapsed",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "WinnerNotSet",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "WithdrawAmountExceedsLimit",
        "type": "error"
    },
    {
        "inputs": [],
        "name": "WrongInput",
        "type": "error"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "claimer",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "name": "PrizeClaimed",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "requester",
                "type": "address"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "requestId",
                "type": "uint256"
            },
            {
                "indexed": false,
                "internalType": "uint256",
                "name": "paid",
                "type": "uint256"
            }
        ],
        "name": "RandomnessRequested",
        "type": "event"
    },
    {
        "anonymous": false,
        "inputs": [
            {
                "indexed": true,
                "internalType": "address",
                "name": "winner",
                "type": "address"
            }
        ],
        "name": "WinnerDetermined",
        "type": "event"
    },
    {
        "inputs": [],
        "name": "CALLBACK_GAS_LIMIT",
        "outputs": [
            {
                "internalType": "uint32",
                "name": "",
                "type": "uint32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "NUM_WORDS",
        "outputs": [
            {
                "internalType": "uint32",
                "name": "",
                "type": "uint32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "PRIZE_CLAIM_PERIOD",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "REQUEST_CONFIRMATION",
        "outputs": [
            {
                "internalType": "uint16",
                "name": "",
                "type": "uint16"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "askForRandomness",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "claimPrize",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "deadline",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "participantsProof",
        "outputs": [
            {
                "internalType": "bytes32",
                "name": "",
                "type": "bytes32"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "prize",
        "outputs": [
            {
                "internalType": "address",
                "name": "contractAddress",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "raffleId",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "internalType": "uint256",
                "name": "_requestId",
                "type": "uint256"
            },
            {
                "internalType": "uint256[]",
                "name": "_randomWords",
                "type": "uint256[]"
            }
        ],
        "name": "rawFulfillRandomWords",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "request",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "requestId",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "paid",
                "type": "uint256"
            },
            {
                "internalType": "bool",
                "name": "fulfilled",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "addr",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "ticketCount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "cumulativeCount",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct RaffleRewarder.Participant[]",
                "name": "_participants",
                "type": "tuple[]"
            }
        ],
        "name": "rewardWinner",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "startClaimTime",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [
            {
                "components": [
                    {
                        "internalType": "address",
                        "name": "addr",
                        "type": "address"
                    },
                    {
                        "internalType": "uint256",
                        "name": "ticketCount",
                        "type": "uint256"
                    },
                    {
                        "internalType": "uint256",
                        "name": "cumulativeCount",
                        "type": "uint256"
                    }
                ],
                "internalType": "struct RaffleRewarder.Participant[]",
                "name": "_participants",
                "type": "tuple[]"
            }
        ],
        "name": "verifyParticipants",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    },
    {
        "inputs": [],
        "name": "winner",
        "outputs": [
            {
                "internalType": "address",
                "name": "",
                "type": "address"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    }
] as const;