import { useEffect, useState } from "react";
import {
  useAccount,
  useBalance,
  usePublicClient,
  useReadContract,
  useSimulateContract,
  useWaitForTransactionReceipt,
  useWalletClient,
  useWriteContract
} from "wagmi";
import { TARGET_CHAIN, walletAddressAreEqual, USDT_ADDRESS } from "~utils/helpers";
import { NFTDataVM } from "~dApp/models/ApiViewModel";
import { Abi } from "abitype";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { NftFeeManagerDataVM, ProductDetailVMOfBaseProductVM } from "~dApp/models/ApiModel";
import { erc20Abi, formatUnits, parseUnits } from "viem";
import { useApp } from "./useApp";
import { altrFeeManager, altrFractions, altrFractionsBuyout, altrFractionsSale, altrNftCollection, staking } from "~dApp/abi";

/** ============================================================================
 * blockchainHooksHelper()
 */
export const blockchainHooksHelper = () => {
  const useContractReadHelper = (contractAbiData: { contractAddress: string; abi: Abi }, functionName: string, args: any[], enabled = true) => {
    return useReadContract({
      address: contractAbiData.contractAddress as `0x${string}`,
      abi: contractAbiData.abi,
      functionName,
      args,
      chainId: TARGET_CHAIN.id,
      query: { enabled }
    });
  };

  const useContractWriteHelper = (contractAbiData: { contractAddress: string; abi: Abi }, functionName: string, args = []) => {
    const { userData } = useApp() as any;

    const {
      error: prepareError,
      isError: isPrepareError,
      refetch
    } = useSimulateContract({
      address: contractAbiData.contractAddress as `0x${string}`,
      abi: contractAbiData.abi,
      functionName: functionName,
      args,
      chainId: TARGET_CHAIN.id,
      query: { retry: false }
    });

    const {
      data: writeData,
      error: writeError,
      isError: writeIsError,
      isPending: isWriteLoading,
      status,
      writeContract: write,
      writeContractAsync: writeAsync
    } = useWriteContract();

    const {
      data: txData,
      isSuccess,
      isLoading
    } = useWaitForTransactionReceipt({ hash: writeData, retryDelay: ({ count }) => (1 << count) * 1000, retryCount: 20 });

    return {
      txData,
      write: () =>
        write({
          address: contractAbiData.contractAddress as `0x${string}`,
          abi: contractAbiData.abi,
          functionName: functionName,
          args,
          chainId: TARGET_CHAIN.id,
          chain: TARGET_CHAIN,
          account: userData?.address
        }),
      writeAsync: () =>
        writeAsync({
          address: contractAbiData.contractAddress as `0x${string}`,
          abi: contractAbiData.abi,
          functionName: functionName,
          args,
          chainId: TARGET_CHAIN.id,
          chain: TARGET_CHAIN,
          account: userData?.address
        }),
      isSuccess,
      isLoading,
      isPrepareError,
      prepareError,
      isWriteLoading,
      status,
      writeError,
      writeIsError,
      refetch
    };
  };

  return {
    useContractReadHelper,
    useContractWriteHelper
  };
};

/** ============================================================================
 * blockchainHooks()
 * @hook
 */
export const blockchainHooks = () => {
  // ---------------------------------------------------------------------
  // COMMON
  const { useContractReadHelper, useContractWriteHelper } = blockchainHooksHelper();

  // ---------------------------------------------------------------------
  // FEE MANAGER
  const useGetLicenseManager = (nftData: NFTDataVM, enabled = true) => {
    const contractAbiData = { contractAddress: nftData.nftCollectionInfo.feeManagerAddress, abi: altrFeeManager as Abi };

    return useContractReadHelper(contractAbiData, `licenseManager`, [], enabled);
  };

  const useGetRedemptionFeeCost = (nftData: NFTDataVM) => {
    const contractAbiData = { contractAddress: nftData.nftCollectionInfo.feeManagerAddress, abi: altrFeeManager as Abi };

    return useContractReadHelper(contractAbiData, `redemptionFee`, []);
  };

  const useIsRedemptionFeePaid = (nftData: NFTDataVM) => {
    const contractAbiData = { contractAddress: nftData.nftCollectionInfo.feeManagerAddress, abi: altrFeeManager as Abi };

    return useContractReadHelper(contractAbiData, `isRedemptionFeePaid`, [nftData?.nftCollectionInfo?.collectionAddress, nftData?.tokenId]);
  };

  const usePayRedemptionFee = (nftData: NFTDataVM) => {
    const contractAbiData = { contractAddress: nftData.nftCollectionInfo.feeManagerAddress, abi: altrFeeManager as Abi };

    return useContractWriteHelper(contractAbiData, `payRedemptionFee`, [nftData?.nftCollectionInfo?.collectionAddress, nftData?.tokenId]);
  };

  // ---------------------------------------------------------------------
  // STAKING

  const useGetStakerInfo = (stakingAddress: `0x${string}`, user: `0x${string}`) => {
    const contractAbiData = { contractAddress: stakingAddress, abi: staking as Abi };

    return useContractReadHelper(contractAbiData, `stakers`, [user], !!stakingAddress);
  };

  // ---------------------------------------------------------------------
  // NFT COLLECTION
  const useGetVaultServiceDeadline = (nftData: NFTDataVM) => {
    const contractAbiData = { contractAddress: nftData?.nftCollectionInfo?.collectionAddress, abi: altrNftCollection as Abi };

    return useContractReadHelper(contractAbiData, `getVaultServiceDeadline`, [nftData.tokenId.toString()]);
  };

  const useTransferNft = (nftData: NFTDataVM, toAddress: string) => {
    const { address } = useAccount();
    const contractAbiData = { contractAddress: nftData?.nftCollectionInfo?.collectionAddress, abi: altrNftCollection as Abi };

    return useContractWriteHelper(contractAbiData, `safeTransferFrom`, [address, toAddress, nftData?.tokenId?.toString()]);
  };

  const useConvertFragmentsToWhole = (nftData: NFTDataVM) => {
    const { address } = useAccount();
    const contractAbiData = { contractAddress: nftData?.nftCollectionInfo?.collectionAddress, abi: altrNftCollection as Abi };

    return useContractWriteHelper(contractAbiData, `safeTransferFrom`, [
      nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress,
      address,
      nftData?.tokenId?.toString()
    ]);
  };

  const useMoveNftToCollateralRetriever = (nftData: NFTDataVM) => useTransferNft(nftData, nftData?.nftCollectionInfo?.collateralRetrieverAddress);

  const useGetNftOwnership = (nftData: NFTDataVM) => {
    const { address } = useAccount();

    const contractAbiData = { contractAddress: nftData?.nftCollectionInfo?.collectionAddress, abi: altrNftCollection as Abi };

    const { data } = useContractReadHelper(contractAbiData, `ownerOf`, [BigInt(nftData?.tokenId?.toString() || "0")]);

    return walletAddressAreEqual(data, address);
  };

  const useGetNftOwnerOf = (nftData: NFTDataVM) => {
    const contractAbiData = { contractAddress: nftData?.nftCollectionInfo?.collectionAddress, abi: altrNftCollection as Abi };

    return useContractReadHelper(contractAbiData, `ownerOf`, [BigInt(nftData?.tokenId?.toString() || "0")]);
  };

  const useManage0xNftAllowance = (
    nftData: NFTDataVM,
    type: `ERC721` | `ERC1155`,
    amount?: string,
    setApproved = (isApproved: boolean) => {},
    isEnabled = true
  ) => {
    const { address } = useAccount();
    const { trader } = useExternalIntegrations();
    const { data: walletClient } = useWalletClient();
    const publicClient = usePublicClient();
    const traderSdk = trader(publicClient, walletClient);

    const [doRefetch, setRefetch] = useState(false);

    useEffect(() => {
      const manageAllowance = async () => {
        const collectionAddress =
          type === `ERC721` ? nftData?.nftCollectionInfo?.collectionAddress : nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress;
        const tokenId = type === `ERC721` ? nftData?.tokenId.toString() : nftData?.saleId?.toString();
        const asset = traderSdk.buildAsset(collectionAddress, type, tokenId, amount);
        const approvalStatus = await traderSdk.loadApprovalStatus(asset, address);

        if (isEnabled) {
          if (approvalStatus.contractApproved || approvalStatus.tokenIdApproved) {
            setApproved(true);
          } else {
            setApproved(false);
          }
        }
      };

      manageAllowance().catch((e) => console.error(e));
    }, [
      nftData?.nftCollectionInfo?.collectionAddress,
      nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress,
      nftData?.tokenId,
      nftData?.saleId,
      type,
      amount,
      address,
      doRefetch,
      isEnabled,
      walletClient?.account?.address
    ]);

    const refetch = () => {
      setRefetch(!doRefetch);
    };

    return { refetch };
  };

  // ---------------------------------------------------------------------
  // FRAGMENTS

  const useGetFractionsBalance = (nftData: NFTDataVM, address: string) => {
    const contractAbiData = { contractAddress: nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress, abi: altrFractions as Abi };

    return useContractReadHelper(contractAbiData, `balanceOf`, [address, nftData?.saleId?.toString()]);
  };
  const useTransferFragments = (nftData: NFTDataVM, toAddress: string, amount: string) => {
    const { address } = useAccount();
    const contractAbiData = { contractAddress: nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress, abi: altrFractions as Abi };

    return useContractWriteHelper(contractAbiData, `safeTransferFrom`, [address, toAddress, nftData?.saleId?.toString(), amount, `0x`]);
  };

  const useApproveFragments = (nftData: NFTDataVM, toAddress: string) => {
    const contractAbiData = { contractAddress: nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress, abi: altrFractions as Abi };

    return useContractWriteHelper(contractAbiData, `setApprovalForAll`, [toAddress, true]);
  };

  const useApproveFragmentsToBuyoutContract = (nftData: NFTDataVM) => {
    return useApproveFragments(nftData, nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsBuyoutAddress);
  };

  const useGetFragmentsAllowance = (nftData: NFTDataVM, operator: string) => {
    const { address } = useAccount();
    const contractAbiData = { contractAddress: nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress, abi: altrFractions as Abi };

    return useContractReadHelper(contractAbiData, `isApprovedForAll`, [address, operator]);
  };

  const useManageFractionsBuyoutFragmentsAllowance = (nftData: NFTDataVM, setApproved = (isApproved: boolean) => {}) => {
    const { data: isApproved } = useGetFragmentsAllowance(nftData, nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsBuyoutAddress);
    useEffect(() => {
      setApproved(isApproved as boolean);
    }, [isApproved]);
  };

  // ---------------------------------------------------------------------
  // NATIVE TOKEN
  const useGetNativeTokenBalance = () => {
    const { address } = useAccount();

    const balance = useBalance({ address });
    return balance;
  };

  // ---------------------------------------------------------------------
  // ERC20
  const useGetTokenBalance = (tokenAddress: string) => {
    const { address } = useAccount();
    const contractAbiData = { contractAddress: tokenAddress, abi: erc20Abi };

    const { data, isLoading, error, refetch: refetchBalanceOf } = useContractReadHelper(contractAbiData, `balanceOf`, [address]);
    const { data: decimals, isLoading: isDecimalsLoading, error: decimalsError } = useContractReadHelper(contractAbiData, `decimals`, []);

    return {
      data: { decimals, value: data, formatted: formatUnits(BigInt((data as bigint) || 0), Number((decimals as bigint) || 0)) },
      isLoading: isLoading || isDecimalsLoading,
      error: error || decimalsError,
      refetch: refetchBalanceOf
    };
  };

  const useGetUSDTBalance = () => useGetTokenBalance(USDT_ADDRESS);

  const useGetPurchaseTokenBalance = (nftData: NFTDataVM, isEnabled = true) => {
    return useGetTokenBalance(nftData ? (Object.keys(nftData.nftCollectionInfo.tokens)[0] as `0x${string}`) : null);
  };

  const useApprove = (contractAddress: string, toAddress: string, approveAmount: BigInt) => {
    const contractAbiData = { contractAddress, abi: erc20Abi };

    return useContractWriteHelper(contractAbiData, `approve`, [toAddress, approveAmount]);
  };

  const useApproveFractionsSale = (nftData: NFTDataVM, approveAmount: BigInt) => {
    const tokenAddress = nftData ? Object.keys(nftData.nftCollectionInfo.tokens)[0] : null;

    const toAddress = nftData.nftCollectionInfo.fractionSaleAddresses.fractionSaleContractAddress;

    return useApprove(tokenAddress, toAddress, approveAmount);
  };

  const useApproveFractionsBuyout = (nftData: NFTDataVM, approveAmount: BigInt) => {
    const tokenAddress = nftData ? Object.keys(nftData.nftCollectionInfo.tokens)[0] : null;

    const toAddress = nftData.nftCollectionInfo.fractionSaleAddresses.fractionsBuyoutAddress;

    return useApprove(tokenAddress, toAddress, approveAmount);
  };

  const useApproveFeeManager = (nftData: NFTDataVM, approveAmount: BigInt) => {
    const tokenAddress = nftData ? Object.keys(nftData?.nftCollectionInfo?.tokens)[0] : null;

    const toAddress = nftData?.nftCollectionInfo?.feeManagerAddress;

    return useApprove(tokenAddress, toAddress, approveAmount);
  };

  const useGetTokenAllowance = (tokenAddress: string, fromAddress: string, toAddress: string) => {
    const contractAbiData = { contractAddress: tokenAddress, abi: erc20Abi };

    return useContractReadHelper(contractAbiData, `allowance`, [fromAddress, toAddress]);
  };

  const useGetFractionsSaleAllowance = (nftData: NFTDataVM) => {
    const { address } = useAccount();
    const tokenAddress = nftData ? (Object.keys(nftData.nftCollectionInfo.tokens)[0] as `0x${string}`) : null;
    return useGetTokenAllowance(tokenAddress, address, nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionSaleContractAddress);
  };

  const useGetFractionsBuyoutAllowance = (nftData: NFTDataVM) => {
    const { address } = useAccount();
    const tokenAddress = nftData ? (Object.keys(nftData.nftCollectionInfo.tokens)[0] as `0x${string}`) : null;
    return useGetTokenAllowance(tokenAddress, address, nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsBuyoutAddress);
  };

  const useGet0xAllowance = (nftData: NFTDataVM, exchangeProxyAddress?: `0x${string}`) => {
    const { address } = useAccount();
    const { trader } = useExternalIntegrations();
    const traderSdk = trader();
    const contractAbiData = { contractAddress: nftData ? (Object.keys(nftData.nftCollectionInfo?.tokens)[0] as `0x${string}`) : null, abi: erc20Abi };

    return useContractReadHelper(contractAbiData, `allowance`, [
      address,
      exchangeProxyAddress || traderSdk.traderService?.nftSwap.exchangeProxyContractAddress
    ]);
  };

  const useManageFractionsSaleTokenAllowance = (enrichedProduct: ProductDetailVMOfBaseProductVM, price: number, setApproved = (isApproved: boolean) => {}) => {
    const publicClient = usePublicClient();
    const { nftData } = enrichedProduct || {};
    const [tokenDecimals, setTokenDecimals] = useState<number>();

    const { data: allowance, refetch } = useGetFractionsSaleAllowance(nftData) as any;
    let tokenAddress: string;
    if (nftData?.nftCollectionInfo?.tokens) {
      tokenAddress = Object.keys(nftData.nftCollectionInfo.tokens)[0];
    }

    useEffect(() => {
      const manageAllowance = async () => {
        let decimals = tokenDecimals;
        if (!decimals) {
          decimals = await publicClient.readContract({ address: tokenAddress as `0x${string}`, abi: erc20Abi, functionName: `decimals`, args: [] });
          setTokenDecimals(decimals);
        }

        const parsedPrice = parseUnits(price.toString(), decimals);

        if (allowance >= parsedPrice) {
          setApproved(true);
        } else {
          setApproved(false);
        }
      };

      manageAllowance().catch((e) => console.error(e));
    }, [price, allowance?.toString(), tokenAddress, publicClient.uid]);

    return { refetch };
  };

  const useManage0xTokenAllowance = (
    enrichedProduct: ProductDetailVMOfBaseProductVM,
    price: number,
    setApproved = (isApproved: boolean) => {},
    applyFee = false,
    overrides?: { exchangeProxyAddress: `0x${string}` },
    forceUpdate = false,
    isEnabled = true
  ) => {
    const [feeData, setFeeData] = useState<NftFeeManagerDataVM>();
    const [tokenDecimals, setTokenDecimals] = useState<number>();
    const { address } = useAccount();
    const { nftData, product } = enrichedProduct || {};
    const publicClient = usePublicClient();

    const { data: allowance, refetch } = useGet0xAllowance(nftData, overrides?.exchangeProxyAddress) as any;
    const { api } = useExternalIntegrations();

    let tokenAddress: string;
    if (nftData?.nftCollectionInfo?.tokens) {
      tokenAddress = Object.keys(nftData.nftCollectionInfo.tokens)[0];
    }

    useEffect(() => {
      const manageAllowance = async () => {
        if (!tokenAddress) return;

        let decimals = tokenDecimals;
        if (!decimals) {
          decimals = await publicClient.readContract({ address: tokenAddress as `0x${string}`, abi: erc20Abi, functionName: `decimals`, args: [] });
          setTokenDecimals(decimals);
        }

        // TODO: if first sale fee differ from secondary market fee edit this
        let nftFeeData = feeData;
        if (!nftFeeData) {
          nftFeeData = await api.getNftFeeData(product.identifier);
          setFeeData(nftFeeData);
        }
        const fee = (price * nftFeeData.takerFee) / 100;
        const totalPrice = applyFee ? price + fee : price;

        const approvalAmount = parseUnits(totalPrice.toString(), decimals);

        if (isEnabled) {
          if (allowance >= approvalAmount && approvalAmount !== BigInt(0)) {
            setApproved(true);
          } else {
            setApproved(false);
          }
        }
      };

      manageAllowance().catch((e) => console.error(e));
    }, [price, allowance?.toString(), tokenAddress, product?.identifier, address, publicClient.uid, forceUpdate, isEnabled]);

    return { refetch };
  };

  const useManageFractionsBuyoutTokenAllowance = (
    enrichedProduct: ProductDetailVMOfBaseProductVM,
    price: number,
    setApproved = (isApproved: boolean) => {}
  ) => {
    const [tokenDecimals, setTokenDecimals] = useState<number>();
    const publicClient = usePublicClient();
    const { nftData } = enrichedProduct || {};

    const { data: allowance, refetch } = useGetFractionsBuyoutAllowance(nftData) as any;

    let tokenAddress: string;
    if (nftData?.nftCollectionInfo?.tokens) {
      tokenAddress = Object.keys(nftData.nftCollectionInfo.tokens)[0];
    }

    useEffect(() => {
      const manageAllowance = async () => {
        let decimals = tokenDecimals;
        if (!decimals) {
          decimals = await publicClient.readContract({ address: tokenAddress as `0x${string}`, abi: erc20Abi, functionName: `decimals`, args: [] });
          setTokenDecimals(decimals);
        }

        const parsedPrice = parseUnits(price.toString(), decimals);

        if (allowance >= parsedPrice) {
          setApproved(true);
        } else {
          setApproved(false);
        }
      };

      manageAllowance().catch((e) => console.error(e));
    }, [price, allowance?.toString(), tokenAddress, publicClient.uid]);

    return { refetch };
  };

  // ---------------------------------------------------------------------
  // FRACTIONS SALE

  const useGetSaleData = (nftData: NFTDataVM, watch = true) => {
    const contractAbiData = { contractAddress: nftData?.nftCollectionInfo?.fractionSaleAddresses.fractionSaleContractAddress, abi: altrFractionsSale as Abi };

    const params = [BigInt(nftData?.saleId?.toString() || "0")];

    const saleData = useContractReadHelper(contractAbiData, `getFractionsSale`, params, watch);

    if (!nftData?.saleId) return { data: null };
    return saleData;
  };

  const useGetFractionsAmountFromPrice = (product: ProductDetailVMOfBaseProductVM) => {
    const [tokenDecimals, setTokenDecimals] = useState<number>();
    const [price, setPrice] = useState<BigInt>(BigInt(0));
    const contractAbiData = {
      contractAddress: product?.nftData?.nftCollectionInfo?.fractionSaleAddresses.fractionSaleContractAddress,
      abi: altrFractionsSale as Abi
    };

    const publicClient = usePublicClient();

    useEffect(() => {
      const getPrice = async () => {
        if (!publicClient || !product?.product?.nftCollectionInfo?.tokens) return;
        const tokenAddress = Object.keys(product?.product?.nftCollectionInfo?.tokens)[0];
        let decimals = tokenDecimals;
        if (!decimals) {
          decimals = await publicClient.readContract({ address: tokenAddress as `0x${string}`, abi: erc20Abi, functionName: `decimals`, args: [] });
          setTokenDecimals(decimals);
        }

        setPrice(parseUnits(product?.product?.price?.usdAmount?.toString() || "0", parseInt(decimals?.toString() || "6")));
      };
      getPrice().catch((e) => console.error(e));
    }, [product?.product?.nftCollectionInfo?.tokens?.length, product?.product?.price?.usdAmount]);

    return useContractReadHelper(contractAbiData, `getFractionsAmountByPrice`, [price]);
  };

  const usePurchaseFractions = (nftData: NFTDataVM, amount: number) => {
    const contractAbiData = { contractAddress: nftData?.nftCollectionInfo?.fractionSaleAddresses.fractionSaleContractAddress, abi: altrFractionsSale as Abi };

    return useContractWriteHelper(contractAbiData, `buyFractions`, [nftData?.saleId?.toString(), amount ?? 0]);
  };

  // ---------------------------------------------------------------------
  // FRACTIONS BUYOUT

  const useCanDoBuyout = (nftData: NFTDataVM) => {
    const { address } = useAccount();
    const contractAbiData = { contractAddress: nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsBuyoutAddress, abi: altrFractionsBuyout as Abi };

    return useContractReadHelper(contractAbiData, `canDoBuyout`, [address, nftData?.saleId?.toString()]);
  };

  const useRequestBuyout = (nftData: NFTDataVM) => {
    const contractAbiData = { contractAddress: nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsBuyoutAddress, abi: altrFractionsBuyout as Abi };

    return useContractWriteHelper(contractAbiData, `requestBuyout`, [nftData?.saleId?.toString()]);
  };

  const useExecuteBuyout = (nftData: NFTDataVM) => {
    const contractAbiData = { contractAddress: nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsBuyoutAddress, abi: altrFractionsBuyout as Abi };

    return useContractWriteHelper(contractAbiData, `executeBuyout`, [nftData?.saleId?.toString()]);
  };

  const useGetBuyoutData = (nftData: NFTDataVM, buyoutId: string) => {
    const contractAbiData = { contractAddress: nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsBuyoutAddress, abi: altrFractionsBuyout as Abi };

    return useContractReadHelper(contractAbiData, `buyouts`, [buyoutId]);
  };

  return {
    useGetRedemptionFeeCost,
    useIsRedemptionFeePaid,
    usePayRedemptionFee,
    useTransferNft,
    useMoveNftToCollateralRetriever,
    useGetVaultServiceDeadline,
    useGetPurchaseTokenBalance,
    useApproveFractionsSale,
    useGetSaleData,
    useGetFractionsSaleAllowance,
    usePurchaseFractions,
    useGetFractionsBalance,
    useGet0xAllowance,
    useManage0xTokenAllowance,
    useManage0xNftAllowance,
    useManageFractionsSaleTokenAllowance,
    useGetFractionsAmountFromPrice,
    useGetNftOwnership,
    useTransferFragments,
    useCanDoBuyout,
    useRequestBuyout,
    useExecuteBuyout,
    useApproveFractionsBuyout,
    useGetBuyoutData,
    useApproveFragments,
    useApproveFragmentsToBuyoutContract,
    useManageFractionsBuyoutTokenAllowance,
    useManageFractionsBuyoutFragmentsAllowance,
    useConvertFragmentsToWhole,
    useApproveFeeManager,
    useGetNativeTokenBalance,
    useGetUSDTBalance,
    useApprove,
    useGetTokenAllowance,
    useGetNftOwnerOf,
    useGetLicenseManager,
    useGetStakerInfo
  };
};
