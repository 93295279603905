import useWindowDimensions from "~hooks/useWindowDimensions";
import Image from "~components/Common/Image";

const NFTOverlaySidebarItem = ({ name, certification, fragments, totalFragments, imageUrl, oracleName }) => {
  const { isMobile } = useWindowDimensions();

  return (
    <section className="nftCard">
      <header>
        <div className="badge">
          <svg width="146" height="20" viewBox="0 0 146 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1.71396 0.500002L2.66226 1.36869L17.7189 15.1612C20.5771 17.7794 24.3126 19.2318 28.1887 19.2318L117.811 19.2318C121.687 19.2318 125.423 17.7795 128.281 15.1612L143.338 1.36869L144.286 0.5"
              stroke="#959595"
            />
            <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
              {certification?.toUpperCase() || `ALTR`}
            </text>
          </svg>
        </div>
      </header>
      <div className="content">
        <div className="details">
          {!!oracleName && (
            <strong>
              <span className="oracle">{oracleName}</span>
            </strong>
          )}
          <div className="title">
            <h3 className={`${isMobile ? `b1` : `h3`}`}>{name || `Altr NFT`}</h3>
            {!!fragments && !!totalFragments && <p>{`${fragments}/${totalFragments} Fragments`}</p>}
          </div>
        </div>
        <figure>{imageUrl && <Image image={imageUrl} />}</figure>
      </div>
    </section>
  );
};

export default NFTOverlaySidebarItem;
