import React from "react";
import cn from "classnames";
import { SVG } from "~components";

/** ============================================================================
 * @component
 */
const NFTIconNote = ({ className, background = `white`, fontClass = `caption`, svg = `shield`, text }) => (
  <p className={cn(`nftEntry note`, className)} background={background}>
    <span className={cn(`icon`, svg)}>
      <SVG svg={svg} />
    </span>
    <span className={fontClass}>{text}</span>
  </p>
);

export default NFTIconNote;
