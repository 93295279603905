import React from "react";
import styled from "@emotion/styled";
import { Button } from "~components";
import { useApp } from "~hooks";
import { isBrowser } from "~utils/helpers";
import { LOCALSTORAGE_KEY } from "~constants";

const Container = styled.article`
  position: relative;
  margin-top: 1.5rem;
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTActionWaitlist = ({ colorTheme = `dark`, enrichedProduct }) => {
  const { setActiveOverlay } = useApp();

  const getSubmitText = () => {
    if (!isBrowser()) return `Join Waitlist`;

    const localStorageItem = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY));
    if (localStorageItem?.[enrichedProduct?.product?.identifier]) {
      return `Waitlist Joined`;
    }
    return `Join Waitlist`;
  };

  return (
    <Container>
      <Button
        onClick={() => setActiveOverlay(`NFTOverlayWaitlist`)}
        fluid
        variant="feature"
        colorTheme={colorTheme === `dark` ? `light` : `dark`}
        disabled={getSubmitText() === `Waitlist Joined`}
      >
        <span className="b1">{getSubmitText()}</span>
      </Button>
    </Container>
  );
};

export default NFTActionWaitlist;
