import React, { useState } from "react";
import { Button } from "~components";
import styled from "@emotion/styled";

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const CloseButton = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
`;

const Title = styled.h2`
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
`;

const Disclaimer = styled.div`
  margin-bottom: 20px;
`;

const PrivateKeyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  transition: opacity 0.3s;
`;

const BlurredInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-size: 1.2em;
  filter: blur(${(props) => (props.visible ? `0px` : `8px`)});
  transition: filter 0.3s;
`;

const KeyPopup = ({ privateKey, closeModal }) => {
  const [accepted, setAccepted] = useState(false);
  const [revealed, setRevealed] = useState(false);
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(privateKey).then(() => setCopied(true));
  };

  const handleAccept = () => {
    setAccepted(true);
  };

  const handleReveal = () => {
    setRevealed(true);
  };

  return (
    <Modal>
      <ModalContent>
        <CloseButton onClick={closeModal}>&times;</CloseButton>
        <Title>Web3Auth Private Key</Title>
        {!accepted ? (
          <Disclaimer>
            <p>
              You shall be responsible for the management and security of this key and any assets associated with this key, and the platform cannot help you
              recover, access, or store your raw private key on your behalf.
            </p>
            <p>
              The platform is not responsible for and will not provide customer service for any other wallet software you may use this private key with, and
              does not represent that any other software or hardware will be compatible with or protect your private key.
            </p>
            <Button variant="primary" colorTheme="dark" fluid onClick={handleAccept}>
              I Accept
            </Button>
          </Disclaimer>
        ) : (
          <div className="key-popup">
            <p>Store this in a secure place that only you can access and do not share it with anyone.</p>
            <PrivateKeyContainer>
              <BlurredInput type="text" value={privateKey} readOnly visible={revealed} />
              {!revealed && (
                <Button variant="primary" colorTheme="dark" fluid onClick={handleReveal}>
                  Reveal
                </Button>
              )}
              {revealed && (
                <Button variant="primary" colorTheme="dark" fluid onClick={handleCopy}>
                  {copied ? `Copied!` : `Copy`}
                </Button>
              )}
            </PrivateKeyContainer>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
};

export default KeyPopup;
