import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Button, NFTFragmentGauge, PriceWidget, SVG, Link } from "~components";
import { useApp, useSaleData } from "~hooks";
import { breakpoint } from "~utils/css";
import { blockchainHooks } from "~hooks/blockchainHooks";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  position: relative;

  padding: 1.25rem 1rem;

  ${breakpoint(`large-tablet`)} {
    padding: 1.5rem 1.5rem 2rem;
  }

  .nftPurchaseFragments {
    animation: 0.5s var(--cubic-easing) appear forwards;
    animation-delay: 0.25s;

    opacity: 0;

    &Button {
      width: 100%;
      height: 56px;
      position: relative;
      display: block;
      padding: 1rem 1.5rem;
      margin: 0 0 0.5rem;

      ${breakpoint(`large-tablet`)} {
        height: auto;
        margin-bottom: 0;
      }
    }

    &Expandable {
      transition: 0.5s var(--cubic-easing) max-height;

      max-height: ${({ expanded }) => `${expanded ? `116px` : `0`}`};
      position: relative;
      overflow: hidden;
      margin-top: 0.75rem;

      ${breakpoint(`large-tablet`)} {
        max-height: none;
        overflow: auto;
        margin-top: 0;
      }
    }

    //

    &Actions {
      margin-top: 2rem;

      ${breakpoint(`large-tablet`)} {
        margin-top: 1.5rem;
      }
    }

    &Flex {
      display: flex;
      flex-direction: column;

      ${breakpoint(`large-tablet`)} {
        flex-direction: row;
      }
    }

    &Meta {
      margin-top: 0.5rem;
      color: var(--color-mono-40);

      &Count {
        text-decoration: underline;
      }
    }

    &ColLeft {
      width: 100%;
      position: relative;
      order: 2;

      ${breakpoint(`large-tablet`)} {
        width: calc(60% - 1rem);
        order: 1;
        margin-right: 1rem;
      }

      &Expander {
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: -0.25rem;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        overflow: hidden;
        z-index: 10;
        border: 1px solid var(--color-khaki-100);
        color: var(--color-khaki-100);

        svg {
          width: 1rem;
        }

        ${breakpoint(`large-tablet`)} {
          display: none;
        }
      }

      &Flex {
        ${breakpoint(`large-tablet`)} {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    &ColRight {
      width: 100%;
      position: relative;
      order: 1;
      margin-top: 1.75rem;
      margin-bottom: 1.5rem;

      ${breakpoint(`large-tablet`)} {
        order: 2;
        margin-top: 0;
        margin-bottom: 0;
        width: 40%;
      }

      &Flex {
        ${breakpoint(`large-tablet`)} {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    //

    &Price {
      display: flex;

      &Heading {
        margin-bottom: 0.5rem;
      }

      &Value {
        width: 100%;
        position: relative;

        ${breakpoint(`large-tablet`)} {
          width: 40%;
        }
      }
    }

    &Subheading,
    &Body {
      color: var(--color-mono-60);
    }

    &Subheading {
      margin-top: 0.5rem;
      color: var(--color-mono-60);
    }

    &Body {
      margin-top: 1rem;
    }

    &LearnMore {
      display: inline-block;
      margin: 1rem 0 2rem;
      color: var(--color-primary-blue-50);

      &Inner {
        display: flex;
        align-items: center;
      }

      &Info {
        width: 0.75rem;
        height: 0.75rem;
        position: relative;
        display: block;
        margin: 0 4px 0 0;
      }

      span {
        text-decoration: underline;
      }
    }

    &GaugeSubheadingLeft {
      margin-bottom: 0.5rem;
    }
    &GaugeSubheadingRight {
      margin-bottom: 6px;
    }
  }
`;

/** ============================================================================
 * @component
 * todo: animations
 * @return {node}
 */
const NFTPurchaseFragments = ({
  nftStatus,
  visible = true,
  estimatedDisplayPrice,
  enrichedProduct,
  //
  heading,
  subheading,
  body,
  background = `beige`
}) => {
  const { setActiveOverlay, userIsLoggedIn } = useApp();
  const { useGetSaleData, useGetFractionsAmountFromPrice } = blockchainHooks();

  const { data: saleData } = useGetSaleData(enrichedProduct?.nftData, false);
  const { data: estimatedFractionsAmount } = useGetFractionsAmountFromPrice(enrichedProduct);

  const { fragments, fragmentsAvailable, fragmentDisplayPrice } = useSaleData(saleData);

  const [expanded, setExpanded] = useState(false);
  const [estimatedFragmentPrice, setEstimatedFragmentPrice] = useState(null);

  const fragmentsOnSale = nftStatus?.is([`OnFractionsSale`, `OnFractionsSale`]);
  const OnFractionsSaleSuccess = nftStatus?.is(`OnFractionsSaleSuccess`);
  const displayFragments = nftStatus?.is(`OnFractionsSaleSuccess`) ? 0 : fragmentsAvailable;

  useEffect(() => {
    const wholePrice = parseInt(estimatedDisplayPrice);
    const fragmentsAmount = parseInt(estimatedFractionsAmount?.toString());
    if (fragmentsAmount) {
      setEstimatedFragmentPrice(wholePrice / fragmentsAmount);
    }
  }, [estimatedDisplayPrice, estimatedFractionsAmount?.toString()]);

  // ---------------------------------------------------------------------------
  // render

  const softCap = nftStatus?.is(`OnFractionsSale`) ? saleData?.saleMinFractions?.toString() : null;

  return (
    <Container
      connected={!!userIsLoggedIn}
      expanded={expanded}
      style={{
        background: `var(--color-${background})`,
        display: visible ? `block` : `none`
      }}
    >
      <div className="nftPurchaseFragments">
        <div className="nftPurchaseFragmentsFlex">
          <header className="nftPurchaseFragmentsColLeft">
            <button type="button" onClick={() => setExpanded(!expanded)} className="nftPurchaseFragmentsColLeftExpander">
              <SVG svg={expanded ? `minus` : `plus`} />
            </button>

            {heading && <h3 className="b1">{heading}</h3>}

            <div className="nftPurchaseFragmentsExpandable">
              {subheading && <h4 className="b2 nftPurchaseFragmentsSubheading">{subheading}</h4>}

              {body && <p className="caption nftPurchaseFragmentsBody">{body}</p>}

              <Link
                to="https://lucidao.medium.com/what-are-fractionalised-nfts-and-why-should-you-care-12634800738f"
                type="button"
                className="nftPurchaseFragmentsLearnMore"
              >
                <div className="nftPurchaseFragmentsLearnMoreInner">
                  <SVG svg="info" className="nftPurchaseFragmentsLearnMoreInfo" />
                  <span className="button-text">Learn More</span>
                </div>
              </Link>
            </div>
          </header>
        </div>
      </div>

      {!nftStatus.is([`OnFractionsSaleSuccess`]) && (saleData || estimatedFractionsAmount) && (
        <div className="nftPurchaseFragments">
          <div className="nftPurchaseFragmentsFlex">
            <div className="nftPurchaseFragmentsColLeft">
              <h4 className="nftPurchaseFragmentsGaugeSubheadingLeft caption">
                {/* {!saleData && estimatedFractionsAmount ? `ESTIMATED ` : ``} */}
                FRAGMENTS{` `}
                {fragmentsAvailable === fragments || (!saleData && estimatedFractionsAmount) ? `AVAILABLE` : `REMAINING`}
              </h4>

              <NFTFragmentGauge
                actual={saleData && typeof saleData !== `undefined` ? Number(displayFragments) : Number(estimatedFractionsAmount?.toString())}
                total={saleData && typeof saleData !== `undefined` ? Number(fragments) : Number(estimatedFractionsAmount?.toString())}
              />

              {softCap && (
                <h4 className="nftPurchaseFragmentsMeta caption">
                  <span>Soft cap: </span>
                  <span className="nftPurchaseFragmentsMetaCount">{softCap} Fragments</span>
                </h4>
              )}
            </div>

            {!OnFractionsSaleSuccess && (
              <div className="nftPurchaseFragmentsColRight">
                <h4 className="nftPurchaseFragmentsGaugeSubheadingRight caption">
                  {/* {!saleData && estimatedDisplayPrice ? `ESTIMATED ` : ``} */}
                  FRAGMENT PRICE
                </h4>

                <PriceWidget displayPrice={saleData ? fragmentDisplayPrice : estimatedFragmentPrice} fontClass="h2" />
              </div>
            )}
          </div>

          {userIsLoggedIn && fragmentsOnSale && (
            <div className="nftPurchaseFragmentsActions">
              <Button onClick={() => setActiveOverlay(`NFTOverlayPurchaseFragment`)} className="nftPurchaseFragmentsButton" fluid colorTheme="dark">
                <span className="b1">Buy Fragment</span>
              </Button>
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

export default NFTPurchaseFragments;
