import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Button } from "~components";
import { useApp } from "~hooks";
import { KycStatus } from "~dApp/models/profile/KycStatus";

const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 1.5rem;
  border-radius: 12px;

  .NFTActionVerifyIdentity {
    &Body {
      margin: 0.5rem 0 ${({ withButton }) => `${withButton ? `1.5rem` : `0`}`};
      margin-bottom: 0.5rem;

      span {
        margin-right: 0.5rem;
        font-style: italic;
        color: var(--color-mono-60);
      }

      a {
        text-decoration: underline;
      }
    }

    &Dot {
      margin-left: 0.5rem;
    }

    &Error {
      color: red;
    }

    &Flex {
      display: flex;
      align-items: center;
    }

    // todo: button refactor
    &Button {
      background: var(--color-black);

      span {
        color: var(--color-white) !important;
      }

      &Disabled {
        background: var(--color-mono-20);
        border: 1px solid var(--color-mono-20);
      }
    }
  }

  p {
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTActionVerifyIdentity = ({ nftStatus }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state
  const { setActiveOverlay, userData, kycData } = useApp();

  const { kycSession, kycStatus, kycTestMode } = kycData;

  // ---------------------------------------------------------------------------
  // methods
  const noEmail =
    nftStatus?.is(`NoEmail`) ||
    kycStatus === KycStatus.NO_EMAIL ||
    !userData?.user?.emailAddress;

  const onClick = () => {
    if (noEmail) {
      setActiveOverlay(`NFTOverlayLeaveEmail`);
    } else {
      setActiveOverlay(`NFTOverlayWhitelist`);
    }
  };
  // @externalintegration
  // this is placeholder, should be replaced with real KYC states
  const getText = () => {
    if (noEmail) {
      return {
        heading: `Verify Your Identity`,
        body: `We ensure a safe environment by checking sanction lists without storing your data or associating it with your on-chain identity.`,
        learnMore: false
      };
    }
    switch (kycStatus) {
      case KycStatus.PENDING:
        return {
          heading: `Verify your identity`,
          body: `We ensure a safe environment by checking sanction lists without storing your data or associating it with your on-chain identity.`,
          learnMore: false
        };

      case KycStatus.VERIFYING:
      case KycStatus.SUBMITTED:
      case KycStatus.VALIDATED:
        return {
          heading: `Verifying Your Identity`,
          body: `We're verifying your identity.`,
          learnMore: false
        };

      case KycStatus.VERIFIED:
        return {
          heading: `Verified`,
          body: `Thank you for completing the identity verification process. You will be notified of your allowlist registration soon.`,
          learnMore: false
        };
      case KycStatus.REJECTED:
      case KycStatus.FINAL_REJECTED:
        return {
          heading: `Verification Rejected`,
          body: `Sorry, we're unable to verify your identity.`,
          learnMore: false
        };
      default:
        return ``;
    }
  };

  const text = getText();

  const disabled = !kycTestMode && !kycSession?.sessionId && !noEmail;

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container withButton={kycStatus === KycStatus.PENDING}>
      <p className="NFTActionVerifyIdentityBody">
        <span>{text.body}</span>
      </p>

      {(kycStatus === KycStatus.PENDING || noEmail) && (
        <Button
          className={`NFTActionVerifyIdentityButton ${
            disabled ? `NFTActionVerifyIdentityButtonDisabled` : ``
          }`}
          onClick={() => onClick()}
          fluid
          disabled={disabled}
          variant="feature"
          colorTheme="dark"
        >
          <span className="b1">{text.heading}</span>
        </Button>
      )}
    </Container>
  );
};

export default NFTActionVerifyIdentity;
