/* eslint-disable import/no-extraneous-dependencies */

import React from "react";
import { NFTInventoryCard } from "~components";
import ProfileCollectionDigitise from "../ProfileCollectionDigitise";

/** ============================================================================
 * @component
 */
const ProfileCollectionGrid = ({ inventory = [] }) => (
  <ul className="nftGrid">
    {inventory.map((item) => (
      <li key={`grid-${item.tokenId}-${item.contract.address}`}>
        <NFTInventoryCard item={item} />
      </li>
    ))}
    <li>
      <ProfileCollectionDigitise />
    </li>
  </ul>
);

export default ProfileCollectionGrid;
