import React from "react";
import { css, Global } from "@emotion/react";
import { Animations, Colors, Fonts } from "~components";

const Theme = () => (
  <>
    <Global
      styles={css`
        html {
          font-size: 16px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-rendering: optimizeLegibility;
        }

        body {
          //
        }

        a {
          text-decoration: none;
          color: inherit;
          cursor: pointer;
        }

        button {
          background-color: transparent;
          color: inherit;
          border-width: 0;
          padding: 0;
          cursor: pointer;
        }

        a:focus,
        button:focus,
        input:focus,
        textarea:focus {
          outline: none;
        }

        input:not[type="checkbox"],
        textarea {
          appearance: none;
          border-radius: 0;
        }

        figure {
          margin: 0;
        }

        input::-moz-focus-inner {
          border: 0;
          padding: 0;
          margin: 0;
        }

        ul,
        ol,
        dd {
          margin: 0;
          padding: 0;
          list-style: none;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          margin: 0;
          font-size: inherit;
          font-weight: inherit;
        }

        p {
          margin: 0;
        }

        fieldset {
          border-width: 0;
          padding: 0;
          margin: 0;
        }

        .frosted-glass-light {
          background: rgba(240, 240, 240, 0.1);
          box-shadow: 1.5px 3.5px 3.5px rgba(0, 0, 0, 0.25);
          backdrop-filter: blur(45px);
          -webkit-backdrop-filter: blur(45px);
        }

        .frosted-glass-dark {
          background: rgba(0, 0, 0, 0.1);
          box-shadow: 1.5px 3.5px 3.5px rgba(0, 0, 0, 0.25);
          backdrop-filter: blur(45px);
          -webkit-backdrop-filter: blur(45px);
        }
      `}
    />

    <Animations />
    <Colors />
    <Fonts />
  </>
);

export default Theme;
