import useWindowDimensions from "~hooks/useWindowDimensions";
import NFTActivityListDesktop from "../NFTActivityListDesktop";
import NFTActivityListMobile from "../NFTActivityListMobile";

const NFTActivityList = ({
  nftStatus,
  id,
  rows,
  buttonLabel,
  alternativeButtonLabel,
  secondaryAction,
  handleSecondaryActivityItemClick = () => {},
  handleActivityItemClick = () => {}
}) => {
  const { isDesktop } = useWindowDimensions();

  return isDesktop ? (
    <NFTActivityListDesktop
      nftStatus={nftStatus}
      id={id}
      rows={rows}
      buttonLabel={buttonLabel}
      alternativeButtonLabel={alternativeButtonLabel}
      handleActivityItemClick={handleActivityItemClick}
      handleSecondaryActivityItemClick={handleSecondaryActivityItemClick}
      secondaryAction={secondaryAction}
    />
  ) : (
    <NFTActivityListMobile
      nftStatus={nftStatus}
      id={id}
      rows={rows}
      buttonLabel={buttonLabel}
      alternativeButtonLabel={alternativeButtonLabel}
      handleActivityItemClick={handleActivityItemClick}
      handleSecondaryActivityItemClick={handleSecondaryActivityItemClick}
      secondaryAction={secondaryAction}
    />
  );
};

export default NFTActivityList;
