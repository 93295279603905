import React from "react";
import styled from "@emotion/styled";
import { NFTStatusDot } from "~components";
import { RaffleStatus } from "~dApp/models/raffle/RaffleStatus";

const Container = styled.div`
  position: relative;
  .nftStatus {
    padding: 0.375rem 0.5rem;
    display: flex;
    align-items: center;
    border-radius: 4px;
    text-transform: uppercase;

    &Dot {
      margin: 0 0.25rem;
    }

    &Info {
      width: 0.75rem;
      height: 0.75rem;
      position: relative;
      display: block;
      margin: 0 0 0 0.25rem;
    }
  }
`;

const statusTextAndColorMap = {
  [RaffleStatus.NotStarted]: { text: `Not started`, color: `#ffa500` },
  [RaffleStatus.OnSale]: { text: `Active`, color: `#00ff00` },
  [RaffleStatus.PersonalMaxCapReached]: { text: `Personal max cap reached`, color: `#ff0000` },
  [RaffleStatus.Failed]: { text: `Not Achieved`, color: `#ff0000` },
  [RaffleStatus.Success]: { text: `Calculating winner`, color: `#ffff00` },
  [RaffleStatus.WinnerSelected]: { text: `Winner selected`, color: `#00ff00` },
  default: { text: `Unavailable`, color: `#cccccc` }
};

/** ============================================================================
 * @component
 * @return {node}
 */
const RaffleStatusBadge = ({ className = ``, raffleStatus }) => {
  const { text, color } = statusTextAndColorMap[raffleStatus] || statusTextAndColorMap.default;

  return (
    <Container className={className}>
      <div className="caption nftStatus">
        <span>STATUS: </span>
        <NFTStatusDot id="NFTStatusBadge" className="nftStatusDot" hex={color} />
        <span>{text}</span>
      </div>
    </Container>
  );
};

export default RaffleStatusBadge;
