import React from "react";
import styled from "@emotion/styled";
import { NFTFragmentGauge, PriceWidget, SVG, Button, Loader, EventDropDown } from "~components";
import { useAccount } from "wagmi";
import { breakpoint } from "~utils/css";
import useApp from "~hooks/useApp";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  position: relative;
  padding: 1.5rem 1.5rem 2.5rem;
  border-radius: 1rem;

  .nftPurchaseInfo {
    &Flex {
      display: block;
      ${breakpoint(`tablet`)} {
        display: flex;
      }
    }

    &Button {
      width: 100%;
      position: relative;
      display: block;
      padding: 1rem 1.5rem;
      margin: 1.5rem 0 0.5rem;
    }

    &Price {
      display: flex;

      &Heading {
        margin-bottom: 0.5rem;
      }

      &Value {
        width: 40%;
        position: relative;
      }
    }

    &Subheading,
    &Body {
      color: var(--color-mono-60);
    }

    &Subheading {
      margin-top: 0.5rem;
      color: var(--color-mono-60);
    }

    &Body {
      margin-top: 1rem;
    }

    &LearnMore {
      display: inline-block;
      margin: 1rem 0 2rem;
      color: var(--color-primary-blue-50);

      &Inner {
        display: flex;
        align-items: center;
      }

      &Info {
        width: 0.75rem;
        height: 0.75rem;
        position: relative;
        display: block;
        margin: 0 4px 0 0;
      }

      a {
        text-decoration: underline;
      }
    }

    &GaugeSubheadingLeft {
      margin-bottom: 0.5rem;
    }
    &GaugeSubheadingRight {
      margin-bottom: 6px;
    }

    &ColLeft {
      width: 100%;
      position: realtive;
      order: 1;

      ${breakpoint(`tablet`)} {
        width: calc(60% - 1rem);
        margin-right: 1rem;
      }

      &Flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &ColRight {
      width: 90%;
      position: relative;
      margin-bottom: 1rem;
      order: 2;

      ${breakpoint(`tablet`)} {
        width: 40%;
      }

      &Flex {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    //

    &Controls {
      display: flex;
      align-items: stretch;
      margin-bottom: 2rem;
      gap: 0 0.5rem;

      & > div {
        width: 100%;
        flex-basis: 50%;
      }

      button,
      button div {
        height: 100%;
      }
    }

    &Body {
      margin-top: 1rem;
    }

    &PriceSubheading {
      margin-bottom: 4px;
    }
  }
`;

/** ============================================================================
 * @component
 * todo: animations
 * @return {node}
 */
const EventPurchaseInfo = ({
  nft,
  nftStatus,
  visible = true,
  //
  heading,
  title,
  background = `beige`,
  //
  ticketAvailable,
  maxTicketSupply,
  displayPrice,
  selected,
  setSelected,
  loading
}) => {
  const { isConnected } = useAccount();
  const { setActiveOverlay, setConnectorActive, userIsLoggedIn } = useApp();

  const initialSale = nftStatus?.is([`OnSale`]);

  return (
    <Container
      style={{
        background: `var(--color-${background})`,
        display: visible ? `block` : `none`
      }}
    >
      <div className="nftPurchaseInfo">
        <div className="nftPurchaseInfoFlex">
          <header className="nftPurchaseInfoColLeft">
            {title && <h3 className="b1">{title}</h3>}

            <button type="button" className="nftPurchaseInfoLearnMore">
              <div className="nftPurchaseInfoLearnMoreInner">
                <SVG svg="info" className="nftPurchaseInfoLearnMoreInfo" />
                <a href="https://discord.com/invite/lucidao" target="_blank" className="button-text" rel="noreferrer">
                  Need assistance?
                </a>
              </div>
            </button>
          </header>
          <div className="nftPurchaseInfoColRight">
            {heading && <h4 className="b1">{heading}</h4>}

            <div className="b2 nftPurchaseInfoSubheadingRight">
              <EventDropDown selected={selected} setSelected={setSelected} dropDownItems={nft.enrichedEvent?.ids}>
                Select Ticket
              </EventDropDown>
            </div>
          </div>
        </div>
      </div>

      {initialSale && (
        <div className="nftPurchaseInfo">
          <div className="nftPurchaseInfoFlex nftPurchaseInfoControls">
            <Button
              onClick={() => {
                if (!userIsLoggedIn || !isConnected) {
                  setConnectorActive(true);
                } else {
                  setActiveOverlay(`EventOverlayPurchase`);
                }
              }}
              fluid
              colorTheme="dark"
              disabled={ticketAvailable === 0}
            >
              <span className="b1">Buy with USDt</span>
            </Button>

            <Button
              onClick={() => {
                setActiveOverlay(`EventOverlayPurchaseFiat`);
              }}
              fluid
              variant="feature"
              colorTheme="dark"
              disabled={ticketAvailable === 0}
            >
              <span className="b1">Buy with FIAT</span>
            </Button>
          </div>
        </div>
      )}
      {loading ? (
        <Loader inline visible={loading} />
      ) : (
        <div className="nftPurchaseInfo">
          <div className="nftPurchaseInfoFlex">
            <div className="nftPurchaseInfoColRight">
              <h4 className="nftPurchaseInfoGaugeSubheadingRight caption">TICKET PRICE</h4>

              <PriceWidget displayPrice={displayPrice} fontClass="h2" />
            </div>

            <div className="nftPurchaseInfoColLeft">
              <h4 className="nftPurchaseInfoGaugeSubheadingLeft caption">
                TICKETS{` `}
                {ticketAvailable === maxTicketSupply ? `AVAILABLE` : `REMAINING`}
              </h4>

              <NFTFragmentGauge actual={ticketAvailable} total={maxTicketSupply} />
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default EventPurchaseInfo;
