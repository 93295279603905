import React, { useEffect, useState } from "react";
import cn from "classnames";
import { formatCurrency } from "~utils/helpers";
import { blockchainHooks } from "~hooks/blockchainHooks";
import useApp from "~hooks/useApp";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTUserData = ({ className = ``, fragments = null, nft }) => {
  const { useGetPurchaseTokenBalance } = blockchainHooks();

  const [displayCurrency, setDisplayCurrency] = useState(``);
  const [displayAmount, setDisplayAmount] = useState();
  const [isEnabled, setIsEnabled] = useState(false);

  const { activeOverlay } = useApp();

  const { data: balance, isLoading: isBalanceLoading } = useGetPurchaseTokenBalance(nft.enrichedProduct.nftData, isEnabled);

  useEffect(() => {
    setDisplayCurrency(`USDt`);
    setDisplayAmount(formatCurrency(isBalanceLoading ? 0 : +balance?.formatted));
  }, [balance?.formatted]);

  useEffect(() => {
    function getterManager() {
      setIsEnabled(true);
      setTimeout(() => {
        setIsEnabled(false);
      }, 1000);
    }
    getterManager();
    const interval = setInterval(getterManager, 10000);
    return () => clearInterval(interval);
  }, []);

  if (!displayAmount || activeOverlay?.includes(`Fiat`)) {
    return null;
  }

  return (
    <section className={cn(`userData`, className)}>
      <dl>
        <dt>{displayCurrency}</dt>
        <dd>{displayAmount}</dd>
      </dl>
      {fragments && (
        <dl>
          <dt>Fragments</dt>
          <dd>{fragments}</dd>
        </dl>
      )}
    </section>
  );
};

export default NFTUserData;
