import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { SVG } from "~components";

/** ============================================================================
 * @css
 */
const Button = styled.button`
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: all 0.2s;

  &:focus-visible {
    box-shadow: 0 0 0 2px red;
  }

  ${({ color }) => {
    if (color === `light`) {
      return css`
        color: var(--color-white);
        background: var(--color-primary-orange-100);

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: var(--color-primary-orange-100);
            background: var(--color-white);
          }
        }
      `;
    }
    return css`
      color: var(--color-white);
      background: var(--color-black);

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: var(--color-black);
          background: var(--color-primary-orange-100);
        }
      }
    `;
  }}

  ${({ color, disabled }) => {
    if (!disabled) return null;
    if (color === `light`) {
      return css`
        color: var(--color-mono-40);
        background: var(--color-mono-20);
        pointer-events: none;
      `;
    }
    return css`
      color: var(--color-mono-60);
      background: var(--color-mono-40);
      pointer-events: none;
    `;
  }}
`;

const Icon = styled.div`
  width: 16px;
  height: 16px;
`;

/** ============================================================================
 * @component
 * @param  {string}    color   					"light" | "dark"
 * @param  {string}    className    		Can be an identifier, Emotion CSS, or both
 * @param  {function}  onClick      		Click listener function
 * @param  {boolean}   disabled     		Disable button
 * @return {node}
 */

const PlusButton = ({ color = `light`, className, onClick, disabled }) => (
  <Button onClick={onClick} color={color} type="button" disabled={disabled} className={className || null}>
    <Icon>
      <SVG svg="plus" />
    </Icon>
  </Button>
);

export default PlusButton;
