import { graphql, useStaticQuery } from "gatsby";

const useSanityProducts = () => {
  // ---------------------------------------------------------------------------
  // graphql data

  const { allSanityProduct } = useStaticQuery(graphql`
    query useSanityProductsQuery {
      allSanityProduct {
        edges {
          node {
            ...SanityProductFragment
          }
        }
      }
    }
  `);

  // ---------------------------------------------------------------------------
  // methods

  const flattenedData = () => (allSanityProduct?.edges?.[0] ? allSanityProduct.edges.map(({ node }) => node) : []);

  const getSanityProductById = (guid) => {
    const sanityProducts = flattenedData();

    if (!sanityProducts?.[0]) {
      return null;
    }

    return sanityProducts.find(({ productGuid }) => productGuid === guid);
  };

  // ---------------------------------------------------------------------------

  return {
    allSanityProduct,
    flattenedSanityProducts: flattenedData(),
    getSanityProductById
  };
};

export default useSanityProducts;
