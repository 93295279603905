import React from "react";
import styled from "@emotion/styled";
import { SVG } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  color: var(--color-mono-20);

  .transactionHistoryItem {
    &RepeatIcon {
      width: 20px;
      margin-right: 12px;
    }

    &ProductName {
      color: var(--color-white);
      margin-bottom: 1rem;
    }
  }
`;

const TopRow = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-right: 12px;
  }
`;

const Partition = styled.div`
  border-top: 1px solid var(--color-mono-90);
  margin: 1rem 0;
`;

const InfoItem = styled.li`
  border-bottom: 1px solid var(--color-mono-90);
  margin-bottom: 8px;
  padding-bottom: 8px;
  color: var(--color-mono-40);

  .transactionHistoryItemInfoItemContent {
    max-width: 600px;
    display: flex;
    justify-content: space-between;

    span {
      flex: 1;
    }
  }
`;

/** ============================================================================
 * @component
 * @param  {string}    date   					Date of transaction, format `DD/MM/YY`
 * @param  {string}    time   					Time of transaction, format `HH:MM UTC` (24 hour)
 * @param  {string}    productName			Name of product
 * @param  {string}    fractions				Number of fractions
 * @param  {string}    vendor						Vendor name
 * @param  {string}    from							Sold from
 * @param  {string}    to								Sold to
 * @param  {string}    transactionType	Transaction type
 * @param  {string}    price						Price of transaction, format `00,000 USDt`
 * @return {node}
 */

const TransactionHistoryItem = ({ date, time, productName, fractions, vendor, from, to, transactionType, price }) => (
  <Container>
    <TopRow className="b2">
      <SVG className="transactionHistoryItemRepeatIcon" svg="repeat" />
      <span>{date}</span>
      <span>{time}</span>
    </TopRow>
    <Partition />
    <h4 className="h4 transactionHistoryItemProductName">{productName}</h4>
    <div className="caption">{fractions} fractions</div>
    <Partition />
    <ul>
      <InfoItem>
        <div className="caption transactionHistoryItemInfoItemContent">
          <span>Vendor</span>
          <span>{vendor}</span>
        </div>
      </InfoItem>
      <InfoItem>
        <div className="caption transactionHistoryItemInfoItemContent">
          <span>Fractions</span>
          <span>{fractions}</span>
        </div>
      </InfoItem>
      <InfoItem>
        <div className="caption transactionHistoryItemInfoItemContent">
          <span>From</span>
          <span>{from}</span>
        </div>
      </InfoItem>
      <InfoItem>
        <div className="caption transactionHistoryItemInfoItemContent">
          <span>To</span>
          <span>{to}</span>
        </div>
      </InfoItem>
      <InfoItem>
        <div className="caption transactionHistoryItemInfoItemContent">
          <span>Transaction Type</span>
          <span>{transactionType}</span>
        </div>
      </InfoItem>
      <InfoItem>
        <div className="caption transactionHistoryItemInfoItemContent">
          <span>Price</span>
          <span>{price}</span>
        </div>
      </InfoItem>
    </ul>
  </Container>
);

export default TransactionHistoryItem;
