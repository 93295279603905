import { createContext, useContext } from "react";

const NftContext = createContext();

const NftProvider = ({ children, items }) => <NftContext.Provider value={items}>{children}</NftContext.Provider>;

export const useNftContext = () => {
  const data = useContext(NftContext);

  return data;
};

export default NftProvider;
