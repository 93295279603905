import React from "react";
import cn from "classnames";
import styled from "@emotion/styled";
import { Image } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.header`
  position: relative;

  .imageRatioImage {
    position: absolute;
    inset: 0;
  }
`;

/** ============================================================================
 * @component
 * @param  {object}    image   							GatsbyImageData
 * @param  {string}    altText   						Alt text for the image
 * @param  {string}    ratio   							The ratio of the image, e.g. "16/9"
 * @param  {string}    className   					Any additional classes to add to the container
 */

const ImageRatio = ({ image, altText, ratio = `1/1`, className }) => {
  const getRatioPadding = () => {
    const splitString = ratio.split(`/`);
    if (splitString.length !== 2) {
      return `0%`;
    }
    const xRatio = parseInt(splitString[0]);
    const yRatio = parseInt(splitString[1]);
    const ratioFraction = yRatio / xRatio;
    const ratioPercentage = ratioFraction * 100;
    return `${ratioPercentage}%`;
  };

  return (
    <Container
      className={cn(className)}
      style={{
        paddingBottom: getRatioPadding()
      }}
    >
      <Image className="imageRatioImage" image={image} alt={altText || ``} fill />
    </Container>
  );
};

export default ImageRatio;
