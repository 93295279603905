import React, { useEffect } from "react";
import cn from "classnames";
import { useApp } from "~hooks";
import { NFTOverlaySidebar, Modal, SVG, Balances } from "~components";

/** ============================================================================
 * @component NFTOverlay
 * Wrapping component for all overlays exposed in NFTOverlays/index.jsx.
 * @return {node}
 */
const NFTOverlay = ({ children, id, heading, nft, sidebarMode, onActive }) => {
  const { activeOverlay, setActiveOverlay } = useApp();

  // ---------------------------------------------------------------------------
  // lifecycle

  // This re-enables page scrolling after leaving the overlay view
  // useEffect(() => setActiveOverlay(null), []);

  // ---------------------------------------------------------------------------
  // render

  const active = activeOverlay === id;

  useEffect(() => {
    if (active && onActive) {
      onActive();
    }
  }, [active]);

  return (
    <Modal className="nftOverlayContainer" onClose={() => setActiveOverlay(null)} isVisible={active} hideHeader>
      <Modal.Content className={cn(`nftOverlay`, id)}>
        <Balances className="nftOverlayBalances" />
        <div className="nftOverlayContentWrapper">
          <div className="nftOverlayClose">
            <button type="button" onClick={() => setActiveOverlay(null)}>
              <span className="visually-hidden">Close</span>
              <SVG className="closeSvg" svg="closeIcon" />
            </button>
          </div>
          <div className="nftOverlayContent">
            <NFTOverlaySidebar heading={heading} nft={nft} mode={sidebarMode || null} />
            <div className="main">{typeof children === `function` ? children({ active }) : children}</div>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default NFTOverlay;
