import React from "react";
import styled from "@emotion/styled";
import { TransactionHistoryItem } from "~components";

/** ============================================================================
 * @css
 */
const Wrapper = styled.div`
  margin-bottom: 4rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

/** ============================================================================
 * @component
 * @param  {transactionHistoryItem[]}    transactionHistoryItems    Array of transaction history items (see subcomponent for schema)
 * @return {node}
 */

const TransactionHistoryTable = ({ transactionHistoryItems }) => (
  <section>
    {transactionHistoryItems?.map((item, i) => (
      <Wrapper key={i}>
        <TransactionHistoryItem {...item} />
      </Wrapper>
    ))}
  </section>
);

export default TransactionHistoryTable;
