import React, { useEffect, useState } from "react";
import { useApp, useFragments } from "~hooks";
import { NFTOverlaySidebarItem } from "~components";
import { genericErrorCallback, thousandCommas, formatStablecoin } from "~utils/helpers";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { blockchainHooks } from "~hooks/blockchainHooks";

const MetaInfo = ({ tag, value }) => (
  <p className="meta">
    <span className="tag">{tag}</span>
    <span className="value">{value}</span>
  </p>
);

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlaySidebar = ({ nft, mode, heading }) => {
  const { enrichedProduct, nftStatus } = nft || {};
  const { product, nftData } = enrichedProduct || {};
  const { nftCollectionInfo, saleId, tokenId } = nftData || {};
  const { fragmentsPrice, price, oracle, nftImage, numberOfFragments } = product || {};

  const { useGetFractionsBalance, useGetSaleData } = blockchainHooks();
  const { overlayActivityData, userData } = useApp();
  const { crypto, graph } = useExternalIntegrations();
  const { fragments, fragmentsAvailable } = useFragments(nft);
  const { data: fractionsBalance } = useGetFractionsBalance(nftData, overlayActivityData?.owner ? overlayActivityData.owner.address : userData.address);
  const { data: saleData } = useGetSaleData(nftData, false);

  const [lastSellOrder, setLastSellOrder] = useState(null);
  const [value, setValue] = useState(null);

  const fetchTransactionHistory = async () => {
    const isFragmented = [`OnFractionsSale`, `OnFractionsSaleSuccess`].includes(nftStatus?.status);
    const contractAddress = isFragmented ? nftCollectionInfo.fractionSaleAddresses.fractionsContractAddress : nftCollectionInfo.collectionAddress;
    const computedTokenId = isFragmented ? saleId : tokenId;
    const transactions0xes = await graph.get0xTransactionsByNftId(contractAddress, computedTokenId, `desc`);
    setLastSellOrder(transactions0xes.length ? transactions0xes[0] : null);
  };

  const fetchValuation = async () => {
    const valuation = await crypto.getOracleValuation(nftData);
    setValue(valuation);
  };

  const formatDate = (timestamp) => new Date(parseInt(timestamp) * 1000).toLocaleDateString(`en-US`);

  const formatPrice = (nftPrice, isStablecoin = false) => `$${thousandCommas(isStablecoin ? formatStablecoin(nftPrice, true) : nftPrice)}`;

  useEffect(() => {
    if (!nft?.enrichedProduct?.nftData || mode !== `offer`) return;

    const fetchData = async () => {
      if (!lastSellOrder) {
        await fetchTransactionHistory();
      }
      if (!value) {
        await fetchValuation();
      }
    };

    fetchData().catch(genericErrorCallback);
  }, [JSON.stringify(nft?.enrichedProduct?.nftData)]);

  return (
    <div className="sidebar">
      <header>
        <strong className="caption">{heading}</strong>
      </header>
      <NFTOverlaySidebarItem
        name={product?.productName}
        imageUrl={nftImage?.publicUrl}
        oracleName={oracle?.text}
        fragments={[`transfer`, `createSellOrderFragment`].includes(mode) ? fractionsBalance : fragmentsAvailable}
        totalFragments={fragments}
      />
      {mode === `offer` && lastSellOrder?.erc20TokenAmount && (
        <MetaInfo
          tag={`SOLD ON ${formatDate(lastSellOrder.timestamp)} for`}
          value={
            formatPrice(BigInt(lastSellOrder?.erc20TokenAmount || 0) / (1n - BigInt(nft?.enrichedProduct?.nftData?.makerFee || 0) / 100n), true).split(`.`)[0]
          }
        />
      )}
      {mode === `offer` && value && <MetaInfo tag="Oracle's evaluation" value={formatPrice(value)} />}
      {mode === `fragment` && fragments && fragmentsAvailable && <MetaInfo tag="Fragments remaining" value={`${fragmentsAvailable} / ${fragments}`} />}
      {mode === `fragment` && saleData?.saleMinFractions && <MetaInfo tag="Soft cap" value={`${saleData.saleMinFractions} fragments`} />}
      {mode === `fragment` && fragmentsPrice && <MetaInfo tag="Fragment price" value={formatPrice(fragmentsPrice)} />}
      {mode === `fragmentFromOwner` && numberOfFragments && (
        <MetaInfo tag="Fragments" value={overlayActivityData?.owner ? fractionsBalance?.toString() : numberOfFragments} />
      )}
      {mode === `fragmentFromOwner` && fragmentsPrice && <MetaInfo tag="Initial fragment price" value={formatPrice(fragmentsPrice)} />}
      {mode === `fragmentFromSellOrder` && overlayActivityData?.fragments && <MetaInfo tag="Fragments available" value={overlayActivityData.fragments} />}
      {mode === `fragmentFromSellOrder` && fragmentsPrice && <MetaInfo tag="Initial fragment price" value={formatPrice(fragmentsPrice)} />}
      {mode === `fragmentFromBuyOrder` && fragmentsPrice && <MetaInfo tag="Initial fragment price" value={formatPrice(fragmentsPrice)} />}
      {/* @externalIntegration: needs real integration */}
      {mode === `fragmentRefund` && nft && <MetaInfo tag="Fragments purchased" value="5" />}
      {mode === `fragmentRefund` && fragmentsPrice && <MetaInfo tag="Fragment price" value={formatPrice(fragmentsPrice)} />}
      {mode === `createBuyOrder` && nft && <MetaInfo tag="Max. supply of fragments" value="10" />}
      {mode === `createBuyOrder` && fragmentsPrice && <MetaInfo tag="Initial fragment price" value={formatPrice(fragmentsPrice)} />}
      {mode === `buyAndClaim` && (
        <div className="meta">
          <h3 className="tag">How it works</h3>
          <p>
            Upon completion of the purchase, the buyer will be entitled to receive the physical collectible. <br />
            The collectible can be retrieved from the designated Oracle, or delivery can be arranged through the Oracle/Seller. <br />
            This constitutes the terms of possession and delivery under the purchase agreement.
          </p>
        </div>
      )}
      {mode === `createSellOrderWhole` && price?.usdAmount && <MetaInfo tag="Initial price" value={`$${thousandCommas(price.usdAmount)}`} />}
      {(mode === `createSellOrderFragment` || mode === `buyout`) && fragmentsPrice && (
        <MetaInfo tag="Initial fragment price" value={formatPrice(fragmentsPrice)} />
      )}
    </div>
  );
};

export default NFTOverlaySidebar;
