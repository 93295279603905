import React from "react";
import styled from "@emotion/styled";
import { breakpoint } from "~utils/css";
import { Countdown as CountdownComponent } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: space-between;

  .homepageAtfCountdown {
    &Ticker {
      display: none;
    }
  }

  ${breakpoint(`large-tablet`)} {
    .homepageAtfCountdown {
      &Countdown {
        width: 460px;
      }

      &Ticker {
        display: block;
      }
    }
  }
`;

/** ============================================================================
 * @component
 */
const Countdown = ({ textColor, product }) => {
  const dropDate = new Date(product.dropDate);

  const formattedDate = dropDate.toLocaleDateString(`en-US`, {
    month: `long`,
    day: `numeric`
  });

  const dropTime = dropDate.toLocaleTimeString(`en-US`);
  const formattedDropTime = dropTime.replace(/:\d\d /, ``);

  const description = `The ${product.title} by ${product.oracle.title} becomes available ${formattedDate} ${formattedDropTime}.`;

  return (
    <Container>
      <CountdownComponent description={description} product={product} colorTheme={textColor} showLogo className="homepageAtfCountdownCountdown" />
    </Container>
  );
};

export default Countdown;
