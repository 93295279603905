import React from "react";
import cn from "classnames";
import { formatCryptoCurrency, TARGET_CHAIN } from "~utils/helpers";
import useApp from "~hooks/useApp";

const Balances = ({ className }) => {
  const {
    userData: {
      balances: { nativeBalance, usdtBalance }
    }
  } = useApp();

  return (
    <div className={cn(className, `balances`)}>
      <span>USDt: {formatCryptoCurrency(usdtBalance?.formatted || 0)}</span>
      <span>
        {TARGET_CHAIN.nativeCurrency.symbol}: {formatCryptoCurrency(nativeBalance?.formatted || 0)}
      </span>
    </div>
  );
};

export default Balances;
