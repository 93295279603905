import { useEffect, useState } from "react";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { OracleCategories } from "~dApp/models/oracle/OracleCategories";

const useOracle = (guid, oracleType = OracleCategories.Merchant) => {
  const { api } = useExternalIntegrations();

  const [oracleData, setOracleData] = useState(null);

  useEffect(() => {
    const getOracleByGUID = async () => {
      if (oracleType.toLocaleLowerCase() === OracleCategories.Expert.toLocaleLowerCase()) {
        const productExpert = await api.getProductExpertById(guid);
        setOracleData(productExpert);
      } else {
        const oracle = await api.getOracleById(guid);
        setOracleData(oracle);
      }
    };
    getOracleByGUID().catch((e) => console.error(e));
  }, []);

  return oracleData;
};

export default useOracle;
