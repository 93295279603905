import Button from "~components/Common/Button";

const ApproveTransfer = () => (
  <section className="offer">
    <h3>Approve Transfer</h3>
    <p>Lorem ipsum dolor sit amet consectetur. Orci est sed quam turpis tristique mauris felis eget.</p>
    <Button variant="primaryTall" fluid>
      Approve Transfer
    </Button>
  </section>
);

export default ApproveTransfer;
