// @ts-nocheck
import { GraphQLResolveInfo, SelectionSetNode, FieldNode, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { gql } from '@graphql-mesh/utils';

import type { GetMeshOptions } from '@graphql-mesh/runtime';
import type { YamlConfig } from '@graphql-mesh/types';
import { PubSub } from '@graphql-mesh/utils';
import { DefaultLogger } from '@graphql-mesh/utils';
import MeshCache from "@graphql-mesh/cache-localforage";
import { fetch as fetchFn } from '@whatwg-node/fetch';

import { MeshResolvedSource } from '@graphql-mesh/runtime';
import { MeshTransform, MeshPlugin } from '@graphql-mesh/types';
import GraphqlHandler from "@graphql-mesh/graphql"
import StitchingMerger from "@graphql-mesh/merger-stitching";
import { printWithCache } from '@graphql-mesh/utils';
import { usePersistedOperations } from '@graphql-yoga/plugin-persisted-operations';
import { createMeshHTTPHandler, MeshHTTPHandler } from '@graphql-mesh/http';
import { getMesh, ExecuteMeshFn, SubscribeMeshFn, MeshContext as BaseMeshContext, MeshInstance } from '@graphql-mesh/runtime';
import { MeshStore, FsStoreStorageAdapter } from '@graphql-mesh/store';
import { path as pathModule } from '@graphql-mesh/cross-helpers';
import { ImportFn } from '@graphql-mesh/types';
import type { AltrLendTypes } from './sources/altr-lend/types';
import type { AltrTypes } from './sources/altr/types';
import * as importedModule$0 from "./sources/altr-lend/introspectionSchema";
import * as importedModule$1 from "./sources/altr/introspectionSchema";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };



/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigDecimal: { input: any; output: any; }
  BigInt: { input: any; output: any; }
  Bytes: { input: any; output: any; }
  Int8: { input: any; output: any; }
  Timestamp: { input: any; output: any; }
};

export type Query = {
  user?: Maybe<User>;
  users: Array<User>;
  oracle?: Maybe<Oracle>;
  oracles: Array<Oracle>;
  specialUser?: Maybe<SpecialUser>;
  specialUsers: Array<SpecialUser>;
  erc721?: Maybe<ERC721>;
  erc721S: Array<ERC721>;
  erc1155?: Maybe<ERC1155>;
  erc1155S: Array<ERC1155>;
  sale?: Maybe<Sale>;
  sales: Array<Sale>;
  buyout?: Maybe<Buyout>;
  buyouts: Array<Buyout>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
  redeemRequest?: Maybe<RedeemRequest>;
  redeemRequests: Array<RedeemRequest>;
  role?: Maybe<Role>;
  roles: Array<Role>;
  order0X?: Maybe<Order0x>;
  order0Xes: Array<Order0x>;
  transactionFee0X?: Maybe<TransactionFee0x>;
  transactionFee0Xes: Array<TransactionFee0x>;
  transaction0X?: Maybe<Transaction0x>;
  transaction0Xes: Array<Transaction0x>;
  purchaseEvent?: Maybe<PurchaseEvent>;
  purchaseEvents: Array<PurchaseEvent>;
  referral?: Maybe<Referral>;
  referrals: Array<Referral>;
  raffle?: Maybe<Raffle>;
  raffles: Array<Raffle>;
  staker?: Maybe<Staker>;
  stakers: Array<Staker>;
  stakingEvent?: Maybe<StakingEvent>;
  stakingEvents: Array<StakingEvent>;
  stakingParam?: Maybe<StakingParam>;
  stakingParams: Array<StakingParam>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  params?: Maybe<Params>;
  params_collection: Array<Params>;
  loan?: Maybe<Loan>;
  loans: Array<Loan>;
  event?: Maybe<Event>;
  events: Array<Event>;
  collection?: Maybe<Collection>;
  collections: Array<Collection>;
  nft?: Maybe<Nft>;
  nfts: Array<Nft>;
  valuation?: Maybe<Valuation>;
  valuations: Array<Valuation>;
};


export type QueryuserArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryusersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<User_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<User_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryoracleArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryoraclesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Oracle_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Oracle_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryspecialUserArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryspecialUsersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpecialUser_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SpecialUser_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Queryerc721Args = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Queryerc721SArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ERC721_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC721_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Queryerc1155Args = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Queryerc1155SArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ERC1155_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC1155_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerysaleArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerysalesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Sale_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Sale_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerybuyoutArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerybuyoutsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Buyout_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Buyout_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytransactionArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytransactionsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Transaction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Transaction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryredeemRequestArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryredeemRequestsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RedeemRequest_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<RedeemRequest_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryroleArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryrolesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Role_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Role_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Queryorder0XArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Queryorder0XesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Order0x_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Order0x_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytransactionFee0XArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerytransactionFee0XesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TransactionFee0x_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TransactionFee0x_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Querytransaction0XArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Querytransaction0XesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Transaction0x_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Transaction0x_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerypurchaseEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerypurchaseEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PurchaseEvent_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PurchaseEvent_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryreferralArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryreferralsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Referral_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Referral_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryraffleArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryrafflesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Raffle_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Raffle_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerystakerArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerystakersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Staker_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Staker_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerystakingEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerystakingEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<StakingEvent_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<StakingEvent_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerystakingParamArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerystakingParamsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<StakingParam_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<StakingParam_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Query_metaArgs = {
  block?: InputMaybe<Block_height>;
};


export type QueryparamsArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Queryparams_collectionArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Params_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Params_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryloanArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryloansArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Loan_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Loan_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryeventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryeventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Event_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Event_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycollectionArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerycollectionsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Collection_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Collection_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QuerynftsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Nft_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Nft_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryvaluationArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type QueryvaluationsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Valuation_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Valuation_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};

export type Subscription = {
  user?: Maybe<User>;
  users: Array<User>;
  oracle?: Maybe<Oracle>;
  oracles: Array<Oracle>;
  specialUser?: Maybe<SpecialUser>;
  specialUsers: Array<SpecialUser>;
  erc721?: Maybe<ERC721>;
  erc721S: Array<ERC721>;
  erc1155?: Maybe<ERC1155>;
  erc1155S: Array<ERC1155>;
  sale?: Maybe<Sale>;
  sales: Array<Sale>;
  buyout?: Maybe<Buyout>;
  buyouts: Array<Buyout>;
  transaction?: Maybe<Transaction>;
  transactions: Array<Transaction>;
  redeemRequest?: Maybe<RedeemRequest>;
  redeemRequests: Array<RedeemRequest>;
  role?: Maybe<Role>;
  roles: Array<Role>;
  order0X?: Maybe<Order0x>;
  order0Xes: Array<Order0x>;
  transactionFee0X?: Maybe<TransactionFee0x>;
  transactionFee0Xes: Array<TransactionFee0x>;
  transaction0X?: Maybe<Transaction0x>;
  transaction0Xes: Array<Transaction0x>;
  purchaseEvent?: Maybe<PurchaseEvent>;
  purchaseEvents: Array<PurchaseEvent>;
  referral?: Maybe<Referral>;
  referrals: Array<Referral>;
  raffle?: Maybe<Raffle>;
  raffles: Array<Raffle>;
  staker?: Maybe<Staker>;
  stakers: Array<Staker>;
  stakingEvent?: Maybe<StakingEvent>;
  stakingEvents: Array<StakingEvent>;
  stakingParam?: Maybe<StakingParam>;
  stakingParams: Array<StakingParam>;
  /** Access to subgraph metadata */
  _meta?: Maybe<_Meta_>;
  params?: Maybe<Params>;
  params_collection: Array<Params>;
  loan?: Maybe<Loan>;
  loans: Array<Loan>;
  event?: Maybe<Event>;
  events: Array<Event>;
  collection?: Maybe<Collection>;
  collections: Array<Collection>;
  nft?: Maybe<Nft>;
  nfts: Array<Nft>;
  valuation?: Maybe<Valuation>;
  valuations: Array<Valuation>;
};


export type SubscriptionuserArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionusersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<User_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<User_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionoracleArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionoraclesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Oracle_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Oracle_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionspecialUserArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionspecialUsersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpecialUser_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SpecialUser_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscriptionerc721Args = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscriptionerc721SArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ERC721_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC721_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscriptionerc1155Args = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscriptionerc1155SArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ERC1155_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC1155_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionsaleArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionsalesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Sale_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Sale_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionbuyoutArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionbuyoutsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Buyout_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Buyout_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontransactionArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontransactionsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Transaction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Transaction_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionredeemRequestArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionredeemRequestsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<RedeemRequest_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<RedeemRequest_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionroleArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionrolesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Role_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Role_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscriptionorder0XArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscriptionorder0XesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Order0x_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Order0x_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontransactionFee0XArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptiontransactionFee0XesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TransactionFee0x_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TransactionFee0x_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscriptiontransaction0XArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscriptiontransaction0XesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Transaction0x_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Transaction0x_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionpurchaseEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionpurchaseEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<PurchaseEvent_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<PurchaseEvent_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionreferralArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionreferralsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Referral_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Referral_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionraffleArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionrafflesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Raffle_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Raffle_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionstakerArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionstakersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Staker_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Staker_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionstakingEventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionstakingEventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<StakingEvent_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<StakingEvent_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionstakingParamArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionstakingParamsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<StakingParam_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<StakingParam_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscription_metaArgs = {
  block?: InputMaybe<Block_height>;
};


export type SubscriptionparamsArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type Subscriptionparams_collectionArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Params_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Params_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionloanArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionloansArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Loan_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Loan_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioneventArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioneventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Event_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Event_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncollectionArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptioncollectionsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Collection_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Collection_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionnftsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Nft_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Nft_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionvaluationArgs = {
  id: Scalars['ID']['input'];
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};


export type SubscriptionvaluationsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Valuation_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Valuation_filter>;
  block?: InputMaybe<Block_height>;
  subgraphError?: _SubgraphErrorPolicy_;
};

export type Aggregation_interval =
  | 'hour'
  | 'day';

export type BlockChangedFilter = {
  number_gte: Scalars['Int']['input'];
};

export type Block_height = {
  hash?: InputMaybe<Scalars['Bytes']['input']>;
  number?: InputMaybe<Scalars['Int']['input']>;
  number_gte?: InputMaybe<Scalars['Int']['input']>;
};

export type Buyout = {
  id: Scalars['ID']['output'];
  buyoutId: Scalars['BigInt']['output'];
  erc1155: ERC1155;
  openingTime?: Maybe<Scalars['BigInt']['output']>;
  closingTime?: Maybe<Scalars['BigInt']['output']>;
  isSuccessful: Scalars['Boolean']['output'];
  initiator: User;
  buyoutPrice?: Maybe<Scalars['BigInt']['output']>;
  buyoutToken?: Maybe<Scalars['Bytes']['output']>;
  buyoutTokenManager?: Maybe<Scalars['Bytes']['output']>;
};

export type Buyout_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  buyoutId?: InputMaybe<Scalars['BigInt']['input']>;
  buyoutId_not?: InputMaybe<Scalars['BigInt']['input']>;
  buyoutId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  buyoutId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  buyoutId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  buyoutId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  buyoutId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  buyoutId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155?: InputMaybe<Scalars['String']['input']>;
  erc1155_not?: InputMaybe<Scalars['String']['input']>;
  erc1155_gt?: InputMaybe<Scalars['String']['input']>;
  erc1155_lt?: InputMaybe<Scalars['String']['input']>;
  erc1155_gte?: InputMaybe<Scalars['String']['input']>;
  erc1155_lte?: InputMaybe<Scalars['String']['input']>;
  erc1155_in?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_contains?: InputMaybe<Scalars['String']['input']>;
  erc1155_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_contains?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_starts_with?: InputMaybe<Scalars['String']['input']>;
  erc1155_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc1155_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_?: InputMaybe<ERC1155_filter>;
  openingTime?: InputMaybe<Scalars['BigInt']['input']>;
  openingTime_not?: InputMaybe<Scalars['BigInt']['input']>;
  openingTime_gt?: InputMaybe<Scalars['BigInt']['input']>;
  openingTime_lt?: InputMaybe<Scalars['BigInt']['input']>;
  openingTime_gte?: InputMaybe<Scalars['BigInt']['input']>;
  openingTime_lte?: InputMaybe<Scalars['BigInt']['input']>;
  openingTime_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  openingTime_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  closingTime?: InputMaybe<Scalars['BigInt']['input']>;
  closingTime_not?: InputMaybe<Scalars['BigInt']['input']>;
  closingTime_gt?: InputMaybe<Scalars['BigInt']['input']>;
  closingTime_lt?: InputMaybe<Scalars['BigInt']['input']>;
  closingTime_gte?: InputMaybe<Scalars['BigInt']['input']>;
  closingTime_lte?: InputMaybe<Scalars['BigInt']['input']>;
  closingTime_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  closingTime_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  isSuccessful?: InputMaybe<Scalars['Boolean']['input']>;
  isSuccessful_not?: InputMaybe<Scalars['Boolean']['input']>;
  isSuccessful_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isSuccessful_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  initiator?: InputMaybe<Scalars['String']['input']>;
  initiator_not?: InputMaybe<Scalars['String']['input']>;
  initiator_gt?: InputMaybe<Scalars['String']['input']>;
  initiator_lt?: InputMaybe<Scalars['String']['input']>;
  initiator_gte?: InputMaybe<Scalars['String']['input']>;
  initiator_lte?: InputMaybe<Scalars['String']['input']>;
  initiator_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  initiator_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains?: InputMaybe<Scalars['String']['input']>;
  initiator_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  initiator_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  initiator_?: InputMaybe<User_filter>;
  buyoutPrice?: InputMaybe<Scalars['BigInt']['input']>;
  buyoutPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  buyoutPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  buyoutPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  buyoutPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  buyoutPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  buyoutPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  buyoutPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  buyoutToken?: InputMaybe<Scalars['Bytes']['input']>;
  buyoutToken_not?: InputMaybe<Scalars['Bytes']['input']>;
  buyoutToken_gt?: InputMaybe<Scalars['Bytes']['input']>;
  buyoutToken_lt?: InputMaybe<Scalars['Bytes']['input']>;
  buyoutToken_gte?: InputMaybe<Scalars['Bytes']['input']>;
  buyoutToken_lte?: InputMaybe<Scalars['Bytes']['input']>;
  buyoutToken_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  buyoutToken_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  buyoutToken_contains?: InputMaybe<Scalars['Bytes']['input']>;
  buyoutToken_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  buyoutTokenManager?: InputMaybe<Scalars['Bytes']['input']>;
  buyoutTokenManager_not?: InputMaybe<Scalars['Bytes']['input']>;
  buyoutTokenManager_gt?: InputMaybe<Scalars['Bytes']['input']>;
  buyoutTokenManager_lt?: InputMaybe<Scalars['Bytes']['input']>;
  buyoutTokenManager_gte?: InputMaybe<Scalars['Bytes']['input']>;
  buyoutTokenManager_lte?: InputMaybe<Scalars['Bytes']['input']>;
  buyoutTokenManager_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  buyoutTokenManager_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  buyoutTokenManager_contains?: InputMaybe<Scalars['Bytes']['input']>;
  buyoutTokenManager_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Buyout_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Buyout_filter>>>;
};

export type Buyout_orderBy =
  | 'id'
  | 'buyoutId'
  | 'erc1155'
  | 'erc1155__id'
  | 'erc1155__contractAddress'
  | 'erc1155__tokenId'
  | 'erc1155__supply'
  | 'openingTime'
  | 'closingTime'
  | 'isSuccessful'
  | 'initiator'
  | 'initiator__id'
  | 'initiator__address'
  | 'initiator__allowed'
  | 'buyoutPrice'
  | 'buyoutToken'
  | 'buyoutTokenManager';

export type ERC1155 = {
  id: Scalars['ID']['output'];
  contractAddress: Scalars['Bytes']['output'];
  tokenId: Scalars['BigInt']['output'];
  supply?: Maybe<Scalars['BigInt']['output']>;
  owners?: Maybe<Array<User>>;
  ownersBalances?: Maybe<Array<Scalars['BigInt']['output']>>;
  erc721?: Maybe<ERC721>;
  transactionHistory?: Maybe<Array<Transaction>>;
};


export type ERC1155ownersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<User_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<User_filter>;
};


export type ERC1155transactionHistoryArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Transaction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Transaction_filter>;
};

export type ERC1155_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  contractAddress?: InputMaybe<Scalars['Bytes']['input']>;
  contractAddress_not?: InputMaybe<Scalars['Bytes']['input']>;
  contractAddress_gt?: InputMaybe<Scalars['Bytes']['input']>;
  contractAddress_lt?: InputMaybe<Scalars['Bytes']['input']>;
  contractAddress_gte?: InputMaybe<Scalars['Bytes']['input']>;
  contractAddress_lte?: InputMaybe<Scalars['Bytes']['input']>;
  contractAddress_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  contractAddress_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  contractAddress_contains?: InputMaybe<Scalars['Bytes']['input']>;
  contractAddress_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  tokenId?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_not?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tokenId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  supply?: InputMaybe<Scalars['BigInt']['input']>;
  supply_not?: InputMaybe<Scalars['BigInt']['input']>;
  supply_gt?: InputMaybe<Scalars['BigInt']['input']>;
  supply_lt?: InputMaybe<Scalars['BigInt']['input']>;
  supply_gte?: InputMaybe<Scalars['BigInt']['input']>;
  supply_lte?: InputMaybe<Scalars['BigInt']['input']>;
  supply_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  supply_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  owners?: InputMaybe<Array<Scalars['String']['input']>>;
  owners_not?: InputMaybe<Array<Scalars['String']['input']>>;
  owners_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  owners_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  owners_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  owners_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  owners_?: InputMaybe<User_filter>;
  ownersBalances?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  ownersBalances_not?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  ownersBalances_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  ownersBalances_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  ownersBalances_not_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  ownersBalances_not_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc721?: InputMaybe<Scalars['String']['input']>;
  erc721_not?: InputMaybe<Scalars['String']['input']>;
  erc721_gt?: InputMaybe<Scalars['String']['input']>;
  erc721_lt?: InputMaybe<Scalars['String']['input']>;
  erc721_gte?: InputMaybe<Scalars['String']['input']>;
  erc721_lte?: InputMaybe<Scalars['String']['input']>;
  erc721_in?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721_contains?: InputMaybe<Scalars['String']['input']>;
  erc721_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_not_contains?: InputMaybe<Scalars['String']['input']>;
  erc721_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_starts_with?: InputMaybe<Scalars['String']['input']>;
  erc721_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  erc721_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc721_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc721_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_?: InputMaybe<ERC721_filter>;
  transactionHistory?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionHistory_not?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionHistory_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionHistory_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionHistory_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionHistory_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionHistory_?: InputMaybe<Transaction_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ERC1155_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ERC1155_filter>>>;
};

export type ERC1155_orderBy =
  | 'id'
  | 'contractAddress'
  | 'tokenId'
  | 'supply'
  | 'owners'
  | 'ownersBalances'
  | 'erc721'
  | 'erc721__id'
  | 'erc721__contractAddress'
  | 'erc721__tokenId'
  | 'transactionHistory';

export type ERC721 = {
  id: Scalars['ID']['output'];
  contractAddress: Scalars['Bytes']['output'];
  tokenId: Scalars['BigInt']['output'];
  owner: User;
  approved?: Maybe<User>;
  operators?: Maybe<Array<User>>;
  erc1155?: Maybe<ERC1155>;
  transactionHistory?: Maybe<Array<Transaction>>;
};


export type ERC721operatorsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<User_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<User_filter>;
};


export type ERC721transactionHistoryArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Transaction_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Transaction_filter>;
};

export type ERC721_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  contractAddress?: InputMaybe<Scalars['Bytes']['input']>;
  contractAddress_not?: InputMaybe<Scalars['Bytes']['input']>;
  contractAddress_gt?: InputMaybe<Scalars['Bytes']['input']>;
  contractAddress_lt?: InputMaybe<Scalars['Bytes']['input']>;
  contractAddress_gte?: InputMaybe<Scalars['Bytes']['input']>;
  contractAddress_lte?: InputMaybe<Scalars['Bytes']['input']>;
  contractAddress_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  contractAddress_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  contractAddress_contains?: InputMaybe<Scalars['Bytes']['input']>;
  contractAddress_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  tokenId?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_not?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tokenId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  owner?: InputMaybe<Scalars['String']['input']>;
  owner_not?: InputMaybe<Scalars['String']['input']>;
  owner_gt?: InputMaybe<Scalars['String']['input']>;
  owner_lt?: InputMaybe<Scalars['String']['input']>;
  owner_gte?: InputMaybe<Scalars['String']['input']>;
  owner_lte?: InputMaybe<Scalars['String']['input']>;
  owner_in?: InputMaybe<Array<Scalars['String']['input']>>;
  owner_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  owner_contains?: InputMaybe<Scalars['String']['input']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_contains?: InputMaybe<Scalars['String']['input']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_starts_with?: InputMaybe<Scalars['String']['input']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_ends_with?: InputMaybe<Scalars['String']['input']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_?: InputMaybe<User_filter>;
  approved?: InputMaybe<Scalars['String']['input']>;
  approved_not?: InputMaybe<Scalars['String']['input']>;
  approved_gt?: InputMaybe<Scalars['String']['input']>;
  approved_lt?: InputMaybe<Scalars['String']['input']>;
  approved_gte?: InputMaybe<Scalars['String']['input']>;
  approved_lte?: InputMaybe<Scalars['String']['input']>;
  approved_in?: InputMaybe<Array<Scalars['String']['input']>>;
  approved_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  approved_contains?: InputMaybe<Scalars['String']['input']>;
  approved_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  approved_not_contains?: InputMaybe<Scalars['String']['input']>;
  approved_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  approved_starts_with?: InputMaybe<Scalars['String']['input']>;
  approved_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  approved_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  approved_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  approved_ends_with?: InputMaybe<Scalars['String']['input']>;
  approved_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  approved_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  approved_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  approved_?: InputMaybe<User_filter>;
  operators?: InputMaybe<Array<Scalars['String']['input']>>;
  operators_not?: InputMaybe<Array<Scalars['String']['input']>>;
  operators_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  operators_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  operators_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  operators_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  operators_?: InputMaybe<User_filter>;
  erc1155?: InputMaybe<Scalars['String']['input']>;
  erc1155_not?: InputMaybe<Scalars['String']['input']>;
  erc1155_gt?: InputMaybe<Scalars['String']['input']>;
  erc1155_lt?: InputMaybe<Scalars['String']['input']>;
  erc1155_gte?: InputMaybe<Scalars['String']['input']>;
  erc1155_lte?: InputMaybe<Scalars['String']['input']>;
  erc1155_in?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_contains?: InputMaybe<Scalars['String']['input']>;
  erc1155_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_contains?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_starts_with?: InputMaybe<Scalars['String']['input']>;
  erc1155_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc1155_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_?: InputMaybe<ERC1155_filter>;
  transactionHistory?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionHistory_not?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionHistory_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionHistory_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionHistory_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionHistory_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  transactionHistory_?: InputMaybe<Transaction_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<ERC721_filter>>>;
  or?: InputMaybe<Array<InputMaybe<ERC721_filter>>>;
};

export type ERC721_orderBy =
  | 'id'
  | 'contractAddress'
  | 'tokenId'
  | 'owner'
  | 'owner__id'
  | 'owner__address'
  | 'owner__allowed'
  | 'approved'
  | 'approved__id'
  | 'approved__address'
  | 'approved__allowed'
  | 'operators'
  | 'erc1155'
  | 'erc1155__id'
  | 'erc1155__contractAddress'
  | 'erc1155__tokenId'
  | 'erc1155__supply'
  | 'transactionHistory';

export type Oracle = {
  id: Scalars['ID']['output'];
  address: Scalars['Bytes']['output'];
  erc721Owned?: Maybe<Array<ERC721>>;
  erc1155?: Maybe<Array<ERC1155>>;
  erc1155Balance?: Maybe<Array<Scalars['BigInt']['output']>>;
  allowed: Scalars['Boolean']['output'];
  erc721: Array<Scalars['Bytes']['output']>;
};


export type Oracleerc721OwnedArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ERC721_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC721_filter>;
};


export type Oracleerc1155Args = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ERC1155_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC1155_filter>;
};

export type Oracle_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  address?: InputMaybe<Scalars['Bytes']['input']>;
  address_not?: InputMaybe<Scalars['Bytes']['input']>;
  address_gt?: InputMaybe<Scalars['Bytes']['input']>;
  address_lt?: InputMaybe<Scalars['Bytes']['input']>;
  address_gte?: InputMaybe<Scalars['Bytes']['input']>;
  address_lte?: InputMaybe<Scalars['Bytes']['input']>;
  address_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  address_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  address_contains?: InputMaybe<Scalars['Bytes']['input']>;
  address_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  erc721Owned?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_not?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_?: InputMaybe<ERC721_filter>;
  erc1155?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_not?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_?: InputMaybe<ERC1155_filter>;
  erc1155Balance?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Balance_not?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Balance_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Balance_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Balance_not_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Balance_not_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  allowed?: InputMaybe<Scalars['Boolean']['input']>;
  allowed_not?: InputMaybe<Scalars['Boolean']['input']>;
  allowed_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  allowed_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  erc721?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  erc721_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  erc721_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  erc721_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  erc721_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  erc721_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Oracle_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Oracle_filter>>>;
};

export type Oracle_orderBy =
  | 'id'
  | 'address'
  | 'erc721Owned'
  | 'erc1155'
  | 'erc1155Balance'
  | 'allowed'
  | 'erc721';

export type Order0x = {
  id: Scalars['ID']['output'];
  maker: User;
  taker?: Maybe<User>;
  direction?: Maybe<Scalars['BigInt']['output']>;
  nonce: Scalars['BigInt']['output'];
  cancelled: Scalars['Boolean']['output'];
  filled: Scalars['Boolean']['output'];
  timestamp?: Maybe<Scalars['BigInt']['output']>;
  erc20Token?: Maybe<Scalars['Bytes']['output']>;
  erc20FilledAmount?: Maybe<Scalars['BigInt']['output']>;
  nftTokenType?: Maybe<Scalars['String']['output']>;
  nftToken?: Maybe<Scalars['Bytes']['output']>;
  nftTokenId?: Maybe<Scalars['BigInt']['output']>;
  nftTokenFilledAmount?: Maybe<Scalars['BigInt']['output']>;
};

export type Order0x_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  maker?: InputMaybe<Scalars['String']['input']>;
  maker_not?: InputMaybe<Scalars['String']['input']>;
  maker_gt?: InputMaybe<Scalars['String']['input']>;
  maker_lt?: InputMaybe<Scalars['String']['input']>;
  maker_gte?: InputMaybe<Scalars['String']['input']>;
  maker_lte?: InputMaybe<Scalars['String']['input']>;
  maker_in?: InputMaybe<Array<Scalars['String']['input']>>;
  maker_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  maker_contains?: InputMaybe<Scalars['String']['input']>;
  maker_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  maker_not_contains?: InputMaybe<Scalars['String']['input']>;
  maker_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  maker_starts_with?: InputMaybe<Scalars['String']['input']>;
  maker_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  maker_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  maker_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  maker_ends_with?: InputMaybe<Scalars['String']['input']>;
  maker_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  maker_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  maker_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  maker_?: InputMaybe<User_filter>;
  taker?: InputMaybe<Scalars['String']['input']>;
  taker_not?: InputMaybe<Scalars['String']['input']>;
  taker_gt?: InputMaybe<Scalars['String']['input']>;
  taker_lt?: InputMaybe<Scalars['String']['input']>;
  taker_gte?: InputMaybe<Scalars['String']['input']>;
  taker_lte?: InputMaybe<Scalars['String']['input']>;
  taker_in?: InputMaybe<Array<Scalars['String']['input']>>;
  taker_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  taker_contains?: InputMaybe<Scalars['String']['input']>;
  taker_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  taker_not_contains?: InputMaybe<Scalars['String']['input']>;
  taker_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  taker_starts_with?: InputMaybe<Scalars['String']['input']>;
  taker_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  taker_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  taker_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  taker_ends_with?: InputMaybe<Scalars['String']['input']>;
  taker_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  taker_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  taker_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  taker_?: InputMaybe<User_filter>;
  direction?: InputMaybe<Scalars['BigInt']['input']>;
  direction_not?: InputMaybe<Scalars['BigInt']['input']>;
  direction_gt?: InputMaybe<Scalars['BigInt']['input']>;
  direction_lt?: InputMaybe<Scalars['BigInt']['input']>;
  direction_gte?: InputMaybe<Scalars['BigInt']['input']>;
  direction_lte?: InputMaybe<Scalars['BigInt']['input']>;
  direction_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  direction_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  nonce?: InputMaybe<Scalars['BigInt']['input']>;
  nonce_not?: InputMaybe<Scalars['BigInt']['input']>;
  nonce_gt?: InputMaybe<Scalars['BigInt']['input']>;
  nonce_lt?: InputMaybe<Scalars['BigInt']['input']>;
  nonce_gte?: InputMaybe<Scalars['BigInt']['input']>;
  nonce_lte?: InputMaybe<Scalars['BigInt']['input']>;
  nonce_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  nonce_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  cancelled?: InputMaybe<Scalars['Boolean']['input']>;
  cancelled_not?: InputMaybe<Scalars['Boolean']['input']>;
  cancelled_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  cancelled_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  filled?: InputMaybe<Scalars['Boolean']['input']>;
  filled_not?: InputMaybe<Scalars['Boolean']['input']>;
  filled_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  filled_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc20Token?: InputMaybe<Scalars['Bytes']['input']>;
  erc20Token_not?: InputMaybe<Scalars['Bytes']['input']>;
  erc20Token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  erc20Token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  erc20Token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  erc20Token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  erc20Token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  erc20Token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  erc20Token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  erc20Token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  erc20FilledAmount?: InputMaybe<Scalars['BigInt']['input']>;
  erc20FilledAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  erc20FilledAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  erc20FilledAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  erc20FilledAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  erc20FilledAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  erc20FilledAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc20FilledAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  nftTokenType?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_not?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_gt?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_lt?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_gte?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_lte?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_in?: InputMaybe<Array<Scalars['String']['input']>>;
  nftTokenType_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  nftTokenType_contains?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_not_contains?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_starts_with?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_ends_with?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  nftToken?: InputMaybe<Scalars['Bytes']['input']>;
  nftToken_not?: InputMaybe<Scalars['Bytes']['input']>;
  nftToken_gt?: InputMaybe<Scalars['Bytes']['input']>;
  nftToken_lt?: InputMaybe<Scalars['Bytes']['input']>;
  nftToken_gte?: InputMaybe<Scalars['Bytes']['input']>;
  nftToken_lte?: InputMaybe<Scalars['Bytes']['input']>;
  nftToken_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  nftToken_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  nftToken_contains?: InputMaybe<Scalars['Bytes']['input']>;
  nftToken_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  nftTokenId?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenId_not?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  nftTokenId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  nftTokenFilledAmount?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenFilledAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenFilledAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenFilledAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenFilledAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenFilledAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenFilledAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  nftTokenFilledAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Order0x_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Order0x_filter>>>;
};

export type Order0x_orderBy =
  | 'id'
  | 'maker'
  | 'maker__id'
  | 'maker__address'
  | 'maker__allowed'
  | 'taker'
  | 'taker__id'
  | 'taker__address'
  | 'taker__allowed'
  | 'direction'
  | 'nonce'
  | 'cancelled'
  | 'filled'
  | 'timestamp'
  | 'erc20Token'
  | 'erc20FilledAmount'
  | 'nftTokenType'
  | 'nftToken'
  | 'nftTokenId'
  | 'nftTokenFilledAmount';

/** Defines the order direction, either ascending or descending */
export type OrderDirection =
  | 'asc'
  | 'desc';

export type PurchaseEvent = {
  id: Scalars['ID']['output'];
  raffleId: Scalars['BigInt']['output'];
  buyer: Scalars['Bytes']['output'];
  referrer: Scalars['Bytes']['output'];
  ticketAmount: Scalars['BigInt']['output'];
  price: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
};

export type PurchaseEvent_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  raffleId?: InputMaybe<Scalars['BigInt']['input']>;
  raffleId_not?: InputMaybe<Scalars['BigInt']['input']>;
  raffleId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  raffleId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  raffleId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  raffleId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  raffleId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  raffleId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  buyer?: InputMaybe<Scalars['Bytes']['input']>;
  buyer_not?: InputMaybe<Scalars['Bytes']['input']>;
  buyer_gt?: InputMaybe<Scalars['Bytes']['input']>;
  buyer_lt?: InputMaybe<Scalars['Bytes']['input']>;
  buyer_gte?: InputMaybe<Scalars['Bytes']['input']>;
  buyer_lte?: InputMaybe<Scalars['Bytes']['input']>;
  buyer_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  buyer_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  buyer_contains?: InputMaybe<Scalars['Bytes']['input']>;
  buyer_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  referrer?: InputMaybe<Scalars['Bytes']['input']>;
  referrer_not?: InputMaybe<Scalars['Bytes']['input']>;
  referrer_gt?: InputMaybe<Scalars['Bytes']['input']>;
  referrer_lt?: InputMaybe<Scalars['Bytes']['input']>;
  referrer_gte?: InputMaybe<Scalars['Bytes']['input']>;
  referrer_lte?: InputMaybe<Scalars['Bytes']['input']>;
  referrer_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  referrer_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  referrer_contains?: InputMaybe<Scalars['Bytes']['input']>;
  referrer_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  ticketAmount?: InputMaybe<Scalars['BigInt']['input']>;
  ticketAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  ticketAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  ticketAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  ticketAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  ticketAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  ticketAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  ticketAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  price?: InputMaybe<Scalars['BigInt']['input']>;
  price_not?: InputMaybe<Scalars['BigInt']['input']>;
  price_gt?: InputMaybe<Scalars['BigInt']['input']>;
  price_lt?: InputMaybe<Scalars['BigInt']['input']>;
  price_gte?: InputMaybe<Scalars['BigInt']['input']>;
  price_lte?: InputMaybe<Scalars['BigInt']['input']>;
  price_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  price_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<PurchaseEvent_filter>>>;
  or?: InputMaybe<Array<InputMaybe<PurchaseEvent_filter>>>;
};

export type PurchaseEvent_orderBy =
  | 'id'
  | 'raffleId'
  | 'buyer'
  | 'referrer'
  | 'ticketAmount'
  | 'price'
  | 'timestamp';

export type Raffle = {
  id: Scalars['ID']['output'];
  raffleTicketPurchase: Scalars['Bytes']['output'];
  raffleRewarder?: Maybe<Scalars['Bytes']['output']>;
  status?: Maybe<RaffleStatus>;
  purchaseToken: Scalars['Bytes']['output'];
  ticketPrice: Scalars['BigInt']['output'];
  startTimestamp: Scalars['BigInt']['output'];
  finishTimestamp: Scalars['BigInt']['output'];
  minCap: Scalars['BigInt']['output'];
  maxCap: Scalars['BigInt']['output'];
  personalMaxCap: Scalars['BigInt']['output'];
  prize?: Maybe<ERC721>;
  participantsProof?: Maybe<Scalars['Bytes']['output']>;
  winner?: Maybe<Scalars['Bytes']['output']>;
};

export type RaffleStatus =
  | 'SALE_PENDING'
  | 'SALE_SUCCESS'
  | 'SALE_FAIL'
  | 'WINNER_DETERMINED'
  | 'PRIZE_CLAIMED';

export type Raffle_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  raffleTicketPurchase?: InputMaybe<Scalars['Bytes']['input']>;
  raffleTicketPurchase_not?: InputMaybe<Scalars['Bytes']['input']>;
  raffleTicketPurchase_gt?: InputMaybe<Scalars['Bytes']['input']>;
  raffleTicketPurchase_lt?: InputMaybe<Scalars['Bytes']['input']>;
  raffleTicketPurchase_gte?: InputMaybe<Scalars['Bytes']['input']>;
  raffleTicketPurchase_lte?: InputMaybe<Scalars['Bytes']['input']>;
  raffleTicketPurchase_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  raffleTicketPurchase_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  raffleTicketPurchase_contains?: InputMaybe<Scalars['Bytes']['input']>;
  raffleTicketPurchase_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  raffleRewarder?: InputMaybe<Scalars['Bytes']['input']>;
  raffleRewarder_not?: InputMaybe<Scalars['Bytes']['input']>;
  raffleRewarder_gt?: InputMaybe<Scalars['Bytes']['input']>;
  raffleRewarder_lt?: InputMaybe<Scalars['Bytes']['input']>;
  raffleRewarder_gte?: InputMaybe<Scalars['Bytes']['input']>;
  raffleRewarder_lte?: InputMaybe<Scalars['Bytes']['input']>;
  raffleRewarder_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  raffleRewarder_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  raffleRewarder_contains?: InputMaybe<Scalars['Bytes']['input']>;
  raffleRewarder_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  status?: InputMaybe<RaffleStatus>;
  status_not?: InputMaybe<RaffleStatus>;
  status_in?: InputMaybe<Array<RaffleStatus>>;
  status_not_in?: InputMaybe<Array<RaffleStatus>>;
  purchaseToken?: InputMaybe<Scalars['Bytes']['input']>;
  purchaseToken_not?: InputMaybe<Scalars['Bytes']['input']>;
  purchaseToken_gt?: InputMaybe<Scalars['Bytes']['input']>;
  purchaseToken_lt?: InputMaybe<Scalars['Bytes']['input']>;
  purchaseToken_gte?: InputMaybe<Scalars['Bytes']['input']>;
  purchaseToken_lte?: InputMaybe<Scalars['Bytes']['input']>;
  purchaseToken_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  purchaseToken_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  purchaseToken_contains?: InputMaybe<Scalars['Bytes']['input']>;
  purchaseToken_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  ticketPrice?: InputMaybe<Scalars['BigInt']['input']>;
  ticketPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  ticketPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  ticketPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  ticketPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  ticketPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  ticketPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  ticketPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  startTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  startTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  startTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  startTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  startTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  startTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  finishTimestamp?: InputMaybe<Scalars['BigInt']['input']>;
  finishTimestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  finishTimestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  finishTimestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  finishTimestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  finishTimestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  finishTimestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  finishTimestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  minCap?: InputMaybe<Scalars['BigInt']['input']>;
  minCap_not?: InputMaybe<Scalars['BigInt']['input']>;
  minCap_gt?: InputMaybe<Scalars['BigInt']['input']>;
  minCap_lt?: InputMaybe<Scalars['BigInt']['input']>;
  minCap_gte?: InputMaybe<Scalars['BigInt']['input']>;
  minCap_lte?: InputMaybe<Scalars['BigInt']['input']>;
  minCap_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  minCap_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  maxCap?: InputMaybe<Scalars['BigInt']['input']>;
  maxCap_not?: InputMaybe<Scalars['BigInt']['input']>;
  maxCap_gt?: InputMaybe<Scalars['BigInt']['input']>;
  maxCap_lt?: InputMaybe<Scalars['BigInt']['input']>;
  maxCap_gte?: InputMaybe<Scalars['BigInt']['input']>;
  maxCap_lte?: InputMaybe<Scalars['BigInt']['input']>;
  maxCap_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  maxCap_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  personalMaxCap?: InputMaybe<Scalars['BigInt']['input']>;
  personalMaxCap_not?: InputMaybe<Scalars['BigInt']['input']>;
  personalMaxCap_gt?: InputMaybe<Scalars['BigInt']['input']>;
  personalMaxCap_lt?: InputMaybe<Scalars['BigInt']['input']>;
  personalMaxCap_gte?: InputMaybe<Scalars['BigInt']['input']>;
  personalMaxCap_lte?: InputMaybe<Scalars['BigInt']['input']>;
  personalMaxCap_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  personalMaxCap_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  prize?: InputMaybe<Scalars['String']['input']>;
  prize_not?: InputMaybe<Scalars['String']['input']>;
  prize_gt?: InputMaybe<Scalars['String']['input']>;
  prize_lt?: InputMaybe<Scalars['String']['input']>;
  prize_gte?: InputMaybe<Scalars['String']['input']>;
  prize_lte?: InputMaybe<Scalars['String']['input']>;
  prize_in?: InputMaybe<Array<Scalars['String']['input']>>;
  prize_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  prize_contains?: InputMaybe<Scalars['String']['input']>;
  prize_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  prize_not_contains?: InputMaybe<Scalars['String']['input']>;
  prize_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  prize_starts_with?: InputMaybe<Scalars['String']['input']>;
  prize_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  prize_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  prize_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  prize_ends_with?: InputMaybe<Scalars['String']['input']>;
  prize_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  prize_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  prize_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  prize_?: InputMaybe<ERC721_filter>;
  participantsProof?: InputMaybe<Scalars['Bytes']['input']>;
  participantsProof_not?: InputMaybe<Scalars['Bytes']['input']>;
  participantsProof_gt?: InputMaybe<Scalars['Bytes']['input']>;
  participantsProof_lt?: InputMaybe<Scalars['Bytes']['input']>;
  participantsProof_gte?: InputMaybe<Scalars['Bytes']['input']>;
  participantsProof_lte?: InputMaybe<Scalars['Bytes']['input']>;
  participantsProof_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  participantsProof_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  participantsProof_contains?: InputMaybe<Scalars['Bytes']['input']>;
  participantsProof_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  winner?: InputMaybe<Scalars['Bytes']['input']>;
  winner_not?: InputMaybe<Scalars['Bytes']['input']>;
  winner_gt?: InputMaybe<Scalars['Bytes']['input']>;
  winner_lt?: InputMaybe<Scalars['Bytes']['input']>;
  winner_gte?: InputMaybe<Scalars['Bytes']['input']>;
  winner_lte?: InputMaybe<Scalars['Bytes']['input']>;
  winner_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  winner_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  winner_contains?: InputMaybe<Scalars['Bytes']['input']>;
  winner_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Raffle_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Raffle_filter>>>;
};

export type Raffle_orderBy =
  | 'id'
  | 'raffleTicketPurchase'
  | 'raffleRewarder'
  | 'status'
  | 'purchaseToken'
  | 'ticketPrice'
  | 'startTimestamp'
  | 'finishTimestamp'
  | 'minCap'
  | 'maxCap'
  | 'personalMaxCap'
  | 'prize'
  | 'prize__id'
  | 'prize__contractAddress'
  | 'prize__tokenId'
  | 'participantsProof'
  | 'winner';

export type RedeemRequest = {
  id: Scalars['ID']['output'];
  erc721: ERC721;
  from: User;
  operator: Scalars['Bytes']['output'];
};

export type RedeemRequest_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  erc721?: InputMaybe<Scalars['String']['input']>;
  erc721_not?: InputMaybe<Scalars['String']['input']>;
  erc721_gt?: InputMaybe<Scalars['String']['input']>;
  erc721_lt?: InputMaybe<Scalars['String']['input']>;
  erc721_gte?: InputMaybe<Scalars['String']['input']>;
  erc721_lte?: InputMaybe<Scalars['String']['input']>;
  erc721_in?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721_contains?: InputMaybe<Scalars['String']['input']>;
  erc721_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_not_contains?: InputMaybe<Scalars['String']['input']>;
  erc721_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_starts_with?: InputMaybe<Scalars['String']['input']>;
  erc721_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  erc721_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc721_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc721_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_?: InputMaybe<ERC721_filter>;
  from?: InputMaybe<Scalars['String']['input']>;
  from_not?: InputMaybe<Scalars['String']['input']>;
  from_gt?: InputMaybe<Scalars['String']['input']>;
  from_lt?: InputMaybe<Scalars['String']['input']>;
  from_gte?: InputMaybe<Scalars['String']['input']>;
  from_lte?: InputMaybe<Scalars['String']['input']>;
  from_in?: InputMaybe<Array<Scalars['String']['input']>>;
  from_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  from_contains?: InputMaybe<Scalars['String']['input']>;
  from_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  from_not_contains?: InputMaybe<Scalars['String']['input']>;
  from_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  from_starts_with?: InputMaybe<Scalars['String']['input']>;
  from_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  from_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  from_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  from_ends_with?: InputMaybe<Scalars['String']['input']>;
  from_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  from_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  from_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  from_?: InputMaybe<User_filter>;
  operator?: InputMaybe<Scalars['Bytes']['input']>;
  operator_not?: InputMaybe<Scalars['Bytes']['input']>;
  operator_gt?: InputMaybe<Scalars['Bytes']['input']>;
  operator_lt?: InputMaybe<Scalars['Bytes']['input']>;
  operator_gte?: InputMaybe<Scalars['Bytes']['input']>;
  operator_lte?: InputMaybe<Scalars['Bytes']['input']>;
  operator_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operator_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  operator_contains?: InputMaybe<Scalars['Bytes']['input']>;
  operator_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<RedeemRequest_filter>>>;
  or?: InputMaybe<Array<InputMaybe<RedeemRequest_filter>>>;
};

export type RedeemRequest_orderBy =
  | 'id'
  | 'erc721'
  | 'erc721__id'
  | 'erc721__contractAddress'
  | 'erc721__tokenId'
  | 'from'
  | 'from__id'
  | 'from__address'
  | 'from__allowed'
  | 'operator';

export type Referral = {
  id: Scalars['ID']['output'];
  raffleId: Scalars['BigInt']['output'];
  referralCode: Scalars['String']['output'];
  ticketAmount: Scalars['BigInt']['output'];
  referrerOf?: Maybe<Array<Scalars['Bytes']['output']>>;
  referralAmount: Scalars['BigInt']['output'];
};

export type Referral_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  raffleId?: InputMaybe<Scalars['BigInt']['input']>;
  raffleId_not?: InputMaybe<Scalars['BigInt']['input']>;
  raffleId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  raffleId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  raffleId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  raffleId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  raffleId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  raffleId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  referralCode?: InputMaybe<Scalars['String']['input']>;
  referralCode_not?: InputMaybe<Scalars['String']['input']>;
  referralCode_gt?: InputMaybe<Scalars['String']['input']>;
  referralCode_lt?: InputMaybe<Scalars['String']['input']>;
  referralCode_gte?: InputMaybe<Scalars['String']['input']>;
  referralCode_lte?: InputMaybe<Scalars['String']['input']>;
  referralCode_in?: InputMaybe<Array<Scalars['String']['input']>>;
  referralCode_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  referralCode_contains?: InputMaybe<Scalars['String']['input']>;
  referralCode_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  referralCode_not_contains?: InputMaybe<Scalars['String']['input']>;
  referralCode_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  referralCode_starts_with?: InputMaybe<Scalars['String']['input']>;
  referralCode_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  referralCode_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  referralCode_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  referralCode_ends_with?: InputMaybe<Scalars['String']['input']>;
  referralCode_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  referralCode_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  referralCode_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  ticketAmount?: InputMaybe<Scalars['BigInt']['input']>;
  ticketAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  ticketAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  ticketAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  ticketAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  ticketAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  ticketAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  ticketAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  referrerOf?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  referrerOf_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  referrerOf_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  referrerOf_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  referrerOf_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  referrerOf_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  referralAmount?: InputMaybe<Scalars['BigInt']['input']>;
  referralAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  referralAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  referralAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  referralAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  referralAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  referralAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  referralAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Referral_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Referral_filter>>>;
};

export type Referral_orderBy =
  | 'id'
  | 'raffleId'
  | 'referralCode'
  | 'ticketAmount'
  | 'referrerOf'
  | 'referralAmount';

export type Role = {
  id: Scalars['ID']['output'];
  contract: Scalars['Bytes']['output'];
  name: Scalars['Bytes']['output'];
  specialUsers?: Maybe<Array<SpecialUser>>;
};


export type RolespecialUsersArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<SpecialUser_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<SpecialUser_filter>;
};

export type Role_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  contract?: InputMaybe<Scalars['Bytes']['input']>;
  contract_not?: InputMaybe<Scalars['Bytes']['input']>;
  contract_gt?: InputMaybe<Scalars['Bytes']['input']>;
  contract_lt?: InputMaybe<Scalars['Bytes']['input']>;
  contract_gte?: InputMaybe<Scalars['Bytes']['input']>;
  contract_lte?: InputMaybe<Scalars['Bytes']['input']>;
  contract_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  contract_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  contract_contains?: InputMaybe<Scalars['Bytes']['input']>;
  contract_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  name?: InputMaybe<Scalars['Bytes']['input']>;
  name_not?: InputMaybe<Scalars['Bytes']['input']>;
  name_gt?: InputMaybe<Scalars['Bytes']['input']>;
  name_lt?: InputMaybe<Scalars['Bytes']['input']>;
  name_gte?: InputMaybe<Scalars['Bytes']['input']>;
  name_lte?: InputMaybe<Scalars['Bytes']['input']>;
  name_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  name_contains?: InputMaybe<Scalars['Bytes']['input']>;
  name_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  specialUsers?: InputMaybe<Array<Scalars['String']['input']>>;
  specialUsers_not?: InputMaybe<Array<Scalars['String']['input']>>;
  specialUsers_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  specialUsers_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  specialUsers_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  specialUsers_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  specialUsers_?: InputMaybe<SpecialUser_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Role_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Role_filter>>>;
};

export type Role_orderBy =
  | 'id'
  | 'contract'
  | 'name'
  | 'specialUsers';

export type Sale = {
  id: Scalars['ID']['output'];
  openingTime: Scalars['BigInt']['output'];
  closingTime: Scalars['BigInt']['output'];
  saleId: Scalars['BigInt']['output'];
  isSuccessful: Scalars['Boolean']['output'];
  buyer?: Maybe<Array<User>>;
  seller: Oracle;
  fractionPrice: Scalars['BigInt']['output'];
  fractionsAmount: Scalars['BigInt']['output'];
  fractionsSold: Scalars['BigInt']['output'];
  erc721: ERC721;
  erc1155?: Maybe<ERC1155>;
  sellerReleased?: Maybe<Scalars['Boolean']['output']>;
};


export type SalebuyerArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<User_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<User_filter>;
};

export type Sale_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  openingTime?: InputMaybe<Scalars['BigInt']['input']>;
  openingTime_not?: InputMaybe<Scalars['BigInt']['input']>;
  openingTime_gt?: InputMaybe<Scalars['BigInt']['input']>;
  openingTime_lt?: InputMaybe<Scalars['BigInt']['input']>;
  openingTime_gte?: InputMaybe<Scalars['BigInt']['input']>;
  openingTime_lte?: InputMaybe<Scalars['BigInt']['input']>;
  openingTime_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  openingTime_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  closingTime?: InputMaybe<Scalars['BigInt']['input']>;
  closingTime_not?: InputMaybe<Scalars['BigInt']['input']>;
  closingTime_gt?: InputMaybe<Scalars['BigInt']['input']>;
  closingTime_lt?: InputMaybe<Scalars['BigInt']['input']>;
  closingTime_gte?: InputMaybe<Scalars['BigInt']['input']>;
  closingTime_lte?: InputMaybe<Scalars['BigInt']['input']>;
  closingTime_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  closingTime_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  saleId?: InputMaybe<Scalars['BigInt']['input']>;
  saleId_not?: InputMaybe<Scalars['BigInt']['input']>;
  saleId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  saleId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  saleId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  saleId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  saleId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  saleId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  isSuccessful?: InputMaybe<Scalars['Boolean']['input']>;
  isSuccessful_not?: InputMaybe<Scalars['Boolean']['input']>;
  isSuccessful_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  isSuccessful_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  buyer?: InputMaybe<Array<Scalars['String']['input']>>;
  buyer_not?: InputMaybe<Array<Scalars['String']['input']>>;
  buyer_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  buyer_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  buyer_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  buyer_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  buyer_?: InputMaybe<User_filter>;
  seller?: InputMaybe<Scalars['String']['input']>;
  seller_not?: InputMaybe<Scalars['String']['input']>;
  seller_gt?: InputMaybe<Scalars['String']['input']>;
  seller_lt?: InputMaybe<Scalars['String']['input']>;
  seller_gte?: InputMaybe<Scalars['String']['input']>;
  seller_lte?: InputMaybe<Scalars['String']['input']>;
  seller_in?: InputMaybe<Array<Scalars['String']['input']>>;
  seller_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  seller_contains?: InputMaybe<Scalars['String']['input']>;
  seller_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  seller_not_contains?: InputMaybe<Scalars['String']['input']>;
  seller_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  seller_starts_with?: InputMaybe<Scalars['String']['input']>;
  seller_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  seller_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  seller_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  seller_ends_with?: InputMaybe<Scalars['String']['input']>;
  seller_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  seller_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  seller_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  seller_?: InputMaybe<Oracle_filter>;
  fractionPrice?: InputMaybe<Scalars['BigInt']['input']>;
  fractionPrice_not?: InputMaybe<Scalars['BigInt']['input']>;
  fractionPrice_gt?: InputMaybe<Scalars['BigInt']['input']>;
  fractionPrice_lt?: InputMaybe<Scalars['BigInt']['input']>;
  fractionPrice_gte?: InputMaybe<Scalars['BigInt']['input']>;
  fractionPrice_lte?: InputMaybe<Scalars['BigInt']['input']>;
  fractionPrice_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  fractionPrice_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  fractionsAmount?: InputMaybe<Scalars['BigInt']['input']>;
  fractionsAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  fractionsAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  fractionsAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  fractionsAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  fractionsAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  fractionsAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  fractionsAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  fractionsSold?: InputMaybe<Scalars['BigInt']['input']>;
  fractionsSold_not?: InputMaybe<Scalars['BigInt']['input']>;
  fractionsSold_gt?: InputMaybe<Scalars['BigInt']['input']>;
  fractionsSold_lt?: InputMaybe<Scalars['BigInt']['input']>;
  fractionsSold_gte?: InputMaybe<Scalars['BigInt']['input']>;
  fractionsSold_lte?: InputMaybe<Scalars['BigInt']['input']>;
  fractionsSold_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  fractionsSold_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc721?: InputMaybe<Scalars['String']['input']>;
  erc721_not?: InputMaybe<Scalars['String']['input']>;
  erc721_gt?: InputMaybe<Scalars['String']['input']>;
  erc721_lt?: InputMaybe<Scalars['String']['input']>;
  erc721_gte?: InputMaybe<Scalars['String']['input']>;
  erc721_lte?: InputMaybe<Scalars['String']['input']>;
  erc721_in?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721_contains?: InputMaybe<Scalars['String']['input']>;
  erc721_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_not_contains?: InputMaybe<Scalars['String']['input']>;
  erc721_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_starts_with?: InputMaybe<Scalars['String']['input']>;
  erc721_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  erc721_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc721_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc721_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_?: InputMaybe<ERC721_filter>;
  erc1155?: InputMaybe<Scalars['String']['input']>;
  erc1155_not?: InputMaybe<Scalars['String']['input']>;
  erc1155_gt?: InputMaybe<Scalars['String']['input']>;
  erc1155_lt?: InputMaybe<Scalars['String']['input']>;
  erc1155_gte?: InputMaybe<Scalars['String']['input']>;
  erc1155_lte?: InputMaybe<Scalars['String']['input']>;
  erc1155_in?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_contains?: InputMaybe<Scalars['String']['input']>;
  erc1155_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_contains?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_starts_with?: InputMaybe<Scalars['String']['input']>;
  erc1155_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc1155_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc1155_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc1155_?: InputMaybe<ERC1155_filter>;
  sellerReleased?: InputMaybe<Scalars['Boolean']['input']>;
  sellerReleased_not?: InputMaybe<Scalars['Boolean']['input']>;
  sellerReleased_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  sellerReleased_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Sale_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Sale_filter>>>;
};

export type Sale_orderBy =
  | 'id'
  | 'openingTime'
  | 'closingTime'
  | 'saleId'
  | 'isSuccessful'
  | 'buyer'
  | 'seller'
  | 'seller__id'
  | 'seller__address'
  | 'seller__allowed'
  | 'fractionPrice'
  | 'fractionsAmount'
  | 'fractionsSold'
  | 'erc721'
  | 'erc721__id'
  | 'erc721__contractAddress'
  | 'erc721__tokenId'
  | 'erc1155'
  | 'erc1155__id'
  | 'erc1155__contractAddress'
  | 'erc1155__tokenId'
  | 'erc1155__supply'
  | 'sellerReleased';

export type SpecialUser = {
  id: Scalars['ID']['output'];
  address: Scalars['Bytes']['output'];
  erc721Owned?: Maybe<Array<ERC721>>;
  erc1155?: Maybe<Array<ERC1155>>;
  erc1155Balance?: Maybe<Array<Scalars['BigInt']['output']>>;
  allowed: Scalars['Boolean']['output'];
  roles?: Maybe<Array<Role>>;
};


export type SpecialUsererc721OwnedArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ERC721_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC721_filter>;
};


export type SpecialUsererc1155Args = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ERC1155_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC1155_filter>;
};


export type SpecialUserrolesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Role_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Role_filter>;
};

export type SpecialUser_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  address?: InputMaybe<Scalars['Bytes']['input']>;
  address_not?: InputMaybe<Scalars['Bytes']['input']>;
  address_gt?: InputMaybe<Scalars['Bytes']['input']>;
  address_lt?: InputMaybe<Scalars['Bytes']['input']>;
  address_gte?: InputMaybe<Scalars['Bytes']['input']>;
  address_lte?: InputMaybe<Scalars['Bytes']['input']>;
  address_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  address_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  address_contains?: InputMaybe<Scalars['Bytes']['input']>;
  address_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  erc721Owned?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_not?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_?: InputMaybe<ERC721_filter>;
  erc1155?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_not?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_?: InputMaybe<ERC1155_filter>;
  erc1155Balance?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Balance_not?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Balance_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Balance_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Balance_not_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Balance_not_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  allowed?: InputMaybe<Scalars['Boolean']['input']>;
  allowed_not?: InputMaybe<Scalars['Boolean']['input']>;
  allowed_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  allowed_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  roles_not?: InputMaybe<Array<Scalars['String']['input']>>;
  roles_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  roles_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  roles_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  roles_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  roles_?: InputMaybe<Role_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<SpecialUser_filter>>>;
  or?: InputMaybe<Array<InputMaybe<SpecialUser_filter>>>;
};

export type SpecialUser_orderBy =
  | 'id'
  | 'address'
  | 'erc721Owned'
  | 'erc1155'
  | 'erc1155Balance'
  | 'allowed'
  | 'roles';

export type Staker = {
  id: Scalars['ID']['output'];
  stakeAmount: Scalars['BigInt']['output'];
  unclaimedRewards: Scalars['BigInt']['output'];
  lastBoostUpdate: Scalars['BigInt']['output'];
  lastUpdate: Scalars['BigInt']['output'];
};

export type Staker_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  stakeAmount?: InputMaybe<Scalars['BigInt']['input']>;
  stakeAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  stakeAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  stakeAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  stakeAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  stakeAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  stakeAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  stakeAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  unclaimedRewards?: InputMaybe<Scalars['BigInt']['input']>;
  unclaimedRewards_not?: InputMaybe<Scalars['BigInt']['input']>;
  unclaimedRewards_gt?: InputMaybe<Scalars['BigInt']['input']>;
  unclaimedRewards_lt?: InputMaybe<Scalars['BigInt']['input']>;
  unclaimedRewards_gte?: InputMaybe<Scalars['BigInt']['input']>;
  unclaimedRewards_lte?: InputMaybe<Scalars['BigInt']['input']>;
  unclaimedRewards_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  unclaimedRewards_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lastBoostUpdate?: InputMaybe<Scalars['BigInt']['input']>;
  lastBoostUpdate_not?: InputMaybe<Scalars['BigInt']['input']>;
  lastBoostUpdate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  lastBoostUpdate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  lastBoostUpdate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  lastBoostUpdate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lastBoostUpdate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lastBoostUpdate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lastUpdate?: InputMaybe<Scalars['BigInt']['input']>;
  lastUpdate_not?: InputMaybe<Scalars['BigInt']['input']>;
  lastUpdate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  lastUpdate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  lastUpdate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  lastUpdate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lastUpdate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lastUpdate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Staker_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Staker_filter>>>;
};

export type Staker_orderBy =
  | 'id'
  | 'stakeAmount'
  | 'unclaimedRewards'
  | 'lastBoostUpdate'
  | 'lastUpdate';

export type StakingEvent = {
  id: Scalars['ID']['output'];
  type: Scalars['String']['output'];
  staker: Staker;
  amount: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
};

export type StakingEvent_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_not?: InputMaybe<Scalars['String']['input']>;
  type_gt?: InputMaybe<Scalars['String']['input']>;
  type_lt?: InputMaybe<Scalars['String']['input']>;
  type_gte?: InputMaybe<Scalars['String']['input']>;
  type_lte?: InputMaybe<Scalars['String']['input']>;
  type_in?: InputMaybe<Array<Scalars['String']['input']>>;
  type_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  type_contains?: InputMaybe<Scalars['String']['input']>;
  type_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  type_not_contains?: InputMaybe<Scalars['String']['input']>;
  type_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  type_starts_with?: InputMaybe<Scalars['String']['input']>;
  type_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  type_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  type_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  type_ends_with?: InputMaybe<Scalars['String']['input']>;
  type_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  type_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  type_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  staker?: InputMaybe<Scalars['String']['input']>;
  staker_not?: InputMaybe<Scalars['String']['input']>;
  staker_gt?: InputMaybe<Scalars['String']['input']>;
  staker_lt?: InputMaybe<Scalars['String']['input']>;
  staker_gte?: InputMaybe<Scalars['String']['input']>;
  staker_lte?: InputMaybe<Scalars['String']['input']>;
  staker_in?: InputMaybe<Array<Scalars['String']['input']>>;
  staker_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  staker_contains?: InputMaybe<Scalars['String']['input']>;
  staker_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_not_contains?: InputMaybe<Scalars['String']['input']>;
  staker_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_starts_with?: InputMaybe<Scalars['String']['input']>;
  staker_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  staker_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_ends_with?: InputMaybe<Scalars['String']['input']>;
  staker_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  staker_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  staker_?: InputMaybe<Staker_filter>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<StakingEvent_filter>>>;
  or?: InputMaybe<Array<InputMaybe<StakingEvent_filter>>>;
};

export type StakingEvent_orderBy =
  | 'id'
  | 'type'
  | 'staker'
  | 'staker__id'
  | 'staker__stakeAmount'
  | 'staker__unclaimedRewards'
  | 'staker__lastBoostUpdate'
  | 'staker__lastUpdate'
  | 'amount'
  | 'timestamp';

export type StakingParam = {
  id: Scalars['ID']['output'];
  stakingToken: Scalars['Bytes']['output'];
  stakingTokenCap: Scalars['BigInt']['output'];
  minStakingBoostAmount: Scalars['BigInt']['output'];
  rewardRatioNumerator: Scalars['BigInt']['output'];
  tiersDurations: Array<Scalars['BigInt']['output']>;
  tiersMultipliers: Array<Scalars['BigInt']['output']>;
};

export type StakingParam_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  stakingToken?: InputMaybe<Scalars['Bytes']['input']>;
  stakingToken_not?: InputMaybe<Scalars['Bytes']['input']>;
  stakingToken_gt?: InputMaybe<Scalars['Bytes']['input']>;
  stakingToken_lt?: InputMaybe<Scalars['Bytes']['input']>;
  stakingToken_gte?: InputMaybe<Scalars['Bytes']['input']>;
  stakingToken_lte?: InputMaybe<Scalars['Bytes']['input']>;
  stakingToken_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  stakingToken_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  stakingToken_contains?: InputMaybe<Scalars['Bytes']['input']>;
  stakingToken_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  stakingTokenCap?: InputMaybe<Scalars['BigInt']['input']>;
  stakingTokenCap_not?: InputMaybe<Scalars['BigInt']['input']>;
  stakingTokenCap_gt?: InputMaybe<Scalars['BigInt']['input']>;
  stakingTokenCap_lt?: InputMaybe<Scalars['BigInt']['input']>;
  stakingTokenCap_gte?: InputMaybe<Scalars['BigInt']['input']>;
  stakingTokenCap_lte?: InputMaybe<Scalars['BigInt']['input']>;
  stakingTokenCap_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  stakingTokenCap_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  minStakingBoostAmount?: InputMaybe<Scalars['BigInt']['input']>;
  minStakingBoostAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  minStakingBoostAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  minStakingBoostAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  minStakingBoostAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  minStakingBoostAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  minStakingBoostAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  minStakingBoostAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  rewardRatioNumerator?: InputMaybe<Scalars['BigInt']['input']>;
  rewardRatioNumerator_not?: InputMaybe<Scalars['BigInt']['input']>;
  rewardRatioNumerator_gt?: InputMaybe<Scalars['BigInt']['input']>;
  rewardRatioNumerator_lt?: InputMaybe<Scalars['BigInt']['input']>;
  rewardRatioNumerator_gte?: InputMaybe<Scalars['BigInt']['input']>;
  rewardRatioNumerator_lte?: InputMaybe<Scalars['BigInt']['input']>;
  rewardRatioNumerator_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  rewardRatioNumerator_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tiersDurations?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tiersDurations_not?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tiersDurations_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tiersDurations_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tiersDurations_not_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tiersDurations_not_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tiersMultipliers?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tiersMultipliers_not?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tiersMultipliers_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tiersMultipliers_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tiersMultipliers_not_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tiersMultipliers_not_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<StakingParam_filter>>>;
  or?: InputMaybe<Array<InputMaybe<StakingParam_filter>>>;
};

export type StakingParam_orderBy =
  | 'id'
  | 'stakingToken'
  | 'stakingTokenCap'
  | 'minStakingBoostAmount'
  | 'rewardRatioNumerator'
  | 'tiersDurations'
  | 'tiersMultipliers';

export type Transaction = {
  id: Scalars['ID']['output'];
  transactionId: Scalars['Bytes']['output'];
  block: Scalars['BigInt']['output'];
  from: User;
  to: User;
  erc721?: Maybe<ERC721>;
  erc1155?: Maybe<Array<ERC1155>>;
  erc1155Quantity?: Maybe<Array<Scalars['BigInt']['output']>>;
  timestamp: Scalars['BigInt']['output'];
};


export type Transactionerc1155Args = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ERC1155_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC1155_filter>;
};

export type Transaction0x = {
  id: Scalars['ID']['output'];
  transactionId: Scalars['Bytes']['output'];
  order: Order0x;
  erc20Token: Scalars['Bytes']['output'];
  erc20TokenAmount: Scalars['BigInt']['output'];
  fees?: Maybe<Array<TransactionFee0x>>;
  nftTokenType: Scalars['String']['output'];
  nftToken: Scalars['Bytes']['output'];
  nftTokenId: Scalars['BigInt']['output'];
  nftTokenAmount: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
};


export type Transaction0xfeesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<TransactionFee0x_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<TransactionFee0x_filter>;
};

export type Transaction0x_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionId?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionId_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionId_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  order_not?: InputMaybe<Scalars['String']['input']>;
  order_gt?: InputMaybe<Scalars['String']['input']>;
  order_lt?: InputMaybe<Scalars['String']['input']>;
  order_gte?: InputMaybe<Scalars['String']['input']>;
  order_lte?: InputMaybe<Scalars['String']['input']>;
  order_in?: InputMaybe<Array<Scalars['String']['input']>>;
  order_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  order_contains?: InputMaybe<Scalars['String']['input']>;
  order_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  order_not_contains?: InputMaybe<Scalars['String']['input']>;
  order_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  order_starts_with?: InputMaybe<Scalars['String']['input']>;
  order_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  order_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  order_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  order_ends_with?: InputMaybe<Scalars['String']['input']>;
  order_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  order_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  order_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  order_?: InputMaybe<Order0x_filter>;
  erc20Token?: InputMaybe<Scalars['Bytes']['input']>;
  erc20Token_not?: InputMaybe<Scalars['Bytes']['input']>;
  erc20Token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  erc20Token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  erc20Token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  erc20Token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  erc20Token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  erc20Token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  erc20Token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  erc20Token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  erc20TokenAmount?: InputMaybe<Scalars['BigInt']['input']>;
  erc20TokenAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  erc20TokenAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  erc20TokenAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  erc20TokenAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  erc20TokenAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  erc20TokenAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc20TokenAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  fees?: InputMaybe<Array<Scalars['String']['input']>>;
  fees_not?: InputMaybe<Array<Scalars['String']['input']>>;
  fees_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  fees_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  fees_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  fees_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  fees_?: InputMaybe<TransactionFee0x_filter>;
  nftTokenType?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_not?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_gt?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_lt?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_gte?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_lte?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_in?: InputMaybe<Array<Scalars['String']['input']>>;
  nftTokenType_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  nftTokenType_contains?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_not_contains?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_starts_with?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_ends_with?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  nftTokenType_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  nftToken?: InputMaybe<Scalars['Bytes']['input']>;
  nftToken_not?: InputMaybe<Scalars['Bytes']['input']>;
  nftToken_gt?: InputMaybe<Scalars['Bytes']['input']>;
  nftToken_lt?: InputMaybe<Scalars['Bytes']['input']>;
  nftToken_gte?: InputMaybe<Scalars['Bytes']['input']>;
  nftToken_lte?: InputMaybe<Scalars['Bytes']['input']>;
  nftToken_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  nftToken_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  nftToken_contains?: InputMaybe<Scalars['Bytes']['input']>;
  nftToken_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  nftTokenId?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenId_not?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  nftTokenId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  nftTokenAmount?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  nftTokenAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  nftTokenAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Transaction0x_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Transaction0x_filter>>>;
};

export type Transaction0x_orderBy =
  | 'id'
  | 'transactionId'
  | 'order'
  | 'order__id'
  | 'order__direction'
  | 'order__nonce'
  | 'order__cancelled'
  | 'order__filled'
  | 'order__timestamp'
  | 'order__erc20Token'
  | 'order__erc20FilledAmount'
  | 'order__nftTokenType'
  | 'order__nftToken'
  | 'order__nftTokenId'
  | 'order__nftTokenFilledAmount'
  | 'erc20Token'
  | 'erc20TokenAmount'
  | 'fees'
  | 'nftTokenType'
  | 'nftToken'
  | 'nftTokenId'
  | 'nftTokenAmount'
  | 'timestamp';

export type TransactionFee0x = {
  id: Scalars['ID']['output'];
  tokenAddress: Scalars['Bytes']['output'];
  amount: Scalars['BigInt']['output'];
  receiver: Scalars['Bytes']['output'];
  feeData: Scalars['Bytes']['output'];
  transactionId: Scalars['Bytes']['output'];
};

export type TransactionFee0x_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  tokenAddress?: InputMaybe<Scalars['Bytes']['input']>;
  tokenAddress_not?: InputMaybe<Scalars['Bytes']['input']>;
  tokenAddress_gt?: InputMaybe<Scalars['Bytes']['input']>;
  tokenAddress_lt?: InputMaybe<Scalars['Bytes']['input']>;
  tokenAddress_gte?: InputMaybe<Scalars['Bytes']['input']>;
  tokenAddress_lte?: InputMaybe<Scalars['Bytes']['input']>;
  tokenAddress_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  tokenAddress_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  tokenAddress_contains?: InputMaybe<Scalars['Bytes']['input']>;
  tokenAddress_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  receiver?: InputMaybe<Scalars['Bytes']['input']>;
  receiver_not?: InputMaybe<Scalars['Bytes']['input']>;
  receiver_gt?: InputMaybe<Scalars['Bytes']['input']>;
  receiver_lt?: InputMaybe<Scalars['Bytes']['input']>;
  receiver_gte?: InputMaybe<Scalars['Bytes']['input']>;
  receiver_lte?: InputMaybe<Scalars['Bytes']['input']>;
  receiver_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  receiver_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  receiver_contains?: InputMaybe<Scalars['Bytes']['input']>;
  receiver_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  feeData?: InputMaybe<Scalars['Bytes']['input']>;
  feeData_not?: InputMaybe<Scalars['Bytes']['input']>;
  feeData_gt?: InputMaybe<Scalars['Bytes']['input']>;
  feeData_lt?: InputMaybe<Scalars['Bytes']['input']>;
  feeData_gte?: InputMaybe<Scalars['Bytes']['input']>;
  feeData_lte?: InputMaybe<Scalars['Bytes']['input']>;
  feeData_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  feeData_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  feeData_contains?: InputMaybe<Scalars['Bytes']['input']>;
  feeData_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionId_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionId_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<TransactionFee0x_filter>>>;
  or?: InputMaybe<Array<InputMaybe<TransactionFee0x_filter>>>;
};

export type TransactionFee0x_orderBy =
  | 'id'
  | 'tokenAddress'
  | 'amount'
  | 'receiver'
  | 'feeData'
  | 'transactionId';

export type Transaction_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  transactionId?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionId_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionId_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionId_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  block?: InputMaybe<Scalars['BigInt']['input']>;
  block_not?: InputMaybe<Scalars['BigInt']['input']>;
  block_gt?: InputMaybe<Scalars['BigInt']['input']>;
  block_lt?: InputMaybe<Scalars['BigInt']['input']>;
  block_gte?: InputMaybe<Scalars['BigInt']['input']>;
  block_lte?: InputMaybe<Scalars['BigInt']['input']>;
  block_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  block_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  from?: InputMaybe<Scalars['String']['input']>;
  from_not?: InputMaybe<Scalars['String']['input']>;
  from_gt?: InputMaybe<Scalars['String']['input']>;
  from_lt?: InputMaybe<Scalars['String']['input']>;
  from_gte?: InputMaybe<Scalars['String']['input']>;
  from_lte?: InputMaybe<Scalars['String']['input']>;
  from_in?: InputMaybe<Array<Scalars['String']['input']>>;
  from_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  from_contains?: InputMaybe<Scalars['String']['input']>;
  from_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  from_not_contains?: InputMaybe<Scalars['String']['input']>;
  from_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  from_starts_with?: InputMaybe<Scalars['String']['input']>;
  from_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  from_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  from_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  from_ends_with?: InputMaybe<Scalars['String']['input']>;
  from_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  from_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  from_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  from_?: InputMaybe<User_filter>;
  to?: InputMaybe<Scalars['String']['input']>;
  to_not?: InputMaybe<Scalars['String']['input']>;
  to_gt?: InputMaybe<Scalars['String']['input']>;
  to_lt?: InputMaybe<Scalars['String']['input']>;
  to_gte?: InputMaybe<Scalars['String']['input']>;
  to_lte?: InputMaybe<Scalars['String']['input']>;
  to_in?: InputMaybe<Array<Scalars['String']['input']>>;
  to_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  to_contains?: InputMaybe<Scalars['String']['input']>;
  to_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  to_not_contains?: InputMaybe<Scalars['String']['input']>;
  to_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  to_starts_with?: InputMaybe<Scalars['String']['input']>;
  to_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  to_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  to_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  to_ends_with?: InputMaybe<Scalars['String']['input']>;
  to_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  to_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  to_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  to_?: InputMaybe<User_filter>;
  erc721?: InputMaybe<Scalars['String']['input']>;
  erc721_not?: InputMaybe<Scalars['String']['input']>;
  erc721_gt?: InputMaybe<Scalars['String']['input']>;
  erc721_lt?: InputMaybe<Scalars['String']['input']>;
  erc721_gte?: InputMaybe<Scalars['String']['input']>;
  erc721_lte?: InputMaybe<Scalars['String']['input']>;
  erc721_in?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721_contains?: InputMaybe<Scalars['String']['input']>;
  erc721_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_not_contains?: InputMaybe<Scalars['String']['input']>;
  erc721_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_starts_with?: InputMaybe<Scalars['String']['input']>;
  erc721_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  erc721_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc721_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  erc721_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  erc721_?: InputMaybe<ERC721_filter>;
  erc1155?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_not?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_?: InputMaybe<ERC1155_filter>;
  erc1155Quantity?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Quantity_not?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Quantity_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Quantity_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Quantity_not_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Quantity_not_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Transaction_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Transaction_filter>>>;
};

export type Transaction_orderBy =
  | 'id'
  | 'transactionId'
  | 'block'
  | 'from'
  | 'from__id'
  | 'from__address'
  | 'from__allowed'
  | 'to'
  | 'to__id'
  | 'to__address'
  | 'to__allowed'
  | 'erc721'
  | 'erc721__id'
  | 'erc721__contractAddress'
  | 'erc721__tokenId'
  | 'erc1155'
  | 'erc1155Quantity'
  | 'timestamp';

export type User = {
  id: Scalars['ID']['output'];
  address: Scalars['Bytes']['output'];
  erc721Owned?: Maybe<Array<ERC721>>;
  erc1155?: Maybe<Array<ERC1155>>;
  erc1155Balance?: Maybe<Array<Scalars['BigInt']['output']>>;
  allowed: Scalars['Boolean']['output'];
};


export type Usererc721OwnedArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ERC721_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC721_filter>;
};


export type Usererc1155Args = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<ERC1155_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<ERC1155_filter>;
};

export type User_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  address?: InputMaybe<Scalars['Bytes']['input']>;
  address_not?: InputMaybe<Scalars['Bytes']['input']>;
  address_gt?: InputMaybe<Scalars['Bytes']['input']>;
  address_lt?: InputMaybe<Scalars['Bytes']['input']>;
  address_gte?: InputMaybe<Scalars['Bytes']['input']>;
  address_lte?: InputMaybe<Scalars['Bytes']['input']>;
  address_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  address_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  address_contains?: InputMaybe<Scalars['Bytes']['input']>;
  address_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  erc721Owned?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_not?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  erc721Owned_?: InputMaybe<ERC721_filter>;
  erc1155?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_not?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  erc1155_?: InputMaybe<ERC1155_filter>;
  erc1155Balance?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Balance_not?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Balance_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Balance_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Balance_not_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  erc1155Balance_not_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  allowed?: InputMaybe<Scalars['Boolean']['input']>;
  allowed_not?: InputMaybe<Scalars['Boolean']['input']>;
  allowed_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  allowed_not_in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<User_filter>>>;
  or?: InputMaybe<Array<InputMaybe<User_filter>>>;
};

export type User_orderBy =
  | 'id'
  | 'address'
  | 'erc721Owned'
  | 'erc1155'
  | 'erc1155Balance'
  | 'allowed';

export type _Block_ = {
  /** The hash of the block */
  hash?: Maybe<Scalars['Bytes']['output']>;
  /** The block number */
  number: Scalars['Int']['output'];
  /** Integer representation of the timestamp stored in blocks for the chain */
  timestamp?: Maybe<Scalars['Int']['output']>;
  /** The hash of the parent block */
  parentHash?: Maybe<Scalars['Bytes']['output']>;
};

/** The type for the top-level _meta field */
export type _Meta_ = {
  /**
   * Information about a specific subgraph block. The hash of the block
   * will be null if the _meta field has a block constraint that asks for
   * a block number. It will be filled if the _meta field has no block constraint
   * and therefore asks for the latest  block
   *
   */
  block: _Block_;
  /** The deployment ID */
  deployment: Scalars['String']['output'];
  /** If `true`, the subgraph encountered indexing errors at some past block */
  hasIndexingErrors: Scalars['Boolean']['output'];
};

export type _SubgraphErrorPolicy_ =
  /** Data will be returned even if the subgraph has indexing errors */
  | 'allow'
  /** If the subgraph has indexing errors, data will be omitted. The default. */
  | 'deny';

export type Collection = {
  id: Scalars['ID']['output'];
  address: Scalars['Bytes']['output'];
  oracle?: Maybe<Scalars['Bytes']['output']>;
  name: Scalars['String']['output'];
  type: Scalars['String']['output'];
};

export type Collection_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  address?: InputMaybe<Scalars['Bytes']['input']>;
  address_not?: InputMaybe<Scalars['Bytes']['input']>;
  address_gt?: InputMaybe<Scalars['Bytes']['input']>;
  address_lt?: InputMaybe<Scalars['Bytes']['input']>;
  address_gte?: InputMaybe<Scalars['Bytes']['input']>;
  address_lte?: InputMaybe<Scalars['Bytes']['input']>;
  address_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  address_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  address_contains?: InputMaybe<Scalars['Bytes']['input']>;
  address_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  oracle?: InputMaybe<Scalars['Bytes']['input']>;
  oracle_not?: InputMaybe<Scalars['Bytes']['input']>;
  oracle_gt?: InputMaybe<Scalars['Bytes']['input']>;
  oracle_lt?: InputMaybe<Scalars['Bytes']['input']>;
  oracle_gte?: InputMaybe<Scalars['Bytes']['input']>;
  oracle_lte?: InputMaybe<Scalars['Bytes']['input']>;
  oracle_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  oracle_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  oracle_contains?: InputMaybe<Scalars['Bytes']['input']>;
  oracle_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_not?: InputMaybe<Scalars['String']['input']>;
  name_gt?: InputMaybe<Scalars['String']['input']>;
  name_lt?: InputMaybe<Scalars['String']['input']>;
  name_gte?: InputMaybe<Scalars['String']['input']>;
  name_lte?: InputMaybe<Scalars['String']['input']>;
  name_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  name_contains?: InputMaybe<Scalars['String']['input']>;
  name_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_contains?: InputMaybe<Scalars['String']['input']>;
  name_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  name_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  name_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  name_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  type_not?: InputMaybe<Scalars['String']['input']>;
  type_gt?: InputMaybe<Scalars['String']['input']>;
  type_lt?: InputMaybe<Scalars['String']['input']>;
  type_gte?: InputMaybe<Scalars['String']['input']>;
  type_lte?: InputMaybe<Scalars['String']['input']>;
  type_in?: InputMaybe<Array<Scalars['String']['input']>>;
  type_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  type_contains?: InputMaybe<Scalars['String']['input']>;
  type_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  type_not_contains?: InputMaybe<Scalars['String']['input']>;
  type_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  type_starts_with?: InputMaybe<Scalars['String']['input']>;
  type_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  type_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  type_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  type_ends_with?: InputMaybe<Scalars['String']['input']>;
  type_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  type_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  type_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Collection_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Collection_filter>>>;
};

export type Collection_orderBy =
  | 'id'
  | 'address'
  | 'oracle'
  | 'name'
  | 'type';

export type Event = {
  id: Scalars['ID']['output'];
  eventType: LoanStatus;
  time: Scalars['BigInt']['output'];
  transactionHash: Scalars['Bytes']['output'];
  loan: Loan;
  user: Scalars['Bytes']['output'];
};

export type Event_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  eventType?: InputMaybe<LoanStatus>;
  eventType_not?: InputMaybe<LoanStatus>;
  eventType_in?: InputMaybe<Array<LoanStatus>>;
  eventType_not_in?: InputMaybe<Array<LoanStatus>>;
  time?: InputMaybe<Scalars['BigInt']['input']>;
  time_not?: InputMaybe<Scalars['BigInt']['input']>;
  time_gt?: InputMaybe<Scalars['BigInt']['input']>;
  time_lt?: InputMaybe<Scalars['BigInt']['input']>;
  time_gte?: InputMaybe<Scalars['BigInt']['input']>;
  time_lte?: InputMaybe<Scalars['BigInt']['input']>;
  time_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  time_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  transactionHash?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lt?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_gte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_lte?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  transactionHash_contains?: InputMaybe<Scalars['Bytes']['input']>;
  transactionHash_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  loan?: InputMaybe<Scalars['String']['input']>;
  loan_not?: InputMaybe<Scalars['String']['input']>;
  loan_gt?: InputMaybe<Scalars['String']['input']>;
  loan_lt?: InputMaybe<Scalars['String']['input']>;
  loan_gte?: InputMaybe<Scalars['String']['input']>;
  loan_lte?: InputMaybe<Scalars['String']['input']>;
  loan_in?: InputMaybe<Array<Scalars['String']['input']>>;
  loan_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  loan_contains?: InputMaybe<Scalars['String']['input']>;
  loan_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  loan_not_contains?: InputMaybe<Scalars['String']['input']>;
  loan_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  loan_starts_with?: InputMaybe<Scalars['String']['input']>;
  loan_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  loan_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  loan_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  loan_ends_with?: InputMaybe<Scalars['String']['input']>;
  loan_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  loan_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  loan_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  loan_?: InputMaybe<Loan_filter>;
  user?: InputMaybe<Scalars['Bytes']['input']>;
  user_not?: InputMaybe<Scalars['Bytes']['input']>;
  user_gt?: InputMaybe<Scalars['Bytes']['input']>;
  user_lt?: InputMaybe<Scalars['Bytes']['input']>;
  user_gte?: InputMaybe<Scalars['Bytes']['input']>;
  user_lte?: InputMaybe<Scalars['Bytes']['input']>;
  user_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  user_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  user_contains?: InputMaybe<Scalars['Bytes']['input']>;
  user_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Event_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Event_filter>>>;
};

export type Event_orderBy =
  | 'id'
  | 'eventType'
  | 'time'
  | 'transactionHash'
  | 'loan'
  | 'loan__id'
  | 'loan__status'
  | 'loan__borrower'
  | 'loan__token'
  | 'loan__amount'
  | 'loan__duration'
  | 'loan__deadline'
  | 'loan__interestRate'
  | 'loan__collateralValue'
  | 'loan__dueAmount'
  | 'loan__lender'
  | 'loan__startTime'
  | 'loan__endTime'
  | 'loan__totalPaid'
  | 'loan__feePaid'
  | 'loan__liquidator'
  | 'user';

export type Loan = {
  id: Scalars['ID']['output'];
  status: LoanStatus;
  borrower: Scalars['Bytes']['output'];
  token: Scalars['Bytes']['output'];
  amount: Scalars['BigInt']['output'];
  nft: Nft;
  duration: Scalars['BigInt']['output'];
  deadline: Scalars['BigInt']['output'];
  interestRate: Scalars['BigInt']['output'];
  collateralValue: Scalars['BigInt']['output'];
  dueAmount: Scalars['BigInt']['output'];
  lender: Scalars['Bytes']['output'];
  startTime: Scalars['BigInt']['output'];
  endTime: Scalars['BigInt']['output'];
  totalPaid: Scalars['BigInt']['output'];
  feePaid: Scalars['BigInt']['output'];
  liquidator: Scalars['Bytes']['output'];
  events: Array<Event>;
};


export type LoaneventsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Event_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Event_filter>;
};

export type LoanStatus =
  | 'REQUESTED'
  | 'ACCEPTED'
  | 'REPAID'
  | 'LIQUIDATED'
  | 'CANCELLED'
  | 'NFT_CLAIMED';

export type Loan_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  status?: InputMaybe<LoanStatus>;
  status_not?: InputMaybe<LoanStatus>;
  status_in?: InputMaybe<Array<LoanStatus>>;
  status_not_in?: InputMaybe<Array<LoanStatus>>;
  borrower?: InputMaybe<Scalars['Bytes']['input']>;
  borrower_not?: InputMaybe<Scalars['Bytes']['input']>;
  borrower_gt?: InputMaybe<Scalars['Bytes']['input']>;
  borrower_lt?: InputMaybe<Scalars['Bytes']['input']>;
  borrower_gte?: InputMaybe<Scalars['Bytes']['input']>;
  borrower_lte?: InputMaybe<Scalars['Bytes']['input']>;
  borrower_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  borrower_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  borrower_contains?: InputMaybe<Scalars['Bytes']['input']>;
  borrower_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token?: InputMaybe<Scalars['Bytes']['input']>;
  token_not?: InputMaybe<Scalars['Bytes']['input']>;
  token_gt?: InputMaybe<Scalars['Bytes']['input']>;
  token_lt?: InputMaybe<Scalars['Bytes']['input']>;
  token_gte?: InputMaybe<Scalars['Bytes']['input']>;
  token_lte?: InputMaybe<Scalars['Bytes']['input']>;
  token_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  token_contains?: InputMaybe<Scalars['Bytes']['input']>;
  token_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  amount?: InputMaybe<Scalars['BigInt']['input']>;
  amount_not?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  amount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  amount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  amount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  nft?: InputMaybe<Scalars['String']['input']>;
  nft_not?: InputMaybe<Scalars['String']['input']>;
  nft_gt?: InputMaybe<Scalars['String']['input']>;
  nft_lt?: InputMaybe<Scalars['String']['input']>;
  nft_gte?: InputMaybe<Scalars['String']['input']>;
  nft_lte?: InputMaybe<Scalars['String']['input']>;
  nft_in?: InputMaybe<Array<Scalars['String']['input']>>;
  nft_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  nft_contains?: InputMaybe<Scalars['String']['input']>;
  nft_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  nft_not_contains?: InputMaybe<Scalars['String']['input']>;
  nft_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  nft_starts_with?: InputMaybe<Scalars['String']['input']>;
  nft_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  nft_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  nft_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  nft_ends_with?: InputMaybe<Scalars['String']['input']>;
  nft_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  nft_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  nft_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  nft_?: InputMaybe<Nft_filter>;
  duration?: InputMaybe<Scalars['BigInt']['input']>;
  duration_not?: InputMaybe<Scalars['BigInt']['input']>;
  duration_gt?: InputMaybe<Scalars['BigInt']['input']>;
  duration_lt?: InputMaybe<Scalars['BigInt']['input']>;
  duration_gte?: InputMaybe<Scalars['BigInt']['input']>;
  duration_lte?: InputMaybe<Scalars['BigInt']['input']>;
  duration_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  duration_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  deadline?: InputMaybe<Scalars['BigInt']['input']>;
  deadline_not?: InputMaybe<Scalars['BigInt']['input']>;
  deadline_gt?: InputMaybe<Scalars['BigInt']['input']>;
  deadline_lt?: InputMaybe<Scalars['BigInt']['input']>;
  deadline_gte?: InputMaybe<Scalars['BigInt']['input']>;
  deadline_lte?: InputMaybe<Scalars['BigInt']['input']>;
  deadline_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  deadline_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  interestRate?: InputMaybe<Scalars['BigInt']['input']>;
  interestRate_not?: InputMaybe<Scalars['BigInt']['input']>;
  interestRate_gt?: InputMaybe<Scalars['BigInt']['input']>;
  interestRate_lt?: InputMaybe<Scalars['BigInt']['input']>;
  interestRate_gte?: InputMaybe<Scalars['BigInt']['input']>;
  interestRate_lte?: InputMaybe<Scalars['BigInt']['input']>;
  interestRate_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  interestRate_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  collateralValue?: InputMaybe<Scalars['BigInt']['input']>;
  collateralValue_not?: InputMaybe<Scalars['BigInt']['input']>;
  collateralValue_gt?: InputMaybe<Scalars['BigInt']['input']>;
  collateralValue_lt?: InputMaybe<Scalars['BigInt']['input']>;
  collateralValue_gte?: InputMaybe<Scalars['BigInt']['input']>;
  collateralValue_lte?: InputMaybe<Scalars['BigInt']['input']>;
  collateralValue_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  collateralValue_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  dueAmount?: InputMaybe<Scalars['BigInt']['input']>;
  dueAmount_not?: InputMaybe<Scalars['BigInt']['input']>;
  dueAmount_gt?: InputMaybe<Scalars['BigInt']['input']>;
  dueAmount_lt?: InputMaybe<Scalars['BigInt']['input']>;
  dueAmount_gte?: InputMaybe<Scalars['BigInt']['input']>;
  dueAmount_lte?: InputMaybe<Scalars['BigInt']['input']>;
  dueAmount_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  dueAmount_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lender?: InputMaybe<Scalars['Bytes']['input']>;
  lender_not?: InputMaybe<Scalars['Bytes']['input']>;
  lender_gt?: InputMaybe<Scalars['Bytes']['input']>;
  lender_lt?: InputMaybe<Scalars['Bytes']['input']>;
  lender_gte?: InputMaybe<Scalars['Bytes']['input']>;
  lender_lte?: InputMaybe<Scalars['Bytes']['input']>;
  lender_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  lender_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  lender_contains?: InputMaybe<Scalars['Bytes']['input']>;
  lender_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  startTime?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_not?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_gt?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_lt?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_gte?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_lte?: InputMaybe<Scalars['BigInt']['input']>;
  startTime_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  startTime_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  endTime?: InputMaybe<Scalars['BigInt']['input']>;
  endTime_not?: InputMaybe<Scalars['BigInt']['input']>;
  endTime_gt?: InputMaybe<Scalars['BigInt']['input']>;
  endTime_lt?: InputMaybe<Scalars['BigInt']['input']>;
  endTime_gte?: InputMaybe<Scalars['BigInt']['input']>;
  endTime_lte?: InputMaybe<Scalars['BigInt']['input']>;
  endTime_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  endTime_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalPaid?: InputMaybe<Scalars['BigInt']['input']>;
  totalPaid_not?: InputMaybe<Scalars['BigInt']['input']>;
  totalPaid_gt?: InputMaybe<Scalars['BigInt']['input']>;
  totalPaid_lt?: InputMaybe<Scalars['BigInt']['input']>;
  totalPaid_gte?: InputMaybe<Scalars['BigInt']['input']>;
  totalPaid_lte?: InputMaybe<Scalars['BigInt']['input']>;
  totalPaid_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  totalPaid_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  feePaid?: InputMaybe<Scalars['BigInt']['input']>;
  feePaid_not?: InputMaybe<Scalars['BigInt']['input']>;
  feePaid_gt?: InputMaybe<Scalars['BigInt']['input']>;
  feePaid_lt?: InputMaybe<Scalars['BigInt']['input']>;
  feePaid_gte?: InputMaybe<Scalars['BigInt']['input']>;
  feePaid_lte?: InputMaybe<Scalars['BigInt']['input']>;
  feePaid_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  feePaid_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  liquidator?: InputMaybe<Scalars['Bytes']['input']>;
  liquidator_not?: InputMaybe<Scalars['Bytes']['input']>;
  liquidator_gt?: InputMaybe<Scalars['Bytes']['input']>;
  liquidator_lt?: InputMaybe<Scalars['Bytes']['input']>;
  liquidator_gte?: InputMaybe<Scalars['Bytes']['input']>;
  liquidator_lte?: InputMaybe<Scalars['Bytes']['input']>;
  liquidator_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  liquidator_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  liquidator_contains?: InputMaybe<Scalars['Bytes']['input']>;
  liquidator_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  events_?: InputMaybe<Event_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Loan_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Loan_filter>>>;
};

export type Loan_orderBy =
  | 'id'
  | 'status'
  | 'borrower'
  | 'token'
  | 'amount'
  | 'nft'
  | 'nft__id'
  | 'nft__tokenId'
  | 'nft__owner'
  | 'duration'
  | 'deadline'
  | 'interestRate'
  | 'collateralValue'
  | 'dueAmount'
  | 'lender'
  | 'startTime'
  | 'endTime'
  | 'totalPaid'
  | 'feePaid'
  | 'liquidator'
  | 'events';

export type Nft = {
  id: Scalars['ID']['output'];
  collection: Collection;
  tokenId: Scalars['BigInt']['output'];
  valuationHistory?: Maybe<Array<Valuation>>;
  owner: Scalars['String']['output'];
  loans?: Maybe<Array<Loan>>;
};


export type NftvaluationHistoryArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Valuation_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Valuation_filter>;
};


export type NftloansArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Loan_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Loan_filter>;
};

export type Nft_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  collection?: InputMaybe<Scalars['String']['input']>;
  collection_not?: InputMaybe<Scalars['String']['input']>;
  collection_gt?: InputMaybe<Scalars['String']['input']>;
  collection_lt?: InputMaybe<Scalars['String']['input']>;
  collection_gte?: InputMaybe<Scalars['String']['input']>;
  collection_lte?: InputMaybe<Scalars['String']['input']>;
  collection_in?: InputMaybe<Array<Scalars['String']['input']>>;
  collection_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  collection_contains?: InputMaybe<Scalars['String']['input']>;
  collection_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  collection_not_contains?: InputMaybe<Scalars['String']['input']>;
  collection_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  collection_starts_with?: InputMaybe<Scalars['String']['input']>;
  collection_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  collection_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  collection_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  collection_ends_with?: InputMaybe<Scalars['String']['input']>;
  collection_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  collection_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  collection_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  collection_?: InputMaybe<Collection_filter>;
  tokenId?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_not?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_gt?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_lt?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_gte?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_lte?: InputMaybe<Scalars['BigInt']['input']>;
  tokenId_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tokenId_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  valuationHistory?: InputMaybe<Array<Scalars['String']['input']>>;
  valuationHistory_not?: InputMaybe<Array<Scalars['String']['input']>>;
  valuationHistory_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  valuationHistory_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  valuationHistory_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  valuationHistory_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  valuationHistory_?: InputMaybe<Valuation_filter>;
  owner?: InputMaybe<Scalars['String']['input']>;
  owner_not?: InputMaybe<Scalars['String']['input']>;
  owner_gt?: InputMaybe<Scalars['String']['input']>;
  owner_lt?: InputMaybe<Scalars['String']['input']>;
  owner_gte?: InputMaybe<Scalars['String']['input']>;
  owner_lte?: InputMaybe<Scalars['String']['input']>;
  owner_in?: InputMaybe<Array<Scalars['String']['input']>>;
  owner_not_in?: InputMaybe<Array<Scalars['String']['input']>>;
  owner_contains?: InputMaybe<Scalars['String']['input']>;
  owner_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_contains?: InputMaybe<Scalars['String']['input']>;
  owner_not_contains_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_starts_with?: InputMaybe<Scalars['String']['input']>;
  owner_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_starts_with?: InputMaybe<Scalars['String']['input']>;
  owner_not_starts_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_ends_with?: InputMaybe<Scalars['String']['input']>;
  owner_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  owner_not_ends_with?: InputMaybe<Scalars['String']['input']>;
  owner_not_ends_with_nocase?: InputMaybe<Scalars['String']['input']>;
  loans?: InputMaybe<Array<Scalars['String']['input']>>;
  loans_not?: InputMaybe<Array<Scalars['String']['input']>>;
  loans_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  loans_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  loans_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  loans_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  loans_?: InputMaybe<Loan_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Nft_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Nft_filter>>>;
};

export type Nft_orderBy =
  | 'id'
  | 'collection'
  | 'collection__id'
  | 'collection__address'
  | 'collection__oracle'
  | 'collection__name'
  | 'collection__type'
  | 'tokenId'
  | 'valuationHistory'
  | 'owner'
  | 'loans';

export type Params = {
  id: Scalars['ID']['output'];
  priceIndex: Scalars['Bytes']['output'];
  allowList: Scalars['Bytes']['output'];
  governanceTreasury: Scalars['Bytes']['output'];
  repayGraceFee: Scalars['BigInt']['output'];
  protocolFee: Scalars['BigInt']['output'];
  liquidationFee: Scalars['BigInt']['output'];
  baseOriginationFee: Scalars['BigInt']['output'];
  feeReductionFactor: Scalars['BigInt']['output'];
  originationFeeRanges: Array<Scalars['BigInt']['output']>;
  tokens: Array<Scalars['Bytes']['output']>;
  loanTypesDurations: Array<Scalars['BigInt']['output']>;
  loanTypesInterestRates: Array<Scalars['BigInt']['output']>;
  repayGracePeriod: Scalars['BigInt']['output'];
  lenderExclusiveLiquidationPeriod: Scalars['BigInt']['output'];
  disallowedNfts: Array<Nft>;
};


export type ParamsdisallowedNftsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Nft_orderBy>;
  orderDirection?: InputMaybe<OrderDirection>;
  where?: InputMaybe<Nft_filter>;
};

export type Params_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  priceIndex?: InputMaybe<Scalars['Bytes']['input']>;
  priceIndex_not?: InputMaybe<Scalars['Bytes']['input']>;
  priceIndex_gt?: InputMaybe<Scalars['Bytes']['input']>;
  priceIndex_lt?: InputMaybe<Scalars['Bytes']['input']>;
  priceIndex_gte?: InputMaybe<Scalars['Bytes']['input']>;
  priceIndex_lte?: InputMaybe<Scalars['Bytes']['input']>;
  priceIndex_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  priceIndex_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  priceIndex_contains?: InputMaybe<Scalars['Bytes']['input']>;
  priceIndex_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  allowList?: InputMaybe<Scalars['Bytes']['input']>;
  allowList_not?: InputMaybe<Scalars['Bytes']['input']>;
  allowList_gt?: InputMaybe<Scalars['Bytes']['input']>;
  allowList_lt?: InputMaybe<Scalars['Bytes']['input']>;
  allowList_gte?: InputMaybe<Scalars['Bytes']['input']>;
  allowList_lte?: InputMaybe<Scalars['Bytes']['input']>;
  allowList_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  allowList_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  allowList_contains?: InputMaybe<Scalars['Bytes']['input']>;
  allowList_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  governanceTreasury?: InputMaybe<Scalars['Bytes']['input']>;
  governanceTreasury_not?: InputMaybe<Scalars['Bytes']['input']>;
  governanceTreasury_gt?: InputMaybe<Scalars['Bytes']['input']>;
  governanceTreasury_lt?: InputMaybe<Scalars['Bytes']['input']>;
  governanceTreasury_gte?: InputMaybe<Scalars['Bytes']['input']>;
  governanceTreasury_lte?: InputMaybe<Scalars['Bytes']['input']>;
  governanceTreasury_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  governanceTreasury_not_in?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  governanceTreasury_contains?: InputMaybe<Scalars['Bytes']['input']>;
  governanceTreasury_not_contains?: InputMaybe<Scalars['Bytes']['input']>;
  repayGraceFee?: InputMaybe<Scalars['BigInt']['input']>;
  repayGraceFee_not?: InputMaybe<Scalars['BigInt']['input']>;
  repayGraceFee_gt?: InputMaybe<Scalars['BigInt']['input']>;
  repayGraceFee_lt?: InputMaybe<Scalars['BigInt']['input']>;
  repayGraceFee_gte?: InputMaybe<Scalars['BigInt']['input']>;
  repayGraceFee_lte?: InputMaybe<Scalars['BigInt']['input']>;
  repayGraceFee_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  repayGraceFee_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  protocolFee?: InputMaybe<Scalars['BigInt']['input']>;
  protocolFee_not?: InputMaybe<Scalars['BigInt']['input']>;
  protocolFee_gt?: InputMaybe<Scalars['BigInt']['input']>;
  protocolFee_lt?: InputMaybe<Scalars['BigInt']['input']>;
  protocolFee_gte?: InputMaybe<Scalars['BigInt']['input']>;
  protocolFee_lte?: InputMaybe<Scalars['BigInt']['input']>;
  protocolFee_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  protocolFee_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  liquidationFee?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationFee_not?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationFee_gt?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationFee_lt?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationFee_gte?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationFee_lte?: InputMaybe<Scalars['BigInt']['input']>;
  liquidationFee_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  liquidationFee_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  baseOriginationFee?: InputMaybe<Scalars['BigInt']['input']>;
  baseOriginationFee_not?: InputMaybe<Scalars['BigInt']['input']>;
  baseOriginationFee_gt?: InputMaybe<Scalars['BigInt']['input']>;
  baseOriginationFee_lt?: InputMaybe<Scalars['BigInt']['input']>;
  baseOriginationFee_gte?: InputMaybe<Scalars['BigInt']['input']>;
  baseOriginationFee_lte?: InputMaybe<Scalars['BigInt']['input']>;
  baseOriginationFee_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  baseOriginationFee_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  feeReductionFactor?: InputMaybe<Scalars['BigInt']['input']>;
  feeReductionFactor_not?: InputMaybe<Scalars['BigInt']['input']>;
  feeReductionFactor_gt?: InputMaybe<Scalars['BigInt']['input']>;
  feeReductionFactor_lt?: InputMaybe<Scalars['BigInt']['input']>;
  feeReductionFactor_gte?: InputMaybe<Scalars['BigInt']['input']>;
  feeReductionFactor_lte?: InputMaybe<Scalars['BigInt']['input']>;
  feeReductionFactor_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  feeReductionFactor_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  originationFeeRanges?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  originationFeeRanges_not?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  originationFeeRanges_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  originationFeeRanges_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  originationFeeRanges_not_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  originationFeeRanges_not_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  tokens?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  tokens_not?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  tokens_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  tokens_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  tokens_not_contains?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  tokens_not_contains_nocase?: InputMaybe<Array<Scalars['Bytes']['input']>>;
  loanTypesDurations?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  loanTypesDurations_not?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  loanTypesDurations_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  loanTypesDurations_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  loanTypesDurations_not_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  loanTypesDurations_not_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  loanTypesInterestRates?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  loanTypesInterestRates_not?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  loanTypesInterestRates_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  loanTypesInterestRates_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  loanTypesInterestRates_not_contains?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  loanTypesInterestRates_not_contains_nocase?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  repayGracePeriod?: InputMaybe<Scalars['BigInt']['input']>;
  repayGracePeriod_not?: InputMaybe<Scalars['BigInt']['input']>;
  repayGracePeriod_gt?: InputMaybe<Scalars['BigInt']['input']>;
  repayGracePeriod_lt?: InputMaybe<Scalars['BigInt']['input']>;
  repayGracePeriod_gte?: InputMaybe<Scalars['BigInt']['input']>;
  repayGracePeriod_lte?: InputMaybe<Scalars['BigInt']['input']>;
  repayGracePeriod_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  repayGracePeriod_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lenderExclusiveLiquidationPeriod?: InputMaybe<Scalars['BigInt']['input']>;
  lenderExclusiveLiquidationPeriod_not?: InputMaybe<Scalars['BigInt']['input']>;
  lenderExclusiveLiquidationPeriod_gt?: InputMaybe<Scalars['BigInt']['input']>;
  lenderExclusiveLiquidationPeriod_lt?: InputMaybe<Scalars['BigInt']['input']>;
  lenderExclusiveLiquidationPeriod_gte?: InputMaybe<Scalars['BigInt']['input']>;
  lenderExclusiveLiquidationPeriod_lte?: InputMaybe<Scalars['BigInt']['input']>;
  lenderExclusiveLiquidationPeriod_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lenderExclusiveLiquidationPeriod_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  disallowedNfts?: InputMaybe<Array<Scalars['String']['input']>>;
  disallowedNfts_not?: InputMaybe<Array<Scalars['String']['input']>>;
  disallowedNfts_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  disallowedNfts_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  disallowedNfts_not_contains?: InputMaybe<Array<Scalars['String']['input']>>;
  disallowedNfts_not_contains_nocase?: InputMaybe<Array<Scalars['String']['input']>>;
  disallowedNfts_?: InputMaybe<Nft_filter>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Params_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Params_filter>>>;
};

export type Params_orderBy =
  | 'id'
  | 'priceIndex'
  | 'allowList'
  | 'governanceTreasury'
  | 'repayGraceFee'
  | 'protocolFee'
  | 'liquidationFee'
  | 'baseOriginationFee'
  | 'feeReductionFactor'
  | 'originationFeeRanges'
  | 'tokens'
  | 'loanTypesDurations'
  | 'loanTypesInterestRates'
  | 'repayGracePeriod'
  | 'lenderExclusiveLiquidationPeriod'
  | 'disallowedNfts';

export type Valuation = {
  id: Scalars['ID']['output'];
  price: Scalars['BigInt']['output'];
  ltv: Scalars['BigInt']['output'];
  timestamp: Scalars['BigInt']['output'];
};

export type Valuation_filter = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_not?: InputMaybe<Scalars['ID']['input']>;
  id_gt?: InputMaybe<Scalars['ID']['input']>;
  id_lt?: InputMaybe<Scalars['ID']['input']>;
  id_gte?: InputMaybe<Scalars['ID']['input']>;
  id_lte?: InputMaybe<Scalars['ID']['input']>;
  id_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  id_not_in?: InputMaybe<Array<Scalars['ID']['input']>>;
  price?: InputMaybe<Scalars['BigInt']['input']>;
  price_not?: InputMaybe<Scalars['BigInt']['input']>;
  price_gt?: InputMaybe<Scalars['BigInt']['input']>;
  price_lt?: InputMaybe<Scalars['BigInt']['input']>;
  price_gte?: InputMaybe<Scalars['BigInt']['input']>;
  price_lte?: InputMaybe<Scalars['BigInt']['input']>;
  price_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  price_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  ltv?: InputMaybe<Scalars['BigInt']['input']>;
  ltv_not?: InputMaybe<Scalars['BigInt']['input']>;
  ltv_gt?: InputMaybe<Scalars['BigInt']['input']>;
  ltv_lt?: InputMaybe<Scalars['BigInt']['input']>;
  ltv_gte?: InputMaybe<Scalars['BigInt']['input']>;
  ltv_lte?: InputMaybe<Scalars['BigInt']['input']>;
  ltv_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  ltv_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_not?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lt?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_gte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_lte?: InputMaybe<Scalars['BigInt']['input']>;
  timestamp_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  timestamp_not_in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Filter for the block changed event. */
  _change_block?: InputMaybe<BlockChangedFilter>;
  and?: InputMaybe<Array<InputMaybe<Valuation_filter>>>;
  or?: InputMaybe<Array<InputMaybe<Valuation_filter>>>;
};

export type Valuation_orderBy =
  | 'id'
  | 'price'
  | 'ltv'
  | 'timestamp';

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type LegacyStitchingResolver<TResult, TParent, TContext, TArgs> = {
  fragment: string;
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};

export type NewStitchingResolver<TResult, TParent, TContext, TArgs> = {
  selectionSet: string | ((fieldNode: FieldNode) => SelectionSetNode);
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type StitchingResolver<TResult, TParent, TContext, TArgs> = LegacyStitchingResolver<TResult, TParent, TContext, TArgs> | NewStitchingResolver<TResult, TParent, TContext, TArgs>;
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> =
  | ResolverFn<TResult, TParent, TContext, TArgs>
  | ResolverWithResolve<TResult, TParent, TContext, TArgs>
  | StitchingResolver<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Query: ResolverTypeWrapper<{}>;
  Subscription: ResolverTypeWrapper<{}>;
  Aggregation_interval: Aggregation_interval;
  BigDecimal: ResolverTypeWrapper<Scalars['BigDecimal']['output']>;
  BigInt: ResolverTypeWrapper<Scalars['BigInt']['output']>;
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Buyout: ResolverTypeWrapper<Buyout>;
  Buyout_filter: Buyout_filter;
  Buyout_orderBy: Buyout_orderBy;
  Bytes: ResolverTypeWrapper<Scalars['Bytes']['output']>;
  ERC1155: ResolverTypeWrapper<ERC1155>;
  ERC1155_filter: ERC1155_filter;
  ERC1155_orderBy: ERC1155_orderBy;
  ERC721: ResolverTypeWrapper<ERC721>;
  ERC721_filter: ERC721_filter;
  ERC721_orderBy: ERC721_orderBy;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Int8: ResolverTypeWrapper<Scalars['Int8']['output']>;
  Oracle: ResolverTypeWrapper<Oracle>;
  Oracle_filter: Oracle_filter;
  Oracle_orderBy: Oracle_orderBy;
  Order0x: ResolverTypeWrapper<Order0x>;
  Order0x_filter: Order0x_filter;
  Order0x_orderBy: Order0x_orderBy;
  OrderDirection: OrderDirection;
  PurchaseEvent: ResolverTypeWrapper<PurchaseEvent>;
  PurchaseEvent_filter: PurchaseEvent_filter;
  PurchaseEvent_orderBy: PurchaseEvent_orderBy;
  Raffle: ResolverTypeWrapper<Raffle>;
  RaffleStatus: RaffleStatus;
  Raffle_filter: Raffle_filter;
  Raffle_orderBy: Raffle_orderBy;
  RedeemRequest: ResolverTypeWrapper<RedeemRequest>;
  RedeemRequest_filter: RedeemRequest_filter;
  RedeemRequest_orderBy: RedeemRequest_orderBy;
  Referral: ResolverTypeWrapper<Referral>;
  Referral_filter: Referral_filter;
  Referral_orderBy: Referral_orderBy;
  Role: ResolverTypeWrapper<Role>;
  Role_filter: Role_filter;
  Role_orderBy: Role_orderBy;
  Sale: ResolverTypeWrapper<Sale>;
  Sale_filter: Sale_filter;
  Sale_orderBy: Sale_orderBy;
  SpecialUser: ResolverTypeWrapper<SpecialUser>;
  SpecialUser_filter: SpecialUser_filter;
  SpecialUser_orderBy: SpecialUser_orderBy;
  Staker: ResolverTypeWrapper<Staker>;
  Staker_filter: Staker_filter;
  Staker_orderBy: Staker_orderBy;
  StakingEvent: ResolverTypeWrapper<StakingEvent>;
  StakingEvent_filter: StakingEvent_filter;
  StakingEvent_orderBy: StakingEvent_orderBy;
  StakingParam: ResolverTypeWrapper<StakingParam>;
  StakingParam_filter: StakingParam_filter;
  StakingParam_orderBy: StakingParam_orderBy;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Timestamp: ResolverTypeWrapper<Scalars['Timestamp']['output']>;
  Transaction: ResolverTypeWrapper<Transaction>;
  Transaction0x: ResolverTypeWrapper<Transaction0x>;
  Transaction0x_filter: Transaction0x_filter;
  Transaction0x_orderBy: Transaction0x_orderBy;
  TransactionFee0x: ResolverTypeWrapper<TransactionFee0x>;
  TransactionFee0x_filter: TransactionFee0x_filter;
  TransactionFee0x_orderBy: TransactionFee0x_orderBy;
  Transaction_filter: Transaction_filter;
  Transaction_orderBy: Transaction_orderBy;
  User: ResolverTypeWrapper<User>;
  User_filter: User_filter;
  User_orderBy: User_orderBy;
  _Block_: ResolverTypeWrapper<_Block_>;
  _Meta_: ResolverTypeWrapper<_Meta_>;
  _SubgraphErrorPolicy_: _SubgraphErrorPolicy_;
  Collection: ResolverTypeWrapper<Collection>;
  Collection_filter: Collection_filter;
  Collection_orderBy: Collection_orderBy;
  Event: ResolverTypeWrapper<Event>;
  Event_filter: Event_filter;
  Event_orderBy: Event_orderBy;
  Loan: ResolverTypeWrapper<Loan>;
  LoanStatus: LoanStatus;
  Loan_filter: Loan_filter;
  Loan_orderBy: Loan_orderBy;
  Nft: ResolverTypeWrapper<Nft>;
  Nft_filter: Nft_filter;
  Nft_orderBy: Nft_orderBy;
  Params: ResolverTypeWrapper<Params>;
  Params_filter: Params_filter;
  Params_orderBy: Params_orderBy;
  Valuation: ResolverTypeWrapper<Valuation>;
  Valuation_filter: Valuation_filter;
  Valuation_orderBy: Valuation_orderBy;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Query: {};
  Subscription: {};
  BigDecimal: Scalars['BigDecimal']['output'];
  BigInt: Scalars['BigInt']['output'];
  BlockChangedFilter: BlockChangedFilter;
  Block_height: Block_height;
  Boolean: Scalars['Boolean']['output'];
  Buyout: Buyout;
  Buyout_filter: Buyout_filter;
  Bytes: Scalars['Bytes']['output'];
  ERC1155: ERC1155;
  ERC1155_filter: ERC1155_filter;
  ERC721: ERC721;
  ERC721_filter: ERC721_filter;
  Float: Scalars['Float']['output'];
  ID: Scalars['ID']['output'];
  Int: Scalars['Int']['output'];
  Int8: Scalars['Int8']['output'];
  Oracle: Oracle;
  Oracle_filter: Oracle_filter;
  Order0x: Order0x;
  Order0x_filter: Order0x_filter;
  PurchaseEvent: PurchaseEvent;
  PurchaseEvent_filter: PurchaseEvent_filter;
  Raffle: Raffle;
  Raffle_filter: Raffle_filter;
  RedeemRequest: RedeemRequest;
  RedeemRequest_filter: RedeemRequest_filter;
  Referral: Referral;
  Referral_filter: Referral_filter;
  Role: Role;
  Role_filter: Role_filter;
  Sale: Sale;
  Sale_filter: Sale_filter;
  SpecialUser: SpecialUser;
  SpecialUser_filter: SpecialUser_filter;
  Staker: Staker;
  Staker_filter: Staker_filter;
  StakingEvent: StakingEvent;
  StakingEvent_filter: StakingEvent_filter;
  StakingParam: StakingParam;
  StakingParam_filter: StakingParam_filter;
  String: Scalars['String']['output'];
  Timestamp: Scalars['Timestamp']['output'];
  Transaction: Transaction;
  Transaction0x: Transaction0x;
  Transaction0x_filter: Transaction0x_filter;
  TransactionFee0x: TransactionFee0x;
  TransactionFee0x_filter: TransactionFee0x_filter;
  Transaction_filter: Transaction_filter;
  User: User;
  User_filter: User_filter;
  _Block_: _Block_;
  _Meta_: _Meta_;
  Collection: Collection;
  Collection_filter: Collection_filter;
  Event: Event;
  Event_filter: Event_filter;
  Loan: Loan;
  Loan_filter: Loan_filter;
  Nft: Nft;
  Nft_filter: Nft_filter;
  Params: Params;
  Params_filter: Params_filter;
  Valuation: Valuation;
  Valuation_filter: Valuation_filter;
}>;

export type entityDirectiveArgs = { };

export type entityDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = entityDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type subgraphIdDirectiveArgs = {
  id: Scalars['String']['input'];
};

export type subgraphIdDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = subgraphIdDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type derivedFromDirectiveArgs = {
  field: Scalars['String']['input'];
};

export type derivedFromDirectiveResolver<Result, Parent, ContextType = MeshContext, Args = derivedFromDirectiveArgs> = DirectiveResolverFn<Result, Parent, ContextType, Args>;

export type QueryResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryuserArgs, 'id' | 'subgraphError'>>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryusersArgs, 'skip' | 'first' | 'subgraphError'>>;
  oracle?: Resolver<Maybe<ResolversTypes['Oracle']>, ParentType, ContextType, RequireFields<QueryoracleArgs, 'id' | 'subgraphError'>>;
  oracles?: Resolver<Array<ResolversTypes['Oracle']>, ParentType, ContextType, RequireFields<QueryoraclesArgs, 'skip' | 'first' | 'subgraphError'>>;
  specialUser?: Resolver<Maybe<ResolversTypes['SpecialUser']>, ParentType, ContextType, RequireFields<QueryspecialUserArgs, 'id' | 'subgraphError'>>;
  specialUsers?: Resolver<Array<ResolversTypes['SpecialUser']>, ParentType, ContextType, RequireFields<QueryspecialUsersArgs, 'skip' | 'first' | 'subgraphError'>>;
  erc721?: Resolver<Maybe<ResolversTypes['ERC721']>, ParentType, ContextType, RequireFields<Queryerc721Args, 'id' | 'subgraphError'>>;
  erc721S?: Resolver<Array<ResolversTypes['ERC721']>, ParentType, ContextType, RequireFields<Queryerc721SArgs, 'skip' | 'first' | 'subgraphError'>>;
  erc1155?: Resolver<Maybe<ResolversTypes['ERC1155']>, ParentType, ContextType, RequireFields<Queryerc1155Args, 'id' | 'subgraphError'>>;
  erc1155S?: Resolver<Array<ResolversTypes['ERC1155']>, ParentType, ContextType, RequireFields<Queryerc1155SArgs, 'skip' | 'first' | 'subgraphError'>>;
  sale?: Resolver<Maybe<ResolversTypes['Sale']>, ParentType, ContextType, RequireFields<QuerysaleArgs, 'id' | 'subgraphError'>>;
  sales?: Resolver<Array<ResolversTypes['Sale']>, ParentType, ContextType, RequireFields<QuerysalesArgs, 'skip' | 'first' | 'subgraphError'>>;
  buyout?: Resolver<Maybe<ResolversTypes['Buyout']>, ParentType, ContextType, RequireFields<QuerybuyoutArgs, 'id' | 'subgraphError'>>;
  buyouts?: Resolver<Array<ResolversTypes['Buyout']>, ParentType, ContextType, RequireFields<QuerybuyoutsArgs, 'skip' | 'first' | 'subgraphError'>>;
  transaction?: Resolver<Maybe<ResolversTypes['Transaction']>, ParentType, ContextType, RequireFields<QuerytransactionArgs, 'id' | 'subgraphError'>>;
  transactions?: Resolver<Array<ResolversTypes['Transaction']>, ParentType, ContextType, RequireFields<QuerytransactionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  redeemRequest?: Resolver<Maybe<ResolversTypes['RedeemRequest']>, ParentType, ContextType, RequireFields<QueryredeemRequestArgs, 'id' | 'subgraphError'>>;
  redeemRequests?: Resolver<Array<ResolversTypes['RedeemRequest']>, ParentType, ContextType, RequireFields<QueryredeemRequestsArgs, 'skip' | 'first' | 'subgraphError'>>;
  role?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType, RequireFields<QueryroleArgs, 'id' | 'subgraphError'>>;
  roles?: Resolver<Array<ResolversTypes['Role']>, ParentType, ContextType, RequireFields<QueryrolesArgs, 'skip' | 'first' | 'subgraphError'>>;
  order0X?: Resolver<Maybe<ResolversTypes['Order0x']>, ParentType, ContextType, RequireFields<Queryorder0XArgs, 'id' | 'subgraphError'>>;
  order0Xes?: Resolver<Array<ResolversTypes['Order0x']>, ParentType, ContextType, RequireFields<Queryorder0XesArgs, 'skip' | 'first' | 'subgraphError'>>;
  transactionFee0X?: Resolver<Maybe<ResolversTypes['TransactionFee0x']>, ParentType, ContextType, RequireFields<QuerytransactionFee0XArgs, 'id' | 'subgraphError'>>;
  transactionFee0Xes?: Resolver<Array<ResolversTypes['TransactionFee0x']>, ParentType, ContextType, RequireFields<QuerytransactionFee0XesArgs, 'skip' | 'first' | 'subgraphError'>>;
  transaction0X?: Resolver<Maybe<ResolversTypes['Transaction0x']>, ParentType, ContextType, RequireFields<Querytransaction0XArgs, 'id' | 'subgraphError'>>;
  transaction0Xes?: Resolver<Array<ResolversTypes['Transaction0x']>, ParentType, ContextType, RequireFields<Querytransaction0XesArgs, 'skip' | 'first' | 'subgraphError'>>;
  purchaseEvent?: Resolver<Maybe<ResolversTypes['PurchaseEvent']>, ParentType, ContextType, RequireFields<QuerypurchaseEventArgs, 'id' | 'subgraphError'>>;
  purchaseEvents?: Resolver<Array<ResolversTypes['PurchaseEvent']>, ParentType, ContextType, RequireFields<QuerypurchaseEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  referral?: Resolver<Maybe<ResolversTypes['Referral']>, ParentType, ContextType, RequireFields<QueryreferralArgs, 'id' | 'subgraphError'>>;
  referrals?: Resolver<Array<ResolversTypes['Referral']>, ParentType, ContextType, RequireFields<QueryreferralsArgs, 'skip' | 'first' | 'subgraphError'>>;
  raffle?: Resolver<Maybe<ResolversTypes['Raffle']>, ParentType, ContextType, RequireFields<QueryraffleArgs, 'id' | 'subgraphError'>>;
  raffles?: Resolver<Array<ResolversTypes['Raffle']>, ParentType, ContextType, RequireFields<QueryrafflesArgs, 'skip' | 'first' | 'subgraphError'>>;
  staker?: Resolver<Maybe<ResolversTypes['Staker']>, ParentType, ContextType, RequireFields<QuerystakerArgs, 'id' | 'subgraphError'>>;
  stakers?: Resolver<Array<ResolversTypes['Staker']>, ParentType, ContextType, RequireFields<QuerystakersArgs, 'skip' | 'first' | 'subgraphError'>>;
  stakingEvent?: Resolver<Maybe<ResolversTypes['StakingEvent']>, ParentType, ContextType, RequireFields<QuerystakingEventArgs, 'id' | 'subgraphError'>>;
  stakingEvents?: Resolver<Array<ResolversTypes['StakingEvent']>, ParentType, ContextType, RequireFields<QuerystakingEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  stakingParam?: Resolver<Maybe<ResolversTypes['StakingParam']>, ParentType, ContextType, RequireFields<QuerystakingParamArgs, 'id' | 'subgraphError'>>;
  stakingParams?: Resolver<Array<ResolversTypes['StakingParam']>, ParentType, ContextType, RequireFields<QuerystakingParamsArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: Resolver<Maybe<ResolversTypes['_Meta_']>, ParentType, ContextType, Partial<Query_metaArgs>>;
  params?: Resolver<Maybe<ResolversTypes['Params']>, ParentType, ContextType, RequireFields<QueryparamsArgs, 'id' | 'subgraphError'>>;
  params_collection?: Resolver<Array<ResolversTypes['Params']>, ParentType, ContextType, RequireFields<Queryparams_collectionArgs, 'skip' | 'first' | 'subgraphError'>>;
  loan?: Resolver<Maybe<ResolversTypes['Loan']>, ParentType, ContextType, RequireFields<QueryloanArgs, 'id' | 'subgraphError'>>;
  loans?: Resolver<Array<ResolversTypes['Loan']>, ParentType, ContextType, RequireFields<QueryloansArgs, 'skip' | 'first' | 'subgraphError'>>;
  event?: Resolver<Maybe<ResolversTypes['Event']>, ParentType, ContextType, RequireFields<QueryeventArgs, 'id' | 'subgraphError'>>;
  events?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType, RequireFields<QueryeventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  collection?: Resolver<Maybe<ResolversTypes['Collection']>, ParentType, ContextType, RequireFields<QuerycollectionArgs, 'id' | 'subgraphError'>>;
  collections?: Resolver<Array<ResolversTypes['Collection']>, ParentType, ContextType, RequireFields<QuerycollectionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nft?: Resolver<Maybe<ResolversTypes['Nft']>, ParentType, ContextType, RequireFields<QuerynftArgs, 'id' | 'subgraphError'>>;
  nfts?: Resolver<Array<ResolversTypes['Nft']>, ParentType, ContextType, RequireFields<QuerynftsArgs, 'skip' | 'first' | 'subgraphError'>>;
  valuation?: Resolver<Maybe<ResolversTypes['Valuation']>, ParentType, ContextType, RequireFields<QueryvaluationArgs, 'id' | 'subgraphError'>>;
  valuations?: Resolver<Array<ResolversTypes['Valuation']>, ParentType, ContextType, RequireFields<QueryvaluationsArgs, 'skip' | 'first' | 'subgraphError'>>;
}>;

export type SubscriptionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  user?: SubscriptionResolver<Maybe<ResolversTypes['User']>, "user", ParentType, ContextType, RequireFields<SubscriptionuserArgs, 'id' | 'subgraphError'>>;
  users?: SubscriptionResolver<Array<ResolversTypes['User']>, "users", ParentType, ContextType, RequireFields<SubscriptionusersArgs, 'skip' | 'first' | 'subgraphError'>>;
  oracle?: SubscriptionResolver<Maybe<ResolversTypes['Oracle']>, "oracle", ParentType, ContextType, RequireFields<SubscriptionoracleArgs, 'id' | 'subgraphError'>>;
  oracles?: SubscriptionResolver<Array<ResolversTypes['Oracle']>, "oracles", ParentType, ContextType, RequireFields<SubscriptionoraclesArgs, 'skip' | 'first' | 'subgraphError'>>;
  specialUser?: SubscriptionResolver<Maybe<ResolversTypes['SpecialUser']>, "specialUser", ParentType, ContextType, RequireFields<SubscriptionspecialUserArgs, 'id' | 'subgraphError'>>;
  specialUsers?: SubscriptionResolver<Array<ResolversTypes['SpecialUser']>, "specialUsers", ParentType, ContextType, RequireFields<SubscriptionspecialUsersArgs, 'skip' | 'first' | 'subgraphError'>>;
  erc721?: SubscriptionResolver<Maybe<ResolversTypes['ERC721']>, "erc721", ParentType, ContextType, RequireFields<Subscriptionerc721Args, 'id' | 'subgraphError'>>;
  erc721S?: SubscriptionResolver<Array<ResolversTypes['ERC721']>, "erc721S", ParentType, ContextType, RequireFields<Subscriptionerc721SArgs, 'skip' | 'first' | 'subgraphError'>>;
  erc1155?: SubscriptionResolver<Maybe<ResolversTypes['ERC1155']>, "erc1155", ParentType, ContextType, RequireFields<Subscriptionerc1155Args, 'id' | 'subgraphError'>>;
  erc1155S?: SubscriptionResolver<Array<ResolversTypes['ERC1155']>, "erc1155S", ParentType, ContextType, RequireFields<Subscriptionerc1155SArgs, 'skip' | 'first' | 'subgraphError'>>;
  sale?: SubscriptionResolver<Maybe<ResolversTypes['Sale']>, "sale", ParentType, ContextType, RequireFields<SubscriptionsaleArgs, 'id' | 'subgraphError'>>;
  sales?: SubscriptionResolver<Array<ResolversTypes['Sale']>, "sales", ParentType, ContextType, RequireFields<SubscriptionsalesArgs, 'skip' | 'first' | 'subgraphError'>>;
  buyout?: SubscriptionResolver<Maybe<ResolversTypes['Buyout']>, "buyout", ParentType, ContextType, RequireFields<SubscriptionbuyoutArgs, 'id' | 'subgraphError'>>;
  buyouts?: SubscriptionResolver<Array<ResolversTypes['Buyout']>, "buyouts", ParentType, ContextType, RequireFields<SubscriptionbuyoutsArgs, 'skip' | 'first' | 'subgraphError'>>;
  transaction?: SubscriptionResolver<Maybe<ResolversTypes['Transaction']>, "transaction", ParentType, ContextType, RequireFields<SubscriptiontransactionArgs, 'id' | 'subgraphError'>>;
  transactions?: SubscriptionResolver<Array<ResolversTypes['Transaction']>, "transactions", ParentType, ContextType, RequireFields<SubscriptiontransactionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  redeemRequest?: SubscriptionResolver<Maybe<ResolversTypes['RedeemRequest']>, "redeemRequest", ParentType, ContextType, RequireFields<SubscriptionredeemRequestArgs, 'id' | 'subgraphError'>>;
  redeemRequests?: SubscriptionResolver<Array<ResolversTypes['RedeemRequest']>, "redeemRequests", ParentType, ContextType, RequireFields<SubscriptionredeemRequestsArgs, 'skip' | 'first' | 'subgraphError'>>;
  role?: SubscriptionResolver<Maybe<ResolversTypes['Role']>, "role", ParentType, ContextType, RequireFields<SubscriptionroleArgs, 'id' | 'subgraphError'>>;
  roles?: SubscriptionResolver<Array<ResolversTypes['Role']>, "roles", ParentType, ContextType, RequireFields<SubscriptionrolesArgs, 'skip' | 'first' | 'subgraphError'>>;
  order0X?: SubscriptionResolver<Maybe<ResolversTypes['Order0x']>, "order0X", ParentType, ContextType, RequireFields<Subscriptionorder0XArgs, 'id' | 'subgraphError'>>;
  order0Xes?: SubscriptionResolver<Array<ResolversTypes['Order0x']>, "order0Xes", ParentType, ContextType, RequireFields<Subscriptionorder0XesArgs, 'skip' | 'first' | 'subgraphError'>>;
  transactionFee0X?: SubscriptionResolver<Maybe<ResolversTypes['TransactionFee0x']>, "transactionFee0X", ParentType, ContextType, RequireFields<SubscriptiontransactionFee0XArgs, 'id' | 'subgraphError'>>;
  transactionFee0Xes?: SubscriptionResolver<Array<ResolversTypes['TransactionFee0x']>, "transactionFee0Xes", ParentType, ContextType, RequireFields<SubscriptiontransactionFee0XesArgs, 'skip' | 'first' | 'subgraphError'>>;
  transaction0X?: SubscriptionResolver<Maybe<ResolversTypes['Transaction0x']>, "transaction0X", ParentType, ContextType, RequireFields<Subscriptiontransaction0XArgs, 'id' | 'subgraphError'>>;
  transaction0Xes?: SubscriptionResolver<Array<ResolversTypes['Transaction0x']>, "transaction0Xes", ParentType, ContextType, RequireFields<Subscriptiontransaction0XesArgs, 'skip' | 'first' | 'subgraphError'>>;
  purchaseEvent?: SubscriptionResolver<Maybe<ResolversTypes['PurchaseEvent']>, "purchaseEvent", ParentType, ContextType, RequireFields<SubscriptionpurchaseEventArgs, 'id' | 'subgraphError'>>;
  purchaseEvents?: SubscriptionResolver<Array<ResolversTypes['PurchaseEvent']>, "purchaseEvents", ParentType, ContextType, RequireFields<SubscriptionpurchaseEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  referral?: SubscriptionResolver<Maybe<ResolversTypes['Referral']>, "referral", ParentType, ContextType, RequireFields<SubscriptionreferralArgs, 'id' | 'subgraphError'>>;
  referrals?: SubscriptionResolver<Array<ResolversTypes['Referral']>, "referrals", ParentType, ContextType, RequireFields<SubscriptionreferralsArgs, 'skip' | 'first' | 'subgraphError'>>;
  raffle?: SubscriptionResolver<Maybe<ResolversTypes['Raffle']>, "raffle", ParentType, ContextType, RequireFields<SubscriptionraffleArgs, 'id' | 'subgraphError'>>;
  raffles?: SubscriptionResolver<Array<ResolversTypes['Raffle']>, "raffles", ParentType, ContextType, RequireFields<SubscriptionrafflesArgs, 'skip' | 'first' | 'subgraphError'>>;
  staker?: SubscriptionResolver<Maybe<ResolversTypes['Staker']>, "staker", ParentType, ContextType, RequireFields<SubscriptionstakerArgs, 'id' | 'subgraphError'>>;
  stakers?: SubscriptionResolver<Array<ResolversTypes['Staker']>, "stakers", ParentType, ContextType, RequireFields<SubscriptionstakersArgs, 'skip' | 'first' | 'subgraphError'>>;
  stakingEvent?: SubscriptionResolver<Maybe<ResolversTypes['StakingEvent']>, "stakingEvent", ParentType, ContextType, RequireFields<SubscriptionstakingEventArgs, 'id' | 'subgraphError'>>;
  stakingEvents?: SubscriptionResolver<Array<ResolversTypes['StakingEvent']>, "stakingEvents", ParentType, ContextType, RequireFields<SubscriptionstakingEventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  stakingParam?: SubscriptionResolver<Maybe<ResolversTypes['StakingParam']>, "stakingParam", ParentType, ContextType, RequireFields<SubscriptionstakingParamArgs, 'id' | 'subgraphError'>>;
  stakingParams?: SubscriptionResolver<Array<ResolversTypes['StakingParam']>, "stakingParams", ParentType, ContextType, RequireFields<SubscriptionstakingParamsArgs, 'skip' | 'first' | 'subgraphError'>>;
  _meta?: SubscriptionResolver<Maybe<ResolversTypes['_Meta_']>, "_meta", ParentType, ContextType, Partial<Subscription_metaArgs>>;
  params?: SubscriptionResolver<Maybe<ResolversTypes['Params']>, "params", ParentType, ContextType, RequireFields<SubscriptionparamsArgs, 'id' | 'subgraphError'>>;
  params_collection?: SubscriptionResolver<Array<ResolversTypes['Params']>, "params_collection", ParentType, ContextType, RequireFields<Subscriptionparams_collectionArgs, 'skip' | 'first' | 'subgraphError'>>;
  loan?: SubscriptionResolver<Maybe<ResolversTypes['Loan']>, "loan", ParentType, ContextType, RequireFields<SubscriptionloanArgs, 'id' | 'subgraphError'>>;
  loans?: SubscriptionResolver<Array<ResolversTypes['Loan']>, "loans", ParentType, ContextType, RequireFields<SubscriptionloansArgs, 'skip' | 'first' | 'subgraphError'>>;
  event?: SubscriptionResolver<Maybe<ResolversTypes['Event']>, "event", ParentType, ContextType, RequireFields<SubscriptioneventArgs, 'id' | 'subgraphError'>>;
  events?: SubscriptionResolver<Array<ResolversTypes['Event']>, "events", ParentType, ContextType, RequireFields<SubscriptioneventsArgs, 'skip' | 'first' | 'subgraphError'>>;
  collection?: SubscriptionResolver<Maybe<ResolversTypes['Collection']>, "collection", ParentType, ContextType, RequireFields<SubscriptioncollectionArgs, 'id' | 'subgraphError'>>;
  collections?: SubscriptionResolver<Array<ResolversTypes['Collection']>, "collections", ParentType, ContextType, RequireFields<SubscriptioncollectionsArgs, 'skip' | 'first' | 'subgraphError'>>;
  nft?: SubscriptionResolver<Maybe<ResolversTypes['Nft']>, "nft", ParentType, ContextType, RequireFields<SubscriptionnftArgs, 'id' | 'subgraphError'>>;
  nfts?: SubscriptionResolver<Array<ResolversTypes['Nft']>, "nfts", ParentType, ContextType, RequireFields<SubscriptionnftsArgs, 'skip' | 'first' | 'subgraphError'>>;
  valuation?: SubscriptionResolver<Maybe<ResolversTypes['Valuation']>, "valuation", ParentType, ContextType, RequireFields<SubscriptionvaluationArgs, 'id' | 'subgraphError'>>;
  valuations?: SubscriptionResolver<Array<ResolversTypes['Valuation']>, "valuations", ParentType, ContextType, RequireFields<SubscriptionvaluationsArgs, 'skip' | 'first' | 'subgraphError'>>;
}>;

export interface BigDecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigDecimal'], any> {
  name: 'BigDecimal';
}

export interface BigIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['BigInt'], any> {
  name: 'BigInt';
}

export type BuyoutResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Buyout'] = ResolversParentTypes['Buyout']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  buyoutId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  erc1155?: Resolver<ResolversTypes['ERC1155'], ParentType, ContextType>;
  openingTime?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  closingTime?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  initiator?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  buyoutPrice?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  buyoutToken?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  buyoutTokenManager?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface BytesScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Bytes'], any> {
  name: 'Bytes';
}

export type ERC1155Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ERC1155'] = ResolversParentTypes['ERC1155']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  contractAddress?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  supply?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  owners?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType, RequireFields<ERC1155ownersArgs, 'skip' | 'first'>>;
  ownersBalances?: Resolver<Maybe<Array<ResolversTypes['BigInt']>>, ParentType, ContextType>;
  erc721?: Resolver<Maybe<ResolversTypes['ERC721']>, ParentType, ContextType>;
  transactionHistory?: Resolver<Maybe<Array<ResolversTypes['Transaction']>>, ParentType, ContextType, RequireFields<ERC1155transactionHistoryArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ERC721Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['ERC721'] = ResolversParentTypes['ERC721']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  contractAddress?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  owner?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  approved?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  operators?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType, RequireFields<ERC721operatorsArgs, 'skip' | 'first'>>;
  erc1155?: Resolver<Maybe<ResolversTypes['ERC1155']>, ParentType, ContextType>;
  transactionHistory?: Resolver<Maybe<Array<ResolversTypes['Transaction']>>, ParentType, ContextType, RequireFields<ERC721transactionHistoryArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface Int8ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Int8'], any> {
  name: 'Int8';
}

export type OracleResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Oracle'] = ResolversParentTypes['Oracle']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  address?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  erc721Owned?: Resolver<Maybe<Array<ResolversTypes['ERC721']>>, ParentType, ContextType, RequireFields<Oracleerc721OwnedArgs, 'skip' | 'first'>>;
  erc1155?: Resolver<Maybe<Array<ResolversTypes['ERC1155']>>, ParentType, ContextType, RequireFields<Oracleerc1155Args, 'skip' | 'first'>>;
  erc1155Balance?: Resolver<Maybe<Array<ResolversTypes['BigInt']>>, ParentType, ContextType>;
  allowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  erc721?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Order0xResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Order0x'] = ResolversParentTypes['Order0x']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  maker?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  taker?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  direction?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  nonce?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  cancelled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  filled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  erc20Token?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  erc20FilledAmount?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  nftTokenType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nftToken?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  nftTokenId?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  nftTokenFilledAmount?: Resolver<Maybe<ResolversTypes['BigInt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PurchaseEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['PurchaseEvent'] = ResolversParentTypes['PurchaseEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  raffleId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  buyer?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  referrer?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  ticketAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RaffleResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Raffle'] = ResolversParentTypes['Raffle']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  raffleTicketPurchase?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  raffleRewarder?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['RaffleStatus']>, ParentType, ContextType>;
  purchaseToken?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  ticketPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  startTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  finishTimestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  minCap?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  maxCap?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  personalMaxCap?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  prize?: Resolver<Maybe<ResolversTypes['ERC721']>, ParentType, ContextType>;
  participantsProof?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  winner?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RedeemRequestResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['RedeemRequest'] = ResolversParentTypes['RedeemRequest']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  erc721?: Resolver<ResolversTypes['ERC721'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  operator?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReferralResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Referral'] = ResolversParentTypes['Referral']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  raffleId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  referralCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  ticketAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  referrerOf?: Resolver<Maybe<Array<ResolversTypes['Bytes']>>, ParentType, ContextType>;
  referralAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RoleResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Role'] = ResolversParentTypes['Role']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  contract?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  specialUsers?: Resolver<Maybe<Array<ResolversTypes['SpecialUser']>>, ParentType, ContextType, RequireFields<RolespecialUsersArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SaleResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Sale'] = ResolversParentTypes['Sale']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  openingTime?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  closingTime?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  saleId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  isSuccessful?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  buyer?: Resolver<Maybe<Array<ResolversTypes['User']>>, ParentType, ContextType, RequireFields<SalebuyerArgs, 'skip' | 'first'>>;
  seller?: Resolver<ResolversTypes['Oracle'], ParentType, ContextType>;
  fractionPrice?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  fractionsAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  fractionsSold?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  erc721?: Resolver<ResolversTypes['ERC721'], ParentType, ContextType>;
  erc1155?: Resolver<Maybe<ResolversTypes['ERC1155']>, ParentType, ContextType>;
  sellerReleased?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SpecialUserResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['SpecialUser'] = ResolversParentTypes['SpecialUser']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  address?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  erc721Owned?: Resolver<Maybe<Array<ResolversTypes['ERC721']>>, ParentType, ContextType, RequireFields<SpecialUsererc721OwnedArgs, 'skip' | 'first'>>;
  erc1155?: Resolver<Maybe<Array<ResolversTypes['ERC1155']>>, ParentType, ContextType, RequireFields<SpecialUsererc1155Args, 'skip' | 'first'>>;
  erc1155Balance?: Resolver<Maybe<Array<ResolversTypes['BigInt']>>, ParentType, ContextType>;
  allowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<ResolversTypes['Role']>>, ParentType, ContextType, RequireFields<SpecialUserrolesArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StakerResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Staker'] = ResolversParentTypes['Staker']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  stakeAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  unclaimedRewards?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  lastBoostUpdate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  lastUpdate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StakingEventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['StakingEvent'] = ResolversParentTypes['StakingEvent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  staker?: Resolver<ResolversTypes['Staker'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StakingParamResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['StakingParam'] = ResolversParentTypes['StakingParam']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  stakingToken?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  stakingTokenCap?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  minStakingBoostAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  rewardRatioNumerator?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  tiersDurations?: Resolver<Array<ResolversTypes['BigInt']>, ParentType, ContextType>;
  tiersMultipliers?: Resolver<Array<ResolversTypes['BigInt']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface TimestampScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Timestamp'], any> {
  name: 'Timestamp';
}

export type TransactionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Transaction'] = ResolversParentTypes['Transaction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  block?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  from?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  to?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  erc721?: Resolver<Maybe<ResolversTypes['ERC721']>, ParentType, ContextType>;
  erc1155?: Resolver<Maybe<Array<ResolversTypes['ERC1155']>>, ParentType, ContextType, RequireFields<Transactionerc1155Args, 'skip' | 'first'>>;
  erc1155Quantity?: Resolver<Maybe<Array<ResolversTypes['BigInt']>>, ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Transaction0xResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Transaction0x'] = ResolversParentTypes['Transaction0x']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  transactionId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Order0x'], ParentType, ContextType>;
  erc20Token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  erc20TokenAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  fees?: Resolver<Maybe<Array<ResolversTypes['TransactionFee0x']>>, ParentType, ContextType, RequireFields<Transaction0xfeesArgs, 'skip' | 'first'>>;
  nftTokenType?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nftToken?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  nftTokenId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  nftTokenAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TransactionFee0xResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['TransactionFee0x'] = ResolversParentTypes['TransactionFee0x']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  tokenAddress?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  receiver?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  feeData?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  transactionId?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  address?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  erc721Owned?: Resolver<Maybe<Array<ResolversTypes['ERC721']>>, ParentType, ContextType, RequireFields<Usererc721OwnedArgs, 'skip' | 'first'>>;
  erc1155?: Resolver<Maybe<Array<ResolversTypes['ERC1155']>>, ParentType, ContextType, RequireFields<Usererc1155Args, 'skip' | 'first'>>;
  erc1155Balance?: Resolver<Maybe<Array<ResolversTypes['BigInt']>>, ParentType, ContextType>;
  allowed?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Block_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Block_'] = ResolversParentTypes['_Block_']> = ResolversObject<{
  hash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  number?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  parentHash?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type _Meta_Resolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['_Meta_'] = ResolversParentTypes['_Meta_']> = ResolversObject<{
  block?: Resolver<ResolversTypes['_Block_'], ParentType, ContextType>;
  deployment?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasIndexingErrors?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CollectionResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Collection'] = ResolversParentTypes['Collection']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  address?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  oracle?: Resolver<Maybe<ResolversTypes['Bytes']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type EventResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  eventType?: Resolver<ResolversTypes['LoanStatus'], ParentType, ContextType>;
  time?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  transactionHash?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  loan?: Resolver<ResolversTypes['Loan'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LoanResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Loan'] = ResolversParentTypes['Loan']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['LoanStatus'], ParentType, ContextType>;
  borrower?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  amount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  nft?: Resolver<ResolversTypes['Nft'], ParentType, ContextType>;
  duration?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  deadline?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  interestRate?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  collateralValue?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  dueAmount?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  lender?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  startTime?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  totalPaid?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  feePaid?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  liquidator?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  events?: Resolver<Array<ResolversTypes['Event']>, ParentType, ContextType, RequireFields<LoaneventsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type NftResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Nft'] = ResolversParentTypes['Nft']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  collection?: Resolver<ResolversTypes['Collection'], ParentType, ContextType>;
  tokenId?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  valuationHistory?: Resolver<Maybe<Array<ResolversTypes['Valuation']>>, ParentType, ContextType, RequireFields<NftvaluationHistoryArgs, 'skip' | 'first'>>;
  owner?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  loans?: Resolver<Maybe<Array<ResolversTypes['Loan']>>, ParentType, ContextType, RequireFields<NftloansArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ParamsResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Params'] = ResolversParentTypes['Params']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  priceIndex?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  allowList?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  governanceTreasury?: Resolver<ResolversTypes['Bytes'], ParentType, ContextType>;
  repayGraceFee?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  protocolFee?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  liquidationFee?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  baseOriginationFee?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  feeReductionFactor?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  originationFeeRanges?: Resolver<Array<ResolversTypes['BigInt']>, ParentType, ContextType>;
  tokens?: Resolver<Array<ResolversTypes['Bytes']>, ParentType, ContextType>;
  loanTypesDurations?: Resolver<Array<ResolversTypes['BigInt']>, ParentType, ContextType>;
  loanTypesInterestRates?: Resolver<Array<ResolversTypes['BigInt']>, ParentType, ContextType>;
  repayGracePeriod?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  lenderExclusiveLiquidationPeriod?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  disallowedNfts?: Resolver<Array<ResolversTypes['Nft']>, ParentType, ContextType, RequireFields<ParamsdisallowedNftsArgs, 'skip' | 'first'>>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ValuationResolvers<ContextType = MeshContext, ParentType extends ResolversParentTypes['Valuation'] = ResolversParentTypes['Valuation']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  ltv?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  timestamp?: Resolver<ResolversTypes['BigInt'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = MeshContext> = ResolversObject<{
  Query?: QueryResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  BigDecimal?: GraphQLScalarType;
  BigInt?: GraphQLScalarType;
  Buyout?: BuyoutResolvers<ContextType>;
  Bytes?: GraphQLScalarType;
  ERC1155?: ERC1155Resolvers<ContextType>;
  ERC721?: ERC721Resolvers<ContextType>;
  Int8?: GraphQLScalarType;
  Oracle?: OracleResolvers<ContextType>;
  Order0x?: Order0xResolvers<ContextType>;
  PurchaseEvent?: PurchaseEventResolvers<ContextType>;
  Raffle?: RaffleResolvers<ContextType>;
  RedeemRequest?: RedeemRequestResolvers<ContextType>;
  Referral?: ReferralResolvers<ContextType>;
  Role?: RoleResolvers<ContextType>;
  Sale?: SaleResolvers<ContextType>;
  SpecialUser?: SpecialUserResolvers<ContextType>;
  Staker?: StakerResolvers<ContextType>;
  StakingEvent?: StakingEventResolvers<ContextType>;
  StakingParam?: StakingParamResolvers<ContextType>;
  Timestamp?: GraphQLScalarType;
  Transaction?: TransactionResolvers<ContextType>;
  Transaction0x?: Transaction0xResolvers<ContextType>;
  TransactionFee0x?: TransactionFee0xResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  _Block_?: _Block_Resolvers<ContextType>;
  _Meta_?: _Meta_Resolvers<ContextType>;
  Collection?: CollectionResolvers<ContextType>;
  Event?: EventResolvers<ContextType>;
  Loan?: LoanResolvers<ContextType>;
  Nft?: NftResolvers<ContextType>;
  Params?: ParamsResolvers<ContextType>;
  Valuation?: ValuationResolvers<ContextType>;
}>;

export type DirectiveResolvers<ContextType = MeshContext> = ResolversObject<{
  entity?: entityDirectiveResolver<any, any, ContextType>;
  subgraphId?: subgraphIdDirectiveResolver<any, any, ContextType>;
  derivedFrom?: derivedFromDirectiveResolver<any, any, ContextType>;
}>;

export type MeshContext = AltrTypes.Context & AltrLendTypes.Context & BaseMeshContext;


const baseDir = pathModule.join(typeof __dirname === 'string' ? __dirname : '/', '..');

const importFn: ImportFn = <T>(moduleId: string) => {
  const relativeModuleId = (pathModule.isAbsolute(moduleId) ? pathModule.relative(baseDir, moduleId) : moduleId).split('\\').join('/').replace(baseDir + '/', '');
  switch(relativeModuleId) {
    case ".graphclient/sources/altr-lend/introspectionSchema":
      return Promise.resolve(importedModule$0) as T;
    
    case ".graphclient/sources/altr/introspectionSchema":
      return Promise.resolve(importedModule$1) as T;
    
    default:
      return Promise.reject(new Error(`Cannot find module '${relativeModuleId}'.`));
  }
};

const rootStore = new MeshStore('.graphclient', new FsStoreStorageAdapter({
  cwd: baseDir,
  importFn,
  fileType: "ts",
}), {
  readonly: true,
  validate: false
});

export const rawServeConfig: YamlConfig.Config['serve'] = undefined as any
export async function getMeshOptions(): Promise<GetMeshOptions> {
const pubsub = new PubSub();
const sourcesStore = rootStore.child('sources');
const logger = new DefaultLogger("GraphClient");
const cache = new (MeshCache as any)({
      ...({} as any),
      importFn,
      store: rootStore.child('cache'),
      pubsub,
      logger,
    } as any)

const sources: MeshResolvedSource[] = [];
const transforms: MeshTransform[] = [];
const additionalEnvelopPlugins: MeshPlugin<any>[] = [];
const altrTransforms = [];
const altrLendTransforms = [];
const additionalTypeDefs = [] as any[];
const altrHandler = new GraphqlHandler({
              name: "altr",
              config: {"endpoint":"https://subgraph.satsuma-prod.com/cebbf7320c75/lucidao--298598/altr/api"},
              baseDir,
              cache,
              pubsub,
              store: sourcesStore.child("altr"),
              logger: logger.child("altr"),
              importFn,
            });
const altrLendHandler = new GraphqlHandler({
              name: "altr-lend",
              config: {"endpoint":"https://subgraph.satsuma-prod.com/cebbf7320c75/lucidao--298598/altr-lend/api"},
              baseDir,
              cache,
              pubsub,
              store: sourcesStore.child("altr-lend"),
              logger: logger.child("altr-lend"),
              importFn,
            });
sources[0] = {
          name: 'altr',
          handler: altrHandler,
          transforms: altrTransforms
        }
sources[1] = {
          name: 'altr-lend',
          handler: altrLendHandler,
          transforms: altrLendTransforms
        }
const additionalResolvers = [] as any[]
const merger = new(StitchingMerger as any)({
        cache,
        pubsub,
        logger: logger.child('stitchingMerger'),
        store: rootStore.child('stitchingMerger')
      })
const documentHashMap = {
        "1f69554d89b9b4c54ccdec40582f7b3abba5572640face5e1fc315d00bf419df": Get0xTransactionHistoryDocument,
"1df33eadc73c7b88dffc9c312aab948fd91539f73bebb025155e097c17f619ac": Get0xTransactionsByNftIdDocument,
"4528f1442a8f15b6935e2ff64516bfca2fc5d68fd2c46f5ce3ab63ecaa6ef590": GetAllTransactionHistoryDocument,
"58d76ba1c24aae1ab0eeb9a4bdbf3cbdd06f615e04d09c4e1a123370c5511f54": GetAllTransactionsByNftIdAndAddressDocument,
"fe0e0ccaa42bc36130a427b0290f8726ffd1fc652aea886fe0b957e2c62d7091": GetBuyoutDataFromFragmentsIdDocument,
"fe0e0ccaa42bc36130a427b0290f8726ffd1fc652aea886fe0b957e2c62d7091": GetBuyoutDataFromInitiatorDocument,
"fe0e0ccaa42bc36130a427b0290f8726ffd1fc652aea886fe0b957e2c62d7091": GetBuyoutDataFromFragmentsIdAndInitiatorDocument,
"67a89a379f28c9416fc0ae48143e91db25d21d5a5dad6066e2dda29ff6272d4d": GetFractionsDataFromNftDocument,
"3874763309094a033c41deea5c90f87a853b410ae3ab4dc9431c59bd46f70945": GetFractionsOwnersDocument,
"3874763309094a033c41deea5c90f87a853b410ae3ab4dc9431c59bd46f70945": GetNftOwnerDocument,
"beeb77cfa9f59f381d97539e8310ff906f5f303f6e5b1379d3972a5297b13e2d": GetNftValuationHistoryDocument,
"2354a48d2a5d859ca353a8cf9680c52c6258529b78b689195420a7aa1e775314": GetRaffleBoostsDocument,
"2354a48d2a5d859ca353a8cf9680c52c6258529b78b689195420a7aa1e775314": GetRaffleBuyersDocument,
"2354a48d2a5d859ca353a8cf9680c52c6258529b78b689195420a7aa1e775314": GetAllRaffleBoostsDocument,
"b332a72b2404cef780fa3fd9ad9acbe2d11a4c2f2b1380e11463f83f41ffe971": GetRaffleByIdDocument,
"b1cbe05d9f63c2db9c49ee7efea98642cb2db62fde266c453b31044b0ddfaf63": GetUserActivityDocument
      }
additionalEnvelopPlugins.push(usePersistedOperations({
        getPersistedOperation(key) {
          return documentHashMap[key];
        },
        ...{}
      }))

  return {
    sources,
    transforms,
    additionalTypeDefs,
    additionalResolvers,
    cache,
    pubsub,
    merger,
    logger,
    additionalEnvelopPlugins,
    get documents() {
      return [
      {
        document: Get0xTransactionHistoryDocument,
        get rawSDL() {
          return printWithCache(Get0xTransactionHistoryDocument);
        },
        location: 'Get0xTransactionHistoryDocument.graphql',
        sha256Hash: '1f69554d89b9b4c54ccdec40582f7b3abba5572640face5e1fc315d00bf419df'
      },{
        document: Get0xTransactionsByNftIdDocument,
        get rawSDL() {
          return printWithCache(Get0xTransactionsByNftIdDocument);
        },
        location: 'Get0xTransactionsByNftIdDocument.graphql',
        sha256Hash: '1df33eadc73c7b88dffc9c312aab948fd91539f73bebb025155e097c17f619ac'
      },{
        document: GetAllTransactionHistoryDocument,
        get rawSDL() {
          return printWithCache(GetAllTransactionHistoryDocument);
        },
        location: 'GetAllTransactionHistoryDocument.graphql',
        sha256Hash: '4528f1442a8f15b6935e2ff64516bfca2fc5d68fd2c46f5ce3ab63ecaa6ef590'
      },{
        document: GetAllTransactionsByNftIdAndAddressDocument,
        get rawSDL() {
          return printWithCache(GetAllTransactionsByNftIdAndAddressDocument);
        },
        location: 'GetAllTransactionsByNftIdAndAddressDocument.graphql',
        sha256Hash: '58d76ba1c24aae1ab0eeb9a4bdbf3cbdd06f615e04d09c4e1a123370c5511f54'
      },{
        document: GetBuyoutDataFromFragmentsIdDocument,
        get rawSDL() {
          return printWithCache(GetBuyoutDataFromFragmentsIdDocument);
        },
        location: 'GetBuyoutDataFromFragmentsIdDocument.graphql',
        sha256Hash: 'fe0e0ccaa42bc36130a427b0290f8726ffd1fc652aea886fe0b957e2c62d7091'
      },{
        document: GetBuyoutDataFromInitiatorDocument,
        get rawSDL() {
          return printWithCache(GetBuyoutDataFromInitiatorDocument);
        },
        location: 'GetBuyoutDataFromInitiatorDocument.graphql',
        sha256Hash: 'fe0e0ccaa42bc36130a427b0290f8726ffd1fc652aea886fe0b957e2c62d7091'
      },{
        document: GetBuyoutDataFromFragmentsIdAndInitiatorDocument,
        get rawSDL() {
          return printWithCache(GetBuyoutDataFromFragmentsIdAndInitiatorDocument);
        },
        location: 'GetBuyoutDataFromFragmentsIdAndInitiatorDocument.graphql',
        sha256Hash: 'fe0e0ccaa42bc36130a427b0290f8726ffd1fc652aea886fe0b957e2c62d7091'
      },{
        document: GetFractionsDataFromNftDocument,
        get rawSDL() {
          return printWithCache(GetFractionsDataFromNftDocument);
        },
        location: 'GetFractionsDataFromNftDocument.graphql',
        sha256Hash: '67a89a379f28c9416fc0ae48143e91db25d21d5a5dad6066e2dda29ff6272d4d'
      },{
        document: GetFractionsOwnersDocument,
        get rawSDL() {
          return printWithCache(GetFractionsOwnersDocument);
        },
        location: 'GetFractionsOwnersDocument.graphql',
        sha256Hash: '3874763309094a033c41deea5c90f87a853b410ae3ab4dc9431c59bd46f70945'
      },{
        document: GetNftOwnerDocument,
        get rawSDL() {
          return printWithCache(GetNftOwnerDocument);
        },
        location: 'GetNftOwnerDocument.graphql',
        sha256Hash: '3874763309094a033c41deea5c90f87a853b410ae3ab4dc9431c59bd46f70945'
      },{
        document: GetNftValuationHistoryDocument,
        get rawSDL() {
          return printWithCache(GetNftValuationHistoryDocument);
        },
        location: 'GetNftValuationHistoryDocument.graphql',
        sha256Hash: 'beeb77cfa9f59f381d97539e8310ff906f5f303f6e5b1379d3972a5297b13e2d'
      },{
        document: GetRaffleBoostsDocument,
        get rawSDL() {
          return printWithCache(GetRaffleBoostsDocument);
        },
        location: 'GetRaffleBoostsDocument.graphql',
        sha256Hash: '2354a48d2a5d859ca353a8cf9680c52c6258529b78b689195420a7aa1e775314'
      },{
        document: GetRaffleBuyersDocument,
        get rawSDL() {
          return printWithCache(GetRaffleBuyersDocument);
        },
        location: 'GetRaffleBuyersDocument.graphql',
        sha256Hash: '2354a48d2a5d859ca353a8cf9680c52c6258529b78b689195420a7aa1e775314'
      },{
        document: GetAllRaffleBoostsDocument,
        get rawSDL() {
          return printWithCache(GetAllRaffleBoostsDocument);
        },
        location: 'GetAllRaffleBoostsDocument.graphql',
        sha256Hash: '2354a48d2a5d859ca353a8cf9680c52c6258529b78b689195420a7aa1e775314'
      },{
        document: GetRaffleByIdDocument,
        get rawSDL() {
          return printWithCache(GetRaffleByIdDocument);
        },
        location: 'GetRaffleByIdDocument.graphql',
        sha256Hash: 'b332a72b2404cef780fa3fd9ad9acbe2d11a4c2f2b1380e11463f83f41ffe971'
      },{
        document: GetUserActivityDocument,
        get rawSDL() {
          return printWithCache(GetUserActivityDocument);
        },
        location: 'GetUserActivityDocument.graphql',
        sha256Hash: 'b1cbe05d9f63c2db9c49ee7efea98642cb2db62fde266c453b31044b0ddfaf63'
      }
    ];
    },
    fetchFn,
  };
}

export function createBuiltMeshHTTPHandler<TServerContext = {}>(): MeshHTTPHandler<TServerContext> {
  return createMeshHTTPHandler<TServerContext>({
    baseDir,
    getBuiltMesh: getBuiltGraphClient,
    rawServeConfig: undefined,
  })
}


let meshInstance$: Promise<MeshInstance> | undefined;

export const pollingInterval = null;

export function getBuiltGraphClient(): Promise<MeshInstance> {
  if (meshInstance$ == null) {
    if (pollingInterval) {
      setInterval(() => {
        getMeshOptions()
        .then(meshOptions => getMesh(meshOptions))
        .then(newMesh =>
          meshInstance$.then(oldMesh => {
            oldMesh.destroy()
            meshInstance$ = Promise.resolve(newMesh)
          })
        ).catch(err => {
          console.error("Mesh polling failed so the existing version will be used:", err);
        });
      }, pollingInterval)
    }
    meshInstance$ = getMeshOptions().then(meshOptions => getMesh(meshOptions)).then(mesh => {
      const id = mesh.pubsub.subscribe('destroy', () => {
        meshInstance$ = undefined;
        mesh.pubsub.unsubscribe(id);
      });
      return mesh;
    });
  }
  return meshInstance$;
}

export const execute: ExecuteMeshFn = (...args) => getBuiltGraphClient().then(({ execute }) => execute(...args));

export const subscribe: SubscribeMeshFn = (...args) => getBuiltGraphClient().then(({ subscribe }) => subscribe(...args));
export function getBuiltGraphSDK<TGlobalContext = any, TOperationContext = any>(globalContext?: TGlobalContext) {
  const sdkRequester$ = getBuiltGraphClient().then(({ sdkRequesterFactory }) => sdkRequesterFactory(globalContext));
  return getSdk<TOperationContext, TGlobalContext>((...args) => sdkRequester$.then(sdkRequester => sdkRequester(...args)));
}
export type Get0xTransactionHistoryQueryVariables = Exact<{
  userAddress: Scalars['String']['input'];
  orderDirection?: OrderDirection;
}>;


export type Get0xTransactionHistoryQuery = { transaction0Xes: Array<(
    Pick<Transaction0x, 'id' | 'transactionId' | 'nftTokenType' | 'nftToken' | 'nftTokenId' | 'nftTokenAmount' | 'erc20Token' | 'erc20TokenAmount' | 'timestamp'>
    & { order: (
      Pick<Order0x, 'direction'>
      & { maker: Pick<User, 'id' | 'address'>, taker?: Maybe<Pick<User, 'id' | 'address'>> }
    ) }
  )> };

export type Get0xTransactionsByNftIdQueryVariables = Exact<{
  contractAddress: Scalars['Bytes']['input'];
  tokenId: Scalars['BigInt']['input'];
  orderDirection?: OrderDirection;
}>;


export type Get0xTransactionsByNftIdQuery = { transaction0Xes: Array<(
    Pick<Transaction0x, 'id' | 'transactionId' | 'nftTokenType' | 'nftToken' | 'nftTokenId' | 'nftTokenAmount' | 'erc20Token' | 'erc20TokenAmount' | 'timestamp'>
    & { order: (
      Pick<Order0x, 'direction'>
      & { maker: Pick<User, 'id' | 'address'>, taker?: Maybe<Pick<User, 'id' | 'address'>> }
    ) }
  )> };

export type GetAllTransactionHistoryQueryVariables = Exact<{
  userAddress?: InputMaybe<Scalars['String']['input']>;
  orderDirection?: OrderDirection;
}>;


export type GetAllTransactionHistoryQuery = { transactions: Array<(
    Pick<Transaction, 'id' | 'block' | 'erc1155Quantity' | 'timestamp'>
    & { erc721?: Maybe<Pick<ERC721, 'id'>>, erc1155?: Maybe<Array<Pick<ERC1155, 'id'>>>, from: Pick<User, 'id' | 'address'>, to: Pick<User, 'id' | 'address'> }
  )>, transaction0Xes: Array<(
    Pick<Transaction0x, 'id' | 'transactionId' | 'nftTokenType' | 'nftToken' | 'nftTokenId' | 'nftTokenAmount' | 'erc20Token' | 'erc20TokenAmount' | 'timestamp'>
    & { order: (
      Pick<Order0x, 'direction'>
      & { maker: Pick<User, 'id' | 'address'>, taker?: Maybe<Pick<User, 'id' | 'address'>> }
    ), fees?: Maybe<Array<Pick<TransactionFee0x, 'id' | 'tokenAddress' | 'amount' | 'receiver' | 'feeData'>>> }
  )> };

export type GetAllTransactionsByNftIdAndAddressQueryVariables = Exact<{
  userAddress: Scalars['String']['input'];
  contractAddress: Scalars['Bytes']['input'];
  tokenId: Scalars['BigInt']['input'];
  orderDirection?: OrderDirection;
}>;


export type GetAllTransactionsByNftIdAndAddressQuery = { transactions: Array<(
    Pick<Transaction, 'id' | 'block' | 'erc1155Quantity' | 'timestamp'>
    & { erc721?: Maybe<Pick<ERC721, 'id'>>, erc1155?: Maybe<Array<Pick<ERC1155, 'id'>>>, from: Pick<User, 'id' | 'address'>, to: Pick<User, 'id' | 'address'> }
  )>, transaction0Xes: Array<(
    Pick<Transaction0x, 'id' | 'transactionId' | 'nftTokenType' | 'nftToken' | 'nftTokenId' | 'nftTokenAmount' | 'erc20Token' | 'erc20TokenAmount' | 'timestamp'>
    & { order: (
      Pick<Order0x, 'direction'>
      & { maker: Pick<User, 'id' | 'address'>, taker?: Maybe<Pick<User, 'id' | 'address'>> }
    ), fees?: Maybe<Array<Pick<TransactionFee0x, 'id' | 'tokenAddress' | 'amount' | 'receiver' | 'feeData'>>> }
  )> };

export type GetBuyoutDataFromFragmentsIdQueryVariables = Exact<{
  erc1155Id: Scalars['String']['input'];
}>;


export type GetBuyoutDataFromFragmentsIdQuery = { buyouts: Array<(
    Pick<Buyout, 'buyoutId' | 'buyoutPrice' | 'buyoutToken' | 'buyoutTokenManager' | 'closingTime' | 'isSuccessful' | 'openingTime'>
    & { erc1155: Pick<ERC1155, 'id' | 'contractAddress' | 'tokenId'>, initiator: Pick<User, 'id' | 'address'> }
  )> };

export type GetBuyoutDataFromInitiatorQueryVariables = Exact<{
  userAddress: Scalars['String']['input'];
}>;


export type GetBuyoutDataFromInitiatorQuery = { buyouts: Array<(
    Pick<Buyout, 'buyoutId' | 'buyoutPrice' | 'buyoutToken' | 'buyoutTokenManager' | 'closingTime' | 'isSuccessful' | 'openingTime'>
    & { erc1155: Pick<ERC1155, 'id' | 'contractAddress' | 'tokenId'>, initiator: Pick<User, 'id' | 'address'> }
  )> };

export type GetBuyoutDataFromFragmentsIdAndInitiatorQueryVariables = Exact<{
  erc1155Id: Scalars['String']['input'];
  userAddress: Scalars['String']['input'];
}>;


export type GetBuyoutDataFromFragmentsIdAndInitiatorQuery = { buyouts: Array<(
    Pick<Buyout, 'buyoutId' | 'buyoutPrice' | 'buyoutToken' | 'buyoutTokenManager' | 'closingTime' | 'isSuccessful' | 'openingTime'>
    & { erc1155: Pick<ERC1155, 'id' | 'contractAddress' | 'tokenId'>, initiator: Pick<User, 'id' | 'address'> }
  )> };

export type GetFractionsDataFromNftQueryVariables = Exact<{
  nftId: Scalars['ID']['input'];
}>;


export type GetFractionsDataFromNftQuery = { erc721?: Maybe<{ erc1155?: Maybe<(
      Pick<ERC1155, 'id' | 'contractAddress' | 'tokenId'>
      & { owners?: Maybe<Array<Pick<User, 'id' | 'erc1155Balance'>>> }
    )> }> };

export type GetFractionsOwnersQueryVariables = Exact<{
  nftId: Scalars['ID']['input'];
}>;


export type GetFractionsOwnersQuery = { erc1155?: Maybe<(
    Pick<ERC1155, 'id' | 'ownersBalances'>
    & { owners?: Maybe<Array<(
      Pick<User, 'id' | 'address' | 'erc1155Balance'>
      & { erc1155?: Maybe<Array<Pick<ERC1155, 'id'>>> }
    )>> }
  )> };

export type GetNftOwnerQueryVariables = Exact<{
  nftId: Scalars['ID']['input'];
}>;


export type GetNftOwnerQuery = { erc721?: Maybe<{ owner: Pick<User, 'id' | 'address'> }> };

export type GetNftValuationHistoryQueryVariables = Exact<{
  nftId: Scalars['ID']['input'];
}>;


export type GetNftValuationHistoryQuery = { nft?: Maybe<(
    Pick<Nft, 'id' | 'tokenId'>
    & { collection: Pick<Collection, 'id' | 'address' | 'oracle'>, valuationHistory?: Maybe<Array<Pick<Valuation, 'id' | 'price' | 'ltv' | 'timestamp'>>> }
  )> };

export type GetRaffleBoostsQueryVariables = Exact<{
  userAddress: Scalars['Bytes']['input'];
  userAddressId: Scalars['ID']['input'];
  raffleId: Scalars['BigInt']['input'];
  endEarlyBirdTimestamp: Scalars['BigInt']['input'];
  referralId: Scalars['ID']['input'];
}>;


export type GetRaffleBoostsQuery = { user?: Maybe<(
    Pick<User, 'address' | 'allowed'>
    & { erc721Owned?: Maybe<Array<Pick<ERC721, 'id'>>>, erc1155?: Maybe<Array<Pick<ERC1155, 'id'>>> }
  )>, staker?: Maybe<Pick<Staker, 'id' | 'stakeAmount' | 'lastBoostUpdate' | 'lastUpdate'>>, stakingParams: Array<Pick<StakingParam, 'minStakingBoostAmount' | 'rewardRatioNumerator' | 'stakingTokenCap' | 'stakingToken' | 'tiersDurations' | 'tiersMultipliers'>>, loans: Array<Pick<Loan, 'id'>>, referral?: Maybe<Pick<Referral, 'referrerOf' | 'referralAmount'>>, purchaseEvents: Array<Pick<PurchaseEvent, 'id' | 'raffleId' | 'buyer' | 'referrer' | 'ticketAmount' | 'price' | 'timestamp'>> };

export type GetRaffleBuyersQueryVariables = Exact<{
  raffleId: Scalars['BigInt']['input'];
}>;


export type GetRaffleBuyersQuery = { purchaseEvents: Array<Pick<PurchaseEvent, 'id' | 'raffleId' | 'buyer' | 'referrer' | 'ticketAmount' | 'price' | 'timestamp'>>, referrals: Array<Pick<Referral, 'id' | 'referralCode' | 'referrerOf' | 'referralAmount'>> };

export type GetAllRaffleBoostsQueryVariables = Exact<{
  buyerIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
  buyerAddresses?: InputMaybe<Array<Scalars['Bytes']['input']> | Scalars['Bytes']['input']>;
}>;


export type GetAllRaffleBoostsQuery = { users: Array<(
    Pick<User, 'address' | 'allowed'>
    & { erc721Owned?: Maybe<Array<Pick<ERC721, 'id'>>>, erc1155?: Maybe<Array<Pick<ERC1155, 'id'>>> }
  )>, stakers: Array<Pick<Staker, 'id' | 'stakeAmount' | 'lastBoostUpdate' | 'lastUpdate'>>, stakingParams: Array<Pick<StakingParam, 'minStakingBoostAmount' | 'rewardRatioNumerator' | 'stakingTokenCap' | 'stakingToken' | 'tiersDurations' | 'tiersMultipliers'>>, loans: Array<Pick<Loan, 'id'>> };

export type GetRaffleByIdQueryVariables = Exact<{
  raffleId: Scalars['ID']['input'];
}>;


export type GetRaffleByIdQuery = { raffle?: Maybe<Pick<Raffle, 'id' | 'raffleTicketPurchase' | 'raffleRewarder' | 'status' | 'purchaseToken' | 'ticketPrice' | 'startTimestamp' | 'finishTimestamp' | 'minCap' | 'maxCap' | 'personalMaxCap' | 'participantsProof' | 'winner'>> };

export type GetUserActivityQueryVariables = Exact<{
  userAddress: Scalars['String']['input'];
}>;


export type GetUserActivityQuery = { transactions: Array<(
    Pick<Transaction, 'id' | 'block' | 'erc1155Quantity' | 'timestamp'>
    & { erc721?: Maybe<Pick<ERC721, 'id'>>, erc1155?: Maybe<Array<Pick<ERC1155, 'id'>>>, from: Pick<User, 'id' | 'address'>, to: Pick<User, 'id' | 'address'> }
  )>, sales: Array<(
    Pick<Sale, 'saleId' | 'fractionPrice' | 'fractionsAmount' | 'fractionsSold' | 'openingTime' | 'closingTime'>
    & { erc1155?: Maybe<Pick<ERC1155, 'id'>>, erc721: Pick<ERC721, 'id'>, seller: Pick<Oracle, 'id' | 'address'> }
  )>, buyouts: Array<(
    Pick<Buyout, 'buyoutId' | 'openingTime' | 'closingTime' | 'isSuccessful' | 'buyoutPrice' | 'buyoutToken'>
    & { erc1155: Pick<ERC1155, 'id'> }
  )>, order0Xes: Array<(
    Pick<Order0x, 'id' | 'nonce' | 'cancelled' | 'filled' | 'timestamp' | 'erc20Token' | 'erc20FilledAmount' | 'direction'>
    & { maker: Pick<User, 'id' | 'address'>, taker?: Maybe<Pick<User, 'id' | 'address'>> }
  )> };


export const Get0xTransactionHistoryDocument = gql`
    query Get0xTransactionHistory($userAddress: String!, $orderDirection: OrderDirection! = desc) {
  transaction0Xes(
    where: {or: [{order_: {taker_contains_nocase: $userAddress}}, {order_: {maker_contains_nocase: $userAddress}}]}
    orderBy: timestamp
    orderDirection: $orderDirection
  ) {
    id
    transactionId
    order {
      maker {
        id
        address
      }
      taker {
        id
        address
      }
      direction
    }
    nftTokenType
    nftToken
    nftTokenId
    nftTokenAmount
    erc20Token
    erc20TokenAmount
    timestamp
  }
}
    ` as unknown as DocumentNode<Get0xTransactionHistoryQuery, Get0xTransactionHistoryQueryVariables>;
export const Get0xTransactionsByNftIdDocument = gql`
    query Get0xTransactionsByNftId($contractAddress: Bytes!, $tokenId: BigInt!, $orderDirection: OrderDirection! = desc) {
  transaction0Xes(
    where: {and: [{nftToken: $contractAddress}, {nftTokenId: $tokenId}]}
    orderBy: timestamp
    orderDirection: $orderDirection
  ) {
    id
    transactionId
    order {
      maker {
        id
        address
      }
      taker {
        id
        address
      }
      direction
    }
    nftTokenType
    nftToken
    nftTokenId
    nftTokenAmount
    erc20Token
    erc20TokenAmount
    timestamp
  }
}
    ` as unknown as DocumentNode<Get0xTransactionsByNftIdQuery, Get0xTransactionsByNftIdQueryVariables>;
export const GetAllTransactionHistoryDocument = gql`
    query GetAllTransactionHistory($userAddress: String, $orderDirection: OrderDirection! = desc) {
  transactions(
    where: {or: [{from_contains_nocase: $userAddress}, {to_contains_nocase: $userAddress}]}
    orderBy: timestamp
    orderDirection: $orderDirection
  ) {
    id
    block
    erc721 {
      id
    }
    erc1155 {
      id
    }
    erc1155Quantity
    from {
      id
      address
    }
    to {
      id
      address
    }
    timestamp
  }
  transaction0Xes(
    where: {or: [{order_: {taker_contains_nocase: $userAddress}}, {order_: {maker_contains_nocase: $userAddress}}]}
    orderBy: timestamp
    orderDirection: $orderDirection
  ) {
    id
    transactionId
    order {
      maker {
        id
        address
      }
      taker {
        id
        address
      }
      direction
    }
    fees {
      id
      tokenAddress
      amount
      receiver
      feeData
    }
    nftTokenType
    nftToken
    nftTokenId
    nftTokenAmount
    erc20Token
    erc20TokenAmount
    timestamp
  }
}
    ` as unknown as DocumentNode<GetAllTransactionHistoryQuery, GetAllTransactionHistoryQueryVariables>;
export const GetAllTransactionsByNftIdAndAddressDocument = gql`
    query GetAllTransactionsByNftIdAndAddress($userAddress: String!, $contractAddress: Bytes!, $tokenId: BigInt!, $orderDirection: OrderDirection! = desc) {
  transactions(
    where: {and: [{or: [{from_contains_nocase: $userAddress}, {to_contains_nocase: $userAddress}]}, {or: [{and: [{erc721_: {contractAddress: $contractAddress}}, {erc721_: {tokenId: $tokenId}}]}, {and: [{erc1155_: {contractAddress: $contractAddress}}, {erc1155_: {tokenId: $tokenId}}]}]}]}
    orderBy: timestamp
    orderDirection: $orderDirection
  ) {
    id
    block
    erc721 {
      id
    }
    erc1155 {
      id
    }
    erc1155Quantity
    from {
      id
      address
    }
    to {
      id
      address
    }
    timestamp
  }
  transaction0Xes(
    where: {and: [{or: [{order_: {taker_contains_nocase: $userAddress}}, {order_: {maker_contains_nocase: $userAddress}}]}, {and: [{order_: {nftToken: $contractAddress}}, {order_: {nftTokenId: $tokenId}}]}]}
    orderBy: timestamp
    orderDirection: $orderDirection
  ) {
    id
    transactionId
    order {
      maker {
        id
        address
      }
      taker {
        id
        address
      }
      direction
    }
    fees {
      id
      tokenAddress
      amount
      receiver
      feeData
    }
    nftTokenType
    nftToken
    nftTokenId
    nftTokenAmount
    erc20Token
    erc20TokenAmount
    timestamp
  }
}
    ` as unknown as DocumentNode<GetAllTransactionsByNftIdAndAddressQuery, GetAllTransactionsByNftIdAndAddressQueryVariables>;
export const GetBuyoutDataFromFragmentsIdDocument = gql`
    query GetBuyoutDataFromFragmentsId($erc1155Id: String!) {
  buyouts(where: {erc1155: $erc1155Id}) {
    erc1155 {
      id
      contractAddress
      tokenId
    }
    buyoutId
    buyoutPrice
    buyoutToken
    buyoutTokenManager
    closingTime
    initiator {
      id
      address
    }
    isSuccessful
    openingTime
  }
}
    ` as unknown as DocumentNode<GetBuyoutDataFromFragmentsIdQuery, GetBuyoutDataFromFragmentsIdQueryVariables>;
export const GetBuyoutDataFromInitiatorDocument = gql`
    query GetBuyoutDataFromInitiator($userAddress: String!) {
  buyouts(where: {initiator: $userAddress}) {
    erc1155 {
      id
      contractAddress
      tokenId
    }
    buyoutId
    buyoutPrice
    buyoutToken
    buyoutTokenManager
    closingTime
    initiator {
      id
      address
    }
    isSuccessful
    openingTime
  }
}
    ` as unknown as DocumentNode<GetBuyoutDataFromInitiatorQuery, GetBuyoutDataFromInitiatorQueryVariables>;
export const GetBuyoutDataFromFragmentsIdAndInitiatorDocument = gql`
    query GetBuyoutDataFromFragmentsIdAndInitiator($erc1155Id: String!, $userAddress: String!) {
  buyouts(
    where: {and: [{erc1155: $erc1155Id}, {initiator_contains_nocase: $userAddress}]}
    orderBy: buyoutId
    orderDirection: desc
  ) {
    erc1155 {
      id
      contractAddress
      tokenId
    }
    buyoutId
    buyoutPrice
    buyoutToken
    buyoutTokenManager
    closingTime
    initiator {
      id
      address
    }
    isSuccessful
    openingTime
  }
}
    ` as unknown as DocumentNode<GetBuyoutDataFromFragmentsIdAndInitiatorQuery, GetBuyoutDataFromFragmentsIdAndInitiatorQueryVariables>;
export const GetFractionsDataFromNftDocument = gql`
    query GetFractionsDataFromNft($nftId: ID!) {
  erc721(id: $nftId) {
    erc1155 {
      id
      contractAddress
      tokenId
      owners {
        id
        erc1155Balance
      }
    }
  }
}
    ` as unknown as DocumentNode<GetFractionsDataFromNftQuery, GetFractionsDataFromNftQueryVariables>;
export const GetFractionsOwnersDocument = gql`
    query GetFractionsOwners($nftId: ID!) {
  erc1155(id: $nftId) {
    id
    owners {
      id
      address
      erc1155 {
        id
      }
      erc1155Balance
    }
    ownersBalances
  }
}
    ` as unknown as DocumentNode<GetFractionsOwnersQuery, GetFractionsOwnersQueryVariables>;
export const GetNftOwnerDocument = gql`
    query GetNftOwner($nftId: ID!) {
  erc721(id: $nftId) {
    owner {
      id
      address
    }
  }
}
    ` as unknown as DocumentNode<GetNftOwnerQuery, GetNftOwnerQueryVariables>;
export const GetNftValuationHistoryDocument = gql`
    query GetNftValuationHistory($nftId: ID!) {
  nft(id: $nftId) {
    id
    collection {
      id
      address
      oracle
    }
    tokenId
    valuationHistory {
      id
      price
      ltv
      timestamp
    }
  }
}
    ` as unknown as DocumentNode<GetNftValuationHistoryQuery, GetNftValuationHistoryQueryVariables>;
export const GetRaffleBoostsDocument = gql`
    query GetRaffleBoosts($userAddress: Bytes!, $userAddressId: ID!, $raffleId: BigInt!, $endEarlyBirdTimestamp: BigInt!, $referralId: ID!) {
  user(id: $userAddressId) {
    address
    allowed
    erc721Owned {
      id
    }
    erc1155 {
      id
    }
  }
  staker(id: $userAddressId) {
    id
    stakeAmount
    lastBoostUpdate
    lastUpdate
  }
  stakingParams {
    minStakingBoostAmount
    rewardRatioNumerator
    stakingTokenCap
    stakingToken
    tiersDurations
    tiersMultipliers
  }
  loans(
    where: {or: [{borrower: $userAddress}, {lender: $userAddress}, {liquidator: $userAddress}]}
  ) {
    id
  }
  referral(id: $referralId) {
    referrerOf
    referralAmount
  }
  purchaseEvents(
    where: {buyer: $userAddress, raffleId: $raffleId, timestamp_lt: $endEarlyBirdTimestamp}
  ) {
    id
    raffleId
    buyer
    referrer
    ticketAmount
    price
    timestamp
  }
}
    ` as unknown as DocumentNode<GetRaffleBoostsQuery, GetRaffleBoostsQueryVariables>;
export const GetRaffleBuyersDocument = gql`
    query GetRaffleBuyers($raffleId: BigInt!) {
  purchaseEvents(where: {raffleId: $raffleId}) {
    id
    raffleId
    buyer
    referrer
    ticketAmount
    price
    timestamp
  }
  referrals(where: {raffleId: $raffleId}) {
    id
    referralCode
    referrerOf
    referralAmount
  }
}
    ` as unknown as DocumentNode<GetRaffleBuyersQuery, GetRaffleBuyersQueryVariables>;
export const GetAllRaffleBoostsDocument = gql`
    query GetAllRaffleBoosts($buyerIds: [ID!], $buyerAddresses: [Bytes!]) {
  users(where: {address_in: $buyerAddresses}) {
    address
    allowed
    erc721Owned {
      id
    }
    erc1155 {
      id
    }
  }
  stakers(where: {id_in: $buyerIds}) {
    id
    stakeAmount
    lastBoostUpdate
    lastUpdate
  }
  stakingParams {
    minStakingBoostAmount
    rewardRatioNumerator
    stakingTokenCap
    stakingToken
    tiersDurations
    tiersMultipliers
  }
  loans(where: {borrower_in: $buyerAddresses}) {
    id
  }
}
    ` as unknown as DocumentNode<GetAllRaffleBoostsQuery, GetAllRaffleBoostsQueryVariables>;
export const GetRaffleByIdDocument = gql`
    query GetRaffleById($raffleId: ID!) {
  raffle(id: $raffleId) {
    id
    raffleTicketPurchase
    raffleRewarder
    status
    purchaseToken
    ticketPrice
    startTimestamp
    finishTimestamp
    minCap
    maxCap
    personalMaxCap
    participantsProof
    winner
  }
}
    ` as unknown as DocumentNode<GetRaffleByIdQuery, GetRaffleByIdQueryVariables>;
export const GetUserActivityDocument = gql`
    query GetUserActivity($userAddress: String!) {
  transactions(
    where: {or: [{from_contains_nocase: $userAddress}, {to_contains_nocase: $userAddress}]}
    orderBy: timestamp
    orderDirection: desc
  ) {
    id
    block
    erc721 {
      id
    }
    erc1155 {
      id
    }
    erc1155Quantity
    from {
      id
      address
    }
    to {
      id
      address
    }
    timestamp
  }
  sales(
    where: {buyer_contains_nocase: [$userAddress]}
    orderBy: openingTime
    orderDirection: desc
  ) {
    saleId
    erc1155 {
      id
    }
    erc721 {
      id
    }
    fractionPrice
    fractionsAmount
    fractionsSold
    openingTime
    closingTime
    seller {
      id
      address
    }
  }
  buyouts(
    where: {initiator_contains_nocase: $userAddress}
    orderBy: openingTime
    orderDirection: desc
  ) {
    buyoutId
    erc1155 {
      id
    }
    openingTime
    closingTime
    isSuccessful
    buyoutPrice
    buyoutToken
  }
  order0Xes(
    where: {or: [{taker_contains_nocase: $userAddress}, {maker_contains_nocase: $userAddress}]}
    orderBy: timestamp
    orderDirection: desc
  ) {
    id
    nonce
    maker {
      id
      address
    }
    taker {
      id
      address
    }
    cancelled
    filled
    timestamp
    erc20Token
    erc20FilledAmount
    direction
  }
}
    ` as unknown as DocumentNode<GetUserActivityQuery, GetUserActivityQueryVariables>;

















export type Requester<C = {}, E = unknown> = <R, V>(doc: DocumentNode, vars?: V, options?: C) => Promise<R> | AsyncIterable<R>
export function getSdk<C, E>(requester: Requester<C, E>) {
  return {
    Get0xTransactionHistory(variables: Get0xTransactionHistoryQueryVariables, options?: C): Promise<Get0xTransactionHistoryQuery> {
      return requester<Get0xTransactionHistoryQuery, Get0xTransactionHistoryQueryVariables>(Get0xTransactionHistoryDocument, variables, options) as Promise<Get0xTransactionHistoryQuery>;
    },
    Get0xTransactionsByNftId(variables: Get0xTransactionsByNftIdQueryVariables, options?: C): Promise<Get0xTransactionsByNftIdQuery> {
      return requester<Get0xTransactionsByNftIdQuery, Get0xTransactionsByNftIdQueryVariables>(Get0xTransactionsByNftIdDocument, variables, options) as Promise<Get0xTransactionsByNftIdQuery>;
    },
    GetAllTransactionHistory(variables?: GetAllTransactionHistoryQueryVariables, options?: C): Promise<GetAllTransactionHistoryQuery> {
      return requester<GetAllTransactionHistoryQuery, GetAllTransactionHistoryQueryVariables>(GetAllTransactionHistoryDocument, variables, options) as Promise<GetAllTransactionHistoryQuery>;
    },
    GetAllTransactionsByNftIdAndAddress(variables: GetAllTransactionsByNftIdAndAddressQueryVariables, options?: C): Promise<GetAllTransactionsByNftIdAndAddressQuery> {
      return requester<GetAllTransactionsByNftIdAndAddressQuery, GetAllTransactionsByNftIdAndAddressQueryVariables>(GetAllTransactionsByNftIdAndAddressDocument, variables, options) as Promise<GetAllTransactionsByNftIdAndAddressQuery>;
    },
    GetBuyoutDataFromFragmentsId(variables: GetBuyoutDataFromFragmentsIdQueryVariables, options?: C): Promise<GetBuyoutDataFromFragmentsIdQuery> {
      return requester<GetBuyoutDataFromFragmentsIdQuery, GetBuyoutDataFromFragmentsIdQueryVariables>(GetBuyoutDataFromFragmentsIdDocument, variables, options) as Promise<GetBuyoutDataFromFragmentsIdQuery>;
    },
    GetBuyoutDataFromInitiator(variables: GetBuyoutDataFromInitiatorQueryVariables, options?: C): Promise<GetBuyoutDataFromInitiatorQuery> {
      return requester<GetBuyoutDataFromInitiatorQuery, GetBuyoutDataFromInitiatorQueryVariables>(GetBuyoutDataFromInitiatorDocument, variables, options) as Promise<GetBuyoutDataFromInitiatorQuery>;
    },
    GetBuyoutDataFromFragmentsIdAndInitiator(variables: GetBuyoutDataFromFragmentsIdAndInitiatorQueryVariables, options?: C): Promise<GetBuyoutDataFromFragmentsIdAndInitiatorQuery> {
      return requester<GetBuyoutDataFromFragmentsIdAndInitiatorQuery, GetBuyoutDataFromFragmentsIdAndInitiatorQueryVariables>(GetBuyoutDataFromFragmentsIdAndInitiatorDocument, variables, options) as Promise<GetBuyoutDataFromFragmentsIdAndInitiatorQuery>;
    },
    GetFractionsDataFromNft(variables: GetFractionsDataFromNftQueryVariables, options?: C): Promise<GetFractionsDataFromNftQuery> {
      return requester<GetFractionsDataFromNftQuery, GetFractionsDataFromNftQueryVariables>(GetFractionsDataFromNftDocument, variables, options) as Promise<GetFractionsDataFromNftQuery>;
    },
    GetFractionsOwners(variables: GetFractionsOwnersQueryVariables, options?: C): Promise<GetFractionsOwnersQuery> {
      return requester<GetFractionsOwnersQuery, GetFractionsOwnersQueryVariables>(GetFractionsOwnersDocument, variables, options) as Promise<GetFractionsOwnersQuery>;
    },
    GetNftOwner(variables: GetNftOwnerQueryVariables, options?: C): Promise<GetNftOwnerQuery> {
      return requester<GetNftOwnerQuery, GetNftOwnerQueryVariables>(GetNftOwnerDocument, variables, options) as Promise<GetNftOwnerQuery>;
    },
    GetNftValuationHistory(variables: GetNftValuationHistoryQueryVariables, options?: C): Promise<GetNftValuationHistoryQuery> {
      return requester<GetNftValuationHistoryQuery, GetNftValuationHistoryQueryVariables>(GetNftValuationHistoryDocument, variables, options) as Promise<GetNftValuationHistoryQuery>;
    },
    GetRaffleBoosts(variables: GetRaffleBoostsQueryVariables, options?: C): Promise<GetRaffleBoostsQuery> {
      return requester<GetRaffleBoostsQuery, GetRaffleBoostsQueryVariables>(GetRaffleBoostsDocument, variables, options) as Promise<GetRaffleBoostsQuery>;
    },
    GetRaffleBuyers(variables: GetRaffleBuyersQueryVariables, options?: C): Promise<GetRaffleBuyersQuery> {
      return requester<GetRaffleBuyersQuery, GetRaffleBuyersQueryVariables>(GetRaffleBuyersDocument, variables, options) as Promise<GetRaffleBuyersQuery>;
    },
    GetAllRaffleBoosts(variables?: GetAllRaffleBoostsQueryVariables, options?: C): Promise<GetAllRaffleBoostsQuery> {
      return requester<GetAllRaffleBoostsQuery, GetAllRaffleBoostsQueryVariables>(GetAllRaffleBoostsDocument, variables, options) as Promise<GetAllRaffleBoostsQuery>;
    },
    GetRaffleById(variables: GetRaffleByIdQueryVariables, options?: C): Promise<GetRaffleByIdQuery> {
      return requester<GetRaffleByIdQuery, GetRaffleByIdQueryVariables>(GetRaffleByIdDocument, variables, options) as Promise<GetRaffleByIdQuery>;
    },
    GetUserActivity(variables: GetUserActivityQueryVariables, options?: C): Promise<GetUserActivityQuery> {
      return requester<GetUserActivityQuery, GetUserActivityQueryVariables>(GetUserActivityDocument, variables, options) as Promise<GetUserActivityQuery>;
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;