import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { NFTActionMakeOfferFragments, NFTActionMakeOfferWhole, NFTActionRefund, NFTActionWaitlist } from "~components";
import { useApp } from "~hooks";
import { TARGET_CHAIN } from "~utils/helpers";
import SwitchChainButton from "~components/Common/SwitchChainButton";
import NFTActionCreateWholeSellOrder from "./NFTActionCreateWholeSellOrder";
import { blockchainHooks } from "~hooks/blockchainHooks";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  position: relative;

  .nftActionButtons {
    &Widget {
      position: relative;
      overflow: hidden;
      border-radius: 1rem;
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTActionButtons = ({ enrichedProduct, nftStatus, colorTheme }) => {
  const [update, forceUpdate] = useState(false);
  // ---------------------------------------------------------------------------
  // imports / hooks
  const { useGetNftOwnership } = blockchainHooks();
  const { userIsLoggedIn, userData } = useApp();
  const { chain } = useAccount();

  const isOwner = useGetNftOwnership(enrichedProduct?.nftData);

  useEffect(() => {
    forceUpdate(!update);
  }, [chain?.id, userData?.address]);
  // ---------------------------------------------------------------------------
  // methods

  const getAvailableAction = () => {
    if (nftStatus?.is([`NFTBurned`])) {
      return null;
    }

    if (userIsLoggedIn && chain?.id !== TARGET_CHAIN?.id) {
      return <SwitchChainButton fluid variant="feature" colorTheme={colorTheme === `dark` ? `light` : `dark`} onSuccess={() => forceUpdate(!update)} />;
    }

    if (nftStatus?.is([`CollectUserInterest`])) {
      return <NFTActionWaitlist colorTheme={colorTheme} enrichedProduct={enrichedProduct} />;
    }

    if (nftStatus?.is(`OnFractionsSaleFailed`)) {
      // @externalintegration: and user owns this NFT?
      return <NFTActionRefund colorTheme={colorTheme} />;
    }

    if (nftStatus?.is([`SoldOut`])) {
      if (isOwner) {
        return <NFTActionCreateWholeSellOrder colorTheme={colorTheme} />;
      }
      return <NFTActionMakeOfferWhole colorTheme={colorTheme} />;
    }

    if (nftStatus?.is([`OnFractionsSaleSuccess`])) {
      return <NFTActionMakeOfferFragments colorTheme={colorTheme} />;
    }

    return null;
  };

  // ---------------------------------------------------------------------------
  // render

  return <Container>{getAvailableAction()}</Container>;
};

export default NFTActionButtons;
