import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { blockchainHooks } from "~hooks/blockchainHooks";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { genericErrorCallback, walletAddressAreEqual } from "~utils/helpers";

const MarketplaceItemContext = createContext(null);

export const useMarketplaceItemContext = () => {
  const context = useContext(MarketplaceItemContext);

  if (!context) {
    throw new Error(`Use useMarketplaceItemContext within MarketplaceItemContext`);
  }

  return context;
};

const MarketplaceItemProvider = ({ children, nftData }) => {
  const [selectedOffer, setSelectedOffer] = useState(0);
  const [offers, setOffers] = useState([]);
  const [refetch, doRefetch] = useState(false);

  const { api } = useExternalIntegrations();
  const { useGetNftOwnerOf } = blockchainHooks();

  const { data: owner } = useGetNftOwnerOf(nftData);

  useEffect(() => {
    const getOffers = async () => {
      const productOffers = await api.getOrdersByCollectionAddressAndTokenId(nftData.nftCollectionInfo.collectionAddress, nftData.tokenId);

      setOffers(
        productOffers
          .filter((order) => !walletAddressAreEqual(order.order.maker, owner) && order.order.direction === 1)
          .sort((a, b) => Number(b.order.erc20TokenAmount) - Number(a.order.erc20TokenAmount))
      );
    };

    getOffers().catch(genericErrorCallback);
  }, [nftData.nftCollectionInfo.collectionAddress, nftData.tokenId, refetch, owner]);

  const handleSetOffer = useCallback((id) => {
    setSelectedOffer(id);
  }, []);

  const contextValue = useMemo(
    () => ({
      offers,
      selectedOffer: { ...offers[selectedOffer], id: selectedOffer },
      setSelectedOffer: handleSetOffer,
      refetch: () => doRefetch((prev) => !prev)
    }),
    [offers.length, selectedOffer, handleSetOffer]
  );

  return <MarketplaceItemContext.Provider value={contextValue}>{children}</MarketplaceItemContext.Provider>;
};

export default MarketplaceItemProvider;
