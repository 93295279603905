import React from "react";
import styled from "@emotion/styled";
import { SlicePadding } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  text-align: center;
  background: var(--color-beige);
  border-top: 1px solid var(--color-mono-20);
  position: relative;

  .featuredTextBlock {
    &Content {
      max-width: 870px;
      margin: auto;
      padding: 6rem 0;
    }

    &Heading {
      margin-bottom: 0.75rem;
    }
  }
`;

/** ============================================================================
 * @component
 */
const FeaturedTextBlock = ({ data: { text, heading } }) => (
  <Container>
    <SlicePadding>
      <div className="featuredTextBlockContent">
        <h4 className="tag featuredTextBlockHeading">{heading}</h4>
        <p className="h2">{text}</p>
      </div>
    </SlicePadding>
  </Container>
);

export default FeaturedTextBlock;
