import React, { useState } from "react";
import { useApp } from "~hooks";
import { Button, NFTOverlay, NFTTextEntry } from "~components";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayClaimWhole = ({ nft }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const { setOverlayCompletionData, setOverlayProcessingData } = useApp();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const initialFormData = {
    email: null
  };

  const [executing, setExecuting] = useState(false);
  const [formData, setFormData] = useState(initialFormData);

  // ---------------------------------------------------------------------------
  // methods

  const onChange = (e) => {
    const { name, type, value, checked } = e.target;

    if (type === `checkbox`) {
      setFormData({
        ...formData,
        [name]: checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const confirmEmail = async () => {
    if (!nft?.enrichedProduct?.product || executing) {
      return () => {};
    }

    setExecuting(true);

    setOverlayProcessingData({
      icon: `load`,
      text: `Processing. Please do not close this page.`
    });

    // @externalintegration
    // const { api } = useExternalIntegrations();
    // api.claimNft(nftOrder, email)

    // mock
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        setOverlayProcessingData(null);

        setOverlayCompletionData({
          icon: `mail`,
          heading: `Email sent`,
          body: `Check your email to confirm.`
        });

        setFormData(initialFormData);

        setExecuting(false);

        resolve();
      }, 3000);
    });
  };

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  const valid = formData?.email;

  return (
    <NFTOverlay id="NFTOverlayClaimWhole" heading="Claim Request - Confirm Email" nft={nft} sidebarMode="claimWhole">
      <NFTTextEntry
        className="nftOverlayGroup"
        name="email"
        onChange={onChange}
        value={formData?.walletAddress}
        heading="Enter your email:"
        placeholder="Your email"
      />

      <div className="nftEntry controls">
        {!valid && <p className="notice">Agree to the NFT terms and conditions to proceed.</p>}
        <p className="notice">To proceed with the claim request we will need to validate your email address.</p>

        <Button onClick={confirmEmail} fluid variant="primaryTall" colorTheme="dark" disabled={!valid}>
          <span className="b1">Confirm Email</span>
        </Button>
      </div>
    </NFTOverlay>
  );
};

export default NFTOverlayClaimWhole;
