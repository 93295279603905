import React, { useEffect, useRef, useState } from "react";
import TextScramble from "../TextScramble";

/** ============================================================================
 * @component
 * todo: document
 */
const CryptoScramblerManual = ({ className, phrase }) => {
  const contentRef = useRef();
  const [fx, setFX] = useState(null);

  useEffect(() => {
    if (!contentRef?.current || fx) {
      return;
    }

    setFX(new TextScramble(contentRef.current));
  }, [contentRef]);

  useEffect(() => {
    if (!fx) {
      return;
    }

    fx.setText(phrase);
  }, [fx, phrase]);

  return <span ref={contentRef} className={className} />;
};

export default CryptoScramblerManual;
