import cn from "classnames";

const CheckboxButton = ({ label, name, defaultChecked, disabled, onChange, checked }) => (
  <label className={cn(`checkboxButton`, disabled && `disabled`)}>
    <input className="visually-hidden" type="checkbox" name={name} defaultChecked={defaultChecked} disabled={disabled} onChange={onChange} checked={checked} />
    <span>{label}</span>
  </label>
);

export default CheckboxButton;
