import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Image } from "~components";
import { breakpoint } from "~utils/css";
import loader from "~assets/images/loader.webp";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  position: relative;
  padding: 1.25rem 1rem;
  background: var(--color-white);

  ${breakpoint(`large-tablet`)} {
    padding: 0 1.5rem;
  }

  .nftPurchaseLoading {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    ${breakpoint(`large-tablet`)} {
      width: 100%;
      min-height: 115px;
    }
  }
`;

/** ============================================================================
 * @component
 * todo: animations
 * @return {node}
 */
const NFTPurchaseLoading = () => {
  let i;

  return (
    <Container>
      <div className="nftPurchaseLoading">
        <h3 className="b1">Loading...</h3>

        <Image
          css={css`
            width: 3rem;
          `}
          image={loader}
        />
      </div>
    </Container>
  );
};

export default NFTPurchaseLoading;
