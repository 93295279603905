import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useSignMessage } from "wagmi";
import { Button, LoadingSpinner, SVG } from "~components";
import { breakpoint } from "~utils/css";

const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  box-sizing: border-box;
`;

const ModalContent = styled.div`
  padding: 20px;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow: hidden;
  min-width: 320px;

  ${breakpoint(`tablet`)} {
    width: 90%;
    max-width: 600px;
  }
`;

const CloseButton = styled.span`
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: var(--color-white);
  margin-bottom: 10px;
`;

const ResponsiveIframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  opacity: ${(props) => (props.isLoading ? 0 : 1)};
  transition: opacity 0.3s ease-in-out;
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const VerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: var(--color-white);
`;

const VerificationTitle = styled.h3`
  color: white;
  margin-bottom: 10px;
`;

const VerificationText = styled.p`
  color: white;
  margin-bottom: 20px;
`;

const InfoIcon = styled.span`
  width: 48px;
  height: 48px;
  margin-bottom: 20px;
`;

const MtPelerinModal = ({ closeModal, currency, userAddress }) => {
  const apiKey = process.env.GATSBY_MTPELERIN_API_KEY;
  const modalRef = useRef(null);
  const iframeRef = useRef(null);
  const [isIframeLoading, setIsIframeLoading] = useState(true);
  const [verificationCode, setVerificationCode] = useState(``);
  const [iframeSrc, setIframeSrc] = useState(``);

  const { signMessageAsync, isLoading: isSigningMessage, isSuccess: isSigningSuccess, isError: isSigningError, error: signingError } = useSignMessage();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    };

    document.addEventListener(`mousedown`, handleClickOutside);
    return () => document.removeEventListener(`mousedown`, handleClickOutside);
  }, [closeModal]);

  useEffect(() => {
    const adjustIframeHeight = () => {
      if (iframeRef.current && modalRef.current) {
        const modalHeight = modalRef.current.clientHeight;
        const closeButtonHeight = 40;
        iframeRef.current.style.height = `${modalHeight - closeButtonHeight}px`;
      }
    };

    adjustIframeHeight();
    window.addEventListener(`resize`, adjustIframeHeight);
    return () => window.removeEventListener(`resize`, adjustIframeHeight);
  }, []);

  useEffect(() => {
    setVerificationCode(Math.floor(1000 + Math.random() * 9000).toString());
  }, []);

  const handleVerification = async () => {
    try {
      const message = `MtPelerin-${verificationCode}`;
      const signature = await signMessageAsync({ message });

      const toBase64 = (u8) => btoa(String.fromCharCode.apply(null, u8));
      const fromHexString = (hexString) => Uint8Array.from(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
      const encodedSignature = encodeURIComponent(toBase64(fromHexString(signature.replace(`0x`, ``))));

      const src = `https://widget.mtpelerin.com/?_ctkn=${apiKey}&mode=dark&tab=buy&nets=matic_mainnet&bdc=${currency}&crys=${currency}&ssc=${currency}&bsc=USD&sdc=USD&ssa=100&ctry=US&addr=${userAddress}&code=${verificationCode}&hash=${encodedSignature}`;

      setIframeSrc(src);
    } catch (error) {
      console.error(`Error signing message:`, error);
    }
  };

  if (!isSigningSuccess) {
    return (
      <Modal onClick={closeModal}>
        <ModalContent ref={modalRef} onClick={(e) => e.stopPropagation()}>
          <CloseButton onClick={closeModal}>&times;</CloseButton>
          <VerificationContainer>
            <InfoIcon>
              <SVG svg="info" />
            </InfoIcon>
            <VerificationTitle className="h4">Address Verification Required</VerificationTitle>
            <>
              <VerificationText>
                To ensure security and compliance, the onramp provider needs to verify that you control this wallet address.
                <br />
                <br />
                This involves signing a message with your wallet&apos;s private key. This signature proves ownership without revealing your private key.
              </VerificationText>
              <VerificationText>
                By verifying your address, you&apos;ll be able to securely buy crypto and have it sent directly to your wallet.
              </VerificationText>
            </>

            {isSigningMessage ? (
              <LoadingSpinner />
            ) : (
              <>
                <Button onClick={handleVerification} disabled={isSigningMessage}>
                  Verify address
                </Button>
                {isSigningError && (
                  <VerificationText style={{ color: `red` }}>Error: {signingError?.message || `Failed to sign message. Please try again.`}</VerificationText>
                )}
              </>
            )}
          </VerificationContainer>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal onClick={closeModal}>
      <ModalContent ref={modalRef} onClick={(e) => e.stopPropagation()}>
        <CloseButton onClick={closeModal}>&times;</CloseButton>
        {isIframeLoading && (
          <LoaderContainer>
            <LoadingSpinner />
          </LoaderContainer>
        )}
        <IframeContainer>
          <ResponsiveIframe
            ref={iframeRef}
            title="Mt Pelerin exchange widget"
            src={iframeSrc}
            allow="usb; ethereum; clipboard-write; payment; microphone; camera"
            loading="lazy"
            onLoad={() => setIsIframeLoading(false)}
            isLoading={isIframeLoading}
          />
        </IframeContainer>
      </ModalContent>
    </Modal>
  );
};

export default MtPelerinModal;
