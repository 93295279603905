import React, { useCallback, useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useApp, useMarketing } from "~hooks";
import { Button, Grid, SlicePadding, SVG } from "~components";
import { breakpoint } from "~utils/css";
import { genericErrorCallback, isBrowser } from "~utils/helpers";
import { LOCALSTORAGE_KEY } from "~constants";
import altrLoginAPI from "~hooks/altrLoginApi";

/** ============================================================================
 * @css
 */

const DESKTOP_HEIGHT = `300px`;
const MOBILE_HEIGHT = `420px`;

const Container = styled.article`
  transition: 0.5s var(--cubic-easing) transform;

  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};
  transform: translate3d(0, ${({ active }) => `${active ? `0` : `100%`}`}, 0);

  width: 100%;
  height: ${MOBILE_HEIGHT};
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  color: white;

  ${breakpoint(`large-tablet`)} {
    height: ${DESKTOP_HEIGHT};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
`;

//

const BackgroundWrapper = styled.div`
  grid-column: 1 / -1;
  height: 0;
  position: relative;
`;

const Background = styled.div`
  width: 100%;
  height: ${MOBILE_HEIGHT};
  position: absolute;
  top: -40px;
  right: 0;
  left: 0;
  pointer-events: none;
  border-radius: 1.5rem 1.5rem 0 0;
  background: var(--color-mono-40);

  ${breakpoint(`large-tablet`)} {
    height: ${DESKTOP_HEIGHT};
    top: -60px;
  }
`;

const OverlayClose = styled.button`
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: -1.5rem;
  right: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  pointer-events: auto;

  ${breakpoint(`large-tablet`)} {
    top: -2.75rem;
    right: 1.25rem;
  }
`;

//

const Aside = styled.aside`
  grid-column: 1 / -1;
  position: relative;
  z-index: 10;
  padding: 2rem 0.75rem 0.75rem;

  p {
    margin-top: 1rem;
  }

  ${breakpoint(`large-tablet`)} {
    grid-column: 2 / span 4;
    padding: 0;
  }
`;

const Form = styled.form`
  grid-column: 1 / -1;
  position: relative;
  z-index: 10;
  padding: 0.75rem;

  ${breakpoint(`large-tablet`)} {
    grid-column: 7 / span 4;
    padding: 0;
  }

  .output {
    display: block;
  }

  .maxCommitmentButton {
    padding: 14px 20px;
  }

  .raffleOverlayWaitlistForm {
    &Commitment,
    &Email {
      span {
        display: block;
        margin-bottom: 0.5rem;
      }

      input[type="number"],
      input[type="email"] {
        width: 100%;
        height: 48px;
        border: none;
        padding: 0 1rem;
        background: white;
        border-radius: 0.5rem;
      }

      input[type="number"] {
        margin-bottom: 10px;
        width: 70%;
      }

      &Input {
        display: flex;
        justify-content: space-between;
      }
    }

    &CryptoKnowledge {
      span {
        display: block;
        margin-bottom: 0.5rem;
      }

      input[type="range"] {
        width: 100%;
        color: black;
        border-radius: 5px;
        -webkit-appearance: none;
        height: 10px;
        background: white;
        margin-bottom: 10px;
      }

      input[type="range"]:focus {
        outline: none;
      }

      input[type="range"]:hover {
        cursor: pointer;
      }

      input[type="range"]::-webkit-slider-runnable-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        animate: 0.2s;
        background: transparent;
        border-radius: 5px;
        border: 1px solid #ffffff;
      }

      input[type="range"]::-webkit-slider-thumb {
        border: none;
        height: 20px;
        width: 8px;
        background: var(--color-mono-40);
        border: 1px solid #fff;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -6px;
      }

      input[type="range"]::-webkit-range-progress {
        background-color: red;
        height: 1em;
        color: red;
      }
    }

    &Checkbox {
      margin: 1.5rem 0;
      display: flex;
      align-items: center;

      span {
        margin-left: 0.5rem;
        display: block;
      }
    }

    &Honeypot {
      display: none;
    }

    &Error {
      margin-top: 1rem;
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const RaffleOverlayWaitlist = ({ guid }) => {
  const cryptoKnowledgeDict = {
    0: `Complete Novice`,
    25: `Basic Knowledge`,
    50: `Good Understanding`,
    75: `Very Confident`,
    100: `Crypto master`
  };
  const { activeOverlay, setActiveOverlay, userData, emailTrigger, setEmailTrigger, setReloadProfileData, reloadProfileData, userIsLoggedIn } = useApp();
  const { subscribeToAProductDropPage } = useMarketing();
  const { updateUserProfile, refreshProfileData } = altrLoginAPI();

  const [formData, setFormData] = useState({ newsletter: false });
  const [status, setStatus] = useState({
    submitting: false,
    submitted: false,
    error: false
  });
  const [cryptoKnowledgeProgress, setCryptoKnowledgeProgress] = useState(0);
  const [userInterest, setUserInterest] = useState();

  const cryptoKnowledgeValue = useRef(null);
  const cryptoKnowledgeOutput = useRef(null);
  const cryptoKnowledgeInput = useRef(null);
  const commitmentInput = useRef(null);
  const emailInput = useRef(null);
  const checkbox = useRef(null);
  // ---------------------------------------------------------------------------
  // methods

  const onChange = (e) => {
    let { name, type, value, checked, min, max } = e.target;

    if (e.target.type === `number`) {
      if (max && parseInt(value) > parseInt(max)) {
        e.target.value = max;
        value = max;
      }
      if (min && parseInt(value) < parseInt(min) && value != ``) {
        e.target.value = min;
        value = min;
      }
    }

    if (type === `checkbox`) {
      setFormData({
        ...formData,
        [name]: checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (status?.submitting || status?.submitted || formData.honeypot) {
      return;
    }

    setStatus({
      submitting: true,
      submitted: false
    });

    // @externalintegration
    // requires validation/testing
    try {
      const data = [
        formData?.email || userInterest?.email || userData?.user?.emailAddress,
        userData?.user?.nickname || ``,
        formData?.commitment,
        cryptoKnowledgeOutput.current?.value,
        guid,
        formData?.newsletter
      ];
      const response = await subscribeToAProductDropPage(...data);
      if (userData?.user !== null) {
        await updateUserProfile(data[0], data[1] || null);
        await refreshProfileData();
      }

      if (!response?.ok) {
        throw new Error(`Error in subscribeToAProductDropPage. ${response?.statusText} ${data}`);
      }

      if (isBrowser()) {
        localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify({ ...userInterest, [guid]: data, email: data[0] }));
      }

      setEmailTrigger(!emailTrigger);

      setStatus({
        submitting: false,
        submitted: true
      });

      setTimeout(() => {
        setReloadProfileData(!reloadProfileData);
        setActiveOverlay(null);
      }, 1000);
    } catch (error) {
      setStatus({
        submitted: false,
        submitting: false,
        error: true
      });
      genericErrorCallback(error);
    }
  };

  const getSubmitText = () => {
    if (status?.submitted) {
      return `Submitted`;
    }

    if (status?.submitting) {
      return `Submitting...`;
    }

    return `Join Waitlist`;
  };

  const isBrowserCallback = useCallback(isBrowser, []);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (isBrowserCallback() && !userIsLoggedIn) {
      const allUserInterest = localStorage.getItem(LOCALSTORAGE_KEY);
      setUserInterest(JSON.parse(allUserInterest));

      setFormData({ ...formData, email: allUserInterest?.email });
    }
  }, [userIsLoggedIn, isBrowserCallback]);

  useEffect(() => {
    if (activeOverlay == null) {
      setFormData({ newsletter: false });
      setCryptoKnowledgeProgress(0);
      setStatus({
        submitted: false,
        submitting: false,
        error: false
      });
      if (commitmentInput?.current?.value) {
        commitmentInput.current.value = 0;
      }
      if (cryptoKnowledgeInput?.current?.value) {
        cryptoKnowledgeInput.current.value = 0;
      }
      if (cryptoKnowledgeOutput?.current?.value) {
        cryptoKnowledgeOutput.current.value = cryptoKnowledgeDict[0];
      }
      if (checkbox?.current?.checked) {
        checkbox.current.checked = false;
      }
      if (emailInput?.current?.value) {
        emailInput.current.value = ``;
      }
    }
  }, [activeOverlay]);

  // ---------------------------------------------------------------------------
  // render

  const isButtonDisabled = (!formData.email && !userData?.user?.emailAddress && !userInterest?.email) || status?.submitting || status?.submitted;

  return (
    <Container active={activeOverlay === `RaffleOverlayWaitlist`}>
      <SlicePadding>
        <Grid noXPadding>
          <BackgroundWrapper>
            <Background />
            <OverlayClose onClick={() => setActiveOverlay(null)}>
              <SVG
                svg="cross"
                css={css`
                  width: 16px;
                `}
                fill="black"
              />
            </OverlayClose>
          </BackgroundWrapper>

          <Aside>
            <h2 className="h3">Get Notified</h2>
            <p className="b2">Sign up to receive priority notification when our sought-after raffle tickets are available for purchase to the public.</p>
          </Aside>

          <Form onSubmit={onSubmit}>
            <input type="text" className="raffleOverlayWaitlistFormHoneypot" text="text" onChange={onChange} name="honeypot" />

            {/* <label htmlFor="cryptoKnowledge" className="raffleOverlayWaitlistFormCryptoKnowledge">
              <span className="caption">CRYPTO UNDERSTANDING</span>
              <input
                type="range"
                ref={cryptoKnowledgeInput}
                name="cryptoKnowledge"
                min="0"
                max="100"
                step="25"
                defaultValue={0}
                onChange={(event) => {
                  const tempSliderValue = event.target.value;
                  cryptoKnowledgeValue.textContent = tempSliderValue;

                  setCryptoKnowledgeProgress((parseInt(tempSliderValue) / parseInt(event.target.max)) * 100);
                }}
                onInput={(event) => {
                  cryptoKnowledgeOutput.current.value = cryptoKnowledgeDict[parseInt(event.target.value)];
                }}
                style={{
                  background: `linear-gradient(to right, var(--color-mono-40) ${cryptoKnowledgeProgress}%, #fff ${cryptoKnowledgeProgress}%)`
                }}
              />
              <div ref={cryptoKnowledgeValue} />
              <output className="output" ref={cryptoKnowledgeOutput}>
                {cryptoKnowledgeDict[0]}
              </output>
            </label>
            <br /> */}
            {/* <label
              htmlFor="commitment"
              className="raffleOverlayWaitlistFormCommitment"
            >
              <span className="caption">USDt COMMITMENT</span>
              <div className="raffleOverlayWaitlistFormCommitmentInput">
                <input
                  id="raffle-overlay-waitlist-commitment"
                  name="commitment"
                  className="b2"
                  placeholder="Your USDt commitment"
                  onChange={onChange}
                  type="number"
                  min={1}
                  max={raffle?.enrichedProduct?.product?.price?.usdAmount}
                  ref={commitmentInput}
                />
                <Button
                  className="maxCommitmentButton"
                  buttonType="button"
                  variant="feature"
                  onClick={() => {
                    const fakeEvent = {
                      target: {
                        name: `commitment`,
                        value: raffle?.enrichedProduct?.product?.price?.usdAmount
                      }
                    };
                    onChange(fakeEvent);
                    commitmentInput.current.value =
                      raffle?.enrichedProduct?.product?.price?.usdAmount;
                  }}
                >
                  Max
                </Button>
              </div>
            </label> */}

            {!(userData?.user?.emailAddress || userInterest?.email) && (
              <label htmlFor="email" className="raffleOverlayWaitlistFormEmail">
                <span className="caption">EMAIL*</span>
                <input
                  id="raffle-overlay-waitlist-email"
                  ref={emailInput}
                  name="email"
                  className="b2"
                  placeholder="Your Email"
                  onChange={onChange}
                  type="email"
                />
              </label>
            )}

            <label htmlFor="newsletter" className="raffleOverlayWaitlistFormCheckbox">
              <input ref={checkbox} id="raffle-overlay-waitlist-newsletter" name="newsletter" onChange={onChange} type="checkbox" />
              <span className="b2">Receive newletters from Altr</span>
            </label>

            <Button buttonType="submit" fluid variant="feature" disabled={isButtonDisabled}>
              <span className="b1">{getSubmitText()}</span>
            </Button>
            {status.error && <p className="raffleOverlayWaitlistFormError b2">Something went wrong. Please check your connection and try again.</p>}
          </Form>
        </Grid>
      </SlicePadding>
    </Container>
  );
};

export default RaffleOverlayWaitlist;
