import React, { useEffect, useState } from "react";
import { useApp, usePurchaseData } from "~hooks";
import { NFTCheckout, NFTIconNote, NFTOverlay, NFTNumberEntry, NFTTimeSelect } from "~components";
import { usePublicClient, useWalletClient } from "wagmi";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError } from "~utils/error";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayCreateWholeSellOrder = ({ nft }) => {
  const expires = { "24 hours": 86400, "7 days": 604800, "15 days": 1296000 };
  // ---------------------------------------------------------------------------
  // imports / hooks
  const {
    setOverlayCompletionData,
    activeOverlay,
    userData: {
      balances: { nativeBalance }
    }
  } = useApp();
  const { enrichedProduct } = nft;

  const { useManage0xNftAllowance } = blockchainHooks();

  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();

  const { data: purchaseData, approved, setApproved, update, onChange, reset } = usePurchaseData();

  const { trader } = useExternalIntegrations();
  const traderSdk = trader(publicClient, walletClient);

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [executing, setExecuting] = useState(false);
  const [valid, setValid] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  const executeApproval = async () => {
    if (!enrichedProduct || executing) {
      return () => {};
    }

    setExecuting(true);

    // @externalIntegration
    const { nftData } = enrichedProduct;

    const asset = traderSdk.buildAsset(nftData?.nftCollectionInfo?.collectionAddress, `ERC721`, nftData?.tokenId, `1`);

    try {
      await traderSdk.approveSwappableAsset(asset);
    } catch (e) {
      handleError(e, setOverlayCompletionData, nativeBalance?.value);
      console.error(e);
    }

    setExecuting(false);

    return null;
  };

  const purchaseFragments = async () => {
    if (!enrichedProduct || executing) {
      return () => {};
    }

    setExecuting(true);

    // @externalintegration
    const asset = await traderSdk.buildAssetFromEnrichedProduct(enrichedProduct, purchaseData?.price || 0);

    try {
      await traderSdk.createOrder(
        enrichedProduct,
        asset.tokenAddress,
        asset.amount,
        `sell`,
        `ERC721`,
        `1`,
        Math.floor(Date.now() / 1000) + expires[purchaseData?.expiry]
      );

      setOverlayCompletionData({
        heading: `Sell offer published`,
        icon: `upload`
      });

      reset();
    } catch (e) {
      handleError(e, setOverlayCompletionData, nativeBalance?.value);
      console.error(e);
    }

    setExecuting(false);

    return null;
  };

  // ---------------------------------------------------------------------------
  // lifecycle
  const { refetch } = useManage0xNftAllowance(enrichedProduct?.nftData, `ERC721`, `1`, setApproved, activeOverlay === `NFTOverlayCreateWholeSellOrder`);

  useEffect(() => {
    setValid(purchaseData?.price > 0 && purchaseData?.expiry && purchaseData?.accepted);
  }, [purchaseData?.price, purchaseData?.expiry, purchaseData?.accepted]);

  // ---------------------------------------------------------------------------
  // render

  if (!enrichedProduct) {
    return null;
  }

  return (
    <NFTOverlay id="NFTOverlayCreateWholeSellOrder" heading="Create Sell Offer" nft={nft} sidebarMode="createSellOrderWhole">
      {/* todo (validation): max should equal ?? */}
      <NFTNumberEntry className="nftOverlayGroup" name="price" onChange={onChange} heading="1. Set your price:" placeholder="Your price" min={1} withBadge />

      {/* todo (validation): (usdt per fraction * fractions wanted) < wallet balance */}
      <NFTTimeSelect
        className="nftOverlayGroup"
        onSelect={(value) => {
          update(`expiry`, value);
        }}
        heading="2. Choose how long this sell offer will be valid for:"
      />

      <NFTCheckout
        className="nftOverlayGroup"
        subheadingVisible={parseFloat(enrichedProduct?.nftData?.makerFee.split(`%`)[0]) > 0}
        fee={enrichedProduct?.nftData?.makerFee}
        feeSubtract
        finalButtonText="Create Sell Offer"
        totalText="You Receive"
        nft={nft}
        data={purchaseData}
        execute={purchaseFragments}
        executeApproval={executeApproval}
        valid={valid}
        approved={approved}
        refetch={refetch}
        type="whole"
        approveLoading={executing && !approved}
        actionLoading={executing && approved}
      />
      <NFTIconNote
        background="rgba(255, 255, 255, 0.4)"
        fontClass="caption"
        svg="alert"
        text="Your order is posted at the Listed Price. When sold, the Platform Fee is deducted, and you receive the remaining amount."
      />
      <NFTIconNote
        background="rgba(255, 255, 255, 0.4)"
        fontClass="caption"
        svg="alert"
        text="Once you approve the offer, the smart contract can transfer your NFTs in any moment."
      />
    </NFTOverlay>
  );
};

export default NFTOverlayCreateWholeSellOrder;
