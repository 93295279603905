import React from "react";
import styled from "@emotion/styled";
import cn from "classnames";
import { Button, CountdownClock, SVG } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  border-radius: 12px;
  color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-white)` : `var(--color-black)`)};

  padding: 1.5rem;

  .countdown {
    &Title,
    &Description {
      margin-bottom: 20px;

      ${breakpoint(`small-tablet`)} {
        margin-bottom: 40px;
      }
    }

    &Logo {
      width: 50px;
      margin-bottom: 1.5rem;
    }
  }
`;

/** ============================================================================
 * @component
 * @param  {string}    colorTheme	 		"light" | "dark"
 * @param  {string}    launchDate  		UTC date string eg `2028-02-21T23:23:44.249Z`
 * @param  {string}    product   			Product name
 * @param  {string}    className   		ClassName for additional styling config
 * @param  {string}    description  	Text block
 * @param  {string}    ctaText  			Button text
 * @param  {boolean}   showLogo  			Include Altr logo at top of component
 * @return {node}
 */

const Countdown = ({
  colorTheme = `light`,
  // launchDate,
  product,
  description,
  ctaText = `Join the waitlist`,
  showLogo,
  className
}) => {
  const { title, dropDate: dropDateSanity } = product;

  const dropDate = new Date(dropDateSanity);

  return (
    <Container colorTheme={colorTheme} className={cn(colorTheme === `light` ? `frosted-glass-dark` : `frosted-glass-light`, className)}>
      {showLogo && <SVG className="countdownLogo" svg="logo" />}
      <h4 className="h2 countdownTitle">
        <CountdownClock launchDate={dropDate} />
        <br />
        Until {title} Launch
      </h4>
      <p className="b2 countdownDescription">{description}</p>
      {/* Todo - wire up this button */}
      <Button colorTheme={colorTheme}>{ctaText}</Button>
    </Container>
  );
};

export default Countdown;
