import React, { useState } from "react";
import { SVG, SearchInput, Modal, Button } from "~components";
import { pluralize } from "~utils/helpers";
import CheckboxButton from "~components/Common/CheckboxButton";
import MultiRangeSlider from "~components/Common/MultiRangeSlider";
import Select from "~components/Common/Select";
import TextInput from "~components/Common/TextInput";
import { categories, nftTypes, statuses } from "~hooks/useMarketplace";

const MarketplaceMobileFilters = ({ searchQuery, setSearchQuery, filters, setFilters, clearFilters, oracles, brands }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFiltersModalOpen = () => setIsModalOpen(true);

  const handleFiltersClose = () => setIsModalOpen(false);

  const handleFiltersApply = () => setIsModalOpen(false);

  const handleQueryChange = (value) => {
    setSearchQuery(value || ``);
  };

  const handleMinPriceChange = (value) => {
    setFilters((prev) => ({ ...prev, priceMin: value }));
  };

  const handleMaxPriceChange = (value) => {
    setFilters((prev) => ({ ...prev, priceMax: value }));
  };

  const handleFiltersChange = (e, filter) => {
    if (e.target.name === `all`) {
      setFilters((prev) => {
        const { all, ...prevFilterWithOutAll } = prev[filter];
        return { ...prev, [filter]: e.target.checked ? { all: true } : { ...prev[filter], all: !Object.values(prevFilterWithOutAll).includes(true) } };
      });
      return;
    }
    setFilters((prev) => ({ ...prev, [filter]: { ...prev[filter], [e.target.name]: e.target.checked, all: false } }));
  };

  const handleCategoryChange = (e) => {
    setFilters((prev) => ({
      ...prev,
      categories: {
        ...Object.keys(prev.categories).reduce((acc, category) => ({ ...acc, [category]: false }), {}),
        [e.target.checked ? e.target.name : `all`]: e.target.checked
      }
    }));
  };

  const handleSelectChange = (value, name) => {
    setFilters((prev) => {
      if (prev[name].includes(value)) {
        const options = prev[name].filter((item) => item !== value);

        return { ...prev, [name]: options.length > 0 ? options : [`All`] };
      }
      return { ...prev, [name]: [...prev[name].filter((item) => item !== `All`), value] };
    });
  };

  const handleSliderChange = ({ min, max }) => {
    setFilters((prev) => ({ ...prev, minYear: min, maxYear: max }));
  };

  return (
    <>
      <Modal className="marketplaceFiltersModal" title="Filter results:" onClose={handleFiltersClose} isVisible={isModalOpen} disableOutsideClick>
        <Modal.Content className="filters marketplaceFiltersModalContent">
          <section>
            <h4>Categories:</h4>
            <ul>
              {Object.entries(categories).map(([category, activeness]) => (
                <li key={category}>
                  <CheckboxButton
                    label={category.includes(`Product`) ? pluralize(category.toLowerCase().split(`product`)[0]) : category}
                    name={category}
                    disabled={activeness === `disabled`}
                    checked={!!filters.categories[category]}
                    onChange={handleCategoryChange}
                  />
                </li>
              ))}
            </ul>
          </section>
          <section>
            <h4>Price (USD):</h4>
            <TextInput value={filters.priceMin} onChange={handleMinPriceChange} placeholder="MIN" />
            <TextInput value={filters.priceMax} onChange={handleMaxPriceChange} placeholder="MAX" />
          </section>
          <section>
            <h4>Status:</h4>
            <ul>
              {Object.entries(statuses).map(([status, activeness]) => (
                <li key={status}>
                  <CheckboxButton
                    label={status}
                    name={status}
                    disabled={activeness === `disabled`}
                    checked={!!filters.status[status]}
                    onChange={(e) => handleFiltersChange(e, `status`)}
                  />
                </li>
              ))}
            </ul>
          </section>
          <section>
            <h4>NFT Type:</h4>
            <ul>
              {Object.entries(nftTypes).map(([nftType, activeness]) => (
                <li key={nftType}>
                  <CheckboxButton
                    label={nftType}
                    name={nftType}
                    disabled={activeness === `disabled`}
                    checked={!!filters.nftType[nftType]}
                    onChange={(e) => handleFiltersChange(e, `nftType`)}
                  />
                </li>
              ))}
            </ul>
          </section>
          <section>
            <h4>Oracles</h4>
            <Select
              variant="secondary"
              className="filtersOracles"
              options={oracles?.map((oracle) => ({ title: oracle.text, value: oracle.identifier }))}
              onChange={(value) => handleSelectChange(value, `oracles`)}
              value={filters.oracles}
              closeOnSelect={false}
            />
          </section>
          {!filters.categories.all && (
            <>
              <h2>
                {Object.entries(filters.categories)
                  .find(([_, val]) => !!val)[0]
                  .toLowerCase()
                  .split(`product`)[0][0]
                  .toUpperCase() +
                  Object.entries(filters.categories)
                    .find(([_, val]) => !!val)[0]
                    .toLowerCase()
                    .split(`product`)[0]
                    .slice(1)}
                {` `}
                specific filters:
              </h2>
              <section>
                <h4>Brand</h4>
                <Select
                  variant="secondary"
                  className="filtersOracles"
                  options={brands?.map((brand) => ({ title: brand.brandName, value: brand.identifier }))}
                  onChange={(value) => handleSelectChange(value, `brands`)}
                  value={filters.brands}
                  closeOnSelect={false}
                />
              </section>
              <section>
                <h4>Model</h4>
                <TextInput value={filters.priceMin} onChange={handleMinPriceChange} placeholder="Model" />
              </section>
              <section>
                <h4>Year</h4>
                <MultiRangeSlider min={1800} max={new Date().getFullYear()} onChange={handleSliderChange} />
              </section>
            </>
          )}
        </Modal.Content>
        <Modal.Footer>
          <Button variant="feature" colorTheme="light" fluid onClick={clearFilters}>
            Clear Filters
          </Button>
          <Button onClick={handleFiltersApply} variant="primaryTall" colorTheme="light" fluid>
            Apply filters
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="profileFilters">
        <SearchInput value={searchQuery} onChange={handleQueryChange} />
        <button type="button" className="toggleModal" onClick={handleFiltersModalOpen} aria-label="Open filters">
          <SVG svg="filtersIcon" />
        </button>
      </div>
    </>
  );
};

export default MarketplaceMobileFilters;
