import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import gsap from "gsap";
import { GatsbyImage } from "gatsby-plugin-image";
import { useScroll, useTimeout } from "~hooks";
import { Grid, Link, NFTActionButtons, NFTPurchase, NFTStatusBadge, SlicePadding, SVG } from "~components";
import { breakpoint } from "~utils/css";
import cn from "classnames";

import backgroundDark from "~assets/images/backgrounds/pdp-atf-dark.jpg";
import backgroundLight from "~assets/images/backgrounds/pdp-atf-light.jpg";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  position: relative;

  @keyframes atf-appear-down {
    0% {
      transform: translate3d(0, -4rem, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  //

  .nftPremiere {
    &BackgroundImageContainer {
      position: absolute;
      inset: 0;
      filter: grayscale(1);

      img {
        animation: 1s var(--cubic-easing) atf-appear-down forwards;
        animation-delay: 0.5s;

        transform: translate3d(0, -4rem, 0);
        opacity: 0;

        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &Content {
      position: relative;
      z-index: 10;
      color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-black)` : `var(--color-white)`)};
    }

    &Title {
      margin-top: 0.5rem;
      margin-bottom: 1.5rem;

      // ${breakpoint(`large-tablet`)} {
      //   margin-top: 0;
      //   margin-bottom: 0;
      // }
    }

    &SlicePadding {
      padding-top: 0;
    }

    &Media {
      width: 100%;
      height: 60vh;
      min-height: 300px;
      overflow: hidden;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      margin-bottom: 60px;
      -webkit-mask-image: -webkit-radial-gradient(white, black); // Need for safari rounded corners ¯\_(ツ)_/¯"
    }

    &Figure {
      width: 100%;
      height: 100%;
      position: relative;
    }

    &Image {
      animation: 1s var(--cubic-easing) atf-appear-down forwards;
      animation-delay: 0.25s;

      transform: translate3d(0, 4rem, 0);
      opacity: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;

      &Desktop {
        display: none;
        ${breakpoint(`large-tablet`)} {
          display: block;
        }
      }

      &Mobile {
        ${breakpoint(`large-tablet`)} {
          display: none;
        }
      }
    }

    &ContentLeft {
      animation: 0.75s var(--cubic-easing) appear-up forwards;
      animation-delay: 0.1s;

      transform: translate3d(0, -1rem, 0);
      opacity: 0;

      grid-column: 1 / -1;

      ${breakpoint(`large-tablet`)} {
        grid-column: 1 / 6;
      }
    }

    &ContentRight {
      animation: 0.75s var(--cubic-easing) appear-up forwards;
      animation-delay: 0.1s;
      color: var(--color-black);

      transform: translate3d(0, -1rem, 0);
      opacity: 0;

      grid-column: 1 / -1;

      ${breakpoint(`large-tablet`)} {
        grid-column: 6 / -1;
      }
    }

    &Oracle {
      color: var(--color-mono-40);

      a {
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &OracleArrow {
      width: 7px;
      position: relative;
      display: inline-block;
      top: -3px;
      margin-left: 4px;
    }

    &Status {
      // margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      display: inline-block;
      color: var(--color-black);

      ${breakpoint(`large-tablet`)} {
        // margin-top: 2.5rem;
        margin-bottom: 0;
      }
    }

    &List {
      width: 100%;
      margin-bottom: 3rem;
      border-bottom: 1px solid var(--color-mono-40);

      ${breakpoint(`large-tablet`)} {
        width: calc(75% + 1rem);
        margin-top: 0.75rem;
        margin-bottom: 0;
      }

      &Item {
        padding: 0.5rem 0;
        border-top: 1px solid var(--color-mono-40);

        &Heading {
          color: var(--color-mono-40);
        }

        &Name {
          color: var(--color-mono-60);
        }

        svg {
          width: 11px;
          height: 11px;
          margin-left: 7px;
        }
      }
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTPremiere = ({ nft: { nftStatus, enrichedProduct, saleId }, sanityProduct }) => {
  let title = ``;
  let heroImage = ``;
  let oracle = null;
  let pdpTheme = null;

  if (sanityProduct) {
    ({ title, heroImage, oracle, pdpTheme } = sanityProduct);
  }

  // ---------------------------------------------------------------------------
  // imports / hooks

  const { scrollTop } = useScroll();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const mediaRef = useRef();

  const [loaded, setLoaded] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (mediaRef?.current) {
      gsap.to(mediaRef.current, {
        y: scrollTop * 0.2,
        ease: `none`,
        duration: 0
      });
    }
  }, [mediaRef, scrollTop]);

  useEffect(() => {
    if (loaded) {
      return;
    }

    if (enrichedProduct?.hasOwnProperty(`product`)) {
      setTimeout(() => setLoaded(true), 1000);
    }
  }, [enrichedProduct]);

  // force loaded, after a time
  useTimeout(() => {
    setLoaded(true);
  }, 4500);

  // ---------------------------------------------------------------------------
  // render
  const listOracleVisible = nftStatus?.is([`SoldOut`, `NFTBurned`]);
  const topOracleVisible = !listOracleVisible && oracle;

  return (
    <Container colorTheme={pdpTheme}>
      <figure
        className="nftPremiereBackgroundImageContainer"
        css={css`
          background: var(--color-${pdpTheme === `dark` ? `black` : `beige`});
        `}
      >
        {loaded && (
          <>
            {pdpTheme === `dark` && <img src={backgroundDark} alt="Dark abstract background" />}

            {pdpTheme === `light` && <img src={backgroundLight} alt="Light abstract background" />}
          </>
        )}
      </figure>

      <article className="nftPremiereContent">
        <SlicePadding className="nftPremiereSlicePadding">
          <div className="nftPremiereMedia">
            <figure className="nftPremiereFigure" ref={mediaRef}>
              <GatsbyImage
                image={heroImage?.asset.gatsbyImage}
                alt={heroImage?.altText || title}
                className={cn(`nftPremiereImage`, {
                  [`nftPremiereImageDesktop`]: heroImage?.mobileImage
                })}
              />
              {heroImage?.mobileImage && (
                <GatsbyImage
                  image={heroImage?.mobileImage?.asset.gatsbyImageData}
                  alt={heroImage?.mobileImage?.altText || title}
                  className="nftPremiereImage nftPremiereImageMobile"
                />
              )}
            </figure>
          </div>

          <Grid noXPadding>
            {/* // leftmost title/status information // */}
            <header className="nftPremiereContentLeft">
              <h3 className="b2 nftPremiereOracle">
                {topOracleVisible && (
                  <>
                    <Link to={`/oracles/${oracle.slug.current}`}>{oracle.title}</Link>

                    <SVG svg="arrowUp" className="h1 nftPremiereOracleArrow" />
                  </>
                )}
              </h3>

              <h1 className="h1 nftPremiereTitle">{title}</h1>

              <NFTStatusBadge className="nftPremiereStatus" nftStatus={nftStatus} enrichedProduct={enrichedProduct} />

              {listOracleVisible && (
                <ul className="nftPremiereList">
                  {/* per Figma:
                    we will update the API so that enrichedProduct
                    will have the storage company and sold on date
                    available when the status is "sold"
                  */}
                  {!nftStatus?.is(`NFTBurned`) && (
                    <li className="nftPremiereListItem">
                      <h5 className="nftPremiereListItemHeading caption">STORED BY</h5>
                      <p className="nftPremiereListItemName b2">
                        <span>Vault Company LTD</span>
                        <SVG svg="annchorIcon" />
                      </p>
                    </li>
                  )}

                  <li className="nftPremiereListItem">
                    <h5 className="nftPremiereListItemHeading caption">DIGITISED BY</h5>
                    <Link to={`/oracles/${oracle.slug.current}`} className="nftPremiereListItemName b2">
                      <span>{oracle.title}</span>
                      <SVG svg="anchorIcon" />
                    </Link>
                  </li>
                </ul>
              )}
            </header>

            {/* // rightmost purchase/register controls // */}
            <div className="nftPremiereContentRight">
              <NFTPurchase enrichedProduct={enrichedProduct} nftStatus={nftStatus} sanityProduct={sanityProduct} saleId={saleId} loaded={loaded} />

              <NFTActionButtons enrichedProduct={enrichedProduct} nftStatus={nftStatus} loaded={loaded} colorTheme={pdpTheme} />
            </div>
          </Grid>
        </SlicePadding>
      </article>
    </Container>
  );
};

export default NFTPremiere;
