import { useState, useEffect } from "react";
import AltrApiService from "~dApp/AltrApiService";

const useEnrichedProducts = (allSanityEvent) => {
  const altrApiService = new AltrApiService();

  const [enrichedEvents, setEnrichedEvents] = useState([]);

  useEffect(() => {
    const enrichAll = async () => {
      if (allSanityEvent?.edges?.[0]) {
        const sanityEvents = allSanityEvent.edges.map(({ node }) => node);

        const sanityIds = sanityEvents
          .filter((item) => item.eventGuid)
          .map(({ eventGuid }) => ({
            id: eventGuid
          }));

        const lcdData = await altrApiService.getLucidEventById(sanityIds[0].id);
        const lucidData = [lcdData];

        const joinedData = await Promise.all(
          lucidData.map(async (item) => {
            const sanityEvent = sanityEvents.find(
              (event) => event?.eventGuid === item?.identifier
            );
            const enrichedEvent = await altrApiService.enrichSanityEvent(
              sanityEvent
            );

            return enrichedEvent;
          })
        );

        setEnrichedEvents(joinedData);
      }
    };

    enrichAll();
  }, [allSanityEvent?.edges?.length]);

  return {
    enrichedEvents
  };
};

export default useEnrichedProducts;
