import React from "react";
import styled from "@emotion/styled";
import { useApp } from "~hooks";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  position: relative;

  .featuredVideo {
    &Video {
      width: 100%;
    }

    &FullscreenButton {
      position: absolute;
      bottom: 40px;
      color: white;
      padding: 1rem 1.5rem;
      left: 0;
      border-radius: 0.75rem;
      overflow: hidden;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

/** ============================================================================
 * @component
 */
const FeaturedVideo = ({ data: { allowFullscreen, video } }) => {
  const { setVideoPlayerSource, setIsVideoPlayerVisible } = useApp();

  const handleClick = () => {
    setVideoPlayerSource(video.video.asset.url);
    setIsVideoPlayerVisible(true);
  };

  return (
    <Container>
      <video className="featuredVideoVideo" src={video.video.asset.url} autoPlay loop playsInline muted />

      {allowFullscreen && (
        <button onClick={handleClick} type="button" className="featuredVideoFullscreenButton frosted-glass-dark">
          <span className="tag">Watch the full video</span>
        </button>
      )}
    </Container>
  );
};

export default FeaturedVideo;
