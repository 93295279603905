import { CustomLoginData } from "~dApp/models/CustomLoginData";
import { isBrowser } from "~utils/helpers";
import { BaseApiLoginWalletResponse, LoggedUserVM } from "~dApp/models/ApiModel";

/** ============================================================================
 * LoginService
 * todo - document
 */
class LoginService {
  // ---------------------------------------------------------------------------
  // class variables

  // ---------------------------------------------------------------------------
  // constructor
  private readonly loggedUserKey = "loggedUser"; //FIXME: define a proper key

  constructor() {}

  // ---------------------------------------------------------------------------
  // methods

  getUser(): CustomLoginData {
    return isBrowser() && window.localStorage.getItem(this.loggedUserKey) ? JSON.parse(window.localStorage.getItem(this.loggedUserKey)) : null;
  }

  setUser(user: CustomLoginData) {
    return window.localStorage.setItem(this.loggedUserKey, JSON.stringify(user));
  }

  cleanUserInLocalStorage() {
    return window.localStorage.removeItem(this.loggedUserKey);
  }

  walletLoginCallback(authenticationData: BaseApiLoginWalletResponse, userData: LoggedUserVM, preferredCulture: string) {
    if (!authenticationData || !authenticationData.token || !authenticationData.publicAddress) {
      return false;
    }
    const loginData = new CustomLoginData();
    loginData.token = authenticationData.token;
    loginData.walletAddress = authenticationData.publicAddress;
    //loginData.role = authenticationData.role; //check role via api call to avoid malicious attacks
    loginData.username = authenticationData.username;
    loginData.preferredCulture = preferredCulture;
    loginData.emailAddress = userData.emailAddress;
    loginData.nickname = userData.nickname;
    loginData.memberSince = userData.memberSince;
    this.setUser(loginData);
    return loginData;
  }

  updateProfileData(newProfileData: LoggedUserVM) {
    let currentProfileData = this.getUser();
    if (!currentProfileData) {
      return;
    }
    currentProfileData.emailAddress = newProfileData.emailAddress;
    currentProfileData.nickname = newProfileData.nickname;
    // currentProfileData.preferredCulture = newProfileData.preferredCulture;
    //FIXME: opt in, opt out marketingPreferences
    this.setUser(currentProfileData);
  }

  isAuthenticated() {
    const user = this.getUser();
    return user && !!user.token && !!user.walletAddress;
  }

  logout(callback: any) {
    this.cleanUserInLocalStorage();
    if (callback) callback();
  }

  getAuthorizationHeaderForLoggedUser() {
    const user = this.getUser();
    var header = {};
    if (!user) {
      return header;
    }
    return this.getAuthorizationHeaderForToken(user.token);
  }

  getAuthorizationHeaderForToken(token: string) {
    return { Authorization: `Bearer ${token}` };
  }
}

export default LoginService;
