import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useApp } from "~hooks";
import { SVG, Button } from "~components";

const Container = styled.div`
  .rotate {
    writing-mode: vertical-rl;
  }
`;

const Icon = styled.div`
  height: 1rem;
  width: 1rem;
  margin-left: 10px;
  margin-top: 5px;
`;

const StickyComponent = ({ text, color, backgroundColor, direction, center, navigateTo }) => {
  const { pathname } = useApp();

  const [showLabel, setShowLabel] = useState(true);

  useEffect(() => {
    if (navigateTo && pathname && pathname.includes(navigateTo)) {
      setShowLabel(false);
    } else {
      setShowLabel(true);
    }
  }, [pathname, navigateTo]);

  const getStyles = () => {
    const baseStyles = {
      display: `flex`,
      position: `fixed`,
      backgroundColor,
      color,
      padding: `100px 10px`,
      zIndex: 9999,
      textAlign: `center`,
      alignItems: `center`,
      justifyContent: `center`,
      fontSize: `20px`
    };

    const borderRadius = `20px`;

    switch (direction) {
      case `top`:
        return {
          ...baseStyles,
          top: 0,
          left: center ? `50%` : 0,
          right: center ? `50%` : `auto`,
          transform: center ? `translateX(-50%)` : `none`,
          borderBottomLeftRadius: borderRadius,
          borderBottomRightRadius: borderRadius
        };
      case `bottom`:
        return {
          ...baseStyles,
          bottom: 0,
          left: center ? `50%` : 0,
          right: center ? `50%` : `auto`,
          transform: center ? `translateX(-50%)` : `none`,
          borderTopLeftRadius: borderRadius,
          borderTopRightRadius: borderRadius
        };
      case `left`:
        return {
          ...baseStyles,
          left: 0,
          top: center ? `50%` : 0,
          bottom: center ? `50%` : `auto`,
          transform: center ? `translateY(-50%)` : `none`,
          borderTopRightRadius: borderRadius,
          borderBottomRightRadius: borderRadius
        };
      case `right`:
        return {
          ...baseStyles,
          right: 0,
          top: center ? `50%` : 0,
          bottom: center ? `50%` : `auto`,
          transform: center ? `translateY(-50%)` : `none`,
          borderTopLeftRadius: borderRadius,
          borderBottomLeftRadius: borderRadius
        };
      default:
        return baseStyles;
    }
  };

  return (
    showLabel && (
      <Container>
        <Button style={getStyles()} to={navigateTo} variant="text" className="rotate">
          {text}
          <Icon>
            <SVG svg="arrowRight" />
          </Icon>
        </Button>
      </Container>
    )
  );
};

export default StickyComponent;
