import React from "react";
import styled from "@emotion/styled";
import { breakpoint } from "~utils/css";
import { LinkButton, SlicePadding, InfoRow } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  background: ${({ colorTheme }) =>
    colorTheme === `light` ? `linear-gradient(180deg, #FFFFFF 59.9%, #F0F0F0 100%)` : `linear-gradient(180deg, #000000 0%, #12101E 100%)`};

  color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-black)` : `var(--color-white)`)};

  .infoRows {
    &FlexContainer {
      ${breakpoint(`small-tablet`)} {
        display: flex;
        justify-content: space-between;

        > div {
          flex: 1;
        }
      }
    }

    &ButtonContainer {
      ${breakpoint(`small-tablet`)} {
        text-align: right;
      }
    }

    &TextContainer {
      ${breakpoint(`small-tablet`)} {
        margin-bottom: 3.75rem;
      }
    }

    &Label {
      margin-bottom: 0.25rem;

      ${breakpoint(`small-tablet`)} {
        margin-bottom: 1.5rem;
      }
    }

    &Header {
      margin-bottom: 2.5rem;
      white-space: pre-line;

      ${breakpoint(`small-tablet`)} {
        margin-bottom: 0;
      }
    }

    &Cta {
      margin-bottom: 2.5rem;

      ${breakpoint(`small-tablet`)} {
        margin-bottom: 0;
      }
    }

    &Row {
      margin-bottom: 2.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

/** ============================================================================
 * @component
 */
const InfoRows = ({ data: { colorTheme, header, label, cta, infoRows } }) => (
  <Container colorTheme={colorTheme}>
    <SlicePadding>
      <div className="infoRowsFlexContainer">
        <div className="infoRowsTextContainer">
          <div className="tag infoRowsLabel">{label}</div>
          <h3 className="h1 infoRowsHeader">{header}</h3>
        </div>
        <div className="infoRowsButtonContainer">
          <LinkButton link={cta} className="infoRowsCta" variant="feature" colorTheme={colorTheme === `light` ? `dark` : `light`} />
        </div>
      </div>
      {infoRows.map((infoRow, i) => (
        <InfoRow
          key={i}
          step={infoRow.step}
          className="infoRowsRow"
          title={infoRow.title}
          description={infoRow.description}
          colorTheme={colorTheme === `light` ? `dark` : `light`}
        />
      ))}
    </SlicePadding>
  </Container>
);

export default InfoRows;
