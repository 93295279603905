import cn from "classnames";
import { useTabsContext } from "./TabsContext";

const TabsList = ({ className, children }) => {
  const { colorTheme } = useTabsContext();

  return <ul className={cn(`tabsList hiddenScroll`, colorTheme, className)}>{children}</ul>;
};

export default TabsList;
