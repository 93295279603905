import React, { useEffect, useState } from "react";
import { useApp } from "~hooks";
import { NFTOverlay, Button } from "~components";
import styled from "@emotion/styled";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError } from "~utils/error";
import { usePublicClient } from "wagmi";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};

  width: 100%;
  position: relative;

  .nftOverlayClaimRequestApproveCurrency {
    &Container {
      border-radius: 1rem;
      overflow: hidden;
      background: var(--color-white);
      padding: 2.5rem;
    }

    &Subheader {
      margin-bottom: 1rem;
    }

    &List {
      color: var(--color-mono-60);
      margin-bottom: 0.5rem;

      li {
        list-style: initial;
        margin-left: 1.5rem;
      }
    }

    &Hint {
      margin-bottom: 2rem;
      color: var(--color-mono-60);
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayClaimRequestApproveCurrency = ({ nft }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const { useApproveFeeManager } = blockchainHooks();
  const { setOverlayProcessingData, setActiveOverlay, setOverlayCompletionData, userData } = useApp();
  const publicClient = usePublicClient();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [executing, setExecuting] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle
  const { writeAsync: doApprove, isSuccess } = useApproveFeeManager(nft?.enrichedProduct?.nftData);

  useEffect(() => {
    if (isSuccess) {
      setActiveOverlay(`NFTOverlayClaimRequestPayFee`);
      setOverlayProcessingData(null);
      setExecuting(false);
    }
  }, [isSuccess]);

  // ---------------------------------------------------------------------------
  // methods

  const approve = async () => {
    if (!nft?.enrichedProduct?.nftData || executing) {
      return () => {};
    }

    setExecuting(true);

    setOverlayProcessingData({
      icon: `load`,
      text: `Processing. Please do not close this page.`
    });

    // @externalintegration
    try {
      await doApprove();
    } catch (e) {
      setOverlayProcessingData(null);
      setExecuting(false);
      console.error(e);
      handleError(e, setOverlayCompletionData, await publicClient.getBalance({ address: userData?.address }));
    }
  };

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  return (
    <NFTOverlay id="NFTOverlayClaimRequestApproveCurrency" heading="Claim Request – Approve Currency" nft={nft}>
      {({ active }) => (
        <Container active={active}>
          {active && (
            <div className="nftOverlayClaimRequestApproveCurrencyContainer">
              <p className="nftOverlayClaimRequestApproveCurrencySubheader b1">You are claiming your collectible</p>

              <ul className="nftOverlayClaimRequestApproveCurrencyList b2">
                <li>Once the request is sent to the oracle you will not be able to transfer the NFT.</li>
                <li>The NFT will be frozen until the end of the claim operations.</li>
                <li>Once the item is claimed, the oracle will BURN the NFT.</li>
              </ul>

              <p className="nftOverlayClaimRequestApproveCurrencyHint b2">Click ‘Approve’ to allow an escrow smart contract to transfer USDt on your behalf.</p>
              <Button variant="primaryTall" colorTheme="dark" fluid onClick={approve}>
                Approve
              </Button>
            </div>
          )}
        </Container>
      )}
    </NFTOverlay>
  );
};

export default NFTOverlayClaimRequestApproveCurrency;
