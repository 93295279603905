import React from "react";
import styled from "@emotion/styled";
import { Button } from "~components";
import { useApp } from "~hooks";
import { checkUserIsLoggedIn } from "~utils/helpers";

const Container = styled.article`
  position: relative;
  margin-top: 1.5rem;
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTActionMakeOfferFragments = ({ colorTheme = `dark` }) => {
  const { setActiveOverlay, userIsLoggedIn, setOverlayCompletionData, setConnectorActive } = useApp();

  const handleClick = () => {
    if (!checkUserIsLoggedIn(userIsLoggedIn, setOverlayCompletionData, setConnectorActive)) return;
    setActiveOverlay(`NFTOverlayPurchaseFragmentFromOwner`);
  };

  return (
    <Container>
      <Button onClick={() => handleClick()} fluid variant="feature" colorTheme={colorTheme === `dark` ? `light` : `dark`}>
        <span className="b1">Make an Offer</span>
      </Button>
    </Container>
  );
};

export default NFTActionMakeOfferFragments;
