import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useApp, useWindowDimensions } from "~hooks";
import { Grid, EventOverlaySidebar, EventOverlayTray, SVG } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const CLOSE_BAR_HEIGHT = `68px`;

const Container = styled.section`
  background: radial-gradient(ellipse at 10% 130%, #d4d0d1 30%, #eeeeec 50%);
  transition: 0.5s var(--cubic-easing) transform;

  opacity: ${({ active }) => `${active ? 1 : 0}`};
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};
  transform: scale(${({ active }) => `${active ? `1` : `1.05`}`});

  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: scroll;

  ${breakpoint(`large-tablet`)} {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .nftOverlay {
    &Close {
      grid-column: 1 / span 5;
      height: ${CLOSE_BAR_HEIGHT};
      position: relative;
      z-index: 10;
      display: none;
      align-items: center;
      justify-content: end;
      padding-right: 1rem;

      &Icon {
        width: 1.75rem;
      }

      ${breakpoint(`large-tablet`)} {
        display: flex;
      }
    }

    //

    &Heading {
      margin-bottom: 2.5rem;
    }

    &Content {
      position: relative;
      z-index: 10;
      // overflow: auto;

      &Grid {
        height: 100%;
        align-items: start;
      }

      ${breakpoint(`large-tablet`)} {
        height: calc(100% - ${CLOSE_BAR_HEIGHT});
      }
    }

    &Group {
      margin-bottom: 0.75rem;
    }
  }

  .emergencyCloseButton {
    display: flex;
    ${breakpoint(`large-tablet`)} {
      display: none;
    }
  }
`;

const SidebarContainer = styled.aside`
  grid-column: 1/-1;
  margin-bottom: 1rem;
  ${breakpoint(`large-tablet`)} {
    grid-column: 1 / span 4;
    margin-bottom: 0;
  }

  height: 100%;
  position: relative;
`;

const Separator = styled.div`
  display: none;
  ${breakpoint(`large-tablet`)} {
    display: block;
  }

  grid-column: 5 / span 1;
  height: calc(100% + ${CLOSE_BAR_HEIGHT});
  position: relative;
  top: -${CLOSE_BAR_HEIGHT};

  &:before {
    content: " ";

    width: 1px;
    height: 100%;
    position: absolute;
    transform: translate3d(-50%, -50%, 0);
    top: 50%;
    left: 50%;
    background: black;
    z-index: 100;
  }
`;

const FormContainer = styled.article`
  grid-column: 1/-1;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-top: 5rem;

  ${breakpoint(`large-tablet`)} {
    height: 100%;
    grid-column: 6 / span 7;
    padding-top: 0;
  }
`;

const InjectedContent = styled.div`
  width: 100%;
  position: relative;
  overflow-y: scroll;
  flex: 0 1 auto;
  padding-bottom: 5rem;
`;

/** ============================================================================
 * @component NFTOverlay
 * Wrapping component for all overlays exposed in NFTOverlays/index.jsx.
 * @return {node}
 */
const EventOverlay = ({ children, id, heading, nft, sanityEvent, selected }) => {
  const { activeOverlay, setActiveOverlay } = useApp();
  const { isMobile } = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // lifecycle

  // This re-enables page scrolling after leaving the overlay view
  useEffect(() => setActiveOverlay(null), []);

  // ---------------------------------------------------------------------------
  // render

  const active = activeOverlay === id;

  return (
    <Container className="nftOverlay" active={active}>
      <div className="nftOverlayClose">
        <button type="button" onClick={() => setActiveOverlay(null)}>
          <SVG svg="xCircle" className="nftOverlayCloseIcon" />
        </button>
      </div>

      <div className="nftOverlayContent">
        <Grid className="nftOverlayContentGrid">
          <SidebarContainer>
            {(isMobile && <>{active && <EventOverlayTray nft={nft} sanityProduct={sanityEvent} selected={selected} />}</>) || (
              <>{active && <EventOverlaySidebar nft={nft} sanityEvent={sanityEvent} selected={selected} />}</>
            )}
          </SidebarContainer>

          <Separator />

          <FormContainer>
            <div className="emergencyCloseButton">
              <button type="button" onClick={() => setActiveOverlay(null)}>
                <SVG svg="xCircle" className="nftOverlayCloseIcon" />
              </button>
            </div>
            <h2 className="nftOverlayHeading h2">{heading}</h2>

            {/* todo: do we need a scroll indicator? */}
            <InjectedContent>{children({ active })}</InjectedContent>
          </FormContainer>
        </Grid >
      </div >
    </Container >
  );
};

export default EventOverlay;
