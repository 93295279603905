import React, { useEffect, useState } from "react";
import { useApp } from "~hooks";
import { NFTOverlay, Button, NFTIconNote } from "~components";
import styled from "@emotion/styled";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError } from "~utils/error";
import { usePublicClient } from "wagmi";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};

  width: 100%;
  position: relative;

  .nftOverlayClaimRequestClaim {
    &Container {
      border-radius: 1rem;
      overflow: hidden;
      margin-bottom: 0.75rem;
      padding: 2.5rem;
      background: var(--color-white);
    }

    &Subheader {
      margin-bottom: 1rem;
    }

    &List {
      color: var(--color-mono-60);
      margin-bottom: 2rem;

      li {
        list-style: initial;
        margin-left: 1.5rem;
      }
    }

    &MarginBottom {
      margin-bottom: 0.75rem;
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayClaimRequestClaim = ({ nft }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const { setOverlayProcessingData, setOverlayCompletionData, userData } = useApp();
  const { useTransferNft } = blockchainHooks();
  const publicClient = usePublicClient();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [executing, setExecuting] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle
  const { writeAsync: doClaim, isSuccess } = useTransferNft(
    nft?.enrichedProduct?.nftData,
    nft?.enrichedProduct?.nftData?.nftCollectionInfo?.collateralRetrieverAddress
  );

  useEffect(() => {
    if (isSuccess) {
      setOverlayCompletionData({
        icon: `mail`,
        heading: `Claim request sent`,
        body: `As per the agreement with your oracle, your item will be dispatched as soon as possible.`
      });
      setOverlayProcessingData(null);
      setExecuting(false);
    }
  }, [isSuccess]);
  // ---------------------------------------------------------------------------
  // methods

  const claim = async () => {
    if (!nft?.enrichedProduct?.nftData || executing) {
      return () => {};
    }

    setExecuting(true);

    setOverlayProcessingData({
      icon: `load`,
      text: `Processing. Please do not close this page.`
    });

    // @externalintegration
    try {
      await doClaim();
    } catch (e) {
      setOverlayProcessingData(null);
      setExecuting(false);
      console.error(e);
      handleError(e, setOverlayCompletionData, await publicClient.getBalance({ address: userData?.address }));
    }
  };

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  return (
    <NFTOverlay id="NFTOverlayClaimRequestClaim" heading="Claim Request – Claim" nft={nft}>
      {({ active }) => (
        <Container active={active}>
          {active && (
            <>
              <div className="nftOverlayClaimRequestClaimContainer">
                <p className="nftOverlayClaimRequestClaimSubheader b1">You are claiming your collectible</p>

                <ul className="nftOverlayClaimRequestClaimList b2">
                  <li>Once the request is sent to the oracle you will not be able to transfer the NFT.</li>
                  <li>The NFT will be frozen until the end of the claim operations.</li>
                  <li>Once the item is claimed, the oracle will BURN the NFT.</li>
                </ul>

                <Button variant="primaryTall" colorTheme="dark" fluid onClick={claim}>
                  Claim
                </Button>
              </div>
              <div className="nftOverlayClaimRequestClaimMarginBottom">
                <NFTIconNote background="rgba(255, 255, 255, 0.4)" fontClass="caption" svg="check" text="Fee Paid" />
              </div>
            </>
          )}
        </Container>
      )}
    </NFTOverlay>
  );
};

export default NFTOverlayClaimRequestClaim;
