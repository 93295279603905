export const useProduct = (data) => {
  let nftData = null;
  let oracle = null;
  let product = null;
  let sanity = null;

  if (data?.nftData) {
    ({ nftData } = data);
  }
  if (data?.oracle) {
    ({ oracle } = data);
  }
  if (data?.product) {
    ({ product } = data);
  }
  if (data?.sanity) {
    ({ sanity } = data);
  }

  return {
    nftData,
    oracle,
    product,
    sanity
  };
};

export default useProduct;
