import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useWindowDimensions } from "~hooks";
import { Image } from "~components";
import { breakpoint, hoverable } from "~utils/css.js";

const Container = styled.button`
  transition: 0.5s var(--cubic-easing) transform;

  padding: 0 0 0 0.625rem;
  border: 0.0625rem solid #959595;
  border-radius: 1rem;
  background: rgba(240, 240, 240, 0.1);
  box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(75px);

  display: ${({ full }) => `${full ? `block` : `flex`}`};
  text-align: inherit;
  width: 100%;
  height: 100%;

  ${breakpoint(`large-tablet`)} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 1rem 1rem;
  }

  ${hoverable} {
    &:hover {
      ${breakpoint(`large-tablet`)} {
        transform: scale(1.1);
      }
    }
  }

  .nftInventoryCard {
    transition: 0.5s var(--cubic-easing) transform;

    padding: 0 0 0 0.625rem;
    border: 0.0625rem solid #959595;
    border-radius: 1rem;
    background: rgba(240, 240, 240, 0.1);
    box-shadow: 0px 3px 6px 3px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(75px);

    display: ${({ full }) => `${full ? `block` : `flex`}`};

    ${breakpoint(`large-tablet`)} {
      aspect-ratio: 0.6131375459;
      display: block;
      padding: 0 1rem 1rem;
    }

    &Front {
      position: relative;

      ${breakpoint(`large-tablet`)} {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .nftInventoryCardHeader {
        flex: 1;
      }
    }

    &Header {
      width: ${({ full }) => `${full ? `100%` : `50%`}`};
      display: flex;
      align-self: stretch;
      flex-direction: column;
      justify-content: space-between;
      padding-right: 0.5rem;
      padding-bottom: 1rem;

      ${breakpoint(`large-tablet`)} {
        width: 100%;
        padding-right: 0;
        padding-bottom: 0;
      }

      .nftInventoryCardMetadata {
        display: ${({ full }) => `${full ? `none` : `block`}`};

        ${breakpoint(`large-tablet`)} {
          display: none;
        }
      }

      .title {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;
        padding-top: 1rem;
      }
    }

    &Label {
      display: flex;
      padding: 0.25rem 0.5rem;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 0px 10px;
      border: 1px solid var(--Mono-40, #919191);
      color: var(--color-white, #fff);
    }

    &Image {
      width: ${({ full }) => `${full ? `100%` : `50%`}`};
      aspect-ratio: 0.8;
      position: relative;
      overflow: hidden;
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.9);

      margin-top: ${({ full }) => `${full ? `1rem` : `0`}`};

      ${breakpoint(`large-tablet`)} {
        width: 100%;
        margin: 1rem 0 0;
      }

      &Border {
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid var(--color-mono-40);
        box-shadow: inset 0px 0px 10px 5px rgba(0, 0, 0, 0.9);
        border-radius: 10px;
      }

      img {
        ${breakpoint(`large-tablet`)} {
          animation: 0.75s var(--cubic-easing) appear-scale-down-slight;
        }
      }
    }

    //

    &Category,
    &Heading,
    &Subheading,
    &Metadata {
      ${breakpoint(`large-tablet`)} {
        animation: 0.5s var(--cubic-easing) appear forwards;
        opacity: 0;
      }
    }

    &Category {
      animation-delay: 0.1s;

      color: var(--color-mono-40);
    }

    &Heading {
      animation-delay: 0.2s;

      margin: 0.25rem 0;
      color: var(--color-white);

      ${breakpoint(`large-tablet`)} {
        margin: 0.5rem 0;
      }
    }

    &Subheading,
    &Metadata {
      animation-delay: 0.3s;

      color: var(--color-mono-20);
    }

    &Metadata {
      display: ${({ full }) => `${full ? `block` : `none`}`};
      margin-top: ${({ full }) => `${full ? `1.5rem` : `0`}`};

      ${breakpoint(`large-tablet`)} {
        display: block;
        margin-top: 0;
      }
    }

    &Table {
      padding-top: 0.5rem;
      margin-bottom: 1rem;
      border-top: 1px solid var(--color-white);
      color: var(--color-white);

      &Item {
        padding: 0.125rem 0;
      }
    }

    &View {
      margin-top: 2.5rem;
    }
  }
`;

const MobileButton = styled.button`
  width: 100%;
  position: relative;
  display: block;
  text-align: left;
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTListCard = ({ className = ``, notButton, oracle, item: { title, raw, metadata, image, media }, full = false, onSelect = () => {} }) => {
  const { isMobile, isTablet } = useWindowDimensions();
  const certification = (raw?.metadata || metadata)?.certificatetype || `ALTR`;
  const imageUrl = image?.cachedUrl || media?.at(0)?.gateway;

  const cardHeader = (
    <header className="nftInventoryCardHeader">
      <div
        css={css`
          text-align: center;
          color: #ffffff;
          position: relative;
          display: flex;
          justify-content: center;

          svg {
            position: relative;
            top: -0.0625rem;
            stroke-width: 0.0625rem;
          }
        `}
      >
        <svg width="146" height="20" viewBox="0 0 146 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3 0.500002L1.71396 0.500002L2.66226 1.36869L17.7189 15.1612C20.5771 17.7794 24.3126 19.2318 28.1887 19.2318L117.811 19.2318C121.687 19.2318 125.423 17.7795 128.281 15.1612L143.338 1.36869L144.286 0.5L143 0.5L3 0.500002Z"
            stroke="#959595"
          />
          <text
            css={css`
              font-size: 0.5rem;
              font-family: "Sohne Leicht", sans-serif;
              fill: #ffffff;
            `}
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            {certification?.toUpperCase()}
          </text>
        </svg>
      </div>
      <div className="title">
        <span className="nftInventoryCardLabel">{oracle}</span>
        <h3 className={`nftInventoryCardHeading ${isMobile ? `b1` : `h3`}`}>{title || `Altr NFT`}</h3>
      </div>
    </header>
  );

  const cardJSX = (
    <Container as={notButton ? `div` : `button`} className={className} full={full} onClick={onSelect}>
      {cardHeader}
      <figure className="nftInventoryCardImage">
        <div className="nftInventoryCardImageBorder" />
        {imageUrl && (
          <Image
            css={css`
              width: 100%;
              height: 100%;
              object-fit: cover;
            `}
            image={imageUrl}
          />
        )}
      </figure>
    </Container>
  );

  //

  return isMobile || isTablet ? (
    <MobileButton as={notButton ? `div` : `button`} type="button" onClick={onSelect}>
      {cardJSX}
    </MobileButton>
  ) : (
    cardJSX
  );
};

export default NFTListCard;
