import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { usePublicClient, useWalletClient } from "wagmi";
import { useApp, useFragments, usePurchaseData } from "~hooks";
import { NFTIconNote, NFTCheckout, NFTOverlay, NFTNumberEntry } from "~components";
import { formatStablecoin, genericErrorCallback } from "~utils/helpers";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError, parseError } from "~utils/error";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { parseUnits } from "viem";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayPurchaseFragment = ({ nft }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [executing, setExecuting] = useState(false);
  const [rawPrice, setRawPrice] = useState(0n);
  const [displayPrice, setDisplayPrice] = useState(0);

  // ---------------------------------------------------------------------------
  // imports / hooks

  const { setOverlayCompletionData, userData } = useApp();

  const { fragmentDisplayPrice, fragmentsAvailable } = useFragments(nft);

  const { useApproveFractionsSale, useManageFractionsSaleTokenAllowance, usePurchaseFractions } = blockchainHooks();

  const { writeAsync: doApproveFractionsSaleAsync, isPrepareError, isApproveSuccess } = useApproveFractionsSale(nft?.enrichedProduct?.nftData, rawPrice);

  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();

  const { trader } = useExternalIntegrations();

  const { data: purchaseData, approved, setApproved, onChange, reset, update } = usePurchaseData();

  const {
    writeAsync: doBuyFractions,
    isPrepareError: isBuyError,
    prepareError,
    isSuccess
  } = usePurchaseFractions(nft?.enrichedProduct?.nftData, purchaseData?.fragments);

  useEffect(() => {
    if (isSuccess) {
      setExecuting(false);
      reset();
      navigate(`/fragment-purchase-confirmation/${nft.enrichedProduct.product.identifier}`);
    } else if (isApproveSuccess) {
      setExecuting(false);
    }
  }, [isSuccess, isApproveSuccess]);
  // ---------------------------------------------------------------------------
  // methods

  const executeApproval = async () => {
    if (executing || isPrepareError) {
      return;
    }

    setExecuting(true);

    try {
      await doApproveFractionsSaleAsync();
    } catch (e) {
      console.error(e);
      handleError(e, setOverlayCompletionData, await publicClient.getBalance({ address: userData?.address }));
    }

    setExecuting(false);
  };

  const purchaseFragments = async () => {
    if (!nft?.enrichedProduct || executing || isBuyError) {
      return () => {};
    }
    setExecuting(true);

    try {
      await doBuyFractions();
    } catch (e) {
      console.error(e);
      handleError(e, setOverlayCompletionData, await publicClient.getBalance({ address: userData?.address }));
    }

    setExecuting(false);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (typeof fragmentDisplayPrice !== `undefined`) {
      update(`pricePerFragment`, fragmentDisplayPrice);
    }
  }, [fragmentDisplayPrice]);

  useEffect(() => {
    const getPrice = async () => {
      if (!nft?.enrichedProduct) {
        return;
      }

      const traderSdk = trader(publicClient, walletClient);

      const price = parseFloat(purchaseData?.pricePerFragment) * parseInt(purchaseData?.fragments);

      const { nftData } = nft?.enrichedProduct || {};

      const decimals = await traderSdk.getTokenDecimals(nftData);

      setRawPrice(parseUnits(price.toString(), decimals));
      setDisplayPrice(price);
    };

    getPrice().catch(genericErrorCallback);
  }, [purchaseData?.pricePerFragment, purchaseData?.fragments, nft?.enrichedProduct?.nftData?.nftCollectionInfo?.tokens?.length]);

  const { refetch } = useManageFractionsSaleTokenAllowance(nft?.enrichedProduct, displayPrice, setApproved);

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  return (
    <NFTOverlay id="NFTOverlayPurchaseFragment" heading="Buy Fragments from Oracle" nft={nft} sidebarMode="fragment">
      <NFTNumberEntry
        className="nftOverlayGroup"
        name="fragments"
        onChange={onChange}
        heading="How many Fragments would you like to purchase?"
        placeholder="Enter no. of Fragments"
        min={1}
        max={fragmentsAvailable}
      />

      <NFTCheckout
        className="nftOverlayGroup"
        heading="Your Purchase"
        finalButtonText="Purchase"
        nft={nft}
        data={purchaseData}
        execute={purchaseFragments}
        executeApproval={executeApproval}
        excludeKeys={[`expiry`]}
        approved={approved}
        valid={typeof purchaseData?.fragments !== `undefined` && parseInt(purchaseData?.fragments) > 0 && parseFloat(purchaseData?.pricePerFragment) > 0}
        refetch={refetch}
        prepareError={isBuyError ? parseError(prepareError, userData?.address, setOverlayCompletionData) : null}
        approveLoading={executing && !approved}
        actionLoading={executing && approved}
      />

      {!approved && (
        <div className="nftOverlayGroup">
          <NFTIconNote
            background="rgba(255, 255, 255, 0.4)"
            fontClass="caption"
            svg="alert"
            text="By clicking this button, you are authorizing ALTR's smart contract to initiate a blockchain transaction and process funds to the seller on your behalf."
          />
        </div>
      )}
    </NFTOverlay>
  );
};

export default NFTOverlayPurchaseFragment;
