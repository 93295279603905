import React, { useState } from "react";
import styled from "@emotion/styled";
import { useAccount } from "wagmi";
import { useApp } from "~hooks";
import { Button, PriceWidget, SVG } from "~components";
import { thousandCommas } from "~utils/helpers";
import { breakpoint } from "~utils/css";
import { ticketContractHooks } from "~hooks/ticketContractHook";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  opacity: ${({ connected }) => (connected ? 1 : 0.4)};
  pointer-events: ${({ connected }) => (connected ? `auto` : `none`)};

  position: relative;
  padding: 1.25rem 1rem;

  ${breakpoint(`large-tablet`)} {
    padding: 1.5rem;
  }

  .nftPurchaseWhole {
    &Button {
      height: 56px;
      margin-bottom: 0.5rem;

      ${breakpoint(`large-tablet`)} {
        height: auto;
        margin-bottom: 0;
      }
    }

    &Expandable {
      transition: 0.5s var(--cubic-easing) max-height;

      max-height: ${({ expanded }) => `${expanded ? `72px` : `0`}`};
      position: relative;
      overflow: hidden;
      margin-top: 0.75rem;

      ${breakpoint(`large-tablet`)} {
        max-height: none;
        overflow: auto;
        margin-top: 0;
      }
    }

    //

    &Flex {
      ${breakpoint(`large-tablet`)} {
        display: flex;
      }
    }

    &ColLeft {
      width: 100%;
      position: relative;

      &Expander {
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: -0.25rem;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        overflow: hidden;
        z-index: 10;
        border: 1px solid var(--color-khaki-100);
        color: var(--color-khaki-100);

        svg {
          width: 1rem;
        }

        ${breakpoint(`large-tablet`)} {
          display: none;
        }
      }

      ${breakpoint(`large-tablet`)} {
        width: calc(60% - 1rem);
        margin-right: 1rem;
      }

      &Flex {
        ${breakpoint(`large-tablet`)} {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    &ColRight {
      width: 100%;
      position: relative;
      margin-top: 2.5rem;

      ${breakpoint(`large-tablet`)} {
        width: 40%;
        margin-top: 0;
      }

      &Flex {
        ${breakpoint(`large-tablet`)} {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    //

    &Controls {
      display: block;
      margin-top: 2.5rem;
      gap: 0 0.5rem;

      ${breakpoint(`large-tablet`)} {
        margin-top: 2rem;
        display: flex;
        align-items: stretch;
      }

      & > div {
        width: 100%;

        ${breakpoint(`large-tablet`)} {
          flex-basis: 50%;
        }
      }

      button,
      button div {
        ${breakpoint(`large-tablet`)} {
          height: 100%;
        }
      }
    }

    &Subheading,
    &Body {
      color: var(--color-mono-60);
    }

    &Subheading {
      color: var(--color-mono-60);

      ${breakpoint(`large-tablet`)} {
        margin-top: 0.5rem;
      }
    }

    &Body {
      margin-top: 1rem;
    }

    &PriceSubheading {
      margin-bottom: 4px;
    }
  }
`;

/** ============================================================================
 * @component
 * todo: animations
 * @return {node}
 */
const EventPurchaseWhole = ({
  nft,
  nftStatus,
  ticketAvailable,
  flex = false,
  visible = true,
  //
  heading,
  subheading,
  body,
  background = `beige`
}) => {
  const { isConnected } = useAccount();
  const { setActiveOverlay, setConnectorActive, userIsLoggedIn } = useApp();
  const { useGetTokenIdToPrice } = ticketContractHooks();
  const [expanded, setExpanded] = useState(false);

  const { data: ticketPrice } = useGetTokenIdToPrice(nft.enrichedEvent?.contractAddress, `0`);

  const initialSale = nftStatus?.is([`OnSale`]);

  return (
    <Container
      expanded={expanded}
      style={{
        background: `var(--color-${background})`,
        display: visible ? `block` : `none`
      }}
    >
      <div className="nftPurchaseWhole">
        <div className="nftPurchaseWholeFlex">
          <header className={`nftPurchaseWholeColLeft ${flex ? `nftPurchaseWholeColLeftFlex` : ``}`}>
            <button type="button" onClick={() => setExpanded(!expanded)} className="nftPurchaseWholeColLeftExpander">
              <SVG svg={expanded ? `minus` : `plus`} />
            </button>
            {heading && <h3 className="b1">{heading}</h3>}

            <div className="nftPurchaseWholeExpandable">
              {subheading && <h4 className="b2 nftPurchaseWholeSubheading">{subheading}</h4>}

              {body && <p className="caption nftPurchaseWholeBody">{body}</p>}
            </div>
          </header>

          <div className={`nftPurchaseWholeColRight ${flex ? `nftPurchaseWholeColRightFlex` : ``}`}>
            {ticketPrice && (
              <>
                <h4 className="nftPurchaseWholePriceSubheading caption">PRICE FROM:</h4>
                <PriceWidget displayPrice={thousandCommas(ticketPrice / 1e6)} fontClass="h2" />
              </>
            )}
          </div>
        </div>

        {initialSale && (
          <div className="nftPurchaseWholeFlex nftPurchaseWholeControls">
            <Button
              onClick={() => {
                if (!userIsLoggedIn || !isConnected) {
                  setConnectorActive(true);
                } else {
                  setActiveOverlay(`EventOverlayPurchase`);
                }
              }}
              fluid
              colorTheme="dark"
              disabled={ticketAvailable === 0}
            >
              <span className="b1">Buy</span>
            </Button>

            <Button
              onClick={() => {
                setActiveOverlay(`EventOverlayPurchaseFiat`);
              }}
              fluid
              variant="feature"
              colorTheme="dark"
              disabled={ticketAvailable === 0}
            >
              <span className="b1">Buy with FIAT</span>
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};

export default EventPurchaseWhole;
