import cn from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { CtaLink, Carousel } from "~components";
import { useWindowDimensions } from "~hooks";

const Icons = ({ data: { eyebrow, title, description, icons, background } }) => {
  const { isMobile, isTablet, screen } = useWindowDimensions();
  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: `start`,
    loop: false,
    slidesToScroll: 1
  });

  // ---------------------------------------------------------------------------
  // context / ref / state
  const [carouselPage, setCarouselPage] = useState(0);
  const [mounted, setMounted] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on(`select`, () => setCarouselPage(emblaApi.selectedScrollSnap()));
  }, [emblaApi]);

  useEffect(() => {
    if (mounted || !screen) {
      return;
    }

    setTimeout(() => {
      setMounted(true);
    }, 100);
  }, [mounted, screen]);

  if (!screen) return null;

  return (
    <div className="bg-wrapper dark" style={{ "--wrapper-background": background || `#000` }}>
      <section className={cn(`iconsSlice`, `container`, `narrow`, `dark`)}>
        <div className="content">
          {!!eyebrow && <strong className="tag">{eyebrow}</strong>}
          {!!title && <h2>{title}</h2>}
          {!!description && <p>{description}</p>}
        </div>
        {!(isMobile || isTablet) ? (
          <ul className="icons">
            {icons.map((icon) => (
              <li key={icon._key}>
                <div className="heading">
                  <strong className="caption">
                    <CtaLink cta={icon.link}>{icon.eyebrow}</CtaLink>
                  </strong>
                  <h3>
                    <CtaLink cta={icon.link}>{icon.title}</CtaLink>
                  </h3>
                </div>
                <CtaLink className="imageWrapper" cta={icon.link}>
                  <GatsbyImage className="image" image={icon.image.asset.gatsbyImageData} alt={icon.image.altText || ``} />
                </CtaLink>
              </li>
            ))}
          </ul>
        ) : (
          <div className="mobileCarousel">
            <Carousel
              embla={{
                api: emblaApi,
                ref: emblaRef
              }}
              className=""
              slidesPerView={1}
              gap={15}
              slides={() =>
                icons.map((icon) => (
                  <>
                    <div key={icons._key} className="heading">
                      <strong className="caption">
                        <CtaLink cta={icon.link}>{icon.eyebrow}</CtaLink>
                      </strong>
                      <h3>
                        <CtaLink cta={icon.link}>{icon.title}</CtaLink>
                      </h3>
                    </div>
                    <CtaLink className="imageWrapper" cta={icon.link}>
                      <GatsbyImage className="image" image={icon.image.asset.gatsbyImageData} alt={icon.image.altText || ``} />
                    </CtaLink>
                  </>
                ))
              }
            />
            <div className="dots">
              {icons.map((icon, index) => (
                <div key={`dots_${icon._key}`} className={cn(`dot`, carouselPage === index && `active`)} />
              ))}
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Icons;
