import React, { useEffect, useState } from "react";
import {
  formatDateTime,
  genericErrorCallback,
  formatCryptoCurrency,
  walletAddressAreEqual,
  formatStablecoin,
  BLOCK_EXPLORER_ADDRESS,
  FRACTIONS_SALE_ADDRESS,
  BLOCK_EXPLORER,
  truncateAddress
} from "~utils/helpers";
import useApp from "~hooks/useApp";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import Table from "~components/Common/Table";
import LoadingSpinner from "~components/Common/LoadingSpinner";
import Button from "~components/Common/Button";
import SVG from "~components/Common/SVG";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";

/** ============================================================================
 * @component
 */
const ProfileItemHistory = ({ item }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const { userData } = useApp();
  const { graph } = useExternalIntegrations();

  // ---------------------------------------------------------------------------
  // context / ref / state
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState();

  // ---------------------------------------------------------------------------
  // methods
  const parseTransaction = (id, from, to, timestamp, nftType, amount, price) => ({
    id,
    fromUnformatted: from,
    toUnformatted: to,
    from: walletAddressAreEqual(userData?.address, from) ? `You` : truncateAddress(from),
    to: walletAddressAreEqual(userData?.address, to) ? `You` : truncateAddress(to),
    date: formatDateTime(parseInt(timestamp) * 1000, `DD-MM-YYYY HH:mm`),
    nftType,
    amount,
    price: price && `${formatCryptoCurrency(formatStablecoin(price, true))}`,
    description: price ? (walletAddressAreEqual(userData?.address, from) ? `Sale` : `Purchase`) : `Transfer`
  });

  const parseSaleAddresses = (transaction, saleAddresses) => {
    if (saleAddresses.reduce((prev, current) => current?.toLowerCase() === transaction?.from?.toLowerCase() || prev, false)) {
      transaction.from = `Altr Sale Contract`;
      transaction.description = `Purchase`;
    }

    return transaction;
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  const { getPriceWithFees } = useParseTraderOrder();

  useEffect(() => {
    setIsLoading(true);

    const fetchTransactions = async () => {
      const saleAddresses = [FRACTIONS_SALE_ADDRESS]; // FIXME: add an endpoint to get all sale contract addresses
      const txs = await graph.getAllTransactionsByNftIdAndAddress(userData?.address, item.contract.address, item.tokenId, `desc`);

      setIsLoading(false);

      setTransactions(
        txs.reduce((accum, curr) => {
          const from = curr?.order ? (curr.order.direction ? curr.order.maker.id : curr.order.taker.id) : curr.from.id;
          const to = curr?.order ? (!curr.order.direction ? curr.order.maker.id : curr.order.taker.id) : curr.to.id;

          let parsedTransaction = parseTransaction(
            curr.id,
            from,
            to,
            curr.timestamp,
            curr?.nftTokenType || (curr?.erc1155?.length > 0 ? `ERC1155` : `ERC721`),
            Number(curr?.nftTokenAmount) ?? Number(curr?.erc1155Quantity?.[0]) ?? 1,
            walletAddressAreEqual(userData?.address, from) ? curr?.erc20TokenAmount : getPriceWithFees(curr)
          );

          parsedTransaction = parseSaleAddresses(parsedTransaction, saleAddresses);

          accum.push(parsedTransaction);
          return accum;
        }, [])
      );
    };
    fetchTransactions().catch(genericErrorCallback);
  }, []);

  // ---------------------------------------------------------------------------
  // render

  if (!item) {
    return null;
  }

  return (
    <section className="profileItemHistory">
      <div className="profileList">
        <h2>Transaction History</h2>
        {!!transactions?.length && !isLoading && (
          <Table>
            {transactions.map((transaction) => {
              const additionalContent = (
                <>
                  {transaction.nftType === `ERC1155` && !!transaction.amount && (
                    <dl>
                      <dt>Fragments:</dt>
                      <dd>{transaction.amount || `-----`}</dd>
                    </dl>
                  )}
                  {!!transaction.price && (
                    <dl>
                      <dt>Price:</dt>
                      <dd>{transaction.price || `-`} USDt</dd>
                    </dl>
                  )}
                  <dl>
                    <dt>Transaction ID:</dt>
                    <dd className="link">
                      <Button variant="text" href={`${BLOCK_EXPLORER}${transaction.id}`}>
                        <span>{transaction.id}</span>
                        <SVG svg="chain" />
                      </Button>
                    </dd>
                  </dl>
                </>
              );

              return (
                <Table.Row className="profileListItem" key={`transaction_${transaction.id}`} additionalContent={additionalContent}>
                  <Table.Cell as="dl">
                    <dt>Date:</dt>
                    <dd>{transaction.date}</dd>
                  </Table.Cell>
                  <Table.Cell as="dl">
                    <dt>Description:</dt>
                    <dd>{transaction.description}</dd>
                  </Table.Cell>
                  <Table.Cell as="dl">
                    <dt>From:</dt>
                    <dd className="link">
                      <Button variant="text" href={`${BLOCK_EXPLORER_ADDRESS}${transaction.fromUnformatted}`}>
                        <span>{transaction.from}</span>
                        <SVG svg="chain" />
                      </Button>
                    </dd>
                  </Table.Cell>
                  <Table.Cell as="dl">
                    <dt>To:</dt>
                    <dd className="link">
                      <Button variant="text" href={`${BLOCK_EXPLORER_ADDRESS}${transaction.toUnformatted}`}>
                        <span>{transaction.to}</span>
                        <SVG svg="chain" />
                      </Button>
                    </dd>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table>
        )}
        {isLoading && <LoadingSpinner className="loader" isInline />}
      </div>
    </section>
  );
};

export default ProfileItemHistory;
