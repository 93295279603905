import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { breakpoint } from "~utils/css";
import { LinkButton, OracleInfoCard, SlicePadding, Button } from "~components";
import { OracleCategories } from "~dApp/models/oracle/OracleCategories";
import OracleCardClusterCarousel from "./components/OracleCardClusterCarousel";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  position: relative;
  background: var(--color-black);

  .oracleCardCluster {
    &BgImage {
      position: absolute;
      inset: 0;
    }

    &Gradient {
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.15) 20%, rgba(0, 0, 0, 0.15) 80%, rgba(0, 0, 0, 1) 100%);
    }
  }
`;

const Content = styled.div`
  position: relative;
  color: var(--color-white);

  .oracleCardCluster {
    &Tag {
      margin-bottom: 0.25rem;

      ${breakpoint(`large-tablet`)} {
        margin-bottom: 1.5rem;
      }
    }

    &Header {
      margin-bottom: 1.5rem;

      ${breakpoint(`large-tablet`)} {
        margin-bottom: 2rem;
      }
    }

    &Cta,
    &Text {
      margin-bottom: 1.5rem;
    }

    &Oracles {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 1.5rem;

      ${breakpoint(`large-tablet`)} {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &CategoryFilter {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      > * {
        margin-left: 0.5rem;
      }
    }
  }
`;

/** ============================================================================
 * @component
 */
const OracleCardCluster = ({ data: { header, text, cta, oracles, background, backgroundImage, oraclesFilter, carousel } }) => {
  const [categoryFilter, setCategoryFilter] = useState(null);

  const manageCategoryFilter = (category) => {
    setCategoryFilter(category === categoryFilter ? null : category);
  };

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });

    if (params.category) {
      setCategoryFilter(params.category);
    }
  }, []);

  return (
    <Container>
      {background === `image` && (
        <>
          <GatsbyImage className="oracleCardClusterBgImage" image={backgroundImage.asset.gatsbyImageData} alt="" />
          <div className="oracleCardClusterGradient" />
        </>
      )}
      <SlicePadding>
        <Content>
          <div className="tag oraclaCardClusterTag">Oracles</div>
          <h3 className="h1 oracleCardClusterHeader">{header}</h3>
          <p className="b2 oracleCardClusterText">{text}</p>
          <LinkButton className="oracleCardClusterCta" link={cta} />
          {oraclesFilter && (
            <div className="oracleCardClusterCategoryFilter">
              Filter category:
              {Object.values(OracleCategories).map((category, index) => {
                category = category.toLowerCase();
                return (
                  <Button
                    key={`filter-button-${index}`}
                    className="oracleCardClusterCategoryFilterButton"
                    variant={category === categoryFilter ? `primary` : `secondary`}
                    colorTheme="light"
                    onClick={() => manageCategoryFilter(category)}
                  >
                    {category.toLocaleUpperCase()}
                  </Button>
                );
              })}
            </div>
          )}
          {carousel ? (
            <OracleCardClusterCarousel oracles={oracles} categoryFilter={categoryFilter} />
          ) : (
            <div className="oracleCardClusterOracles">
              {oracles
                ?.filter((oracle) => (categoryFilter ? oracle.category.find((category) => category === categoryFilter) : true))
                .map((oracle, i) => (
                  <OracleInfoCard
                    key={i}
                    title={oracle.title}
                    specialty={oracle.specialty}
                    currentListing={oracle.currentListing}
                    image={oracle.image}
                    description={oracle.description}
                    slug={oracle.slug}
                    category={oracle.category}
                  />
                ))}
            </div>
          )}
        </Content>
      </SlicePadding>
    </Container>
  );
};

export default OracleCardCluster;
