import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { gsap } from "gsap";
import { HTMLVideo, Image, CryptoScrambler, SVG } from "~components";
import { useDevice } from "~hooks";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  width: 100vw;
  height: 100svh;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  background: ${({ background }) => background || `#ffffff`};
  color: ${({ color }) => color || `#000000`};

  #liquid-banner-light,
  #liquid-banner-dark {
    animation: 2s var(--cubic-easing) appear forwards;
    animation-delay: 0.25s;
    opacity: 0;
  }

  ${breakpoint(`tablet`)} {
    height: 87.75vw;
  }

  ${breakpoint(`large-tablet`)} {
    height: 52.9vw;
    min-height: 700px;
    max-height: 1000px;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 2.5rem;
  color: var(--color-white);

  text-align: center;
  opacity: 0;

  ${breakpoint(`large-tablet`)} {
    align-items: center;
    padding-top: 0;
    text-align: left;
  }

  animation: var(--animation-appear-up);
  animation-delay: 1s;
`;

const BackgroundMedia = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  ${breakpoint(`large-tablet`)} {
    animation: 2s var(--animation-appear-scale-down);
    animation-delay: 1s;

    transform: scale(1.2);
    opacity: 0;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-tems: center;
  justify-content: center;
`;

const VideoWrapper = styled.div`
  width: calc(100% + 2rem);
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -1rem;
  z-index: 10;

  transform: translateZ(0);
  backface-visibility: hidden;

  ${breakpoint(`tablet`)} {
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`;

const AltrVideo = styled(HTMLVideo)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  height: 100%;

  display: block;
  object-fit: cover;
`;

/** ============================================================================
 * @component
 */
const ALTRBanner = ({ data: { backgroundColour, media, headings } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { screen } = useDevice();

  const containerRef = useRef();

  // ---------------------------------------------------------------------------
  // variables

  // const videoUrl = `/videos/altr-banner-desktop.mp4`;
  const videoUrl = media?.video?.desktop;
  const videoUrlXS = media?.video?.mobile || media?.video?.desktop;
  const videoUrlSM = media?.video?.tablet || media?.video?.mobile || media?.video?.desktop;

  // ---------------------------------------------------------------------------
  // methods

  // todo: only works at the top of the screen
  const handleScroll = () => {
    const rect = containerRef.current.getBoundingClientRect();
    const scrollTop = parseInt(document.scrollingElement.scrollTop);

    if (scrollTop > rect?.height) {
      return;
    }

    gsap.to(`.altr-banner`, { duration: 0, y: parseInt(scrollTop / 7) });
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (typeof window === `undefined` || !containerRef?.current) {
      return () => {};
    }

    window.addEventListener(`scroll`, handleScroll, false);

    return () => {
      window.removeEventListener(`scroll`, handleScroll, false);
    };
  }, [containerRef]);

  // ---------------------------------------------------------------------------
  // render

  let mediaJSX;

  if (media?.type === `image`) {
    mediaJSX = <Image image={media?.image} />;
  } else {
    switch (screen) {
      case `tablet`:
        mediaJSX = (
          <VideoContainer>
            <VideoWrapper>
              <AltrVideo id="altr-banner-sm" autoplay muted loop playsInline src={videoUrlSM} />
            </VideoWrapper>
          </VideoContainer>
        );
        break;

      case `mobile`:
        mediaJSX = (
          <VideoContainer>
            <VideoWrapper>
              <AltrVideo id="altr-banner-xs" autoplay muted loop playsInline src={videoUrlXS} />
            </VideoWrapper>
          </VideoContainer>
        );
        break;

      default:
        mediaJSX = (
          <VideoContainer>
            <VideoWrapper>
              <AltrVideo id="altr-banner" className="altr-banner" autoplay muted loop playsInline src={videoUrl} />
            </VideoWrapper>
          </VideoContainer>
        );

        break;
    }
  }

  return (
    <>
      <Container ref={containerRef} role="presentation" background={backgroundColour?.hex}>
        <Overlay>
          <h1
            className="h1"
            css={css`
              ${breakpoint(`large-tablet`)} {
                display: flex;
                font-weight: 300;
              }
            `}
          >
            <span
              css={css`
                display: block;
                text-align: center;

                ${breakpoint(`large-tablet`)} {
                  margin-left: -1.75rem;
                  margin-right: 1rem;
                  text-align: left;
                }
              `}
            >
              Don’t just
            </span>

            <span
              css={css`
                display: block;
                text-align: center;
                white-space: nowrap;

                .dummy {
                  opacity: 0.3;
                }

                ${breakpoint(`large-tablet`)} {
                  width: 120px;
                  text-align: left;
                }
              `}
            >
              <CryptoScrambler phrases={headings} />
            </span>
          </h1>

          <SVG
            svg="logo"
            css={css`
              animation: var(--animation-appear-up);
              animation-delay: 2s;
              opacity: 0;

              height: 40px;
              margin-top: 0.75rem;

              ${breakpoint(`tablet`)} {
                height: 46px;
                margin-top: 1rem;
              }

              ${breakpoint(`large-tablet`)} {
                height: 65px;
              }
            `}
            fill="white"
          />
        </Overlay>

        <BackgroundMedia>{mediaJSX}</BackgroundMedia>
      </Container>
    </>
  );
};

export default ALTRBanner;
