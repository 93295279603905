import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";

const TabsContext = createContext();

export const useTabsContext = () => {
  const value = useContext(TabsContext);

  return value;
};

const TabsContextProvider = ({ children, defaultActiveTab, activeTab: propsActiveTab, onChange, colorTheme = `dark` }) => {
  const [activeTab, setActiveTab] = useState(propsActiveTab || defaultActiveTab);

  const changeActiveTab = useCallback(
    (tabId) => {
      if (!propsActiveTab) setActiveTab(tabId);
      if (onChange) onChange(tabId);
    },
    [onChange, setActiveTab]
  );

  const contextValue = useMemo(() => ({
    activeTab,
    setActiveTab: changeActiveTab,
    colorTheme
  }));

  useEffect(() => {
    if (propsActiveTab) setActiveTab(propsActiveTab);
  }, [propsActiveTab]);

  return <TabsContext.Provider value={contextValue}>{children}</TabsContext.Provider>;
};

export default TabsContextProvider;
