import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { checkRightChain, checkUserIsLoggedIn, genericErrorCallback, walletAddressAreEqual } from "~utils/helpers";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import useApp from "~hooks/useApp";
import NFTActivityList from "../NFTActivityList";

const NFTOwnersList = ({ id, enrichedProduct, nftStatus, setTabsLength, activeId }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const { userData } = useApp();
  const { graph } = useExternalIntegrations();
  const { chain } = useAccount();
  const { setWrongChainOverlayData, setActiveOverlay, setOverlayActivityData, activeOverlay, userIsLoggedIn, setOverlayCompletionData, setConnectorActive } =
    useApp();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [nftOwners, setNftOwners] = useState();

  // ---------------------------------------------------------------------------
  // methods

  const isSoldOut = nftStatus?.is(`SoldOut`);

  const handleActivityItemClick = (row) => {
    if (!checkUserIsLoggedIn(userIsLoggedIn, setOverlayCompletionData, setConnectorActive)) return;
    if (!checkRightChain(chain?.id, setWrongChainOverlayData)) return;
    setOverlayActivityData({
      owner: row,
      order: row
    });
    const isOwner = walletAddressAreEqual(userData?.address, row?.address);
    if (isOwner) {
      if (isSoldOut) {
        setActiveOverlay(`NFTOverlayCreateWholeSellOrder`);
      } else {
        setActiveOverlay(`NFTOverlayCreateFragmentSellOrder`);
      }
    } else if (isSoldOut) {
      setActiveOverlay(`NFTOverlayOfferWhole`);
    } else {
      setActiveOverlay(`NFTOverlayPurchaseFragmentFromOwner`);
    }
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const setOwners = async () => {
      const owners = await graph.getNftOwners(enrichedProduct?.nftData, isSoldOut);
      const sortedOwners = owners.owners.sort((a, b) => parseInt(b.fractions) - parseInt(a.fractions));
      if (nftStatus?.is(`SoldOut`)) {
        setNftOwners([owners.erc721Owner]);
        setTabsLength(id, 1);
      } else if (sortedOwners) {
        setNftOwners(sortedOwners);
        setTabsLength(id, sortedOwners.length);
      }
    };
    setOwners().catch(genericErrorCallback);
  }, [
    enrichedProduct?.nftData?.nftCollectionInfo?.collectionAddress,
    enrichedProduct?.nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress,
    enrichedProduct?.nftData?.tokenId,
    enrichedProduct?.nftData?.saleId,
    activeOverlay,
    nftStatus?.status
  ]);

  // ---------------------------------------------------------------------------
  // render
  if (activeId !== `Current Owner` && activeId !== `Owner’s Board`) {
    return null;
  }

  return (
    <NFTActivityList
      nftStatus={nftStatus}
      id={isSoldOut ? `Current Owner` : `Owner’s Board`}
      rows={nftOwners}
      buttonLabel="Make an offer"
      alternativeButtonLabel={`Sell ${!isSoldOut && `fragments`}`}
      handleActivityItemClick={handleActivityItemClick}
    />
  );
};

export default NFTOwnersList;
