import React from "react";
import { CountdownClock, PgWrapper, PgItem } from "~components";

const CountdownClockPG = () => (
  <PgWrapper title="CountdownClock" itemWidth="400px">
    <PgItem>
      <CountdownClock className="h1" launchDate="2028-02-21T23:23:44.249Z" />
    </PgItem>
  </PgWrapper>
);

export default CountdownClockPG;
