import { NftOrderV4 } from "@lucidao-developer/nft-swap-sdk";
import { useMemo } from "react";
import { formatStablecoin, humanizeDuration, thousandCommas, truncateAddress, walletAddressAreEqual } from "~utils/helpers";
import useApp from "./useApp";

export const useParseTraderOrder = (order?: NftOrderV4) => {
  const { userData } = useApp() as any;

  const makeDisplayPrice = (rawPrice: bigint | string) => {
    return thousandCommas(Number(formatStablecoin(rawPrice || `0`)).toFixed(2));
  };

  const getPriceWithFees = (order: NftOrderV4) => {
    return BigInt(order.erc20TokenAmount || 0) + (order.fees || []).reduce((acc, fee) => acc + BigInt(fee.amount), 0n);
  };

  const getPriceWithMakerFee = (order: NftOrderV4) => {
    return BigInt(order.erc20TokenAmount || 0) + (order.fees || []).reduce((acc, fee) => acc + BigInt(fee.amount), 0n) / 2n;
  };

  const getPriceWithTakerFee = (order: NftOrderV4) => {
    return BigInt(order.erc20TokenAmount || 0) + (order.fees || []).reduce((acc, fee) => acc + BigInt(fee.amount), 0n) / 2n;
  };

  const calculatePercentageDifference = (a: NftOrderV4, b: NftOrderV4) => {
    return (((BigInt(a?.erc20TokenAmount || 0) - BigInt(b?.erc20TokenAmount || 0)) * 100n) / BigInt(b?.erc20TokenAmount || 1)).toString() + "%";
  };

  const getEtaFromExpiry = (order: NftOrderV4) => {
    return `In ${humanizeDuration(Number(order.expiry || 0) * 1000 - Date.now())}`;
  };

  const getDurationFromExpiry = (order: NftOrderV4) => {
    return Number(order.expiry || 0) * 1000 - Date.now();
  };

  return useMemo(() => {
    if (!order)
      return {
        getPriceWithFees,
        calculatePercentageDifference,
        getEtaFromExpiry,
        getDurationFromExpiry,
        getPriceWithMakerFee,
        getPriceWithTakerFee,
        makeDisplayPrice,
        price: {}
      };

    const fees = (order.fees || []).reduce((acc, fee) => acc + BigInt(fee.amount), 0n);
    const feePercentage = order.erc20TokenAmount == 0n ? 0n : (fees * 100n) / BigInt(order.erc20TokenAmount || 1);

    return {
      price: {
        raw: order.erc20TokenAmount || `0`,
        priceWithMakerFee: getPriceWithMakerFee(order),
        priceWithTakerFee: getPriceWithTakerFee(order),
        priceWithFees: getPriceWithFees(order),
        displayPrice: makeDisplayPrice(order.erc20TokenAmount),
        displayPriceWithFees: makeDisplayPrice(getPriceWithFees(order)),
        displayPriceWithMakerFee: makeDisplayPrice(getPriceWithMakerFee(order)),
        displayPriceWithTakerFee: makeDisplayPrice(getPriceWithTakerFee(order)),
        fees,
        feePercentage,
        makerFeePercentage: (feePercentage / 2n).toString(),
        takerFeePercentage: (feePercentage / 2n).toString()
      },
      duration: getDurationFromExpiry(order),
      eta: getEtaFromExpiry(order),
      from: walletAddressAreEqual(userData?.address, order.maker) ? `YOU` : truncateAddress(order.maker, 4, 6),
      getPriceWithFees,
      calculatePercentageDifference,
      getEtaFromExpiry,
      getDurationFromExpiry,
      getPriceWithMakerFee,
      getPriceWithTakerFee,
      makeDisplayPrice
    };
  }, [order?.nonce]);
};
