import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Button } from "~components";
import { hoverable } from "~utils/css";
import { ticketContractHooks } from "~hooks/ticketContractHook";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import LoginService from "~services/auth";
import useApp from "~hooks/useApp";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  width: 100%;

  span {
    margin-left: 0.5rem;
  }

  p {
    margin: 1rem 0 2rem;
    color: var(--color-mono-60);
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const EventDoPurchase = ({ onStart = () => {}, onComplete = () => {}, onError = () => {}, nft, formData, setFormData, selected }) => {
  const { enrichedEvent } = nft;

  const { useBuyTicket } = ticketContractHooks();
  const {
    write: doBuyTicket,
    prepareError,
    writeError,
    txData,
    isWriteLoading,
    isSuccess,
    writeIsError
  } = useBuyTicket(enrichedEvent.contractAddress, BigInt(selected.id), 1n);

  const { api } = useExternalIntegrations();

  const { userIsLoggedIn } = useApp();

  const loginService = new LoginService();

  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);

  // ---------------------------------------------------------------------------
  // methods
  const onChange = (e) => {
    const { name, type, value, checked } = e.target;

    if (type === `checkbox`) {
      setFormData({
        ...formData,
        [name]: checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // @externalintegration
    doBuyTicket();
  };

  useEffect(() => {
    if (!(writeError || prepareError)) {
      setError(null);
    }
    if (prepareError) {
      let errorMsg = `Error`;

      let searchError;
      if (prepareError.message?.includes(`TicketContract`)) {
        searchError = `TicketContract`;
      } else if (prepareError.message?.includes(`ERC20`)) {
        searchError = `ERC20`;
      }
      if (searchError) {
        const wordArray = prepareError.message.split(` `);
        const index = wordArray.findIndex((value) => value === `${searchError}:`);

        if (index) {
          errorMsg = ``;
          let i = 0;
          for (i = index + 1; !wordArray[i].includes(`"`); i++) {
            errorMsg += wordArray[i];
            errorMsg += ` `;
          }
          errorMsg += wordArray[i];
          errorMsg = errorMsg.slice(0, errorMsg.length - 2);
          errorMsg = errorMsg.slice(0, 1).toUpperCase() + errorMsg.slice(1);
        }
      }
      setError(errorMsg);
    }
    if (writeError) {
      setError(writeError.title);
    }
  }, [writeError, prepareError]);

  useEffect(() => {
    setUser(loginService.getUser());
  }, [userIsLoggedIn]);

  useEffect(() => {
    const processingManager = async () => {
      if (isWriteLoading) {
        onStart();
      }
      if (writeIsError) {
        onError();
      }
      if (isSuccess) {
        await api.sendEventSalePurchaseData({
          eventGuid: nft.enrichedEvent.identifier,
          email: user ? user.emailAddress : ``,
          id: selected.id,
          transactionHash: txData?.transactionHash
        });
        onComplete(nft.enrichedEvent.identifier, selected.id);
      }
    };

    processingManager().catch((e) => console.error(e));
  }, [isWriteLoading, isSuccess, writeIsError, txData?.transactionHash]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container>
      <form onSubmit={onSubmit}>
        <label htmlFor="email">
          <input name="accepted" onChange={onChange} type="checkbox" checked={formData?.accepted} />

          <span className="b2">
            Accept &nbsp;
            <a
              href={`${process.env.GATSBY_SITE_URL}/terms-and-conditions/event/`}
              css={css`
                pointer-events: inherit;
                text-decoration: underline;
                color: var(--color-primary-orange-100);

                ${hoverable} {
                  &:hover {
                    text-decoration: none;
                  }
                }
              `}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Event terms and conditions
            </a>
          </span>
        </label>

        <p className="b2">Click ‘Purchase’ to buy the selected ticket</p>

        <Button buttonType="submit" colorTheme="dark" fluid variant="feature" disabled={formData?.accepted !== true || error}>
          <span className="b1">{error || `Purchase`}</span>
        </Button>
      </form>
    </Container>
  );
};

export default EventDoPurchase;
