/* eslint-disable react/no-danger */
import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import striptags from "striptags";
import { Image } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  width: 100%;
  position: relative;
  background: var(--color-mono-10);
  color: var(--color-mono-100);
`;

/** ============================================================================
 * OracleScroll
 * @component
 * Topmost slice loaded by /product/[sanityProduct.slug.current] routes.
 */
const OracleScroll = ({ data }) => (
  <Container>
    {/* dog: full-bleed, so no <Grid /> */}
    <div
      css={css`
        width: 100%;
        position: relative;
        display: flex;
      `}
    >
      <figure
        css={css`
          width: 50%;
          flex-basis: 50%;
          height: 50vw;
          position: sticky;
          top: 0;
          left: 0;
          overflow: hidden;
        `}
      >
        {data?.logoBlackUrl && (
          <Image
            css={css`
              width: 100%;
              height: 100%;
              position: relative;
              display: block;
              object-fit: cover;
            `}
            image={data.logoBlackUrl}
          />
        )}
      </figure>

      {/* // */}

      <div
        css={css`
          width: 50%;
          flex-basis: 50%;
          position: relative;
        `}
      >
        <article
          css={css`
            width: 100%;
            height: 50vw;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: left;

            padding: 0 8vw; // todo: snap to <Grid />
          `}
        >
          <div
            css={css`
              animation: var(--animation-appear-up);
              animation-delay: 750ms;

              transform: translate3d(0, 1rem, 0);
              opacity: 0;

              width: 100%;
              position: relative;
            `}
          >
            <h1
              css={css`
                margin-bottom: 2rem;
              `}
              className="h2"
            >
              {data?.companyName}
            </h1>

            {data?.description && (
              <>
                <p className="b1">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: striptags(data.description)
                    }}
                  />
                </p>
              </>
            )}
          </div>
        </article>

        <div
          css={css`
            position: relative;
            background: var(--color-white);
            padding: 5rem 0;
          `}
        >
          <article
            css={css`
              position: relative;

              padding: 0 8vw; // todo: snap to <Grid />
            `}
          >
            {data?.cavaeuServiceAndFees && (
              <div>
                <h3
                  css={css`
                    margin-bottom: 1rem;
                    text-transform: uppercase;
                  `}
                  className="caption"
                >
                  Service and Fees
                </h3>

                <p className="b1">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: striptags(data.cavaeuServiceAndFees)
                    }}
                  />
                </p>
              </div>
            )}

            {data?.deliveryInformation && (
              <div
                css={css`
                  margin-top: 4rem;
                `}
              >
                <h3
                  css={css`
                    margin-bottom: 1rem;
                    text-transform: uppercase;
                  `}
                  className="caption"
                >
                  Delivery Information
                </h3>

                <p className="b1">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: striptags(data.deliveryInformation)
                    }}
                  />
                </p>
              </div>
            )}

            {data?.nationality && (
              <div
                css={css`
                  margin-top: 4rem;
                `}
              >
                <h3
                  css={css`
                    margin-bottom: 1rem;
                    text-transform: uppercase;
                  `}
                  className="caption"
                >
                  Nationality
                </h3>

                <p className="b1">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: striptags(data.nationality)
                    }}
                  />
                </p>
              </div>
            )}

            {data?.website && (
              <div
                css={css`
                  margin-top: 4rem;
                `}
              >
                <h3
                  css={css`
                    margin-bottom: 1rem;
                    text-transform: uppercase;
                  `}
                  className="caption"
                >
                  Website
                </h3>

                <p className="b1">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: striptags(data.website)
                    }}
                  />
                </p>
              </div>
            )}
          </article>
        </div>
      </div>
    </div>
  </Container>
);

export default OracleScroll;
