import React from "react";
import cn from "classnames";

/** ============================================================================
 * CurrencyBadge
 * @component
 */
const CurrencyBadge = ({ className, small = false, text, color = `black`, variant = `primary` }) => (
  <span style={{ "--color": `var(--color-${color})` }} className={cn(`currencyBadge`, className, small ? `small caption` : `tag`, variant)}>
    {text}
  </span>
);

export default CurrencyBadge;
