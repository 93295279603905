import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Image, LinkButton, Grid } from "~components";
import { breakpoint } from "~utils/css";

const Container = styled.section`
  display: flex;
  flex-wrap: wrap-reverse;
  font-family: "Sohne Leicht", sans-serif;
  gap: 2.5rem 7.5rem;
  max-width: 1920px;
  padding: 2.5rem 2rem;
  margin: 0 auto;

  ${breakpoint(`tablet`)} {
    padding: 1.5rem 8rem 4.125rem;
  }

  ${breakpoint(`large-tablet`)} {
    padding: 4.125rem 8rem;
  }

  ${breakpoint(`large-tablet`, `max`)} {
    justify-content: center;
  }

  .grid {
    grid-template-columns: repeat(10, minmax(0, 1fr));
    gap: 1.5rem;
  }

  h2 {
    margin-bottom: 1rem;
    line-height: 1;
  }

  .description {
    color: #797878;
    line-height: 1.625;
    margin-top: 3.75rem;
  }

  .content {
    grid-column: 1/-1;

    ${breakpoint(`small-desktop`)} {
      grid-column: 1/6;
    }
  }

  .image {
    grid-column: 1/-1;
    justify-self: center;
    max-width: 20rem;

    ${breakpoint(`small-desktop`)} {
      align-self: flex-start;
      grid-column: 7/10;
      max-width: unset;
    }
  }

  .link {
    font-weight: 600;
    text-decoration: underline;
    line-height: 1.25;
  }
`;

const BadgeContainer = styled.div`
  margin: 1.25rem 0;
`;

const Badge = styled.span`
  background: #ffffff;
  color: #1c1b1d;
  font-family: "Sohne Mono", monospace;
  font-weight: 0.75rem;
  padding: 0.5rem;
  margin-right: 0.625rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px 0px rgba(0, 0, 0, 0.35);
  line-height: 1.25;
`;

const InfoBlock = ({ className, title, link, badges, description, image }) => (
  <Container className={className}>
    <Grid className="grid">
      <div className="content">
        <h2 className="h1">{title}</h2>
        {!!link && <LinkButton className="link" link={link} variant="text" colorTheme="dark" />}
        {!!badges && !!badges.length && (
          <BadgeContainer>
            {badges.map((badge) => (
              <Badge key={badge}>{badge}</Badge>
            ))}
          </BadgeContainer>
        )}
        <p className="description">{description}</p>
      </div>
      {!!image && (
        <Image
          className="image"
          css={css`
            width: 100%;
            border-radius: 1rem;
            object-fit: cover;
          `}
          image={image}
          alt=""
        />
      )}
    </Grid>
  </Container>
);

export default InfoBlock;
