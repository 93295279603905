import React from "react";
import styled from "@emotion/styled";
import { NFTItemCard } from "~components";
import { breakpoint } from "~utils/css";
import EventUserData from "../../EventUserData";

/** ============================================================================
 * @css
 */

const Container = styled.article`
  width: 100%;
  height: 100%;
  position: relative;

  .nftOverlaySidebar {
    &Heading {
      margin-bottom: 2.5rem;
    }

    &Meta {
      width: 100%;
      position: relative;
      overflow: hidden;
      margin-top: 0.75rem;
      border-radius: 0.75rem;

      //
      // from Figma
      background: rgba(255, 255, 255, 0.4);
      backdrop-filter: blur(50px);
      //

      &Heading {
        margin-bottom: 0.5rem;
      }

      &Subheading {
        color: var(--color-mono-40);
        margin-top: 0.5rem;
      }

      &Item {
        padding: 2.5rem 1.5rem;

        &Light {
          background: white;
        }
      }
    }

    &UserData {
      width: 100%;
      height: 68px;
      display: flex;
      align-items: center;
      justify-content: center;

      ${breakpoint(`large-tablet`)} {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const EventOverlaySidebar = ({ nft, sanityEvent, selected }) => (
  // ---------------------------------------------------------------------------
  // imports / hooks

  // ---------------------------------------------------------------------------
  // context / ref / state

  // ---------------------------------------------------------------------------
  // lifecycle

  // ---------------------------------------------------------------------------
  // render

  <Container className="nftOverlaySidebar">
    <div>
      <h2 className="nftOverlaySidebarHeading h2">Event Ticket</h2>

      <NFTItemCard image={sanityEvent?.image} oracle={sanityEvent?.oracle?.title} title={sanityEvent?.title} body={selected?.title} />
    </div>

    <div className="nftOverlaySidebarUserData">
      <EventUserData nft={nft} />
    </div>
  </Container>
);

export default EventOverlaySidebar;
