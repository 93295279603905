import { Link } from "gatsby";
import { useEffect, useState } from "react";

const OracleHeroes = ({ data: { title, guid } }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    // TODO: Fetch hero items by guid
    setItems([]);
  }, []);

  return (
    <section className="oracleHeroes">
      <h2>{title}</h2>
      <p>
        <Link to={`/marketplace?oracle=${guid}`}>See more</Link>
      </p>
      <ul>
        {items.map((item) => (
          <li key={item.id}>
            <h3>{item.title}</h3>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default OracleHeroes;
