import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import cn from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Carousel, SVG, Image } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  position: relative;
  margin-bottom: 6rem;
  height: 100%;
  width: 100%;
  overflow-x: hidden;

  .imageGallery {
    &Carousel {
      height: calc(100% - 60px);

      ${breakpoint(`small-tablet`)} {
        height: calc(100% - 100px);
      }
    }

    &Pagination {
      margin-top: 1.5rem;
      display: flex;
      justify-content: center;
      gap: 4px;

      ${breakpoint(`small-tablet`)} {
        display: none;
      }
    }

    &NavButton {
    }

    &NavButtonIcon {
      width: 1rem;
      height: 1rem;
    }

    &Thumbnails {
      justify-content: center;
      gap: 4px;
      margin: 2rem auto;
      max-width: 700px;
      display: none;

      ${breakpoint(`small-tablet`)} {
        display: flex;
      }
    }
  }
`;

const NavButton = styled.button`
  background: var(--color-black);
  color: var(--color-white);
  align-items: center;
  justify-content: center;
  height: 56px;
  width: 56px;
  position: absolute;
  display: none;
  top: 50%;
  transform: translateY(-50%);
  ${({ side }) => (side === `left` ? `left: 0;` : `right: 0;`)}

  ${breakpoint(`small-tablet`)} {
    display: ${({ hidden }) => (hidden ? `none` : `flex`)};
  }
`;

const PaginationDot = styled.div`
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: ${({ active }) => (active ? `var(--color-black)` : `var(--color-mono-20)`)};
`;

const Thumbnail = styled.button`
  width: 62px;
  height: 32px;
  position: relative;

  &::after {
    content: "";
    display: ${({ active }) => (active ? `block` : `none`)};
    width: 4px;
    height: 4px;
    background: var(--color-primary-orange-100);
    position: absolute;
    border-radius: 100%;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

/** ============================================================================
 * @component
 * @param  {string} className - any additional classes to add to the component
 * @return {node} - the rendered component
 */

const ImageGallery = ({ className, images, thumbnailImages }) => {
  const [carouselPage, setCarouselPage] = useState(0);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: `start`,
    loop: false,
    slidesToScroll: 1
  });

  const emblaPrev = () => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollPrev();
  };

  const emblaNext = () => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollNext();
  };

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on(`select`, () => setCarouselPage(emblaApi.selectedScrollSnap()));
  }, [emblaApi]);

  return (
    <Container className={cn(className)}>
      <Carousel
        embla={{
          api: emblaApi,
          ref: emblaRef
        }}
        className="imageGalleryCarousel"
        slidesPerView={1}
        gap={0}
        slides={() =>
          images.map((image) =>
            !!image?.asset?.gatsbyImageData ? (
              <GatsbyImage
                image={image.asset.gatsbyImageData}
                alt={image.alt || ``}
                style={{ height: `100%`, width: `100%` }}
                imgStyle={{ objectFit: `contain` }}
              />
            ) : (
              <Image image={image.publicUrl} alt={image.fileName || ``} style={{ height: `100%`, width: `100%` }} imgStyle={{ objectFit: `contain` }} />
            )
          )
        }
      />

      <div className="imageGalleryPagination">
        {images.map((_image, i) => (
          <PaginationDot key={i} active={i === carouselPage} />
        ))}
      </div>

      <NavButton className="imageGalleryNavButton" type="button" side="left" onClick={emblaPrev} hidden={carouselPage === 0}>
        <SVG className="imageGalleryNavButtonIcon" svg="arrowLeft" />
      </NavButton>
      <NavButton className="imageGalleryNavButton" side="right" type="button" onClick={emblaNext} hidden={carouselPage === images.length - 1}>
        <SVG className="imageGalleryNavButtonIcon" svg="arrowRight" />
      </NavButton>

      <div className="imageGalleryThumbnails">
        {thumbnailImages.map((image, i) => (
          <Thumbnail
            active={i === carouselPage}
            className={cn(i === carouselPage && `active`)}
            type="button"
            onClick={() => emblaApi.scrollTo(i)}
            aria-label={`Go to slide ${i + 1}`}
            key={i}
          >
            {!!image?.asset?.gatsbyImageData ? (
              <GatsbyImage
                image={image.asset.gatsbyImageData}
                alt={image.alt || ``}
                style={{ height: `100%`, width: `100%` }}
                imgStyle={{ objectFit: `cover` }}
              />
            ) : (
              <Image image={image.publicUrl} alt={image.fileName || ``} style={{ height: `100%`, width: `100%` }} imgStyle={{ objectFit: `cover` }} />
            )}
          </Thumbnail>
        ))}
      </div>
    </Container>
  );
};

export default ImageGallery;
