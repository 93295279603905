import React from "react";
import { Button } from "~components";

/** ============================================================================
 * @component
 * For use with the linkOptions sanity object
 */
const CtaButton = ({ cta, colorTheme = `light`, variant, fluid, iconRight }) => {
  if (!cta) return null;

  const { externalLink, globalLink, internalLink } = cta;

  if (cta.type === `none`) return null;

  if (cta.type === `linkInternal`) {
    const { reference } = internalLink;

    if (reference._type === `page`) {
      return (
        <Button to={`/${reference.slug.current}`} colorTheme={colorTheme} variant={variant} fluid={fluid} iconRight={iconRight}>
          {internalLink.text}
        </Button>
      );
    }

    if (reference._type === `event`) {
      return (
        <Button to={`/events/${reference.slug.current}`} colorTheme={colorTheme} variant={variant} fluid={fluid} iconRight={iconRight}>
          {internalLink.text}
        </Button>
      );
    }

    if (reference._type === `product`) {
      return (
        <Button to={`/products/${reference.slug.current}`} colorTheme={colorTheme} variant={variant} fluid={fluid} iconRight={iconRight}>
          {internalLink.text}
        </Button>
      );
    }

    if (reference._type === `raffle`) {
      return (
        <Button to={`/raffles/${reference.slug.current}`} colorTheme={colorTheme} variant={variant} fluid={fluid} iconRight={iconRight}>
          {internalLink.text}
        </Button>
      );
    }
  }

  if (cta.type === `linkExternal`) {
    return (
      <Button
        href={externalLink.url}
        newTab={externalLink.newTab}
        colorTheme={colorTheme}
        variant={variant}
        fluid={fluid}
        iconRight={iconRight}
        disabled={!externalLink.url}
      >
        {externalLink.text}
      </Button>
    );
  }

  if (cta.type === `globalLink`) {
    return (
      <Button href={globalLink.link.url} colorTheme={colorTheme} newTab={globalLink.newTab} variant={variant} fluid={fluid}>
        {globalLink.link.text}
      </Button>
    );
  }

  return null;
};

export default CtaButton;
