import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { v4 as uuid } from "uuid";
import { Link, SquareCard, Grid } from "~components";
import { breakpoint } from "~utils/css.js";

/** ============================================================================
 * @css
 */

const Container = styled.section`
  width: 100%;
  position: relative;
  background: ${({ background }) => background || `#ffffff`};
  color: ${({ color }) => color || `#ffffff`};

  overflow: hidden;

  ${breakpoint(`small-tablet`)} {
    padding-bottom: 1.5rem;
  }
`;

const Content = styled.div`
  width: 100%;
  position: relative;

  grid-column: 1 / -1;
  width: calc(100% + 3rem);
  left: -1.5rem;

  ${breakpoint(`small-tablet`)} {
    width: calc(100% + 4rem);
    left: -2rem;
  }

  ${breakpoint(`large-tablet`)} {
    width: calc(100% + 7rem);
    left: -3.5rem;
  }

  ${breakpoint(`desktop`)} {
    margin: 0 auto;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: 2 / -2;
    width: 100%;
    left: 0;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  ${breakpoint(`small-tablet`)} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  ${breakpoint(`large-tablet`)} {
    flex-wrap: nowrap;
    // overflow-x: scroll;
    // scroll-snap-type: x mandatory;
    padding-bottom: 4rem;
  }

  ${breakpoint(`desktop`)} {
    padding-bottom: 2.5rem;
  }
`;

const CardContainer = styled.div`
  flex: 0 0 100%;

  pointer-events: ${({ disabled }) => `${disabled ? `none !important` : `auto`}`};

  ${breakpoint(`small-tablet`)} {
    flex: 0 0 50%;
  }

  ${breakpoint(`large-tablet`)} {
    min-width: 25%;
    flex: unset;
    scroll-snap-align: start;
  }
`;

/** ============================================================================
 * @component
 */
const SquareLinkGrid = ({ data: { backgroundColour, fontColour, gridItems } }) => (
  <Container background={backgroundColour?.hex} color={fontColour?.hex}>
    <Grid>
      <Content>
        <CardWrapper>
          {gridItems.map((gridItem) => (
            <CardContainer key={`square-link-${uuid()}`} disabled={gridItem?.disabled}>
              {(!gridItem?.disabled && gridItem?.link?.url && (
                <Link
                  to={gridItem.link.url}
                  css={css`
                    ${gridItem?.disabled && `cursor: unset;`}
                  `}
                >
                  <SquareCard data={gridItem} />
                </Link>
              )) || <SquareCard data={gridItem} />}
            </CardContainer>
          ))}
        </CardWrapper>
      </Content>
    </Grid>
  </Container>
);

export default SquareLinkGrid;
