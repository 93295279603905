import React from "react";
import { css, Global } from "@emotion/react";

export const COLORS = {
  transparent: `transparent`,
  black: `#000000`,
  white: `#ffffff`,
  //
  "mono-100": `#000000`,
  "mono-90": `#1e1e1e`,
  "mono-70": `#323232`,
  "mono-60": `#4f4f4f`,
  "mono-40": `#919191`,
  "mono-20": `#dddee2`,
  "mono-10": `#f0f0f0`,
  "mono-0": `#ffffff`,
  //
  "primary-blue-100": `#12101e`,
  "primary-blue-50": `#00109e`,
  "primary-blue-20": `#0b9bed`,
  //
  "primary-orange-100": `#ff6108`,
  "primary-orange-50": `#ffa674`,
  "primary-orange-20": `#fcf2eb`,
  //
  "khaki-10": `#e9e9e9`,
  "khaki-50": `#a4a79e`,
  "khaki-100": `#8e9287`,
  beige: `#f2f1ef`,
  //
  "ux-error": `#f75757`,
  "ux-success": `#07cb9c`,
  "ux-orange-100": `#ff8662`,
  "ux-orange-50": `#ffa674`,
  "ux-green-100": `#3df988`,
  "ux-green-50": `#bdffa6`
};

const Colors = () => (
  <Global
    styles={[
      css`
        :root {
          ${Object.keys(COLORS).map(
            (colorKey) => `
              --color-${colorKey}: ${COLORS[colorKey]};
            `
          )}
        }
      `
    ]}
  />
);

export default Colors;
