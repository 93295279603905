import React from "react";
import { Button, Image } from "~components";
import { BLOCK_EXPLORER_ADDRESS } from "~utils/helpers";
import { blockchainHooks } from "~hooks/blockchainHooks";
import useApp from "~hooks/useApp";

/** ============================================================================
 * @component
 */
const NFTPurchaseCard = ({ nft, isFragment = false }) => {
  const { useGetFractionsBalance } = blockchainHooks();
  const { userData } = useApp();

  // ---------------------------------------------------------------------------
  // methods
  // todo: formatting that works across types?
  const getTitle = () => nft?.product?.productName;

  const getTokenExplorerUrl = () => {
    if (isFragment && nft?.nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress) {
      return `${BLOCK_EXPLORER_ADDRESS}${nft?.nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress}`;
    }
    if (!isFragment && nft?.nftData?.tokenExplorerUrl) {
      return nft.nftData.tokenExplorerUrl;
    }
    return null;
  };
  // ---------------------------------------------------------------------------
  // lifecycle

  const { data: fragmentBalance } = useGetFractionsBalance(nft?.nftData, userData?.address);

  // ---------------------------------------------------------------------------
  // render

  if (!nft) {
    return null;
  }

  return (
    <section className="nftPurchaseCard">
      <div className="details">
        <h2 className="nftPurchaseCardWholeTitle">You now own {!isFragment && `a`}</h2>
        {isFragment && <span className="h2">{`${fragmentBalance}/${nft?.product?.numberOfFragments} fragment${fragmentBalance > 1 ? `s` : ``}`}</span>}
      </div>

      <div className="card">
        {nft?.product?.oracle?.text && <strong className="caption">{nft.product.oracle.text}</strong>}

        <h3 className="h2">{getTitle()}</h3>

        <Image className="image" image={nft.product.nftImage.publicUrl} />
      </div>

      <div className="buttons">
        <Button to="/profile/collection" variant="feature" colorTheme="light" fluid>
          <span className="button-text">Go to my NFTs</span>
        </Button>

        <Button to="/" variant="feature" colorTheme="light" fluid>
          <span className="button-text">Back to home</span>
        </Button>

        {!!getTokenExplorerUrl() && (
          <Button href={getTokenExplorerUrl()} variant="feature" colorTheme="light" fluid>
            <span className="button-text">View NFT on Polygonscan</span>
          </Button>
        )}
      </div>
    </section>
  );
};

export default NFTPurchaseCard;
