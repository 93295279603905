import React from "react";

const PgWrapper = ({ title, itemWidth, children }) => (
  <div style={{ padding: `1em` }}>
    {title && (
      <div
        className="h1"
        style={{
          marginBottom: 16,
          color: `#ffffff`,
          textShadow: `0px 4px 4px black`
        }}
      >
        {title}
      </div>
    )}
    <div
      style={{
        display: `grid`,
        gridTemplateColumns: `repeat(auto-fill, ${itemWidth})`,
        marginBottom: 32
      }}
    >
      {children}
    </div>
  </div>
);

export default PgWrapper;
