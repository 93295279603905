import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { useApp } from "~hooks";
import { SVG } from "~components";
import { breakpoint } from "~utils/css";

const Container = styled.div`
  pointer-events: ${({ isVisible }) => (isVisible ? `auto` : `none`)};
  opacity: ${({ isVisible }) => (isVisible ? `1` : `0`)};
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.9);
  transition: opacity 0.2s;
  color: white;
  z-index: 80;

  .videoPlayerOverlay {
    &CloseButton {
      position: fixed;
      top: 2rem;
      right: 2rem;
      width: 24px;
      height: 24px;
    }

    &Video {
      width: 100%;
      object-fit: contain;
      height: 100%;

      ${breakpoint(`small-tablet`)} {
        padding: 6rem 2rem;
      }
    }
  }
`;

const VideoPlayerOverlay = () => {
  const { isVideoPlayerVisible, setIsVideoPlayerVisible, videoPlayerSource } = useApp();

  const videoRef = useRef(null);

  useEffect(() => {
    if (isVideoPlayerVisible) {
      videoRef.current.currentTime = 0;
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isVideoPlayerVisible]);

  return (
    <Container isVisible={isVideoPlayerVisible}>
      <video className="videoPlayerOverlayVideo" ref={videoRef} autoPlay controls src={videoPlayerSource} />
      <button className="videoPlayerOverlayCloseButton" type="button" onClick={() => setIsVideoPlayerVisible(false)} aria-label="Close video">
        <SVG svg="cross" />
      </button>
    </Container>
  );
};

export default VideoPlayerOverlay;
