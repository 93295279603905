import { useTabsContext } from "./TabsContext";

const TabContent = ({ id, children }) => {
  const { activeTab } = useTabsContext();

  if (activeTab !== id) return null;

  return children;
};

export default TabContent;
