import React from "react";
import { NFTOverlay, PriceWidget } from "~components";
import { thousandCommas } from "~utils/helpers";
import FiatPaymentForm from "~components/Marketplace/_Common/Form/FiatPaymentForm";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayPurchaseClaim = ({ nft }) => (
  <NFTOverlay id="NFTOverlayPurchaseClaim" heading="Buy & Claim" nft={nft} sidebarMode="buyAndClaim">
    <div className="nftOverlayGroup nftOverlayPurchaseClaim">
      {nft?.enrichedProduct && (
        <>
          <div className="nftEntry">
            <div className="summary">
              <h4 className="caption">Collectibe Price</h4>
              <div className="summaryPrice">
                <PriceWidget
                  color="white"
                  displayPrice={thousandCommas(
                    nft?.enrichedProduct?.nftData?.price?.usdAmount +
                      nft?.enrichedProduct?.nftData?.price?.usdAmount * parseFloat(parseInt(nft?.enrichedProduct?.nftData?.makerFee?.split(`%`)[0]) / 100)
                  )}
                  fontClass="h2"
                />
              </div>
            </div>
          </div>
          <FiatPaymentForm enrichedProduct={nft.enrichedProduct} />
        </>
      )}
    </div>
  </NFTOverlay>
);

export default NFTOverlayPurchaseClaim;
