import React from "react";
import { ProfileWalletData, PgWrapper, PgItem } from "~components";

const ProfileWalletDataPG = () => (
  <>
    <PgWrapper title="WalletData">
      <PgItem title="Disconnected">
        <ProfileWalletData />
      </PgItem>
      <PgItem title="Connected">
        <ProfileWalletData connected />
      </PgItem>
    </PgWrapper>
  </>
);

export default ProfileWalletDataPG;
