import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { v4 as uuid } from "uuid";
import { Grid } from "~components";
import { breakpoint } from "~utils/css.js";

/** ============================================================================
 * @css
 */

const Container = styled.section`
  width: 100%;
  position: relative;
  display: block;
  padding: 2.5rem 0;
  background: ${({ background }) => background || `#ffffff`};
  color: ${({ color }) => color || `#ffffff`};

  ${breakpoint(`large-tablet`)} {
    padding: 2rem 0 4rem;
  }

  ${breakpoint(`large-tablet`)} {
    padding: 4rem 0;
  }
`;

const List = styled.ul`
  grid-column: -1 / 1;
  position: relative;

  ${breakpoint(`tablet`)} {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-wrap: wrap;
  }

  ${breakpoint(`large-tablet`)} {
    flex-wrap: initial;
    gap: 2rem;
  }
`;

const ListItem = styled.li`
  position: relative;
  flex-basis: 100%;
  margin-bottom: 2.5rem;

  ${breakpoint(`tablet`)} {
    flex-basis: 50%;
    padding-right: 0.5rem;
  }

  ${breakpoint(`large-tablet`)} {
    flex-basis: 25%;
    margin-bottom: 0;
    padding-right: 0.5rem;
  }
`;

/** ============================================================================
 * @component
 */
const FourUpText = ({ data: { backgroundColour, fontColour, columns } }) => (
  <Container background={backgroundColour?.hex} color={fontColour?.hex}>
    <Grid>
      <List>
        {columns?.[0] &&
          columns.map(({ heading, body }) => (
            <ListItem key={`four-up-text-column-${uuid()}`}>
              <h4
                css={css`
                  margin-bottom: 0.5rem;
                `}
                className="caption"
              >
                {heading}
              </h4>

              <p className="b2">{body}</p>
            </ListItem>
          ))}
      </List>
    </Grid>
  </Container>
);

export default FourUpText;
