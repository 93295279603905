/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { useDevice, useSize } from "~hooks";
import { Image, ProductATFText } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  background: var(--color-mono-90);
`;

const CanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .product-atf-canvas {
    animation: var(--animation-appear-down);
  }
`;

const ProductATFTextWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
`;

const ProductATFLegibilityUnderlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.25);
`;

/** ============================================================================
 * ScrollWave
 * @component
 * Topmost slice loaded by /product/[sanityProduct.slug.current] routes.
 */
const ProductATF = ({ active, className }) => {
  // ---------------------------------------------------------------------------
  // hooks

  const { deviceAbove } = useDevice();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const containerRef = useRef();

  const [canvasRef, containerBoundingRect] = useSize();

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0
  });

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (typeof window === `undefined` || !containerRef?.current || !containerBoundingRect) {
      return;
    }

    const { width, height } = containerBoundingRect;

    setDimensions({
      width,
      height
    });
  }, [containerRef, containerBoundingRect]);

  // ---------------------------------------------------------------------------
  // render

  const isMobile = deviceAbove(`small-tablet`) === false;

  let imageUrl;
  let imageUrlXS;

  switch (product?.sanity?.title?.toLowerCase()) {
    case `test rolex daytona`:
    case `rolex daytona 6265/8`:
    case `rolex daytona`:
      imageUrl = `/images/rolex-daytona/desktop-rolex-atf.jpg`;
      imageUrlXS = `/images/rolex-daytona/mobile-rolex-atf.jpg`;
      break;

    case `ferrari f40`:
      imageUrl = `/images/f40-hero-3.jpg`;
      break;

    default:
      break;
  }

  return (
    <Container ref={containerRef} className={[`product-atf-container`, className].join(` `)}>
      <ProductATFLegibilityUnderlay />

      <ProductATFTextWrapper className="product-atf-text-wrapper">
        {product?.sanity?.title && <ProductATFText active={active} product={product} />}
      </ProductATFTextWrapper>

      <CanvasContainer ref={canvasRef}>
        {(isMobile && <Image id="product-atf-canvas" className="product-atf-canvas" image={imageUrlXS} />) || (
          <Image id="product-atf-canvas" className="product-atf-canvas" image={imageUrl} />
        )}
      </CanvasContainer>
    </Container>
  );
};

export default ProductATF;
