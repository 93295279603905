import cn from "classnames";
import { CtaButton } from "~components";
import { css } from "@emotion/react";

const buildBgImages = (src, originalWidth, mobileSrc) => {
  const srcUrlObj = new URL(src);
  const mobileSrcUrlObj = mobileSrc ? new URL(mobileSrc) : null;
  const imageUrl = `${srcUrlObj.origin}${srcUrlObj.pathname}`;
  const mobileImageUrl = mobileSrcUrlObj ? `${mobileSrcUrlObj.origin}${mobileSrcUrlObj.pathname}` : null;
  const dimensionsArray = mobileImageUrl ? [660, 1024, 1280, 1440, 1920, 2048, 3048] : [400, 500, 640, 1024, 1280, 1440, 1920, 2048, 3048];
  const resolutions = [...dimensionsArray.filter((size) => size < originalWidth), originalWidth];

  return resolutions
    .map((resolution, index) => {
      if (index === 0) return `background-image: url(${mobileImageUrl || imageUrl}?w=${resolution}&auto=format&q=85);`;

      return `
      @media only screen and (min-width: ${resolutions[index - 1]}px) {
        background-image: url(${imageUrl}?w=${resolution}&auto=format&q=85);
      }
    `;
    })
    .join(`\n\n`);
};

const BannerWithCtas = ({ data }) => {
  const { bgColor, blur } = data;
  const bgImage = data.backgroundImage?.asset.gatsbyImageData?.images.fallback.src;
  const mobileBgImage = data.mobileBackground?.asset.gatsbyImageData?.images.fallback.src;
  const direction = data.layout;

  return (
    <div
      className="bg-wrapper bannerWithCtas"
      style={{
        "--wrapper-background": bgColor || `transparent`
      }}
    >
      <section
        className="container narrow dark"
        style={{
          "--background": `transparent`,
          "--primary-color": data.titleColour?.hex,
          "--secondary-color": data.fontColour?.hex
        }}
      >
        <div
          className={cn(`slice`, direction === `horizontal` ? `horizontal` : `vertical`)}
          css={
            bgImage &&
            css`
              ${buildBgImages(bgImage, data.backgroundImage?.asset.gatsbyImageData.width, mobileBgImage)}
            `
          }
        >
          <div className="wrapper">
            <div className={cn(blur && `blur-container frosted-glass-dark`)}>
              <strong className="tag">{data.titleNote}</strong>
              <div className="content">
                <div className="headings">
                  <h2>{data.heading}</h2>
                  {data.subheading && <p>{data.subheading}</p>}
                </div>
                <div className="description">
                  <p>{data.description}</p>
                  {!!data.links && !!data.links.length && direction === `horizontal` && (
                    <div className="flexGrid">
                      {data.links.map((link, index) => (
                        <CtaButton key={index} cta={link} variant={index % 2 === 0 ? `primaryTall` : `feature`} />
                      ))}
                    </div>
                  )}
                </div>
                {!!data.links && !!data.links.length && direction !== `horizontal` && (
                  <div className="flexGrid buttons">
                    {data.links.map((link, index) => (
                      <CtaButton key={index} cta={link} variant={index % 2 === 0 ? `primaryTall` : `feature`} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          {!!(direction === `horizontal` && bgImage) && (
            <div
              className="horizontalBg"
              css={css`
                ${buildBgImages(bgImage, data.backgroundImage?.asset.gatsbyImageData.width, mobileBgImage)}
              `}
            />
          )}
        </div>
      </section>
    </div>
  );
};

export default BannerWithCtas;
