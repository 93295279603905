import React from "react";
import { PortableText } from "@portabletext/react";
import styled from "@emotion/styled";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

/** ============================================================================
 * @component
 * @param {array} rawText - array of Portable Text blocks
 * @return {node}
 */

const components = {
  block: {
    d1: ({ children }) => <h1 className="d1">{children}</h1>,
    h1: ({ children }) => <h2 className="h1">{children}</h2>,
    h2: ({ children }) => <h3 className="h2">{children}</h3>,
    h3: ({ children }) => <h4 className="h3">{children}</h4>,
    h4: ({ children }) => <h5 className="h4">{children}</h5>,
    normal: ({ children }) => <p className="b1">{children}</p>
  }
};

const TextBlock = ({ rawText }) => (
  <Container className="b2">
    <PortableText value={rawText} components={components} />
  </Container>
);

export default TextBlock;
