import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";
import { GatsbyImage } from "gatsby-plugin-image";
import cn from "classnames";
import { useScroll } from "~hooks";
import { SVG } from "~components";
import RaffleStatusBadge from "../RaffleStatusBadge";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import useApp from "~hooks/useApp";
import RafflePurchaseInfo from "../RafflePurchaseInfo";

const ScrollableImage = ({ image, mobileImage, title }) => {
  const { scrollTop } = useScroll();
  const mediaRef = useRef();

  useEffect(() => {
    const mediaElement = mediaRef.current;

    if (mediaElement) {
      gsap.to(mediaElement, {
        y: scrollTop * 0.2,
        ease: `none`,
        duration: 0
      });
    }
  }, [scrollTop]);

  return (
    <figure ref={mediaRef}>
      <GatsbyImage
        image={image?.asset.gatsbyImage}
        alt={image?.altText || title}
        className={cn(`image`, {
          [`desktop`]: mobileImage
        })}
      />
      {mobileImage && <GatsbyImage image={mobileImage?.asset.gatsbyImageData} alt={mobileImage?.altText || title} className="image mobile" />}
    </figure>
  );
};

/** ============================================================================
 * @component
 * @return {node}
 */
const RafflePremiere = ({ raffle, loading, displayPrice, ticketAvailable, maxTicketSupply, raffleUrl, referral }) => {
  const { sanityRaffle, raffleStatus } = raffle;
  const { title = ``, subtitle = ``, label = ``, heroImage = ``, raffleInfo: { basicInfo = [] } = {} } = sanityRaffle || {};

  const [copyClicked, setCopyClicked] = useState(false);
  const [referredCode, setReferredCode] = useState(null);

  const { userData, userIsLoggedIn, raffleReferralCode, setRaffleReferralCode } = useApp();
  const { api } = useExternalIntegrations();

  const userReferralUrl = `${raffleUrl}?ref=${raffleReferralCode}`;

  useEffect(() => {
    const getRaffleReferralCode = async () => {
      if (userIsLoggedIn && userData?.address) {
        const referralCode = await api.getRaffleReferralCode();

        if (referralCode) {
          setRaffleReferralCode(referralCode);
        }
        if (referral !== raffleReferralCode) {
          setReferredCode(referral);
        }
      }
    };

    getRaffleReferralCode().catch((error) => console.error(error));

    return () => setRaffleReferralCode(null);
  }, [userIsLoggedIn, userData?.address, referral]);

  return (
    <div id="raffle">
      <div className={cn(`container wide dark`)}>
        <div className="cover">
          <ScrollableImage image={heroImage} mobileImage={heroImage?.mobileImage} title={title} />
        </div>
        <div className="content">
          <div className="infoList">
            <header>
              <span>{label}</span>
              <h2 className="h1">{title}</h2>
              <h3>{subtitle}</h3>
            </header>
            <RaffleStatusBadge className="status" raffleStatus={raffleStatus} />
            <ul>
              {!!raffleReferralCode && (
                <li className="refLink info">
                  <dl>
                    <dt>Increase your odds by 5% for every 3 referrals:</dt>
                    <dd>
                      <a target="_blank" rel="noreferrer" href={userReferralUrl}>
                        {userReferralUrl}
                      </a>
                      <button
                        type="button"
                        className="copy"
                        disabled={copyClicked}
                        onClick={() => {
                          if (typeof navigator !== `undefined` && typeof navigator.clipboard !== `undefined`) {
                            navigator.clipboard.writeText(userReferralUrl);
                            setCopyClicked(true);
                            setTimeout(() => setCopyClicked(false), 2000);
                          }
                        }}
                      >
                        {copyClicked ? <SVG svg="checkboxChecked" /> : <SVG svg="copy" />}
                      </button>
                    </dd>
                  </dl>
                </li>
              )}
              {basicInfo.map((entry) => (
                <li key={entry._key} className="info">
                  <dl>
                    <dt>{entry.label}</dt>
                    <dd>{entry.text}</dd>
                  </dl>
                </li>
              ))}
            </ul>
          </div>
          <div className="tickets">
            <RafflePurchaseInfo
              raffle={raffle}
              raffleStatus={raffleStatus}
              loading={loading}
              displayPrice={displayPrice}
              ticketAvailable={ticketAvailable}
              maxTicketSupply={maxTicketSupply}
              referredUrl={referral && `${raffleUrl}?ref=${referral}`}
              referredCode={referredCode}
              title="Event Access Ticket"
              visible
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RafflePremiere;
