import styled from "@emotion/styled";
import { NoSSR, Button, Image } from "~components";
import cn from "classnames";
import useIsMounted from "~hooks/useIsMounted";

const WalletButton = styled.li`
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  border-radius: 16px;
  background: #282828;

  .walletButton {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    padding: 20px 0;
    border-radius: 16px;

    &:hover {
      background: #535353;
      text-decoration: none;
    }

    &.disabled {
      background: black;
    }

    &Heading {
    }
  }
`;

const ConnectorFigure = styled.figure`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConnectOverlayWalletButton = ({ connector, image, onClick = () => {} }) => {
  const isMounted = useIsMounted();
  return (
    <WalletButton>
      <NoSSR>
        <Button
          variant="text"
          disabled={isMounted ? connector.disabled : false}
          fluid
          className={cn(`walletButton`, {
            [`disabled`]: isMounted ? connector.disabled : false
          })}
          onClick={() => onClick()}
        >
          {image && (
            <ConnectorFigure>
              <div style={{ width: `45px` }}>
                <Image image={image} fill />
              </div>
            </ConnectorFigure>
          )}

          <span className="walletButtonHeading">
            {connector.name === `Injected` ? `MetaMask` : connector.name}
            {isMounted && connector.disabled && ` (unavailable)`}
          </span>
        </Button>
      </NoSSR>
    </WalletButton>
  );
};

export default ConnectOverlayWalletButton;
