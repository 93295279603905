import React, { useState } from "react";
import styled from "@emotion/styled";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { Button, Grid, SlicePadding } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  width: 100%;
  position: relative;
  display: block;
  padding: 3.75rem 0;

  .nftValueHistory {
    &TextHeading {
      margin-bottom: 2.5rem;
    }
    &TextBody {
      margin-bottom: 1rem;
    }
    &Button {
      margin-top: 1.5rem;
    }
  }
`;

const Aside = styled.aside`
  grid-column: 1 / -1;
  position: relative;

  ${breakpoint(`large-tablet`)} {
    grid-column: 1 / span 3;
  }
`;

const ChartData = styled.div`
  grid-column: 1 / -1;
  position: relative;
  margin-top: 4rem;

  ${breakpoint(`large-tablet`)} {
    grid-column: 4 / span 9;
    margin-top: 0;
  }
`;

const ChartRange = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
  gap: 0.5rem 0.5rem;

  ${breakpoint(`large-tablet`)} {
    gap: 0 0.5rem;
  }

  .chartRange {
    &Button {
      padding: 6px 8px;
      border-radius: 4px;
      background: var(--color-beige);
      color: black;

      &Active {
        background: var(--color-khaki-100);
        color: white;
      }
    }
  }
`;

// @externalintegration
// This would likely need to be replaced by an API call
const DUMMY_DATA = [
  {
    date: `2009-09-01`,
    price: `68,919`
  },
  {
    date: `2011-04-13`,
    price: `68,239`
  },
  {
    date: `2011-05-15`,
    price: `59,670`
  },
  {
    date: `2011-11-14`,
    price: `88,734`
  },
  {
    date: `2012-11-13`,
    price: `109,337`
  },
  {
    date: `2015-11-08`,
    price: `129,093`
  },
  {
    date: `2016-03-16`,
    price: `137,160`
  },
  {
    date: `2016-11-13`,
    price: `121,336`
  },
  {
    date: `2017-05-15`,
    price: `137,160`
  },
  {
    date: `2017-12-06`,
    price: `163,538`
  },
  {
    date: `2019-11-10`,
    price: `215,692`
  },
  {
    date: `2021-06-26`,
    price: `211,454`
  },
  {
    date: `2022-04-01`,
    price: `223,987`
  }
];
/** ============================================================================
 * @component
 * @return {node}
 */
const NFTValueHistory = ({
  nft: { nftStatus, enrichedProduct, sanityProduct, saleId }
}) => {
  Chart.defaults.font.family = `"Sohne Mono", "Helvetica Neue", "Helvetica", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji`;

  // ---------------------------------------------------------------------------
  // variables

  const timeRanges = {
    MAX: Infinity,
    "5 YEARS": 5 * 365 * 24 * 60 * 60 * 1000,
    "3 YEARS": 3 * 365 * 24 * 60 * 60 * 1000,
    "1 YEAR": 1 * 365 * 24 * 60 * 60 * 1000,
    "6 MONTHS": 6 * 30 * 24 * 60 * 60 * 1000,
    "3 MONTHS": 3 * 30 * 24 * 60 * 60 * 1000,
    "1 MONTH": 1 * 30 * 24 * 60 * 60 * 1000
  };

  const sortedRows = DUMMY_DATA?.sort(
    (a, b) => new Date(a.date) - new Date(b.date)
  );

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [activeRange, setActiveRange] = useState(`MAX`);

  // ---------------------------------------------------------------------------
  // methods

  const filterDataByRange = (data, range) => {
    if (range === `MAX`) {
      return data;
    }

    const now = new Date();
    const fromDate = new Date(now.getTime() - timeRanges[range]);

    return data.filter((row) => new Date(row.date) >= fromDate);
  };

  const parsePrice = (price) => {
    const priceWithoutSymbol = price.replace(/[^0-9.]/g, ``);
    const parsedPrice = parseFloat(priceWithoutSymbol);
    return parsedPrice;
  };

  // ---------------------------------------------------------------------------
  // render

  const filteredData = filterDataByRange(sortedRows, activeRange);

  const chartData = {
    labels: filteredData?.map((row) => row.date),
    datasets: [
      {
        label: `Price ($USD)`,
        data: filteredData?.map((row) => parsePrice(row.price)),
        backgroundColor: `#000000`,
        pointBorderColor: `#000000`,
        borderColor: `black`
      }
    ]
  };

  return (
    <Container>
      <SlicePadding>
        <Grid node="article" noXPadding>
          <Aside>
            <h2 className="nftValueHistoryTextHeading h2">Value History</h2>

            <div>
              <p className="nftValueHistoryTextBody b2">
                Prices history is determinated by auction prices from Sothebys,
                Christies, etc.
              </p>
              <p className="nftValueHistoryTextBody b2">
                This not reflective of the perfect value of this product because
                its uniquness is made by:
              </p>

              <Button
                colorTheme="dark"
                iconRight="arrowRight"
                className="nftValueHistoryButton"
              >
                <span className="button-text">See what makes it unique</span>
              </Button>
            </div>
          </Aside>

          <ChartData>
            <Line data={chartData} />

            <ChartRange>
              {Object.keys(timeRanges)?.map((timeKey) => {
                const timeRange = timeRanges[timeKey];
                const active = timeKey === activeRange;

                return (
                  <button
                    key={timeRange}
                    type="button"
                    className={`chartRangeButton ${
                      active ? `chartRangeButtonActive` : ``
                    }`}
                    onClick={() => setActiveRange(timeKey)}
                  >
                    <span className="tag">{timeKey}</span>
                  </button>
                );
              })}
            </ChartRange>
          </ChartData>
        </Grid>
      </SlicePadding>
    </Container>
  );
};

export default NFTValueHistory;
