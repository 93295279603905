import React from "react";
import styled from "@emotion/styled";
import { NFTStatusDot } from "~components";
import { breakpoint } from "~utils/css";
import { formatDateTime, getDotColor } from "~utils/helpers";
import { blockchainHooks } from "~hooks/blockchainHooks";

const Container = styled.div`
  position: relative;

  .nftStatus {
    margin-bottom: 1rem;
    ${breakpoint(`large-tablet`)} {
      margin-bottom: 0;
    }

    padding: 0.375rem 0.5rem;
    display: flex;
    align-items: center;
    background: #e1e1e1;
    border-radius: 4px;
    text-transform: uppercase;

    &Dot {
      margin: 0 4px;
    }

    &Info {
      width: 0.75rem;
      height: 0.75rem;
      position: relative;
      display: block;
      margin: 0 0 0 4px;
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTStatusBadge = ({ className = ``, nftStatus, enrichedProduct }) => {
  const { useGetSaleData } = blockchainHooks();
  // ---------------------------------------------------------------------------
  // lifecycle

  const { data: saleData } = useGetSaleData(enrichedProduct?.nftData, false);
  // ---------------------------------------------------------------------------
  // methods

  const getStatusText = () => {
    switch (nftStatus?.status) {
      case `NoEmail`:
        return `Waitlist Open`;

      case `CollectUserInterest`:
        return `Waitlist Open`;

      case `WaitingForWhitelisting`:
        return `In Allowlist queue`;

      case `DoKyc`:
        return `Complete identity verification`;

      case `KycRejected`:
        return `Unable to verify your identity`;

      case `WaitingForSaleStart`:
        return `Sale will start soon`;

      case `OnSale`:
        return `Whole item sale active for 48h`;

      case `NFTBurned`:
        return `Claimed`;

      case `OnFractionsSale`:
        return saleData?.closingTime
          ? `Fragmentation open until ${formatDateTime(parseInt(saleData?.closingTime?.toString()) * 1000, `MMM DD, YYYY hh:mm:ss Z`)}`
          : `Fragmentation open`;

      case `SoldOut`:
        return `Sold Out`;

      case `OnFractionsSaleSuccess`:
        return `Secondary Market open`;

      case `OnFractionsSaleFailed`:
        return `Sale Failed`;

      default:
        return ``;
    }
  };
  // ---------------------------------------------------------------------------
  // render

  if (!nftStatus?.status) {
    return null;
  }

  return (
    <>
      <Container className={className}>
        <div className="caption nftStatus">
          <span>STATUS: </span>

          <NFTStatusDot
            id="NFTStatusBadge"
            animate={!nftStatus?.is([`OnFractionsSaleSuccess`, `NFTBurned`])}
            className="nftStatusDot"
            hex={getDotColor(nftStatus)}
          />

          <span>{getStatusText()}</span>
        </div>
      </Container>
    </>
  );
};

export default NFTStatusBadge;
