import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import gsap from "gsap";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import cn from "classnames";
import { useScroll } from "~hooks";
import { Grid, EventPurchase, EventStatusBadge, SlicePadding } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  position: relative;

  @keyframes atf-appear-down {
    0% {
      transform: translate3d(0, -4rem, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }

  //

  .nftPremiere {
    &BackgroundImageContainer {
      position: absolute;
      inset: 0;
      background: black;

      .gatsby-image-wrapper {
        animation: 2s var(--cubic-easing) atf-appear-down forwards;
        animation-delay: 0.5s;

        transform: translate3d(0, -4rem, 0);
        opacity: 0;
      }
    }

    &Content {
      position: relative;
      color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-black)` : `var(--color-white)`)};
    }

    &Title {
      margin-top: 0.5rem;

      ${breakpoint(`large-tablet`)} {
        margin-top: 0;
      }
    }

    &SlicePadding {
      padding-top: 0;
    }

    &Media {
      width: 100%;
      height: 60vh;
      min-height: 300px;
      overflow: hidden;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      margin-bottom: 60px;
      -webkit-mask-image: -webkit-radial-gradient(white, black); // Need for safari rounded corners ¯\_(ツ)_/¯"
    }

    &Figure {
      width: 100%;
      height: 100%;
      position: relative;
    }

    &Image {
      animation: 1s var(--cubic-easing) atf-appear-down forwards;
      animation-delay: 0.25s;

      transform: translate3d(0, 4rem, 0);
      opacity: 0;

      width: 100%;
      height: 100%;
      object-fit: cover;

      &Desktop {
        display: none;
        ${breakpoint(`large-tablet`)} {
          display: block;
        }
      }

      &Mobile {
        ${breakpoint(`large-tablet`)} {
          display: none;
        }
      }
    }

    &ContentLeft {
      animation: 0.75s var(--cubic-easing) appear-up forwards;
      animation-delay: 0.1s;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      transform: translate3d(0, -1rem, 0);
      opacity: 0;

      grid-column: 1 / -1;

      ${breakpoint(`large-tablet`)} {
        grid-column: 1 / 6;
      }

      &Card {
        margin: 10px 0;
        padding: 10px;
        height: 100%;
        width: 100%;
        color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-mono-60)` : `var(--color-mono-40)`)};

        ${breakpoint(`large-tablet`)} {
          margin-bottom: 0;
          width: 85%;
        }

        &Title {
          margin-bottom: 1rem;
        }
      }
    }

    &ContentRight {
      animation: 0.75s var(--cubic-easing) appear-up forwards;
      animation-delay: 0.1s;

      transform: translate3d(0, -1rem, 0);
      opacity: 0;

      grid-column: 1 / -1;

      ${breakpoint(`large-tablet`)} {
        grid-column: 6 / -1;
      }
      border-radius: 1rem;
    }

    &Oracle {
      color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-mono-60)` : `var(--color-mono-40)`)};

      a {
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &OracleArrow {
      width: 7px;
      position: relative;
      display: inline-block;
      top: -3px;
      margin-left: 4px;
    }

    &Status {
      margin-top: 2rem;
      margin-bottom: 2rem;
      display: inline-block;

      ${breakpoint(`large-tablet`)} {
        margin-top: 2.5rem;
        margin-bottom: 0;
      }
    }

    &List {
      width: calc(75% + 1rem);
      margin-top: 0.75rem;
      border-bottom: 1px solid var(--color-mono-40);

      &Item {
        padding: 0.5rem 0;
        border-top: 1px solid var(--color-mono-40);

        &Heading {
          color: var(--color-mono-40);
        }

        &Name {
          color: var(--color-mono-60);
        }

        svg {
          width: 11px;
          height: 11px;
          margin-left: 7px;
        }
      }
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const EventPremiere = ({ connected, nft, selected, setSelected, loading, displayPrice, ticketAvailable, maxTicketSupply }) => {
  let title = ``;
  let description = ``;
  let heroImage = ``;
  let pdpTheme = null;

  const { nftStatus, enrichedEvent, sanityEvent } = nft;

  if (sanityEvent) {
    ({ title, description, pdpTheme, heroImage } = sanityEvent);
  }

  // ---------------------------------------------------------------------------
  // imports / hooks

  const { scrollTop } = useScroll();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const mediaRef = useRef();
  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const mediaElement = mediaRef.current;

    gsap.to(mediaElement, {
      y: scrollTop * 0.2,
      ease: `none`,
      duration: 0
    });
  }, [scrollTop]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container colorTheme={pdpTheme}>
      <figure className="nftPremiereBackgroundImageContainer">
        {pdpTheme === `dark` && <StaticImage style={{ height: `100%`, width: `100%` }} src="../../../assets/images/backgrounds/pdp-atf-dark.jpg" alt="" />}

        {pdpTheme === `light` && <StaticImage style={{ height: `100%`, width: `100%` }} src="../../../assets/images/backgrounds/pdp-atf-light.jpg" alt="" />}
      </figure>

      <article className="nftPremiereContent">
        <SlicePadding className="nftPremiereSlicePadding">
          <div className="nftPremiereMedia">
            <figure className="nftPremiereFigure" ref={mediaRef}>
              <GatsbyImage
                image={heroImage?.asset.gatsbyImage}
                alt={heroImage?.altText || title}
                className={cn(`nftPremiereImage`, {
                  [`nftPremiereImageDesktop`]: heroImage?.mobileImage
                })}
              />
              {heroImage?.mobileImage && (
                <GatsbyImage
                  image={heroImage?.mobileImage?.asset.gatsbyImageData}
                  alt={heroImage?.mobileImage?.altText || title}
                  className="nftPremiereImage nftPremiereImageMobile"
                />
              )}
            </figure>
          </div>

          <Grid noXPadding>
            {/* // leftmost title/status information // */}
            <div className="nftPremiereContentLeft">
              <header>
                <h1 className="h1 nftPremiereTitle">{title}</h1>

                <EventStatusBadge className="nftPremiereStatus" nftStatus={nftStatus} enrichedEvent={enrichedEvent} />
              </header>

              <div className="nftPremiereContentLeftCard">
                <div className="caption">{description}</div>
              </div>
            </div>

            <div className="nftPremiereContentRight">
              <EventPurchase
                nft={nft}
                connected={connected}
                enrichedEvent={enrichedEvent}
                nftStatus={nftStatus}
                selected={selected}
                setSelected={setSelected}
                loading={loading}
                displayPrice={displayPrice}
                ticketAvailable={ticketAvailable}
                maxTicketSupply={maxTicketSupply}
              />
            </div>
          </Grid>
        </SlicePadding>
      </article>
    </Container>
  );
};

export default EventPremiere;
