import React, { useMemo } from "react";
import { SanitySlice } from "~components";

const sanityProductSlices = [`productDetailSlice`, `infoTabs`];

/** ============================================================================
 * One-time API enrichment of Sanity data + helpers.
 */
const useSanityProduct = (
  sanityProduct,
  enrichedProduct,
  showSliceTitles = false
) => ({
  product: enrichedProduct,
  slices:
    sanityProduct?.pagebuilder?.slices?.[0] &&
    sanityProduct?.pagebuilder?.slices?.map((slice, i) => {
      let product = null;

      if (sanityProductSlices?.includes(slice?._type)) {
        product = enrichedProduct;
      }

      return (
        <div key={i}>
          {showSliceTitles && (
            <h2 style={{ padding: 16, background: `green` }}>{slice._type}</h2>
          )}
          <SanitySlice
            key={slice._key}
            data={slice}
            enrichedProduct={product}
          />
        </div>
      );
    })
});

export default useSanityProduct;
