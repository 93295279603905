import { useEffect, useState } from "react";
import _ from "underscore";

export const useImageTagCache = (urls) => {
  const [imageTags, setImageTags] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(0);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(async () => {
    if (imageTags?.[0] || loaded) {
      return;
    }

    setLoaded(true);

    const batchSize = 10;

    const batches = _.map(urls, (_, index) =>
      index % batchSize === 0 ? urls.slice(index, index + batchSize) : null
    ).filter((url) => url);

    const promises = batches.map(async (batch, batchIndex) => {
      const batchPromises = batch.map(
        (url) =>
          new Promise((resolve, reject) => {
            const img = new Image();

            img.onload = resolve(img);
            img.onerror = reject();

            img.src = url;
          })
      );

      return Promise.all(batchPromises).then((values) => {
        setProgress(batchIndex / batches.length);
        return values;
      });
    });

    await Promise.all(promises).then((batchResults) => {
      setProgress(1);
      setImageTags(batchResults.flat(1));
    });
  }, [urls]);

  // ---------------------------------------------------------------------------
  // return

  return { imageTags, progress };
};

export default useImageTagCache;
