import React, { useState } from "react";
import { Expander, PgWrapper, PgItem } from "~components";

const ExpanderPG = () => {
  const [active, setActive] = useState(false);
  return (
    <PgWrapper title="Expander" itemWidth="300px">
      <PgItem title="Default">
        <Expander
          active={active}
          onExpand={() => setActive((prev) => !prev)}
          expander={{ key: `Top text`, value: `Bottom text` }}
        />
      </PgItem>

      <PgItem title="Small">
        <Expander
          active={active}
          small
          onExpand={() => setActive((prev) => !prev)}
          expander={{ key: `Top text`, value: `Bottom text` }}
        />
      </PgItem>
    </PgWrapper>
  );
};

export default ExpanderPG;
