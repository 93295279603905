import React from "react";
import { Button } from "~components";
import { useApp } from "~hooks";
import { isBrowser } from "~utils/helpers";
import { LOCALSTORAGE_KEY } from "~constants";

/** ============================================================================
 * @component
 * @return {node}
 */
const RaffleActionWaitlist = ({ colorTheme = `dark`, guid }) => {
  const { setActiveOverlay } = useApp();

  const getSubmitText = () => {
    if (!isBrowser()) return `Join Waitlist`;

    const localStorageItem = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY));
    if (localStorageItem?.[guid]) {
      return `Waitlist Joined`;
    }
    return `Join Waitlist`;
  };

  return (
    <Button
      onClick={() => setActiveOverlay(`RaffleOverlayWaitlist`)}
      fluid
      variant="primaryTall"
      colorTheme={colorTheme}
      disabled={getSubmitText() === `Waitlist Joined`}
    >
      <span className="b1">{getSubmitText()}</span>
    </Button>
  );
};

export default RaffleActionWaitlist;
