/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { breakpoint, hoverable } from "~utils/css.js";
import SVG from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  transition:
    0.5s var(--cubic-easing) max-height,
    0.5s var(--cubic-easing) background-color,
    0.5s var(--cubic-easing) color;

  position: relative;
  overflow: hidden;
  border-radius: 16px;

  ${breakpoint(`large-tablet`)} {
    margin-top: 0;
  }

  ${hoverable} {
    &:hover {
      background: var(--color-mono-90);
    }
  }
`;

const Content = styled.div`
  position: relative;
  padding: 1rem;

  ${breakpoint(`large-tablet`)} {
    padding: 0.5rem 1rem 1.5rem;
  }

  ${hoverable} {
    a:hover {
      text-decoration: underline;
    }
  }
`;

const ExpanderButton = styled.button`
  width: 100%;
  height: ${({ height }) => `${height}`};
  position: relative;
  z-index: 10;
  display: block;
  padding: 0 1rem;
  cursor: pointer;
`;

const ExpanderButtonInner = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ExpanderButtonText = styled.p`
  width: 100%;
  text-align: left;

  ${breakpoint(`large-tablet`)} {
    width: auto;
  }
`;

/** ============================================================================
 * @component
 */
const Expander = ({ expander: { key, value }, active, onExpand = () => { }, small = false }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const contentRef = useRef();

  const [contentHeight, setContentHeight] = useState(0);

  // ---------------------------------------------------------------------------
  // variables

  const buttonHeight = `4rem`;

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!contentRef?.current) {
      return;
    }

    setContentHeight(contentRef.current.getBoundingClientRect().height);
  }, [contentRef]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container
      css={css`
        max-height: ${active ? `calc(${contentHeight}px + ${buttonHeight} + 2rem)` : buttonHeight};

        background: ${active ? `var(--color-white) !important` : `var(--color-mono-100)`};
        color: ${active ? `var(--color-mono-100) !important` : `var(--color-white)`};

        ${breakpoint(`large-tablet`)} {
          max-height: ${active ? `calc(${contentHeight}px + 7rem)` : buttonHeight};
        }

        white-space: pre-line;

        & * {
          white-space: pre-line;
        }
      `}
    >
      <ExpanderButton type="button" onClick={onExpand} height={buttonHeight}>
        <ExpanderButtonInner>
          <ExpanderButtonText className={small ? `b2` : `h4`}>{key}</ExpanderButtonText>

          {active ? (
            <SVG svg="minus" css={css`width: 1rem;`} fill="black" />
          ) : (
            <SVG svg="plus" css={css`width: 1rem;`} fill="white" />
          )}
        </ExpanderButtonInner>
      </ExpanderButton>

      <Content ref={contentRef}>
        {typeof value === `object` ? value : <p className={small ? `b2` : `b1`} dangerouslySetInnerHTML={{ __html: value }} />}
      </Content>
    </Container>
  );
};

export default Expander;
