import React from "react";
import cn from "classnames";
import { useWindowDimensions } from "~hooks";
import { Image } from "~components";
import { truncateAddress } from "~utils/helpers";
import { Link } from "gatsby";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTInventoryCard = ({ className = ``, item: { name, balance, raw, contract, image, tokenId } }) => {
  const { screen, isMobile, isTablet } = useWindowDimensions();
  const certification = raw.metadata.certificatetype || `ALTR`;

  const cardHeader = (
    <header>
      <div className="badge">
        <svg width="146" height="20" viewBox="0 0 146 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3 0.500002L1.71396 0.500002L2.66226 1.36869L17.7189 15.1612C20.5771 17.7794 24.3126 19.2318 28.1887 19.2318L117.811 19.2318C121.687 19.2318 125.423 17.7795 128.281 15.1612L143.338 1.36869L144.286 0.5L143 0.5L3 0.500002Z"
            stroke="#959595"
          />
          <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle">
            {certification?.toUpperCase()}
          </text>
        </svg>
      </div>
      <div className="title">
        <h3 className={`${isMobile ? `b1` : `h3`}`}>{name || `Altr NFT`}</h3>
        <p>{contract.tokenType === `ERC1155` && raw.metadata.amount ? `${balance}/${raw.metadata.amount} Fragments` : `Whole Item`}</p>
      </div>
    </header>
  );

  return (
    <Link className="nftCard" to={`/profile/collection/${contract.address}/${tokenId}`}>
      <section className={cn(`front`, className)}>
        {cardHeader}
        <figure>{image && <Image image={image.cachedUrl} />}</figure>
      </section>

      {screen && !(isMobile || isTablet) && (
        <section className={cn(`back`, className)}>
          {cardHeader}

          <table className="caption">
            <tbody>
              <tr>
                <td>Collection: </td>
                <td>{truncateAddress(contract.address)}</td>
              </tr>
              <tr>
                <td>Id: </td>
                <td>{tokenId}</td>
              </tr>
            </tbody>
          </table>
        </section>
      )}
    </Link>
  );
};

export default NFTInventoryCard;
