import Button from "~components/Common/Button";

const AcceptOffer = () => (
  <section className="offer">
    <h3>Accept Offer</h3>
    <p>Lorem ipsum dolor sit amet consectetur. Orci est sed quam turpis tristique mauris felis eget.</p>
    <Button variant="primaryTall" fluid>
      Accept Offer
    </Button>
  </section>
);

export default AcceptOffer;
