import cn from "classnames";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";

const MarketplaceItemActionDrawer = forwardRef(({ children }, ref) => {
  const mql = useRef(null);
  const swipeableContainer = useRef();
  const [isExpanded, setIsExpanded] = useState(false);
  const { ref: swipeRef, ...swipeHandlers } = useSwipeable({
    onSwipedUp: () => {
      if (isExpanded || mql.current?.matches) return;

      setIsExpanded(true);
    },
    onSwipedDown: () => {
      if (!isExpanded || mql.current?.matches) return;
      if (swipeableContainer.current && swipeableContainer.current.scrollTop > 0) return;

      setIsExpanded(false);
    },
    preventScrollOnSwipe: !isExpanded
  });

  useEffect(() => {
    if (!isExpanded) document.body.classList.remove(`overflow-clip`);
    else document.body.classList.add(`overflow-clip`);

    return () => document.body.classList.remove(`overflow-clip`);
  }, [isExpanded]);

  useEffect(() => {
    if (!mql.current) mql.current = matchMedia(`(min-width: 640px)`);

    const onMediaChange = ({ matches }) => {
      if (matches) setIsExpanded(false);
    };

    mql.current.addEventListener(`change`, onMediaChange);

    return () => mql.current?.removeEventListener(`change`, onMediaChange);
  }, []);

  return (
    <div
      className={cn(`marketplaceItemActionDrawer`, isExpanded && `expanded`)}
      ref={(element) => {
        if (!element) return;

        swipeRef(element);
        swipeableContainer.current = element;

        if (typeof ref === `function`) ref(element);
        else if (typeof ref === `object` && ref !== null) ref.current = element;
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...swipeHandlers}
    >
      <button type="button" aria-label="Expand" className="expand" onClick={() => setIsExpanded((prevValue) => !prevValue)}>
        <span />
      </button>
      {children}
    </div>
  );
});

export default MarketplaceItemActionDrawer;
