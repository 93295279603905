/* eslint-disable import/no-extraneous-dependencies */

import React from "react";
import { SVG, PriceWidget } from "~components";
import { BLOCK_EXPLORER, BLOCK_EXPLORER_ADDRESS, truncateAddress } from "~utils/helpers";
import Button from "~components/Common/Button";
import Table from "~components/Common/Table";

/** ============================================================================
 * @component
 */
const ProfileHistoryReceipt = ({ transaction }) => {
  if (!transaction.id) {
    return null;
  }

  const additionalContent = (
    <>
      <dl>
        <dt>Ownership</dt>
        <dd>{transaction.nftType === `ERC721` ? `Whole` : `Fragment`}</dd>
      </dl>
      {transaction.nftType === `ERC1155` && (
        <dl>
          <dt>Amount</dt>
          <dd>{transaction.amount}</dd>
        </dl>
      )}
      {!!transaction.price && (
        <dl>
          <dt>Price</dt>
          <dd>
            <PriceWidget displayPrice={transaction.price} fontClass="b1" color="white" />
          </dd>
        </dl>
      )}
    </>
  );

  return (
    <Table.Row className="profileHistoryReceipt profileListItem transaction" additionalContent={additionalContent}>
      <Table.Cell as="dl">
        <dt>Date</dt>
        <dd>{transaction.date}</dd>
      </Table.Cell>
      <Table.Cell as="dl" className="action">
        <dt>Action</dt>
        <dd className="link">
          <Button href={`${BLOCK_EXPLORER}${transaction.id}`} variant="text">
            <span className="b2">{transaction.type}</span>
            <SVG svg="chain" />
          </Button>
        </dd>
      </Table.Cell>
      <Table.Cell as="dl">
        <dt>Asset</dt>
        <dd className="link">
          <Button href={`${BLOCK_EXPLORER_ADDRESS}${transaction.contractAddress}?a=${transaction.tokenId}`} variant="text">
            <span className="b2">{`${truncateAddress(transaction.contractAddress)}/${transaction.tokenId}`}</span>
            <SVG svg="chain" />
          </Button>
        </dd>
      </Table.Cell>
      <Table.Cell as="dl">
        <dt>From</dt>
        <dd className="link">
          <Button href={`${BLOCK_EXPLORER_ADDRESS}${transaction?.from}`} variant="text">
            <span>{transaction.displayFrom === `Altr Sale Contract` ? transaction.displayFrom : truncateAddress(transaction.displayFrom)}</span>
            <SVG svg="chain" />
          </Button>
        </dd>
      </Table.Cell>
      <Table.Cell as="dl">
        <dt>To</dt>
        <dd className="link">
          <Button href={`${BLOCK_EXPLORER_ADDRESS}${transaction?.to}`} variant="text">
            <span>{transaction.displayTo === `You` ? transaction.displayTo : truncateAddress(transaction.displayTo)}</span>
            <SVG svg="chain" />
          </Button>
        </dd>
      </Table.Cell>
    </Table.Row>
  );
};

export default ProfileHistoryReceipt;
