import React, { useMemo, useRef, useState } from "react";
import { SVG, LoadingSpinner, Modal } from "~components";
import { css } from "@emotion/react";
import { navigate } from "gatsby";
import useInventory from "~hooks/useInventory";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import ProfileDigitisingModal from "./ProfileDigitisingModal";
import { useProfileData } from "~context/ProfileContext";
import Tabs from "~components/Common/Tabs";
import ProfileCollectionFilters from "../ProfileCollection/ProfileCollectionFilters";
import useApp from "~hooks/useApp";
import { UserPurchaseabilityStatus } from "~dApp/models/profile/UserPurchaseabilityStatus";

const statusToColorMap = {
  0: `#FFCD6C`,
  10: `#FFCD6C`,
  20: `#3DF988`,
  25: `#FF6108`,
  30: `#919191`,
  [-10]: `#FF6C6C`
};

const ProfileDigitising = () => {
  const { kycData } = useApp();
  const { kycSession } = kycData;
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [sorting, setSorting] = useState(`latest`);
  const [search, setSearch] = useState(``);
  const [status, setStatus] = useState(-1);
  const [requestData, setRequestData] = useState(null);
  const container = useRef();

  const { api } = useExternalIntegrations();
  const { lightRequests, fullRequests, isDigitiseLoading: isLoading, refetchCertifications } = useProfileData();
  const { inventory } = useInventory();

  const sortAndFilter = (request) =>
    request
      .sort((a, b) =>
        sorting === `latest`
          ? new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
          : new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime()
      )
      .filter((req) => req.productName.toLowerCase().includes(search.toLowerCase().trim()))
      .filter((req) => (status === -1 ? true : req.lastStatus.status === status));

  const setNtfDetails = (requestIdentifier) => {
    const item = inventory?.find((nft) => nft.raw.metadata.nonce === requestIdentifier);

    if (item) navigate(`/profile/collection/${item.contract.address}/${item.tokenId}`);
  };

  const onSortingChange = (value) => setSorting(value);

  const onSearchChange = (value) => setSearch(value || ``);

  const onStatusChange = (value) => setStatus(+value);

  const onRequestClick =
    (requestId, isLight = true) =>
    async () => {
      setIsRequestLoading(true);

      try {
        const data = isLight ? await api.getLightCertificationRequestDetails(requestId) : await api.getFullCertificationRequestDetails(requestId);
        setRequestData(data);
        setIsRequestLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

  const filteredLightRequests = useMemo(() => sortAndFilter(lightRequests), [sorting, lightRequests, search, status]);
  const filteredFullRequests = useMemo(() => sortAndFilter(fullRequests), [sorting, fullRequests, search, status]);

  const onModalClose = () => setRequestData(null);

  const dropdowns = [
    {
      label: `Status`,
      options: [
        { value: -1, title: `All` },
        { value: 0, title: `Submitted` },
        { value: 10, title: `Processing` },
        { value: 20, title: `Approved` },
        { value: 30, title: `Burned` },
        { value: -10, title: `Rejected` }
      ],
      onChange: onStatusChange,
      value: status,
      defaultValue: -1
    },
    {
      label: `Sort by`,
      options: [
        { value: `latest`, title: `Latest` },
        { value: `oldest`, title: `Oldest` }
      ],
      onChange: onSortingChange,
      value: sorting,
      defaultValue: `latest`
    }
  ];

  const filtersSection = <ProfileCollectionFilters setSearchQuery={onSearchChange} dropdowns={dropdowns} />;
  const canSeeFull = [UserPurchaseabilityStatus.Verified, UserPurchaseabilityStatus.Whitelisted].includes(kycSession?.status);

  return (
    <section id="profileDigitising" ref={container}>
      {isRequestLoading && <LoadingSpinner />}
      <Tabs defaultActiveTab="light">
        <Tabs.TabsList className="profileTabs">
          <Tabs.Tab id="light">
            <span>Light {`(${lightRequests.length})`}</span>
          </Tabs.Tab>
          <Tabs.Tab id="full" disabled={!canSeeFull}>
            <span>Full {`(${fullRequests.length})`}</span>
          </Tabs.Tab>
        </Tabs.TabsList>
        <Tabs.TabContent id="light">
          <div className="info">
            <h2 className="title">Light</h2>
            <p>
              The light digitisation process not only grants you access to real-time market information on luxury items, but also provides an expert valuation
              of your collectible based on current market trends.
            </p>
          </div>
        </Tabs.TabContent>
        <Tabs.TabContent id="full">
          <div className="info">
            <h2 className="title">Full</h2>
            <p>
              The full digitisation process gives you access to lending, stores your items and also provides an expert valuation of your collectible based on
              current market trends.
            </p>
          </div>
        </Tabs.TabContent>
        <div className="profileList">
          {filtersSection}
          {isLoading && <LoadingSpinner className="loader" isInline />}
          <Tabs.TabContent id="light">
            {filteredLightRequests.map((item, index) => (
              <button type="button" className="profileListItem item" key={`request-${item.requestID}-${index}`} onClick={onRequestClick(item.requestID, true)}>
                <div className="title">
                  <span className="tag">{item.productType}</span>
                  <p className="max-3-lines">{item.productName}</p>
                </div>
                <div>
                  <span className="tag">Request id</span>
                  <p>{`${item.requestID}`}</p>
                </div>
                <div className="status">
                  <span className="tag">Status</span>
                  <p
                    className="tag"
                    css={css`
                      &::before {
                        background-color: ${statusToColorMap[item.lastStatus.status]};
                      }
                    `}
                  >
                    <span>{item.lastStatus.description}</span>
                  </p>
                </div>
                <SVG className="icon" svg="arrowAltRight" />
              </button>
            ))}
            {!isLoading && !filteredLightRequests.length && <p>No collectibles were found</p>}
          </Tabs.TabContent>
          <Tabs.TabContent id="full">
            {filteredFullRequests.map((item, index) => (
              <button type="button" className="profileListItem item" key={`request-${item.requestID}-${index}`} onClick={onRequestClick(item.requestID, false)}>
                <div className="title">
                  <span className="tag">{item.productType}</span>
                  <p className="max-3-lines">{item.productName}</p>
                </div>
                <div>
                  <span className="tag">Request id</span>
                  <p>{`${item.requestID}`}</p>
                </div>
                <div className="status">
                  <span className="tag">Status</span>
                  <p
                    className="tag"
                    css={css`
                      &::before {
                        background-color: ${statusToColorMap[item.lastStatus.status]};
                      }
                    `}
                  >
                    <span>{item.lastStatus.description}</span>
                  </p>
                </div>
                <SVG className="icon" svg="arrowAltRight" />
              </button>
            ))}
            {!isLoading && !filteredFullRequests.length && <p>No collectibles were found</p>}
          </Tabs.TabContent>
        </div>
      </Tabs>
      <Modal className="item-modal" isVisible={requestData} onClose={onModalClose} disableOutsideClick>
        <ProfileDigitisingModal request={requestData} setNftDetails={setNtfDetails} refresh={refetchCertifications} />
      </Modal>
    </section>
  );
};

export default ProfileDigitising;
