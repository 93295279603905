import { useAccount } from "wagmi";
import { TARGET_CHAIN, formatDate } from "~utils/helpers";
import { TermsAndConditionsVM } from "./models/ApiModel";

//FIXME: use siwe message library?
export default class SIWEServices {
  constructor() {}

  getLoginBaseMessage(termsAndConditions: TermsAndConditionsVM, walletAddress: string) {
    // FIXME: manage privacy policy
    // FIXME: manage issued-at field?
    const message = `${window.location.hostname} wants you to sign in with your Ethereum account:\n${walletAddress}
    \nI hereby accept:
    - The Altr Marketplace Terms of Service (Version: ${termsAndConditions.lastVersion} - ${formatDate(termsAndConditions.publishedOn)}).
      T&C: ${window.location.origin}/terms-and-conditions
    - The Privacy Policy: ${window.location.origin}/terms-and-conditions/privacy
    \nURI: ${window.location.href}\nChain ID: ${TARGET_CHAIN.id}\nNonce: `;
    return message;
  }

  getPurchaseTermsAndConditionBaseMessage(
    nftTermsAndConditions: TermsAndConditionsVM,
    vaultTermsAndConditions: TermsAndConditionsVM,
    collectionAddress: string,
    tokenId: string
  ) {
    // FIXME: why ${TARGET_CHAIN.id} and not connected wallet address?
    const message = `${window.location.hostname} wants you to sign in with your Ethereum account:\n${TARGET_CHAIN.id}
    \nFor the purchase of the product (collection: ${collectionAddress} - token Id:  ${tokenId}) I hereby accept:
    - The Altr Nft Terms of Service (Version: ${nftTermsAndConditions.lastVersion} - ${formatDate(nftTermsAndConditions.publishedOn)}).
      Nft T&C: ${window.location.origin}/terms-and-conditions/nft
    - The Vault Terms of Service (Version: ${vaultTermsAndConditions.lastVersion} - ${formatDate(vaultTermsAndConditions.publishedOn)}).
      Vault T&C: ${window.location.origin}/terms-and-conditions/vault
    \nURI: ${window.location.href}\nChain ID: ${TARGET_CHAIN.id}\nNonce: `;
    return message;
  }

  getFpocBaseMessage() {
    const { address } = useAccount();
    return `${window.location.hostname} wants you to sign this message with your Ethereum account:\n${address}
        \nI hereby state that I own access to the email linked to this private link.
        \nURI: ${window.location.href}\nVersion: 1\nChain ID: ${TARGET_CHAIN.id}\nNonce: `;
  }

  async getFpocMessage(nonce: string) {
    return this.getFpocBaseMessage() + nonce;
  }

  getLoginMessage(termsAndConditions: TermsAndConditionsVM, nonce: string, walletAddress: string) {
    return this.getLoginBaseMessage(termsAndConditions, walletAddress) + nonce;
  }

  getPurchaseTermsAndConditionMessage(
    nftTermsAndConditions: TermsAndConditionsVM,
    vaultTermsAndConditions: TermsAndConditionsVM,
    collectionAddress: string,
    tokenId: string,
    nonce: string
  ) {
    return this.getPurchaseTermsAndConditionBaseMessage(nftTermsAndConditions, vaultTermsAndConditions, collectionAddress, tokenId) + nonce;
  }
}
