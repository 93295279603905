import React, { useState } from "react";
import styled from "@emotion/styled";
import cn from "classnames";
import { Button, TextInput } from "~components";
import { breakpoint } from "~utils/css";
import regex from "~constants/regex";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import useApp from "~hooks/useApp";
import useWindowDimensions from "~hooks/useWindowDimensions";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  animation: 0.5s var(--cubic-easing) appear-down;
  background: var(--color-mono-10);
  padding: 0.75rem;
  display: flex;
  flex-direction: column-reverse;
  color: var(--color-black);

  ${breakpoint(`large-tablet`)} {
    margin-top: 0;
  }

  .feeTerms {
    margin-top: 1rem;
    color: var(--color-mono-60);
  }

  span {
    margin-left: 5px;
  }

  .cancelButton {
    margin-top: 0.8rem;
  }

  .walletData {
    &Image {
      filter: grayscale(1);

      &Connected {
        filter: none;
      }
    }

    &ConnectText {
      margin: 60px 0;
    }

    &IdentityVerificationCaption {
      margin-bottom: 0.5rem;
    }

    &IdentityVerification {
      background: var(--color-white);
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
      border-radius: 2px;
    }

    &FormField {
      margin-bottom: 1rem;
    }
  }

  ${breakpoint(`large-tablet`)} {
    flex-direction: row;
    gap: 1.5rem;
    padding: 1.25rem;
    .walletData {
      &Content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
      }
      &ConnectText {
        margin: 120px 0;
      }
    }
  }

  .description {
    margin-bottom: 1rem;
  }
`;

const Partition = styled.div`
  border-top: 1px solid var(--color-mono-40);
  margin: 1rem 0;
`;
/** ============================================================================
 * @component
 * @param {boolean} connected - Whether the user is connected to a wallet.
 * @param {string} className - For additional styling config
 * @return {node}
 */

const FiatPaymentForm = ({ className, enrichedProduct }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const externalIntegration = useExternalIntegrations();
  const { api } = externalIntegration;
  const { setActiveOverlay, setOverlayCompletionData } = useApp();
  const { isMobile } = useWindowDimensions();
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [formValues, setFormValues] = useState({
    name: ``,
    address: ``,
    email: ``,
    phoneNumber: ``,
    id: ``,
    productIdentifier: enrichedProduct?.product?.identifier
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    address: false,
    email: false,
    phoneNumber: false,
    id: false
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  const isFormReadyToSubmit = () => {
    if (isSubmitting || hasSubmitted) {
      return false;
    }

    if (!formValues.name || !formValues.email || !regex.email.test(formValues.email) || !formValues.address || !formValues.phoneNumber) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const res = await api.sendBuyAndClaimPurchaseRequest(formValues);

    setIsSubmitting(false);
    setHasSubmitted(true);

    if (res?.status === 200) {
      setActiveOverlay(null);
      setOverlayCompletionData({
        icon: `upload`,
        heading: `Congratulations! Your order has been received.`,
        body: `An invoice will be sent to the email address you provided, containing the payment details. Please remember to check both your inbox and spam folder regularly.
        Please note that the item will not be reserved exclusively for you. It remains available for purchase by other users on a first-come, first-served basis. The first user to complete the purchase process will become the final owner of the item.`
      });
    } else {
      alert(`Error, please try again!`); // FIXME
    }

    return true;
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container className={cn(className)}>
      <div className="walletDataContent">
        <div>
          <h2 className="h3">Billing and Contact</h2>
          <Partition />
        </div>

        <div className="description">
          <p>
            Please provide your billing and contact information. You will receive an email with an IBAN and detailed instructions for completing your purchase
            of the{` `}
            {enrichedProduct?.product?.productName} via wire transfer.
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <TextInput
            required
            className="walletDataFormField"
            label="Full name | Company name"
            placeholder="Your Name"
            value={formValues.name}
            error={formErrors.name}
            onBlur={() => {
              if (!formValues.name) {
                setFormErrors((prev) => ({ ...prev, name: true }));
              }
            }}
            onChange={(value) => {
              setFormErrors((prev) => ({ ...prev, name: false }));
              setFormValues((prev) => ({ ...prev, name: value }));
            }}
          />

          <TextInput
            required
            className="walletDataFormField"
            label="Address"
            placeholder="Address"
            value={formValues.address}
            error={formErrors.address}
            onBlur={() => {
              if (!formValues.address) {
                setFormErrors((prev) => ({ ...prev, address: true }));
              }
            }}
            onChange={(value) => {
              setFormErrors((prev) => ({ ...prev, address: false }));
              setFormValues((prev) => ({ ...prev, address: value }));
            }}
          />

          <TextInput
            className="walletDataFormField"
            label="Tax ID | VAT Number"
            placeholder="Tax ID | VAT Number"
            value={formValues.id}
            error={formErrors.id}
            onChange={(value) => {
              setFormErrors((prev) => ({ ...prev, id: false }));
              setFormValues((prev) => ({ ...prev, id: value }));
            }}
          />

          <TextInput
            required
            className="walletDataFormField"
            label="Email"
            placeholder="Your Email"
            value={formValues.email}
            error={formErrors.email}
            onBlur={() => {
              if (!regex.email.test(formValues.email)) {
                setFormErrors((prev) => ({ ...prev, email: true }));
              }
            }}
            onChange={(value) => {
              setFormErrors((prev) => ({ ...prev, email: false }));
              setFormValues((prev) => ({ ...prev, email: value }));
            }}
          />

          <TextInput
            required
            className="walletDataFormField"
            label="Phone Number"
            placeholder="+39 3333333333"
            value={formValues.phoneNumber}
            error={formErrors.phoneNumber}
            onBlur={() => {
              if (!formValues.phoneNumber) {
                setFormErrors((prev) => ({ ...prev, phoneNumber: true }));
              }
            }}
            onChange={(value) => {
              setFormErrors((prev) => ({ ...prev, phoneNumber: false }));
              setFormValues((prev) => ({ ...prev, phoneNumber: value }));
            }}
          />

          <div className="walletDataFormField">
            {parseFloat(enrichedProduct?.nftData?.takerFee.split(`%`)[0]) > 0 && (
              <p className="b2 feeTerms">Price includes {enrichedProduct?.nftData?.takerFee} platform fee.</p>
            )}
          </div>

          <Button disabled={!isFormReadyToSubmit()} buttonType="submit" variant="secondary" colorTheme="dark" fluid>
            Send
          </Button>
          {isMobile && (
            <Button
              colorTheme="dark"
              fluid
              variant="primary"
              onClick={() => {
                setActiveOverlay(null);
              }}
              className="cancelButton"
            >
              Cancel Offer
            </Button>
          )}
        </form>
      </div>
    </Container>
  );
};

export default FiatPaymentForm;
