import { ProductPurchaseStatus } from "./ProductPurchaseStatus";

/** ============================================================================
 * ProductState
 * Class that wraps the ProductPurchaseStatus enum. Removes the need to import
 * ProductPurchaseStatus anywhere else in the app, allowing for simple string
 * comparators against an implicitly valid status with overloaded is() helpers.
 * 
 * e.g. instead of:
 * someStatus === ProductPurchaseStatus.OnSale || someStatus === ProductPurchaseStatus.NFTBurned || ...
 * 
 * We can do:
 * thisSingleton.is([`OnSale`, `NFTBurned`, ...])
 */
class ProductState {
  status: string;

  constructor(status: string) {
    if (!status) {
      throw new Error(`Status is required`);
    }

    if (!(status in ProductPurchaseStatus)) {
      throw new Error(`ProductPurchaseStatus does not contain status: ${status}`);
    }

    this.status = ProductPurchaseStatus[status];
  }

  is(status: string): boolean;

  is(status: string[]): boolean;

  is(status: string | string[]): boolean {
    if (typeof status === 'string') {
      return this.status === status;
    }

    return status.includes(this.status);
  }
}

export default ProductState;
