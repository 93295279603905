// import React, { useEffect, useState } from "react";
import React from "react";
import { css } from "@emotion/react";
// import { Link } from "~components";

// const Container = styled.div`
//   text-align: center;
//   padding: 20px;
//   & > a {
//     text-decoration: underline;
//   }
// `;

const Loader = ({ dark = false, inline = false, visible = true }) => (
  <section
    css={css`
      transition: 1s var(--cubic-easing) opacity;

      opacity: ${!visible ? 0 : 1};
      pointer-events: none;

      width: ${inline ? `100%` : `100vw`};
      height: ${inline ? `100%` : `100vh`};
      position: ${inline ? `absolute` : `fixed`};
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 20;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: ${dark ? `black` : `var(--color-mono-10)`};
      color: ${dark ? `white` : `black`};
    `}
  >
    <h4
      css={css`
        margin-bottom: 1rem;
      `}
      className="caption"
    >
      LOADING...
    </h4>
  </section>
);

export default Loader;
