import React, { useEffect, useState } from "react";
import { useApp } from "~hooks";
import { Button, Link, NFTOverlay } from "~components";
import { usePublicClient } from "wagmi";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError, parseError } from "~utils/error";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayBuyoutQuote = ({ nft }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const { setOverlayCompletionData, setOverlayProcessingData, userData } = useApp();
  const { useRequestBuyout } = blockchainHooks();
  const publicClient = usePublicClient();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [executing, setExecuting] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  const { writeAsync: doRequetBuyout, isPrepareError, prepareError, isSuccess, txData } = useRequestBuyout(nft?.enrichedProduct?.nftData);

  useEffect(() => {
    if (isSuccess) {
      setOverlayCompletionData({
        icon: `mail`,
        heading: `Request sent (ID: ${txData.transactionHash})`,
        body: `Your request has been sent to our team. We will contact you within 3 business days.`
      });
      setOverlayProcessingData(null);
      setExecuting(false);
    }
  }, [isSuccess]);

  // ---------------------------------------------------------------------------
  // methods

  const sendQuote = async () => {
    if (!nft?.enrichedProduct?.nftData || executing) {
      return () => {};
    }

    setExecuting(true);

    setOverlayProcessingData({
      icon: `load`,
      text: `Processing. Please do not close this page.`
    });

    // @externalintegration
    try {
      await doRequetBuyout();
    } catch (e) {
      console.error(e);
      handleError(e, setOverlayCompletionData, await publicClient.getBalance({ address: userData?.address }));
    }

    setOverlayProcessingData(null);
    setExecuting(false);

    return null;
  };

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  return (
    <NFTOverlay id="NFTOverlayBuyoutQuote" heading="Buyout" nft={nft}>
      <section className="nftEntry buyout">
        <h3 className="caption">Want to buy the whole item?</h3>

        <p className="caption">
          In order to perform a buyout of the whole item, you will need to receive a quotation that will be provided within 3 business days.
        </p>

        <p className="caption">
          For more detailed information read our{` `}
          <Link to="/">documentation here</Link>.
        </p>

        <Button onClick={sendQuote} variant="primaryTall" fluid>
          <span className="b1">{isPrepareError ? parseError(prepareError, userData?.address, setOverlayCompletionData) : `Request Buyout Quotation`}</span>
        </Button>
      </section>
    </NFTOverlay>
  );
};

export default NFTOverlayBuyoutQuote;
