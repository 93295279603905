import React from "react";
import { Button } from "~components";

const referenceTypeToSegmentMap = {
  page: ``,
  product: `/products`,
  event: `/events`,
  raffle: `/raffles`
};

const LinkButton = ({ link, colorTheme, variant, fluid, className }) => {
  if (link?.type === `linkExternal`) {
    const { externalLink } = link;
    const { newTab, text, url } = externalLink;

    return (
      <Button href={url} newTab={newTab} colorTheme={colorTheme} variant={variant} fluid={fluid} className={className || null}>
        {text}
      </Button>
    );
  }

  if (link?.type === `globalLink`) {
    const { globalLink, newTab } = link;
    const { link: linkData } = globalLink;

    return (
      <Button fluid={fluid} newTab={newTab} colorTheme={colorTheme} variant={variant} className={className || null} href={linkData.url}>
        {linkData.text}
      </Button>
    );
  }

  if (link?.type === `linkInternal`) {
    const { internalLink } = link;
    const { text, reference } = internalLink;
    const slugPrefix = referenceTypeToSegmentMap[reference._type];
    const slug = reference.slug.current === `/` ? `/` : `${slugPrefix}/${reference.slug.current}`;

    return (
      <Button className={className || null} fluid={fluid} colorTheme={colorTheme} variant={variant} to={slug}>
        {text}
      </Button>
    );
  }

  return null;
};

export default LinkButton;
