import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Image, Link } from "~components";
import { hoverable } from "~utils/css";
import { formatDateTime, getDotColor } from "~utils/helpers";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import ProductState from "~dApp/models/product/ProductState";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  transition:
    color 0.3s var(--cubic-easing),
    background-color 0.3s var(--cubic-easing);

  padding: 20px;

  width: 100%;
  height: 100%;
  position: relative;

  display: flex;
  flex-direction: column;

  gap: 20px;

  background: var(--color-mono-20);
  color: var(--color-black);
  border-radius: 12px;

  overflow: hidden;

  .product-card-stat {
    flex-basis: 50%;
  }

  ${hoverable} {
    &:hover {
      ${({ purchaseable }) => `${purchaseable ? `background: var(--color-mono-90); color: var(--color-mono-0);` : ``}`};

      figure {
        transform: scale(1.05);
      }
    }
  }
`;

const Label = styled.h4`
  margin: 0 0 0.5rem;
  text-transform: uppercase;
  color: var(--color-mono-40);
`;

const CardMedia = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;
  border-radius: 10px;
`;

const Shadow = styled.div`
  position: absolute;
  z-index: 10;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.9) inset;
  width: 100%;
  height: 100%;
`;

const CardFigure = styled.figure`
  transition: 0.3s var(--cubic-easing) transform;

  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  border-radius: 10px;
`;

const CardContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  height: 100%;

  .cardContent {
    &Row {
      display: flex;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 10px;

      &Item {
        width: 50%;
      }
    }
  }
`;

const CardHeading = styled.header`
  width: 100%;

  .cardHeading {
    &Status {
      display: flex;
      align-items: center;
    }

    &Title {
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 33.6px */
      letter-spacing: -0.24px;
    }
  }
`;

const StatusDot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-right: 6px;
  background-color: ${({ color }) => color};
`;

const Separator = styled.div`
  border: 1px solid ${({ color }) => color};
  border-bottom: 0;
  margin: 0.3rem 0;
  height: 1px;
  width: 100%;
`;
/** ============================================================================
 * @component
 */
const ProductCard = ({ data }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const ref = useRef();

  const { api } = useExternalIntegrations();

  const [product, setProduct] = useState(data);
  const [nftStatus, setNftStatus] = useState();
  // ---------------------------------------------------------------------------
  // methods

  const getStatusText = () => {
    switch (nftStatus?.status) {
      case `CollectUserInterest`:
        return `Soon On Sale`;

      case `OnSale`:
      case `OnFractionsSale`:
        return `On Sale`;

      case `OnFractionsSaleSuccess`:
      case `SoldOut`:
        return `On Secondary Market`;

      case `NFTBurned`:
        return `Claimed`;

      case `OnFractionsSaleFailed`:
        return `Sale Failed`;

      default:
        return `Unavailable`;
    }
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (product?.lucid) {
      return;
    }
    const getNftStatus = async (lucidProduct) => {
      const controlStatus = await api.getProductPurchaseStatus(lucidProduct?.productGuid);

      const status = new ProductState(controlStatus);

      if (status) {
        setNftStatus(status);
      }
    };

    const enrichProduct = async (productData) => {
      if (!productData?.productGuid) {
        return productData;
      }

      const lucid = await api.getProductById(productData?.productGuid, productData?.collection?.title?.toLowerCase());

      return {
        ...productData,
        lucid
      };
    };

    const enrich = async () => {
      const enrichedProduct = await enrichProduct(data);
      await getNftStatus(enrichedProduct);
      setProduct(enrichedProduct);
    };

    enrich().catch((e) => console.error(e));
  }, [data?.productGuid, data?.collection?.title]);

  // ---------------------------------------------------------------------------
  // render
  let mintDate = ``;

  if (product?.lucid?.nftData?.mintDate) {
    mintDate = formatDateTime(product.lucid.nftData.mintDate);
  }

  return (
    <Link ref={ref} to={`/products/${product.slug.current}`}>
      <Container purchaseable>
        <CardHeading>
          <div className="cardHeadingStatus">
            <StatusDot color={getDotColor(nftStatus)} />
            <h1 className="caption">{getStatusText()}</h1>
          </div>
          <Separator />
          <h1 className="cardHeadingTitle">{product?.title}</h1>
        </CardHeading>
        {product?.image && (
          <CardMedia
            css={css`
              padding-bottom: 125%;
            `}
          >
            <CardFigure purchaseable>
              <Shadow />
              <Image
                image={product.image}
                css={css`
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 10px;
                `}
              />
            </CardFigure>
          </CardMedia>
        )}

        <CardContent>
          <div className="cardContentRow">
            {product?.lucid?.product?.oracle?.text && (
              <div className="cardContentRowItem">
                <Label className="product-card-label caption">ORACLE</Label>
                {product.lucid.product.oracle.text}
              </div>
            )}
            {(product?.lucid?.nftData && (
              <div className="cardContentRowItem">
                <Label className="product-card-label caption">PRICE</Label>
                {api.getNftPrice(product.lucid.nftData)}
              </div>
            )) ||
              (mintDate && (
                <div className="cardContentRowItem">
                  <Label className="product-card-label caption">Minted on</Label>
                  {mintDate}
                </div>
              ))}
          </div>

          <div className="cardContentRow">
            {product?.lucid?.product?.serialNumber && (
              <div className="cardContentRowItem">
                <Label className="product-card-label caption">REF:</Label>
                {product?.lucid?.product?.serialNumber}
              </div>
            )}
            {product?.lucid?.nftData && (
              <div className="cardContentRowItem">
                <Label className="product-card-label caption">Commission</Label>
                <p className="b1">{product?.lucid?.nftData?.commission}</p>
              </div>
            )}
          </div>
        </CardContent>
      </Container>
    </Link>
  );
};

export default ProductCard;
