import React from "react";
import cn from "classnames";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTTextEntry = ({ className = ``, name = ``, onChange = () => {}, heading, placeholder = ``, value }) => (
  <div className={cn(`nftEntry`, className)}>
    <h3 className="caption">{heading}</h3>
    <div className="nftInput">
      <input className="b1" name={name} onChange={onChange} placeholder={placeholder} type="text" value={value} />
    </div>
  </div>
);

export default NFTTextEntry;
