import React from "react";
import styled from "@emotion/styled";
import cn from "classnames";
import { ImageRatio } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  .featuredImage {
    &Mobile {
      ${breakpoint(`small-tablet`)} {
        display: none;
      }
    }

    &Desktop {
      display: none;

      ${breakpoint(`small-tablet`)} {
        display: block;
      }
    }
  }
`;

/** ============================================================================
 * @component
 */
const FeaturedImage = ({ data: { image, cropOnMobile } }) => (
  <Container>
    {cropOnMobile && <ImageRatio image={image.asset.gatsbyImageData} className="featuredImageMobile" altText={image.altText} ratio="2/3" />}

    <ImageRatio image={image.asset.gatsbyImageData} className={cn({ [`featuredImageDesktop`]: cropOnMobile })} altText={image.altText} ratio="16/9" />
  </Container>
);

export default FeaturedImage;
