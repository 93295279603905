import { useState, useEffect } from "react";
import AltrApiService from "~dApp/AltrApiService";
import { NftStatus } from "~dApp/models/ApiModel";

const useEnrichedProducts = (allSanityProduct) => {
  const altrApiService = new AltrApiService();

  const [enrichedProducts, setEnrichedProducts] = useState([]);

  useEffect(() => {
    const enrichAll = async () => {
      if (allSanityProduct?.edges?.[0]) {
        const sanityProducts = allSanityProduct.edges.map(({ node }) => node);

        const sanityIds = sanityProducts
          .filter((item) => item.productGuid)
          .map(({ productGuid, collection }) => ({
            id: productGuid,
            type: collection.title.toLowerCase()
          }));

        const lucidData = await altrApiService.getLucidProductsByIds(sanityIds);

        const joinedData = await Promise.all(
          lucidData
            .filter((item) => item.nftData?.saleStatus >= NftStatus.Hidden)
            .map(async (item) => {
              const sanityProduct = sanityProducts.find(
                (product) => product?.productGuid === item?.product?.identifier
              );
              const enrichedProduct = await altrApiService.enrichSanityProduct(
                sanityProduct
              );

              return enrichedProduct;
            })
        );

        setEnrichedProducts(
          joinedData.filter((enrichedProduct) => enrichedProduct?.product)
        );
      }
    };

    enrichAll();
  }, [allSanityProduct?.edges?.length]);

  return {
    enrichedProducts
  };
};

export default useEnrichedProducts;
