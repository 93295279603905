import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import gsap from "gsap";
import { v4 as uuid } from "uuid";

import { Grid, TextChanger, CtaButton } from "~components";
import { useScroll, useDevice } from "~hooks";

import { breakpoint } from "~utils/css.js";

/** ============================================================================
 * @css
 */

const Container = styled.section`
  padding: 4rem 0 8rem;
`;

const Header = styled.header`
  grid-column: 1 / -1;
  margin-bottom: 6rem;

  ${breakpoint(`large-tablet`)} {
    position: sticky;
    top: 8rem;

    align-self: start;

    grid-column: 1 / span 6;
    padding-right: 4vw;
    margin-bottom: 0;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: 2 / span 5;
  }
`;

const Body = styled.p`
  margin-top: 1.5rem;
  ${({ fontSize }) => (fontSize ? `font-size: ${fontSize};` : ``)}
`;

const Ctas = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 3.75rem;
  gap: 1rem;

  & > div {
    flex: 1 0 12rem;
    text-align: center;
    display: flex;
    align-items: stretch;
    justify-content: center;
  }
  a {
    width: 100% !important;
  }
  a > span {
    border: 1px solid #000000;
    padding: 1rem;
    height: 100%;
  }
`;

const StepsWrapper = styled.div`
  grid-column: 1 / -1;
  position: relative;

  display: flex;

  ${breakpoint(`large-tablet`)} {
    grid-column: 7 / span 6;
  }
`;

const Steps = styled.ol`
  position: relative;
  margin: 0 1.5rem;

  strong {
    display: block;
  }
`;

const NotchClip = styled.div`
  display: none;

  ${breakpoint(`large-tablet`)} {
    display: block;

    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    clip: rect(auto, auto, auto, auto);
    overflow: hidden;
    pointer-events: none;
    z-index: 10;
  }
`;
const Notch = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 0;
  left: calc(50% + 0.75rem);
  transform: translate3d(-50%, 0, 0);
  background: white;
`;

/** ============================================================================
 * @component
 */
const FourStepScroll = ({ data: { backgroundColour, fontColour, headings, body, links, linkedSteps, bodyFontSize } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const containerRef = useRef();
  const { scrollTop } = useScroll();
  const { deviceAbove } = useDevice();

  // ---------------------------------------------------------------------------
  // methods
  const setDefaultPercent = () => {
    if (deviceAbove(`desktop`)) {
      return 20;
    }

    if (deviceAbove(`large-tablet`)) {
      return 15;
    }

    return 12;
  };

  const setPercentScale = () => {
    if (deviceAbove(`large-tablet`)) {
      return 2;
    }

    if (deviceAbove(`small-mobile`)) {
      return 1.4;
    }

    return 1.2;
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!containerRef?.current) return;

    const { offsetTop, clientHeight } = containerRef.current;

    const defaultPercent = setDefaultPercent();
    const percentScale = setPercentScale();

    const percent = ((scrollTop - offsetTop) / clientHeight) * 100 * percentScale;

    let yPercent = defaultPercent;

    if (percent < defaultPercent) {
      yPercent = defaultPercent;
    }
    if (percent + defaultPercent >= defaultPercent) {
      yPercent = percent + defaultPercent;
    }
    if (percent > 100 - defaultPercent) {
      yPercent = 100;
    }

    const y = parseFloat(0 - 100 + yPercent).toFixed(2);

    gsap.to(`.steps-bar`, {
      duration: 0,
      y: `${y}%`
    });
  }, [containerRef, scrollTop, deviceAbove]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container className="bg-wrapper" style={{ "--wrapper-background": backgroundColour?.hex || `#ffffff` }} ref={containerRef}>
      <NotchClip>
        <Notch />
      </NotchClip>

      <Grid>
        <Header>
          <TextChanger headings={headings} />

          <Body className="h4" fontSize={bodyFontSize}>
            {body}
          </Body>

          {!!links && !!links.length && (
            <Ctas>
              {links.map((link, index) => (
                <CtaButton key={link._key} cta={link} variant="primaryTall" colorTheme={index % 2 === 0 ? `dark` : `light`} />
              ))}
            </Ctas>
          )}
        </Header>

        <StepsWrapper>
          <div
            css={css`
              width: 5px;
              height: 100%;
              background-color: var(--color-mono-20);
              overflow: hidden;
            `}
          >
            <div
              className="steps-bar"
              css={css`
                width: 100%;
                height: 100%;
                background-color: var(--color-mono-100);
              `}
            />
          </div>

          <Steps>
            {linkedSteps?.[0] &&
              linkedSteps.map(({ heading, subheading }, index) => (
                <li
                  key={`step-${uuid()}`}
                  css={css`
                    position: relative;
                    margin-bottom: 3rem;
                  `}
                >
                  <strong className="caption" aria-hidden>
                    STEP
                  </strong>

                  <strong
                    css={css`
                      margin: 0.5rem 0 0.75rem;
                    `}
                    className="d1"
                    aria-hidden
                  >
                    0{index + 1}
                  </strong>

                  <h3
                    css={css`
                      margin: 1rem 0;
                    `}
                    className="h3"
                  >
                    {heading}
                  </h3>

                  {subheading && <p className="b2">{subheading}</p>}
                </li>
              ))}
          </Steps>
        </StepsWrapper>
      </Grid>
    </Container>
  );
};

export default FourStepScroll;
