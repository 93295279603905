import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useApp } from "~hooks";
import { NFTOverlay } from "~components";
import { breakpoint } from "~utils/css";
import { usePublicClient } from "wagmi";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError } from "~utils/error";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  grid-column: 1 / -1;
  position: relative;
  padding: 1rem 1rem 0;

  ${breakpoint(`large-tablet`)} {
    padding: 1rem 2.5rem 0;
  }

  .overlayConvertFragmentToWhole {
    &Hint {
      color: var(--color-mono-60);
      margin-bottom: 2rem;
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayConvertFragmentToWhole = ({ nft }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const { useConvertFragmentsToWhole } = blockchainHooks();
  const { setOverlayCompletionData, setOverlayProcessingData, userData } = useApp();
  const publicClient = usePublicClient();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [executing, setExecuting] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  const { writeAsync: doConvertFragment, isSuccess } = useConvertFragmentsToWhole(nft?.enrichedProduct?.nftData);

  useEffect(() => {
    if (isSuccess) {
      setOverlayCompletionData({
        icon: `check`,
        heading: `Successfully converted Fragments to Whole`
      });
      setOverlayProcessingData(null);
      setExecuting(false);
    }
  }, [isSuccess]);

  // ---------------------------------------------------------------------------
  // methods

  const convert = async () => {
    if (!nft?.enrichedProduct?.nftData || executing) {
      return () => {};
    }

    setExecuting(true);

    setOverlayProcessingData({
      icon: `load`,
      text: `Processing. Please do not close this page.`
    });

    // @externalintegration
    try {
      await doConvertFragment();
    } catch (e) {
      console.error(e);
      handleError(e, setOverlayCompletionData, await publicClient.getBalance({ address: userData?.address }));
    }

    setOverlayProcessingData(null);
    setExecuting(false);

    return null;
  };

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  return (
    <NFTOverlay id="NFTOverlayConvertFragmentToWhole" heading="Buyout" nft={nft} onActive={convert}>
      {({ active }) => <Container>{active && <></>}</Container>}
    </NFTOverlay>
  );
};

export default NFTOverlayConvertFragmentToWhole;
