import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import gsap from "gsap";
import { Grid, RippleImage } from "~components";
import { useDevice } from "~hooks";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  width: 100vw;
  max-width: 100%;
  position: relative;
  overflow: hidden;
  background: ${({ background }) => background || `#ffffff`};
  color: ${({ color }) => color || `#000000`};

  ${breakpoint(`tablet`)} {
    height: 87.75vw;
  }

  ${breakpoint(`large-tablet`)} {
    height: 52.91666667vw;
  }
`;

//

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
  display: flex;
  justify-content: center;
  padding-top: 2.5rem;
  color: var(--color-white);
  mix-blend-mode: difference;
  text-align: center;

  ${breakpoint(`large-tablet`)} {
    align-items: center;
    padding-top: 0;
    text-align: left;
  }
`;

const OverlayHeader = styled.header`
  animation: var(--animation-appear-up);
  animation-delay: 300ms;

  transform: translate3d(0, 1rem, 0);
  opacity: 0;

  grid-column: -1 / 1;
  position: relative;
  white-space: pre-wrap;

  ${breakpoint(`large-tablet`)} {
    grid-column: span 5 / span 5;
  }
`;

const OverlayBody = styled.p`
  animation: var(--animation-appear-up);
  animation-delay: 500ms;

  transform: translate3d(0, 1rem, 0);
  opacity: 0;

  grid-column: span 5 / span 5;
  grid-column-start: 1;
  position: relative;
  margin-top: 1rem;
`;

//

const FullAbsoluteGPU = `
  transform: translate3d(0, 0, 0);
  perspective: 1000;
  backface-visibility: hidden;

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
const LightImage = styled.div`
  ${FullAbsoluteGPU}
  z-index: 10;
`;

const DarkImage = styled.div`
  ${FullAbsoluteGPU}
  z-index: 20;
`;

/** ============================================================================
 * @component
 */
const ALTRClipper = ({ data: { backgroundColour, imageLight, imageDark, heading, body } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { deviceAbove } = useDevice();

  const containerRef = useRef();

  const [cursorTimeout, setCursorTimeout] = useState(null);

  const [pos, setPos] = useState({
    x: 0,
    y: 0
  });

  const [prevPos, setPrevPos] = useState({
    x: 0,
    y: 0
  });

  // ---------------------------------------------------------------------------
  // variables

  const CURSOR_SIZE = 300;

  // const imageSrcLight = getFallbackImageSrc(imageLight);
  // const imageSrcDark = getFallbackImageSrc(imageDark);
  const imageSrcLight = imageLight;
  const imageSrcDark = imageDark;

  // ---------------------------------------------------------------------------
  // methods

  const handleMouseMove = (e) => {
    if (!containerRef?.current) {
      return;
    }

    const x = e.pageX - containerRef.current.offsetLeft;
    const y = e.pageY - containerRef.current.offsetTop;

    setPos({
      x,
      y
    });
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  const isMobile = deviceAbove(`small-tablet`) === false;

  useEffect(() => {
    if (typeof window === `undefined` || !containerRef?.current || isMobile) {
      return () => {
        window.removeEventListener(`mousemove`, handleMouseMove, false);
      };
    }

    window.addEventListener(`mousemove`, handleMouseMove, false);

    return () => {
      window.removeEventListener(`mousemove`, handleMouseMove, false);
    };
  }, [containerRef, isMobile]);

  useEffect(() => {
    if (cursorTimeout) {
      clearTimeout(cursorTimeout);
    }

    const transition = {
      duration: 2,
      ease: `expo.out`
    };

    let velocityX = pos.x - prevPos.x;
    let velocityY = pos.y - prevPos.y;

    if (velocityX < 0) {
      velocityX = -velocityX;
    }

    if (velocityY < 0) {
      velocityY = -velocityY;
    }

    let scale = (velocityX + velocityY) / 75;

    if (scale < 1) {
      scale = 1;
    } else if (scale > 2.5) {
      scale = 2.5;
    }

    gsap.to(`.altr-clipper-dark-image`, {
      ...transition,
      clipPath: `circle(${CURSOR_SIZE * scale}px at ${pos.x}px ${pos.y}px)`
    });

    setPrevPos(pos);

    //
    // ensure the cursor returns to its default size on idle

    return () => {
      setCursorTimeout(
        setTimeout(() => {
          gsap.to(`.altr-clipper-dark-image`, {
            ...transition,
            clipPath: `circle(${CURSOR_SIZE}px at ${pos.x}px ${pos.y}px)`
          });
        }, 100)
      );
    };
  }, [pos?.x, pos?.y]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <>
      <Container ref={containerRef} role="presentation" background={backgroundColour?.hex}>
        <Overlay>
          <Grid node="article">
            {(heading || body) && <OverlayHeader>{heading && <h1 className="h1">{heading}</h1>}</OverlayHeader>}

            {body && deviceAbove(`tablet`) && <OverlayBody className="b1">{body}</OverlayBody>}
          </Grid>
        </Overlay>

        <LightImage>
          <RippleImage imageUrl={imageSrcLight} pos={pos} />
        </LightImage>

        <DarkImage className="altr-clipper-dark-image">
          <RippleImage imageUrl={imageSrcDark} pos={pos} />
        </DarkImage>
      </Container>
    </>
  );
};

export default ALTRClipper;
