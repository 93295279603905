import React, { useState } from "react";
import styled from "@emotion/styled";
import { v4 as uuid } from "uuid";
import { useDevice, useInterval } from "~hooks";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const LINE_HEIGHT_XS = 40;
const LINE_HEIGHT = 70;

const Container = styled.hgroup`
  width: 100%;
  height: ${LINE_HEIGHT_XS * 2}px;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: center;

  ${breakpoint(`large-tablet`)} {
    height: ${LINE_HEIGHT * 2}px;
  }
`;

const TextContainer = styled.h3`
  height: 100%;
  position: relative;
  color: var(--color-mono-40);
`;

const Text = styled.span`
  display: block;
  line-height: ${LINE_HEIGHT_XS}px;

  ${breakpoint(`large-tablet`)} {
    line-height: ${LINE_HEIGHT}px;
  }
`;

const Title = styled.p`
  display: block;
  line-height: ${LINE_HEIGHT_XS}px;

  ${breakpoint(`large-tablet`)} {
    line-height: ${LINE_HEIGHT}px;
  }
`;

const MediaContainer = styled.div`
  height: 100%;
  position: relative;
  flex: 1 0 auto;
  overflow: hidden;
`;

const MediaTransformContainer = styled.div`
  transition: 0.75s var(--cubic-easing) transform;

  transform: translate3d(0, ${({ activeIndex }) => `-${LINE_HEIGHT_XS * activeIndex}px`}, 0);

  height: 100%;
  padding-left: 0.9rem;

  ${breakpoint(`large-tablet`)} {
    transform: translate3d(0, ${({ activeIndex }) => `-${LINE_HEIGHT * activeIndex}px`}, 0);
  }
`;

/** ============================================================================
 * @component
 */
const TextChanger = ({ headings }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state
  const { deviceAbove } = useDevice();
  const [activeTextIndex, setActiveTextIndex] = useState(0);

  // ---------------------------------------------------------------------------
  // methods
  useInterval(() => {
    const nextTextIndex = !headings[activeTextIndex + 1] || activeTextIndex === headings.length - 2 ? 0 : activeTextIndex + 1;

    setActiveTextIndex(nextTextIndex);
  }, 3000);

  // ---------------------------------------------------------------------------
  // lifecycle

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container>
      <TextContainer>
        <Text className={deviceAbove(`tablet`) ? `h1` : `h2`}>Altr</Text>
        <Text className={deviceAbove(`tablet`) ? `h1` : `h2`}>Into</Text>
      </TextContainer>

      <MediaContainer>
        <MediaTransformContainer activeIndex={activeTextIndex}>
          {headings.map((heading) => (
            <Title key={`${heading}-text-changer-${uuid()}`} className={deviceAbove(`tablet`) ? `h1` : `h2`}>
              {heading}
            </Title>
          ))}
        </MediaTransformContainer>
      </MediaContainer>
    </Container>
  );
};

export default TextChanger;
