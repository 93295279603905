import React from "react";
import { ProfileCard, PgWrapper, PgItem } from "~components";

const data = {
  username: `IssacNewton`,
  userId: `82946932ige7rwq9r367e790rg `,
  walletAddress: `0x176...d84`,
  loginWalletAddress: `0x176...d84`,
  token: `ok`,
  usernameId: `82946932ige7rwq9r367e790rg`
};

const ProductInfoCardPG = () => (
  <PgWrapper title="ProfileCard" itemWidth="400px">
    <PgItem title="Light connected">
      <ProfileCard {...data} colorTheme="light" connected />
    </PgItem>
    <PgItem title="Light disconnected">
      <ProfileCard {...data} colorTheme="light" />
    </PgItem>
    <PgItem title="Dark connected">
      <ProfileCard {...data} colorTheme="dark" connected />
    </PgItem>
    <PgItem title="Dark disconnected">
      <ProfileCard {...data} colorTheme="dark" />
    </PgItem>
  </PgWrapper>
);

export default ProductInfoCardPG;
