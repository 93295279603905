import React from "react";
import styled from "@emotion/styled";
import { useApp } from "~hooks";
import Synaps from "@synaps-io/react-verify";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */

const Container = styled.article`
  transition: 0.5s var(--cubic-easing) transform;

  opacity: ${({ active }) => `${active ? 1 : 0}`};
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};
  transform: scale(${({ active }) => `${active ? `1` : `1.05`}`});

  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: center;

  .idVerificationModalEscape {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  iframe {
    width: 100%;
    min-width: 0 !important;
    position: relative;
    z-index: 10;

    ${breakpoint(`mobile`)} {
      width: auto;
      min-width: 400px !important;
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayWhitelist = () => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const { activeOverlay, setActiveOverlay, kycData } = useApp();

  const { kycSession } = kycData;

  // ---------------------------------------------------------------------------
  // methods

  const onReady = () => {
    console.log(`KYC Ready`);
  };

  const onFinish = () => {
    console.log(`KYC Completed`);
  };

  const active = activeOverlay === `NFTOverlayWhitelist`;

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container active={active}>
      {active && (
        <>
          <div className="idVerificationModalEscape" aria-hidden onClick={() => setActiveOverlay(null)} />

          {active && kycSession?.sessionId && (
            <Synaps
              sessionId={kycSession.sessionId}
              service="individual"
              lang="en"
              onReady={onReady}
              onFinish={onFinish}
              color={{
                primary: `ff6108`,
                secondary: `1e1e1e`
              }}
            />
          )}
        </>
      )}
    </Container>
  );
};

export default NFTOverlayWhitelist;
