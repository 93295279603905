import React from "react";
import styled from "@emotion/styled";
import { hexToRgb } from "~utils/helpers";

const Container = styled.div`
  animation: ${({ animate, r, g, b, id }) => (animate ? `pulse${id} 2s var(--cubic-easing) infinite` : `none`)};

  @keyframes pulse${({ id }) => id} {
    0% {
      box-shadow: 0 0 0 0 rgba(${({ r, g, b }) => `${r}, ${g}, ${b}`}, 0.4);
      opacity: 0.5;
    }
    70% {
      box-shadow: 0 0 0 0.35rem rgba(${({ r, g, b }) => `${r}, ${g}, ${b}`}, 0);
      opacity: 1;
    }
    100% {
      box-shadow: 0 0 0 0 rgba(${({ r, g, b }) => `${r}, ${g}, ${b}`}, 0);
      opacity: 0.5;
    }
  }

  width: 0.5rem;
  height: 0.5rem;
  background: ${({ hex }) => hex};
  border-radius: 100%;
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTStatusDot = ({ id = `0`, className = ``, animate = true, hex = `#0000ff` }) => {
  const { r, g, b } = hexToRgb(hex);
  return <Container className={`${className} NFTActionVerifyIdentityDot`} animate={animate} hex={hex} r={r} g={g} b={b} id={id} />;
};

export default NFTStatusDot;
