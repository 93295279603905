import cn from "classnames";
import { PortableText } from "@portabletext/react";
import { CtaButton } from "~components";

const CardWrapper = ({ cardsPerRow: cardsPerRowProp, _key, cards, fontSize }) => (
  <div className="cardsWrapper" key={_key}>
    {cards?.map((card) => {
      const backgroundImage = card.backgroundImage?.asset.url || card.oracle?.image?.asset.url;
      const cardsPerRow = +cardsPerRowProp || 1;
      const styles = {
        "--background": backgroundImage
          ? `linear-gradient(0deg, #000000cc 15.73%, #00000000 40.61%), url(${backgroundImage})`
          : `linear-gradient(180deg, #161616 0%, #010101 100%)`,
        "--min-width": `${100 / cardsPerRow}%`,
        "--max-width": `${68.75 / cardsPerRow}rem`,
        "--ratio": cardsPerRow > 1 ? -0.371 * cardsPerRow + 1.913 : 2.4
      };

      if (card._type === `oracleCardSelector`)
        return (
          <div key={card._key} className="card" style={styles}>
            <div className={cn(`cardContent`, card.blur && `blur`)}>
              {card.oracle.specialty && <p className="tag">{card.oracle.specialty}</p>}
              {card.oracle.title && <h3 className={card.fontSize || fontSize || `h1`}>{card.oracle.title}</h3>}
              <CtaButton
                cta={{
                  type: `linkExternal`,
                  externalLink: {
                    url: `/oracles/${card.oracle.slug.current}`,
                    text: card.title,
                    newTab: false
                  }
                }}
                colorTheme="light"
              />
            </div>
          </div>
        );

      return (
        <div key={card._key} className="card" style={styles}>
          <div className={cn(`cardContent`, card.blur && `blur`)}>
            {card.caption && <p className="tag">{card.caption}</p>}
            {card.title && <h3 className={card.fontSize || fontSize || `h1`}>{card.title}</h3>}
            <CtaButton cta={card.link} colorTheme="light" />
          </div>
        </div>
      );
    })}
  </div>
);

const componentsMap = {
  types: {
    linkCardsGroup: ({ value }) => <CardWrapper {...value} />
  },
  block: {
    normal: ({ children }) => <p className="description">{children}</p>
  }
};

const LinksCardBanner = ({ data: { theme, title, caption, blur, _rawItems } }) => (
  <div className={cn(`bg-wrapper`, theme === `light` || theme === `dark-transparent` ? null : `dark`)}>
    <section
      className={cn(`linksCardBanner`, `container`, `narrow`, theme === `light` ? `light` : `dark`)}
      style={{ "--secondary-color": theme === `light` ? `#4f4f4f` : undefined }}
    >
      <div className={cn(blur && `frosted-glass-dark`)}>
        <span className="tag">{caption}</span>
        <h2 className="h1">{title}</h2>
        <div className="content">
          <PortableText value={_rawItems} components={componentsMap} />
        </div>
      </div>
    </section>
  </div>
);

export default LinksCardBanner;
