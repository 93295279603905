import React from "react";
import { Tag, PgWrapper, PgItem } from "~components";

const TagPG = () => (
  <>
    <PgWrapper title="Tag" itemWidth="300px">
      <PgItem title="Default">
        <Tag text="Tag" iconLeft="arrowLeft" iconRight="arrowRight" />
      </PgItem>
      <PgItem title="Transparent">
        <Tag
          text="Tag"
          iconLeft="arrowLeft"
          iconRight="arrowRight"
          transparent
        />
      </PgItem>
      <PgItem title="Disabled">
        <Tag text="Tag" iconLeft="arrowLeft" iconRight="arrowRight" disabled />
      </PgItem>
      <PgItem title="Disabled transparent">
        <Tag
          text="Tag"
          iconLeft="arrowLeft"
          iconRight="arrowRight"
          disabled
          transparent
        />
      </PgItem>
      <PgItem title="Left icon">
        <Tag text="Tag" iconLeft="arrowLeft" />
      </PgItem>
      <PgItem title="Right icon">
        <Tag text="Tag" iconRight="arrowRight" />
      </PgItem>
      <PgItem title="No icon">
        <Tag text="Tag" />
      </PgItem>
      <PgItem title="Button">
        <Tag
          text="Tag"
          iconLeft="arrowLeft"
          iconRight="arrowRight"
          onClick={() => {}}
        />
      </PgItem>
      <PgItem title="Button transparent">
        <Tag
          text="Tag"
          iconLeft="arrowLeft"
          iconRight="arrowRight"
          onClick={() => {}}
          transparent
        />
      </PgItem>
    </PgWrapper>
  </>
);

export default TagPG;
