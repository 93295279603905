import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createContext, useContext, useEffect, useMemo } from "react";
import { useApp } from "~hooks";
import useExternalIntegrations from "~hooks/useExternalIntegrations";

const ProfileDataContext = createContext();

export const PROFILE_DIGITIZE_TAB = `digitise`;

export const PROFILE_HISTORY_TAB = `history`;

export const PROFILE_ORDERS_TAB = `offers`;

const ProfileDataProvider = ({ children, activeTab }) => {
  const queryClient = useQueryClient();
  const { userIsLoggedIn, userData, orderCanceled } = useApp();
  const { api, graph } = useExternalIntegrations();

  const digitiseQueryKey = [`useProfileDigitise`, userData?.user?.walletAddress];
  const historyQueryKey = [`useProfileHistory`, userData?.address];
  const ordersQueryKey = [`useProfileOrders`, userData?.address, userIsLoggedIn];

  const digitiseData = queryClient.getQueryData(digitiseQueryKey);
  const historyData = queryClient.getQueryData(historyQueryKey);
  const ordersData = queryClient.getQueryData(ordersQueryKey);

  const {
    data: { lightRequests = [], fullRequests = [] } = {},
    isLoading: isDigitiseLoading,
    refetch: refetchCertifications
  } = useQuery({
    queryKey: digitiseQueryKey,
    queryFn: async () => {
      const [lightRequestsList, fullRequestsList] = await Promise.all([api.getLightCertificationRequests(), api.getFullCertificationRequests()]);

      return { lightRequests: lightRequestsList, fullRequests: fullRequestsList };
    },
    enabled: userIsLoggedIn && (activeTab === PROFILE_DIGITIZE_TAB || !digitiseData)
  });

  const { data: transactions, isLoading: isHistoryLoading } = useQuery({
    queryKey: historyQueryKey,
    queryFn: async () => {
      const data = await graph.getAllTransactionHistory(userData?.address, `desc`);

      return data;
    },
    enabled: userIsLoggedIn && !!userData?.address && (activeTab === PROFILE_HISTORY_TAB || !historyData)
  });

  const {
    data: traderOrders,
    isLoading: isLoadingOrders,
    refetch: refetchOrders
  } = useQuery({
    queryKey: ordersQueryKey,
    queryFn: async () => {
      if (!userIsLoggedIn) return [];
      return api.getMyTraderOrders();
    },
    enabled: userIsLoggedIn && !!userData?.address && (activeTab === PROFILE_ORDERS_TAB || !ordersData)
  });

  useEffect(() => {
    refetchOrders();
  }, [orderCanceled]);

  const data = useMemo(
    () => ({
      lightRequests: userIsLoggedIn ? lightRequests : [],
      fullRequests: userIsLoggedIn ? fullRequests : [],
      isDigitiseLoading,
      transactions,
      isHistoryLoading,
      traderOrders,
      isLoadingOrders,
      refetchCertifications,
      refetchOrders
    }),
    [lightRequests, fullRequests, isDigitiseLoading, transactions, isHistoryLoading, traderOrders, isLoadingOrders]
  );

  return <ProfileDataContext.Provider value={data}>{children}</ProfileDataContext.Provider>;
};

export const useProfileData = () => {
  const data = useContext(ProfileDataContext);

  return data;
};

export default ProfileDataProvider;
