import React from "react";
import { Button } from "~components";

/** ============================================================================
 * @component
 */
const ProfileCollectionEmpty = () => (
  <section id="profileCollection" className="empty">
    <p>Browse the latest collectibles available in our marketplace.</p>
    <Button to="/products/" fluid>
      Get started
    </Button>
  </section>
);

export default ProfileCollectionEmpty;
