import React, { useState } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import cn from "classnames";
import { Button, TextInput } from "~components";
import { breakpoint, hoverable } from "~utils/css";
import regex from "~constants/regex";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import useApp from "~hooks/useApp";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  animation: 0.5s var(--cubic-easing) appear-down;
  background: var(--color-mono-10);
  padding: 0.75rem;
  display: flex;
  flex-direction: column-reverse;
  color: var(--color-black);

  margin-top: 4rem;

  ${breakpoint(`large-tablet`)} {
    margin-top: 0;
  }

  span {
    margin-left: 5px;
  }

  .walletData {
    &Image {
      filter: grayscale(1);

      &Connected {
        filter: none;
      }
    }

    &ConnectText {
      margin: 60px 0;
    }

    &IdentityVerificationCaption {
      margin-bottom: 0.5rem;
    }

    &IdentityVerification {
      background: var(--color-white);
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
      border-radius: 2px;
    }

    &FormField {
      margin-bottom: 1rem;
    }
  }

  ${breakpoint(`large-tablet`)} {
    flex-direction: row;
    gap: 1.5rem;
    padding: 1.25rem;
    .walletData {
      &Content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
      }
      &ConnectText {
        margin: 120px 0;
      }
    }
  }

  .description {
    margin-bottom: 1rem;
  }
`;

const Partition = styled.div`
  border-top: 1px solid var(--color-mono-40);
  margin: 1rem 0;
`;
/** ============================================================================
 * @component
 * @param {boolean} connected - Whether the user is connected to a wallet.
 * @param {string} className - For additional styling config
 * @return {node}
 */

const FiatPaymentForm = ({ className, selected, sanityEvent }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const externalIntegration = useExternalIntegrations();
  const { api } = externalIntegration;
  const { setActiveOverlay, setOverlayCompletionData } = useApp();
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [formValues, setFormValues] = useState({
    name: ``,
    address: ``,
    email: ``,
    phoneNumber: ``,
    id: ``,
    ticketId: selected?.id,
    eventIdentifier: sanityEvent.eventGuid
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    address: false,
    email: false,
    phoneNumber: false,
    id: false
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [accepted, setAccepted] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  const onChange = () => {
    if (accepted) {
      setAccepted(false);
    } else {
      setAccepted(true);
    }
  };

  const isFormReadyToSubmit = () => {
    if (isSubmitting || hasSubmitted) {
      return false;
    }

    if (!formValues.name || !formValues.email || !regex.email.test(formValues.email) || !formValues.address || !formValues.phoneNumber || !accepted) {
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    const res = await api.sendEventSalePurchaseRequest(formValues);

    setIsSubmitting(false);
    setHasSubmitted(true);

    if (res?.status === 200) {
      setActiveOverlay(null);
      setOverlayCompletionData({
        icon: `upload`,
        heading: `Congratulations! Your ticket order has been received.`,
        body: `An invoice will be sent to the email address you've provided, containing the details of your payment and verification of your purchase. Ensure that you periodically inspect your inbox, and also your spam folder.`
      });
    } else {
      alert(`Error, please try again!`); // FIXME
    }

    return true;
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container className={cn(className)}>
      <div className="walletDataContent">
        <div>
          <h2 className="h3">Billing and Contact</h2>
          <Partition />
        </div>

        <div className="description">
          <p>
            Complete the form below with your billing and contact details. You will receive an email containing an IBAN and detailed instructions for finalizing
            the ticket purchase via wire transfer within a few hours. Make sure to check your email inbox periodically.
          </p>
        </div>

        <form onSubmit={handleSubmit}>
          <TextInput
            required
            className="walletDataFormField"
            label="Full name | Company name"
            placeholder="Your Name"
            value={formValues.name}
            error={formErrors.name}
            onBlur={() => {
              if (!formValues.name) {
                setFormErrors((prev) => ({ ...prev, name: true }));
              }
            }}
            onChange={(value) => {
              setFormErrors((prev) => ({ ...prev, name: false }));
              setFormValues((prev) => ({ ...prev, name: value }));
            }}
          />

          <TextInput
            required
            className="walletDataFormField"
            label="Address"
            placeholder="Address"
            value={formValues.address}
            error={formErrors.address}
            onBlur={() => {
              if (!formValues.address) {
                setFormErrors((prev) => ({ ...prev, address: true }));
              }
            }}
            onChange={(value) => {
              setFormErrors((prev) => ({ ...prev, address: false }));
              setFormValues((prev) => ({ ...prev, address: value }));
            }}
          />

          <TextInput
            className="walletDataFormField"
            label="Tax ID | VAT Number"
            placeholder="Tax ID | VAT Number"
            value={formValues.id}
            error={formErrors.id}
            onChange={(value) => {
              setFormErrors((prev) => ({ ...prev, id: false }));
              setFormValues((prev) => ({ ...prev, id: value }));
            }}
          />

          <TextInput
            required
            className="walletDataFormField"
            label="Email"
            placeholder="Your Email"
            value={formValues.email}
            error={formErrors.email}
            onBlur={() => {
              if (!regex.email.test(formValues.email)) {
                setFormErrors((prev) => ({ ...prev, email: true }));
              }
            }}
            onChange={(value) => {
              setFormErrors((prev) => ({ ...prev, email: false }));
              setFormValues((prev) => ({ ...prev, email: value }));
            }}
          />

          <TextInput
            required
            className="walletDataFormField"
            label="Phone Number"
            placeholder="+39 3333333333"
            value={formValues.phoneNumber}
            error={formErrors.phoneNumber}
            onBlur={() => {
              if (!formValues.phoneNumber) {
                setFormErrors((prev) => ({ ...prev, phoneNumber: true }));
              }
            }}
            onChange={(value) => {
              setFormErrors((prev) => ({ ...prev, phoneNumber: false }));
              setFormValues((prev) => ({ ...prev, phoneNumber: value }));
            }}
          />

          <div className="walletDataFormField">
            <input name="accepted" onChange={onChange} type="checkbox" checked={accepted} />

            <span className="b2">
              Accept &nbsp;
              <a
                href={`${process.env.GATSBY_SITE_URL}/terms-and-conditions/event/`}
                css={css`
                  pointer-events: inherit;
                  text-decoration: underline;
                  color: var(--color-primary-orange-100);

                  ${hoverable} {
                    &:hover {
                      text-decoration: none;
                    }
                  }
                `}
                target="_blank"
                rel="nofollow noopener noreferrer"
              >
                Event terms and conditions
              </a>
            </span>
          </div>

          <Button disabled={!isFormReadyToSubmit()} buttonType="submit" variant="secondary" colorTheme="dark">
            Send
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default FiatPaymentForm;
