import { useEffect, useState } from "react";
import axios from "axios";

const useAllOracles = () => {
  const BASE_URL = process.env.GATSBY_LUCID_DB_URL;

  const [oraclesData, setOraclesData] = useState(null);

  const getAllOracles = async () => {
    const oracles = await axios.get(`${BASE_URL}/api/Oracle/GetList`);

    setOraclesData(oracles.data);
  };

  useEffect(() => {
    getAllOracles();
  }, []);

  const getOracleByGUID = (guid) =>
    oraclesData.find((oracle) => oracle.identifier === guid);

  return { oraclesData, getOracleByGUID };
};

export default useAllOracles;
