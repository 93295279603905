import { useEffect, useState } from "react";
import { genericErrorCallback } from "~utils/helpers";
import { useApp } from "~hooks";
import useExternalIntegrations from "./useExternalIntegrations";

// @externalintegration
// (dog)
// This is intended to replace the use of Sanity in purchase-confirmation URLs.
// It's cleanest for this to just accept a GUID and do a bunch of work to
// find things out about how it relates to the current user before setting
// and returning some data.
//
// Is there a way that this hook can 'know' things based on just the guid?
// - whether or not this user owns the NFT
// - what the NFT type is by its ID (car vs watch vs ...)
// - how many fragments the user owns of this NFT by its ID
// - etc.
export const usePostPurchase = (guid) => {
  const { userIsLoggedIn } = useApp();
  const { api } = useExternalIntegrations();

  // (dog) the ALTR product that we fetch from back-end
  const [product, setProduct] = useState(null);
  // (dog) the enriched product, only set when validated against userData
  const [purchase, setPurchase] = useState(null);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!guid || !userIsLoggedIn) {
      return;
    }

    const getProduct = async () => {
      // @externalintegration
      // (dog) need the type, could come via URL params?
      const altrProduct = await api.getBaseProductById(guid);

      if (altrProduct) {
        setProduct(altrProduct);
      }
    };

    getProduct().catch(genericErrorCallback);
  }, [guid, userIsLoggedIn]);

  useEffect(() => {
    if (!product?.nftData?.identifier || !userIsLoggedIn) {
      return;
    }

    console.log(`[postpurchase]: product: `, product.nftData.identifier);

    // (dog)
    // At this point we know we have userData and a product from ALTR back-end.
    // We could:
    // - Validate that the user owns it
    // - Add some metadata, how many fragments they bought etc.
    // - Save to state to implicitly indicate to the caller that the user can
    //   view the purchase-confirmation, or
    // - Leave the product as null and redirect the user elsewhere if they:
    //     - attempted to view the page without being logged in, or
    //     - are logged in but don't own anything related to this guid

    // (dog) mock
    setPurchase(product);
  }, [product?.nftData?.identifier, userIsLoggedIn]);

  // ---------------------------------------------------------------------------

  return {
    product,
    purchase
  };
};

export default usePostPurchase;
