import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Image } from "~components";
import loader from "~assets/images/loader.webp";

const LoadingAnimation = styled.span`
  transition: opacity 0.3s var(--cubic-easing);

  pointer-events: none;

  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
`;

const LoadingAnimationGlobal = styled.div`
  transition: opacity 0.3s var(--cubic-easing);

  pointer-events: none;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 90;
`;

const LoadingSpinner = ({ className, isInline, width }) => {
  const Wrapper = isInline ? LoadingAnimation : LoadingAnimationGlobal;

  return (
    <Wrapper className={className}>
      <Image
        css={css`
          width: ${isInline ? 100 : width ? width : 15}%;
          background: #ffffff;
          border-radius: 50%;
          aspect-ratio: 1;
        `}
        image={loader}
      />
    </Wrapper>
  );
};

export default LoadingSpinner;
