import React from "react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import cn from "classnames";
import { breakpoint } from "~utils/css";
import { SlicePadding } from "~components";

/** ============================================================================
 * @css
 */
const PaddingWrapper = styled.section`
  .frostedAtfPadding {
    ${breakpoint(`small-tablet`)} {
      padding-top: 0;
    }
  }
`;

const Container = styled.div`
  margin-top: 60px;
  border-radius: 1rem;
  height: 200px;
  color: ${({ textColor }) => (textColor === `light` ? `var(--color-white)` : `var(--color-black)`)};
  -webkit-mask-image: -webkit-radial-gradient(white, black); // Need for safari rounded corners ¯\_(ツ)_/¯

  ${breakpoint(`small-tablet`)} {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    height: 345px;
  }

  .frostedAtf {
    &ContentContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
    }

    &Content {
      text-align: center;
    }

    &Subheading {
      margin-bottom: 0.5rem;
    }

    &BackgroundImage {
      position: absolute;
      inset: 0;

      &Mobile {
        display: block;
        ${breakpoint(`small-tablet`)} {
          display: none;
        }
      }

      &Desktop {
        display: none;
        ${breakpoint(`small-tablet`)} {
          display: block;
        }
      }
    }
  }
`;

/** ============================================================================
 * @component
 */
const FourUpText = ({ data: { textColor, subheading, heading, backgroundImageMobile, backgroundImageDesktop } }) => (
  <PaddingWrapper>
    <SlicePadding className="frostedAtfPadding">
      <Container textColor={textColor}>
        <div className="frostedAtfBackgroundImage">
          <GatsbyImage
            className="frostedAtfBackgroundImageMobile"
            image={backgroundImageMobile?.asset.gatsbyImageData}
            style={{ height: `100%`, width: `100%` }}
            alt=""
          />
          <GatsbyImage
            className="frostedAtfBackgroundImageDesktop"
            image={backgroundImageDesktop?.asset.gatsbyImageData}
            style={{ height: `100%`, width: `100%` }}
            alt=""
          />
        </div>
        <div
          className={cn(`frostedAtfContentContainer`, {
            [`frosted-glass-dark`]: textColor === `light`,
            [`frosted-glass-light`]: textColor === `dark`
          })}
        >
          <div className="frostedAtfContent">
            <h2 className="tag frostedAtfSubheading">{subheading}</h2>
            <h1 className="h2">{heading}</h1>
          </div>
        </div>
      </Container>
    </SlicePadding>
  </PaddingWrapper>
);

export default FourUpText;
