import React from "react";
import styled from "@emotion/styled";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

/** ============================================================================
 * @css
 */
const Container = styled.div``;

/** ============================================================================
 * @component
 * @return {node}
 */

const PriceChart = ({ saleDataRows }) => {
  Chart.defaults.font.family = `"Sohne Mono", "Helvetica Neue", "Helvetica", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji`;
  const sortedSaleDataRows = saleDataRows?.sort((a, b) => new Date(a.date) - new Date(b.date));

  const parsePrice = (price) => {
    const priceWithoutSymbol = price.replace(/[^0-9.]/g, ``); // Remove all characters except numbers and decimal point
    const parsedPrice = parseFloat(priceWithoutSymbol);
    return parsedPrice;
  };

  const chartData = {
    labels: sortedSaleDataRows?.map((row) => row.date),
    datasets: [
      {
        label: `Price ($USD)`,
        data: sortedSaleDataRows?.map((row) => parsePrice(row.price)),
        backgroundColor: `#ff6108`,
        pointBorderColor: `#ff6108`,
        borderColor: `black`
      }
    ]
  };

  return (
    <Container>
      <Line data={chartData} />
    </Container>
  );
};

export default PriceChart;
