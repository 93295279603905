import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { SVG } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  display: inline-block;
  user-select: none;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 5px;
  transition: 0.2s all;

  ${({ transparent }) => {
    if (transparent) {
      return css`
        background: transparent;
        color: var(--color-black);
        border: 1px solid var(--color-black);
      `;
    }
    return css`
      background: var(--color-white);
      color: var(--color-black);
      border: 1px solid var(--color-white);
    `;
  }}

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      ${({ transparent, onClick }) => {
        if (!onClick) return null;

        if (transparent) {
          return css`
            color: var(--color-mono-60);
            border-color: var(--color-mono-60);
          `;
        }
        return css`
          background: var(--color-mono-20);
        `;
      }}
    }
  }

  ${({ disabled }) => {
    if (!disabled) return null;
    return css`
      opacity: 0.3;
      pointer-events: none;
    `;
  }}
`;

const Icon = styled.div`
  width: 1rem;
  height: 1rem;

  &.tagIconLeft {
    margin-right: 0.5rem;
  }

  &.tagIconRight {
    margin-left: 0.5rem;
  }
`;

/** ============================================================================
 * @component
 * @param  {string}    text   					Appears in the center of the tag
 * @param  {string}    iconLeft   			Icon to the left of the text
 * @param  {string}    iconRight				Icon to the right of the text
 * @param  {function}  onClick					For buttons
 * @param  {boolean}   transparent			Stroke outline style
 * @param  {boolean}   disabled					Disabled
 * @param  {string}    className    		Can be an identifier, Emotion CSS, or both
 * @return {node}
 */

const PlusButton = ({ text, iconLeft, iconRight, transparent, disabled, onClick, className }) => {
  let TagElm;
  if (onClick) {
    TagElm = `button`;
  } else {
    TagElm = `div`;
  }

  return (
    <Container>
      <TagElm>
        <Content transparent={transparent} disabled={disabled} className={className || null} onClick={onClick}>
          {iconLeft && (
            <Icon className="tagIconLeft">
              <SVG svg={iconLeft} />
            </Icon>
          )}
          <span className="tag">{text}</span>
          {iconRight && (
            <Icon className="tagIconRight">
              <SVG svg={iconRight} />
            </Icon>
          )}
        </Content>
      </TagElm>
    </Container>
  );
};

export default PlusButton;
