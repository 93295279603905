/* eslint-disable react/no-danger */
import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import striptags from "striptags";
import { CategoryTab, Link } from "~components";
import { TARGET_CHAIN } from "~utils/helpers";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  display: flex;
  list-style-type: none;

  .infoTabsDesktop {
    &TabContainer {
      width: 220px;
      flex-shrink: 0;
      margin-right: 2rem;

      > * {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &ContentContainer {
      flex-grow: 1;
      padding: 1rem 0;
    }

    &Heading {
      margin-bottom: 80px;
    }

    &Partition {
      margin-bottom: 1.5rem;
      border-top: 1px solid var(--color-mono-40);
    }

    &InfoRow {
      &Heading {
        margin-bottom: 1.5rem;
        margin-top: 2.5rem;
      }

      &LabelText {
        display: flex;
        align-items: center;
        border-top: 1px solid var(--color-mono-40);
        padding: 8px 0;
      }

      &Label {
        color: var(--color-mono-60);
        width: 200px;
        margin-right: 1rem;
        flex-shrink: 0;
      }
    }

    &AboutContent {
      display: flex;
      gap: 160px;
    }
  }

  .Content {
    animation: 0.75s var(--cubic-easing) appear-up forwards;
    animation-delay: 0.1s;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    transform: translate3d(0, -1rem, 0);
    opacity: 0;
    grid-column: 1 / 6;


    &Card {
      height: 100%;
      width: 85%;

      &Heading {
        margin-bottom: 2rem;
        color: var(--color-mono-70);
      }

      &Text {
        margin-bottom: 3px;
        color: var(--color-mono-70);
      }
    }
`;

const Separator = styled.div`
  border: 1px solid ${({ color }) => color};
  border-bottom: 0;
  margin: 0.3rem 0;
  height: 1px;
  width: 90%;
`;

/** ============================================================================
 * @component
 */
const InfoTabsDesktop = ({ lucidEvent, selected }) => {
  const [activeTab, setActiveTab] = useState(`ticketInfo`);

  return (
    <Container>
      <div className="infoTabsDesktopTabContainer">
        <CategoryTab tabNumber="01" tabTitle="Ticket Information" selected={activeTab === `ticketInfo`} onClick={() => setActiveTab(`ticketInfo`)} />
        <CategoryTab tabNumber="02" tabTitle="Event Schedule" selected={activeTab === `eventSchedule`} onClick={() => setActiveTab(`eventSchedule`)} />
        <CategoryTab tabNumber="03" tabTitle="Blockchain Data" selected={activeTab === `blockchainData`} onClick={() => setActiveTab(`blockchainData`)} />
      </div>

      <div className="infoTabsDesktopContentContainer">
        {activeTab === `ticketInfo` && (
          <article>
            <h3 className="h3 infoTabsDesktopHeading">Ticket Information</h3>

            <div className="ContentCard">
              <p className="ContentCardHeading">
                Tickets provide access to the superyacht from 10:00 am onwards, allowing guests to stay until the conclusion of cocktail dinners on the boat.
                The ticket includes tender transfer to the yacht, light breakfast, a fine buffet lunch and a cocktail dinner. Additionally, throughout the day
                and evening, guests will be served a selection of Champagne, spirits, fine wines, beers, and soft drinks.
              </p>
              <Separator color="var(--color-mono-40)" />
              <div>
                {lucidEvent?.tickets?.map((ticket) => (
                  <li key={`pdp-attribute-${ticket?.erc1155TokenID}`}>
                    <h4 className="b2 ContentCardText">{ticket.title.values[`en-US`]}</h4>
                    <p className="ContentCardText">{striptags(ticket.description.values[`en-US`])}</p>
                    <p className="ContentCardText">USDt {ticket?.price?.usdAmount}</p>
                    <Separator color="var(--color-mono-40)" />
                  </li>
                ))}
              </div>
            </div>
          </article>
        )}

        {activeTab === `blockchainData` && (
          <article>
            <h3 className="h3 infoTabsDesktopHeading">Blockchain Data</h3>

            {selected && (
              <div className="infoTabsDesktopInfoRowLabelText">
                <h5 className="caption infoTabsDesktopInfoRowLabel">NFT ID</h5>
                <div className="tag infoTabsDesktopInfoRowText">{selected.id}</div>
              </div>
            )}

            {lucidEvent && selected && (
              <div className="infoTabsDesktopInfoRowLabelText">
                <h5 className="caption infoTabsDesktopInfoRowLabel">Contract Address</h5>
                <div className="tag infoTabsDesktopInfoRowText">
                  <Link
                    css={css`
                      &:hover {
                        text-decoration: underline;
                        color: var(--color-primary-orange-100);
                      }
                    `}
                    to={
                      TARGET_CHAIN.id !== 137
                        ? `https://mumbai.polygonscan.com/address/${
                            lucidEvent.contractAddresses[Object.keys(lucidEvent.contractAddresses)[0]][0].contractAddress
                          }`
                        : `https://polygonscan.com/address/${lucidEvent.contractAddresses[Object.keys(lucidEvent.contractAddresses)[0]][0].contractAddress}`
                    }
                  >
                    {lucidEvent.contractAddresses[Object.keys(lucidEvent.contractAddresses)[0]][0].contractAddress}
                  </Link>
                </div>
              </div>
            )}
          </article>
        )}

        {activeTab === `eventSchedule` && (
          <article>
            <h3 className="h3 infoTabsDesktopHeading">Event Schedule</h3>
            <ul>
              {lucidEvent && (
                <li key="pdp-attribute-0">
                  <div className="infoTabsDesktopInfoRowLabelText">
                    <h5 className="caption infoTabsDesktopInfoRowLabel">Experience</h5>
                    <div
                      className="tag infoTabsDesktopInfoRowText"
                      dangerouslySetInnerHTML={{
                        __html: lucidEvent.description.values[`en-US`]
                      }}
                    />
                  </div>
                </li>
              )}
            </ul>
          </article>
        )}
      </div>
    </Container>
  );
};

export default InfoTabsDesktop;
