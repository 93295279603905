/* eslint-disable react/no-danger */
import React, { useRef } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Image, Link, SVG } from "~components";
import { breakpoint, hoverable } from "~utils/css.js";
import { truncateText } from "~utils/helpers.js";
// todo: these are placeholder, should be added to the Lucidao DB
import oracleProfile1 from "~assets/images/oracles/oracle-profile-1.jpg";
import oracleProfile2 from "~assets/images/oracles/oracle-profile-2.jpg";
import oracleProfile3 from "~assets/images/oracles/oracle-profile-3.jpg";

/** ============================================================================
 * @css
 */

const Container = styled.section`
  width: 100%;
  position: relative;
  display: block;
`;

const OracleListItem = styled.article`
  position: relative;

  width: 100%;
  height: auto;
  min-height: calc(100vw / 3);

  display: flex;

  border-top: 1px solid var(--color-black);
  background: var(--color-mono-90);
  color: var(--color-white);

  ${hoverable} {
    .oracle-hover-text {
      transition: 0.3s var(--cubic-easing) color;
    }

    &:hover {
      .oracle-hover-text {
        color: var(--color-primary-orange-100);
      }

      figure {
        transform: scale(1.05);
      }

      svg {
        fill: var(--color-primary-orange-100);
      }
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: inherit;
  display: flex;
  flex-direction: column;

  ${breakpoint(`large-tablet`)} {
    flex-direction: row;
  }
`;

const OracleImage = styled.div`
  width: 100%;
  padding-bottom: 108%;
  min-height: 25vw;
  position: relative;
  overflow: hidden;

  ${breakpoint(`large-tablet`)} {
    width: 66.6667%;
    padding-bottom: 0;
  }
`;

const OracleImageFigure = styled.figure`
  transition: 0.3s var(--cubic-easing) transform;

  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const OracleText = styled.div`
  flex-basis: 33.3333%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem;

  ${breakpoint(`large-tablet`)} {
    padding: 2.5rem;
  }
`;

/** ============================================================================
 * @component
 */
const OracleList = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const oracles = [];

  const ref = useRef();

  // ---------------------------------------------------------------------------
  // render

  const fallbackOracleImages = [oracleProfile1, oracleProfile2, oracleProfile3];

  if (!oracles?.[0]) {
    return null;
  }

  return (
    <Container ref={ref}>
      <ul>
        {oracles.map(({ node }, oracleIndex) => {
          // todo: these are placeholder, should be added to the Lucidao DB
          const oracleImage = node?.bannerImageUrl || fallbackOracleImages[oracleIndex % 3];

          const { companyName } = node;
          const key = `oracle-list-${companyName.toLowerCase()}`;

          if (!companyName || companyName === ``) {
            return <React.Fragment key={key} />;
          }

          if (!node?.oracleSlug) {
            return null;
          }

          return (
            <Link
              to={node.oracleSlug}
              key={key}
              css={css`
                display: block;
              `}
            >
              <OracleListItem>
                <Wrapper>
                  <OracleImage>
                    <OracleImageFigure>
                      {/* todo: why doesn't this work with lucidao images?: */}
                      {/* <RippleImage
                      id={`oracle-list-ripple-${uuid()}`}
                      imageUrl={oracleImage}
                      pos={pos}
                    /> */}

                      <Image
                        css={css`
                          width: 100%;
                          height: 100%;
                          position: relative;
                          display: block;
                          object-fit: cover;
                        `}
                        image={oracleImage}
                      />
                    </OracleImageFigure>
                  </OracleImage>

                  <OracleText>
                    <div>
                      <h4 className="oracle-hover-text caption">ORACLES</h4>

                      <h2
                        css={css`
                          margin-top: 0.75rem;
                          margin-bottom: 1.5rem;
                        `}
                        className="oracle-hover-text h3"
                      >
                        {companyName}
                      </h2>

                      <div
                        css={css`
                          white-space: pre-wrap;
                        `}
                        className="b1"
                        dangerouslySetInnerHTML={{
                          __html: truncateText(node?.description) || `Description goes here.`
                        }}
                      />
                    </div>

                    <div
                      className="oracle-hover-text"
                      css={css`
                        position: relative;
                        display: flex;
                        align-items: center;
                        margin-top: 2rem;
                      `}
                    >
                      <h4 className="button-text">VIEW ORACLE COLLECTION</h4>

                      <SVG
                        svg="arrowRight"
                        css={css`
                          width: 0.75rem;
                          margin-left: 0.667rem;
                        `}
                        fill="white"
                      />
                    </div>
                  </OracleText>
                </Wrapper>
              </OracleListItem>
            </Link>
          );
        })}
      </ul>
    </Container>
  );
};

export default OracleList;
