import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { Button } from "~components";
import { hoverable } from "~utils/css";
import { ticketContractHooks } from "~hooks/ticketContractHook";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  width: 100%;

  span {
    margin-left: 0.5rem;
  }

  p {
    margin: 1rem 0 2rem;
    color: var(--color-mono-60);
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const EventDoApproval = ({ nft, onStart = () => {}, onComplete = () => {}, formData, setFormData, ticketPrice, refetch }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const { enrichedEvent } = nft;
  const { useApproveTicketContract } = ticketContractHooks();

  const {
    write: doApprove,
    isWriteLoading,
    isSuccess,
    writeIsError,
    txData
  } = useApproveTicketContract(enrichedEvent.contractAddress, enrichedEvent.purchaseTokens[0].contractAddress, ticketPrice);

  // ---------------------------------------------------------------------------
  // methods

  const onChange = (e) => {
    const { name, type, value, checked } = e.target;

    if (type === `checkbox`) {
      setFormData({
        ...formData,
        [name]: checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // onStart();
    // @externalIntegration
    doApprove();
  };

  useEffect(() => {
    const processingManager = async () => {
      if (isWriteLoading) {
        onStart();
      }
      if (writeIsError) {
        onComplete();
      }
      if (txData) {
        onComplete();

        refetch();
      }
    };
    processingManager().catch((e) => console.error(e));
  }, [isWriteLoading, isSuccess, writeIsError, txData?.transactionHash]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container>
      <form onSubmit={onSubmit}>
        <label htmlFor="email">
          <input name="accepted" onChange={onChange} type="checkbox" checked={formData?.accepted} />

          <span className="b2">
            Accept &nbsp;
            <a
              href={`${process.env.GATSBY_SITE_URL}/terms-and-conditions/event/`}
              css={css`
                pointer-events: inherit;
                text-decoration: underline;
                color: var(--color-primary-orange-100);

                ${hoverable} {
                  &:hover {
                    text-decoration: none;
                  }
                }
              `}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              Event terms and conditions
            </a>
          </span>
        </label>

        <p className="b2">Click ‘Approve’ to allow an escrow smart contract to transfer USDt on your behalf.</p>

        <Button buttonType="submit" colorTheme="dark" fluid variant="feature" disabled={formData?.accepted !== true}>
          <span className="b1">Approve</span>
        </Button>
      </form>
    </Container>
  );
};

export default EventDoApproval;
