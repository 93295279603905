import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { SVG } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.button`
  transition: 0.2s all;
  text-align: left;
  width: 100%;

  ${({ mobile, selected }) => {
    if (mobile) {
      return css`
        padding: 1rem 0 0 0;
        border-top: 1px solid var(--color-khaki-100);
        display: flex;
        justify-content: space-between;
        align-items: center;
      `;
    }
    return css`
      padding: 1rem;
      border: 1px solid transparent;
      border-radius: 12px;
      background: ${selected ? `var(--color-khaki-100)` : `transparent`};

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          border: 1px solid var(--color-khaki-100);
        }
      }
    `;
  }}

  .categoryTab {
    &Number {
      margin-right: 1.5rem;
      width: 1.5em;
      flex-shrink: 0;
    }

    &Content {
      display: ${({ mobile }) => (mobile ? `flex` : `block`)};
      align-items: center;
    }

    &Chevron {
      width: 14px;
      transition: 0.2s transform;
      transform: rotate(${({ selected }) => (selected ? `180deg` : `0deg`)});
    }
  }
`;

/** ============================================================================
 * @component
 * @param  {string}    tabNumber   			Number displayed in large text
 * @param  {string}    tabTitle   			Title of tab
 * @param  {boolean}   mobile   				Render mobile style
 * @param  {boolean}   selected    			If is currently selected tab
 * @param  {function}  onClick    			Tab click handler
 * @return {node}
 */

const CategoryTab = ({ tabNumber, tabTitle, mobile, selected, onClick }) => (
  <Container onClick={onClick} mobile={mobile} selected={selected}>
    <div className="categoryTabContent">
      <div className="h2 categoryTabNumber">{tabNumber}</div>
      <div className="tag">{tabTitle}</div>
    </div>
    {mobile && <SVG className="categoryTabChevron" svg="chevronDown" />}
  </Container>
);

export default CategoryTab;
