import React from "react";
import styled from "@emotion/styled";
import { PortableText } from "@portabletext/react";
import cn from "classnames";
import { CtaButton } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  .caption {
    margin-bottom: 8px;
  }

  .h3 {
    margin-bottom: 40px;
  }
`;

const BlockText = styled.div`
  padding: 1.5rem;
  border-radius: 1rem;
  background: var(--color-mono-10);

  p,
  .articleTextAreaFeature {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    padding: 1rem;
  }
`;

const Feature = styled.div`
  .tag {
    margin-bottom: 4px;
  }

  li {
    margin-left: 1.5rem;
  }
`;

/** ============================================================================
 * @component
 * @param  {blockContent} rawText - The raw text from Sanity
 * @return {node}
 */

const Features = ({ value }) => {
  const { title, items } = value;
  return (
    <Feature className="articleTextAreaFeature">
      <h3 className="tag">{title}</h3>
      <ul>
        {items?.map((item, i) => (
          <li key={i}>{item}</li>
        ))}
      </ul>
    </Feature>
  );
};

const LinkOptions = ({ value }) => <CtaButton cta={value} colorTheme="light" variant="primary" iconRight="arrowRight" fluid />;

const components = {
  types: {
    headingItems: Features,
    linkOptions: LinkOptions
  },
  block: {
    normal: ({ children }) => <p className="b2">{children}</p>
  }
};

const ArticleTextArea = ({ rawText, heading, headingCaption, className, theme = `light` }) => (
  <Container className={cn(className)}>
    {headingCaption && <div className="caption">{headingCaption}</div>}
    {heading && <h2 className="h3">{heading}</h2>}
    <BlockText theme={theme}>
      <PortableText value={rawText} components={components} />
    </BlockText>
  </Container>
);

export default ArticleTextArea;
