import React from "react";
import { PlusButton, PgWrapper, PgItem } from "~components";

const PlusButtonPG = () => (
  <>
    <PgWrapper title="PlusButton" itemWidth="280px">
      <PgItem title="Light">
        <PlusButton color="light" />
      </PgItem>
      <PgItem title="Dark">
        <PlusButton color="dark" />
      </PgItem>
      <PgItem title="Light disabled">
        <PlusButton disabled color="light" />
      </PgItem>
      <PgItem title="Dark disabled">
        <PlusButton disabled color="dark" />
      </PgItem>
    </PgWrapper>
  </>
);

export default PlusButtonPG;
