import React from "react";
import { Navbar, PgWrapper, PgItem } from "~components";

const NavbarPG = () => (
  <>
    <PgWrapper title="Navbar">
      <PgItem>
        <Navbar currentPageTop="The Watch Boutique" currentPageBottom="Rolex Daytona" />
      </PgItem>
    </PgWrapper>
  </>
);

export default NavbarPG;
