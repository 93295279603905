import { useMemo } from "react";
import { useApp } from "~hooks";

const useWindowDimensions = () => {
  const { windowSize } = useApp();

  const screen = useMemo(() => {
    if (windowSize?.width > 1440) {
      return `xl`;
    }

    if (windowSize?.width > 1280) {
      return `lg`;
    }

    if (windowSize?.width > 1024) {
      return `md`;
    }

    if (windowSize?.width > 768) {
      return `sm`;
    }

    return `xs`;
  }, [windowSize?.width, windowSize?.height]);

  const { isMobile, isTablet, isDesktop } = useMemo(() => {
    switch (screen) {
      case `sm`:
        return {
          isMobile: false,
          isTablet: true,
          isDesktop: false
        };

      case `md`:
      case `lg`:
      case `xl`:
      case `xxl`:
        return {
          isMobile: false,
          isTablet: false,
          isDesktop: true
        };

      default:
        return {
          isMobile: true,
          isTablet: false,
          isDesktop: false
        };
    }
  }, [screen]);

  return {
    screen,
    windowSize,
    isMobile,
    isTablet,
    isDesktop
  };
};

export default useWindowDimensions;
