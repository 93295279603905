import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Grid } from "~components";
import { useDevice, useSize } from "~hooks";
import { breakpoint } from "~utils/css.js";
import cn from "classnames";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  height: 300px;
  position: relative;
  overflow: hidden;
  background: ${({ background }) => background || `#ffffff`};
  color: ${({ color }) => color || `#000000`};

  #liquid-banner-default {
    animation: 2s var(--cubic-easing) appear forwards;
    animation-delay: 0.25s;
    opacity: 0;
  }

  .blur-container {
    border-radius: 0.625rem;
    gap: 1.5rem;
    padding: 1.5rem;
  }
`;

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  padding-top: 2.5rem;

  color: var(--color-white);
  mix-blend-mode: ${({ blend }) => (blend ? `difference` : `unset`)};
`;

const Header = styled.header`
  grid-column: 1 / -1;

  ${breakpoint(`large-tablet`)} {
    grid-column: 1 / span 5;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: 2 / span 5;
  }
`;

/** ============================================================================
 * @component
 */
const LiquidBanner = ({ data: { backgroundColour, image, heading, subheading, blend, frosting }, location }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  // const containerRef = useRef();

  const { deviceAbove } = useDevice();

  const [containerRef] = useSize();

  // ---------------------------------------------------------------------------
  // variables

  // const imageSrc = getFallbackImageSrc(image);
  // const imageSrcXS = getFallbackImageSrc(image.mobileImage);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container ref={containerRef} role="presentation" background={backgroundColour?.hex}>
      <Overlay blend={blend}>
        <Grid className={frosting && cn(`blur-container`, `frosted-glass-dark`)}>
          {(heading || subheading) && (
            <Header>
              {heading && location?.pathname?.includes(`/collections/`) && (
                <h2
                  className="cap"
                  css={css`
                    margin-bottom: 0.5rem;
                  `}
                >
                  COLLECTION
                </h2>
              )}
              {heading && <h2 className="h1">{heading}</h2>}
            </Header>
          )}

          {subheading && (
            <p
              css={css`
                grid-column: span 12 / span 12;
                position: relative;

                ${breakpoint(`large-tablet`)} {
                  grid-column: span 5 / span 5;
                  grid-column-start: 1;
                  margin-top: 1rem;
                }
              `}
              className="b1"
            >
              {subheading}
            </p>
          )}
        </Grid>
      </Overlay>

      <div
        css={css`
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        `}
      >
        {image?.mobileImage && (
          <>
            {deviceAbove(`tablet`) ? (
              <GatsbyImage style={{ width: `100%`, height: `100%` }} image={image.asset.gatsbyImage} alt={image.alt || ``} />
            ) : (
              <GatsbyImage image={image.mobileImage.asset.gatsbyImage} style={{ width: `100%`, height: `100%` }} alt={image.alt || ``} />
            )}
          </>
        )}
        {image && !image.mobileImage && <GatsbyImage style={{ width: `100%`, height: `100%` }} image={image.asset.gatsbyImage} alt={image.alt || ``} />}

        {/* Liquid disabled as per Kev request */}
        {/* {(deviceAbove(`tablet`) && (
          <WaveImage
            id="liquid-banner-default"
            imageUrl={imageSrc}
            resolution={{
              x: containerBoundingRect?.width || 0,
              y: containerBoundingRect?.height || 0
            }}
          />
        )) || <WaveImage id="liquid-banner-xs" imageUrl={imageSrcXS} />} */}
      </div>
    </Container>
  );
};

export default LiquidBanner;
