import React, { useRef } from "react";
import { useTimeout } from "~hooks";
import TextScramble from "./TextScramble";

const CryptoScrambler = ({ className, delay = 0, iterations, phrases }) => {
  const contentRef = useRef();

  //

  useTimeout(() => {
    if (!contentRef?.current || !phrases?.[0]) {
      return;
    }

    const fx = new TextScramble(contentRef.current);

    let counter = 1;

    const next = () => {
      fx.setText(phrases[counter]).then(() => {
        setTimeout(next, 3000);
      });
      counter = (counter + 1) % phrases.length;
    };

    next();
  }, 3000);

  if (!phrases?.[0]) {
    return <></>;
  }

  return (
    <span ref={contentRef} className={className}>
      {phrases[0]}
    </span>
  );
};

export default CryptoScrambler;
