import { useEffect, useState } from "react";
import { formatStablecoin } from "~utils/helpers";

// this is intended to be a one-time parser of fragment data and prices
export const useSaleData = (saleData) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [fragments, setFragments] = useState(null);
  const [fragmentsAvailable, setFragmentsAvailable] = useState(0);
  const [fragmentsSold, setFragmentsSold] = useState(undefined);
  const [purchaseAmount, setPurchaseAmount] = useState(undefined);

  // ---------------------------------------------------------------------------
  // methods

  const getFragmentDisplayPrice = () => {
    if (!saleData?.fractionPrice) {
      return `NaN`;
    }

    return parseFloat(formatStablecoin(saleData.fractionPrice));
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (fragments || !saleData?.fractionsAmount) {
      return;
    }

    setFragments(saleData?.fractionsAmount?.toString() || 0);
  }, [fragments, saleData?.fractionsAmount]);

  useEffect(() => {
    if (!purchaseAmount && fragmentsSold !== undefined && saleData?.fractionsAmount) {
      setPurchaseAmount(saleData.fractionsAmount - fragmentsSold);
    }
  }, [purchaseAmount, fragmentsSold, saleData?.fractionsAmount]);

  useEffect(() => {
    if (fragmentsSold !== undefined && saleData?.fractionsAmount) {
      setFragmentsAvailable(saleData.fractionsAmount - fragmentsSold);
    }
  }, [fragmentsSold]);

  useEffect(() => {
    if (saleData?.fractionsSold !== undefined) {
      setFragmentsSold(saleData.fractionsSold);
    }
  }, [saleData?.fractionsSold]);

  // ---------------------------------------------------------------------------

  return {
    fragments,
    fragmentsAvailable,
    fragmentsSold,
    purchaseAmount,
    fragmentDisplayPrice: getFragmentDisplayPrice()
  };
};

export default useSaleData;
