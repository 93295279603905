import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useWindowDimensions } from "~hooks";
import { SlicePadding } from "~components";
import { genericErrorCallback } from "~utils/helpers";
import InfoTabsDesktop from "./components/InfoTabsDesktop";
import InfoTabsMobile from "./components/InfoTabsMobile";
import AltrApiService from "~dApp/AltrApiService";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  background: var(--color-khaki-50);
`;

/** ============================================================================
 * @component
 */
const EventInfoTabs = ({ event, selected }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const altrApiService = new AltrApiService();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [lucidEvent, setLucidEvent] = useState();

  const { isMobile: mobile, isTablet: tablet } = useWindowDimensions();

  const isMobile = mobile || tablet;

  const { basicInfo, _rawAbout: aboutText } = event?.eventInfo || {};

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!event?.eventGuid) {
      return;
    }

    const getData = async () => {
      const lucid = await altrApiService.getLucidEventById(event?.eventGuid);

      setLucidEvent(lucid);
    };

    getData().catch(genericErrorCallback);
  }, [event?.eventGuid]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container>
      <SlicePadding>
        {(isMobile && <InfoTabsMobile basicInfo={basicInfo} aboutText={aboutText} lucidEvent={lucidEvent} selected={selected} />) || (
          <InfoTabsDesktop basicInfo={basicInfo} aboutText={aboutText} lucidEvent={lucidEvent} selected={selected} />
        )}
      </SlicePadding>
    </Container>
  );
};

export default EventInfoTabs;
