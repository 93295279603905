/* eslint-disable import/no-extraneous-dependencies */

import React from "react";
import styled from "@emotion/styled";
import { Button, OwnershipStatus, ProfileItemActionsFragment, ProfileItemActionsWhole } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  animation: 0.5s var(--cubic-easing) appear-down forwards;

  transform: translate3d(0, -1rem, 0);
  opacity: 0;

  position: relative;

  .profileItemActions {
    &Buyouts {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &Buyout {
      &Info {
        width: 1.5rem;
        height: 1.5rem;
        position: relative;
        cursor: pointer;
        margin-left: 0.5rem;
        color: var(--color-mono-40);

        ${breakpoint(`large-tablet`)} {
          width: 1rem;
          height: 1rem;
          margin-top: 0.25rem;
        }

        svg {
          width: 100%;
        }
      }

      &Flex {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &Dot {
        width: 0.75rem;
        height: 0.75rem;
        position: relative;
        display: block;
        overflow: hidden;
        margin-right: 0.25rem;
        background: var(--color-ux-orange-100);
        border-radius: 100%;
      }

      &Status {
        text-decoration: underline;
      }
    }

    &Ownership {
      margin-bottom: 0.75rem;
    }

    &AwaitingConversion {
      &Hint {
        color: var(--color-mono-40);
        margin: 2.5rem 0 2rem;
      }
    }

    &Group {
      position: relative;
      overflow: hidden;
      margin-bottom: 0.75rem;
      padding: 1rem 1rem 1rem;
      background: var(--color-mono-90);
      border-radius: 12px;

      ${breakpoint(`large-tablet`)} {
        padding: 2.5rem 2.5rem 3rem;
        margin-bottom: 0.75rem;
      }
    }

    //

    &Buttons {
      position: relative;
      margin-top: 2.5rem;
      width: 100%;
      button {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.75rem;
        padding: 1.5rem;
        background: var(--color-mono-70);
        border-radius: 8px;
        border: 1px solid transparent;

        svg {
          width: 10px;
        }
      }
    }

    &Button {
      &Disabled {
        pointer-events: none !important;
        opacity: 0.5;
        border: 1px solid var(--color-black) !important;
      }
    }

    &SaleLive {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &Status {
        display: flex;
        align-items: center;
      }
    }
  }

  .profileItemVault {
    padding-bottom: 2rem;

    &Info {
      width: 1.5rem;
      height: 1.5rem;
      top: 15px;
      right: 1rem;
      z-index: 10;
      position: absolute;
      color: var(--color-mono-40);

      ${breakpoint(`large-tablet`)} {
        top: 2.5rem;
        right: 2.5rem;
      }

      svg {
        width: 100%;
      }
    }

    &Item {
      position: relative;
      display: block;
      padding: 0.5rem 0 0.75rem;
      border-bottom: 1px solid var(--color-mono-70);
    }

    &Table {
      margin-top: 2.5rem;
      border-top: 1px solid var(--color-mono-70);
    }

    &Subheading {
      color: var(--color-mono-40);
    }

    &Extend {
      margin-top: 2.5rem;
    }
  }

  .profileItemBuyouts {
    &List {
      padding: 1rem 0;
      border-top: 1px solid var(--color-mono-70);

      &:first-of-type {
        margin-top: 3rem;
      }

      &:last-child {
        border-bottom: 1px solid var(--color-mono-70);
      }
    }

    &Item {
      padding: 0.5rem 0;
      display: flex;
      align-items: center;
    }

    &Key,
    &Value {
      width: 50%;

      a {
        text-decoration: underline;
      }
    }

    &Key {
      color: var(--color-mono-40);

      &Primary {
        color: var(--color-white);
      }
    }

    &Value {
      &Total {
        display: flex;

        p {
          margin-right: 1rem;
        }
      }
    }

    &Total {
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--color-mono-70);
      padding: 2rem 0;
    }

    &Accept {
      margin-top: 2rem;
    }
  }
`;

const StatusDot = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  margin-right: 0.5rem;
  background: ${({ active }) => (active ? `var(--color-ux-green-100)` : `var(--color-ux-green-100)`)};
  border-radius: 100%;
`;

/** ============================================================================
 * @component
 */
const ProfileItemActions = ({ nft, item, isFullCertificate, readOnly }) => {
  // ! MOCK Control variables
  // @external integration - need to setup the conditions for these various statuses
  const STATUS = nft?.nftStatus?.status;
  const showSaleLiveWidget = STATUS === `OnFractionsSale`;
  const isItemFragmented = item?.tokenType === `ERC1155`;

  // ---------------------------------------------------------------------------
  // methods

  const actionsHeaderText = () => {
    switch (STATUS) {
      case `awaitingQuotation`:
      case `quotationReceived`:
      case `awaitingConversion`:
        return `Buyout Request`;
      case `awaitingEmailConfirmation`:
      case `finalisingDetails`:
      case `readyToClaim`:
        return `Claim Request`;
      default:
        return `Manage Item`;
    }
  };

  const statusText = () => {
    switch (STATUS) {
      case `awaitingQuotation`:
        return `AWAITING QUOTATION`; // after buyout request
      case `quotationReceived`:
        return `QUOTATION RECEIVED`;
      case `awaitingConversion`:
        return `AWAITING FRAGMENT CONVERSION`; // after buyout execution
      case `awaitingEmailConfirmation`:
        return `AWAITING YOUR EMAIL CONFIRMATION`; // after claim request
      case `finalisingDetails`:
        return `FINALISING SHIPPING DETAILS`;
      case `readyToClaim`:
        return `READY TO CLAIM`;
      default:
        return ``;
    }
  };

  // ---------------------------------------------------------------------------
  // render

  if (!item || readOnly) {
    return null;
  }

  return (
    <Container>
      <OwnershipStatus className="profileItemActionsOwnership" item={item} nft={nft} isFullCertificate={isFullCertificate} readOnly={readOnly} />

      <article className="profileItemActionsGroup">
        <div className="profileItemActionsBuyouts">
          {isItemFragmented ? (
            <ProfileItemActionsFragment nft={nft} STATUS={STATUS} header={actionsHeaderText()} statusText={statusText()} />
          ) : (
            <ProfileItemActionsWhole STATUS={STATUS} header={actionsHeaderText()} statusText={statusText()} isFullCertificate={isFullCertificate} />
          )}
        </div>
      </article>

      {showSaleLiveWidget && (
        <div className="profileItemActionsGroup profileItemActionsSaleLive">
          <div className="profileItemActionsSaleLiveStatus">
            <StatusDot active />
            <span className="b1">Sale Live</span>
          </div>
          {/* @externalintegration FIXME: */}
          <Button>Purchase</Button>
        </div>
      )}
    </Container>
  );
};

export default ProfileItemActions;
