import useEmblaCarousel from "embla-carousel-react";
import cn from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { CtaButton, Button, SVG } from "~components";

const PressCarousel = ({ data }) => {
  const [carouselRef, carouselApi] = useEmblaCarousel({ loop: true, slidesToScroll: 1, align: `start` });
  const { eyebrow, title, description, links } = data;

  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [selectedSlide, setSelectedSlide] = useState(0);

  useEffect(() => {
    if (carouselApi) {
      const updateCarousel = (api) => {
        const scrollablePrev = api.canScrollPrev();
        const scrollableNext = api.canScrollNext();

        setCanScrollNext(scrollableNext);
        setCanScrollPrev(scrollablePrev);
        setIsScrollable(scrollableNext || scrollablePrev);
        setSelectedSlide(api.selectedScrollSnap());
      };

      carouselApi.on(`init`, updateCarousel);

      carouselApi.on(`reInit`, updateCarousel);

      carouselApi.on(`resize`, updateCarousel);

      carouselApi.on(`scroll`, updateCarousel);

      updateCarousel(carouselApi);
    }
  }, [carouselApi]);

  return (
    <div className="bg-wrapper dark">
      <section className="container dark narrow pressCarousel">
        <div className="contentBody">
          <div>
            {!!eyebrow && <strong className="caption">{eyebrow}</strong>}
            <h2>{title}</h2>
          </div>
          <p className="description">{description}</p>
        </div>
        <div>
          {isScrollable && (
            <div className="buttons">
              <Button variant="secondary" disabled={!canScrollPrev} onClick={() => carouselApi?.scrollPrev()}>
                <SVG svg="arrowLeft" />
              </Button>
              <Button variant="secondary" disabled={!canScrollNext} onClick={() => carouselApi?.scrollNext()}>
                <SVG svg="arrowRight" />
              </Button>
            </div>
          )}
          <div className="carouselWrapper">
            <div className="carousel" ref={carouselRef}>
              <ul className="carouselContainer">
                {links.map((link) => (
                  <li key={link._key} className="carouselSlide">
                    <div className="pressItem">
                      {!!link.image && (
                        <GatsbyImage
                          className="pressImage"
                          image={link.image.asset.gatsbyImageData}
                          imgStyle={{ objectFit: `contain`, objectPosition: `left` }}
                          alt=""
                        />
                      )}
                      <strong className="caption">{link.eyebrow}</strong>
                      <p>{link.description}</p>
                      <ul>
                        {link.links.map((button) => (
                          <li key={button._key}>
                            <CtaButton key={button._key} cta={button} variant="secondary" />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="dots">
            {links.map((link, index) => (
              <div key={`dots_${link._key}`} className={cn(`dot`, selectedSlide === index && `active`)} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default PressCarousel;
