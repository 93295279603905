import React, { useEffect, useState } from "react";
import cn from "classnames";
import { SVG } from "~components";
import { useWindowDimensions } from "~hooks";
import { usePopper } from "react-popper";
import { createPortal } from "react-dom";

const popperOptionsPrimary = {
  modifiers: [
    {
      name: `offset`,
      options: {
        offset: [0, 24]
      }
    }
  ]
};

const popperOptionsSecondary = {
  modifiers: [
    {
      name: `offset`,
      options: {
        offset: [0, 0]
      }
    },
    {
      name: `sameWidth`,
      enabled: true,
      phase: `beforeWrite`,
      requires: [`computeStyles`],
      fn: ({ state }) => {
        state.styles.popper.width = `${state.rects.reference.width}px`;
        state.styles.popper.zIndex = 100;
      },
      effect: ({ state }) => {
        state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
      }
    }
  ]
};

const Select = ({
  className = ``,
  popdownClassName = ``,
  hidden,
  title,
  defaultValue,
  onChange,
  value: currentValue,
  options = [],
  variant = `primary`,
  closeOnSelect = true
}) => {
  const [popperAnchor, setPopperAcnhor] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const [sortingActive, setSortingActive] = useState(false);
  const [sorting, setSorting] = useState(currentValue || defaultValue);
  const { isMobile } = useWindowDimensions();
  const { styles, attributes } = usePopper(popperAnchor, popperElement, variant === `primary` ? popperOptionsPrimary : popperOptionsSecondary);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popperElement && popperAnchor && !popperAnchor.contains(event.target) && !popperElement.contains(event.target)) {
        setSortingActive(false);
      }
    }

    document.addEventListener(`mousedown`, handleClickOutside);
    return () => {
      document.removeEventListener(`mousedown`, handleClickOutside);
    };
  }, [popperElement]);

  useEffect(() => {
    if (typeof currentValue !== `undefined` && currentValue !== null) {
      setSorting(currentValue);
    }
  }, [currentValue]);

  const onOptionsChange = (value) => {
    setSorting(value);
    setSortingActive(!closeOnSelect);
    onChange?.(value);
  };

  const displayValues = options
    .filter((option) => (Array.isArray(sorting) ? sorting.includes(option.value) : sorting === option.value))
    .reduce((acc, option) => `${acc}, ${option.title}`, ``)
    .slice(2);

  return (
    <div className={cn(`selectWrapper`, hidden && `isHidden`, variant, className)}>
      <button ref={setPopperAcnhor} type="button" onClick={() => setSortingActive(!sortingActive)}>
        <div className="toggle">
          <p className="caption">
            {!!title && `${title}: `}
            {displayValues}
          </p>
          <SVG svg={variant === `primary` ? `selectArrow` : `chevronDown`} />
        </div>
      </button>
      {createPortal(
        <>
          <div
            className={cn(`selectPopdown`, variant, !sortingActive && `inactive`, popdownClassName)}
            style={styles.popper}
            ref={setPopperElement}
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...attributes.popper}
          >
            {options.map(({ value, title: optionTitle }) => (
              <ul key={`${optionTitle}-${value}`}>
                <li>
                  <button type="button" onClick={() => onOptionsChange(value)}>
                    <span className={isMobile ? `tag` : `caption`}>{optionTitle}</span>
                    {(Array.isArray(sorting) ? sorting.includes(value) : sorting === value) && <SVG svg="check" />}
                  </button>
                </li>
              </ul>
            ))}
          </div>
        </>,
        document.body
      )}
    </div>
  );
};

export default Select;
