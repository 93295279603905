import React, { useState } from "react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { TextCtaCluster, Accordion } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  .accordionCluster {
    &BgImage {
      position: absolute;
      inset: 0;
    }

    &BgGradient {
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.15) 30%, rgba(0, 0, 0, 0.15) 70%, rgba(0, 0, 0, 1) 100%);
    }
  }
`;

const Content = styled.div`
  position: relative;
  color: var(--color-white);

  .accordionCluster {
    &Text {
      margin-bottom: 1.5rem;
      flex: 1;

      p {
        color: #797878;
      }

      .tag {
        color: #797878;
      }
    }

    &Accordions {
      flex: 1;
    }

    &Accordion {
      margin-bottom: 0.75rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ${breakpoint(`large-tablet`)} {
    display: flex;
    gap: 2rem;

    &Text {
      margin-bottom: 0;
    }
  }
`;

/** ============================================================================
 * @component
 */
const AccordionCluster = ({ data: { textCtaCluster, accordions, background, backgroundImage } }) => {
  const [openedAccordion, setOpenedAccordion] = useState(accordions?.find((accordion) => accordion.openByDefault)?.title);

  const handleAccordionToggle = (key) => {
    if (openedAccordion === key) setOpenedAccordion(null);
    else setOpenedAccordion(key);
  };

  return (
    <div className="bg-wrapper dark">
      <Container className="">
        {background === `image` && (
          <>
            <GatsbyImage className="accordionClusterBgImage" image={backgroundImage.asset.gatsbyImageData} alt="" />
            <div className="accordionClusterBgGradient" />
          </>
        )}

        <section className="container narrow wide">
          <Content>
            <TextCtaCluster
              caption={textCtaCluster.caption}
              title={textCtaCluster.title}
              body={textCtaCluster.body}
              ctaLink={textCtaCluster.ctaLink}
              className="accordionClusterText"
            />
            <div className="accordionClusterAccordions">
              {accordions?.map((accordion, index) => (
                <Accordion
                  key={index}
                  title={accordion.title}
                  content={accordion.content}
                  status={accordion.status}
                  links={accordion.links}
                  open={accordion.title === openedAccordion}
                  onAccordionToggle={() => handleAccordionToggle(accordion.title)}
                  className="accordionClusterAccordion"
                />
              ))}
            </div>
          </Content>
        </section>
      </Container>
    </div>
  );
};

export default AccordionCluster;
