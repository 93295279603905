import { useEffect, useState } from "react";
import { useApp } from "~hooks";

export const usePurchaseData = (
  checkoutData = {
    fragments: `0`,
    pricePerFragment: `0`,
    expiry: null
  }
) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const { activeOverlay } = useApp();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [data, setData] = useState(checkoutData);
  const [approved, setApproved] = useState(false);
  const [error, setError] = useState(null);

  // ---------------------------------------------------------------------------
  // methods

  const update = (name, value) => {
    setData({
      ...data,
      [name]: value
    });
  };

  const updateAll = (allData) => {
    setData(allData);
  };

  const onChange = (e) => {
    const { name, value } = e.target;

    update(name, value);
  };

  const reset = () => {
    setData(checkoutData);
    setApproved(false);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!activeOverlay) {
      reset();
    }
  }, [activeOverlay]);

  // ---------------------------------------------------------------------------

  return { data, approved, error, setApproved, update, onChange, reset, updateAll };
};

export default usePurchaseData;
