import React from "react";
import styled from "@emotion/styled";
import { NFTPurchaseLoading, NFTPurchaseFragments, NFTPurchaseWhole } from "~components";
import { thousandCommas } from "~utils/helpers";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 1rem;
  margin-top: 1rem;

  ${breakpoint(`large-tablet`)} {
    margin-top: 0;
  }

  .nftPurchaseComingSoonText {
    background: var(--color-beige);
    padding: 1.25rem 1rem;
  }
`;

/** ============================================================================
 * @component
 * todo: animations
 * @return {node}
 */
const NFTPurchase = ({ enrichedProduct, nftStatus, sanityProduct, loaded }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  // ---------------------------------------------------------------------------
  // context / ref / state

  // ---------------------------------------------------------------------------
  // variables

  let body;

  if (sanityProduct?.priceInformation) {
    body = sanityProduct.priceInformation;
  }

  const fragmenting = nftStatus?.is([`OnFractionsSale`]);
  const soldOut = nftStatus?.is([`OnFractionsSaleSuccess`]);
  const saleFailed = nftStatus?.is([`OnFractionsSaleFailed`]);

  // ---------------------------------------------------------------------------
  // methods

  const getFragmentationText = () => {
    let text = `Fragmentation becomes available if the full item remains unsold.`;

    if (fragmenting || soldOut) {
      text = `This product has been Fragmented.`;
    }

    return text;
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  // ---------------------------------------------------------------------------
  // render

  const withText = !saleFailed && !fragmenting && !soldOut;

  let subheading = ``;
  let dropDetails = ``;

  if (nftStatus?.is([`WaitingForSaleStart`, `CollectUserInterest`, `DoKyc`, `WaitingForWhitelisting`, `NoEmail`, `OnSale`])) {
    subheading = `Acquire the collectible outright.`;
    dropDetails = !nftStatus?.is(`OnSale`) ? `${sanityProduct?.dropDescription}: ${sanityProduct?.dropDate}` : dropDetails;
  }

  return (
    <Container>
      {(!loaded && <NFTPurchaseLoading />) || (
        <>
          {(enrichedProduct && (
            <>
              <NFTPurchaseWhole
                nftStatus={nftStatus}
                displayPrice={
                  enrichedProduct.nftData
                    ? thousandCommas(enrichedProduct.nftData.price.usdAmount)
                    : enrichedProduct.product?.price?.usdAmount
                    ? thousandCommas(enrichedProduct.product.price.usdAmount)
                    : null
                }
                flex={!withText}
                heading="Whole Item"
                subheading={withText ? subheading : null}
                dropDetails={withText ? dropDetails : null}
                body={withText ? body : null}
                enrichedProduct={enrichedProduct}
              />

              <NFTPurchaseFragments
                nftStatus={nftStatus}
                enrichedProduct={enrichedProduct}
                estimatedDisplayPrice={
                  enrichedProduct.nftData
                    ? enrichedProduct.nftData.price.usdAmount
                    : enrichedProduct.product?.price?.usdAmount
                    ? enrichedProduct.product.price.usdAmount
                    : null
                }
                visible={!nftStatus?.is([`SoldOut`, `NFTBurned`])}
                background="khaki-10"
                heading="Fragmentation"
                subheading={getFragmentationText()}
              />
            </>
          )) || <p className="nftPurchaseComingSoonText">Details announced soon</p>}
        </>
      )}
    </Container>
  );
};

export default NFTPurchase;
