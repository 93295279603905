import React, { useEffect, useState } from "react";
import { useApp } from "~hooks";
import { Button, NFTIconNote, NFTOverlay, PriceWidget } from "~components";
import { BLOCK_EXPLORER, thousandCommas } from "~utils/helpers";
import { usePublicClient } from "wagmi";
import { blockchainHooks } from "~hooks/blockchainHooks";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { handleError } from "~utils/error";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayBuyout = ({ nft }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const {
    useApproveFractionsBuyout,
    useApproveFragmentsToBuyoutContract,
    useManageFractionsBuyoutFragmentsAllowance,
    useManageFractionsBuyoutTokenAllowance,
    useGetBuyoutData,
    useExecuteBuyout
  } = blockchainHooks();
  const { setOverlayCompletionData, setOverlayProcessingData, userData } = useApp();
  const { graph } = useExternalIntegrations();
  const publicClient = usePublicClient();
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [tokenApproval, setTokenApproval] = useState(false);
  const [fragmentApproval, setFragmentApproval] = useState(false);
  const [totalBuyoutPrice, setTotalBuyoutPrice] = useState(0);
  const [buyoutId, setBuyoutId] = useState(0);
  const [executing, setExecuting] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle
  useEffect(() => {
    const fetchBuyoutId = async () => {
      const fragmentAddress = nft?.enrichedProduct?.nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionContractAddress;
      const fragmentId = nft?.enrichedProduct?.nftData?.saleId?.toString();
      const { buyouts } = await graph.getBuyoutDataFromFragmentsIdAndInitiator(userData?.address, fragmentAddress, fragmentId);
      setBuyoutId(buyouts?.[0].buyoutId);
    };
    fetchBuyoutId().catch((e) => console.error(e));
  }, [
    nft?.enrichedProduct?.nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionContractAddress,
    nft?.enrichedProduct?.nftData?.saleId,
    userData?.address
  ]);

  const { data: buyoutData } = useGetBuyoutData(nft?.enrichedProduct?.nftData, buyoutId);

  useEffect(() => {
    setTotalBuyoutPrice(buyoutData?.buyoutPrice?.mul(buyoutData?.fractionsToBuyout));
  }, [buyoutData?.buyoutPrice?.toString(), buyoutData?.fractionsToBuyout?.toString()]);

  const { writeAsync: doApproveFractionsBuyout, isSuccess: isBuyoutApproved } = useApproveFractionsBuyout(nft?.enrichedProduct?.nftData, totalBuyoutPrice);

  const { writeAsync: doApproveFragments, isSuccess: isFragmentApproved } = useApproveFragmentsToBuyoutContract(nft?.enrichedProduct?.nftData);

  const { writeAsync: doExecuteBuyout, isSuccess, txData } = useExecuteBuyout(nft?.enrichedProduct?.nftData);

  useManageFractionsBuyoutFragmentsAllowance(nft?.enrichedProduct?.nftData, setFragmentApproval);

  useManageFractionsBuyoutTokenAllowance(nft?.enrichedProduct?.nftData, totalBuyoutPrice, setTokenApproval);

  useEffect(() => {
    switch (true) {
      case isSuccess:
        setOverlayCompletionData({
          icon: `check`,
          heading: `Buy out completed`,
          transactionUrl: `${BLOCK_EXPLORER}${txData.transactionHash}`,
          purchaseSubheading: `You now own ${nft?.enrichedProduct?.product?.numberOfFragments}/${nft?.enrichedProduct?.product?.numberOfFragments} fragments`
        });
        setOverlayProcessingData(null);
        setExecuting(false);
        break;
      case isBuyoutApproved:
      case isFragmentApproved:
        setOverlayProcessingData(null);
        setExecuting(false);
        break;
      default:
        break;
    }
  }, [isSuccess, isBuyoutApproved, isFragmentApproved]);

  // ---------------------------------------------------------------------------
  // methods

  const getFormattedExpiryDate = () => new Date(buyoutData?.closingTime.mul(1000).toString()).toDateString();

  const approveNFTs = async () => {
    if (!nft?.enrichedProduct?.nftData || executing) {
      return () => {};
    }

    setExecuting(true);

    setOverlayProcessingData({
      icon: `load`,
      text: `Processing. Please do not close this page.`
    });

    // @externalintegration
    try {
      await doApproveFragments();
    } catch (e) {
      console.error(e);
      handleError(e, setOverlayCompletionData, await publicClient.getBalance({ address: userData?.address }));
    }

    setOverlayProcessingData(null);
    setExecuting(false);

    return null;
  };

  const approveUSDT = async () => {
    if (executing) {
      return () => {};
    }

    setExecuting(true);

    setOverlayProcessingData({
      icon: `load`,
      text: `Processing. Please do not close this page.`
    });

    // @externalintegration
    try {
      await doApproveFractionsBuyout();
    } catch (e) {
      console.error(e);
      handleError(e, setOverlayCompletionData, await publicClient.getBalance({ address: userData?.address }));
    }

    setOverlayProcessingData(null);
    setExecuting(false);

    return null;
  };

  const buyout = async () => {
    if (!nft?.nftData?.enrichedProduct?.nftData || executing || !buyoutData?.buyoutPrice) {
      return () => {};
    }

    setExecuting(true);

    setOverlayProcessingData({
      icon: `load`,
      text: `Processing. Please do not close this page.`
    });

    // @externalintegration
    try {
      await doExecuteBuyout();
    } catch (e) {
      console.error(e);
      handleError(e, setOverlayCompletionData, await publicClient.getBalance({ address: userData?.address }));
    }

    setOverlayProcessingData(null);
    setExecuting(false);

    return null;
  };

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  return (
    <NFTOverlay id="NFTOverlayBuyout" heading="Buyout" nft={nft} sidebarMode="buyout">
      <section className="nftEntry checkout">
        <h3>Buyout Summary</h3>
        <section className="summary">
          <h4 className="caption">Summary</h4>
          <div className="summaryValues">
            {buyoutData &&
              Object.keys(buyoutData).map((rowKey) => {
                if (rowKey === `price` || rowKey === `timeToExpiry`) {
                  return null;
                }

                let label = rowKey;
                let value = buyoutData[rowKey];

                switch (rowKey) {
                  case `closingTime`:
                    label = `Offer Valid until `;
                    value = getFormattedExpiryDate();
                    break;

                  case `fractionsToBuyout`:
                    label = `No. of fragments`;
                    break;

                  case `buyoutPrice`:
                    label = `Price per fragment`;

                    if (value) {
                      value = `${value?.toString() === `NaN` ? `-` : `$${thousandCommas(value || 0)} USDt`}`;
                    }

                    break;

                  default:
                    break;
                }

                return (
                  <dl key={`nft-offer-control-${rowKey}`} className="caption">
                    <dt>{label}</dt>
                    <dd>{value || `-`}</dd>
                  </dl>
                );
              })}
          </div>
        </section>
        <section className="summary">
          <h4 className="caption">Total</h4>

          <div className="summaryValue">
            <PriceWidget displayPrice={thousandCommas(totalBuyoutPrice || 0)} color="white" enrichedProduct={nft?.enrichedProduct} fontClass="h2" />
          </div>
        </section>

        <section className="nftInput controls">
          {!fragmentApproval && (
            <>
              <p className="notice">Click ‘Approve’ to give your wallet provider permission for us to move the NFTs you own.</p>

              <Button onClick={() => approveNFTs()} colorTheme="dark" fluid variant="primaryTall">
                <span className="b1">Approve NFT&apos;s</span>
              </Button>
            </>
          )}

          {fragmentApproval && !tokenApproval && (
            <>
              <p className="notice">Click ‘Approve’ to allow an escrow smart contract to transfer USDt on your behalf.</p>

              <Button onClick={() => approveUSDT()} colorTheme="dark" fluid variant="primaryTall">
                <span className="b1">Approve USDt</span>
              </Button>
            </>
          )}

          {fragmentApproval && tokenApproval && (
            <Button onClick={() => buyout()} colorTheme="dark" fluid variant="primaryTall">
              <span className="b1">Buyout</span>
            </Button>
          )}
        </section>
      </section>

      {tokenApproval && <NFTIconNote background="rgba(255, 255, 255, 0.4)" fontClass="caption" svg="check" text="USDt access Approved." />}

      {fragmentApproval && <NFTIconNote background="rgba(255, 255, 255, 0.4)" fontClass="caption" svg="check" text="NFT access Approved." />}
    </NFTOverlay>
  );
};

export default NFTOverlayBuyout;
