import React, { useState } from "react";
import { Checkbox, PgWrapper, PgItem } from "~components";

const CheckboxPG = () => {
  const [checked, setChecked] = useState(false);
  return (
    <PgWrapper title="Checkbox" itemWidth="400px">
      <PgItem title="Light">
        <Checkbox
          label="Click me"
          checked={checked}
          setChecked={setChecked}
          colorTheme="light"
        />
      </PgItem>
      <PgItem title="Dark">
        <Checkbox
          label="Click me"
          checked={checked}
          setChecked={setChecked}
          colorTheme="dark"
        />
      </PgItem>
    </PgWrapper>
  );
};

export default CheckboxPG;
