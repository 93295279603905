import React from "react";
import styled from "@emotion/styled";
import cn from "classnames";
import { breakpoint } from "~utils/css";
import { ImageRatio, VideoThumbnailTile } from "~components";

/** ============================================================================
 * @css
 */
const Row = styled.div`
  gap: 1.5rem;
  display: flex;
  flex-direction: column;

  &:last-child {
    margin-bottom: 0;
  }

  ${breakpoint(`small-tablet`)} {
    flex-direction: row;
  }

  .mediaRow {
    &Column {
      flex: 1;
    }
  }
`;

/** ============================================================================
 * @component
 */
const MediaRow = ({ media, aspectRatio, className }) => (
  <Row className={cn(className)}>
    {media.map((mediaColumn) => (
      <div className="mediaRowColumn" key={mediaColumn._key}>
        {mediaColumn._type === `altImage` && <ImageRatio ratio={aspectRatio} image={mediaColumn.asset.gatsbyImageData} altText={mediaColumn.altText} />}
        {mediaColumn._type === `mediaVideo` && (
          <VideoThumbnailTile videoUrl={mediaColumn.video.asset.url} aspectRatio={aspectRatio} thumbnailImage={mediaColumn.thumbnail.asset.gatsbyImageData} />
        )}
      </div>
    ))}
  </Row>
);

export default MediaRow;
