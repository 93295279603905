import React from "react";
import { Button } from "~components";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTDoApproval = ({
  onStart = () => {},
  execute = async () => {},
  onComplete = () => {},
  nft,
  formData,
  subheading = `Agree to the NFT terms and conditions to proceed.`,
  setFormData,
  valid
}) => {
  // ---------------------------------------------------------------------------
  // methods

  const onChange = (e) => {
    const { name, type, value, checked } = e.target;

    if (type === `checkbox`) {
      setFormData({
        ...formData,
        [name]: checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    onStart();

    // @externalIntegration
    await execute();

    onComplete();
  };

  // ---------------------------------------------------------------------------
  // render

  return (
    <form onSubmit={onSubmit}>
      <label className="caption">
        <input name="accepted" onChange={onChange} type="checkbox" checked={formData?.accepted} />
        <span>
          Accept&nbsp;
          <a
            href={`${process.env.GATSBY_SITE_URL}/terms-and-conditions/nft/${nft?.enrichedProduct?.product?.identifier}`}
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            NFTs terms and conditions
          </a>
        </span>
      </label>
      {subheading && <p className="notice b2">{subheading}</p>}
      <Button buttonType="submit" colorTheme="dark" fluid variant="primaryTall" disabled={formData?.accepted !== true || !valid}>
        <span className="b1">Approve</span>
      </Button>
    </form>
  );
};

export default NFTDoApproval;
