import cn from "classnames";
import { useCallback, useEffect, useState } from "react";
import SVG from "../SVG";

const TableCell = ({ children, className, as: RenderElement = `div` }) => (
  <RenderElement className={className} role="cell">
    {children}
  </RenderElement>
);

const TableRow = ({ children, className, additionalContent }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentRef, setContentRef] = useState(null);
  const [contentHeight, setContentHeight] = useState(1000);

  const calculateHeight = useCallback((element) => {
    const computedStyles = getComputedStyle(element);

    return (
      [element.offsetHeight, Number.parseFloat(computedStyles.marginTop), Number.parseFloat(computedStyles.marginBottom)].reduce(
        (acc, curr) => acc + (curr || 0)
      ) + 1
    );
  }, []);

  useEffect(() => {
    if (contentRef) setContentHeight(calculateHeight(contentRef));
  }, [contentRef, setContentHeight, calculateHeight]);

  useEffect(() => {
    const resizeListener = () => {
      if (contentRef) setContentHeight(calculateHeight(contentRef));
    };

    window.addEventListener(`resize`, resizeListener);
    return () => window.removeEventListener(`resize`, resizeListener);
  }, [setContentHeight, contentRef, calculateHeight]);

  return (
    <div className={cn(className, additionalContent && `accordion`)} role="row">
      <section className="mainContent">
        {children}
        {!!additionalContent && (
          <button className="expand" type="button" onClick={() => setIsExpanded((prevValue) => !prevValue)}>
            <span className="visually-hidden">{isExpanded ? `Show less` : `Show more`}</span>
            <SVG svg={isExpanded ? `minus` : `plus`} />
          </button>
        )}
      </section>
      {!!additionalContent && (
        <div className="contentWrapper" style={{ maxHeight: isExpanded ? `${contentHeight}px` : 0 }}>
          <div className="content" ref={setContentRef}>
            {additionalContent}
          </div>
        </div>
      )}
    </div>
  );
};

const Table = ({ children, className }) => (
  <div className={cn(`table`, className)} role="table">
    {children}
  </div>
);

export default Object.assign(Table, { Row: TableRow, Cell: TableCell });
