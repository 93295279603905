import { useEffect, useState } from "react";
import { genericErrorCallback } from "~utils/helpers";
import useExternalIntegrations from "~hooks/useExternalIntegrations";

export const useKYC = (userData) => {
  let KYC_STATUS_OVERRIDE;

  // @altr: swap these out for testing
  // KYC_STATUS_OVERRIDE = `PENDING`;
  // KYC_STATUS_OVERRIDE = `SUBMITTED`;
  // KYC_STATUS_OVERRIDE = `VALIDATED`;
  // KYC_STATUS_OVERRIDE = `REJECTED`;
  // KYC_STATUS_OVERRIDE = `FINAL_REJECTED`;
  // KYC_STATUS_OVERRIDE = `VERIFIED`;

  // ---------------------------------------------------------------------------
  // imports / hooks
  const { api } = useExternalIntegrations();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [executing, setExecuting] = useState(false);
  const [kycSession, setKYCSession] = useState(null);
  const [kycStatus, setKYCStatus] = useState(KYC_STATUS_OVERRIDE || null);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const doGetKYCSessions = async () => {
      if (!userData?.user?.emailAddress) {
        setKYCSession({
          sessionId: null,
          status: `NoEmail`,
          kycEnabled: false
        });
        return;
      }
      if (executing) {
        return;
      }
      setExecuting(true);

      const currentKycSession = await api.getOrCreateKYCSession(userData?.user);

      setKYCSession(currentKycSession);

      if (KYC_STATUS_OVERRIDE) {
        setKYCStatus(KYC_STATUS_OVERRIDE);
      } else if (currentKycSession?.status !== `` && !currentKycSession?.Exception) {
        setKYCStatus(currentKycSession.status);
      } else {
        setKYCStatus(`PENDING`);
      }

      setExecuting(false);
    };

    doGetKYCSessions().catch(genericErrorCallback);
  }, [userData?.user?.emailAddress]);

  // ---------------------------------------------------------------------------

  return {
    kycSession,
    kycStatus,
    kycTestMode: typeof KYC_STATUS_OVERRIDE !== `undefined`
  };
};

export default useKYC;
