import { useEffect, useState } from "react";
import { useApp } from "~hooks";
import { genericErrorCallback } from "~utils/helpers";
import ProductState from "~dApp/models/product/ProductState";
import useExternalIntegrations from "./useExternalIntegrations";
import { ProductPurchaseStatus } from "~dApp/models/product/ProductPurchaseStatus";

export const useNFT = (guid, type) => {
  // ~dApp/models/product/ProductState.ts
  let PRODUCT_STATUS_OVERRIDE;

  // @altr: swap these out for testing
  // PRODUCT_STATUS_OVERRIDE = ProductPurchaseStatus.CollectUserInterest;
  // PRODUCT_STATUS_OVERRIDE = ProductPurchaseStatus.NoEmail;
  // PRODUCT_STATUS_OVERRIDE = ProductPurchaseStatus.DoKyc;
  // PRODUCT_STATUS_OVERRIDE = ProductPurchaseStatus.KycRejected;
  // PRODUCT_STATUS_OVERRIDE = ProductPurchaseStatus.WaitingForWhitelisting;
  // PRODUCT_STATUS_OVERRIDE = ProductPurchaseStatus.WaitingForSaleStart;
  // PRODUCT_STATUS_OVERRIDE = ProductPurchaseStatus.OnSale;
  // PRODUCT_STATUS_OVERRIDE = ProductPurchaseStatus.SoldOut;
  // PRODUCT_STATUS_OVERRIDE = ProductPurchaseStatus.OnFractionsSale;
  // PRODUCT_STATUS_OVERRIDE = ProductPurchaseStatus.OnFractionsSaleSuccess;
  // PRODUCT_STATUS_OVERRIDE = ProductPurchaseStatus.NFTBurned;
  // PRODUCT_STATUS_OVERRIDE = ProductPurchaseStatus.OnFractionsSaleFailed;
  // PRODUCT_STATUS_OVERRIDE = ProductPurchaseStatus.Unavailable;

  // ---------------------------------------------------------------------------
  // imports / hooks

  const { userIsLoggedIn, userData, emailTrigger } = useApp();
  const { api } = useExternalIntegrations();

  // ---------------------------------------------------------------------------
  // context / ref / state

  // todo (dog): could rename to something more like altrProduct
  const [enrichedProduct, setEnrichedProduct] = useState(null);
  const [nftStatus, setNftStatus] = useState(null);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!enrichedProduct?.nftData?.identifier && guid) {
      const getProduct = async () => {
        let altrProduct;
        if (type) {
          altrProduct = await api.getProductById(guid, type);
        } else {
          altrProduct = await api.getBaseProductById(guid);
        }

        setEnrichedProduct(altrProduct);
      };

      getProduct().catch(genericErrorCallback);
    }
  }, [guid, type]);

  //
  // Once enriched, set the status and assign a sale ID for fragments.

  useEffect(() => {
    if (enrichedProduct?.product) {
      let status;

      if (PRODUCT_STATUS_OVERRIDE) {
        status = new ProductState(PRODUCT_STATUS_OVERRIDE);
        setNftStatus(status);
      } else if (userIsLoggedIn) {
        const doGetPurchaseControlsStatus = async () => {
          const controlStatus = await api.getPurchaseControlsStatus(
            enrichedProduct.product
          );

          status = new ProductState(controlStatus);

          if (status) {
            setNftStatus(status);
          }
        };

        doGetPurchaseControlsStatus().catch(genericErrorCallback);
      } else {
        const doGetPurchaseControlsStatus = async () => {
          const controlStatus = await api.getProductPurchaseStatus(
            enrichedProduct.product.identifier
          );

          status = new ProductState(controlStatus);

          if (status) {
            setNftStatus(status);
          }
        };

        doGetPurchaseControlsStatus().catch(genericErrorCallback);
      }
      //
    }
  }, [
    enrichedProduct?.product?.fractionsSaleGuid,
    enrichedProduct?.product?.identifier,
    userData?.address,
    userIsLoggedIn,
    emailTrigger
  ]);

  // ---------------------------------------------------------------------------

  return {
    enrichedProduct,
    nftStatus
  };
};

export default useNFT;
