import React from "react";
import { TextCtaCluster, PgWrapper, PgItem } from "~components";

const data = {
  caption: `Features`,
  title: `A liquid market for off-chain assets.`,
  ctaLink: {
    type: "linkInternal",
    internalLink: {
      _key: null,
      _type: "linkInternal",
      text: "Find out more",
      reference: { slug: { current: "/" } }
    },
    globalLink: null,
    externalLink: {
      _key: null,
      _type: "linkExternal",
      url: null,
      text: null,
      newTab: null
    }
  }
};

const TextCtaClusterPG = () => (
  <>
    <PgWrapper title="TextCtaCluster">
      <PgItem title="No body light">
        <TextCtaCluster {...data} />
      </PgItem>
      <PgItem title="With body light">
        <TextCtaCluster
          {...data}
          body="Our trusted Oracles are experienced vendors of world-class luxury collectibles. We've now brought them on chain."
        />
      </PgItem>
      <PgItem title="No body dark">
        <TextCtaCluster {...data} colorTheme="dark" />
      </PgItem>
      <PgItem title="With body dark">
        <TextCtaCluster
          {...data}
          colorTheme="dark"
          body="Our trusted Oracles are experienced vendors of world-class luxury collectibles. We've now brought them on chain."
        />
      </PgItem>
    </PgWrapper>
  </>
);

export default TextCtaClusterPG;
