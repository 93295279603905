import { DEFAULT_CULTURE } from "~utils/helpers";

export class CustomLoginData {
    token: string;
    walletAddress: string;
    username: string;
    preferredCulture: string;
    emailAddress: string;
    nickname: string;
    memberSince: string;

    constructor() {
        this.walletAddress = "";
        this.token = "";
        this.username = "";
        this.preferredCulture = DEFAULT_CULTURE;
        this.emailAddress = "";
        this.nickname = "";
        this.memberSince = "";
    }
}