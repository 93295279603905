import React from "react";
import { CategoryTab, PgWrapper, PgItem } from "~components";

const data = {
  tabNumber: `02`,
  tabTitle: `About this product`
};

const CategoryTabPG = () => (
  <PgWrapper title="CategoryTab" itemWidth="400px">
    <PgItem title="Desktop default">
      <CategoryTab {...data} />
    </PgItem>
    <PgItem title="Desktop selected">
      <CategoryTab {...data} selected />
    </PgItem>
    <PgItem title="Mobile default">
      <CategoryTab {...data} mobile />
    </PgItem>
    <PgItem title="Mobile selected">
      <CategoryTab {...data} mobile selected />
    </PgItem>
  </PgWrapper>
);

export default CategoryTabPG;
