import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { CollectionCard, Grid, ProductCard } from "~components";
import { useDevice } from "~hooks";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  width: 100%;
  position: relative;
  padding: 0 0 2rem;
  overflow: hidden;
  background: ${({ background }) => `${background}`};
  color: ${({ color }) => `${color}`};

  ${breakpoint(`tablet`)} {
    padding: 0 0 3.75rem;
  }

  ${breakpoint(`large-tablet`)} {
    padding: 2.5rem 0 3.75rem;
  }
`;

const CardContainer = styled.li`
  position: relative;
  display: block;
  margin-bottom: 1.5rem;
`;

const TabletScrollContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  overflow-x: scroll;
  padding: 0 1rem 3rem 2rem;
`;

/** ============================================================================
 * @component
 */
const CardGrid = ({ data: { backgroundColour, fontColour, cards } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { deviceAbove, screen } = useDevice();

  // ---------------------------------------------------------------------------
  // render

  if (!cards?.[0]) {
    return null;
  }

  let hasProduct = false;

  if (cards?.[0]) {
    cards.forEach(({ _type }) => {
      if (hasProduct) {
        return;
      }

      if (_type === `product`) {
        hasProduct = true;
      }
    });
  }

  const { length } = cards;

  let dynamicColumns = `4`;
  let dynamicPadding = `125%`;

  if (deviceAbove(`tablet`)) {
    if (length < 3) {
      switch (length) {
        case 2:
          dynamicColumns = `6`;
          dynamicPadding = `80%`;
          break;

        case 1:
          dynamicColumns = `12`;
          dynamicPadding = `80%`;
          break;

        default:
          break;
      }
    }
  } else {
    dynamicColumns = `8`;
  }

  //

  let cardsJSX = [];

  if (cards?.[0]) {
    cardsJSX = cards.map((item) => {
      const isCollection = item?._type === `collection`;
      const isProduct = item?._type === `product`;
      const mobileColumns = 8;
      const tabletColumns = 4;
      const desktopColumns = isProduct ? 4 : dynamicColumns;

      return (
        <CardContainer
          key={`item-card-${item?.slug?.current || item?.name}`}
          css={css`
            grid-column: span ${mobileColumns} / span ${mobileColumns};

            ${breakpoint(`tablet`)} {
              grid-column: span ${tabletColumns} / span ${tabletColumns};

              ${!hasProduct && `width: 83.333%;flex: 1 0 auto;margin-right: 1rem;`};
            }

            ${breakpoint(`large-tablet`)} {
              width: auto;
              grid-column: span ${desktopColumns} / span ${desktopColumns};
              flex: initial;
              margin-right: 0;
              padding: 0;
            }
          `}
        >
          {isProduct && <ProductCard data={item} />}
          {isCollection && <CollectionCard data={{ ...item, paddingBottom: dynamicPadding }} />}
        </CardContainer>
      );
    });
  }

  //

  let innerJSX = [];

  if (!hasProduct && screen === `tablet`) {
    innerJSX = <TabletScrollContainer>{cardsJSX}</TabletScrollContainer>;
  } else {
    innerJSX = <Grid node="ul">{cardsJSX}</Grid>;
  }

  //

  return (
    <Container className="container" background={backgroundColour?.hex || `#ffffff`} color={fontColour?.hex || `#000000`}>
      {innerJSX}
    </Container>
  );
};

export default CardGrid;
