import { InfiniteData, keepPreviousData, useInfiniteQuery, useQuery } from "@tanstack/react-query";
import useExternalIntegrations from "./useExternalIntegrations";
import { useEffect, useState } from "react";
import { SellOrderListVM } from "~dApp/models/ApiModel";
import { walletAddressAreEqual } from "~utils/helpers";
import { paginationSize } from "~constants";
import { readContracts } from "@wagmi/core";
import { erc721Abi, parseUnits } from "viem";
import { Config, deepEqual } from "wagmi";

export const MARKETPLACE_SORT_BY = Object.freeze({ HIGHEST_PRICE: 0, LOWEST_PRICE: 1, NEAREST_EXPIRY: 2, FURTHEST_EXPIRY: 3, NEWEST: 4, OLDEST: 5 });
const sort = [
  { propertyName: `Order.Order.TotalAmount`, ascending: false },
  { propertyName: `Order.Order.TotalAmount`, ascending: true },
  { propertyName: `Order.Order.UnixTimestampExpiry`, ascending: false },
  { propertyName: `Order.Order.UnixTimestampExpiry`, ascending: true },
  { propertyName: `Order.Order.UnixTimestampCreatedOn`, ascending: false },
  { propertyName: `Order.Order.UnixTimestampCreatedOn`, ascending: true }
] as const;
export const categories = Object.freeze({ all: "active", WatchProduct: "active", CarProduct: "active", WineProduct: "active", ArtProduct: "active" });
export const statuses = Object.freeze({ all: "active", new: "active", resell: "active", auctions: "disabled" });
export const nftTypes = Object.freeze({ all: "active", whole: "active", fragments: "disabled" });
const nftTypeDict = Object.freeze({ all: "all", whole: "ERC721", fragments: "ERC1155" });

export const useMarketplace = (
  wagmiConfig: Config,
  filtersDefault?: Partial<{
    search: string;
    priceMin: string;
    priceMax: string;
    categories: { [key: string]: string };
    status: { [key: string]: string };
    nftType: { [key: string]: string };
    oracles: string[];
  }>,
  sortByDefault = MARKETPLACE_SORT_BY.NEWEST
) => {
  const { api } = useExternalIntegrations();

  const initialFilters = {
    search: ``,
    priceMin: ``,
    priceMax: ``,
    categories: Object.keys(categories).reduce((prev, category) => ({ ...prev, [category]: false, all: true }), {}),
    status: Object.keys(statuses).reduce((prev, status) => ({ ...prev, [status]: false, all: true }), {}),
    nftType: Object.keys(nftTypes).reduce((prev, nftType) => ({ ...prev, [nftType]: false, all: true }), {}),
    oracles: [`All`],
    brands: [`All`],
    model: ``,
    minYear: 1800,
    maxYear: new Date().getFullYear(),
    ...filtersDefault
  };

  const [filters, setFilters] = useState(initialFilters);
  const [timeout, setTimeoutState] = useState(null);
  const [debouncedFilters, setDebouncedFilters] = useState(initialFilters);
  const [sortBy, setSortBy] = useState<number>(sortByDefault || MARKETPLACE_SORT_BY.NEWEST);

  const clearFilters = () => {
    setFilters(initialFilters);
  };

  const { data: oracles } = useQuery({ queryKey: ["oracles"], queryFn: async () => api.getOracleList() });

  const { data: brands } = useQuery({ queryKey: ["brands"], queryFn: async () => api.getBrandList() });

  const { data, isPending, isFetchingNextPage, fetchNextPage, hasNextPage, isFetching, isPlaceholderData } = useInfiniteQuery<
    { items: SellOrderListVM[]; totalItemsCount: number },
    Error,
    InfiniteData<{ items: SellOrderListVM[]; totalItemsCount: number }>,
    string[],
    number
  >({
    queryKey: [`useMarketplace`, ...Object.values(debouncedFilters).map((val) => (typeof val === `string` ? val : JSON.stringify(val))), sortBy.toString()],
    placeholderData: keepPreviousData,
    initialPageParam: 0,
    getPreviousPageParam: (_firstPage, _allPages, firstPageParam) => (firstPageParam - 1 >= 0 ? firstPageParam - 1 : undefined),
    getNextPageParam: (lastPage, _allPages, lastPageParams) =>
      lastPage.totalItemsCount >= paginationSize * (lastPageParams + 1) ? lastPageParams + 1 : undefined,
    queryFn: async ({ pageParam }) => {
      const categories = Object.entries(debouncedFilters.categories).reduce((acc, [key, val]) => (val ? [...acc, key] : acc), []);
      const { items: orders, totalItemsCount } = await api.getSellOrders({
        "Paging.CurrentPage": pageParam,
        "Paging.ItemsPerPage": paginationSize,
        "Paging.Skip": pageParam * paginationSize,
        "Filter[Product.Text]": [debouncedFilters.search],
        "Filter[FirstSale]": (debouncedFilters.status as any)?.all
          ? undefined
          : [(debouncedFilters.status as any)?.new, !(debouncedFilters.status as any)?.resell],
        "Filter[Product.ProductType]": categories,
        "Filter[Order.NftType]": Object.entries(debouncedFilters.nftType).reduce((acc, [key, val]) => (val ? [...acc, nftTypeDict[key]] : acc), []),
        MinPrice: debouncedFilters.priceMin ? parseUnits(debouncedFilters.priceMin, 6).toString() : ``,
        MaxPrice: debouncedFilters.priceMax ? parseUnits(debouncedFilters.priceMax, 6).toString() : ``,
        sortBy: sort[sortBy],
        "Filter[Oracle.Identifier]": debouncedFilters.oracles.filter((oracle) => oracle !== `All`),
        "Filter[Brand.Identifier]": debouncedFilters.brands.filter((brand) => brand !== `All`),
        "Filter[Model]": [debouncedFilters.model],
        MinYear: !categories.includes(`all`) ? debouncedFilters.minYear.toString() : undefined,
        MaxYear: !categories.includes(`all`) ? debouncedFilters.maxYear.toString() : undefined
      });

      const getNftsOwnerParams = orders.map(({ order }) => ({
        address: order.nftToken as `0x${string}`,
        abi: erc721Abi,
        functionName: `ownerOf`,
        args: [BigInt(order.nftTokenId)] as const
      }));

      const owners = await readContracts<any, any>(wagmiConfig, { contracts: getNftsOwnerParams } as any);
      // FIXME: should be done in backend
      return { items: orders.filter(({ order }, i) => walletAddressAreEqual(owners[i].result, order.order.maker)), totalItemsCount };
    }
  });

  useEffect(
    () => {
      if (timeout) clearTimeout(timeout);
      setTimeoutState(setTimeout(() => setDebouncedFilters(filters), 500));
    },
    Object.values(filters).map((val) => (typeof val === `string` ? val : JSON.stringify(val)))
  );

  return {
    productsOnSale: data?.pages?.reduce((acc, page) => [...acc, ...page.items], []) ?? [],
    totalProducts: data?.pages?.at(-1)?.totalItemsCount || `-`,
    oracles: !filters.oracles.includes(`All`) ? oracles : [...(oracles || []), { text: `All`, identifier: `All` }],
    brands: (!filters.brands.includes(`All`) ? brands : [...(brands || []), { brandName: `All`, identifier: `All`, productType: `All` }]).filter(
      (brand) => brand.identifier === `All` || filters.categories[brand.productType]
    ),
    isLoading: isPending,
    isFetching,
    isFetchingNextPage,
    isPlaceholderData,
    isDefaultFilters: deepEqual(filters, initialFilters),
    filters,
    setFilters,
    setSortBy,
    clearFilters,
    fetchNextPage,
    hasNextPage
  };
};
