import { Link, navigate } from "gatsby";
import { css } from "@emotion/react";
import { SearchInput, Select, LoadingSpinner, MarketplaceMobileFilters } from "~components";
import { useMemo } from "react";
import { Waypoint } from "react-waypoint";
import { MARKETPLACE_SORT_BY } from "~hooks/useMarketplace";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";
import Button from "~components/Common/Button";

const MarketplaceItems = ({
  productsOnSale,
  totalProducts,
  isLoading,
  setSortBy,
  setFilters,
  filters,
  clearFilters,
  fetchNextPage,
  hasNextPage,
  isFetching,
  isFetchingNextPage,
  oracles,
  brands
}) => {
  const { getPriceWithMakerFee, makeDisplayPrice } = useParseTraderOrder();

  const handleSortChange = (value) => {
    setSortBy(value);
  };

  const handleSearchValueChange = (value) => {
    setFilters((prev) => ({ ...prev, search: value }));
  };

  const options = useMemo(() => Object.entries(MARKETPLACE_SORT_BY).map(([key, val]) => ({ value: val, title: key.split(`_`).join(` `) })), []);

  return (
    <section className="products">
      <div className="filterMobile">
        <MarketplaceMobileFilters
          searchQuery={filters.search}
          setSearchQuery={handleSearchValueChange}
          filters={filters}
          setFilters={setFilters}
          clearFilters={clearFilters}
          oracles={oracles}
          brands={brands}
        />
        <div className="subfilterMobile">
          <Select options={options} onChange={handleSortChange} title="Sort by" value={4} />
          <div className="view">
            <Button className="caption" variant="primary" colorTheme="dark" fluid onClick={() => navigate(`/profile/collection`)}>
              New Sell Offer
            </Button>
          </div>
        </div>
      </div>
      <div className="filterBar">
        <SearchInput value={filters.search} onChange={handleSearchValueChange} />
        <span className="filterBarCenter">
          <Select options={options} onChange={handleSortChange} title="Sort by" value={4} />
          <span className="caption">{!isFetching || isFetchingNextPage ? totalProducts : `-`} products</span>
        </span>
        <div className="view">
          <Button className="caption" variant="primary" colorTheme="dark" fluid onClick={() => navigate(`/profile/collection`)}>
            New Sell Offer
          </Button>
        </div>
      </div>
      <ul>
        {(!isFetching || isFetchingNextPage) &&
          productsOnSale.map((product, index) => {
            const displayPrice = makeDisplayPrice(getPriceWithMakerFee(product.order.order)).split(`.`)[0];
            const priceValue = displayPrice.replace(/,/g, ``);
            return (
              <li key={index}>
                <section className="product">
                  <svg width="146" height="20" viewBox="0 0 146 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3 0.500002L1.71396 0.500002L2.66226 1.36869L17.7189 15.1612C20.5771 17.7794 24.3126 19.2318 28.1887 19.2318L117.811 19.2318C121.687 19.2318 125.423 17.7795 128.281 15.1612L143.338 1.36869L144.286 0.5L143 0.5L3 0.500002Z"
                      stroke="#959595"
                    />
                    <text
                      css={css`
                        font-size: 0.5rem;
                        font-family: "Sohne Leicht", sans-serif;
                        fill: #ffffff;
                      `}
                      x="50%"
                      y="50%"
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      {Boolean(product.firstSale) ? `NEW` : `RESELL`}
                    </text>
                  </svg>
                  <div className="productContent">
                    <div className="heading">
                      <strong>{product.oracle.text}</strong>
                      <h3 className="max-3-lines">{product.product.text}</h3>
                      <data className="mobilePrice" value={priceValue}>
                        ${displayPrice}
                      </data>
                    </div>
                    <div>
                      <img src={product.nftImage} alt={product.product.text} />
                      <data className="price" value={priceValue}>
                        ${displayPrice}
                      </data>
                    </div>
                  </div>
                  <p className="productLink">
                    <Link to={`/marketplace/${product.product.identifier}`}>
                      <span className="visually-hidden">See {product.product.text} details</span>
                    </Link>
                  </p>
                </section>
              </li>
            );
          })}
        {isFetching && <LoadingSpinner isInline className="loadingSpinner" />}
      </ul>
      {!isLoading && !isFetchingNextPage && hasNextPage && <Waypoint onEnter={fetchNextPage} topOffset="20%" />}
    </section>
  );
};

export default MarketplaceItems;
