import React, { useState } from "react";
import styled from "@emotion/styled";
import { breakpoint } from "~utils/css";
import { ImageGallery } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  padding: 2.5rem 0;
  background: var(--color-beige);

  ${breakpoint(`small-tablet`)} {
    padding: 3.75rem 0;
  }

  .gallerySelector {
    &Nav {
      margin: auto;
      text-align: center;
      margin-bottom: 1.5rem;

      ${breakpoint(`small-tablet`)} {
        margin-bottom: 2.5rem;
      }
    }

    &NavTitle {
      margin-bottom: 8px;
    }

    &NavButtons {
      display: flex;
      justify-content: center;
      gap: 1.5rem;
    }

    &Galleries {
      position: relative;
      height: 90vh;
    }
  }
`;

const GalleryButton = styled.button`
  color: ${({ active }) => (active ? `var(--color-black)` : `var(--color-mono-40)`)};
`;

const Gallery = styled.div`
  opacity: ${({ active }) => (active ? 1 : 0)};
  pointer-events: ${({ active }) => (active ? `all` : `none`)};
  transition: opacity 0.3s;
  position: absolute;
  inset: 0;
  height: 100%;
`;

/** ============================================================================
 * @component
 */
const GallerySelector = ({ data: { galleries } }) => {
  const [activeGallery, setActiveGallery] = useState(0);

  const getFormattedGalleryNumber = (index) => {
    const number = index + 1;
    if (number < 10) {
      return `0${number}`;
    }
    return number;
  };

  return (
    <Container>
      <div className="gallerySelectorNav">
        <h4 className="tag gallerySelectorNavTitle">Gallery</h4>
        <div className="gallerySelectorNavButtons">
          {galleries.length > 2 &&
            galleries.map((gallery, i) => (
              <GalleryButton active={activeGallery === i} className="h4" type="button" key={i} onClick={() => setActiveGallery(i)}>
                {gallery.title || getFormattedGalleryNumber(i)}
              </GalleryButton>
            ))}
        </div>
      </div>

      <div className="gallerySelectorGalleries">
        {galleries.map((gallery, i) => (
          <Gallery key={i} active={activeGallery === i}>
            <ImageGallery index={i} images={gallery.images} thumbnailImages={gallery.thumbnailImages} />
          </Gallery>
        ))}
      </div>
    </Container>
  );
};

export default GallerySelector;
