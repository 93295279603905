import cn from "classnames";

const Tooltip = ({ content, children, direction = `right` }) =>
  content ? (
    <div className="tooltipWrapper">
      {children}
      {typeof content === `string` ? (
        <div className={cn(`tip`, direction)}>
          <p style={{ "--secondary-color": `var(--tooltip-text-color)` }}>{content}</p>
        </div>
      ) : (
        <div className={cn(`tip`, direction)}>{content}</div>
      )}
    </div>
  ) : (
    children
  );

export default Tooltip;
