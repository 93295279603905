import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { breakpoint } from "~utils/css";
import loader from "~assets/images/loader.webp";
import {
  BLOCK_EXPLORER,
  formatCryptoCurrency,
  formatDateTime,
  formatStablecoin,
  genericErrorCallback,
  truncateAddress,
  walletAddressAreEqual
} from "~utils/helpers";
import { Button, Image, SVG, Grid } from "~components";
import useApp from "~hooks/useApp";
import useExternalIntegrations from "~hooks/useExternalIntegrations";

const ActivityList = styled.ul`
  width: 100%;
  position: relative;

  .nftActivityListMobile {
    &Item {
      margin: 1rem;
      border: 1px solid var(--color-mono-20);
      padding: 1rem 0.75rem;
      border-radius: 0.5rem;

      display: grid;
      gap: 1rem;

      ${breakpoint(`small-tablet`)} {
        margin: 1rem 2rem;
      }

      ${breakpoint(`large-tablet`)} {
        display: none;
      }
    }
    &WalletItem {
      grid-column: 1 / 2;
    }
    &WalletLabel {
      display: flex;
      color: var(--color-khaki-100);
      margin-bottom: 0.5rem;
    }
    &WalletIcon {
      width: 12px;
      margin-right: 0.5rem;
    }
    &WalletAddress {
      margin-bottom: 2rem;
    }
    &RowInfo {
      grid-column: 1 / 4;
      display: grid;
      gap: 1rem;
      margin-bottom: 1.5rem;
    }
    &Label {
      color: var(--color-khaki-100);
      margin-bottom: 0.5rem;
    }
    &Date {
      text-align: right;
      grid-column: 2 / 3;
    }
    &DateLabel {
      margin-bottom: 0.5rem;
      color: var(--color-khaki-100);
    }
    &Actions {
      grid-column: 1 / 4;
    }
  }
`;

const ActivityListItem = styled.li`
  width: 100%;
  position: relative;
  padding: ${({ heading }) => `${heading ? `1rem` : `2rem`}`} 0 0;
  display: none;

  ${breakpoint(`large-tablet`)} {
    display: block;
  }

  .activityListItem {
    &Grid {
      align-items: center;
    }

    &Separator {
      grid-column: 1 / -1;
      height: 1px;
      margin: ${({ heading }) => `${heading ? `1rem` : `2rem`}`} 0 0;
      background: var(--color-mono-20);
    }

    //

    &From,
    &To,
    &Fragments,
    &Price,
    &Date {
      color: var(--color-${({ heading }) => `${heading ? `khaki-50` : `black`}`});
    }

    &Date {
      grid-column: span 2;
    }

    &From {
      grid-column: span 2;
    }

    &To {
      grid-column: span 2;
    }

    &Fragments {
      grid-column: span 2;
    }

    &Price {
      grid-column: span 2;

      &Hidden {
        opacity: 0;
      }
    }

    &Actions {
      grid-column: span 2;
      display: flex;
      justify-content: end;
    }
  }
`;

const ActivityEmpty = styled.div`
  grid-column: 1 / -1;
  height: 400px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-mono-20);
  border-radius: 12px;
`;

const LoadMoreButton = styled.div`
  grid-column: 1/-1;

  ${breakpoint(`large-tablet`)} {
    grid-column: 4 / span 6;
  }

  position: relative;
  margin-top: 4rem;

  // todo: refactor after buttons are aligned

  button,
  button div {
    height: 80px;
    margin-bottom: 1rem;
  }
`;

const NFTTransactionsList = ({ id, enrichedProduct, nftStatus, setTabsLength, activeId }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const { userData } = useApp();
  const { graph } = useExternalIntegrations();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [nftTransactionsList, setNftTransactionsList] = useState();
  const [showAllRows, setShowAllRows] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const setTransactionHistory = async () => {
      const contractAddress = nftStatus?.is(`SoldOut`)
        ? enrichedProduct?.nftData?.nftCollectionInfo?.collectionAddress
        : enrichedProduct?.nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress;
      const tokenId = nftStatus?.is(`SoldOut`) ? enrichedProduct?.nftData?.tokenId : enrichedProduct?.nftData?.saleId;
      let transaction0Xes = [];
      try {
        transaction0Xes = await graph.get0xTransactionsByNftId(contractAddress, tokenId);
      } catch (e) {
        console.error(e);
      }

      setNftTransactionsList(transaction0Xes);
      setTabsLength(id, transaction0Xes?.length || 0);
    };

    setTransactionHistory().catch(genericErrorCallback);
  }, [
    enrichedProduct?.nftData?.nftCollectionInfo?.collectionAddress,
    enrichedProduct?.nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress,
    enrichedProduct?.nftData?.tokenId,
    enrichedProduct?.nftData?.saleId,
    nftStatus?.status
  ]);

  const maxRows = 3;

  let displayedRows = nftTransactionsList;

  if (nftTransactionsList?.length > maxRows && !showAllRows) {
    displayedRows = nftTransactionsList.slice(0, maxRows);
  }

  // ---------------------------------------------------------------------------
  // render

  if (activeId !== `Transaction History`) {
    return null;
  }

  return (
    <>
      <ActivityList>
        {/* Mobile */}
        {!displayedRows ? (
          <Grid>
            <ActivityEmpty>
              <Image image={loader} contain fill />
            </ActivityEmpty>
          </Grid>
        ) : displayedRows <= 0 ? (
          <Grid>
            <ActivityEmpty>
              <p className="b2">No entries yet</p>
            </ActivityEmpty>
          </Grid>
        ) : (
          displayedRows.map((row, i) => {
            const from = !parseInt(row.order.direction) ? row.order.maker.id : row.order.taker.id;
            const to = parseInt(row.order.direction) ? row.order.maker.id : row.order.taker.id;

            return (
              <div className="nftActivityListMobileItem" key={i}>
                <div className="nftActivityListMobileWalletItem">
                  <div className="nftActivityListMobileWalletLabel">
                    <SVG svg="wallet" className="nftActivityListMobileWalletIcon" />
                    <h4 className="caption">From</h4>
                  </div>

                  <h4 className="caption nftActivityListMobileWalletAddress">
                    {!walletAddressAreEqual(userData?.address, from) ? truncateAddress(from) : `You`}
                  </h4>
                </div>

                <div className="nftActivityListMobileDate">
                  <h4 className="caption nftActivityListMobileDateLabel">Date</h4>
                  <div className="activityListItemDate">{formatDateTime(row?.timestamp * 1000, `DD-MM-YYYY HH:mm`)}</div>
                </div>

                <div className="nftActivityListMobileWalletItem">
                  <div className="nftActivityListMobileWalletLabel">
                    <SVG svg="wallet" className="nftActivityListMobileWalletIcon" />
                    <h4 className="caption">To</h4>
                  </div>

                  <h4 className="caption nftActivityListMobileWalletAddress">{!walletAddressAreEqual(userData?.address, to) ? truncateAddress(to) : `You`}</h4>
                </div>

                <div className="nftActivityListMobileRowInfo">
                  {row.nftTokenType === `ERC1155` && (
                    <div>
                      <h4 className="caption nftActivityListMobileLabel">Fragments</h4>
                      <div className="activityListItemFragments b1">{row.nftTokenAmount}</div>
                    </div>
                  )}

                  <div>
                    <h4 className="caption nftActivityListMobileLabel">Total Price</h4>
                    <div className="activityListItemFragments b1">
                      {row.erc20TokenAmount && `${formatCryptoCurrency(formatStablecoin(row.erc20TokenAmount, true))} USDt`}
                    </div>
                  </div>
                </div>

                <div className="nftActivityListMobileActions">
                  <Button fluid colorTheme="dark" href={`${BLOCK_EXPLORER}${row?.id}`}>
                    <span className="button-text">View in explorer</span>
                  </Button>
                </div>
              </div>
            );
          })
        )}

        {displayedRows && (
          <>
            <ActivityListItem heading>
              <Grid className="activityListItemGrid">
                <h4 className="activityListItemDate caption">Date</h4>
                <h4 className="activityListItemFrom caption">From</h4>
                <h4 className="activityListItemTo caption">To</h4>

                <h4 className="activityListItemPrice caption">Total Price</h4>

                <h4 className="activityListItemFragments caption">{displayedRows?.[0]?.nftTokenType === `ERC1155` && `Fragments`}</h4>

                <div className="activityListItemActions" />
                <div className="activityListItemSeparator" />
              </Grid>
            </ActivityListItem>

            {displayedRows?.map((row, rowIndex) => {
              const from = !parseInt(row.order.direction) ? row.order.maker.id : row.order.taker.id;
              const to = parseInt(row.order.direction) ? row.order.maker.id : row.order.taker.id;

              return (
                <ActivityListItem key={`activityListItem-${row.wallet}-${rowIndex}`}>
                  <Grid className="activityListItemGrid">
                    <h4 className="activityListItemDate">{formatDateTime(row?.timestamp * 1000, `DD-MM-YYYY HH:mm`)}</h4>

                    <h4 className="activityListItemFrom">{!walletAddressAreEqual(userData?.address, from) ? truncateAddress(from) : `You`}</h4>

                    <h4 className="activityListItemTo">{!walletAddressAreEqual(userData?.address, to) ? truncateAddress(to) : `You`}</h4>

                    <h4 className="activityListItemPrice">
                      {row?.erc20TokenAmount && `${formatCryptoCurrency(formatStablecoin(row.erc20TokenAmount, true))} USDt`}
                    </h4>

                    <h4 className="activityListItemFragments">{row.nftTokenType === `ERC1155` && row.nftTokenAmount}</h4>

                    <div className="activityListItemActions">
                      <Button colorTheme="dark" href={`${BLOCK_EXPLORER}${row?.id}`}>
                        <span className="button-text">View in explorer</span>
                      </Button>
                    </div>

                    <div className="activityListItemSeparator" />
                  </Grid>
                </ActivityListItem>
              );
            })}
          </>
        )}
        <Grid>
          <LoadMoreButton>
            {nftTransactionsList?.length > 3 && (
              <Button colorTheme="dark" fluid variant="feature" onClick={() => setShowAllRows(!showAllRows)}>
                <span className="button-text">Show {showAllRows ? `Less` : `All`}</span>
              </Button>
            )}
          </LoadMoreButton>
        </Grid>
      </ActivityList>
    </>
  );
};

export default NFTTransactionsList;
