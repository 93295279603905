import { useEffect, useState } from "react";
import { genericErrorCallback } from "~utils/helpers";
import { ProductPurchaseStatus } from "~dApp/models/product/ProductPurchaseStatus";
import AltrApiService from "~dApp/AltrApiService";
import ProductState from "~dApp/models/product/ProductState";
// import useExternalIntegrations from "~hooks/useExternalIntegrations";

// Set top-level NFT data required by downstream components in the Marketplace.
export const useEventNFT = (sanityEvent) => {
  const altrApiService = new AltrApiService();

  let PRODUCT_STATUS_OVERRIDE;

  // PRODUCT_STATUS_OVERRIDE = ProductPurchaseStatus.OnSale;
  // PRODUCT_STATUS_OVERRIDE = ProductPurchaseStatus.SoldOut;

  // ---------------------------------------------------------------------------
  // imports / hooks

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [enrichedEvent, setEnrichedEvent] = useState(null);
  const [nftStatus, setNftStatus] = useState(null);

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!enrichedEvent?.identifier && sanityEvent?.eventGuid) {
      const getEvent = async () => {
        const product = await altrApiService.enrichSanityEvent(sanityEvent);

        setEnrichedEvent(product);
      };

      getEvent().catch(genericErrorCallback);
    }
  }, [sanityEvent?.eventGuid]);

  useEffect(() => {
    if (!nftStatus) {
      let status;

      if (PRODUCT_STATUS_OVERRIDE) {
        status = new ProductState(PRODUCT_STATUS_OVERRIDE);
      }

      if (status) {
        setNftStatus(status);
      }
    }
  }, [enrichedEvent?.identifier, nftStatus?.status]);

  // ---------------------------------------------------------------------------

  return {
    enrichedEvent,
    nftStatus,
    sanityEvent
  };
};

export default useEventNFT;
