import React from "react";

const PgItem = ({ title, children }) => (
  <div style={{ marginBottom: `2rem`, marginRight: `2rem` }}>
    <div className="b1" style={{ marginBottom: `0.5rem` }}>
      {title}
    </div>
    {children}
  </div>
);

export default PgItem;
