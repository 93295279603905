import React, { useEffect, useMemo, useState } from "react";
import { navigate } from "gatsby";
import { useApp, usePurchaseData } from "~hooks";
import { NFTCheckout, NFTOverlay } from "~components";
import { walletAddressAreEqual } from "~utils/helpers";
import { usePublicClient, useWalletClient } from "wagmi";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError } from "~utils/error";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayPurchaseWholeFromSellOrder = ({ nft, activeOrder }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const {
    setOverlayCompletionData,
    activeOverlay,
    userData: {
      balances: { nativeBalance }
    },
    getConfig
  } = useApp();

  const { data: purchaseData, approved, setApproved, updateAll, reset } = usePurchaseData();

  const exchangeProxyAddress = useMemo(
    () => (walletAddressAreEqual(activeOrder?.order?.maker, nft?.enrichedProduct?.product?.oracleWalletAddress) ? activeOrder?.order?.taker : null),
    [activeOrder?.order?.nonce, nft?.enrichedProduct?.product?.oracleWalletAddress]
  );

  const { useManage0xTokenAllowance } = blockchainHooks();
  const { refetch } = useManage0xTokenAllowance(
    nft?.enrichedProduct,
    parseFloat(purchaseData?.price || 0),
    setApproved,
    false,
    { exchangeProxyAddress },
    false,
    activeOverlay === `NFTOverlayPurchaseWholeFromSellOrder`
  );

  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();

  const { trader } = useExternalIntegrations();
  const traderSdk = trader(publicClient, walletClient);

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [executing, setExecuting] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  const executeApproval = async () => {
    if (!nft?.enrichedProduct?.product || !activeOrder || executing) {
      return () => {};
    }

    setExecuting(true);

    try {
      await traderSdk.approveTokenOrder(activeOrder, { exchangeProxyAddress });
    } catch (e) {
      handleError(e, setOverlayCompletionData, nativeBalance?.value);
      console.error(e);
    }

    setExecuting(false);
    refetch();

    return null;
  };

  const purchase = async () => {
    if (!nft?.enrichedProduct?.product || !activeOrder || executing) {
      return () => {};
    }

    setExecuting(true);
    const config = await getConfig();

    // @externalintegration
    try {
      await traderSdk.fillOrder(activeOrder, {}, config);
      reset();

      navigate(`/purchase-confirmation/${nft.enrichedProduct.product.identifier}/`);
    } catch (e) {
      handleError(e, setOverlayCompletionData, nativeBalance?.value);
      console.error(e);
    }

    setExecuting(false);

    return null;
  };

  const {
    price: { displayPriceWithMakerFee }
  } = useParseTraderOrder(activeOrder?.order);

  // ---------------------------------------------------------------------------
  // lifecycle
  useEffect(() => {
    if (activeOrder && activeOverlay === `NFTOverlayPurchaseWholeFromSellOrder`) {
      const expiry = activeOrder?.order?.expiry;
      updateAll({ price: displayPriceWithMakerFee.replace(`,`, ``), expiry });
    }
  }, [activeOrder?.order?.expiry, activeOverlay, displayPriceWithMakerFee]);

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  return (
    <NFTOverlay id="NFTOverlayPurchaseWholeFromSellOrder" heading="Buy From Sell Offer" nft={nft} sidebarMode="wholeFromSellOrder">
      <NFTCheckout
        className="nftOverlayGroup"
        heading="Your Purchase"
        finalButtonText="Purchase"
        fee={nft?.enrichedProduct?.nftData?.takerFee}
        nft={nft}
        data={purchaseData}
        execute={purchase}
        executeApproval={executeApproval}
        approved={approved}
        onReset={reset}
        refetch={refetch}
        type="whole"
        approveLoading={executing && !approved}
        actionLoading={executing && approved}
      />
    </NFTOverlay>
  );
};

export default NFTOverlayPurchaseWholeFromSellOrder;
