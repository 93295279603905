import React from "react";
import styled from "@emotion/styled";
import { Button, Grid, SlicePadding } from "~components";
import { breakpoint } from "~utils/css.js";

/** ============================================================================
 * @css
 */

const Container = styled.section`
  width: 100%;
  position: relative;
  display: block;
  background: ${({ background }) => background || `#ffffff`};
  color: ${({ color }) => color || `#ffffff`};
`;

const Header = styled.header`
  grid-column: 1 / -1;
  position: relative;

  ${breakpoint(`large-tablet`)} {
    grid-column: 1 / span 6;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: 2 / span 5;
  }
`;

const Content = styled.div`
  grid-column: 1 / -1;
  position: relative;

  ${breakpoint(`large-tablet`)} {
    grid-column: 7 / span 6;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: 7 / span 5;
  }
`;

const Heading = styled.h2`
  margin-bottom: 1.5rem;
  white-space: pre-wrap;

  ${breakpoint(`tablet`)} {
    margin-bottom: 2rem;
  }

  ${breakpoint(`large-tablet`)} {
    margin-bottom: 0;
  }
`;

const Body = styled.p`
  margin-bottom: 2rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;

/** ============================================================================
 * @component
 */
const LinkListCTA = ({ data: { backgroundColour, fontColour, heading, body, links } }) => (
  <Container background={backgroundColour?.hex} color={fontColour?.hex}>
    <SlicePadding>
      <Grid noXPadding node="article">
        <Header>
          <Heading className="h2">{heading}</Heading>
        </Header>

        <Content>
          <Body className="h4">{body}</Body>

          {links?.[0] && (
            <ButtonsContainer>
              {links?.map(({ reference }, i) => (
                <Button iconRight="arrowRight" href={reference?.link.url} key={i}>
                  {reference?.link.text}
                </Button>
              ))}
            </ButtonsContainer>
          )}
        </Content>
      </Grid>
    </SlicePadding>
  </Container>
);

export default LinkListCTA;
