import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { v4 as uuid } from "uuid";
import { Grid } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */

const Container = styled.section`
  width: 100%;
  position: relative;
  display: block;
  background: ${({ background }) => background || `#ffffff`};
  color: ${({ color }) => color || `#ffffff`};

  ${breakpoint(`large-tablet`)} {
    padding: 2rem 0 4rem;
  }
`;

const List = styled.ul`
  grid-column: 1 / -1;
  position: relative;
  padding: 2.5rem 0 1rem;
  border-top: 1px solid var(--color-black);

  ${breakpoint(`small-tablet`)} {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
    justify-content: center;
  }

  ${breakpoint(`large-tablet`)} {
    flex-wrap: initial;
    gap: 1.5rem;
    margin-top: 2rem;
    padding: 3rem 0 4rem;
    border: none;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: 2 / -2;
  }
`;

const ListItem = styled.li`
  position: relative;
  flex-basis: 100%;
  margin-bottom: 1.5rem;

  ${breakpoint(`small-tablet`)} {
    flex-basis: 50%;
    margin-bottom: 2.5rem;
  }

  ${breakpoint(`large-tablet`)} {
    flex-basis: 25%;
    margin-bottom: 0;
  }
`;

/** ============================================================================
 * @component
 */
const FourSteps = ({ data: { backgroundColour, fontColour, steps } }) => (
  <Container background={backgroundColour?.hex} color={fontColour?.hex}>
    <Grid>
      <List>
        {steps?.[0] &&
          steps.map((step, index) => (
            <ListItem key={`four-step-column-${uuid()}`}>
              <h4 className="caption">STEP</h4>

              <h3
                css={css`
                  margin: 0.5rem 0 0.75rem;
                `}
                className="d1"
              >
                0{index + 1}
              </h3>

              <p
                className="b2"
                css={css`
                  white-space: pre-line;
                `}
              >
                {step}
              </p>
            </ListItem>
          ))}
      </List>
    </Grid>
  </Container>
);

export default FourSteps;
