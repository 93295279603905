import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import cn from "classnames";
import { breakpoint } from "~utils/css";
import TextImageChangerScrollingText from "./components/TextImageChangerScrollingText";

/** ============================================================================
 * @css
 */
const transitionTime = `1s`;
const rowHeight = `30px`;

const Container = styled.section`
  background: var(--color-black);
  text-transform: uppercase;

  .textImageChanger {
    &TextRow {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: ${rowHeight};

      &Mobile {
        ${breakpoint(`tablet`)} {
          display: none;
        }
      }

      &Desktop {
        display: none;
        ${breakpoint(`tablet`)} {
          display: flex;
        }
      }
    }

    &StaticText {
      color: var(--color-mono-40);
      height: 100%;

      ${breakpoint(`tablet`)} {
        margin-right: 1.25rem;
      }
    }

    &ImageContainer {
      margin: 1.25rem 0;
      border-radius: 16px;
      overflow: hidden;
      position: relative;
      padding-bottom: 125%;
      -webkit-mask-image: -webkit-radial-gradient(white, black); // Need for safari rounded corners ¯\_(ツ)_/¯

      ${breakpoint(`small-tablet`)} {
        padding-bottom: 60%;
      }
    }

    &ImageFilter {
      background: #006eff;
      position: absolute;
      inset: 0;
      height: 100%;
      mix-blend-mode: difference;
      transition: top ${transitionTime};

      &Top {
        top: 100%;
      }

      &Middle {
        top: 0%;
      }

      &Bottom {
        top: -100%;
      }
    }

    &Image {
      position: absolute;
      inset: 0;
      opacity: 0;
      transition: opacity ${transitionTime};

      &Visible {
        opacity: 1;
      }
    }

    &BottomText {
      ${breakpoint(`tablet`)} {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

/** ============================================================================
 * @component
 */
const TextImageChanger = ({ data: { slides, colorEffect } }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [orchestratedIndex, setOrchestratedIndex] = useState(0);
  const [filterPosition, setFilterPosition] = useState(`top`); // `top` | `middle` | `bottom`
  const filterDirectionRef = useRef(`down`); // `down` | `up`

  const isInitialRenderRef = useRef(true);

  const INTERVAL_DURATION_MS = 4000;
  const DELAY_DURATION_MS = 1000;

  const updateSlide = () => {
    setCurrentSlideIndex((prev) => {
      if (prev === slides.length - 1) {
        return 0;
      }
      return prev + 1;
    });
  };

  // Setup interval
  useEffect(() => {
    const interval = setInterval(updateSlide, INTERVAL_DURATION_MS);
    return () => clearInterval(interval);
  }, []);

  // Control filter position to middle
  useEffect(() => {
    if (isInitialRenderRef.current) return;
    setFilterPosition((prev) => {
      if (prev === `top`) {
        filterDirectionRef.current = `down`;
        return `middle`;
      }
      if (prev === `bottom`) {
        filterDirectionRef.current = `up`;
        return `middle`;
      }
      return prev;
    });
  }, [currentSlideIndex]);

  // Control filter position to top or bottom
  useEffect(() => {
    if (filterPosition !== `middle`) return;
    if (filterDirectionRef.current === `up`) {
      setTimeout(() => setFilterPosition(`top`), DELAY_DURATION_MS);
    } else {
      setTimeout(() => setFilterPosition(`bottom`), DELAY_DURATION_MS);
    }
  }, [filterPosition]);

  // Update orchestrated index
  useEffect(() => {
    setTimeout(() => setOrchestratedIndex(currentSlideIndex), DELAY_DURATION_MS);
  }, [currentSlideIndex]);

  // Check if initial render
  useEffect(() => {
    if (isInitialRenderRef.current) {
      isInitialRenderRef.current = false;
    }
  }, []);

  const altrText = slides.map((slide) => slide.altr);
  const intoText = slides.map((slide) => slide.into);

  return (
    <div className="bg-wrapper dark">
      <Container className="container dark wide">
        <div className="textImageChangerTextRow textImageChangerTextRowMobile">
          <span className="textImageChangerStaticText">Altr</span>
          <TextImageChangerScrollingText text={altrText} orchestratedIndex={orchestratedIndex} />
        </div>

        <div className="textImageChangerImageContainer">
          {slides.map((slide, i) => (
            <GatsbyImage
              className={cn(`textImageChangerImage`, {
                [`textImageChangerImageVisible`]: i === orchestratedIndex
              })}
              key={i}
              aria-hidden={i !== orchestratedIndex}
              image={slide.image.asset.gatsbyImageData}
              alt={slide.image.asset.altText || ``}
            />
          ))}
          {colorEffect && (
            <div
              className={cn(`textImageChangerImageFilter`, {
                [`textImageChangerImageFilterTop`]: filterPosition === `top`,
                [`textImageChangerImageFilterMiddle`]: filterPosition === `middle`,
                [`textImageChangerImageFilterBottom`]: filterPosition === `bottom`
              })}
            />
          )}
        </div>

        <div className="textImageChangerBottomText">
          <div className="textImageChangerTextRow textImageChangerTextRowDesktop">
            <span className="textImageChangerStaticText">Altr</span>
            <TextImageChangerScrollingText text={altrText} orchestratedIndex={orchestratedIndex} />
          </div>
          <div className="textImageChangerTextRow">
            <span className="textImageChangerStaticText">Into</span>
            <TextImageChangerScrollingText text={intoText} orchestratedIndex={orchestratedIndex} />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TextImageChanger;
