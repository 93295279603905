import React from "react";
import styled from "@emotion/styled";
import { PriceWidget, FiatPaymentForm } from "~components";
import { breakpoint } from "~utils/css";

import { thousandCommas } from "~utils/helpers";
import EventOverlay from "~components/Event/_Common/Form/EventOverlay";

/** ============================================================================
 * @css
 */

const Container = styled.article`
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};

  width: 100%;
  position: relative;

  .nftOverlayPurchase {
    position: relative;
    overflow: hidden;
    border-radius: 1rem;

    &Cancel {
      width: 100%;
      margin-top: 0.5rem;

      .buttonContainer {
        width: 100% !important;
        border: 3px solid red;
      }

      button {
        width: 100%;
        display: block;
      }
    }

    &Price,
    &Terms {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 1rem;
      ${breakpoint(`large-tablet`)} {
        padding: 3rem;
      }
    }

    &Price {
      background: white;
      backdrop-filter: blur(50px);
    }

    &Terms {
      flex-direction: column;
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(50px);

      ${breakpoint(`large-tablet`)} {
        flex-direction: row;
      }
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const EventOverlayPurchaseFiat = ({ nft, displayPrice, selected }) => {
  const { enrichedEvent, sanityEvent } = nft;

  // ---------------------------------------------------------------------------
  // render

  return (
    <EventOverlay id="EventOverlayPurchaseFiat" heading="Ticket Purchase" nft={nft} sanityEvent={sanityEvent} sidebarMode="offer" selected={selected}>
      {({ active }) => (
        <Container active={active}>
          {active && (
            <>
              <div className="nftOverlayGroup nftOverlayPurchase">
                {enrichedEvent && (
                  <>
                    <div className="nftOverlayPurchasePrice">
                      <h2 className="nftOverlayPurchaseHeading b1">Item Total</h2>

                      <div>
                        <PriceWidget displayPrice={thousandCommas(displayPrice)} fontClass="h2" />
                      </div>
                    </div>
                    <FiatPaymentForm selected={selected} sanityEvent={nft.sanityEvent} />
                  </>
                )}
              </div>
            </>
          )}
        </Container>
      )}
    </EventOverlay>
  );
};

export default EventOverlayPurchaseFiat;
