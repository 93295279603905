import React, { useState } from "react";
import styled from "@emotion/styled";
import { Button, Grid, SVG } from "~components";
import { useApp } from "~hooks";
import { breakpoint } from "~utils/css";
import { walletAddressAreEqual, truncateAddress, formatStablecoin, formatCryptoCurrency } from "~utils/helpers";
import loader from "~assets/images/loader.webp";
import Image from "~components/Common/Image";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  width: 100%;
  position: relative;
  display: block;
`;

const ActivityEmpty = styled.div`
  grid-column: 1 / -1;
  height: 400px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-mono-20);
  border-radius: 12px;
`;

const ActivityList = styled.ul`
  width: 100%;
  position: relative;

  .nftActivityListMobile {
    &Item {
      margin: 1rem;
      border: 1px solid var(--color-mono-20);
      padding: 1rem 0.75rem;
      border-radius: 0.5rem;

      ${breakpoint(`small-tablet`)} {
        margin: 1rem 2rem;
      }

      ${breakpoint(`large-tablet`)} {
        display: none;
      }
    }
    &WalletLabel {
      display: flex;
      color: var(--color-khaki-100);
      margin-bottom: 0.5rem;
    }
    &WalletIcon {
      width: 12px;
      margin-right: 0.5rem;
    }
    &WalletAddress {
      margin-bottom: 2rem;
    }
    &RowInfo {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 1.5rem;
    }
    &Label {
      color: var(--color-khaki-100);
      margin-bottom: 0.5rem;
    }
  }
`;

const LoadMoreButton = styled.div`
  grid-column: 1/-1;

  ${breakpoint(`large-tablet`)} {
    grid-column: 4 / span 6;
  }

  position: relative;
  margin-top: 4rem;

  // todo: refactor after buttons are aligned

  button,
  button div {
    height: 80px;
    margin-bottom: 1rem;
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTActivityListMobile = ({
  rows,
  buttonLabel,
  alternativeButtonLabel,
  nftStatus,
  secondaryAction,
  handleSecondaryActivityItemClick = () => {},
  handleActivityItemClick = () => {}
}) => {
  // ---------------------------------------------------------------------------
  // imports / state
  const [showAllRows, setShowAllRows] = useState(false);

  const { userData } = useApp();

  const maxRows = 3;

  const isSoldOut = nftStatus?.is(`SoldOut`);

  let displayedRows = rows;

  if (rows?.length > maxRows && !showAllRows) {
    displayedRows = rows.slice(0, maxRows);
  }

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container>
      {rows === undefined && (
        <Grid>
          <ActivityEmpty>
            <Image image={loader} contain fill />
          </ActivityEmpty>
        </Grid>
      )}
      {rows?.length > 0 && (
        <>
          <ActivityList>
            {displayedRows.map((row, i) => {
              const isOwner = walletAddressAreEqual(userData?.address, row?.address) || walletAddressAreEqual(userData?.address, row?.order?.maker);
              return (
                <div className="nftActivityListMobileItem" key={i}>
                  <div className="nftActivityListMobileWalletLabel">
                    <SVG svg="wallet" className="nftActivityListMobileWalletIcon" />
                    <h4 className=" caption">Wallet</h4>
                  </div>

                  <h4 className="caption nftActivityListMobileWalletAddress">{!isOwner ? truncateAddress(row?.address || row?.order?.maker) : `You`}</h4>
                  <div className="nftActivityListMobileRowInfo">
                    {!isSoldOut && (
                      <div>
                        <h4 className="caption nftActivityListMobileLabel">Fragments</h4>
                        <div className="activityListItemFragments b1">{row.fractions || row.nftTokenAmount}</div>
                      </div>
                    )}
                    {row.sellOrBuyNft && (
                      <div>
                        <h4 className="caption nftActivityListMobileLabel">{`Price ${!isSoldOut ? `per Fragment` : ``}`}</h4>
                        <div className="activityListItemFragments b1">
                          {row?.erc20TokenAmount &&
                            row?.order?.fees?.[0] &&
                            row?.nftTokenAmount &&
                            `${formatCryptoCurrency(
                              formatStablecoin(
                                (BigInt(row.erc20TokenAmount) + BigInt(row.sellOrBuyNft === `sell` ? row.order.fees[0].amount : 0)) /
                                  BigInt(row.nftTokenAmount || `1`)
                              )
                            )} USDt`}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="activityListItemActions">
                    <Button variant={isOwner ? `feature` : `primaryTall`} fluid colorTheme="dark" onClick={() => handleActivityItemClick(row)}>
                      <span className="button-text">{isOwner ? alternativeButtonLabel : buttonLabel}</span>
                    </Button>
                  </div>
                </div>
              );
            })}
          </ActivityList>
        </>
      )}

      {rows?.length <= 0 && (
        <Grid>
          <ActivityEmpty>
            <p className="b2">No entries yet</p>
          </ActivityEmpty>
        </Grid>
      )}
      <Grid>
        <LoadMoreButton>
          {secondaryAction && (
            <Button colorTheme="dark" fluid variant="feature" onClick={() => handleSecondaryActivityItemClick()}>
              <span className="button-text">{secondaryAction}</span>
            </Button>
          )}
          {rows?.length > 3 && (
            <Button colorTheme="dark" fluid variant="feature" onClick={() => setShowAllRows(!showAllRows)}>
              <span className="button-text">Show {showAllRows ? `Less` : `All`}</span>
            </Button>
          )}
        </LoadMoreButton>
      </Grid>
    </Container>
  );
};

export default NFTActivityListMobile;
