import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useApp } from "~hooks";
import { breakpoint } from "~utils/css";
import { usePublicClient } from "wagmi";
import Button from "~components/Common/Button";
import altrLoginAPI from "~hooks/altrLoginApi";
import { handleError } from "~utils/error";

/** ============================================================================
 * @css
 */

const Container = styled.article`
  transition: 0.5s var(--cubic-easing) transform;

  opacity: ${({ active }) => `${active ? 1 : 0}`};
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};
  transform: scale(${({ active }) => `${active ? `1` : `1.05`}`});

  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 90;
  display: flex;
  align-items: center;
  justify-content: center;

  .idVerificationModalEscape {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  iframe {
    width: 100%;
    min-width: 0 !important;
    position: relative;
    z-index: 10;

    ${breakpoint(`large-tablet`)} {
      width: auto;
      min-width: 400px !important;
    }
  }
`;

const Content = styled.div`
  width: 50%;
  max-width: 500px;
  height: 30%;
  background: white;
  margin: auto;
  color: black;
  border-radius: 1rem;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10;
  justify-content: space-between;
  align-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10;
  width: 80%;
  height: 50%;

  input[type="email"] {
    width: 100%;
    height: 48px;
    border: 1px solid black;
    padding: 0 1rem;
    background: white;
    border-radius: 0.5rem;
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayLeaveEmail = () => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const publicClient = usePublicClient();
  const { activeOverlay, setActiveOverlay, userData, reloadProfileData, setReloadProfileData, setOverlayCompletionData } = useApp();
  const { updateUserProfile, refreshProfileData } = altrLoginAPI();

  // ---------------------------------------------------------------------------
  // state / refs

  const [email, setEmail] = useState(``);

  // ---------------------------------------------------------------------------
  // methods
  const active = activeOverlay === `NFTOverlayLeaveEmail`;

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      await updateUserProfile(email, userData?.user?.nickname || null);
      await refreshProfileData();
      setReloadProfileData(!reloadProfileData);
      setActiveOverlay(`NFTOverlayWhitelist`);
    } catch (e) {
      handleError(e, setOverlayCompletionData, await publicClient.getBalance({ address: userData?.address }));
      console.error(e);
    }
  };

  const onChange = (e) => {
    setEmail(e.target.value);
  };

  // ---------------------------------------------------------------------------
  // lifecylce
  useEffect(() => {
    if (activeOverlay === null) {
      setEmail(``);
    }
  }, [activeOverlay]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container active={active}>
      {active && (
        <>
          <div className="idVerificationModalEscape" aria-hidden onClick={() => setActiveOverlay(null)} />

          {active && (
            <Content>
              <h1>Leave Your Email</h1>
              <h4>Please leave your email to complete the id verfication process</h4>
              <Form onSubmit={onSubmit}>
                <input name="email" type="email" className="b2" placeholder="Your Email" onChange={onChange} value={email} />
                <Button variant="primary" colorTheme="dark" buttonType="submit" fluid>
                  Confirm
                </Button>
              </Form>
            </Content>
          )}
        </>
      )}
    </Container>
  );
};

export default NFTOverlayLeaveEmail;
