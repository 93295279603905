import React from "react";
import styled from "@emotion/styled";
import { breakpoint } from "~utils/css";
import { SVG } from "~components";

/** ============================================================================
 * @css
 */
const TextBlock = styled.div`
  grid-column: 1/-1;
  text-align: center;

  .homepageAtfText {
    &Logo {
      width: 100px;
      margin-bottom: 1.5rem;

      ${breakpoint(`large-tablet`)} {
        width: 200px;
      }
    }

    &FeaturedHeadline {
      &:last-child {
        margin-bottom: 0;
      }
    }

    &Tagline {
      color: var(--color-mono-20);
      margin-bottom: 1.75rem;
      white-space: pre-wrap;
    }
  }
`;

const Container = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .homepageAtfFlexContainer {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .homepageAtfTextCurrencyTicker {
    grid-column: 1/-1;
  }

  ${breakpoint(`large-tablet`)} {
    .homepageAtfTextCurrencyTicker {
      grid-column: 6/-1;
    }
  }

  ${breakpoint(`desktop`)} {
    .homepageAtfTextCurrencyTicker {
      grid-column: 8/-1;
    }
  }
`;

/** ============================================================================
 * @component
 */
const TextBlockComponent = ({ textFeaturedHeadline, tagline }) => (
  <TextBlock>
    <div>
      <SVG className="homepageAtfTextLogo" svg="logo" />
      {textFeaturedHeadline && <h2 className="h3 homepageAtfTextFeaturedHeadline">{textFeaturedHeadline}</h2>}
      {tagline && <div className="b1 homepageAtfTextTagline">{tagline}</div>}
    </div>
  </TextBlock>
);

const CenterContent = ({ textFeaturedHeadline, tagline }) => (
  <Container>
    <div className="homepageAtfFlexContainer">
      <TextBlockComponent textFeaturedHeadline={textFeaturedHeadline} tagline={tagline} />
    </div>
  </Container>
);

export default CenterContent;
