/* eslint-disable */
import React from "react";
import { TextBlock, PgWrapper, PgItem } from "~components";

const rawText = [
  {
    _key: "cca9cfe102f0",
    _type: "block",
    children: [
      {
        _key: "d2c5190862020",
        _type: "span",
        marks: [],
        text: "D1 text"
      }
    ],
    markDefs: [],
    style: "d1"
  },
  {
    _key: "aca9cfe102f0",
    _type: "block",
    children: [
      {
        _key: "d2c5190862020",
        _type: "span",
        marks: [],
        text: "H1 text"
      }
    ],
    markDefs: [],
    style: "h1"
  },
  {
    _key: "bca9cfe102f0",
    _type: "block",
    children: [
      {
        _key: "d2c5190862020",
        _type: "span",
        marks: [],
        text: "H2 text"
      }
    ],
    markDefs: [],
    style: "h2"
  },
  {
    _key: "baa9cfe102f0",
    _type: "block",
    children: [
      {
        _key: "d2c5190862020",
        _type: "span",
        marks: [],
        text: "H3 text"
      }
    ],
    markDefs: [],
    style: "h3"
  },
  {
    _key: "baa9cae102f0",
    _type: "block",
    children: [
      {
        _key: "d2c5190862020",
        _type: "span",
        marks: [],
        text: "H4 text"
      }
    ],
    markDefs: [],
    style: "h4"
  },
  {
    _key: "baa9cae102f0",
    _type: "block",
    children: [
      {
        _key: "d2c5190862020",
        _type: "span",
        marks: [],
        text: "Normal: Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service."
      }
    ],
    markDefs: [],
    style: "normal"
  },
  {
    _key: "baa9cae102f0",
    _type: "block",
    children: [
      {
        _key: "d2c5190862020",
        _type: "span",
        marks: ["strong"],
        text: "Bold: Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service."
      }
    ],
    markDefs: [],
    style: "normal"
  },
  {
    _key: "baa9cae102f0",
    _type: "block",
    children: [
      {
        _key: "d2c5190862020",
        _type: "span",
        marks: ["em"],
        text: "Italic: Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service."
      }
    ],
    markDefs: [],
    style: "normal"
  },
  {
    _key: "baa9cae102f0",
    _type: "block",
    children: [
      {
        _key: "d2c5190862020",
        _type: "span",
        marks: ["strong", "em"],
        text: "Bold Italic: Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service."
      }
    ],
    markDefs: [],
    style: "normal"
  }
];

const TextBlockPG = () => (
  <>
    <PgWrapper title="TextBlock">
      <PgItem>
        <TextBlock rawText={rawText} />
      </PgItem>
    </PgWrapper>
  </>
);

export default TextBlockPG;
