import React from "react";
import styled from "@emotion/styled";
import { breakpoint } from "~utils/css";

const Container = styled.div`
  align-items: flex-start;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  grid-column: 1 / -1;

  ${breakpoint(`large-tablet`)} {
    grid-column: 1 / span 6;
  }

  ${breakpoint(`desktop`)} {
    grid-column: 1 / span 5;
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 0.75rem;

    ${breakpoint(`large-tablet`)} {
      font-size: 2.75rem;
      margin-bottom: 0;
    }
  }

  .homepageAtfTextCurrencyTicker:not(#booster) {
    align-self: flex-start;
  }
`;

const TextFocussed = ({ textFeaturedHeadline }) => (
  <Container>
    <h2>{textFeaturedHeadline}</h2>
  </Container>
);

export default TextFocussed;
