import React from "react";
import styled from "@emotion/styled";
import { breakpoint } from "~utils/css";
import { SVG } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  display: inline-block;
`;

const Note = styled.div`
  width: 100%;
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid var(--color-black);

  ${breakpoint(`small-tablet`)} {
    display: flex;
  }
`;
const NoteIcon = styled.figure`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  background: var(--color-black);
  color: var(--color-white);
  flex-shrink: 0;

  svg {
    width: 16px;
  }

  ${breakpoint(`small-tablet`)} {
    width: 56px;
    height: auto;
  }
`;
const NoteText = styled.div`
  padding: 1.5rem 1rem;

  p {
    font-size: 12px;
  }
`;

/** ============================================================================
 * @component
 */
const IconNote = ({ className, fontClass = `b2`, style = `default`, svg = `shield`, text }) => (
  <Container>
    <Note className={className}>
      <NoteIcon>
        <SVG svg={svg} />
      </NoteIcon>

      <NoteText>
        <p className={fontClass}>{text}</p>
      </NoteText>
    </Note>
  </Container>
);

export default IconNote;
