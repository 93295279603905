import React, { useEffect, useState } from "react";
import { useApp } from "~hooks";
import { NFTOverlay, PriceWidget, Button, NFTIconNote } from "~components";
import styled from "@emotion/styled";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError } from "~utils/error";
import { usePublicClient } from "wagmi";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};

  width: 100%;
  position: relative;

  .nftOverlayClaimRequestPayFee {
    &Container {
      border-radius: 1rem;
      overflow: hidden;
      margin-bottom: 0.75rem;
    }

    &Price {
      background: var(--color-white);
      display: flex;
      align-items: center;
      padding: 2.5rem;
      justify-content: space-between;
    }

    &MainContent {
      background: #faf9f9;
      padding: 2.5rem;
    }

    &Subheader {
      margin-bottom: 1rem;
    }

    &List {
      color: var(--color-mono-60);
      margin-bottom: 0.5rem;

      li {
        list-style: initial;
        margin-left: 1.5rem;
      }
    }

    &Hint {
      margin-bottom: 2rem;
      color: var(--color-mono-60);
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayClaimRequestPayFee = ({ nft }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const { setOverlayProcessingData, setActiveOverlay, userData, setOverlayCompletionData } = useApp();
  const { usePayRedemptionFee, useGetRedemptionFeeCost } = blockchainHooks();
  const publicClient = usePublicClient();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [executing, setExecuting] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle
  const { data: claimFee } = useGetRedemptionFeeCost(nft?.enrichedProduct?.nftData);

  const { writeAsync: doPayRedemptionFee, isSuccess } = usePayRedemptionFee(nft?.enrichedProduct?.nftData);

  useEffect(() => {
    if (isSuccess) {
      setActiveOverlay(`NFTOverlayClaimRequestClaim`);
      setOverlayProcessingData(null);
      setExecuting(false);
    }
  }, [isSuccess]);

  // ---------------------------------------------------------------------------
  // methods

  const payFee = async () => {
    if (!nft?.enrichedProduct?.nftData || executing) {
      return () => {};
    }

    setExecuting(true);

    setOverlayProcessingData({
      icon: `load`,
      text: `Processing. Please do not close this page.`
    });

    // @externalintegration
    try {
      await doPayRedemptionFee();
    } catch (e) {
      setOverlayProcessingData(null);
      setExecuting(false);
      console.error(e);
      handleError(e, setOverlayCompletionData, await publicClient.getBalance({ address: userData?.address }));
    }
  };

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  return (
    <NFTOverlay id="NFTOverlayClaimRequestPayFee" heading="Claim Request – Pay Claim Fee" nft={nft}>
      {({ active }) => (
        <Container active={active}>
          {active && (
            <>
              <div className="nftOverlayClaimRequestPayFeeContainer">
                <div className="nftOverlayClaimRequestPayFeePrice">
                  <div className="b1">Claim Fee</div>
                  <div>
                    <PriceWidget displayPrice={claimFee} fontClass="h2" />
                  </div>
                </div>
                <div className="nftOverlayClaimRequestPayFeeMainContent">
                  <p className="nftOverlayClaimRequestPayFeeSubheader b1">You are claiming your collectible</p>

                  <ul className="nftOverlayClaimRequestPayFeeList b2">
                    <li>Once the request is sent to the oracle you will not be able to transfer the NFT.</li>
                    <li>The NFT will be frozen until the end of the claim operations.</li>
                    <li>Once the item is claimed, the oracle will BURN the NFT.</li>
                  </ul>

                  <p className="nftOverlayClaimRequestPayFeeHint b2">Click ‘Pay Fee’ to proceed onto claiming the item.</p>
                  <Button variant="primaryTall" colorTheme="dark" fluid onClick={payFee}>
                    Pay Claim Fee
                  </Button>
                </div>
              </div>
            </>
          )}
        </Container>
      )}
    </NFTOverlay>
  );
};

export default NFTOverlayClaimRequestPayFee;
