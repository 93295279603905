import React from "react";
import { SanitySlice } from "~components";

/** ============================================================================
 * One-time API enrichment of Sanity data + helpers.
 */
const useSanity = (sanitySlices, showSliceTitles = false) => ({
  slices:
    sanitySlices?.[0] &&
    sanitySlices.map((slice) => {
      if (showSliceTitles) {
        return (
          <div key={slice._key}>
            {showSliceTitles && <h2 style={{ padding: 16, background: `green` }}>{slice._type}</h2>}
            <SanitySlice key={slice._key} data={slice} />
          </div>
        );
      }

      return <SanitySlice key={slice._key} data={slice} />;
    })
});

export default useSanity;
