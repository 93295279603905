import React from "react";
import { Image } from "~components";

const MarketplaceItemCertificate = ({ certificateUrl }) => (
  <div className="certificate">
    <Image image={certificateUrl} alt="Authenticity Certificate" />
  </div>
);

export default MarketplaceItemCertificate;
