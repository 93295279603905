type Props = {
  src?: string;
  mobileSrc?: string;
  originalWidth?: number;
  quality?: number;
}

export const buildBgImages = ({
  src,
  mobileSrc,
  originalWidth = 3048,
  quality = 85
}: Props) => {
  if (!src) return ``;

  const srcUrlObj = new URL(src);
  const mobileSrcUrlObj = mobileSrc ? new URL(mobileSrc) : null;
  const imageUrl = `${srcUrlObj.origin}${srcUrlObj.pathname}`;
  const mobileImageUrl = mobileSrcUrlObj ? `${mobileSrcUrlObj.origin}${mobileSrcUrlObj.pathname}` : null;
  const dimensionsArray = mobileImageUrl ? [660, 1024, 1280, 1440, 1920, 2048, 3048] : [400, 500, 640, 1024, 1280, 1440, 1920, 2048, 3048];
  const resolutions = [...dimensionsArray.filter((size) => size < originalWidth), originalWidth];

  return resolutions
    .map((resolution, index) => {
      if (index === 0) return `background-image: url(${mobileImageUrl || imageUrl}?w=${resolution}&auto=format&q=${quality});`;

      return `
      @media only screen and (min-width: ${resolutions[index - 1]}px) {
        background-image: url(${imageUrl}?w=${resolution}&auto=format&q=85);
      }
    `;
    })
    .join(`\n\n`);
};