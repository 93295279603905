import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import { isMetamask } from "~utils/helpers";

const ProductCertificate = ({ guaranteeCertificateUrl }) => {
  if (typeof window === `undefined`) {
    return null;
  }

  if (isMetamask) {
    return (
      <Link
        to={guaranteeCertificateUrl}
        css={css`
          color: var(--color-primary-orange-100);
          font-weight: 600;
          test-decoration: underline;
        `}
      >
        Authenticity Certificate
      </Link>
    );
  }

  return (
    <object
      css={css`
        width: 100%;
        height: 100%;
      `}
      type="text/html"
      data={guaranteeCertificateUrl}
      aria-label="Guarantee Certificate"
    />
  );
};

export default ProductCertificate;
