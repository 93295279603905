import cn from "classnames";
import { useEffect, useRef } from "react";
import { SVG } from "~components";

const ModalFooter = ({ className, children }) => <footer className={className}>{children}</footer>;

const ModalContent = ({ className, children }) => <main className={className}>{children}</main>;

const Modal = ({ title, className, children, isVisible, onClose = () => {}, disableOutsideClick, hideHeader }) => {
  const modalRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!disableOutsideClick && modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener(`mousedown`, handleClickOutside);

    return () => document.removeEventListener(`mousedown`, handleClickOutside);
  }, [modalRef, isVisible]);

  useEffect(() => {
    if (isVisible) document.documentElement.style.overflow = `hidden`;
    else document.documentElement.style.removeProperty(`overflow`);

    return () => {
      document.documentElement.style.removeProperty(`overflow`);
    };
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <div className={cn(`modalWrapper`, className)}>
      <div className="modal" ref={modalRef}>
        {!hideHeader && (
          <header className={cn(title && `hasTitle`)}>
            {title && <h2>{title}</h2>}
            <button type="button" onClick={onClose}>
              <span className="visually-hidden">Close</span>
              <SVG className="closeSvg" svg="cross" />
            </button>
          </header>
        )}
        {children}
      </div>
    </div>
  );
};

export default Object.assign(Modal, { Content: ModalContent, Footer: ModalFooter });
