import React, { useEffect } from "react";
import { css } from "@emotion/react";
import { useInView } from "react-intersection-observer";
import { Link, SVG } from "~components";
import { useApp } from "~hooks";
import { lucidaoWebsite } from "~utils/helpers";
import usePoweredAlchemy from "~hooks/usePoweredAlchemy";

/** ============================================================================
 * @component
 */
const Footer = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { ref, inView } = useInView();
  const { setFooterInView } = useApp();
  const { logBadgeClick } = usePoweredAlchemy();

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setFooterInView(inView);
  }, [inView]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <footer className="bg-wrapper dark" ref={ref}>
      <div id="footer" className="container wide">
        <div className="logo">
          <SVG svg="logo" />
        </div>
        <div className="linksWrapper">
          <section className="links">
            <h2 className="tag">Collections</h2>

            <ul>
              <li className="tag">
                <Link to="/collections/watches/">Watches</Link>
              </li>
              <li className="tag">
                <Link to="/collections/cars/">Cars</Link>
              </li>

              <li className="tag">
                <Link to="/products/">All Products</Link>
              </li>
            </ul>
          </section>

          <section className="links">
            <h2 className="tag">About</h2>

            <ul>
              <li className="tag">
                <Link to="/how-it-works/">How it Works</Link>
              </li>

              <li className="tag">
                <Link to="/terms-and-conditions/privacy/">Privacy</Link>
              </li>

              <li className="tag">
                <Link to="/terms-and-conditions/">Terms &amp; Conditions</Link>
              </li>

              <li className="tag">
                {/* todo: where shuld this go? */}
                <Link className="iubenda-cs-preferences-link" to="/">
                  Manage cookies
                </Link>
              </li>
            </ul>
          </section>

          <section className="links">
            <h2 className="tag">Links</h2>

            <ul>
              <li className="tag">
                <Link to={lucidaoWebsite}>Lucidao</Link>
              </li>

              <li className="tag">
                <Link to="https://www.certik.com/projects/altr">CertiK Audit</Link>
              </li>
            </ul>
          </section>
        </div>
        <ul className="socials">
          <li>
            <Link to="https://www.instagram.com/altr.trade/">
              <SVG svg="instagram" />
              <span className="visually-hidden">Instagram</span>
            </Link>
          </li>

          <li>
            <Link to="https://discord.com/invite/lucidao/">
              <SVG svg="discord" />
              <span className="visually-hidden">Discord</span>
            </Link>
          </li>

          <li>
            <Link to="https://lucidao.medium.com/">
              <SVG svg="medium" />
              <span className="visually-hidden">Medium</span>
            </Link>
          </li>

          <li>
            <Link to="https://x.com/intent/follow?screen_name=Lucidao_">
              <SVG svg="twitter" />
              <span className="visually-hidden">Twitter</span>
            </Link>
          </li>
        </ul>
        <div className="poweredBy">
          <div>
            <p className="b2">Powered by</p>
            <Link
              to="https://polygon.technology/"
              css={css`
                display: flex;
              `}
            >
              <SVG
                svg="polygon"
                css={css`
                  height: 37px;
                `}
              />
              <span className="visually-hidden">Polygon</span>
            </Link>
          </div>
          <button type="button" className="alchemy" onClick={logBadgeClick}>
            <img loading="lazy" id="badge-button" width={350} height={76} src="/images/alchemy-logo.webp" alt="Powered by Alchemy" />
          </button>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
