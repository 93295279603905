import React from "react";
import styled from "@emotion/styled";
import { SlicePadding, MediaRow } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  .mediaRows {
    &Row {
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

/** ============================================================================
 * @component
 */
const MediaRows = ({ data: { rows } }) => (
  <Container>
    <SlicePadding>
      {rows.map((row, i) => (
        <MediaRow className="mediaRowsRow" key={i} media={row.media} aspectRatio={row.aspectRatio} />
      ))}
    </SlicePadding>
  </Container>
);

export default MediaRows;
