import React from "react";
import cn from "classnames";
import { BLOCK_EXPLORER_ADDRESS, truncateAddress, walletAddressAreEqual } from "~utils/helpers";
import { useMarketplaceItemContext } from "../MarketplaceItemContext";
import useApp from "~hooks/useApp";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";
import SVG from "~components/Common/SVG";

const MarketplaceItemOrders = ({ sellOrder }) => {
  const { userData } = useApp();
  const { offers, selectedOffer, setSelectedOffer } = useMarketplaceItemContext();
  const { calculatePercentageDifference, getPriceWithMakerFee, getPriceWithTakerFee, makeDisplayPrice, getEtaFromExpiry } = useParseTraderOrder(
    sellOrder.order
  );

  const isWhole = !!sellOrder.order.erc721Token;
  const isOwner = walletAddressAreEqual(userData?.address, sellOrder.order.maker);

  return (
    <section className="offers tabContent">
      <h3>Buy Offers</h3>
      <div className="tableWrapper hiddenScroll">
        {(!offers || offers.length === 0) && <div>No offers have been made yet, be the first to make one!</div>}
        {offers && offers.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>PRICE</th>
                {!isWhole && <th>QUANTITY</th>}
                <th>FLOOR DIFFERENCE</th>
                <th>EXPIRATION</th>
                <th>FROM</th>
              </tr>
            </thead>
            <tbody>
              {offers.map((offer, i) => {
                if (!offer?.order) return null;
                const price = isOwner ? getPriceWithMakerFee(offer.order) : getPriceWithTakerFee(offer.order);
                const displayPrice = makeDisplayPrice(BigInt(price));
                const eta = getEtaFromExpiry(offer.order).toUpperCase();

                const from = walletAddressAreEqual(userData?.address, offer.order.maker) ? `YOU` : truncateAddress(offer.order.maker, 4, 6);

                const floorDifference = calculatePercentageDifference(offer.order, sellOrder.order);
                return (
                  <tr key={i} className={cn(i === selectedOffer.id && `selected`)} onClick={() => setSelectedOffer(i)}>
                    <td>{displayPrice} USDt</td>
                    {!isWhole && <td>{offer.order.erc1155Amount}</td>}
                    <td>{floorDifference.startsWith(`-`) ? `${floorDifference.slice(1)} BELOW` : `${floorDifference} ABOVE`}</td>
                    <td>{eta}</td>
                    <td>
                      <a href={`${BLOCK_EXPLORER_ADDRESS}${offer.order.maker}`} target="_blank" rel="noreferrer">
                        {from}
                        <SVG className="icon" svg="arrowUp" />
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </section>
  );
};

export default MarketplaceItemOrders;
