import React from "react";
import { NFTOverlayOfferWhole, NFTOverlayPurchaseWholeFromSellOrder } from "~components";
import useApp from "~hooks/useApp";
import { useMarketplaceItemContext } from "../../MarketplaceItemContext";

const MarketplaceItemActionsOverlays = ({ nft, sellOrder }) => {
  const { activeOverlay } = useApp();
  const { refetch } = useMarketplaceItemContext();

  switch (activeOverlay) {
    case `NFTOverlayOfferWhole`:
      return <NFTOverlayOfferWhole nft={nft} completionCallback={refetch} sellOrder={sellOrder} />;
    case `NFTOverlayPurchaseWholeFromSellOrder`:
      return <NFTOverlayPurchaseWholeFromSellOrder nft={nft} activeOrder={sellOrder} />;
    default:
      return null;
  }
};

export default MarketplaceItemActionsOverlays;
