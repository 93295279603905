export enum ProductPurchaseStatus {
  NoEmail = "NoEmail",
  CollectUserInterest = "CollectUserInterest",
  DoKyc = "DoKyc",
  WaitingForWhitelisting = "WaitingForWhitelisting",
  KycRejected = "KycRejected",
  WaitingForSaleStart = "WaitingForSaleStart",
  Unavailable = "Unavailable",
  OnSale = "OnSale",
  SoldOut = "SoldOut",
  OnFractionsSale = "OnFractionsSale",
  OnFractionsSaleSuccess = "OnFractionsSaleSuccess",
  OnFractionsSaleFailed = "OnFractionsSaleFailed",
  NFTBurned = "NFTBurned"
}
