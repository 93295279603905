import React from "react";
import styled from "@emotion/styled";
import { NFTItemCard, NFTUserData } from "~components";

/** ============================================================================
 * @css
 */

const Container = styled.article`
  width: 100%;
  height: 100%;
  position: relative;

  .nftOverlaySidebar {
    &Heading {
      margin-bottom: 2.5rem;
    }

    &UserData {
      width: 100%;
      height: 68px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlaySidebar = ({ sanityProduct }) => (
  <Container className="nftOverlaySidebar">
    <h2 className="nftOverlaySidebarHeading h2">Item</h2>

    <NFTItemCard image={sanityProduct?.image} oracle={sanityProduct?.oracle?.title} title={sanityProduct?.title} />

    <div className="nftOverlaySidebarUserData">
      <NFTUserData nft={null} />
    </div>
  </Container>
);

export default NFTOverlaySidebar;
