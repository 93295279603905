import { getBuiltGraphSDK } from "../../.graphclient";

export const {
  GetUserActivity,
  GetFractionsOwners,
  GetNftOwner,
  GetFractionsDataFromNft,
  Get0xTransactionHistory,
  Get0xTransactionsByNftId,
  GetAllTransactionHistory,
  GetBuyoutDataFromFragmentsId,
  GetBuyoutDataFromInitiator,
  GetBuyoutDataFromFragmentsIdAndInitiator,
  GetAllTransactionsByNftIdAndAddress,
  GetRaffleById,
  GetRaffleBoosts,
  GetAllRaffleBoosts,
  GetRaffleBuyers,
  // Price Index
  GetNftValuationHistory
} = getBuiltGraphSDK();
