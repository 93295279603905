import styled from "@emotion/styled";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { breakpoint } from "~utils/css";
import { Carousel, SVG, OracleInfoCard, Grid } from "~components";

const CarouselContent = styled.div`
  grid-column: 1 / -1;
  position: relative;
  overflow: hidden;
  margin-top: 20px;

  ${breakpoint(`large-tablet`)} {
    grid-column: span 8 / span 8;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: span 6 / span 6;
  }
`;

const CarouselFigure = styled.figure`
  width: 100%;
  height: 100%;

  ${breakpoint(`large-tablet`)} {
    padding: 120px 20px;
  }
`;
//

const CarouselArrow = styled.button`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ left }) => `${left ? `start` : `end`}`};
  padding: ${({ left }) => `${left ? `0 0 0 0.5rem` : `0 0.5rem 0 0`}`};
`;
const CarouselLeft = styled.div`
  display: none;

  ${breakpoint(`large-tablet`)} {
    transition: 0.3s ease opacity;

    opacity: ${({ visible }) => `${visible ? `1` : `0`}`};

    display: block;
    grid-column: span 1 / span 1;
    grid-column-start: 2;
    position: relative;
    z-index: 10;
    color: white;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column-start: 3;
  }
`;
const CarouselRight = styled.div`
  display: none;

  ${breakpoint(`large-tablet`)} {
    transition: 0.3s ease opacity;

    opacity: ${({ visible }) => `${visible ? `1` : `0`}`};

    display: block;
    grid-column: span 1 / span 1;
    position: relative;
    z-index: 10;
    color: white;
  }
`;

const OracleCardClusterCarousel = ({ oracles, categoryFilter }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: `start`,
      loop: false,
      slidesToScroll: 1
    },
    [Autoplay()]
  );

  const [carouselPage, setCarouselPage] = useState(0);

  // ---------------------------------------------------------------------------
  // methods

  const emblaPrev = () => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollPrev();
  };

  const emblaNext = () => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollNext();
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on(`select`, () => setCarouselPage(emblaApi.selectedScrollSnap()));
  }, [emblaApi]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Grid>
      <CarouselLeft visible={carouselPage > 0}>
        <CarouselArrow type="button" onClick={() => emblaPrev()} left>
          <SVG
            scg="chevronLeft"
            css={css`
              width: 14px;
              pointer-events: none;
            `}
          />
        </CarouselArrow>
      </CarouselLeft>
      <CarouselContent>
        <Carousel
          css={css`
            width: 100%;
            height: 600px;
            position: relative;

            ${breakpoint(`large-mobile`)} {
              height: 720px;
            }

            ${breakpoint(`small-tablet`)} {
              height: 550px;
            }

            ${breakpoint(`large-tablet`)} {
              height: 720px;
            }
          `}
          embla={{
            api: emblaApi,
            ref: emblaRef
          }}
          slidesPerView={1}
          gap={0}
          slides={() =>
            oracles
              ?.filter((oracle) => (categoryFilter ? oracle.category.find((category) => category === categoryFilter) : true))
              .map((oracle, i) => (
                <CarouselFigure>
                  <OracleInfoCard
                    key={i}
                    title={oracle.title}
                    specialty={oracle.specialty}
                    currentListing={oracle.currentListing}
                    image={oracle.image}
                    description={oracle.description}
                    slug={oracle.slug}
                    category={oracle.category}
                  />
                </CarouselFigure>
              ))
          }
        />
      </CarouselContent>
      <CarouselRight visible={carouselPage < oracles.length - 1}>
        <CarouselArrow type="button" onClick={() => emblaNext()}>
          <SVG
            svg="chevronRight"
            css={css`
              width: 14px;
              pointer-events: none;
            `}
          />
        </CarouselArrow>
      </CarouselRight>
    </Grid>
  );
};

export default OracleCardClusterCarousel;
