import React from "react";
import { ProfileTab, PgWrapper, PgItem } from "~components";

const ProfileTabPG = () => (
  <>
    <PgWrapper title="ProfileTab">
      <PgItem title="Light Connected">
        <div style={{ position: `relative`, padding: `1rem` }}>
          <div
            style={{
              position: `absolute`,
              inset: 0,
              background: `url(https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2664&q=80)`,
              backgroundSize: `cover`
            }}
          />
          <ProfileTab connected activePage="activity" />
        </div>
      </PgItem>
      <PgItem title="Light Disconnected">
        <div style={{ position: `relative`, padding: `1rem` }}>
          <div
            style={{
              position: `absolute`,
              inset: 0,
              background: `url(https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2664&q=80)`,
              backgroundSize: `cover`
            }}
          />
          <ProfileTab activePage="collection" />
        </div>
      </PgItem>
      <PgItem title="Dark Connected">
        <div style={{ position: `relative`, padding: `1rem` }}>
          <div
            style={{
              position: `absolute`,
              inset: 0,
              background: `url(https://images.unsplash.com/photo-1607236083122-583e96ea5aac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80)`,
              backgroundSize: `cover`
            }}
          />
          <ProfileTab colorTheme="dark" connected activePage="activity" />
        </div>
      </PgItem>
      <PgItem title="Dark Disconnected">
        <div style={{ position: `relative`, padding: `1rem` }}>
          <div
            style={{
              position: `absolute`,
              inset: 0,
              background: `url(https://images.unsplash.com/photo-1607236083122-583e96ea5aac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80)`,
              backgroundSize: `cover`
            }}
          />
          <ProfileTab colorTheme="dark" activePage="settings" />
        </div>
      </PgItem>
    </PgWrapper>
  </>
);

export default ProfileTabPG;
