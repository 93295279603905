import * as Sentry from "@sentry/core";
import { NFTDataVM, VoidPagedRequestVM } from "~dApp/models/ApiViewModel";
import {
  PurchaseTermsAndConditionsVM,
  FractionSaleTransactionVM,
  ProductDetailVMOfWatchProductVM,
  ProductDetailVMOfCarProductVM,
  ProductDetailVMOfBaseProductVM,
  GeneralOracleInfoVm,
  NftFeeManagerDataVM,
  EventModelDetailVM,
  TraderGenericOrder,
  MakerOrderOfTraderGenericOrder,
  MakerOrderOfTraderErc721Order,
  SubscribeToProductDropVM,
  UserDigitalizationRequestDetailVM,
  UserDigitalizationRequestsVM,
  SellOrderListVM,
  BrandVM,
  ConfigVM,
  TraderXYZOrder
} from "~dApp/models/ApiModel";
import LoginService from "~services/auth";
import { capitalize, thousandCommas } from "~utils/helpers";
import { PaginatedQueryParams } from "./models/IExternalIntegrations";

const BASE_URL = process.env.GATSBY_LUCID_DB_URL;

/** ============================================================================
 * AltrApiService
 * todo - document
 */
export default class AltrApiService {
  // ---------------------------------------------------------------------------
  // class variables

  //

  // ---------------------------------------------------------------------------
  // constructor

  constructor() {}

  // ---------------------------------------------------------------------------
  // methods

  async getBaseProductById(id: string) {
    let nft: ProductDetailVMOfBaseProductVM;
    try {
      const response = await fetch(`${BASE_URL}/api/BaseProduct/GetProductById?productIdentifier=${id}`, {
        method: `GET`,
        headers: { Accept: `application/json` }
      });

      nft = await response.json();
    } catch (e) {
      console.error(`Problem fetching base product by id '${id}': `, e);
    }

    return nft;
  }

  async getLucidProductById(id: string, type: string) {
    let nft: ProductDetailVMOfWatchProductVM | ProductDetailVMOfCarProductVM;

    try {
      switch (type?.toLowerCase().trim()) {
        case `watches`: {
          const response = await fetch(`${BASE_URL}/api/WatchProduct/GetById?identifier=${id}`, {
            method: `GET`,
            headers: { Accept: `application/json` }
          });

          nft = await response.json();

          break;
        }

        case `cars`: {
          const response = await fetch(`${BASE_URL}/api/CarProduct/GetById?identifier=${id}`, {
            method: `GET`,
            headers: { Accept: `application/json` }
          });

          nft = await response.json();

          break;
        }

        default:
          break;
      }
    } catch (e) {
      console.error(`Problem fetching NFT by id '${id}': `, e);
    }

    return nft;
  }

  async getLucidProductsByIds(ids: { id: string; type: string }[]) {
    const products = await Promise.all(
      ids.map(async ({ id, type }) => {
        return this.getLucidProductById(id, type);
      })
    );

    return products;
  }

  async getLucidEventById(id: string) {
    let event: EventModelDetailVM;

    try {
      const response = await fetch(`${BASE_URL}/api/EventModel/GetById?identifier=${id}`, {
        method: `GET`,
        headers: { Accept: `application/json` }
      });

      event = await response.json();
    } catch (e) {
      console.error(`Problem fetching Event by id '${id}': `, e);
    }

    return event;
  }

  async getOracleById(id: string) {
    let oracle: GeneralOracleInfoVm;

    try {
      const response = await fetch(`${BASE_URL}/api/Oracle/GetGeneralInfoById?identifier=${id}`, {
        method: `GET`,
        headers: { Accept: `application/json` }
      });

      oracle = await response.json();
    } catch (e) {
      console.error(`Problem fetching Oracle by id '${id}': `, e);
    }

    return oracle;
  }

  async getOracleList() {
    let oracles: GeneralOracleInfoVm[];

    try {
      const response = await fetch(`${BASE_URL}/api/Oracle/GetList`, {
        method: `GET`,
        headers: { Accept: `application/json` }
      });

      oracles = await response.json();
    } catch (e) {
      console.error(`Problem fetching oracles list: `, e);
    }

    return oracles;
  }

  async getBrandList() {
    let brands: BrandVM[];

    try {
      const response = await fetch(`${BASE_URL}/api/Brand/GetAll`, {
        method: `GET`,
        headers: { Accept: `application/json` }
      });

      brands = await response.json();
    } catch (e) {
      console.error(`Problem fetching brands list: `, e);
    }

    return brands;
  }

  async getProductExpertById(id: string) {
    let oracle: GeneralOracleInfoVm;

    try {
      const response = await fetch(`${BASE_URL}/api/ProductExpert/GetInfoById?identifier=${id}`, {
        method: `GET`,
        headers: { Accept: `application/json` }
      });

      oracle = await response.json();
    } catch (e) {
      console.error(`Problem fetching Product Expert by id '${id}': `, e);
    }

    return oracle;
  }

  async getPurchaseControlsStatus(product) {
    const loginService = new LoginService();
    const user = loginService.getUser();

    let status = null;

    if (!user || !product) {
      return status;
    }

    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();

    const productIdentifier = product.identifier;

    try {
      const response = await fetch(`${BASE_URL}/api/kyc/GetProductPurchaseStatusForCustomer?productIdentifier=${productIdentifier}`, {
        method: `GET`,
        headers: authorizationHeader
      });

      status = await response.text();
      //FIXME: manage cookie expiration date
    } catch (e) {
      const errorMsg = `Problem fetching PurchaseControlsStatus for product '${productIdentifier}': ${e}`;

      Sentry.captureMessage(errorMsg, `error`);
    }

    return status;
  }

  async enrichSanityProduct(sanityProduct) {
    if (!sanityProduct?.productGuid) {
      return sanityProduct;
    }

    const lucidData = (await this.getLucidProductById(sanityProduct?.productGuid, sanityProduct.collection.title.toLowerCase())) as any;

    if (!lucidData) {
      return sanityProduct;
    }

    if (lucidData?.product?.oracle?.identifier) {
      lucidData.oracle = await this.getOracleById(lucidData.product.oracle.identifier);
    }

    // removed:
    // sanity: {
    //   ...sanityProduct
    // }
    return lucidData;
  }

  async enrichSanityEvent(sanityEvent) {
    if (!sanityEvent?.eventGuid) {
      return sanityEvent;
    }

    const lucidData = await this.getLucidEventById(sanityEvent?.eventGuid);

    if (!lucidData) {
      return sanityEvent;
    }

    const oracleGuid = lucidData.oracles[0]?.identifier;

    if (!oracleGuid) {
      return sanityEvent;
    }
    const oracle = await this.getOracleById(oracleGuid);

    const rectifiedLucidData = {
      identifier: lucidData.identifier,
      title: lucidData.title.values["en-US"],
      description: lucidData.description.values["en-US"],
      oracle: {
        name: oracle.companyName,
        walletAddress: oracle.walletAddress
      },
      contractAddress: lucidData.oracles[0].contractAddresses[0]?.contractAddress,
      ids: lucidData.tickets.map((ticket) => {
        return {
          guid: ticket.identifier,
          id: ticket.erc1155TokenID,
          title: ticket.title.values["en-US"],
          description: ticket.description.values["en-US"],
          metadata: ticket.ticketAttributes,
          image: ticket.nftImage.publicUrl,
          price: ticket.price
        };
      }),
      purchaseTokens: lucidData.availableTokens,
      iban: lucidData.oracles[0].iBans[0]?.iban
    };

    return rectifiedLucidData;
  }

  getSanityProductByProductGUID(productGuid) {
    return null;
  }

  async getFullProductByProductGUID(productGuid, productType) {
    const lucidData = await this.getLucidProductById(productGuid, productType);

    if (!lucidData) {
      return null;
    }

    const sanityProduct = this.getSanityProductByProductGUID(productGuid);

    if (!sanityProduct) {
      return null;
    }

    return this.enrichSanityProduct(sanityProduct);
  }

  async buyNft(orderStatus) {
    let response = {};
    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    try {
      response = await fetch(`${BASE_URL}/api/Nft/BuyNft`, {
        method: `POST`,
        body: JSON.stringify(orderStatus),
        headers: { ...headers, ...authorizationHeader }
      });
    } catch (e) {
      console.error(`Problem buying nft: `, e);
    }

    return response;
  }

  async getProductsOnSale() {
    let products: ProductDetailVMOfBaseProductVM[];

    try {
      const response = await fetch(`${BASE_URL}/api/BaseProduct/GetProductOnSale`, {
        method: `GET`,
        body: JSON.stringify(new VoidPagedRequestVM()),
        headers: { Accept: `application/json`, "Content-Type": `application/json` }
      });

      products = await response.json();
    } catch (e) {
      console.error(`Problem fetching produtcs: `, e);
    }

    return products;
  }

  async getOrdersByCollectionAddressAndTokenId(collectionAddress: string, tokenId: string, makerAddress?: string) {
    let orders: MakerOrderOfTraderGenericOrder[];

    try {
      const response = await fetch(
        `${BASE_URL}/api/Order/GetTraderOrders?collectionAddress=${collectionAddress}&tokenId=${tokenId}${makerAddress ? `&makerAddress=${makerAddress}` : ``}`,
        {
          method: `GET`,
          headers: { Accept: `application/json` }
        }
      );
      orders = await response.json();
    } catch (e) {
      console.error(`Problem fetching Sell Offer by collection address '${collectionAddress}' and token id '${tokenId}': `, e);
    }

    return orders;
  }

  async getOrCreateKYCSession(user) {
    let sessionData = null;

    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    try {
      const response = await fetch(`${BASE_URL}/api/kyc/GetOrCreateKycSession`, {
        method: `Post`,
        headers: {
          ...headers,
          ...authorizationHeader
        }
      });

      sessionData = await response.json();
    } catch (e) {
      Sentry.captureMessage(`Problem fetching KYC session by address '${user.walletAddress}'. Error: ${e} `, `error`);
    }

    return sessionData;
  }

  getNftPrice(nftData, showCurrency = true, raw = false) {
    if (!nftData) {
      return ``;
    }

    return `${raw ? nftData.price.usdAmount : thousandCommas(nftData.price.usdAmount)}${showCurrency ? ` USDt` : ``}`;
  }

  async getFractionalizedOrderDetailsBySaleGuid(saleGuid) {
    let order = {};

    try {
      const response = await fetch(`${BASE_URL}/api/sale/GetFractionalizedOrderBaseDetail?orderIdentifier=${saleGuid}`, {
        method: `GET`,
        headers: { Accept: `application/json` }
      });

      order = await response.json();
    } catch (e) {
      console.error(`Problem fetching Fractionalized Order by Sale Guid '${saleGuid}': `, e);
    }

    return order;
  }

  async acceptPurchaseTermsAndConditions(nftIdentifier, vaultTermsAndConditions, nftTermsAndConditions, message, signedMessage, nonce) {
    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();
    const user = loginService.getUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };
    const data = new PurchaseTermsAndConditionsVM();

    data.nftIdentifier = nftIdentifier;
    data.vaultTermsAndConditions = vaultTermsAndConditions;
    data.nftTermsAndConditions = nftTermsAndConditions;
    data.message = message;
    data.signedMessage = signedMessage;
    data.nonce = nonce;

    try {
      const response = await fetch(`${BASE_URL}/api/sale/acceptPurchaseTermsAndConditions`, {
        method: `Post`,
        headers: {
          ...headers,
          ...authorizationHeader
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (e) {
      Sentry.captureMessage(
        `Problem in acceptPurchaseTermsAndConditions for address '${user.walletAddress}'. Data: '${JSON.stringify(data)}'. Error: ${e} `,
        `error`
      );
    }
  }

  async getFractionSaleTransaction(transactionIdentifier) {
    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();
    const user = loginService.getUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    let data = {};

    try {
      const response = await fetch(`${BASE_URL}/api/sale/getFractionSaleTransaction?transactionIdentifier=${transactionIdentifier}`, {
        method: `Get`,
        headers: {
          ...headers,
          ...authorizationHeader
        }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      data = await response.json(); // FractionSaleTransactionVM
    } catch (e) {
      Sentry.captureMessage(
        `Problem in getFractionSaleTransaction for address '${user.walletAddress}'. Data: '${transactionIdentifier}'. Error: ${e} `,
        `error`
      );
    }
    return data;
  }

  async saveFractionSaleTransaction(saleGuid, nftData, purchaseTxData, purchasePrice, purchasedFractions) {
    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();
    const user = loginService.getUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };
    let transactionGuid = ``;

    const data = new FractionSaleTransactionVM();
    data.nftIdentifier = nftData.identifier;
    data.saleIdentifier = saleGuid;
    data.transactionHash = purchaseTxData.transactionHash;
    data.from = purchaseTxData.from;
    data.to = purchaseTxData.to;
    data.totalFractions = nftData.saleData.fractionsAmount;
    data.fractionsPrice = nftData.saleData.fractionPrice;
    data.totalFractionsSold = nftData.saleData.fractionsSold;
    data.purchasedFractions = purchasedFractions;
    data.totalPurchasedAmount = purchasePrice;
    data.fractionSaleAddress = nftData.nftCollectionInfo.fractionSaleAddresses.fractionSaleContractAddress;

    try {
      const response = await fetch(`${BASE_URL}/api/sale/registerFractionSaleTransaction`, {
        method: `Post`,
        headers: {
          ...headers,
          ...authorizationHeader
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }
      transactionGuid = await response.json();
    } catch (e) {
      Sentry.captureMessage(
        `Problem in saveFractionSaleTransaction for address '${user.walletAddress}'. Data: '${JSON.stringify(data)}'. Error: ${e} `,
        `error`
      );
    }
    return transactionGuid;
  }

  async getNftFeeData(productIdentifier: string) {
    let feeData: NftFeeManagerDataVM;
    try {
      const respose = await fetch(`${BASE_URL}/api/Nft/GetNftFeeData?productIdentifier=${productIdentifier}`, {
        method: `GET`,
        headers: { "Content-Type": `application/json` }
      });
      feeData = await respose.json();
    } catch (e) {
      console.error(`Problem fetching nft fee data: `, e);
    }
    return feeData;
  }

  async sendEventSalePurchaseRequest(formValues: any) {
    let res: any;
    const body = JSON.stringify({
      eventIdentifier: formValues.eventIdentifier,
      fullName: formValues.name,
      email: formValues.email,
      taxId: formValues.id,
      phoneNumber: formValues.id,
      fiscalAddress: formValues.address,
      ticketId: formValues.ticketId
    });
    try {
      res = await fetch(`${BASE_URL}/api/TicketProduct/PurchaseRequest`, {
        method: `POST`,
        headers: { "Content-Type": `application/json` },
        body
      });
    } catch (e) {
      console.error(`Problem sending ticket purchase request: ${e}`);
    }

    return res;
  }

  async sendEventSalePurchaseData(data: any) {
    let res: any;
    const body = JSON.stringify({
      eventIdentifier: data.eventGuid,
      ticketId: data.id,
      email: data.email,
      transactionHash: data.transactionHash
    });

    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };
    try {
      res = await fetch(`${BASE_URL}/api/TicketProduct/Sold`, {
        method: `POST`,
        headers: {
          ...headers,
          ...authorizationHeader
        },
        body
      });
    } catch (e) {
      console.error(`Problem sending ticket purchase data: ${e}`);
    }
    return res;
  }

  async getAltrCollections() {
    let collections: string[];

    try {
      const response = await fetch(`${BASE_URL}/api/Config/GetAltrCollections`, {
        method: `GET`,
        headers: { Accept: `application/json` }
      });

      collections = await response.json();
    } catch (e) {
      console.error(`Problem fetching Altr collections`, e);
    }

    return collections;
  }

  async getErc721TraderOrder(nftData: NFTDataVM, makerAddress?: string) {
    let res: { orders: MakerOrderOfTraderErc721Order[] };

    try {
      const respose = await fetch(
        `${BASE_URL}/api/Order/GetTraderOrders?nftIdentifier=${nftData?.identifier}&nftType=erc721${makerAddress ? `&makerAddress=${makerAddress}` : ``}`,
        {
          method: `GET`,
          headers: { "Content-Type": `application/json` }
        }
      );
      res = await respose.json();
    } catch (e) {
      console.error(`Problem fetching erc721 trader order: `, e);
    }
    return res;
  }

  async getErc1155TraderOrder(nftData: NFTDataVM, makerAddress?: string) {
    let res: { orders: TraderXYZOrder[] }; //FIXME: should return {sellOrders: Order[], buyOrders: Order[]}

    try {
      const respose = await fetch(
        `${BASE_URL}/api/Order/GetTraderOrders?nftIdentifier=${nftData?.identifier}&nftType=erc1155${makerAddress ? `&makerAddress=${makerAddress}` : ``}`,
        {
          method: `GET`,
          headers: { "Content-Type": `application/json` }
        }
      );
      res = await respose.json();
    } catch (e) {
      console.error(`Problem fetching erc1155 trader order: `, e);
    }

    return res;
  }

  async getMyTraderOrders() {
    let res: { orders: TraderGenericOrder[] };

    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    try {
      const response = await fetch(`${BASE_URL}/api/Order/GetMyTraderXYZOrders`, { method: `GET`, headers: { ...headers, ...authorizationHeader } });
      res = await response.json();
    } catch (e) {
      console.error(`Problem fetching trader order: `, e);
    }

    return res;
  }

  async subscribeUserToProductDrop(data: SubscribeToProductDropVM) {
    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();

    const baseHeaders = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    const headers = authorizationHeader ? { ...authorizationHeader, ...baseHeaders } : { ...baseHeaders };
    try {
      const response = await fetch(`${BASE_URL}/api/Marketing/SubscribeUserToAProductDrop`, {
        method: `POST`,
        headers,
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error(`Error in subscribeToAProductDropPage. ${response.statusText} ${data}`);
      }

      return response;
    } catch (e) {
      // FIXME: improve error management
      Sentry.captureMessage(`Problem encountered while subscribing to the product drop page for the user '${data.email}'. Error: ${e} `, `error`);
    }
  }

  async getProductPurchaseStatus(productIdentifier: string) {
    let res: any;

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    try {
      const response = await fetch(`${BASE_URL}/api/BaseProduct/GetProductPurchaseStatus?productIdentifier=${productIdentifier}`, { method: `GET`, headers });
      res = await response.json();
    } catch (e) {
      console.error(`Problem fetching product purchase status: `, e);
    }

    return res;
  }

  async sendBuyAndClaimPurchaseRequest(formValues: any) {
    let res: any;
    const body = JSON.stringify({
      productIdentifier: formValues.productIdentifier,
      fullName: formValues.name,
      email: formValues.email,
      taxId: formValues.id,
      phoneNumber: formValues.id,
      fiscalAddress: formValues.address
    });
    try {
      res = await fetch(`${BASE_URL}/api/BaseProduct/BuyAndClaim`, {
        method: `POST`,
        headers: { "Content-Type": `application/json` },
        body
      });
    } catch (e) {
      console.error(`Problem sending ticket purchase request: ${e}`);
    }

    return res;
  }

  async getLightCertificationRequests() {
    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();
    const user = loginService.getUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    let data: UserDigitalizationRequestsVM[] = [];

    try {
      const response = await fetch(`${BASE_URL}/api/DigitalizationProduct/GetAllCertificationRequests`, {
        method: `Get`,
        headers: {
          ...headers,
          ...authorizationHeader
        }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      data = await response.json();
    } catch (e) {
      Sentry.captureMessage(`Problem in GetAllCertificationRequests for address '${user.walletAddress}'. Error: ${e} `, `error`);
    }
    return data;
  }

  async getLightCertificationRequestDetails(requestID: number) {
    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();
    const user = loginService.getUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    let data: UserDigitalizationRequestDetailVM;

    try {
      const response = await fetch(`${BASE_URL}/api/DigitalizationProduct/GetCertificationRequestDetail?requestID=${requestID}`, {
        method: `Get`,
        headers: {
          ...headers,
          ...authorizationHeader
        }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      data = await response.json();
    } catch (e) {
      Sentry.captureMessage(`Problem in GetCertificationRequestDetail for address '${user.walletAddress}'. Data: '${requestID}' Error: ${e} `, `error`);
    }
    return data;
  }

  async getLightCertificationRequestDetailsByGuid(guid: number) {
    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();
    const user = loginService.getUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    let data: UserDigitalizationRequestDetailVM;

    try {
      const response = await fetch(`${BASE_URL}/api/DigitalizationProduct/GetCertificationRequestDetailByGuid?requestIdentifier=${guid}`, {
        method: `Get`,
        headers: {
          ...headers,
          ...authorizationHeader
        }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      data = await response.json();
    } catch (e) {
      Sentry.captureMessage(`Problem in GetCertificationRequestDetailByGuid for address '${user.walletAddress}'. Data: '${guid}' Error: ${e} `, `error`);
    }
    return data;
  }

  async getFullCertificationRequests() {
    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();
    const user = loginService.getUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    let data: UserDigitalizationRequestsVM[] = [];

    try {
      const response = await fetch(`${BASE_URL}/api/FullDigitalizationProduct/GetMyRequestsList`, {
        method: `Get`,
        headers: {
          ...headers,
          ...authorizationHeader
        }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      data = await response.json();
    } catch (e) {
      Sentry.captureMessage(`Problem in GetMyRequestsList for address '${user.walletAddress}'. Error: ${e} `, `error`);
    }
    return data;
  }

  async getFullCertificationRequestDetails(requestID: number) {
    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();
    const user = loginService.getUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    let data: UserDigitalizationRequestDetailVM;

    try {
      const response = await fetch(`${BASE_URL}/api/FullDigitalizationProduct/GetMyRequestDetail?requestID=${requestID}`, {
        method: `Get`,
        headers: {
          ...headers,
          ...authorizationHeader
        }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      data = await response.json();
    } catch (e) {
      Sentry.captureMessage(`Problem in GetCertificationRequestDetail for address '${user.walletAddress}'. Data: '${requestID}' Error: ${e} `, `error`);
    }
    return data;
  }

  async createFullCertificationRequest(lightRequestIdentifier: string) {
    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();
    const user = loginService.getUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    const body = JSON.stringify({ lightRequestIdentifier });

    let data: string;

    try {
      const response = await fetch(`${BASE_URL}/api/FullDigitalizationProduct/CreateRequest`, {
        method: `POST`,
        headers: {
          ...headers,
          ...authorizationHeader
        },
        body
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }
    } catch (e) {
      Sentry.captureMessage(
        `Problem in FullDigitalizationProductCreateRequest for address '${user.walletAddress}'. Data: '${lightRequestIdentifier}' Error: ${e} `,
        `error`
      );
      throw e;
    }
  }

  async getRaffleReferralCode() {
    let res: string;

    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();
    const user = loginService.getUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    try {
      const response = await fetch(`${BASE_URL}/api/Customer/GetRefCode`, { method: `GET`, headers: { ...headers, ...authorizationHeader } });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      res = await response.json();
    } catch (e) {
      Sentry.captureMessage(`Problem in GetRaffleReferralCode for address '${user.walletAddress}'. Error: ${e} `, `error`);
    }

    return res;
  }

  async getUserLiquidityProvisioning() {
    let res: string;

    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();
    const user = loginService.getUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    try {
      const response = await fetch(`${BASE_URL}/api/Customer/GetLiquidityProvisioning`, { method: `GET`, headers: { ...headers, ...authorizationHeader } });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      res = await response.json();
    } catch (e) {
      Sentry.captureMessage(`Problem in GetLiquidityProvisioning for address '${user.walletAddress}'. Error: ${e} `, `error`);
    }

    return res;
  }

  async getLiquidityProvisioningByPublicAddress(address: string) {
    let res: string;

    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();
    const user = loginService.getUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    try {
      const response = await fetch(`${BASE_URL}/api/Customer/GetLiquidityProvisioningByPublicAddress?userPublicAddress=${address}`, {
        method: `GET`,
        headers: { ...headers, ...authorizationHeader }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      res = await response.json();
    } catch (e) {
      Sentry.captureMessage(`Problem in GetLiquidityProvisioningByPublicAddress for address '${user.walletAddress}'. Error: ${e} `, `error`);
    }

    return res;
  }

  async getAllCustomerReferralCode() {
    let res: string;

    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();
    const user = loginService.getUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    try {
      const response = await fetch(`${BASE_URL}/api/Customer/GetAllCustomerReferralCode`, { method: `GET`, headers: { ...headers, ...authorizationHeader } });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      res = await response.json();
    } catch (e) {
      Sentry.captureMessage(`Problem in GetAllCustomerReferralCode. Error: ${e} `, `error`);
    }

    return res;
  }

  async getMyAddressHashed() {
    let res: string;

    const loginService = new LoginService();
    const authorizationHeader = loginService.getAuthorizationHeaderForLoggedUser();
    const user = loginService.getUser();

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    try {
      const response = await fetch(`${BASE_URL}/api/Customer/GetMyAddressHashed`, { method: `GET`, headers: { ...headers, ...authorizationHeader } });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      res = await response.json();
    } catch (e) {
      console.error(e);
      Sentry.captureMessage(`Problem in GetMyAddressHashed for address '${user.walletAddress}'. Error: ${e} `, `error`);
    }

    return res;
  }

  async getSellOrders(params: PaginatedQueryParams) {
    let res: SellOrderListVM[];

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    const queryStringParams = Object.entries(params)
      .reduce((acc, [key, val]) => {
        let res = !val || typeof val === `undefined` ? `` : `${key}=${val}`;
        if (Array.isArray(val)) {
          if (val.includes(`all`)) {
            res = ``;
          } else {
            res = val.reduce((acc, val, i) => (!val.toString() || typeof val === `undefined` ? acc : `${acc}&${key}[${i}]=${val}`), ``).slice(1);
          }
        } else if (typeof val === `object`) {
          res = Object.entries(val)
            .reduce((acc, [nestedKey, val]) => `${acc}&${capitalize(key)}.${capitalize(nestedKey)}=${val}`, ``)
            .slice(1);
        }
        return res ? `${acc}&${res}` : acc;
      }, ``)
      .slice(1);

    try {
      const response = await fetch(`${BASE_URL}/api/BaseProduct/GetSellOrders?${queryStringParams}`, {
        method: `GET`,
        headers: { ...headers }
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      res = await response.json();
    } catch (e) {
      console.error(e);
      Sentry.captureMessage(`Problem in GetSellOrders. Error: ${e} `, `error`);
    }

    return res;
  }

  async getSystemConfig() {
    let res: ConfigVM;

    const headers = {
      "Content-Type": `application/json`,
      Accept: `application/json`
    };

    try {
      const response = await fetch(`${BASE_URL}/api/Config/GetSystemConfiguration`, { method: `GET`, headers: { ...headers } });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      res = await response.json();
    } catch (e) {
      console.error(e);
      Sentry.captureMessage(`Problem in GetSystemConfiguration. Error: ${e} `, `error`);
    }

    return res;
  }
}
