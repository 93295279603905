import React from "react";
import styled from "@emotion/styled";
import { SVG } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-white)` : `var(--color-black)`)};

  display: inline-block;
  user-select: none;

  .checkboxLabel {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .checkboxCheckIcon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  /* Hide the browser's default checkbox */
  .checkboxLabel input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkboxLabel input:focus-visible ~ .checkboxCheckIcon {
    box-shadow: 0 0 0 2px var(--color-black);
  }
`;

/** ============================================================================
 * @component
 * @param  {string} colorTheme - "light" or "dark"
 * @param  {boolean} checked - whether the checkbox is checked or not
 * @param  {function} setChecked - setter for checked state
 * @param  {string} label - the label for the checkbox
 * @param  {string} className - any additional classes to add to the component
 * @return {node} - the rendered component
 */

const Checkbox = ({ colorTheme = `light`, checked, setChecked, label, className }) => (
  <Container colorTheme={colorTheme} className={className || null}>
    <label className="checkboxLabel b2">
      <input type="checkbox" checked={checked} onChange={(e) => setChecked(e.target.checked)} />
      <SVG svg={checked ? `checkboxChecked` : `checkboxUnchecked`} className="checkboxCheckIcon" />
      {label}
    </label>
  </Container>
);

export default Checkbox;
