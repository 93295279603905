import React, { useEffect, useState } from "react";
import { useApp } from "~hooks";
import { Button, NFTOverlay, NFTTextEntry } from "~components";
import { BLOCK_EXPLORER } from "~utils/helpers";
import { usePublicClient } from "wagmi";
import { isAddress } from "viem";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError, parseError } from "~utils/error";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayTransferWhole = ({ nft }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const { setOverlayCompletionData, userData } = useApp();
  const { useTransferNft } = blockchainHooks();
  const publicClient = usePublicClient();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const initialFormData = {
    walletAddress: null
  };
  const [formData, setFormData] = useState(initialFormData);

  // ---------------------------------------------------------------------------
  // lifecycle

  const {
    writeAsync: doTransferNft,
    prepareError,
    isPrepareError,
    isSuccess,
    isLoading,
    txData
  } = useTransferNft(nft?.enrichedProduct?.nftData, formData?.walletAddress);

  useEffect(() => {
    if (isSuccess) {
      setFormData(initialFormData);
      setOverlayCompletionData({
        icon: `check`,
        heading: `Transfer completed`,
        transactionUrl: `${BLOCK_EXPLORER}${txData.transactionHash}`,
        purchaseSubheading: `You transferred the whole item`
      });
    }
  }, [isSuccess]);

  // ---------------------------------------------------------------------------
  // methods

  const onChange = (e) => {
    const { name, type, value, checked } = e.target;

    if (type === `checkbox`) {
      setFormData({
        ...formData,
        [name]: checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const transfer = async () => {
    if (!nft?.enrichedProduct?.nftData || isLoading) {
      return () => {};
    }

    // @externalintegration
    try {
      await doTransferNft();
    } catch (e) {
      handleError(e, setOverlayCompletionData, await publicClient.getBalance({ address: userData?.address }));
      console.error(e);
    }
  };

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  const isAddressValid = formData?.walletAddress && isAddress(formData.walletAddress);
  const valid = formData?.walletAddress && !isPrepareError && isAddressValid;

  return (
    <NFTOverlay id="NFTOverlayTransferWhole" heading="Transfer" nft={nft} sidebarMode="transfer">
      <NFTTextEntry name="walletAddress" onChange={onChange} heading="Enter the wallet address of the receiver:" placeholder="Enter wallet address" />
      <div className="nftEntry controls">
        {!!formData?.walletAddress && !isAddressValid && <p className="notice">Invalid address</p>}
        {!valid && !!isAddressValid && <p className="notice">You must fulfill all input fields before you can proceed.</p>}
        {!!formData?.walletAddress && isAddressValid && isPrepareError ? <p className="notice">{parseError(prepareError)}</p> : ``}

        <Button onClick={() => transfer()} fluid className={isLoading && `loading`} variant="primaryTall" colorTheme="dark" disabled={!valid}>
          Transfer
        </Button>
      </div>
    </NFTOverlay>
  );
};

export default NFTOverlayTransferWhole;
