import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useEmblaCarousel from "embla-carousel-react";
import { Carousel, Grid, Image, SVG } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  width: 100%;
  position: relative;
  display: block;
  overflow: hidden;
  padding: 2.5rem 0;
  background: ${({ background }) => background || `#ffffff`};
  color: ${({ color }) => color || `#ffffff`};

  ${breakpoint(`large-tablet`)} {
    padding: 3.75rem 0 4.25rem;
  }
`;

//

const CarouselContent = styled.div`
  grid-column: 1 / -1;
  position: relative;

  ${breakpoint(`large-tablet`)} {
    grid-column: span 8 / span 8;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: span 6 / span 6;
  }
`;
const CarouselFigure = styled.figure`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .gatsby-image-wrapper {
    max-width: 100%;
    max-height: 100%;
  }
`;

//

const CarouselArrow = styled.button`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ left }) => `${left ? `start` : `end`}`};
  padding: ${({ left }) => `${left ? `0 0 0 0.5rem` : `0 0.5rem 0 0`}`};
`;
const CarouselLeft = styled.div`
  display: none;

  ${breakpoint(`large-tablet`)} {
    transition: 0.3s ease opacity;

    opacity: ${({ visible }) => `${visible ? `1` : `0`}`};

    display: block;
    grid-column: span 1 / span 1;
    grid-column-start: 2;
    position: relative;
    z-index: 10;
    color: var(--color-black);
  }

  ${breakpoint(`large-desktop`)} {
    grid-column-start: 3;
  }
`;
const CarouselRight = styled.div`
  display: none;

  ${breakpoint(`large-tablet`)} {
    transition: 0.3s ease opacity;

    opacity: ${({ visible }) => `${visible ? `1` : `0`}`};

    display: block;
    grid-column: span 1 / span 1;
    position: relative;
    z-index: 10;
    color: var(--color-black);
  }
`;

//

const CarouselNav = styled.div`
  grid-column: 1 / -1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 1.75rem;
  overflow-x: scroll;
  padding-bottom: 1rem;

  ${breakpoint(`large-tablet`)} {
    grid-column: span 8 / span 8;
    grid-column-start: 3;
    justify-content: center;
    overflow-x: auto;
    padding-bottom: 0;
  }
`;
const CarouselNavButton = styled.button`
  width: 72px;
  height: 72px;
  margin: 0 4px;
  flex: 1 0 auto;

  ${breakpoint(`large-tablet`)} {
    width: 72px;
    height: 72px;
    flex: initial;
  }
`;
const CarouselNavFigure = styled.figure`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  border-top: 1px solid var(--color-${({ active }) => `${active ? `primary-orange-100` : `mono-20`}`});

  .gatsby-image-wrapper {
    max-width: 100%;
    max-height: 100%;
  }
`;

/** ============================================================================
 * @component
 */
const ImageCarousel = ({ data: { backgroundColour, fontColour, images } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: `start`,
    loop: false,
    slidesToScroll: 1
  });

  const [carouselPage, setCarouselPage] = useState(0);

  // ---------------------------------------------------------------------------
  // methods

  const emblaPrev = () => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollPrev();
  };

  const emblaNext = () => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollNext();
  };

  const emblaTo = (index) => {
    if (!emblaApi) {
      return;
    }

    emblaApi.scrollTo(index);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on(`select`, () => setCarouselPage(emblaApi.selectedScrollSnap()));
  }, [emblaApi]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container className="container" background={backgroundColour?.hex} color={fontColour?.hex}>
      <Grid>
        <CarouselLeft visible={carouselPage > 0}>
          <CarouselArrow type="button" onClick={() => emblaPrev()} left>
            <SVG
              svg="chevronLeft"
              css={css`
                width: 14px;
                pointer-events: none;
              `}
            />
          </CarouselArrow>
        </CarouselLeft>
        <CarouselContent>
          <Carousel
            css={css`
              width: 100%;
              height: 366px;
              position: relative;

              ${breakpoint(`large-tablet`)} {
                height: 720px;
              }
            `}
            embla={{
              api: emblaApi,
              ref: emblaRef
            }}
            slidesPerView={1}
            gap={0}
            slides={() =>
              images.map((image, imageIndex) => {
                const key = `carousel-nav-${imageIndex}`;

                return (
                  <CarouselFigure key={key}>
                    <Image contain image={image} />
                  </CarouselFigure>
                );
              })
            }
          />
        </CarouselContent>
        <CarouselRight visible={carouselPage < images.length - 1}>
          <CarouselArrow type="button" onClick={() => emblaNext()}>
            <SVG
              svg="chevronRight"
              css={css`
                width: 14px;
                pointer-events: none;
              `}
            />
          </CarouselArrow>
        </CarouselRight>
        <CarouselNav>
          {images.map((image, imageIndex) => {
            const key = `carousel-nav-${imageIndex}`;
            const active = carouselPage === imageIndex;

            return (
              <CarouselNavButton type="button" key={key} onClick={() => emblaTo(imageIndex)}>
                <CarouselNavFigure active={active}>
                  <Image
                    css={css`
                      object-fit: cover;
                    `}
                    image={image}
                  />
                </CarouselNavFigure>
              </CarouselNavButton>
            );
          })}
        </CarouselNav>
      </Grid>
    </Container>
  );
};

export default ImageCarousel;
