import { useContext } from "react";
import { AppContext } from "~context/AppContext.jsx";

export const useApp = () => {
  const app = useContext(AppContext);

  if (!app) {
    throw new Error(`Use useApp within AppContext`);
  }

  return app;
};

export default useApp;
