import { navigate } from "gatsby";
import { Button } from "~components";

const ProfileCollectionDigitise = () => (
  <div className="digitize">
    <div>
      <p>Want to add your collectible here?</p>
      <Button
        fluid
        iconRight="arrowRight"
        onClick={() => {
          navigate(`/profile/digitise`);
        }}
      >
        Digitise it
      </Button>
    </div>
  </div>
);

export default ProfileCollectionDigitise;
