import React from "react";
import styled from "@emotion/styled";
import cn from "classnames";
import { useWindowDimensions } from "~hooks";
import { SlicePadding } from "~components";
import InfoTabsDesktop from "./components/InfoTabsDesktop";
import InfoTabsMobile from "./components/InfoTabsMobile";

const DUMMY_DATA = [
  {
    tabNumber: 1,
    tabTitle: `Basic information`,
    content: []
  },
  {
    tabNumber: 2,
    tabTitle: `About this product`,
    content: []
  },
  {
    tabNumber: 3,
    tabTitle: `Certificate of Authenticity`,
    content: []
  }
];

/** ============================================================================
 * @css
 */
const Container = styled.section`
  background: var(--color-khaki-50);
`;

/** ============================================================================
 * @component
 */
const InfoTabs = ({ data, className }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  // const altrApiService = new AltrApiService();

  // ---------------------------------------------------------------------------
  // context / ref / state

  // const [lucidProduct, setLucidProduct] = useState();

  const { isMobile: mobile, isTablet: tablet } = useWindowDimensions();

  const isMobile = mobile || tablet;

  // ---------------------------------------------------------------------------
  // lifecycle

  // useEffect(() => {
  //   if (!product?.productGuid) {
  //     return;
  //   }

  //   const getData = async () => {
  //     const lucid = await altrApiService.getLucidProductById(
  //       product?.productGuid,
  //       product?.collection?.title?.toLowerCase()
  //     );

  //     setLucidProduct(lucid);
  //   };

  //   getData();
  // }, [product]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container className={cn(className)}>
      <SlicePadding>
        {isMobile && <InfoTabsMobile data={data || DUMMY_DATA} />}
        {!isMobile && <InfoTabsDesktop data={data || DUMMY_DATA} />}
      </SlicePadding>
    </Container>
  );
};

export default InfoTabs;
