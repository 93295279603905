export const raffleTicketPurchaseAbi = [
  {
    inputs: [
      {
        internalType: "address",
        name: "_purchaseToken",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "_ticketPrice",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "_startTimestamp",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "_finishTimestamp",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "_minTickets",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "_maxTickets",
        type: "uint256"
      },
      {
        internalType: "uint256",
        name: "_personalMaxTickets",
        type: "uint256"
      }
    ],
    stateMutability: "nonpayable",
    type: "constructor"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "target",
        type: "address"
      }
    ],
    name: "AddressEmptyCode",
    type: "error"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address"
      }
    ],
    name: "AddressInsufficientBalance",
    type: "error"
  },
  {
    inputs: [],
    name: "AskForRandomnessFirst",
    type: "error"
  },
  {
    inputs: [],
    name: "CannotAskForNewRandomness",
    type: "error"
  },
  {
    inputs: [],
    name: "FailedInnerCall",
    type: "error"
  },
  {
    inputs: [],
    name: "InvalidAmount",
    type: "error"
  },
  {
    inputs: [],
    name: "InvalidCaps",
    type: "error"
  },
  {
    inputs: [],
    name: "InvalidTicketAmount",
    type: "error"
  },
  {
    inputs: [],
    name: "InvalidTimestamps",
    type: "error"
  },
  {
    inputs: [],
    name: "InvalidTokenAddress",
    type: "error"
  },
  {
    inputs: [],
    name: "MaxCapReached",
    type: "error"
  },
  {
    inputs: [],
    name: "NoExcessPurchaseToken",
    type: "error"
  },
  {
    inputs: [],
    name: "OnlyWinnerCanCall",
    type: "error"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "owner",
        type: "address"
      }
    ],
    name: "OwnableInvalidOwner",
    type: "error"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "account",
        type: "address"
      }
    ],
    name: "OwnableUnauthorizedAccount",
    type: "error"
  },
  {
    inputs: [],
    name: "PersonalMaxCapReached",
    type: "error"
  },
  {
    inputs: [],
    name: "PrizeClaimPeriodElapsed",
    type: "error"
  },
  {
    inputs: [],
    name: "PrizeNotApproved",
    type: "error"
  },
  {
    inputs: [],
    name: "RaffleNotActive",
    type: "error"
  },
  {
    inputs: [],
    name: "RaffleNotFailed",
    type: "error"
  },
  {
    inputs: [],
    name: "RaffleNotSuccessful",
    type: "error"
  },
  {
    inputs: [],
    name: "RefundNotAvailable",
    type: "error"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "token",
        type: "address"
      }
    ],
    name: "SafeERC20FailedOperation",
    type: "error"
  },
  {
    inputs: [],
    name: "UnableToFundContract",
    type: "error"
  },
  {
    inputs: [],
    name: "VerificationTimeNotElapsed",
    type: "error"
  },
  {
    inputs: [],
    name: "WinnerNotSet",
    type: "error"
  },
  {
    inputs: [],
    name: "WithdrawAmountExceedsLimit",
    type: "error"
  },
  {
    inputs: [],
    name: "WrongInput",
    type: "error"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "tokenAddress",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      }
    ],
    name: "ExcessTokensWithdrawn",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      }
    ],
    name: "FundsWithdrawn",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address"
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address"
      }
    ],
    name: "OwnershipTransferred",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "purchaser",
        type: "address"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      }
    ],
    name: "RefundIssued",
    type: "event"
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "purchaser",
        type: "address"
      },
      {
        indexed: true,
        internalType: "string",
        name: "referralCode",
        type: "string"
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ticketAmount",
        type: "uint256"
      }
    ],
    name: "TicketPurchased",
    type: "event"
  },
  {
    inputs: [],
    name: "claimRefund",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "finishTimestamp",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "isSuccessful",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "maxTickets",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "minTickets",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "personalMaxTickets",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_ticketAmount",
        type: "uint256"
      },
      {
        internalType: "string",
        name: "_referralCode",
        type: "string"
      }
    ],
    name: "purchaseTickets",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "purchaseToken",
    outputs: [
      {
        internalType: "contract IERC20",
        name: "",
        type: "address"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "startTimestamp",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "ticketPrice",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address"
      }
    ],
    name: "ticketsPurchased",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "totalTicketsSold",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256"
      }
    ],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "newOwner",
        type: "address"
      }
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "tokenAddress",
        type: "address"
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256"
      }
    ],
    name: "withdrawExcessTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "withdrawFunds",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  }
] as const;
