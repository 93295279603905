import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { SVG } from "~components";
import { formatCryptoCurrency, formatStablecoin } from "~utils/helpers";
import useApp from "~hooks/useApp";
import { ticketContractHooks } from "~hooks/ticketContractHook";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  width: 100%;
  position: relative;
  gap: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: start;

  .nftUserData {
    &Icon {
      gap: 0 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        height: 1rem;
      }
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const EventUserData = ({ nft, className = `` }) => {
  const { useGetTokenBalance } = ticketContractHooks();

  const { data: tokenBalance } = useGetTokenBalance(nft.enrichedEvent?.purchaseTokens[0].contractAddress);

  const [displayCurrency, setDisplayCurrency] = useState(``);
  const [displayAmount, setDisplayAmount] = useState();

  const { activeOverlay } = useApp();

  useEffect(() => {
    setDisplayCurrency(nft.enrichedEvent?.purchaseTokens[0].text.toUpperCase());

    const formattedBalance = formatStablecoin(tokenBalance ?? 0);

    setDisplayAmount(formatCryptoCurrency(formattedBalance));
  }, [tokenBalance?.toString(), nft?.enrichedEvent?.purchaseTokens?.[0].text]);

  if (!displayAmount || displayAmount === `NaN`) {
    return null;
  }

  if (activeOverlay.includes(`Fiat`)) {
    return null;
  }

  return (
    <Container className={className}>
      <div className="nftUserDataIcon">
        <SVG svg="wallet" />
        <h4 className="b2">Your wallet balance</h4>
      </div>

      <div className="nftUserDataAmount">
        <h4 className="b1">
          {displayAmount} {displayCurrency}
        </h4>
      </div>
    </Container>
  );
};

export default EventUserData;
