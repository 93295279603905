import React from "react";
import { IconNote, PgWrapper, PgItem } from "~components";

const IconNotePG = () => (
  <PgWrapper title="IconNote" itemWidth="400px">
    <PgItem>
      <IconNote text="ALTR items are securely stored and insured in certified third-party Free Port facilities in Switzerland. For more information, please visit our website's Product Storage and Insurance section." />
    </PgItem>
  </PgWrapper>
);

export default IconNotePG;
