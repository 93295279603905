import React from "react";
import { formatDate, thousandCommas } from "~utils/helpers";
import MarketplaceItemValuationChart from "./MarketplaceItemValuationChart";

const MarketplaceItemValuation = ({ valuations }) => {
  const lastValuationMetrics = valuations.at(-1);
  return (
    <div className="valuation">
      <div className="valuationHeading">
        <div>
          <h2 className="valuationTitle">Price History</h2>
          <p className="valuationDatetime">Last update: {formatDate(lastValuationMetrics.valuationDatetime, `MMM DD, YYYY`)}</p>
        </div>
        <span className="valuationPrice">$ {thousandCommas(lastValuationMetrics.price)}</span>
      </div>

      <div className="valuationGraphWrapper">
        <MarketplaceItemValuationChart valuations={valuations} />
      </div>
    </div>
  );
};

export default MarketplaceItemValuation;
