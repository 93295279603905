import React, { useMemo, useState } from "react";
import cn from "classnames";
import { ProfileHistoryReceipt, LoadingSpinner } from "~components";
import { formatDateTime, formatCryptoCurrency, formatStablecoin, walletAddressAreEqual, FRACTIONS_SALE_ADDRESS } from "~utils/helpers";
import useApp from "~hooks/useApp";
import { useProfileData } from "~context/ProfileContext";
import ProfileCollectionFilters from "../ProfileCollection/ProfileCollectionFilters";
import Table from "~components/Common/Table";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";

const ITEMS_PER_PAGE = 10;

const ProfileHistory = ({ className }) => {
  const { userData } = useApp();
  const { transactions, isHistoryLoading: isLoading } = useProfileData();
  const [sorting, setSorting] = useState(`date_desc`);
  const [searchQuery, setSearchQuery] = useState(``);
  const [currentPage, setCurrentPage] = useState(0);

  const { getPriceWithFees } = useParseTraderOrder();

  const dropdowns = [
    {
      label: `SORT BY`,
      options: [
        { title: `Most Recent`, value: `date_desc` },
        { title: `Least Recent`, values: `date_asc` }
      ],
      onChange: setSorting,
      defaultValue: `date_desc`,
      value: sorting
    }
  ];

  const parseTransaction = (id, from, to, contractAddress, tokenId, timestamp, nftType, amount, price) => ({
    id,
    from,
    to,
    displayFrom: walletAddressAreEqual(userData?.address, from) ? `You` : from,
    displayTo: walletAddressAreEqual(userData?.address, to) ? `You` : to,
    contractAddress,
    tokenId,
    date: formatDateTime(parseInt(timestamp) * 1000, `DD-MM-YYYY HH:mm`),
    nftType,
    amount,
    price: price && `${formatCryptoCurrency(formatStablecoin(price, true))}`,
    type: price ? (walletAddressAreEqual(userData?.address, from) ? `Sale` : `Purchase`) : from === `Altr Sale Contract` ? `Purchase` : `Transfer`
  });

  const transactionsList = useMemo(() => {
    const saleAddresses = [FRACTIONS_SALE_ADDRESS]; // FIXME: add an endpoint to get all sale contract addresses

    return transactions
      ?.slice(0)
      .sort((a, b) => {
        const timeA = Number(a.timestamp);
        const timeB = Number(b.timestamp);

        if (sorting === `date_desc`) return timeB - timeA;

        return timeA - timeB;
      })
      .reduce((accum, curr) => {
        let from = curr?.order ? (curr.order.direction ? curr.order.maker.id : curr.order.taker.id) : curr.from.id;
        const to = curr?.order ? (!curr.order.direction ? curr.order.maker.id : curr.order.taker.id) : curr.to.id;

        if (saleAddresses.reduce((prev, current) => current?.toLowerCase() === from?.toLowerCase() || prev, false)) {
          from = `Altr Sale Contract`;
        }

        const parsedTransaction = parseTransaction(
          curr?.id,
          from,
          to,
          curr?.nftToken || curr?.erc721?.id.slice(0, 42) || curr?.erc1155?.[0]?.id.slice(0, 42),
          curr?.nftTokenId || curr?.erc721?.id.slice(42) || curr?.erc1155?.[0]?.id.slice(42),
          curr.timestamp,
          curr?.nftTokenType || (curr?.erc1155?.length ? `ERC1155` : `ERC721`),
          curr?.nftTokenAmount || curr?.erc1155Quantity?.[0] || 1,
          walletAddressAreEqual(userData?.address, to) ? getPriceWithFees(curr) : curr?.erc20TokenAmount
        );

        return [...accum, parsedTransaction];
      }, [])
      .filter((item) => {
        const values = Object.values(item);

        return values.some((value) => `${value}`.toLowerCase().includes(searchQuery.toLowerCase()));
      });
  }, [transactions, sorting, searchQuery, currentPage]);

  return (
    <section id="profileHistory" className={cn(`profileList`, className)}>
      {isLoading && <LoadingSpinner className="loader" isInline />}
      {!isLoading && transactions?.length > 0 && (
        <>
          <ProfileCollectionFilters
            setSearchQuery={setSearchQuery}
            pageNumber={Math.ceil(transactionsList.length / ITEMS_PER_PAGE)}
            displayedPages={5}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
            dropdowns={dropdowns}
          />
          <Table>
            {transactionsList.slice(currentPage * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE + ITEMS_PER_PAGE).map((transaction) => (
              <ProfileHistoryReceipt key={`profile-history-transaction-${transaction.id}`} transaction={transaction} />
            ))}
          </Table>
        </>
      )}
      {!isLoading && transactionsList?.length <= 0 && <p>No transactions to show</p>}
    </section>
  );
};

export default ProfileHistory;
