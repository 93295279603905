import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  width: 100%;
  position: relative;

  @keyframes bar-fill {
    0% {
      transform: scaleX(0);
    }
    100% {
      transform: scaleX(1);
    }
  }

  .nftFragmentGauge {
    width: 100%;
    position: relative;

    &Bar {
      width: 100%;
      height: 6px;
      position: relative;
      display: block;
      border-radius: 4px;
      overflow: hidden;
      background: 1px solid var(--color-beige);
      border: 1px solid var(--color-khaki-50);

      &Inner {
        animation: 1000ms bar-fill var(--cubic-easing) forwards;
        animation-delay: 250ms;

        transform: scaleX(0);
        transform-origin: 0 50%;

        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--color-khaki-50);
      }
    }

    &Heading {
      margin: 0 0 0.5rem;
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTFragmentGauge = ({ className = ``, actual, total }) => (
  <Container className={`nftFragmentGauge ${className}`}>
    <h4 className="h4 nftFragmentGaugeHeading">
      <span>{actual}</span>
      <span style={{ color: `var(--color-mono-40)` }}>/{total}</span>
    </h4>

    <div className="nftFragmentGaugeBar">
      <div className="nftFragmentGaugeBarInner" style={{ width: `${(actual / total) * 100}%` }} />
    </div>
  </Container>
);

export default NFTFragmentGauge;
