import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useInterval } from "~hooks";
import { Grid, HTMLVideo, Image, TextChanger } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  width: 100%;
  position: relative;
  display: block;
  padding: 2.5rem 0;
  background: ${({ background }) => background || `#ffffff`};
  color: ${({ color }) => color || `#ffffff`};

  ${breakpoint(`large-tablet`)} {
    padding: 3rem 0;
  }
`;

const TextContainer = styled.div`
  grid-column: 1 / -1;
  position: relative;
  margin-bottom: 1.5rem;

  ${breakpoint(`small-tablet`)} {
    grid-column: 1 / span 4;
  }

  ${breakpoint(`large-tablet`)} {
    grid-column: 1 / span 6;
    margin-bottom: 0;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: 2 / span 5;
  }
`;

const MediaContainer = styled.div`
  grid-column: 1 / -1;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 0.75rem;

  ${breakpoint(`small-tablet`)} {
    grid-column: 5 / span 4;
  }

  ${breakpoint(`large-tablet`)} {
    grid-column: 7 / span 6;
    border-radius: 1.5rem;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: 7 / span 5;
  }
`;

const Figure = styled.figure`
  width: 100%;
  height: 100%;
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  opacity: ${({ id, activeSlide }) => (id === activeSlide ? `1` : `0`)};
  transition: 1s var(--cubic-easing) opacity;

  overflow: hidden;

  video {
    border-radius: 0.75rem;

    ${breakpoint(`large-tablet`)} {
      border-radius: 1.5rem;
    }
  }
`;

/** ============================================================================
 * @component
 */
const AltrCarousel = ({ data: { backgroundColour, fontColour, headings, slides } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [activeSlide, setActiveSlide] = useState(slides?.[0]?._key);

  // ---------------------------------------------------------------------------
  // lifecycle

  useInterval(() => {
    const foundSlideIndex = slides.findIndex((slide) => slide._key === activeSlide);

    const nextSlideIndex = slides[foundSlideIndex + 1] ? slides[foundSlideIndex + 1]._key : slides[0]._key;

    setActiveSlide(nextSlideIndex);
  }, 3000);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container className="container" background={backgroundColour?.hex} color={fontColour?.hex}>
      <Grid
        css={css`
          align-items: center;
        `}
      >
        <TextContainer>
          <TextChanger headings={headings} />
        </TextContainer>

        <MediaContainer>
          {slides.map((slide) => (
            <Figure key={slide?._key} id={slide?._key} activeSlide={activeSlide}>
              {(slide?.videoURL && slide.videoURL !== `` && (
                <HTMLVideo
                  id={slide?.key}
                  css={css`
                    width: 100%;
                    height: 100%;
                    position: relative;
                    display: block;
                    object-fit: cover;
                  `}
                  autoplay
                  muted
                  loop
                  playsInline
                  src={slide.videoURL}
                />
              )) || (
                <Image
                  key={slide?.key}
                  css={css`
                    width: 100%;
                    height: 100%;
                    position: relative;
                    display: block;
                    object-fit: cover;
                  `}
                  image={slide.image}
                />
              )}
            </Figure>
          ))}
        </MediaContainer>
      </Grid>
    </Container>
  );
};

export default AltrCarousel;
