import React, { useState, useRef } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Image, SVG } from "~components";
import { breakpoint, hoverable } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  pointer-events: ${({ disabled }) => `${disabled ? `none` : `auto`}`};

  width: 100%;
  position: relative;
  display: block;

  ${hoverable} {
    transition: 0.3s var(--cubic-easing) color;

    ${({ disabled }) =>
    !disabled &&
    `
    &:hover {
      color: var(--color-primary-orange-100);

      svg {
        fill: var(--color-primary-orange-100);
      }
    }
  
    `}
  }
`;

const CardImage = styled.div`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  display: block;
  overflow: hidden;
`;

const CardImageFigure = styled.figure`
  width: ${({ aspect, figureAspect }) => `${figureAspect < 1 ? aspect * 100 : 100}%`};
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid black;

  transition: transform 0.3s var(--cubic-easing);

  &:hover {
    transform: scale(1.05);
  }

  ${({ disabled }) => disabled && `background: var(--color-mono-70);color:var(--color-black);`}
`;

const CardContent = styled.div`
  position: relative;
  padding: 1rem 1.5rem 2.25rem;

  ${breakpoint(`tablet`)} {
    padding: 1rem 1.5rem 2.25rem;
  }

  ${breakpoint(`large-tablet`)} {
    padding: 1rem;
  }
`;

const CardText = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

/** ============================================================================
 * @component
 */
const SquareCard = ({ data: { image, type, name, link, disabled } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const ref = useRef();

  // const { relativeCursorHandler, pos } = useRelativeCursor(ref);

  const [imageAspect] = useState(image?.asset?.gatsbyImage ? parseFloat(image.asset.gatsbyImage.width / image.asset.gatsbyImage.height).toFixed(2) : 1);

  // const imageUrl = getFallbackImageSrc(image);

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container ref={ref} disabled={disabled}>
      <CardImage>
        <CardImageFigure
          // onMouseMove={disabled ? () => {} : relativeCursorHandler}
          disabled={disabled}
          aspect={imageAspect}
        >
          {/* <RippleImage
            id={`square-card-ripple-${uuid()}`}
            imageUrl={imageUrl}
            pos={pos}
          /> */}
          {(image && (
            <Image
              image={image}
              css={css`
                height: 100%;
              `}
            />
          )) || (
              <SVG svg="icon" css={css`width: 20%;`} />
            )}
        </CardImageFigure>
      </CardImage>

      <CardContent>
        <h4 className="caption">{type}</h4>

        <h3
          css={css`
            margin: 0.5rem 0;
          `}
          className="h4"
        >
          {name}
        </h3>

        {!disabled && (
          <CardText>
            <p
              css={css`
                text-transform: uppercase;
                font-size: 18px;

                ${breakpoint(`tablet`)} {
                  font-size: 16px;
                }

                ${breakpoint(`large-tablet`)} {
                  font-size: 18px;
                }
              `}
              className="button-text"
            >
              {link.text}
            </p>

            <SVG svg="arrowRight" css={css`width: 1rem; height: 1rem; margin-left: 0.5rem;`} fill="white" />
          </CardText>
        )}
      </CardContent>
    </Container>
  );
};

export default SquareCard;
