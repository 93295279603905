import React, { useState } from "react";
import styled from "@emotion/styled";
import { useApp } from "~hooks";
import { PriceWidget, SVG } from "~components";
import { breakpoint } from "~utils/css";
import NFTPurchaseWholeActions from "./NFTPurchaseWholeActions";
import { formatDate } from "~utils/helpers";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  position: relative;
  padding: 1.25rem 1rem;

  ${breakpoint(`large-tablet`)} {
    padding: 1.5rem;
  }

  .thick {
    font-weight: bold;
  }

  .nftPurchaseWhole {
    animation: 0.5s var(--cubic-easing) appear forwards;
    animation-delay: 0.25s;

    opacity: 0;

    &Button {
      height: 56px;
      margin-bottom: 0.5rem;

      ${breakpoint(`large-tablet`)} {
        height: auto;
        margin-bottom: 0;
      }
    }

    &Expandable {
      transition: 0.5s var(--cubic-easing) max-height,
        0.5s var(--cubic-easing) margin;

      max-height: ${({ expanded }) => `${expanded ? `300px` : `0`}`};
      margin-top: ${({ expanded }) => `${expanded ? `0.75rem` : `0`}`};

      position: relative;
      overflow: hidden;

      ${breakpoint(`large-tablet`)} {
        max-height: none;
        overflow: auto;
        margin-top: 0;
      }
    }

    //

    &Flex {
      ${breakpoint(`large-tablet`)} {
        display: flex;
      }
    }

    &ColLeft {
      width: 100%;
      position: relative;

      &Expander {
        width: 2rem;
        height: 2rem;
        position: absolute;
        top: -0.25rem;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        overflow: hidden;
        z-index: 10;
        border: 1px solid var(--color-khaki-100);
        color: var(--color-khaki-100);

        svg {
          width: 1rem;
        }

        ${breakpoint(`large-tablet`)} {
          display: none;
        }
      }

      ${breakpoint(`large-tablet`)} {
        width: calc(60% - 1rem);
        margin-right: 1rem;
      }

      &Flex {
        ${breakpoint(`large-tablet`)} {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    &ColRight {
      width: 100%;
      position: relative;
      margin-top: 2.5rem;

      ${breakpoint(`large-tablet`)} {
        width: 40%;
        margin-top: 0;
      }

      &Flex {
        ${breakpoint(`large-tablet`)} {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      }
    }

    //
    p.connectWallet {
      margin: 0.5rem 0;
      display: block;
      width: 100%;

      span {
        margin-right: 0.5rem;
        font-style: italic;
        color: var(--color-mono-60);
      }
    }

    &Controls {
      display: block;
      margin-top: 2rem;
      gap: 0 0.5rem;

      ${breakpoint(`large-tablet`)} {
        margin-top: 1rem;
        display: flex;
        align-items: stretch;
      }

      & > div {
        width: 100%;

        ${breakpoint(`large-tablet`)} {
          flex-basis: 50%;
        }
      }

      button,
      button div {
        ${breakpoint(`large-tablet`)} {
          height: 100%;
        }
      }
    }

    &Subheading,
    &Body {
      color: var(--color-mono-60);
    }

    &Subheading {
      color: var(--color-mono-60);

      ${breakpoint(`large-tablet`)} {
        margin-top: 0.5rem;
      }
    }

    &Body {
      margin-top: 1rem;
    }
  }
`;

/** ============================================================================
 * @component
 * todo: animations
 * @return {node}
 */
const NFTPurchaseWhole = ({
  nftStatus,
  enrichedProduct,
  displayPrice,
  flex = false,
  //
  heading,
  subheading,
  dropDetails,
  body,
  background = `beige`
}) => {
  const { userIsLoggedIn } = useApp();

  const [expanded, setExpanded] = useState(false);

  const claimed = nftStatus?.is([`NFTBurned`]);
  const soldOut = nftStatus?.is(`SoldOut`);

  if (claimed) {
    flex = true;
    heading = `Sold out on ${formatDate(
      enrichedProduct?.nftData?.soldDate,
      `DD MMMM YYYY`
    )}`;
    subheading = `Claimed on ${formatDate(
      enrichedProduct?.nftData?.burnedOn,
      `DD MMMM YYYY`
    )}`;
    body = ``;
  } else if (soldOut) {
    flex = true;
    subheading = `Sold out on ${formatDate(
      enrichedProduct?.nftData?.soldDate,
      `DD MMMM YYYY`
    )}`;
    body = ``;
  }

  return (
    <Container
      connected={!!userIsLoggedIn}
      expanded={expanded}
      style={{
        background: `var(--color-${background})`
      }}
    >
      <div className="nftPurchaseWhole">
        <div className="nftPurchaseWholeFlex">
          <header
            className={`nftPurchaseWholeColLeft ${
              flex ? `nftPurchaseWholeColLeftFlex` : ``
            }`}
          >
            <button
              type="button"
              onClick={() => setExpanded(!expanded)}
              className="nftPurchaseWholeColLeftExpander"
            >
              <SVG svg={expanded ? `minus` : `plus`} />
            </button>

            {heading && <h3 className="b1">{heading}</h3>}

            <div className="nftPurchaseWholeExpandable">
              {dropDetails && (
                <h4 className="b2 nftPurchaseWholeSubheading thick">
                  {dropDetails}
                </h4>
              )}
              {subheading && (
                <h4 className="b2 nftPurchaseWholeSubheading">{subheading}</h4>
              )}

              {body && <p className="caption nftPurchaseWholeBody">{body}</p>}
            </div>
          </header>

          <div
            className={`nftPurchaseWholeColRight ${
              flex ? `nftPurchaseWholeColRightFlex` : ``
            }`}
          >
            {displayPrice && (
              <>
                <h4 className="nftPurchaseWholePriceSubheading caption">
                  {/* {preSale ? `ESTIMATED PRICE` : `PRICE`} */}
                  PRICE
                </h4>
                <PriceWidget displayPrice={displayPrice} fontClass="h2" />
              </>
            )}
          </div>
        </div>
        <NFTPurchaseWholeActions nftStatus={nftStatus} />
      </div>
    </Container>
  );
};

export default NFTPurchaseWhole;
