/* eslint-disable import/no-extraneous-dependencies */

import { css } from "@emotion/react";
import Synaps from "@synaps-io/react-verify";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @component
 */
const ProfileIdentityVerificationModal = ({ kycSession, userData, setKycDone }) => {
  if (!userData?.user || !kycSession?.sessionId) {
    return null;
  }

  return (
    <Synaps
      sessionId={kycSession.sessionId}
      service="individual"
      lang="en"
      onFinish={() => setKycDone()}
      css={css`
        width: 100%;
        min-width: 0 !important;

        ${breakpoint(`large-tablet`)} {
          width: auto;
          min-width: 400px !important;
        }
      `}
      color={{
        primary: `ff6108`,
        secondary: `1e1e1e`
      }}
    />
  );
};

export default ProfileIdentityVerificationModal;
