import TabsContext from "./TabsContext";
import TabsList from "./TabsList";
import TabContent from "./TabContent";
import Tab from "./Tab";

const Tabs = Object.assign(TabsContext, {
  TabsList,
  TabContent,
  Tab
});

export default Tabs;
