import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import styled from "@emotion/styled";
import { useApp, useWindowDimensions } from "~hooks";
import { Button, EventDoPurchase, NFTIconNote, EventOverlay, EventDoApproval, PriceWidget } from "~components";
import { breakpoint } from "~utils/css";
import { ticketContractHooks } from "~hooks/ticketContractHook";

/** ============================================================================
 * @css
 */

const Container = styled.article`
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};

  width: 100%;
  position: relative;

  .nftOverlayPurchase {
    position: relative;
    overflow: hidden;
    border-radius: 1rem;

    &Cancel {
      width: 100%;
      margin-top: 0.5rem;

      .buttonContainer {
        width: 100% !important;
        border: 3px solid red;
      }

      button {
        width: 100%;
        display: block;
      }
    }

    &Price,
    &Terms {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      padding: 1rem;
      ${breakpoint(`large-tablet`)} {
        padding: 3rem;
      }
    }

    &Price {
      background: white;
      backdrop-filter: blur(50px);
    }

    &Terms {
      flex-direction: column;
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(50px);

      ${breakpoint(`large-tablet`)} {
        flex-direction: row;
      }
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const EventOverlayPurchase = ({ nft, selected, ticketPrice, discountPrice, isDiscountEligible }) => {
  const { enrichedEvent, nftStatus, sanityEvent } = nft;
  const { setActiveOverlay, setOverlayProcessingData } = useApp();
  const { useGetTicketContractAllowance } = ticketContractHooks();

  const { isMobile } = useWindowDimensions();
  const { data: allowance, refetch } = useGetTicketContractAllowance(enrichedEvent.contractAddress, enrichedEvent.purchaseTokens[0].contractAddress);

  const [purchaseApproved, setPurchaseApproved] = useState(false);
  const [formData, setFormData] = useState({});
  const [price, setPrice] = useState(0n);
  useEffect(() => {
    if (allowance && allowance?.gte(price)) {
      setPurchaseApproved(true);
    } else {
      setPurchaseApproved(false);
    }
  }, [allowance?.toString(), price?.toString()]);

  useEffect(() => {
    if (isDiscountEligible && discountPrice) {
      setPrice(discountPrice);
    } else if (ticketPrice) {
      setPrice(ticketPrice);
    }
  }, [ticketPrice?.toString(), discountPrice?.toString(), isDiscountEligible]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <EventOverlay id="EventOverlayPurchase" heading="Purchase Ticket" nft={nft} sanityEvent={sanityEvent} selected={selected}>
      {({ active }) => (
        <Container active={active}>
          {active && (
            <>
              <div className="nftOverlayGroup nftOverlayPurchase">
                {enrichedEvent && (
                  <>
                    <div className="nftOverlayPurchasePrice">
                      <h2 className="nftOverlayPurchaseHeading b1">Ticket Total</h2>

                      <div>
                        <PriceWidget price={ticketPrice} isDiscountEligible={isDiscountEligible} discountPrice={discountPrice} fontClass="h2" />
                      </div>
                    </div>

                    <div className="nftOverlayPurchaseTerms">
                      {(!purchaseApproved && (
                        <EventDoApproval
                          nft={nft}
                          onStart={() =>
                            setOverlayProcessingData({
                              icon: `load`,
                              text: `Processing. Please do not close this page.`
                            })
                          }
                          onComplete={() => {
                            setOverlayProcessingData(null);
                          }}
                          formData={formData}
                          setFormData={setFormData}
                          ticketPrice={price}
                          refetch={refetch}
                        />
                      )) || (
                        <EventDoPurchase
                          nft={{ enrichedEvent, nftStatus }}
                          onStart={() =>
                            setOverlayProcessingData({
                              icon: `load`,
                              text: `Processing. Please do not close this page.`
                            })
                          }
                          onComplete={(eventIdentifier, ticketId) => {
                            setOverlayProcessingData({
                              icon: `done`,
                              text: `Processed. Please do not close this page.`
                            });

                            setActiveOverlay(null);
                            setOverlayProcessingData(null);

                            // todo: validation?
                            navigate(`/ticket-purchase-confirmation/${eventIdentifier}/?ticketId=${ticketId}`);
                          }}
                          onError={() => {
                            setOverlayProcessingData(null);
                          }}
                          formData={formData}
                          setFormData={setFormData}
                          selected={selected}
                        />
                      )}

                      {isMobile && (
                        <div className="nftOverlayPurchaseCancel">
                          <Button
                            colorTheme="dark"
                            fluid
                            variant="feature"
                            onClick={() => {
                              setActiveOverlay(null);
                            }}
                          >
                            <span className="b1">Cancel Offer</span>
                          </Button>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
              {!purchaseApproved && (
                <div className="nftOverlayGroup">
                  <NFTIconNote
                    background="rgba(255, 255, 255, 0.4)"
                    fontClass="caption"
                    svg="alert"
                    text="By clicking this button, you are authorizing ALTR's smart contract to initiate a blockchain transaction and process funds to the seller on your behalf."
                  />
                </div>
              )}
            </>
          )}
        </Container>
      )}
    </EventOverlay>
  );
};

export default EventOverlayPurchase;
