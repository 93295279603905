import React from "react";
import { css, Global } from "@emotion/react";
import { breakpoint } from "~utils/css.js";

const SANS_FALLBACKS = `"Helvetica Neue", "Helvetica", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`;
const SOHNE_BUCH_FAMILY = `"Sohne Buch", ${SANS_FALLBACKS}`;
const SOHNE_LEICHT_FAMILY = `"Sohne Leicht", ${SANS_FALLBACKS}`;
const SOHNE_MONO_FAMILY = `"Sohne Mono", ${SANS_FALLBACKS}`;

/** ============================================================================
 * @component
 * Typography files and settings.
 */
const Fonts = () => (
  <Global
    styles={css`
      // common //
      .d1,
      .h1,
      .h2,
      .h3 {
        font-family: ${SOHNE_LEICHT_FAMILY};
        font-weight: normal;
      }

      .h4,
      .b1,
      .b2,
      .button-text,
      .underline {
        font-family: ${SOHNE_BUCH_FAMILY};
        font-weight: normal;
      }

      .tag,
      .caption {
        font-family: ${SOHNE_MONO_FAMILY};
        font-weight: normal;
        text-transform: uppercase;
      }

      // display //
      .d1 {
        font-size: 4.25rem;
        line-height: 100%;
        letter-spacing: -0.02em;
      }

      // headings //
      .h1 {
        font-size: 2.625rem;
        line-height: 100%;
        letter-spacing: -0.01em;
      }

      .h2 {
        font-size: 2.125rem;
        line-height: 110%;
        letter-spacing: -0.01em;
      }

      .h3 {
        font-size: 1.75rem;
        line-height: 120%;
        letter-spacing: -0.01em;
      }

      .h4 {
        font-size: 1.375rem;
        line-height: 120%;
        letter-spacing: 0;
      }

      // body //
      .b1 {
        font-size: 1.125rem;
        line-height: 124%;
        letter-spacing: 0;
      }

      .b2 {
        font-size: 1rem;
        line-height: 124%;
        letter-spacing: 0;
      }

      // other //
      .button-text {
        font-size: 1rem;
        line-height: 124%;
        letter-spacing: 0.01em;
      }

      .underline {
        font-size: 1rem;
        line-height: 124%;
        letter-spacing: 0.01em;
        text-decoration: underline;
      }

      .tag {
        font-size: 1rem;
        line-height: 124%;
        letter-spacing: 0.01em;
      }

      .caption {
        font-size: 0.75rem;
        line-height: 140%;
        letter-spacing: 0.04em;
      }

      ${breakpoint(`small-tablet`)} {
        // display //
        .d1 {
          font-size: 5.25rem;
        }

        // headings //
        .h1 {
          font-size: 3.25rem;
        }

        .h2 {
          font-size: 2.375rem;
        }

        .h3 {
          font-size: 1.875rem;
        }
      }

      ${breakpoint(`small-desktop`)} {
        // display //
        .d1 {
          font-size: 6rem;
        }

        // headings //
        .h1 {
          font-size: 3.75rem;
        }

        .h2 {
          font-size: 2.625rem;
        }

        .h3 {
          font-size: 2rem;
        }

        .h4 {
          font-size: 1.5rem;
        }

        // body //
        .b1 {
          font-size: 1.25rem;
        }
      }
    `}
  />
);
export default Fonts;
