import React, { useState } from "react";
import { Scrambler, Button, PgWrapper, PgItem } from "~components";

const ScramblerPG = () => {
  const [key, setKey] = useState(0);

  return (
    <div key={key}>
      <PgWrapper title="Scrambler" itemWidth="300px">
        <PgItem>
          <Scrambler text="This is the text for this component" />
          <div style={{ marginTop: 32 }}>
            <Button buttonType="button" onClick={() => setKey((prev) => prev + 1)}>
              Rescramble
            </Button>
          </div>
        </PgItem>
      </PgWrapper>
    </div>
  );
};

export default ScramblerPG;
