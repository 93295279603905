import React, { useState, useMemo, useDeferredValue } from "react";
import styled from "@emotion/styled";
import { Grid, NFTListCard, TextInput } from "~components";
import { breakpoint } from "~utils/css.js";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import { useNftContext } from "~context/NftContext";

const itemsPerPage = 40;

/** ============================================================================
 * @css
 */
const Container = styled.section`
  animation: 0.5s var(--cubic-easing) appear-up forwards;

  opacity: 0;
  transform: translate3d(0, 1rem, 0);

  position: relative;
  min-height: 100svh;

  ${breakpoint(`large-tablet`)} {
    min-height: calc(100lvh + 200px);
  }

  .loader {
    width: 10%;
    min-width: 10rem;
    margin: 0 auto 2rem;
  }
`;

const Navigation = styled.nav`
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  display: flex;
  gap: 3.375rem;
  flex-wrap: wrap;
  color: #ffffff;
  margin: 0 auto 1rem;
  max-width: 1920px;

  p {
    font-size: 0.625rem;
  }

  ol {
    align-items: center;
    display: flex;
    gap: 0.25rem;
    font-size: 0.625rem;
  }

  ol h3 {
    font-size: 0.625rem;
    margin-right: 0.625rem;
  }

  li button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.625rem;
    border: 0.0625rem solid #919191;
    aspect-ratio: 1;
    max-width: 1.5rem;
  }

  li.current button {
    background: #000000;
    border-color: #000000;
  }

  ${breakpoint(`small-tablet`, `max`)} {
    gap: 2rem;
  }

  ${breakpoint(`tablet`)} {
    padding: 1rem 2rem;
  }

  ${breakpoint(`large-tablet`)} {
    padding: 1rem 3.5rem
  }

  .search {
    flex: 0 0 15.5rem;
    width: auto;

    ${breakpoint(`small-tablet`, `max`)} {
      flex: 1 0 100%;
    }

    div {
      margin-left: 0;
    }
  }

  .search input {
    color: #ffffff;
    font-family: "Sohne Mono";
    font-size: 0.625rem;
    background: transparent;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ffffff;
    padding:right: 2rem;

    &:hover,
    &:active,
    &:focus {
      border: 0;
      border-bottom: 1px solid #ffffff;
      box-shadow: unset;
    }

    &::placeholder {
      color: #ffffff;
      text-transform: uppercase;
    }
  }

  .search path {
    fill: #ffffff;
  }
`;

const Card = styled.li`
  grid-column: 1 / -1;
  position: relative;
  margin-bottom: 1.5rem;

  ${breakpoint(`large-tablet`)} {
    grid-column: span 4 / span 4;
    margin-bottom: 5rem;
  }

  ${breakpoint(`desktop`)} {
    grid-column: span 3 / span 3;
  }
`;

const NftList = ({ data: { oracleName } }) => {
  const data = useNftContext();

  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState(``);
  const defferedSearchQuery = useDeferredValue(searchQuery);

  const onSearchChange = (value) => {
    setSearchQuery(value || ``);
    setCurrentPage(0);
  };

  const onPageChange = (value) => () => {
    setCurrentPage(value);
  };

  const filteredInventory = useMemo(() => {
    if (!data?.nftList) return null;

    return data.nftList.filter((item) => defferedSearchQuery === `` || item.name.toLowerCase().indexOf(defferedSearchQuery.toLowerCase()) >= 0);
  }, [data?.nftList, defferedSearchQuery]);

  return (
    <Container>
      {!!data?.nftList?.length && (
        <Navigation>
          <p className="tag">{`LOTS: ${data?.totalCount} ITEMS`}</p>
          <TextInput className="search" placeholder="Search lot..." onChange={onSearchChange} hasSearchIcon isDarkTheme />
          {!!filteredInventory && (
            <ol>
              <h3 className="tag">{`PAGE: `}</h3>
              {Array(Math.ceil(filteredInventory.length / itemsPerPage))
                .fill(0)
                .map((_, index) => (
                  <li className={index === currentPage ? `current` : undefined} key={index}>
                    <button onClick={onPageChange(index)} type="button">
                      {index + 1}
                    </button>
                  </li>
                ))}
            </ol>
          )}
        </Navigation>
      )}
      {!!filteredInventory?.[0] && (
        <Grid node="ul">
          {filteredInventory.slice(itemsPerPage * currentPage, itemsPerPage * (currentPage + 1)).map((item) => (
            <Card key={item.id.tokenId}>
              <Link to={`/nfts/${item.contract.address}/${parseInt(item.id.tokenId)}`}>
                <NFTListCard item={item} oracle={`LOT ${item.lot}`} notButton />
              </Link>
            </Card>
          ))}
        </Grid>
      )}
      {!!data?.nftList?.length && (
        <Navigation
          css={css`
            margin-bottom: 0;
          `}
        >
          {!!filteredInventory && (
            <ol
              css={css`
                margin: 0 auto;
              `}
            >
              <h3 className="tag">{`PAGE: `}</h3>
              {Array(Math.ceil(filteredInventory.length / itemsPerPage))
                .fill(0)
                .map((_, index) => (
                  <li className={index === currentPage ? `current` : undefined} key={index}>
                    <button onClick={onPageChange(index)} type="button">
                      {index + 1}
                    </button>
                  </li>
                ))}
            </ol>
          )}
        </Navigation>
      )}
    </Container>
  );
};

export default NftList;
