import React from "react";
import { InfoRow, PgWrapper, PgItem } from "~components";

const infoRowPG = () => (
  <>
    <PgWrapper title="InfoRow">
      <PgItem>
        <InfoRow
          step="01"
          title="Select a collectible"
          description="Choose a product sourced from one of our approved Oracles and purchase it using USDt Stablecoin"
        />
      </PgItem>
    </PgWrapper>
  </>
);

export default infoRowPG;
