import React, { useEffect, useState } from "react";
import { useApp } from "~hooks";
import { Button, NFTNumberEntry, NFTOverlay, NFTTextEntry } from "~components";
import { BLOCK_EXPLORER } from "~utils/helpers";
import { usePublicClient } from "wagmi";
import { isAddress } from "viem";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError, parseError } from "~utils/error";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayTransferFragment = ({ nft }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const { setOverlayCompletionData, setOverlayProcessingData, userData } = useApp();
  const { useTransferFragments, useGetFractionsBalance } = blockchainHooks();
  const publicClient = usePublicClient();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [executing, setExecuting] = useState(false);
  const [formData, setFormData] = useState({
    fragments: null,
    walletAddress: null
  });

  // ---------------------------------------------------------------------------
  // lifecycle
  const {
    writeAsync: doTransferFragments,
    prepareError,
    isPrepareError,
    isSuccess,
    txData
  } = useTransferFragments(nft?.enrichedProduct?.nftData, formData?.walletAddress, formData?.fragments);

  const { data: fractionsBalance } = useGetFractionsBalance(nft?.enrichedProduct?.nftData, userData?.address);

  useEffect(() => {
    if (isSuccess) {
      setExecuting(false);
      setOverlayProcessingData(null);
      setOverlayCompletionData({
        icon: `check`,
        heading: `Transfer completed`,
        transactionUrl: `${BLOCK_EXPLORER}${txData.transactionHash}`,
        purchaseSubheading: `You sent ${formData?.fragments}/${nft?.enrichedProduct?.product?.numberOfFragments} Fragments`
      });
    }
  }, [isSuccess]);

  // ---------------------------------------------------------------------------
  // methods

  const onChange = (e) => {
    const { name, type, value, checked } = e.target;

    if (type === `checkbox`) {
      setFormData({
        ...formData,
        [name]: checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
  };

  const transfer = async () => {
    if (!nft?.enrichedProduct?.product || executing) {
      return () => {};
    }

    setExecuting(true);

    setOverlayProcessingData({
      icon: `load`,
      text: `Processing. Please do not close this page.`
    });

    try {
      await doTransferFragments();
    } catch (e) {
      handleError(e, setOverlayCompletionData, await publicClient.getBalance({ address: userData?.address }));
      console.error(e);
    }

    setExecuting(false);
    setOverlayProcessingData(null);

    return null;
  };

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  const isAddressValid = formData?.walletAddress && isAddress(formData.walletAddress);
  const valid = formData?.fragments && formData?.walletAddress && !isPrepareError && isAddressValid;

  return (
    <NFTOverlay id="NFTOverlayTransferFragment" heading="Transfer" nft={nft} sidebarMode="transfer">
      <NFTNumberEntry
        name="fragments"
        onChange={onChange}
        heading="1. How many Fragments would you like to send?"
        placeholder="Enter no. of Fragments"
        max={fractionsBalance ? parseInt(fractionsBalance.toString()) : 1}
      />

      <NFTTextEntry name="walletAddress" onChange={onChange} heading="2. Enter the wallet address of the receiver:" placeholder="Enter wallet address" />

      <div className="nftEntry controls">
        {!!formData?.walletAddress && !isAddressValid && <p className="notice">Invalid address</p>}
        {!valid && !!isAddressValid && <p className="notice">You must fulfill all input fields before you can proceed.</p>}

        <Button onClick={transfer} fluid variant="primaryTall" colorTheme="dark" disabled={!valid || isPrepareError}>
          <span className="b1">{isPrepareError ? parseError(prepareError, userData?.address, setOverlayCompletionData) : `Transfer`}</span>
        </Button>
      </div>
    </NFTOverlay>
  );
};

export default NFTOverlayTransferFragment;
