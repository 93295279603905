import React, { useState } from "react";
import { useApp } from "~hooks";
import { NFTIconNote, NFTCheckout, NFTOverlay } from "~components";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayRefund = ({ nft }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const { setOverlayCompletionData, setOverlayProcessingData } = useApp();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [executing, setExecuting] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  //
  // core executor

  const refund = async () => {
    if (!nft?.enrichedProduct?.product || executing) {
      return () => {};
    }

    setExecuting(true);

    setOverlayProcessingData({
      icon: `load`,
      text: `Processing. Please do not close this page.`
    });

    // @externalintegration
    // await crypto.refundFailedFractionsSale(nftData)

    // mock
    return new Promise((resolve) => {
      setTimeout(() => {
        setOverlayCompletionData({
          icon: `check`,
          heading: `Refund successful`
        });

        setOverlayProcessingData(null);

        setExecuting(false);

        resolve();
      }, 3000);
    });
  };

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  // @externalintegration
  // @altr: where does this data come from?
  // this is presumably user data for someone logged in viewing this page
  const DUMMY_REFUND_DATA = {
    fragments: 5,
    pricePerFragment: 1500
  };

  return (
    <NFTOverlay id="NFTOverlayRefund" heading="Refund" nft={nft} sidebarMode="fragmentRefund">
      <NFTCheckout
        className="nftOverlayGroup"
        heading="Your Refund"
        finalButtonText="Refund"
        totalText="Your Refund Amount"
        nft={nft}
        data={DUMMY_REFUND_DATA}
        execute={refund}
        preApproved
        hasTerms={false}
      />

      <NFTIconNote
        background="rgba(255, 255, 255, 0.4)"
        fontClass="caption"
        svg="info"
        text="The smartcontract will take back your NFTs and give the total refund back."
      />
    </NFTOverlay>
  );
};

export default NFTOverlayRefund;
