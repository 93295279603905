import React from "react";
import styled from "@emotion/styled";
import { Button, Link } from "~components";
import { useApp } from "~hooks";

const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 1.5rem;
  border-radius: 12px;

  .nftActionConnect {
    &Flex {
      display: flex;
      align-items: center;
    }

    &Dot {
      margin-left: 0.5rem;
    }
  }

  p {
    margin: 0.5rem 0 1.5rem;

    span {
      margin-right: 0.5rem;
      font-style: italic;
      color: var(--color-mono-60);
    }

    a {
      text-decoration: underline;
    }
  }

  // todo: button refactor
  button {
    background: var(--color-black);
    color: var(--color-white) !important;

    span {
      color: var(--color-white) !important;
    }

    svg {
      rect {
        stroke: white !important;
      }

      path {
        fill: white !important;
      }
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTActionConnect = () => {
  const { setConnectorActive } = useApp();

  return (
    <Container>
      <p className="connectWallet">
        <span>Connect your wallet to unlock platform features.</span>
        <Link to="https://support.metamask.io/hc/en-us/articles/360015489531-Getting-started-with-MetaMask">Learn more</Link>
      </p>

      <Button onClick={() => setConnectorActive(true)} fluid variant="feature" colorTheme="dark" iconLeft="wallet">
        <span className="b1">Sign In</span>
      </Button>
    </Container>
  );
};

export default NFTActionConnect;
