import React from "react";
import { Accordion, PgWrapper, PgItem } from "~components";

const data = {
  title: `Buy & Trade`,
  content: `Take ownership of a physical asset, while simultaneously and instantly trading it on the blockchain. All purchases produce a unique NFT. This is your trading token.`,
  buttons: [
    {
      _key: `70f2e994202c`,
      _type: `linkExternal`,
      url: `https://discord.com`,
      text: `Join Discord`,
      newTab: null
    }
  ]
};

const AccordionPG = () => (
  <>
    <PgWrapper title="Accordion" itemWidth="100%">
      <PgItem title="Live">
        <div style={{ position: `relative`, padding: `1rem` }}>
          <div
            style={{
              position: `absolute`,
              inset: 0,
              background: `url(https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2664&q=80)`,
              backgroundSize: `cover`
            }}
          />
          <Accordion {...data} status="live" />
        </div>
      </PgItem>
      <PgItem title="Launching soon">
        <div style={{ position: `relative`, padding: `1rem` }}>
          <div
            style={{
              position: `absolute`,
              inset: 0,
              background: `url(https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2664&q=80)`,
              backgroundSize: `cover`
            }}
          />
          <Accordion {...data} status="comingSoon" />
        </div>
      </PgItem>
      <PgItem title="No status">
        <div style={{ position: `relative`, padding: `1rem` }}>
          <div
            style={{
              position: `absolute`,
              inset: 0,
              background: `url(https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2664&q=80)`,
              backgroundSize: `cover`
            }}
          />
          <Accordion {...data} />
        </div>
      </PgItem>
    </PgWrapper>
  </>
);

export default AccordionPG;
