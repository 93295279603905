/* eslint-disable react/no-danger */
import React, { useState } from "react";
import styled from "@emotion/styled";
import { CategoryTab } from "~components";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  display: flex;

  .infoTabsDesktop {
    &TabContainer {
      width: 220px;
      flex-shrink: 0;
      margin-right: 2rem;

      > * {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &ContentContainer {
      flex-grow: 1;
      padding: 1rem 0;
      animation: 0.5s var(--cubic-easing) appear-down;

      img {
        width: 100%;
        max-width: 1000px;
      }

      p {
        white-space: pre-wrap;
      }
    }

    &Heading {
      margin-bottom: 80px;
    }

    &Partition {
      margin-bottom: 1.5rem;
      border-top: 1px solid var(--color-mono-40);
    }

    &InfoRow {
      &Heading {
        margin-bottom: 1.5rem;
        margin-top: 2.5rem;
      }

      &LabelText {
        display: flex;
        align-items: center;
        border-top: 1px solid var(--color-mono-40);
        padding: 8px 0;
      }

      &Label {
        color: var(--color-mono-60);
        width: 200px;
        margin-right: 1rem;
        flex-shrink: 0;
      }
    }

    &AboutContent {
      display: flex;
      gap: 160px;
    }
  }
`;

/** ============================================================================
 * @component
 */
const InfoTabsDesktop = ({ data }) => {
  const [activeTab, setActiveTab] = useState(data?.[0].tabNumber);

  const activeTabData = data.find((dataNode) => dataNode.tabNumber === activeTab);

  return (
    <Container>
      <div className="infoTabsDesktopTabContainer">
        {data.map((tab, i) => (
          <CategoryTab
            key={i}
            tabNumber={tab.tabNumber}
            tabTitle={tab.tabTitle}
            selected={activeTab === tab.tabNumber}
            onClick={() => setActiveTab(tab.tabNumber)}
          />
        ))}
      </div>

      <div className="infoTabsDesktopContentContainer" key={activeTab}>
        <article>
          <h3 className="h3 infoTabsDesktopHeading">{activeTabData.tabTitle}</h3>

          {activeTabData.content.map((contentNode, i) => (
            <div key={i}>
              {contentNode.type === `text` && (
                <>
                  <div className="infoTabsDesktopPartition" />

                  <div className="infoTabsDesktopAboutContent">
                    <h4 className="tag">{contentNode.subtitle}</h4>

                    <p className="b2">{contentNode.content}</p>
                  </div>
                </>
              )}

              {contentNode.type === `image` && <img src={contentNode.url} alt={activeTabData.tabTitle} />}
            </div>
          ))}
        </article>
      </div>
    </Container>
  );
};

export default InfoTabsDesktop;
