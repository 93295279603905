import React from "react";
import cn from "classnames";
import { CurrencyBadge } from "~components";
import { formatStablecoin, thousandCommas } from "~utils/helpers";

/** ============================================================================
 * PriceWidget
 * @component
 */
const PriceWidget = ({
  className = ``,
  price = 0n,
  displayPrice,
  discountPrice = 0n,
  isDiscountEligible = false,
  currency = `USDt`,
  information,
  fontClass = `h3`,
  color = `black`,
  currencyVariant = `primary`,
  digits = 0
}) => {
  const formattedPrice = price && thousandCommas(Number(price).toFixed(digits));
  const discountedPrice = isDiscountEligible && thousandCommas(Number(formatStablecoin(discountPrice)).toFixed(2));

  return (
    <div style={{ "--color": `var(--color-${color})` }} className={cn(`priceWidget`, className)}>
      <p className={fontClass}>
        {!!displayPrice && <span className="price">{displayPrice}</span>}
        {!displayPrice && !discountedPrice && <span className="price">{formattedPrice}</span>}
        {!displayPrice && !!discountedPrice && (
          <span className="price">
            <s>{formattedPrice}</s>
            {discountedPrice}
          </span>
        )}
        <CurrencyBadge color={color} text={currency} variant={currencyVariant} />
      </p>
      {!!information && <p className="info">{information}</p>}
    </div>
  );
};

export default PriceWidget;
