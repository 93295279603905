import useExternalIntegrations from "./useExternalIntegrations";
import { SubscribeToProductDropVM } from "~dApp/models/ApiModel";

const useMarketing = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state
  const { api } = useExternalIntegrations();

  // ---------------------------------------------------------------------------
  // methods

  /**
   * Subscribe to a source (drop page)
   * @function
   * @return
   */
  const subscribeToAProductDropPage = async (email, name, usdtCommitment, blockchainKnowledgeLevel, productIdentifier, receiveNewsletters) => {
    const data = new SubscribeToProductDropVM();
    data.email = email;
    data.name = name;
    data.usdtCommitment = usdtCommitment;
    data.productIdentifier = productIdentifier;
    data.receiveNewsletters = receiveNewsletters;
    data.blockchainKnowledgeLevel = blockchainKnowledgeLevel;
    return api.subscribeUserToProductDrop(data);
  };

  // ---------------------------------------------------------------------------
  // api

  return {
    subscribeToAProductDropPage
  };
};

export default useMarketing;
