import React, { useEffect, useMemo, useState } from "react";
import { useWindowDimensions } from "~hooks";
import { ProfileCollectionEmpty, ProfileCollectionGrid, LoadingSpinner } from "~components";
import { navigate } from "gatsby";
import ProfileCollectionFilters from "./ProfileCollectionFilters";
import { getTokenType } from "~utils/token";

/** ============================================================================
 * @component
 */
const ProfileCollection = ({ inventory, isLoading, sorting, setSorting }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const { isMobile } = useWindowDimensions();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [certification, setCertification] = useState(``);
  const [category, setCategory] = useState(``);
  const [inventoryView, setInventoryView] = useState(`grid`);
  const [searchQuery, setSearchQuery] = useState(``);

  const setActiveInventoryItem = (item) => {
    navigate(`/profile/collection/${item.contract.address}/${item.tokenId}`);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (isMobile && inventoryView !== `grid`) setInventoryView(`grid`);
  }, [isMobile, inventoryView]);

  const hasProductsInInventory = !!inventory?.[0];

  const filteredInventory = useMemo(() => {
    if (!hasProductsInInventory) return [];

    return inventory
      .filter((item) => item.name?.toLowerCase().includes(searchQuery.toLowerCase()))
      .filter((item) => {
        if (category === ``) return true;
        return getTokenType(item) === category;
      })
      .filter(
        (item) =>
          certification === `` || certification === item?.raw?.metadata?.certificatetype || (certification === `altr` && !item?.raw?.metadata?.certificatetype)
      );
  }, [searchQuery, hasProductsInInventory, inventory, certification, category]);

  const dropdowns = [
    {
      label: `Certification`,
      options: [
        { title: `All`, value: `` },
        { title: `Altr`, value: `altr` },
        { title: `Light`, value: `light` },
        { title: `Full`, value: `full` }
      ],
      onChange: setCertification,
      defaultValue: ``,
      value: certification
    },
    {
      label: `Category`,
      options: [
        { title: `All`, value: `` },
        { title: `Watches`, value: `watches` },
        { title: `Cars`, value: `cars` }
      ],
      onChange: setCategory,
      defaultValue: ``,
      value: category
    },
    {
      label: `SORT BY`,
      options: [
        { title: `Most Fragments`, value: `MOST FRAGMENTS` },
        { title: `Least Fragments`, values: `LEAST FRAGMENTS` },
        { title: `Most Recent Acquisition`, value: `MOST RECENT ACQUISITION` },
        { title: `Oldest Acquisition`, value: `OLDEST ACQUISITION` }
      ],
      onChange: setSorting,
      defaultValue: `MOST FRAGMENTS`,
      value: sorting
    }
  ];

  if (isLoading) return <LoadingSpinner />;

  if (inventory !== null && !hasProductsInInventory) return <ProfileCollectionEmpty />;

  return (
    <section id="profileCollection">
      <ProfileCollectionFilters setSearchQuery={setSearchQuery} dropdowns={dropdowns} />
      <ProfileCollectionGrid inventory={filteredInventory} onSelect={(item) => setActiveInventoryItem(item)} />
    </section>
  );
};

export default ProfileCollection;
