import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { breakpoint } from "~utils/css";
import { SVG } from "~components";
import { formatDateTime, thousandCommas } from "~utils/helpers";
import Button from "~components/Common/Button";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import useApp from "~hooks/useApp";

const statusToColorMap = {
  0: `#FFCD6C`,
  10: `#FFCD6C`,
  20: `#3DF988`,
  25: `#FF6108`,
  30: `#919191`,
  [-10]: `#FF6C6C`
};

export const MIME_TYPE_TO_EXTENSION_MAP = {
  "image/jpeg": `.jpg`,
  "image/png": `.png`,
  "image/gif": `.gif`,
  "image/bmp": `.bmp`,
  "image/svg+xml": `.svg`,
  "image/tiff": `.tiff`,
  "image/webp": `.webp`,
  "image/x-icon": `.ico`
};

const ModalWrapper = styled.div`
  font-family: "Sohne Leicht", sans-serif;

  h3.tag {
    color: #919191;
    font-size: 0.75rem;
    margin: 0.625rem 0;
  }

  time {
    color: #919191;
  }

  .statuses {
    background: #202020;
    border-top: 1px solid #ffffff;
    padding: 1.875rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1.4;
  }

  .properties {
    margin-bottom: 1.75rem;
    padding: 0 1.875rem;
  }

  .item-info {
    align-items: start;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem 3.75rem;
    margin-bottom: 0.625rem;
    padding: 0 1.875rem 1.875rem;
    position: relative;
    text-align: inherit;
    width: 100%;

    .title {
      flex: 1 0 16.5rem;
    }

    .status {
      min-width: 6.875rem;
    }

    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.5rem;
    }

    span.tag {
      font-size: 0.75rem;
      opacity: 0.5;
    }

    p {
      font-size: 1.25rem;
      vertical-align: middle;

      &.tag {
        font-size: 0.75rem;
      }
    }
  }

  .images {
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem;
  }

  .images img,
  .images a {
    flex-grow: 1;
    aspect-ratio: 1;
    align-items: center;
    background: #202020;
    display: flex;
    min-width: 10rem;
    max-width: 11rem;
    justify-content: center;
    border-radius: 1rem;
    object-fit: cover;
    object-position: center;
  }

  .images a svg {
    width: 25%;
  }

  .collectible-info {
    align-items: start;
    display: flex;
    flex-wrap: wrap;
    gap: 1.875rem 9.75rem;
    margin-bottom: 0.625rem;
    padding: 1.875rem;
    position: relative;
    text-align: inherit;
    width: 100%;

    ${breakpoint(`large-mobile`, `max`)} {
      padding-right: 3.625rem;
    }

    .title {
      flex: 1 0;
    }

    .status {
      min-width: 6.875rem;
    }

    .icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.5rem;
    }

    span.tag {
      font-size: 0.75rem;
      opacity: 0.5;
    }

    p {
      font-size: 1.25rem;
      vertical-align: middle;

      &.tag {
        font-size: 0.75rem;
      }
    }
  }

  li {
    margin: 0.5rem 0;
  }

  li * {
    vertical-align: middle;
  }

  li svg {
    width: 0.5rem;
    margin-right: 0.625rem;
  }

  .status-container {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
  }

  .status-cards {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 0.875rem;
  }

  .status-card {
    flex: 0 1 9rem;
  }

  .status {
    margin-bottom: 0.625rem;
  }

  .status span {
    height: 0.125rem;
    display: inline-block;
    max-width: calc(100% - 1.75rem);
    width: 100%;
    margin: 0;
    vertical-align: middle;
    background: #ffffff;
  }

  .status::before {
    content: "";
    position: relative;
    border-radius: 50%;
    display: inline-block;
    height: 0.75rem;
    margin-right: 0.375rem;
    vertical-align: middle;
    width: 0.75rem;
  }

  .status::after {
    content: "";
    border-top: 0.125rem solid white;
    border-right: 0.125rem solid white;
    width: 0.625rem;
    height: 0.625rem;
    display: inline-block;
    transform: rotate(45deg);
    vertical-align: middle;
    position: relative;
    right: 0.625rem;
  }

  .status-card:last-child .status span {
    display: none;
  }

  .status-card:last-child .status::after {
    border-top: 0;
    border-right: 0;
  }

  .estimate {
    padding: 2.5rem 1.875rem;
    border-top: 0.0625rem solid var(--color-white, #fff);

    h3 {
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.2;
    }

    h3 * {
      vertical-align: middle;
    }

    h3 svg {
      margin-right: 0.625rem;
    }

    data {
      font-size: 2rem;
      line-height: 1.4;
    }

    p {
      line-height: 1.4;
      color: var(--mono-0, #ffffff);
    }

    .price {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.25rem;
    }
  }
`;

const ProfileDigitisingModal = ({ request, setNftDetails, refresh }) => {
  const { api } = useExternalIntegrations();
  const { setOverlayCompletionData, setOverlayProcessingData, kycData } = useApp();

  const isApproved = request.lastStatus?.status === 20;
  const isLight = request.certificateType === `light`;

  const isKycd = kycData.kycStatus === `Whitelisted`;

  const requestFull = async () => {
    setOverlayProcessingData({
      icon: `load`,
      text: `Processing. Please do not close this page.`
    });
    try {
      await api.createFullCertificationRequest(request.requestIdentifier);
      refresh();
      setOverlayProcessingData(null);
      setOverlayCompletionData({
        icon: `check`,
        heading: `Full certificate requested`
      });
    } catch (e) {
      setOverlayProcessingData(null);
      setOverlayCompletionData({
        icon: `cross`,
        heading: `Error. Please try again.`
      });
    }
  };

  return (
    <ModalWrapper>
      <section className="item-info">
        <div className="title">
          <span className="tag">{request.productType}</span>
          <p>{`${request.brand || request.product?.brand?.text} ${request.productName || request.product?.model}`}</p>
        </div>
        <div>
          <span className="tag">Type</span>
          <p>{request.certificateType}</p>
        </div>
        <div>
          <span className="tag">Request id</span>
          <p>{`${request.requestID}`}</p>
        </div>
      </section>
      {isApproved && request.priceEstimate && (
        <>
          <section className="estimate">
            <div className="price">
              <h3>
                <SVG svg="altrLogo" />
                <span>Altr Expert</span>
              </h3>
              <data value={request.priceEstimate}>{thousandCommas(request.priceEstimate)} $</data>
            </div>
            <p>
              Your collectible has been evaluated by an Altr category expert, who has provided a provisional value. Please be aware that this value is not the
              amount you can use for collateral. To determine that, you&apos;ll need to proceed with full digitisation.
            </p>
          </section>
        </>
      )}
      <section className="statuses">
        <h3 className="tag">Status History</h3>
        <div className="status-container">
          <div className="status-cards">
            {request.statusHistory.map((status) => (
              <div className="status-card" key={status.status}>
                <p
                  className="status"
                  css={css`
                    &::before {
                      background-color: ${statusToColorMap[status.status]};
                    }
                  `}
                  aria-hidden
                >
                  <span />
                </p>
                <p>{status.description}</p>
                <time dateTime={status.createdOn}>{formatDateTime(status.createdOn, `DD-MM-YYYY HH:mm`)}</time>
              </div>
            ))}
          </div>
          {/* {isApproved && isLight && isKycd && (
            <Button
              onClick={requestFull}
              css={css`
                margin-right: 1rem;
              `}
            >
              Request Full Certificate
            </Button>
          )}
          {isApproved && isLight && !isKycd && (
            <Button
              css={css`
                margin-right: 1rem;
              `}
            >
              Perform Identity Verification to Request Full Certificate
            </Button>
          )} */}
          {isApproved && <Button onClick={() => setNftDetails(request.requestIdentifier)}>View NFT</Button>}
        </div>
      </section>
      {request.lastStatus?.status === -10 && (
        <section className="statuses">
          <h3 className="tag">Rejection Reason</h3>
          <p>{request.lastStatus.note}</p>
        </section>
      )}
      <section className="collectible-info">
        <div className="title">
          <span className="tag">Brand</span>
          <p>{request.brand || request.product?.brand?.text}</p>
        </div>
        <div>
          <span className="tag">Model / Reference / Year</span>
          <p>
            {request.model || request.product?.model} {request.reference || request.product?.serialNumber} {request.year || request.product?.year}
          </p>
        </div>
      </section>
      <section className="properties images">
        {request.productImages?.map((doc) =>
          MIME_TYPE_TO_EXTENSION_MAP[doc.mimeType] ? (
            <img key={doc.publicUrl} src={doc.publicUrl} alt="" />
          ) : (
            <a key={doc.publicUrl} href={doc.publicUrl} target="_blank" rel="noreferrer" download={doc.fileName}>
              <SVG svg="download" />
              <span className="visually-hidden">Download {doc.fileName}</span>
            </a>
          )
        ) || request.product?.images.map((img) => <img key={img.publicUrl} src={img.publicUrl} alt="" />)}
        {request.productDocuments?.map((doc) =>
          MIME_TYPE_TO_EXTENSION_MAP[doc.mimeType] ? (
            <img key={doc.gDriveUrl} src={doc.gDriveUrl} alt="" />
          ) : (
            <a key={doc.gDriveUrl} href={doc.gDriveUrl} target="_blank" rel="noreferrer" download={doc.fileName}>
              <SVG svg="download" />
              <span className="visually-hidden">Download {doc.fileName}</span>
            </a>
          )
        )}
      </section>
      {isLight && (
        <>
          <section className="properties">
            <h3 className="tag">Conditions</h3>
            <p>{request.conditions}</p>
          </section>
          {!!request.accessories && (
            <section className="properties">
              <h3 className="tag">Accessories</h3>
              <ul>
                {request.accessories.split(`,`).map((feature) => (
                  <li key={feature}>
                    <SVG svg="check" />
                    {feature.trim()}
                  </li>
                ))}
              </ul>
            </section>
          )}
          {!!request?.configuration && (
            <section className="properties">
              <h3 className="tag">Configuration</h3>
              <p>{request.configuration}</p>
            </section>
          )}
          {!!request?.location && (
            <section className="properties">
              <h3 className="tag">Current Location</h3>
              <p>{request.location}</p>
            </section>
          )}
        </>
      )}
      {!isLight && (
        <section className="properties">
          <h3 className="tag">Attributes</h3>
          <ul>
            {Object.entries(request.product?.attributes).map(([, val]) => {
              if (val.label === `Accessories` && !!val.value) {
                return (
                  <div key={val.label}>
                    <span className="tag">{val.label}:</span>
                    <ul>
                      {val.value?.split(`,`)?.map((feature) => (
                        <li key={feature} style={{ marginLeft: `5px` }}>
                          <SVG svg="check" />
                          {feature.trim()}
                        </li>
                      ))}
                    </ul>
                  </div>
                );
              }
              return (
                val.value && (
                  <li key={val.label}>
                    <span className="tag">{val.label}: </span>
                    <span>{val.value}</span>
                  </li>
                )
              );
            })}
          </ul>
        </section>
      )}
    </ModalWrapper>
  );
};

export default ProfileDigitisingModal;
