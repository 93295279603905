/* eslint-disable react/jsx-props-no-spreading */

import React from "react";

// Unique SVGs
import { ReactComponent as alert } from "~assets/svg/alert.svg";
import { ReactComponent as edit } from "~assets/svg/edit.svg";
import { ReactComponent as info } from "~assets/svg/info.svg";
import { ReactComponent as logo } from "~assets/svg/logo.svg";
import { ReactComponent as mail } from "~assets/svg/mail.svg";
import { ReactComponent as upload } from "~assets/svg/upload.svg";
import { ReactComponent as collectionViewCarousel } from "~assets/svg/collection-view-carousel.svg";
import { ReactComponent as collectionViewGrid } from "~assets/svg/collection-view-grid.svg";
import { ReactComponent as switcherIconGrid } from "~assets/svg/switcher-icon-grid.svg";
import { ReactComponent as switcherIconSlider } from "~assets/svg/switcher-icon-slider.svg";
import { ReactComponent as chevronDouble } from "~assets/svg/chevron-double.svg";
import { ReactComponent as selectArrow } from "~assets/svg/select-arrow.svg";
import { ReactComponent as certificateBadge } from "~assets/svg/certificate-badge.svg";
import { ReactComponent as lucidao } from "~assets/svg/lucidao.svg";

// Icon library
import { ReactComponent as arrowRight } from "~assets/svg/arrow-right.svg";
import { ReactComponent as arrowLeft } from "~assets/svg/arrow-left.svg";
import { ReactComponent as arrowBottom } from "~assets/svg/arrow-bottom.svg";
import { ReactComponent as arrowBack } from "~assets/svg/arrow-back.svg";
import { ReactComponent as arrowUp } from "~assets/svg/arrow-up.svg";
import { ReactComponent as chain } from "~assets/svg/chain.svg";
import { ReactComponent as check } from "~assets/svg/check.svg";
import { ReactComponent as checkboxChecked } from "~assets/svg/checkbox-checked.svg";
import { ReactComponent as checkboxUnchecked } from "~assets/svg/checkbox-unchecked.svg";
import { ReactComponent as chevronDown } from "~assets/svg/chevron-down.svg";
import { ReactComponent as cross } from "~assets/svg/cross.svg";
import { ReactComponent as download } from "~assets/svg/download.svg";
import { ReactComponent as facebook } from "~assets/svg/facebook.svg";
import { ReactComponent as instagram } from "~assets/svg/instagram.svg";
import { ReactComponent as linkedin } from "~assets/svg/linkedin.svg";
import { ReactComponent as lookingGlass } from "~assets/svg/looking-glass.svg";
import { ReactComponent as minus } from "~assets/svg/minus.svg";
import { ReactComponent as play } from "~assets/svg/play.svg";
import { ReactComponent as playSolid } from "~assets/svg/play-solid.svg";
import { ReactComponent as plus } from "~assets/svg/plus.svg";
import { ReactComponent as repeat } from "~assets/svg/repeat.svg";
import { ReactComponent as shield } from "~assets/svg/shield.svg";
import { ReactComponent as twitter } from "~assets/svg/twitter.svg";
import { ReactComponent as user } from "~assets/svg/user.svg";
import { ReactComponent as wallet } from "~assets/svg/wallet-icon.svg";
import { ReactComponent as warningTriangle } from "~assets/svg/warning-triangle.svg";
import { ReactComponent as copy } from "~assets/svg/copy.svg";
import { ReactComponent as discord } from "~assets/svg/discord.svg";
import { ReactComponent as medium } from "~assets/svg/medium.svg";
import { ReactComponent as icon } from "~assets/svg/icon.svg";
import { ReactComponent as polygon } from "~assets/svg/polygon.svg";
import { ReactComponent as anchorIcon } from "~assets/svg/anchor.svg";
import { ReactComponent as paperclip } from "~assets/svg/paperclip.svg";
import { ReactComponent as database } from "~assets/svg/database.svg";
import { ReactComponent as xCircle } from "~assets/svg/x-circle.svg";
import { ReactComponent as arrowAltRight } from "~assets/svg/arrow-alt-right.svg";
import { ReactComponent as altrLogo } from "~assets/svg/logos/altr.svg";
import { ReactComponent as filtersIcon } from "~assets/svg/filters.svg";
import { ReactComponent as closeIcon } from "~assets/svg/close.svg";

const svgs = {
  // Unique
  alert,
  edit,
  info,
  logo,
  mail,
  upload,
  collectionViewCarousel,
  collectionViewGrid,
  switcherIconGrid,
  switcherIconSlider,
  chevronDouble,
  selectArrow,
  certificateBadge,
  altrLogo,

  // Library
  arrowRight,
  arrowBottom,
  arrowBack,
  arrowLeft,
  arrowUp,
  arrowAltRight,
  chain,
  check,
  checkboxChecked,
  checkboxUnchecked,
  chevronDown,
  cross,
  download,
  facebook,
  instagram,
  linkedin,
  lookingGlass,
  minus,
  play,
  playSolid,
  plus,
  repeat,
  shield,
  twitter,
  user,
  wallet,
  warningTriangle,
  copy,
  discord,
  medium,
  icon,
  polygon,
  anchorIcon,
  paperclip,
  database,
  xCircle,
  filtersIcon,
  closeIcon,
  lucidao
  // ... all icons as required
};

/** ============================================================================
 * @component
 * @param {string}     svg     Name of desired SVG, must be imported first in this file
 * @return {node}
 */

const SVG = ({ svg, ...props }) => {
  if (!svgs[svg]) return null;

  const SVGElement = svgs[svg];

  return <SVGElement {...props} aria-hidden />;
};

export default SVG;
