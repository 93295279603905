import React, { useLayoutEffect, useState } from "react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import cn from "classnames";
import { breakpoint } from "~utils/css";
import { Accordion, Grid } from "~components";
import TextFocussed from "./components/TextFocussed";
import Countdown from "./components/Countdown";
import CenterContent from "./components/CenterContent";
import useWindowDimensions from "~hooks/useWindowDimensions";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  position: relative;
  height: 100svh;

  .homepageAtfBackground {
    &Desktop {
      display: none;

      ${breakpoint(`large-tablet`)} {
        display: block;
      }
    }

    &Mobile {
      display: block;

      ${breakpoint(`large-tablet`)} {
        display: none;
      }
    }

    &Video {
      position: absolute;
      inset: 0;

      &::after {
        content: " ";
        position: absolute;
        inset: 0;
        backdrop-filter: blur(64px);
        background: rgba(0, 0, 0, 0.25);
        display: block;
        opacity: ${({ blur }) => (blur ? `1` : `0`)};
        transition: 0.4s opacity;

        ${breakpoint(`large-tablet`)} {
          content: unset;
        }
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .container {
    height: 100%;
  }

  .container h2 {
    opacity: 1;
    transition: 0.2s opacity;
  }

  .container.openedAccordion h2 {
    opacity: 0;

    ${breakpoint(`large-tablet`)} {
      opacity: 1;
    }
  }
`;

const ContrastOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 40%);
`;

const Content = styled.div`
  position: relative;
  height: 100%;
  color: ${({ textColor }) => (textColor === `light` ? `var(--color-white)` : `var(--color-black)`)};

  .container {
    align-items: flex-end;
    display: flex;
  }

  .accordions {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 0.75rem;
    grid-column: 1 / -1;
    justify-content: flex-end;
    margin-top: 1.5rem;
  }

  .accordion {
    border: 0.0625rem solid var(--color-mono-40);
    opacity: 1;
    transition: 0.2s opacity;
  }

  .accordionDisabled {
    opacity: 0;

    ${breakpoint(`large-tablet`)} {
      opacity: 1;
    }
  }

  .accordion .buttonContainer {
    display: inline-flex;
    width: auto;
  }

  .accordion p.content {
    color: var(--color-white);
  }

  .homepageAtfTextCurrencyTicker {
    align-self: flex-end;
  }

  ${breakpoint(`large-tablet`)} {
    .accordions {
      grid-column: 7 / -1;
    }
  }

  ${breakpoint(`desktop`)} {
    .accordions {
      grid-column: 8 / -1;
    }
  }
`;

/** ============================================================================
 * @component
 */
const HomepageAtf = ({
  data: {
    variant,
    textPosition,
    textColor,
    textFeaturedHeadline,
    tagline,
    product,
    cta,
    accordions,
    backgroundContent,
    backgroundImage,
    backgroundImageMobile,
    backgroundVideo
  }
}) => {
  const { screen } = useWindowDimensions();

  const [openedAccordion, setOpenedAccordion] = useState(null);

  useLayoutEffect(() => {
    if ([`xs`, `sm`].includes(screen)) {
      setOpenedAccordion(null);
    } else if (openedAccordion === null) {
      setOpenedAccordion(accordions?.find((accordion) => accordion.openByDefault)?._key);
    }
  }, [screen, accordions]);

  const handleAccordionToggle = (key) => {
    if (openedAccordion === key) setOpenedAccordion(null);
    else setOpenedAccordion(key);
  };

  return (
    <Container blur={!!openedAccordion}>
      {backgroundContent === `image` && (
        <>
          <GatsbyImage
            style={{ position: `absolute`, inset: 0 }}
            alt=""
            image={backgroundImage.asset.gatsbyImageData}
            className={cn({
              [`homepageAtfBackgroundDesktop`]: backgroundImageMobile
            })}
          />
          {backgroundImageMobile && (
            <GatsbyImage
              style={{ position: `absolute`, inset: 0 }}
              alt=""
              image={backgroundImageMobile.asset.gatsbyImageData}
              className="homepageAtfBackgroundMobile"
            />
          )}
        </>
      )}

      {backgroundContent === `video` && (
        <div className="homepageAtfBackgroundVideo">
          <video autoPlay loop muted playsInline src={backgroundVideo.asset.url} />
        </div>
      )}

      {textColor === `light` && <ContrastOverlay />}
      <Content textColor={textColor}>
        <div className={cn(`container dark wide`, openedAccordion && `openedAccordion`)}>
          <Grid>
            {variant === `text` && (
              <TextFocussed textFeaturedHeadline={textFeaturedHeadline} tagline={tagline} textPosition={textPosition} textColor={textColor} cta={cta} />
            )}
            {variant === `center` && <CenterContent textFeaturedHeadline={textFeaturedHeadline} tagline={tagline} textColor={textColor} />}
            {variant === `countdown` && <Countdown product={product} textColor={textColor} />}
            <div className="accordions">
              {accordions?.map((accordion) => (
                <Accordion
                  key={accordion._key}
                  title={accordion.title}
                  content={accordion.content}
                  status={accordion.status}
                  links={accordion.links}
                  open={accordion._key === openedAccordion}
                  onAccordionToggle={() => handleAccordionToggle(accordion._key)}
                  className={cn(`accordion`, openedAccordion && accordion._key !== openedAccordion && `accordionDisabled`)}
                  dark
                />
              ))}
            </div>
          </Grid>
        </div>
      </Content>
    </Container>
  );
};

export default HomepageAtf;
