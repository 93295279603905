import React from "react";
import styled from "@emotion/styled";
import { breakpoint } from "~utils/css";
import { TextInput } from "~components";

const Container = styled.div`
.search {
  flex: 0 0 11.5rem;
  width: auto;

  ${breakpoint(`small-tablet`, `max`)} {
    flex: 1 0 100%;
  }

  div {
    margin-left: 0;
  }
}

.search .searchIcon {
  color: var(--color-white);
}

.search input {
  color: #ffffff;
  font-family: "Sohne Mono";
  font-size: 0.625rem;
  background: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #ffffff;
  padding:right: 2rem;

  &:hover,
  &:active,
  &:focus {
    border: 0;
    border-bottom: 1px solid #ffffff;
    box-shadow: unset;
  }

  &::placeholder {
    color: #ffffff;
    text-transform: uppercase;
  }
}
`;

const SearchInput = ({ value, placeholder = `Search...`, onChange, className }) => (
  <Container>
    <TextInput value={value} className={`search ${className}`} placeholder={placeholder} onChange={onChange} hasSearchIcon isDarkTheme />
  </Container>
);

export default SearchInput;
