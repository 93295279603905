/* eslint-disable react/no-danger */
import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import striptags from "striptags";
import { CategoryTab, Link } from "~components";
import { TARGET_CHAIN } from "~utils/helpers";

/** ============================================================================
 * @css
 */
const Expander = styled.div`
  margin-bottom: 1rem;
  overflow: hidden;
  transition: 0.4s height;
  height: ${({ isOpen, contentHeight }) => (isOpen ? `${contentHeight}px` : `0px`)};
`;

const Content = styled.div`
  padding: 1.5rem 0;

  .infoTabsMobile {
    &SummaryTag {
      margin-bottom: 0.75rem;
    }

    &InfoRow {
      &Heading {
        margin-bottom: 1.25rem;
        margin-top: 2.5rem;
      }
      &LabelText {
        border-bottom: 1px solid var(--color-mono-40);
        margin-bottom: 1.25rem;
      }
      &Label {
        color: var(--color-mono-60);
        margin-bottom: 4px;
      }
      &Text {
        margin-bottom: 8px;
      }
    }
  }

  .Content {
    animation: 0.75s var(--cubic-easing) appear-up forwards;
    animation-delay: 0.1s;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    transform: translate3d(0, -1rem, 0);
    opacity: 0;
    grid-column: 1 / 6;


    &Card {
      height: 100%;
      width: 85%;

      &Heading {
        margin-bottom: 2rem;
        color: var(--color-mono-70);
      }

      &Text {
        margin-bottom: 3px;
        color: var(--color-mono-70);
      }
    }
`;

const Separator = styled.div`
  border: 1px solid ${({ color }) => color};
  border-bottom: 0;
  margin: 0.3rem 0;
  height: 1px;
  width: 90%;
`;

/** ============================================================================
 * @component
 */
const InfoTabsMobile = ({ lucidEvent, selected }) => {
  const initialOpenTabs = {
    ticketInfo: false,
    blockchainData: false,
    eventSchedule: false
  };
  const [openTabs, setOpenTabs] = useState(initialOpenTabs);
  const [ticketInfoHeight, setTicketInfoHeight] = useState();
  const [blockchainDataHeight, setBlockchainDataHeight] = useState();
  const [eventScheduleHeight, seteventScheduleHeight] = useState();

  const ticketInfoRef = useRef(null);
  const blockchainDataRef = useRef(null);
  const eventScheduleRef = useRef(null);

  const calculateContentHeight = () => {
    setTicketInfoHeight(ticketInfoRef?.current?.offsetHeight || 0);
    setBlockchainDataHeight(blockchainDataRef?.current?.offsetHeight || 0);
    seteventScheduleHeight(eventScheduleRef?.current?.offsetHeight || 0);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    calculateContentHeight();

    window.addEventListener(`resize`, calculateContentHeight);

    return () => window.removeEventListener(`resize`, calculateContentHeight);
  }, []);

  // ---------------------------------------------------------------------------
  // render

  return (
    <div>
      {/* 01 Basic Info */}
      <CategoryTab
        tabNumber="01"
        tabTitle="Ticket Information"
        selected={openTabs.ticketInfo}
        onClick={() => setOpenTabs((prev) => ({ ...prev, ticketInfo: !prev.ticketInfo }))}
        mobile
      />

      <Expander isOpen={openTabs?.ticketInfo} contentHeight={ticketInfoHeight}>
        <Content ref={ticketInfoRef}>
          {lucidEvent && (
            <div className="ContentCard">
              <p className="ContentCardHeading">
                Tickets provide access to the superyacht from 10:00 am onwards, allowing guests to stay until the conclusion of cocktail dinners on the boat.
                The ticket includes tender transfer to the yacht, light breakfast, a fine buffet lunch and a cocktail dinner. Additionally, throughout the day
                and evening, guests will be served a selection of Champagne, spirits, fine wines, beers, and soft drinks.
              </p>
              <Separator color="var(--color-mono-40)" />
              <div>
                {lucidEvent?.tickets?.map((ticket) => (
                  <div key={ticket?.erc1155TokenID}>
                    <h4 className="b2 ContentCardText">{ticket?.title.values[`en-US`]}</h4>
                    <p className="ContentCardText">{striptags(ticket?.description.values[`en-US`])}</p>
                    <p className="ContentCardText">USDt {ticket?.price?.usdAmount}</p>
                    <Separator color="var(--color-mono-40)" />
                  </div>
                ))}
              </div>
            </div>
          )}
        </Content>
      </Expander>

      {/* 02 Event Schedule */}
      <CategoryTab
        tabNumber="02"
        tabTitle="Event Schedule"
        selected={openTabs.eventSchedule}
        onClick={() =>
          setOpenTabs((prev) => ({
            ...prev,
            eventSchedule: !prev.eventSchedule
          }))
        }
        mobile
      />
      <Expander isOpen={openTabs.eventSchedule} contentHeight={eventScheduleHeight}>
        <Content ref={eventScheduleRef}>
          {lucidEvent && (
            <div className="infoTabsMobileInfoRowLabelText">
              <h5 className="caption infoTabsMobileInfoRowLabel">Experience</h5>
              <div
                className="tag infoTabsMobileInfoRowText"
                dangerouslySetInnerHTML={{
                  __html: lucidEvent.description.values[`en-US`]
                }}
              />
            </div>
          )}
        </Content>
      </Expander>

      {/* 03 Blockchain Data */}
      <CategoryTab
        tabNumber="03"
        tabTitle="Blockchain Data"
        selected={openTabs.blockchainData}
        onClick={() =>
          setOpenTabs((prev) => ({
            ...prev,
            blockchainData: !prev.blockchainData
          }))
        }
        mobile
      />
      <Expander isOpen={openTabs.blockchainData} contentHeight={blockchainDataHeight}>
        <Content ref={blockchainDataRef}>
          <>
            {selected && (
              <div className="infoTabsMobileInfoRowLabelText">
                <h5 className="caption infoTabsMobileInfoRowLabel">NFT ID</h5>
                <div className="tag infoTabsMobileInfoRowText">{selected.id}</div>
              </div>
            )}

            {lucidEvent && selected && (
              <div className="infoTabsMobileInfoRowLabelText">
                <h5 className="caption infoTabsMobileInfoRowLabel">Contract Address</h5>
                <div className="tag infoTabsMobileInfoRowText">
                  <Link
                    css={css`
                      &:hover {
                        text-decoration: underline;
                        color: var(--color-primary-orange-100);
                      }
                    `}
                    to={
                      TARGET_CHAIN.id !== 137
                        ? `https://mumbai.polygonscan.com/address/${
                            lucidEvent.contractAddresses[Object.keys(lucidEvent.contractAddresses)[0]][0].contractAddress
                          }`
                        : `https://polygonscan.com/address/${lucidEvent.contractAddresses[Object.keys(lucidEvent.contractAddresses)[0]][0].contractAddress}`
                    }
                  >
                    {lucidEvent.contractAddresses[Object.keys(lucidEvent.contractAddresses)[0]][0].contractAddress}
                  </Link>
                </div>
              </div>
            )}
          </>
        </Content>
      </Expander>
    </div>
  );
};

export default InfoTabsMobile;
