import { Fragment } from "react";
import {
  BLOCK_EXPLORER_ADDRESS,
  BLOCK_EXPLORER_NFT,
  BLOCK_EXPLORER_TOKEN,
  handleImageClick,
  thousandCommas,
  truncateAddress,
  walletAddressAreEqual
} from "~utils/helpers";
import { Button, PriceWidget, Image } from "~components";
import Tooltip from "~components/Common/Tooltip";

const MarketplaceItemDetails = ({ product, onValuationClick, isWhole, owner, oracle }) => (
  <section className="itemDetails">
    <div className="images">
      <figure>
        <a href={product.product.nftImage.publicUrl} target="_blank" rel="noreferrer" onClick={(e) => handleImageClick(e, product.product.nftImage.publicUrl)}>
          <span className="visually-hidden">{`${product.product.model} image`}</span>
          <Image alt={product.product.model} image={product.product.nftImage.publicUrl} fill />
        </a>
      </figure>
      {product.product.images.slice(0, 2).map((image, i) => (
        <figure key={`${image.publicUrl}-${i}`}>
          <a href={image.publicUrl} target="_blank" rel="noreferrer" onClick={(e) => handleImageClick(e, image.publicUrl)}>
            <span className="visually-hidden">{`${product.product.productName} image`}</span>
            <Image alt={product.product.productName} image={image.publicUrl} fill />
          </a>
        </figure>
      ))}
    </div>
    <div className="parameters tabContent">
      <h3>{product.product.productName}</h3>
      <ul>
        <li className="secondary">
          <dl>
            <Tooltip content="For legal compliance, items sold directly by oracles can only be purchased after completing Identity verification. This ensures a secure and regulated transaction process.">
              <dt>
                Owned by <i className="icon info">info</i>
              </dt>
            </Tooltip>
            <dd>
              <a target="_blank" href={`${BLOCK_EXPLORER_ADDRESS}${owner}`}>
                <span>{walletAddressAreEqual(owner, oracle?.walletAddress) ? oracle?.companyName : truncateAddress(owner, 4, 6)}</span>
                <i className="icon chain">chain</i>
              </a>
            </dd>
          </dl>
        </li>
        <li className="secondary">
          <dl>
            <dt>NFT Type</dt>
            <dd>{isWhole ? `Whole` : `Fragments`}</dd>
          </dl>
        </li>
        <li className="secondary">
          <dl>
            <dt>Collection</dt>
            <dd>
              <a target="_blank" href={`${BLOCK_EXPLORER_TOKEN}${product.nftData.nftCollectionInfo.collectionAddress}`}>
                <span>{truncateAddress(product.nftData.nftCollectionInfo.collectionAddress, 4, 6)}</span>
                <i className="icon chain">chain</i>
              </a>
            </dd>
          </dl>
        </li>
        <li className="secondary">
          <dl>
            <dt>NFT Id</dt>
            <dd>
              <a target="_blank" href={`${BLOCK_EXPLORER_NFT}${product.nftData.nftCollectionInfo.collectionAddress}/${product.nftData.tokenId}`}>
                <span>{product.nftData.tokenId}</span>
                <i className="icon chain">chain</i>
              </a>
            </dd>
          </dl>
        </li>
        <li className="primary">
          <dl>
            <dt>ISSUER ORACLE</dt>
            <dd>
              {product.product.oracle.text}
              <Button href={`/oracles/${product.product.oracleSlug}/`} variant="secondary" colorTheme="dark" fluid>
                Visit
                <i className="icon">arrow_right</i>
              </Button>
            </dd>
          </dl>
        </li>
        {product.product.productValueIndexDateAndPrices && product.product.productValueIndexDateAndPrices.length > 0 && (
          <li className="primary price">
            <dl>
              <dt>Valuation</dt>
              <dd>
                <PriceWidget
                  displayPrice={thousandCommas(product.product.productValueIndexDateAndPrices.at(-1).price.toFixed(2))}
                  currencyVariant="secondary"
                  color="mono-90"
                />
                <Button onClick={onValuationClick} variant="secondary" className="metrics" colorTheme="dark" fluid>
                  View price analytics
                  <i className="icon">arrow_right</i>
                </Button>
              </dd>
            </dl>
          </li>
        )}
      </ul>
      <section className="description">
        <h4>Description</h4>
        <p dangerouslySetInnerHTML={{ __html: product.product.description.values["en-US"] }} />
        <dl className="attributes">
          {product.product.attributes.map((attribute) => (
            <Fragment key={attribute.label}>
              <dt>{attribute.label}</dt>
              <dd>{attribute.value}</dd>
            </Fragment>
          ))}
        </dl>
      </section>
      {!walletAddressAreEqual(owner, product.product.oracleWalletAddress) && (
        <dl>
          <dt>WHERE IS THE ITEM?</dt>
          <dd>
            Dodici Motorsport
            <Button href="/oracles/dodici-motorsport/" variant="secondary" colorTheme="dark" fluid>
              Visit
              <i className="icon">arrow_right</i>
            </Button>
          </dd>
        </dl>
      )}
    </div>
  </section>
);

export default MarketplaceItemDetails;
