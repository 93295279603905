import React from "react";
import styled from "@emotion/styled";
import { ImageRatio, Button } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const transitionTime = `0.4s`;

const Container = styled.section`
  border-radius: 1rem;
  position: relative;
  padding: 0.75rem;

  .oracleInfoCardDesktopImage {
    flex: 1;
  }

  .oracleInfoCardDesktopImage {
    display: none;
  }

  .oracleInfoCardPartition {
    border-top: 1px solid var(--color-white);
    margin-bottom: 1rem;
    transition: opacity ${transitionTime};
  }

  ${breakpoint(`small-tablet`)} {
    padding: 1.25rem;
    display: flex;

    .oracleInfoCardMobileImage {
      display: none;
    }

    .oracleInfoCardDesktopImage {
      display: block;
      width: 100%;
      transition: opacity ${transitionTime};
    }
  }
`;

const ImageContainer = styled.div`
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  width: 100%;

  ${breakpoint(`small-tablet`)} {
    margin-bottom: 0;
  }
`;

const TextContainer = styled.div`
  color: var(--color-white);

  .oracleInfoCardTitle,
  .oracleInfoCardLabel,
  .oracleInfoCardInfo {
    transition: opacity ${transitionTime};
  }

  .oracleInfoCard {
    &Title {
      margin-bottom: 12px;
    }
    &Label {
      margin-bottom: 4px;
      color: var(--color-mono-40);
    }
    &Info {
      margin-bottom: 24px;
      display: block;
    }
  }

  hr {
    margin-bottom: 12px;
    border: none;
    border-top: 1px solid var(--color-white);
  }

  ${breakpoint(`small-tablet`)} {
    flex: 1;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
  }
`;

const ButtonContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

/** ============================================================================
 * @component
 */

const OracleInfoCard = ({ title, specialty, currentListing, image, slug, category }) => (
  <Container className="frosted-glass-light">
    <div className="oracleInfoCardMobileImage">
      <ImageContainer>
        <ImageRatio image={image?.asset?.gatsbyImageData} altText={image?.altText} ratio="3/2" />
      </ImageContainer>
    </div>
    <TextContainer>
      <h3 className="h3 oracleInfoCardTitle">{title}</h3>
      <div className="oracleInfoCardPartition" />

      {category && (
        <>
          <h4 className="caption oracleInfoCardLabel">Category</h4>
          <div className="tag oracleInfoCardInfo">
            {category.map((cat, index) => (
              <span key={index}>
                {index ? `, ` : ``}
                <span>{cat}</span>
              </span>
            ))}
          </div>
        </>
      )}

      {specialty && (
        <>
          <h4 className="caption oracleInfoCardLabel">Specialty</h4>
          <div className="tag oracleInfoCardInfo">{specialty}</div>
        </>
      )}

      {currentListing && (
        <>
          <h4 className="caption oracleInfoCardLabel">Current listing</h4>
          <div className="tag oracleInfoCardInfo">{currentListing}</div>
        </>
      )}

      <ButtonContainer>
        <Button to={`/oracles/${slug?.current}`} transparent square>
          See Oracle
        </Button>
      </ButtonContainer>
    </TextContainer>
    <div className="oracleInfoCardDesktopImage">
      <ImageContainer>
        <ImageRatio image={image?.asset?.gatsbyImageData} altText={image?.altText} ratio="30/37" />
      </ImageContainer>
    </div>
  </Container>
);

export default OracleInfoCard;
