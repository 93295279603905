/* eslint-disable import/no-extraneous-dependencies */

import React from "react";
import styled from "@emotion/styled";
import { useWindowDimensions } from "~hooks";
import { Grid, Image, SVG } from "~components";
import { truncateAddress } from "~utils/helpers.js";

/** ============================================================================
 * @css
 */
const NFTInspector = styled.div`
  transition:
    0.3s var(--cubic-easing) opacity,
    0.3s var(--cubic-easing) transform;

  opacity: ${({ active }) => `${active ? 1 : 0}`};
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};
  transform: scale(${({ active }) => `${active ? 1 : 1.025}`});

  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  background: black;
  color: white;

  .nftInspector {
    &Content {
      grid-column: 1 / -1;
      position: relative;
    }

    &Close {
      width: 100%;
      position: relative;
      padding: 1rem 0;
      display: flex;
      justify-content: end;

      button {
        width: 1.75rem;
        height: 1.75rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background: white;
        color: black;

        svg {
          width: 11px;
        }
      }
    }

    &Header {
      position: relative;
      margin-top: 2.5rem;
    }

    &Oracle {
      margin-bottom: 2px;
      color: var(--color-mono-40);
    }

    &Image {
      aspect-ratio: 1.5;
      position: relative;
      overflow: hidden;
      border-radius: 12px;
    }

    &Links {
      margin-top: 3.5rem;
    }

    &Socials {
      position: relative;
      display: flex;
      margin-top: 0.75rem;
      gap: 0 1.5rem;
      color: white;

      button {
        width: 2.5rem;
        height: 2.5rem;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        height: 2.5rem;
      }
    }

    &Table {
      position: relative;
      margin-top: 2.5rem;

      &Heading {
        margin-bottom: 0.5rem;
      }

      &Item {
        padding: 0.5rem 0;
        border-top: 1px solid var(--color-mono-70);
      }

      &Key {
        color: var(--color-mono-40);
      }
    }
  }
`;

/** ============================================================================
 * @component
 */
const ProfileItemInspector = ({ active, setActive = () => {}, item, socials }) => {
  const { isMobile, screen } = useWindowDimensions();

  if (!item) {
    return null;
  }

  return (
    <>
      {screen && isMobile && (
        <NFTInspector active={active}>
          <Grid>
            <div className="nftInspectorContent">
              <div className="nftInspectorClose">
                <button type="button" onClick={() => setActive(false)}>
                  <SVG svg="cross" />
                </button>
              </div>

              {item?.data?.product?.nftImage?.publicUrl && (
                <figure className="nftInspectorImage">
                  <Image image={item.data.product.nftImage.publicUrl} fill />
                </figure>
              )}

              <header className="nftInspectorHeader">
                <h4 className="nftInspectorOracle caption">{item?.data?.product?.oracle?.text || ``}</h4>

                <h3 className={`nftInspectorTitle ${isMobile ? `h3` : `h4`}`}>{item?.data?.product?.model || ``}</h3>
              </header>

              {/* @externalintegration - whole vs fragment? */}
              <div className="nftInspectorTable">
                <h3 className="nftInspectorTableHeading b1">Whole</h3>

                <ul className="nftInspectorTableList">
                  {item?.data?.nftData?.nftCollectionInfo?.collectionAddress && (
                    <li className="nftInspectorTableItem">
                      <h5 className="nftInspectorTableKey caption">COLLECTION:</h5>
                      <p className="b2">{truncateAddress(item.data.nftData.nftCollectionInfo.collectionAddress)}</p>
                    </li>
                  )}

                  {item?.data?.nftData?.nftCollectionInfo?.identifier && (
                    <li className="nftInspectorTableItem">
                      <h5 className="nftInspectorTableKey caption">NFT ID:</h5>
                      <p className="b2">{truncateAddress(item.data.nftData.nftCollectionInfo.identifier)}</p>
                    </li>
                  )}
                </ul>
              </div>

              {socials?.[0] && (
                <div className="nftInspectorLinks">
                  <h4 className="tag">SHARE</h4>

                  <ul className="nftInspectorSocials">
                    {socials.map((social, index) => (
                      <li key={`sidebar-social-${index}`} className="nftInspectorSocial">
                        {social}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </Grid>
        </NFTInspector>
      )}
    </>
  );
};

export default ProfileItemInspector;
