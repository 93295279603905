import React from "react";
import { ArticleTextArea, PgWrapper, PgItem } from "~components";

const rawText = [
  {
    _key: `ecb888cd1059`,
    _type: `block`,
    children: [
      {
        _key: `a4ab89c2247b`,
        _type: `span`,
        marks: [],
        text: `The car was used in several events, such as the 25th anniversary of the Ferrari Club Germany in 2003 in Baden-Baden, a rally marking the 30 years of the Ferrari F40, the Mille Miglia Tribute in 2010, 2011, and 2012, at the invitation of Ferrari.`
      }
    ],
    markDefs: [],
    style: `normal`
  },
  {
    _key: `ecb888cd2059`,
    _type: `block`,
    children: [
      {
        _key: `a4ab89c3247b`,
        _type: `span`,
        marks: [],
        text: `The car was regularly serviced by Kroymans and Forza Service, as documented by invoices. Thanks to its condition, the car was awarded a Ferrari Classiche certification in 2008. After being sold at an auction in 2014, the car changed hands twice in Germany, with the last owner keeping it in his collection of Ferrari’s from different periods. Today, the car shows a total of just over 27k km.`
      }
    ],
    markDefs: [],
    style: `normal`
  },
  {
    _key: `3d6ea8dfdb0e`,
    _type: `block`,
    children: [
      {
        _key: `b2c0a56d90e6`,
        _type: `span`,
        marks: [],
        text: `Here's some more text.`
      }
    ],
    markDefs: [],
    style: `normal`
  }
];

const ArticleTextAreaPG = () => (
  <>
    <PgWrapper title="ArticleTextArea" itemWidth="100%">
      <PgItem>
        <div style={{ background: `white`, padding: 16 }}>
          <ArticleTextArea headingCaption="About this F40" heading="An immaculate conception" rawText={rawText} />
        </div>
      </PgItem>
    </PgWrapper>
  </>
);

export default ArticleTextAreaPG;
