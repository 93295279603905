import React from "react";
import { TextChanger, PgWrapper, PgItem } from "~components";

const TextChangerPG = () => (
  <PgWrapper title="TextChanger">
    <PgItem>
      <TextChanger
        headings={[`Cryptocurrency`, `a supercar`, `a Digital Collectible`]}
      />
    </PgItem>
  </PgWrapper>
);

export default TextChangerPG;
