import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import gsap from "gsap";
import { useImageTagCache, useScroll, useSize, useTimeout } from "~hooks";
import { ScrollVideoText } from "~components";

/** ============================================================================
 * @css
 */

const FULL_ABSOLUTE = `
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
`;
const Container = styled.section`
  width: 100%;
  height: ${({ loading }) => `${loading ? `100vh` : `500vh`}`};
  position: ${({ loading }) => `${loading ? `fixed` : `relative`}`};
  overflow: hidden;

  ${({ loading }) => `${loading ? `top:0;right:0;bottom:0;left:0;` : ``}`};
`;

const HTMLCanvas = styled.canvas`
  ${FULL_ABSOLUTE}
  z-index: 20;
`;

const Clip = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  clip: rect(auto, auto, auto, auto);
  z-index: 20;
`;

const CanvasContainer = styled.div`
  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 30;
`;

const ScrollVideoTextContainer = styled.div`
  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 40;
`;

/** ============================================================================
 * ScrollVideo
 * @component
 * Topmost slice loaded by /product/[sanityProduct.slug.current] routes.
 */
const ScrollVideo = ({
  onLoaded = () => {},
  onProgress = () => {},
  offset = 0
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const canvasRef = useRef();
  const [containerRef, containerBoundingRect] = useSize();

  const { scrollTop } = useScroll();

  const imageURLs = Array(400)
    .fill(null)
    .map((_, index) => `/images/f40/${index}.webp`);

  const { imageTags, progress } = useImageTagCache(imageURLs);

  const [activeFrame, setActiveFrame] = useState(null);
  const [canvasDimensions, setCanvasDimensions] = useState({
    width: 0,
    height: 0
  });
  const [loading, setLoading] = useState(true);

  const textPhrases = [
    `Legendary Ferrari`,
    `Top Speed 324 km/h`,
    `471 horsepower`,
    `V8 Bi-Turbo`,
    ``
  ];
  const [phraseActive, setPhraseActive] = useState(false);
  const [activePhrase, setActivePhrase] = useState(textPhrases[0]);

  // ---------------------------------------------------------------------------
  // methods

  // via https://stackoverflow.com/questions/21961839/simulation-background-size-cover-in-canvas
  const drawCenteredImage = (ctx, img, x, y, w, h, offsetX, offsetY) => {
    offsetX = typeof offsetX === `number` ? offsetX : 0.5;
    offsetY = typeof offsetY === `number` ? offsetY : 0.5;

    if (offsetX < 0) offsetX = 0;
    if (offsetY < 0) offsetY = 0;
    if (offsetX > 1) offsetX = 1;
    if (offsetY > 1) offsetY = 1;

    const iw = img.width;
    const ih = img.height;
    const r = Math.min(w / iw, h / ih);
    let nw = iw * r;
    let nh = ih * r;
    let cx;
    let cy;
    let cw;
    let ch;
    let ar = 1;

    if (nw < w) ar = w / nw;
    if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;
    nw *= ar;
    nh *= ar;

    cw = iw / (nw / w);
    ch = ih / (nh / h);

    cx = (iw - cw) * offsetX;
    cy = (ih - ch) * offsetY;

    if (cx < 0) cx = 0;
    if (cy < 0) cy = 0;
    if (cw > iw) cw = iw;
    if (ch > ih) ch = ih;

    ctx.drawImage(img, cx, cy, cw, ch, x, y, w, h);
  };

  const drawFrame = (frameOverride) => {
    if (!canvasRef?.current || !imageTags?.[0]) {
      return;
    }

    const frame =
      typeof frameOverride !== `undefined` ? frameOverride : activeFrame;

    const img = imageTags?.[frame];

    if (!img) {
      return;
    }

    const canvas = canvasRef.current;
    const ctx = canvas.getContext(`2d`);

    ctx.clearRect(0, 0, canvasDimensions.width, canvasDimensions.height);

    drawCenteredImage(
      ctx,
      img,
      0,
      0,
      canvasDimensions.width,
      canvasDimensions.height
    );
  };

  const load = () => {
    setActiveFrame(0);
    setLoading(false);

    if (typeof window !== `undefined`) {
      window.scrollTo(0, 0);
    }

    onLoaded();
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (typeof window === `undefined`) {
      return () => {};
    }

    const handleResize = (e) => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      setCanvasDimensions({
        width,
        height
      });
    };

    handleResize();

    window.addEventListener(`resize`, handleResize, false);

    return () => {
      window.removeEventListener(`resize`, handleResize, false);
    };
  }, []);

  useEffect(() => {
    if (
      typeof window === `undefined` ||
      !containerRef?.current ||
      !containerBoundingRect
    ) {
      return;
    }

    const adjustedScrollTop = scrollTop - offset;

    if (adjustedScrollTop < 0) {
      const transformOffset = parseInt(0 - adjustedScrollTop) * 0.75;

      setPhraseActive(false);

      gsap.to(`.scroll-video-container`, {
        duration: 0,
        y: transformOffset,
        ease: `expo.inOut`
      });

      return;
    }

    gsap.to(`.scroll-video-container`, {
      duration: 0,
      y: 0,
      ease: `expo.inOut`
    });

    const { height } = containerBoundingRect;

    const percentComplete =
      adjustedScrollTop / (height - window.innerHeight / 2);
    const frame = parseInt(percentComplete * 399);

    setActiveFrame(frame);

    //

    //
    // const phraseRenderFrequency =
    //   1 / ((textPhrases.length - 1) / textPhrases.length);
    const phraseRenderFrequency = 1.1;

    const percentCompleteForPhrases = percentComplete * phraseRenderFrequency;
    const activePhraseIndex = Math.ceil(
      parseInt(textPhrases.length * percentCompleteForPhrases)
    );

    setActivePhrase(textPhrases[activePhraseIndex]);
    setPhraseActive(
      percentCompleteForPhrases > 0 && percentCompleteForPhrases < 0.8
    );
  }, [scrollTop]);

  useEffect(() => {
    if (!canvasRef?.current || !imageTags?.[0]) {
      return;
    }

    drawFrame();
  }, [activeFrame, canvasRef, canvasDimensions, imageTags]);

  useEffect(() => {
    onProgress(progress);

    if (progress >= 1) {
      load();
    }
  }, [progress]);

  // ---------------------------------------------------------------------------
  // render

  //

  return (
    <Container ref={containerRef}>
      <Clip>
        <ScrollVideoTextContainer>
          <ScrollVideoText active={phraseActive} phrase={activePhrase} />
        </ScrollVideoTextContainer>

        <CanvasContainer className="scroll-video-container">
          <HTMLCanvas
            id="scroll-video-canvas"
            ref={canvasRef}
            width={canvasDimensions?.width || 0}
            height={canvasDimensions?.height || 0}
          />
        </CanvasContainer>
      </Clip>
    </Container>
  );
};

export default ScrollVideo;
