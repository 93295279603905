import React, { useEffect, useState } from "react";
import cn from "classnames";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTTimeSelect = ({ className = ``, heading, onSelect = () => {} }) => {
  const [activeRange, setActiveRange] = useState(null);

  const ranges = [`24 hours`, `7 days`, `15 days`];

  useEffect(() => {
    if (activeRange) {
      onSelect(activeRange);
    }
  }, [activeRange]);

  return (
    <div className={cn(`nftEntry`, className)}>
      <h3>{heading}</h3>
      <div className="nftInput select">
        {ranges.map((range) => (
          <button key={`nft-time-select-range-${range}`} type="button" onClick={() => setActiveRange(range)} className={cn(range === activeRange && `active`)}>
            <span className="b2">{range}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default NFTTimeSelect;
