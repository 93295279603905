import React, { useState } from "react";
import { Button } from "~components";
import { createPortal } from "react-dom";
import { privateKeyProvider } from "~layouts";
import KeyPopup from "./KeyPopup";
import NoSSR from "~components/Common/NoSSR";
import useApp from "~hooks/useApp";

/** ============================================================================
 * @component
 */
const ProfileKeyManagement = () => {
  const [privateKey, setPrivateKey] = useState(null);
  const [activeModal, setActiveModal] = useState();
  const { setDisableScroll } = useApp();

  const exportPrivateKey = async () => {
    try {
      const pkey = await privateKeyProvider.request({ method: `eth_private_key` });
      setPrivateKey(pkey);
    } catch (error) {
      console.error(`Error exporting private key:`, error);
      alert(`Failed to export private key. Please try again.`);
    }
  };

  const handleOpenModal = (modal) => {
    setDisableScroll(true);
    setActiveModal(modal);
  };

  const handleExportClick = async () => {
    await exportPrivateKey();
    handleOpenModal(`Pkey`);
  };

  const handleCloseModal = () => {
    setPrivateKey(null);
    setDisableScroll(false);
    setActiveModal(null);
  };

  return (
    <div className="profileKeyManagement">
      {activeModal === `Pkey` && <NoSSR>{createPortal(<KeyPopup privateKey={privateKey} closeModal={handleCloseModal} />, document.body)}</NoSSR>}
      <h2 className="title">Private Key Management</h2>
      <div className="info-text">
        <p>
          This section is specifically for users who connected to our dApp using Web3Auth. It allows you to export your private key, giving you full control
          over your wallet.
        </p>
        <p>By exporting your private key, you can:</p>
        <ul>
          <li>Use your wallet with other applications that don’t support Web3Auth</li>
          <li>Transfer your wallet to a different key management system</li>
          <li>Have complete ownership and responsibility for your crypto assets</li>
        </ul>
      </div>
      <Button onClick={handleExportClick} className="kycButton settingsSubmit" iconRight="arrowRight" variant="secondary" colorTheme="light">
        Export Private Key
      </Button>
      <p className="warning-text">
        <strong>Warning: Exporting your private key carries significant risks. Only proceed if you fully understand the implications.</strong>
      </p>
    </div>
  );
};

export default ProfileKeyManagement;
