import React, { useRef, useState } from "react";
import cn from "classnames";
import { CurrencyBadge } from "~components";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTNumberEntry = ({ className = ``, name = ``, onChange = () => {}, heading, placeholder = ``, min = 1, max = null, withBadge = false, value }) => {
  const [isValid, setIsValid] = useState(true);
  const inputRef = useRef();

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();

    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const handleChange = (event) => {
    const { value: inputValue } = event.target;

    if (event.target.type === `number`) {
      const { min: minTarget, max: maxTarget } = event.target;

      if (maxTarget && parseInt(inputValue) > parseInt(maxTarget)) {
        event.target.value = maxTarget;
      }

      if (minTarget && parseInt(inputValue) < parseInt(minTarget) && inputValue != ``) {
        event.target.value = minTarget;
      }
    }

    if (onChange) onChange(event);

    setIsValid(!((min && inputValue < min) || (max && inputValue > max)));
  };

  const handleMax = () => {
    if (max && inputRef.current) {
      handleChange({ target: { name, value: max } });
      inputRef.current.value = max;
    }
  };

  return (
    <div className={cn(`nftEntry`, className)}>
      <h3>{heading}</h3>
      <div className={cn(`nftInput`, (max || withBadge) && `withBadge`, !isValid && `invalid`)}>
        <input
          ref={inputRef}
          className="b1"
          name={name}
          onChange={handleChange}
          placeholder={placeholder}
          min={min}
          max={max}
          type="number"
          value={value}
          onWheel={(e) => numberInputOnWheelPreventChange(e)}
        />
        {!!max && (
          <div className="badge">
            <button type="button" className="max" onClick={handleMax}>
              MAX
            </button>
          </div>
        )}
        {withBadge && (
          <div className="badge">
            <CurrencyBadge small text="USDt" />
          </div>
        )}
      </div>
    </div>
  );
};

export default NFTNumberEntry;
