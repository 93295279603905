import useNFT from "~hooks/useNFT";
import ActivityControl from "../../../Marketplace/NFTActivityControls/index";

const ActivityControlsPG = () => {
  const nft = useNFT("867298f0-ad79-4c2a-8262-b00b009e13c1", "watches");
  return (
    <div style={{ background: "white" }}>
      <ActivityControl nft={nft} nftStatus={nft?.nftStatus} />
    </div>
  );
};

export default ActivityControlsPG;
