import React, { useState } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useApp } from "~hooks";
import { Grid, Image, NFTItemCard, NFTUserData, SVG } from "~components";

/** ============================================================================
 * @css
 */

const Container = styled.article`
  transition: 0.35s var(--cubic-easing) transform;

  transform: translate3d(0, ${({ open }) => `${open ? `0` : `calc(-100vh + 64px)`}, 0)`};

  width: 100%;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
  background: var(--color-beige);

  .nftOverlayTray {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &Button {
      width: 2rem;
      height: 2rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      overflow: hidden;
      z-index: 10;
      border: 1px solid var(--color-khaki-100);
      color: var(--color-khaki-100);

      svg {
        width: 1rem;
      }
    }

    &Content {
      width: 100%;
      flex: 1 0 auto;
      position: relative;
    }

    &Scroll {
      grid-column: 1 / -1;
      height: 100%;
      overflow-y: scroll;
      position: relative;
    }

    //

    &Close {
      opacity: ${({ open }) => `${open ? `1` : `0`}`};

      grid-column: 1 / -1;
      position: relative;
      padding: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    // toggle is relative, closes the overlay
    &Toggle {
      height: 64px;
    }

    // tab is global, opens the overlay
    &Tab {
      opacity: ${({ open }) => `${open ? `0` : `1`}`};

      width: calc(100% - 1rem);
      position: relative;
      margin: 0 auto;
      border-radius: 0px 0px 12px 12px;
      overflow: hidden;
      border: 1px solid var(--color-khaki-50);
      border-top: none;

      & > div {
        padding: 0 1rem !important;
      }
    }

    &User {
      margin-top: 2rem;
    }

    //

    &Open {
      grid-column: 1 / -1;
      position: relative;
      padding: 1rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &Meta {
        height: 100%;
        display: flex;
        align-items: center;
      }

      &Image {
        width: 2rem;
        height: 2rem;
        position: relative;
        margin-right: 0.5rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
      }
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayTray = ({ nft, sanityProduct, selected }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const [open, setOpen] = useState(false);

  const { overlayActivityData } = useApp();

  // ---------------------------------------------------------------------------
  // context / ref / state

  // ---------------------------------------------------------------------------
  // lifecycle

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container open={open}>
      <article className="nftOverlayTray">
        <div className="nftOverlayTrayToggle">
          <Grid>
            <div className="nftOverlayTrayClose">
              <div className="nftOverlayTrayCloseMeta">
                <h4 className="b2">Item Details</h4>
              </div>

              <button type="button" className="nftOverlayTrayButton" onClick={() => setOpen(false)}>
                <SVG svg="minus" />
              </button>
            </div>
          </Grid>
        </div>

        <div className="nftOverlayTrayContent">
          <Grid>
            <div className="nftOverlayTrayScroll">
              {open && (
                <>
                  <NFTItemCard
                    micro
                    image={sanityProduct?.image}
                    oracle={sanityProduct?.oracle?.title}
                    title={sanityProduct?.title}
                    body={selected?.title}
                    data={overlayActivityData}
                  />

                  <NFTUserData className="nftOverlayTrayUser" nft={nft} />
                </>
              )}
            </div>
          </Grid>
        </div>

        <div className="nftOverlayTrayTab">
          <Grid>
            <div className="nftOverlayTrayOpen">
              <div className="nftOverlayTrayOpenMeta">
                <figure className="nftOverlayTrayOpenImage">
                  <Image
                    css={css`
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    `}
                    image={sanityProduct?.image}
                  />
                </figure>

                <h4 className="b2">{sanityProduct?.title}</h4>
              </div>

              <button type="button" className="nftOverlayTrayButton" onClick={() => setOpen(true)}>
                <SVG svg="plus" />
              </button>
            </div>
          </Grid>
        </div>
      </article>
    </Container>
  );
};

export default NFTOverlayTray;
