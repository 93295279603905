import cn from "classnames";
import { useTabsContext } from "./TabsContext";

const Tab = ({ id, children, disabled }) => {
  const { activeTab, setActiveTab } = useTabsContext();

  return (
    <li className={cn(activeTab === id && `active`)}>
      <button type="button" onClick={() => setActiveTab(id)} disabled={disabled}>
        {children}
      </button>
    </li>
  );
};

export default Tab;
