import React, { useState } from "react";
import { SVG, Select, SearchInput, Modal, Button } from "~components";

const ProfileCollectionFilters = ({ searchQuery, setSearchQuery, dropdowns, pageNumber, displayedPages, currentPage, onPageChange }) => {
  const pagesToRender = (pageNumber >= displayedPages ? displayedPages : pageNumber) || pageNumber;
  const dynamicPages = pagesToRender - 2;
  const pageOffset = dynamicPages % 2 === 0 ? Math.floor(dynamicPages / 2) - 1 : Math.floor(dynamicPages / 2);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFiltersModalOpen = () => setIsModalOpen(true);

  const handleFiltersClose = () => setIsModalOpen(false);

  const handleFiltersApply = () => setIsModalOpen(false);

  const handlePageChange = (page) => () => {
    onPageChange(page);
  };

  const handleQueryChange = (value) => {
    if (onPageChange) onPageChange(0);
    setSearchQuery(value || ``);
  };

  const handleFiltersReset = () => {
    dropdowns.forEach((dropdown) => {
      if (typeof dropdown.defaultValue !== `undefined`) dropdown.onChange(dropdown.defaultValue);
    });
  };

  return (
    <>
      <Modal className="profileFiltersModal" title="Filter results:" onClose={handleFiltersClose} isVisible={isModalOpen} disableOutsideClick>
        <Modal.Content>
          {dropdowns.map((dropdown) => (
            <div className="filter" key={`modal_${dropdown.label}`}>
              <strong className="font-mono text-xs uppercase text-mono-40">{dropdown.label}:</strong>
              <Select
                variant="secondary"
                options={dropdown.options}
                onChange={(value) => dropdown.onChange(value)}
                value={dropdown.value}
                popdownClassName="profileFiltersPopdown"
              />
            </div>
          ))}
        </Modal.Content>
        <Modal.Footer>
          <Button onClick={handleFiltersReset} variant="feature" fluid>
            Clear filters
          </Button>
          <Button onClick={handleFiltersApply} variant="primaryTall" colorTheme="dark" fluid>
            Apply filters
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="profileFilters">
        <SearchInput value={searchQuery} onChange={handleQueryChange} />
        <button type="button" className="toggleModal" onClick={handleFiltersModalOpen} aria-label="Open filters">
          <SVG svg="filtersIcon" />
        </button>
        <div className="filtersList">
          {dropdowns.map((dropdown) => (
            <Select
              key={`main_${dropdown.label}`}
              options={dropdown.options}
              onChange={(value) => dropdown.onChange(value)}
              value={dropdown.value}
              title={dropdown.label}
            />
          ))}
        </div>
        {!!(pageNumber && pageNumber >= 2) && (
          <ol>
            <strong className="tag">{`PAGE: `}</strong>
            <li className={currentPage === 0 ? `current` : undefined}>
              <button onClick={handlePageChange(0)} type="button">
                1
              </button>
            </li>
            {pagesToRender < pageNumber && currentPage > pageOffset + 1 && <li>...</li>}
            {Array(dynamicPages)
              .fill(null)
              .map((_, index) => {
                const startingPage = (() => {
                  if (currentPage - pageOffset <= 1) return 1;
                  if (currentPage + pageOffset >= pageNumber - 2) return pageNumber - pagesToRender + 1;

                  return currentPage - pageOffset;
                })();

                return (
                  <li className={currentPage === startingPage + index ? `current` : undefined} key={index}>
                    <button onClick={handlePageChange(startingPage + index)} type="button">
                      {startingPage + index + 1}
                    </button>
                  </li>
                );
              })}
            {pagesToRender < pageNumber && currentPage < pageNumber - (dynamicPages % 2 === 0 ? pageOffset + 1 : pageOffset) - 2 && <li>...</li>}
            <li className={currentPage === pageNumber - 1 ? `current` : undefined}>
              <button onClick={handlePageChange(pageNumber - 1)} type="button">
                {pageNumber}
              </button>
            </li>
          </ol>
        )}
      </div>
    </>
  );
};

export default ProfileCollectionFilters;
