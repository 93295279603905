import React from "react";
import { TextInput, PgWrapper, PgItem } from "~components";

const data = {
  label: `Enter email`,
  placeholder: `Placeholder`,
  warningMessage: `This is a warning`,
  hint: `This is a hint`
};

const TextInputPG = () => (
  <>
    <PgWrapper title="TextInput">
      <PgWrapper itemWidth="300px">
        <PgItem title="Default">
          <TextInput {...data} />
        </PgItem>

        <PgItem title="Required">
          <TextInput {...data} required />
        </PgItem>

        <PgItem title="Icon">
          <TextInput {...data} hasSearchIcon />
        </PgItem>

        <PgItem title="Warning">
          <TextInput {...data} warningMessage="This is a warning" />
        </PgItem>

        <PgItem title="Error">
          <TextInput {...data} error />
        </PgItem>
        <PgItem title="Disabled">
          <TextInput {...data} disabled />
        </PgItem>
      </PgWrapper>
    </PgWrapper>

    <PgWrapper title="TextInput with button">
      <PgWrapper itemWidth="300px">
        <PgItem title="Default">
          <TextInput {...data} onClick={() => {}} />
        </PgItem>
      </PgWrapper>
    </PgWrapper>

    <PgWrapper title="Text Area Input">
      <PgWrapper itemWidth="300px">
        <PgItem title="Default">
          <TextInput {...data} textarea />
        </PgItem>

        <PgItem title="Disabled">
          <TextInput {...data} textarea disabled />
        </PgItem>

        <PgItem title="Error">
          <TextInput {...data} textarea error />
        </PgItem>

        <PgItem title="Button">
          <TextInput {...data} textarea onClick={() => {}} />
        </PgItem>
      </PgWrapper>
    </PgWrapper>
  </>
);

export default TextInputPG;
