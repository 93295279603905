import React from "react";
import styled from "@emotion/styled";
import { Image, SVG } from "~components";

const Container = styled.div`
  position: relative;

  .nftItemCard {
    position: relative;

    padding: ${({ micro }) => `${micro ? `0` : `1.5rem`}`};
    border-radius: 12px;

    background: ${({ micro }) => `${micro ? `transparent` : `rgba(255, 255, 255, 0.4);`}`};
    backdrop-filter: blur(50px);

    &Main {
      display: flex;
      gap: 0 1.5rem;
    }

    &Image {
      width: ${({ micro }) => `${micro ? `30%` : `50%`}`};
      aspect-ratio: 0.8;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }

    &Text {
      width: 50%;
      position: relative;
      display: block;
      &Body {
        margin-top: 5px;
      }
    }

    &Owner {
      margin-top: 1.5rem;
      padding: 0.75rem 0;
      border-top: 1px solid var(--color-khaki-100);
      border-bottom: 1px solid var(--color-khaki-100);
      color: var(--color-mono-60);
      word-wrap: break-word;

      &Id {
        margin-right: 0.5rem;
      }

      &Icon {
        height: 12px;
      }
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTItemCard = ({ className = ``, micro, oracle, title, image, data, body }) => {
  let i;

  return (
    <Container micro={micro}>
      <div className={`${className} nftItemCard`}>
        <div className="nftItemCardMain">
          <div className="nftItemCardImage">
            <Image image={image} fill />
          </div>

          <div className="nftItemCardText">
            <h4 className="caption">{oracle}</h4>
            <h4 className="b1">{title}</h4>
            <h4 className="b2 nftItemCardTextBody">{body}</h4>
          </div>
        </div>

        {data?.owner?.id && (
          <div className="nftItemCardOwner">
            <div className="nftItemCardOwnerCaption caption">Owned by:</div>
            <div className="b2">
              <span className="nftItemCardOwnerId">{data.owner.id}</span>

              {data?.owner?.url && (
                <a href={data.owner.url} target="_blank" rel="noreferrer noopener">
                  <SVG svg="chain" className="nftItemCardOwnerIcon" />
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default NFTItemCard;
