import cn from "classnames";
import { useState } from "react";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import { TARGET_CHAIN } from "~utils/helpers";
import Button from "~components/Common/Button";
import SwitchChainButton from "~components/Common/SwitchChainButton";
import PriceWidget from "~components/Product/PriceWidget";
import useApp from "~hooks/useApp";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";
import { handleError } from "~utils/error";

const OfferOverview = ({ sellOrder }) => {
  const [executing, setExecuting] = useState(false);

  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { chain } = useAccount();
  const { trader } = useExternalIntegrations();
  const {
    userData: {
      balances: { nativeBalance }
    },
    setOverlayCompletionData,
    forceUpdate,
    update
  } = useApp();

  const {
    price: { displayPrice, displayPriceWithMakerFee, makerFeePercentage },
    eta
  } = useParseTraderOrder(sellOrder.order);

  const handleCancelOffer = async () => {
    setExecuting(true);
    const traderSdk = trader(publicClient, walletClient);
    try {
      await traderSdk.cancelOrder(sellOrder);
      setOverlayCompletionData({
        icon: `check`,
        heading: `Order cancelled`
      });
    } catch (error) {
      handleError(error, setOverlayCompletionData, nativeBalance?.value);
    }
    setExecuting(false);
  };

  return (
    <section className="sellOffer">
      <span className="sellOfferHeading">
        <h3>Your Sell Offer</h3>
        <dl>
          <dt>Expiration</dt>
          <dd>{eta}</dd>
        </dl>
      </span>
      <p>Review your current sale offer details below. Use the cancel button to withdraw your offer before the expiration time.</p>
      <div className="sellOfferDetails">
        <dl>
          <dt>Listing price</dt>
          <dd>
            <PriceWidget color="white" fontClass="h4" currencyVariant="secondary" displayPrice={displayPriceWithMakerFee} />
          </dd>
        </dl>
        <dl>
          <dt>After fees (-{makerFeePercentage}%)</dt>
          <dd>
            <PriceWidget color="white" fontClass="h4" currencyVariant="secondary" displayPrice={displayPrice} />
          </dd>
        </dl>
      </div>

      {TARGET_CHAIN?.id !== chain?.id ? (
        <SwitchChainButton className="singleButton" fluid variant="feature" colorTheme="light" onSuccess={() => forceUpdate(!update)} />
      ) : (
        <Button className={cn(executing && `loading`)} variant="primaryTall" fluid onClick={handleCancelOffer}>
          Cancel Offer
        </Button>
      )}
    </section>
  );
};

export default OfferOverview;
