import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useAccount, useSwitchChain } from "wagmi";
import { Button } from "~components";
import { TARGET_CHAIN } from "~utils/helpers";

const Container = styled.article`
  position: relative;
  margin-top: 1.5rem;
`;

const SwitchChainButton = (props) => {
  const [disabled, setDisabled] = useState(false);

  const { chainId } = useAccount();
  const { switchChainAsync, isPending, status } = useSwitchChain();
  const { onSuccess } = props;

  useEffect(() => {
    setDisabled(!switchChainAsync || TARGET_CHAIN?.id === chainId || isPending);
  }, [TARGET_CHAIN?.id, chainId, isPending, status, switchChainAsync]);

  return (
    <Container>
      <Button
        {...props}
        disabled={disabled}
        onClick={async () => {
          try {
            await switchChainAsync({ chainId: TARGET_CHAIN?.id });
            if (onSuccess) {
              onSuccess();
            }
          } catch (e) {
            console.error(e);
          }
        }}
      >
        {isPending ? `Switching...` : `Switch Chain To ${TARGET_CHAIN.name}`}
      </Button>
    </Container>
  );
};

export default SwitchChainButton;
