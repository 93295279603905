import React from "react";
import styled from "@emotion/styled";
import { Link } from "~components";

const Container = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 1.5rem;
  padding: 1.5rem;
  background: white;
  border-radius: 12px;

  .NFTActionVerifyIdentity {
    &Body {
      margin: 0.5rem 0 0;

      span {
        margin-right: 0.5rem;
      }

      a {
        text-decoration: underline;
      }
    }

    &Error {
      color: red;
    }

    &Flex {
      display: flex;
      align-items: center;
    }
  }

  p {
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTActionWaitAllowlist = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  // ---------------------------------------------------------------------------
  // methods

  const text = {
    heading: `Next Steps Following Identity Verification`,
    body: `You have successfully verified your identity. You will soon be included in the buyer's allowlist.`
  };
  // ---------------------------------------------------------------------------
  // render

  return (
    <Container>
      <div className="NFTActionVerifyIdentityFlex">
        <h3 className="b2">{text.heading}</h3>
      </div>

      <p className="NFTActionVerifyIdentityBody caption">
        <span>{text.body}</span>

        {text?.learnMore && <Link to="/profile/">Learn more</Link>}
      </p>
    </Container>
  );
};

export default NFTActionWaitAllowlist;
