import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import cn from "classnames";
import { SVG, LinkButton } from "~components";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  border-radius: 0.75rem;
  color: var(--color-white);
`;

const AccordionSection = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  text-align: left;
  align-items: center;
  cursor: pointer;

  .h4 {
    display: block;
  }

  .accordionStatus {
    margin-top: 0.25rem;
    display: flex;
    align-items: center;
  }

  .accordionButtonTitleStatus {
    ${breakpoint(`small-tablet`)} {
      display: flex;
      flex: 1;

      .h4 {
        flex: 1;
      }
    }
  }
`;

const StatusDot = styled.div`
  border-radius: 100%;
  height: 0.75rem;
  width: 0.75rem;
  margin-right: 6px;
  background: ${({ status }) => (status === `comingSoon` ? `var(--color-ux-orange-50)` : `var(--color-ux-green-50)`)};
`;

const Icon = styled.div`
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  margin-left: 1.5rem;
`;

const ContentContainer = styled.div`
  overflow: hidden;
  transition: 0.4s height;

  p {
    color: #797878;
  }
`;

const Content = styled.div`
  padding: 0 20px 20px;

  p {
    margin-bottom: 1.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Links = styled.ul`
  grid-template-columns: repeat(2, 1fr);
  gap: 0.75rem;

  li {
    grid-column: span 1;
    margin-bottom: 0.75rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  ${breakpoint(`tablet`)} {
    display: ${({ singleLink }) => (singleLink ? `block` : `grid`)};

    li {
      margin-bottom: 0;
    }
  }
`;

/** ============================================================================
 * @component
 * @param  {string}                   title   					Title text
 * @param  {string}                   content   				Body text
 * @param  {string}                   status    				`comingSoon` | `live` | `none`
 * @param  {boolean}                  openByDefault   	Whether the accordion should be open by default
 * @param  {boolean}                  open              Force accordion to be open or closed by parent
 * @param  {Links[]}                  links  		  			Array of linkOptions
 * @param  {string}                   className 				Class name to apply to the component
 * @param  {(state: boolean) => void} onAccordionToggle Callback fired when accordion changes state
 * @return {node}
 */

const Accordion = ({ title, content, status = `none`, openByDefault, links, className, dark, open, onAccordionToggle }) => {
  const [isOpenInternal, setIsOpenInternal] = useState(openByDefault);

  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef();

  const calculateContentHeight = () => {
    if (!contentRef.current) return;

    const height = contentRef.current.offsetHeight;
    setContentHeight(height);
  };

  useLayoutEffect(() => {
    calculateContentHeight();
    window.addEventListener(`resize`, calculateContentHeight);
    return () => window.removeEventListener(`resize`, calculateContentHeight);
  }, [contentRef.current]);

  const handleAccordionToggle = () => {
    setIsOpenInternal((prev) => {
      if (onAccordionToggle) onAccordionToggle(!prev);

      return !prev;
    });
  };

  const isOpen = open ?? isOpenInternal;
  const statusText = status === `comingSoon` ? `Launching soon` : `Live`;
  const icon = isOpen ? `minus` : `plus`;

  const singleLink = links?.length <= 1;

  return (
    <Container className={cn(dark ? `frosted-glass-dark` : `frosted-glass-light`, className)}>
      <AccordionSection onClick={handleAccordionToggle}>
        <div className="accordionButtonTitleStatus">
          <h3 className="h4">{title}</h3>
          {status !== `none` && (
            <div className="accordionStatus">
              <StatusDot status={status} />
              <span className="caption">{statusText}</span>
            </div>
          )}
        </div>
        <Icon>
          <SVG svg={icon} />
        </Icon>
      </AccordionSection>
      <ContentContainer style={{ height: `${isOpen ? contentHeight : 0}px` }}>
        <Content ref={contentRef}>
          {content && <p className="content">{content}</p>}
          {links?.[0] && (
            <Links singleLink={singleLink}>
              {links.map((link, i) => (
                <li key={i} className="accordionLink">
                  <LinkButton link={link} variant="feature" fluid />
                </li>
              ))}
            </Links>
          )}
        </Content>
      </ContentContainer>
    </Container>
  );
};

export default Accordion;
