import React from "react";
import styled from "@emotion/styled";
import { CryptoScramblerManual } from "~components";

/** ============================================================================
 * @css
 */

const Container = styled.div`
  transition: 1s var(--cubic-easing) opacity;

  opacity: ${({ active }) => `${active ? 1 : 0}`};

  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
`;

/** ============================================================================
 * @component
 * todo: document
 */
const ScrollVideoText = ({ active, phrase }) => (
  <Container active={active} className="scroll-video-text-container">
    <h2 className="h3">
      <CryptoScramblerManual phrase={phrase || ``} />
    </h2>
  </Container>
);

export default ScrollVideoText;
