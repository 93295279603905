import React from "react";
import { MenuItem, PgWrapper, PgItem } from "~components";

const MenuItemPG = () => (
  <>
    <PgWrapper title="MenuItem" itemWidth="400px">
      <PgItem title="Internal">
        <div style={{ background: `black`, padding: 16 }}>
          <MenuItem url="/" text="Menu Item" />
        </div>
      </PgItem>
      <PgItem title="External">
        <div style={{ background: `black`, padding: 16 }}>
          <MenuItem url="https://google.com" text="Menu Item" />
        </div>
      </PgItem>
      <PgItem title="Coming soon">
        <div style={{ background: `black`, padding: 16 }}>
          <MenuItem comingSoon text="Menu Item" />
        </div>
      </PgItem>
    </PgWrapper>
  </>
);

export default MenuItemPG;
