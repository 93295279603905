import React from "react";
import styled from "@emotion/styled";
import cn from "classnames";
import { breakpoint } from "~utils/css";

const Container = styled.div`
  padding: 2.5rem 1rem;
  position: relative;
  height: 100%;
  max-width: 1920px;
  margin: auto;

  ${breakpoint(`small-tablet`)} {
    padding: 2.5rem 2rem;
  }

  ${breakpoint(`large-tablet`)} {
    padding: 3.75rem;
  }
`;

const SlicePadding = ({ children, className }) => <Container className={cn(className)}>{children}</Container>;

export default SlicePadding;
