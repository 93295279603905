import * as OM from "./ApiModel";

export class Breadcrumb {
  title: string;
  link: string;
  constructor() {
    this.title = "";
    this.link = "";
  }
}

export class AppRole {
  public static readonly Customer = "customer";
  public static readonly Manager = "manager";
  public static readonly Admin = "admin";
  public static readonly Minter = "minter";
  public static readonly Oracle = "oracle";
  public static readonly VaultAdministrator = "vaultAdministrator";
  public static readonly BaseUser = "baseuser";
  public static readonly BackofficeUser = "backofficeuser";
}

class KeyText {
  key: string;
  text: string;

  constructor() {
    this.key = "";
    this.text = "";
  }
}

export class CreateableRole {
  role: string;
  authorizations: KeyText[];

  constructor(role: string, authorizations: KeyText[]) {
    this.role = role;
    this.authorizations = authorizations;
  }
}

const minterAuthorizations = new CreateableRole(AppRole.Minter, [
  { key: "authorization_mintnft", text: "Possibilità di mintare gli NFT" },
  {
    key: "authorization_createProduct",
    text: "Possibilità di creare prodotti"
  },
  {
    key: "authorization_updateProduct",
    text: "Possibilità di modificare prodotti"
  }
]);
const vaultAdministratorAuthorizations = new CreateableRole(
  AppRole.VaultAdministrator,
  [
    {
      key: "authorization_changeDatetimeDeposit",
      text: "Possibilità di prorogare la scadenza di deposito"
    }
  ]
);
const oracleAuthorizations = new CreateableRole(AppRole.Oracle, [
  { key: "authorization_createMinter", text: "Possibilità di creare minter" },
  {
    key: "authorization_createVaultAdministrator",
    text: "Possibilità di creare vault administrator"
  },
  {
    key: "authorization_createProduct",
    text: "Possibilità di creare prodotti"
  },
  {
    key: "authorization_updateProduct",
    text: "Possibilità di modificare prodotti"
  },
  { key: "authorization_mintNft", text: "Possibilità di mintare NFT" },
  {
    key: "authorization_changeDatetimeDeposit",
    text: "Possibilità di prorogare la scadenza di deposito"
  },
  {
    key: "authorization_manageProductStatus",
    text: "Possibilità di gestire gli stati dei prodotti"
  }
]);

const backofficeUserAuthorizations = new CreateableRole(
  AppRole.BackofficeUser,
  [
    {
      key: "authorization_editDictionary",
      text: "Possibilità di modificare il dizionario"
    }
  ]
);

// export function getCreatableUsers(): CreateableRole[] {
//   const ris: CreateableRole[] = [
//     minterAuthorizations,
//     vaultAdministratorAuthorizations
//   ];

//   if (store.state.loggedUser.role == AppRole.Admin) {
//     ris.push(oracleAuthorizations);
//     ris.push(backofficeUserAuthorizations);
//   }

//   return ris;
// }

export class Address {
  name: string;
  typedAddress: string;
  streetNumber: string;
  route: string;
  locality: string;
  province: string;
  region: string;
  country: string;
  countryCode: string;
  postalCode: string;
  latitude: number;
  longitude: number;
  completeAddress: string;

  constructor() {
    this.name = "";
    this.typedAddress = "";
    this.streetNumber = "";
    this.route = "";
    this.locality = "";
    this.province = "";
    this.region = "";
    this.country = "";
    this.countryCode = "";
    this.postalCode = "";
    this.latitude = 0;
    this.longitude = 0;
    this.completeAddress = "";
  }
}

export class TextIdentifier {
  text: string;
  identifier: string;

  constructor() {
    this.text = "";
    this.identifier = "";
  }
}

export class AppRoute {
  key: string;
  text: string;
  route: string;
  roles: string[];

  constructor() {
    this.key = "";
    this.text = "";
    this.route = "";
    this.roles = [];
  }
}

export class GalleryVM {
  publicUrl: string;
  index: number;
  file: File;

  constructor() {
    this.publicUrl = "";
    this.index = 0;
    this.file = null;
  }
}

export class VoidPagedRequestVM extends OM.CustomPagedRequestVM {
  declare paging: PagingFilter;
  declare sortBy: SortByParameter;

  constructor() {
    super();

    this.searchText = "";
    this.paging = null;
    this.sortBy = null;
    this.filter = {};
  }
}

export class PagingFilter {
  currentPage: number;
  itemsPerPage: number;
  skip: number;
  take: number;

  constructor() {
    this.currentPage = null;
    this.itemsPerPage = null;
    this.skip = null;
    this.take = null;
  }
}

export class SortByParameter {
  propertyName: string;
  ascending: boolean;

  constructor() {
    this.propertyName = "";
    this.ascending = false;
  }
}

export const productTypes = {
  WatchProduct: "WatchProduct",
  CarProduct: "CarProduct",
  TicketProduct: "TicketProduct"
}

export class TermsAndConditionsTypesDesc {
  public static readonly marketplace = "Terms of Service of the Marketplace";
  public static readonly nft = "Nft Sales and Co-Ownership T&C";
  public static readonly oracle = "Oracle Platform Service Agreement";
  public static readonly vault = "Vault Agreement";
  public static readonly vaultLend = "Vault Lend Agreement";
  public static readonly privacy = "Privacy Policy";
  public static readonly event = "Event Agreement";
}

export class TermsAndConditionsTypes {
  public static readonly Marketplace = "marketplace";
  public static readonly Nft = "nft";
  public static readonly Oracle = "oracle";
  public static readonly Vault = "vault";
  public static readonly VaultLend = "vaultLend";
  public static readonly Privacy = "privacy";
  public static readonly Event = "event";
}

export class ChangeStatusVM extends OM.ChangeStatusBM {
  constructor() {
    super();
  }
}

export class NFTDataVM extends OM.NFTDataBM {
  constructor() {
    super();
  }
}
