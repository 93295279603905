import { AuthenticationWalletCredential, AcceptedTermsAndConditionsVM, TermsAndConditionsVM } from "~dApp/models/ApiModel";
import * as Sentry from "@sentry/core";

const BASE_URL = process.env.GATSBY_LUCID_DB_URL;

/** ============================================================================
 * TermsAndConditions
 * todo - document
 */
class TermsAndConditionsService {
  // ---------------------------------------------------------------------------
  // class variables

  // ---------------------------------------------------------------------------
  // constructor
  public readonly PlatformTermsAndConditionsKey = "marketplace";
  public readonly VaultTermsAndConditionsKey = "vault";
  public readonly NftTermsAndConditionsKey = "nft";

  constructor() {
  }

  private checkResponseStatus(response: Response) {
    if (!response.ok)
      throw Error(response.statusText)
  }

  // ---------------------------------------------------------------------------
  // methods

  async getMarketplaceTermsAndConditions(walletAddress = "") {
    return this.getTermsAndConditions(this.PlatformTermsAndConditionsKey, `walletAddress=${walletAddress}`);
  };

  buildGetTermsAndConditionsQuerystring(walletAddress = "", termsTargetIdentifier = "") {
    return `walletAddress=${walletAddress}&termsTargetIdentifier=${termsTargetIdentifier}`;
  }

  async getTermsAndConditionsByType(tcType: string, walletAddress = "", referenceIdentifier = "") {
    var qs = this.buildGetTermsAndConditionsQuerystring(walletAddress, referenceIdentifier);
    return this.getTermsAndConditions(tcType, qs);
  };

  async getVaultTermsAndConditions(walletAddress = "", nftIdentifier = "") {
    var qs = this.buildGetTermsAndConditionsQuerystring(walletAddress, nftIdentifier);
    return this.getTermsAndConditions(this.VaultTermsAndConditionsKey, qs);
  };

  async getNftTermsAndConditions(walletAddress = "", nftIdentifier = "") {
    var qs = this.buildGetTermsAndConditionsQuerystring(walletAddress, nftIdentifier);
    return this.getTermsAndConditions(this.NftTermsAndConditionsKey, qs);
  };

  async getTermsAndConditions(termsAndConditionType: string, querystring = "") {
    let target = termsAndConditionType;
    if (!termsAndConditionType) {
      target = this.PlatformTermsAndConditionsKey;
    }

    let url = `${BASE_URL}/api/TermsAndConditions/GetTermsAndConditionByType?termsAndConditionsType=${target}`;
    if (querystring) {
      url = `${url}&${querystring}`;
    }
    try {
      const response = await fetch(
        url,
        {
          method: `GET`,
          cache: 'no-cache',
          headers: { "Content-Type": `application/json` }
        }
      );
      this.checkResponseStatus(response);
      const termsAndConditionsVM = Object.assign(new TermsAndConditionsVM, await response.json());
      return termsAndConditionsVM as TermsAndConditionsVM;
    } catch (e) {
      console.error(
        `Problem retrieving terms and conditions of type '${target}': `,
        e
      );
      throw e;
    }
  };

  buildAcceptedTermsAndConditions(termsAndConditions: TermsAndConditionsVM, signedData: AuthenticationWalletCredential): AcceptedTermsAndConditionsVM {
    if (!termsAndConditions || !termsAndConditions.lastVersion) {
      Sentry.captureMessage(`Altr Marketplace - Terms And Condition Not Found for type ${termsAndConditions.type}`,
        `error`);
      return null;
    };

    let acceptedPlatformTermsAndConditions: AcceptedTermsAndConditionsVM = {
      ...termsAndConditions,
      ...{
        message: signedData.baseMessage,
        signedMessage: signedData.signedMessage,
        nonce: signedData.nonce
      }
    };
    return acceptedPlatformTermsAndConditions;
  }

  async getTermsAndConditionsContent(version: string, ipldHash: string) {
    //FIXME: load from IPFS?
    try {
      const response = await fetch(
        `${BASE_URL}/api/TermsAndConditions/GetContent?version=${version}&ipldHash=${ipldHash}`,
        {
          method: `GET`,
          cache: 'no-cache',
          headers: { "Content-Type": `application/json` }
        }
      );
      this.checkResponseStatus(response);
      return await response.text();
    } catch (e) {
      console.error(
        `Problem retrieving terms and conditions for version ${version} - IPLD hash ${ipldHash}`,
        e
      );
      throw e;
    }
  };
}

export default TermsAndConditionsService;