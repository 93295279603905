import { Button } from "~components";
import useApp from "~hooks/useApp";

const ProfileDigitisingSidebar = () => {
  const { userData, userIsLoggedIn } = useApp();
  const formUrl = `${process.env.GATSBY_DIGITISE_FORM_URL}${process.env.GATSBY_DIGITISE_FORM_WALLET_ENTRY}${userData.address}${process.env.GATSBY_DIGITISE_FORM_EMAIL_ENTRY}${userData.user?.emailAddress}`;

  return (
    <section className="sidebar">
      <h2>WHY DIGITISE YOUR COLLECTIBLES?</h2>
      <p>
        Digitising your collectibles provides the security of freeport vault storage and the the liquidity benefits of a digital asset that can be
        collateralized on Altr&apos;s lending platform, along with the promise of additional features to be introduced in the future.
      </p>
      {userIsLoggedIn && (
        <Button fluid variant="feature" href={formUrl}>
          New request
        </Button>
      )}
      <section>
        <h3>NEED MORE INFORMATION?</h3>
        <Button fluid variant="feature" to="/how-digitisation-works">
          How it works
        </Button>
      </section>
    </section>
  );
};

export default ProfileDigitisingSidebar;
